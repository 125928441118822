function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { GET_FEATURE_TOGGLES_FAIL, GET_FEATURE_TOGGLES_SUCCESS, GET_FEATURE_TOGGLES_REQUEST } from './feature-toggles.actions';
const initialState = {
  isLoading: false,
  error: null,
  toggles: {
    goodieGiveaway: false,
    ciProLogoutFeature: false,
    faqSwagSpacePageOnInventory: false,
    faqCustomInkPageOnInventory: false,
    specializedFulfillment: false,
    listResellersAdminDashboard: false,
    taxExemptionRequestsAdminDashboard: false,
    autoReplenishInventory: false,
    separoColorDetection: false,
    createCartLink: false,
    swagBundlesFlow: false,
    resellerDistributionsSync: false,
    isCustomDomainEnabled: false,
    swagBundlesInventoryTransfer: false,
    swagBundlesCreateGiveaway: false,
    swagBundlesOrderMore: false,
    swagBundlesEditBox: false,
    buildACart: false,
    shopifyOrdersPage: false,
    dynamicPricingOptionsForDecorationMethods: false,
    dhlShippingIntegration: false,
    blockUPSRatesForTestingFallback: false
  }
};
export const featureTogglesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEATURE_TOGGLES_REQUEST:
      return _objectSpread({}, state, {
        isLoading: true
      });
    case GET_FEATURE_TOGGLES_SUCCESS:
      return _objectSpread({}, state, {
        isLoading: false,
        error: null,
        toggles: action.payload
      });
    case GET_FEATURE_TOGGLES_FAIL:
      return _objectSpread({}, state, {
        isLoading: false,
        error: action.error,
        toggles: initialState.toggles
      });
    default:
      return state;
  }
};