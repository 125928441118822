import { ResellerAdminMode } from 'swag-common/constants/reseller.constants';
export const checkIsResellerAdminMode = resellerAdminMode => {
  if (!resellerAdminMode) {
    return false;
  }
  return [ResellerAdminMode.CreatingCart, ResellerAdminMode.EditingCart, ResellerAdminMode.CreatingPresentation, ResellerAdminMode.EditingPresentation, ResellerAdminMode.CreatingInvoice, ResellerAdminMode.EditingInvoice, ResellerAdminMode.OrderCreation].includes(resellerAdminMode);
};
export const checkIsPreBuiltCartMode = resellerAdminMode => {
  if (!resellerAdminMode) {
    return false;
  }
  return [ResellerAdminMode.CreatingCart, ResellerAdminMode.EditingCart].includes(resellerAdminMode);
};
export const checkIsPresentationMode = resellerAdminMode => {
  if (!resellerAdminMode) {
    return false;
  }
  return [ResellerAdminMode.CreatingPresentation, ResellerAdminMode.EditingPresentation].includes(resellerAdminMode);
};
export const checkIsInvoiceMode = mode => {
  if (!mode) {
    return false;
  }
  return [ResellerAdminMode.CreatingInvoice, ResellerAdminMode.EditingInvoice].includes(mode);
};
export const checkIsOrderCreationMode = mode => ResellerAdminMode.OrderCreation === mode;
export const isPresentationOrInvoiceMode = mode => checkIsPresentationMode(mode) || checkIsInvoiceMode(mode);
export const checkIsInvoiceOrOrderMode = mode => {
  if (!mode) {
    return false;
  }
  return [ResellerAdminMode.CreatingInvoice, ResellerAdminMode.EditingInvoice, ResellerAdminMode.OrderCreation].includes(mode);
};