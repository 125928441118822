import { getBrands } from 'swag-common/utils/google-tag-manager/get-brands.util';
import { getCategory } from './get-category.util';
import { getParentCategory } from './get-parent-category.util';
export const getProductForGoogleTagManager = (product, allCategories) => {
  return {
    index: 0,
    item_id: null,
    item_name: (product === null || product === void 0 ? void 0 : product.name) || '',
    item_brand: getBrands(product.attributeValues),
    item_category: getParentCategory(product.categories, allCategories),
    item_category2: getCategory(product.categories, allCategories)
  };
};