function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ZxcvbnOptions } from '@zxcvbn-ts/core';
const loadOptions = async () => {
  const zxcvbnCommonPackage = await import( /* webpackChunkName: "zxcvbnCommonPackage" */'@zxcvbn-ts/language-common');
  const zxcvbnEnPackage = await import( /* webpackChunkName: "zxcvbnEnPackage" */'@zxcvbn-ts/language-en');
  return {
    dictionary: _objectSpread({}, zxcvbnCommonPackage.default.dictionary, zxcvbnEnPackage.default.dictionary),
    graphs: zxcvbnCommonPackage.default.adjacencyGraphs,
    translations: zxcvbnEnPackage.default.translations
  };
};
const prepareStrengthMeter = async () => {
  const options = await loadOptions();
  ZxcvbnOptions.setOptions(options);
};
export default prepareStrengthMeter;