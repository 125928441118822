import { createSelector } from 'reselect';
const notecardEditorRootSelector = state => state.notecardEditor;
export const notecardColorSelector = createSelector(notecardEditorRootSelector, state => state.color);
export const notecardLogoPreviewSelector = createSelector(notecardEditorRootSelector, state => {
  var _state$logo, _state$logo2;
  return ((_state$logo = state.logo) === null || _state$logo === void 0 ? void 0 : _state$logo.originalPreview) || ((_state$logo2 = state.logo) === null || _state$logo2 === void 0 ? void 0 : _state$logo2.original) || '';
});
export const isNotecardLoadingSelector = createSelector(notecardEditorRootSelector, state => state.isLogoLoading);
export const notecardTextContentSelector = createSelector(notecardEditorRootSelector, state => state.textContent);
export const notecardEditorDataSelector = createSelector(notecardEditorRootSelector, state => {
  const {
    textContent,
    color,
    logo
  } = state;
  return {
    textContent,
    color,
    logo
  };
});
export const notecardEditorInitialStateSelector = createSelector(notecardEditorRootSelector, state => state.initialState);
export const isNotecardEditorChangedSelector = createSelector(notecardEditorInitialStateSelector, notecardEditorDataSelector, (initialState, data) => {
  var _initialState$logo, _data$logo;
  if (!initialState) {
    return false;
  }
  return ((_initialState$logo = initialState.logo) === null || _initialState$logo === void 0 ? void 0 : _initialState$logo.originalPreview) !== ((_data$logo = data.logo) === null || _data$logo === void 0 ? void 0 : _data$logo.originalPreview) || initialState.color !== data.color || initialState.textContent !== data.textContent;
});