import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { getCart, getDraft } from 'swag-client-common/redux/cart/cart.actions';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { showPredefinedNotification } from 'swag-client-common/redux/notification/notification.actions';
import { NOTIFICATIONS } from 'swag-client-common/redux/notification/notification.constants';
import { PENDING_ORDER_ACTIONS } from 'swag-common/constants/order-statuses.constants';
import { showCustomerMockups } from '../show-mockups/show-mockups.actions';
import { singleItemInOrderSelector } from '../pending-orders/pending-orders.selectors';
import { OrderApi } from '../../../common/api/order.api';
const PREFIX = 'PENDING_ORDERS_';
export const FETCH_PENDING_ORDERS_REQUEST = `${PREFIX}FETCH_PENDING_ORDERS_REQUEST`;
export const FETCH_PENDING_ORDERS_SUCCESS = `${PREFIX}FETCH_PENDING_ORDERS_SUCCESS`;
export const FETCH_PENDING_ORDERS_ERROR = `${PREFIX}FETCH_PENDING_ORDERS_ERROR`;
export const fetchPendingOrders = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: FETCH_PENDING_ORDERS_REQUEST
    });
  },
  fn: OrderApi.getPendingOrders,
  success: result => ({
    type: FETCH_PENDING_ORDERS_SUCCESS,
    payload: result
  }),
  error: error => ({
    type: FETCH_PENDING_ORDERS_ERROR,
    payload: error,
    error
  })
});
export const CONFIRM_ACTION_REQUEST = `${PREFIX}CONFIRM_ACTION_REQUEST`;
export const CONFIRM_ACTION_SUCCESS = `${PREFIX}CONFIRM_ACTION_SUCCESS`;
export const CONFIRM_ACTION_ERROR = `${PREFIX}CONFIRM_ACTION_ERROR`;
export const confirmAction = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: CONFIRM_ACTION_REQUEST
    });
  },
  fn: OrderApi.changePendingOrder,
  success: result => ({
    type: CONFIRM_ACTION_SUCCESS,
    payload: result
  }),
  error: error => ({
    type: CONFIRM_ACTION_ERROR,
    payload: error,
    error
  }),
  postSuccess: (dispatch, _, data) => {
    const notificationName = data.action === PENDING_ORDER_ACTIONS.APPROVE ? NOTIFICATIONS.PENDING_ORDER_APPROVED : NOTIFICATIONS.PENDING_ORDER_REJECTED;
    dispatch(hidePopup());
    dispatch(showPredefinedNotification({
      notificationName
    }));
    if (data.action === PENDING_ORDER_ACTIONS.REJECT) {
      dispatch(getCart());
      dispatch(getDraft());
    }
  }
});
export const showItemImage = (orderId, itemId) => (dispatch, getState) => {
  const item = singleItemInOrderSelector(getState(), {
    orderId,
    itemId
  });
  dispatch(showCustomerMockups(item));
};