function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CLEAR_CART, GET_CART } from 'swag-client-common/redux/cart/cart.actions';
import { SELECT_INVENTORY, DESELECT_INVENTORY, GET_USER_INVENTORIES_SUCCESS, GET_LAST_INVENTORY_SUCCESS, GET_PAYMENT_METHODS_SUCCESS, SELECT_CARD, SELECT_DISTRIBUTE_OPTION, SELECT_DISTRIBUTION_AREA } from './inventory.actions';
const initialState = {
  list: [],
  lastInventoryId: null,
  demoInventory: null,
  selectedInventoryId: null,
  settings: [],
  selectedCard: null,
  selectedDistributeOption: null,
  selectedDistributionArea: null
};
export default function inventoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_INVENTORIES_SUCCESS:
      return _objectSpread({}, state, action.payload);
    case SELECT_INVENTORY:
      {
        const _id = action.payload;
        return _objectSpread({}, state, {
          selectedInventoryId: _id
        });
      }
    case DESELECT_INVENTORY:
      {
        return _objectSpread({}, state, {
          selectedInventoryId: null
        });
      }
    case GET_LAST_INVENTORY_SUCCESS:
      return _objectSpread({}, state, {
        lastInventoryId: action.payload
      });
    case GET_PAYMENT_METHODS_SUCCESS:
      {
        const {
          settings
        } = action.payload;
        return _objectSpread({}, state, {
          settings
        });
      }
    case SELECT_CARD:
      {
        const {
          selectedCard
        } = action.payload;
        return _objectSpread({}, state, {
          selectedCard
        });
      }
    case SELECT_DISTRIBUTE_OPTION:
      {
        return _objectSpread({}, state, {
          selectedDistributeOption: action.payload.option
        });
      }
    case SELECT_DISTRIBUTION_AREA:
      {
        return _objectSpread({}, state, {
          selectedDistributionArea: action.payload.area
        });
      }
    case GET_CART:
      {
        const {
          distributeOption,
          distributionArea
        } = action.body;
        return _objectSpread({}, state, {
          selectedDistributeOption: distributeOption || state.selectedDistributeOption,
          selectedDistributionArea: distributionArea || state.selectedDistributionArea
        });
      }
    case CLEAR_CART:
      {
        return _objectSpread({}, state, {
          selectedDistributeOption: null
        });
      }
    default:
      return state;
  }
}