import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { REMOVE_ITEM_FROM_CART_POPUP, ORDER_MORE_BOX_EDIT_WARNING_POPUP, ORDER_MORE_BOX_REMOVE_WARNING_POPUP, EDIT_BOX_NOTECARD_POPUP, PRODUCTION_TIME_DRAWER } from 'swag-client-common/constants';
import { OrderStorageTypes } from 'swag-common/constants/main-swag.constants';
import { isBox, isItemBoxSpecialNotecard } from 'swag-common/utils/order/item-is-box.util';
import { isOrderMore } from 'swag-common/business-logic/order/is-order-more-item.logic';
import { rebuildItem } from 'swag-client-common/redux/cart/cart.actions';
import ItemApi from '../../api/item.api';
import { setBoxNotecardBuilderBoxItemsId } from '../box-notecard-editor/box-notecard-editor.actions';
import { cartItemsSelector, draftItemsSelector } from './cart.selectors';
const PREFIX = 'CART_';
export const SET_BOX_NOTECARD = `${PREFIX}SET_BOX_NOTECARD`;
export const setBoxNotecard = (boxItemsId, isNotecardPicked) => ({
  type: SET_BOX_NOTECARD,
  payload: {
    boxItemsId,
    isNotecardPicked
  }
});
export const SET_CRINKLE_PAPER = `${PREFIX}SET_CRINKLE_PAPER`;
export const setBoxCrinklePaper = (boxItemsId, pickedVariantIndex) => ({
  type: SET_CRINKLE_PAPER,
  payload: {
    boxItemsId,
    pickedVariantIndex
  }
});
export const RESET_PICKED_SPECIAL_BOXES_PRODUCTS = `${PREFIX}RESET_PICKED_SPECIAL_BOXES_PRODUCTS`;
export const resetPickedSpecialBoxesProducts = () => ({
  type: RESET_PICKED_SPECIAL_BOXES_PRODUCTS
});
export const ITEM_UPDATE_REQUEST = `${PREFIX}ITEM_UPDATE_REQUEST`;
export const ITEM_UPDATE_SUCCESS = `${PREFIX}ITEM_UPDATE_SUCCESS`;
export const ITEM_UPDATE_FAILURE = `${PREFIX}ITEM_UPDATE_FAILURE`;
export const updateItem = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: ITEM_UPDATE_REQUEST
    });
  },
  fn: ItemApi.update,
  success: updatedItem => ({
    type: ITEM_UPDATE_SUCCESS,
    payload: {
      updatedItem
    }
  }),
  error: error => ({
    type: ITEM_UPDATE_FAILURE,
    payload: {
      error
    },
    error
  })
});
export const CLICK_ADD_TO_CART_BUTTON = `${PREFIX}CLICK_ADD_TO_CART_BUTTON`;
export const clickAddToCart = () => ({
  type: CLICK_ADD_TO_CART_BUTTON
});
export const CLICK_ADD_TO_BOX_BUTTON = `${PREFIX}CLICK_ADD_TO_BOX_BUTTON`;
export const clickAddToBox = () => ({
  type: CLICK_ADD_TO_BOX_BUTTON
});
export const CLICK_BUY_PRINTED = `${PREFIX}CLICK_BUY_PRINTED`;
export const clickBuyPrinted = () => ({
  type: CLICK_BUY_PRINTED
});
export const openRemoveDialog = (itemId, storageType, itemIdFromReorder) => (dispatch, getState) => {
  const state = getState();
  const cartItems = cartItemsSelector(state);
  const draftItems = draftItemsSelector(state);
  const isDraft = storageType === OrderStorageTypes.DRAFT;
  const items = isDraft ? draftItems : cartItems;
  const item = items.find(({
    _id
  }) => _id === itemId);
  const fullBox = items.filter(({
    boxItemsId
  }) => item.boxItemsId && boxItemsId === item.boxItemsId);
  const isRemoveFromBox = !!item.boxItemsId;
  if (Boolean(fullBox.length) && fullBox.every(isOrderMore) && !isBox(item)) {
    return dispatch(showPopup({
      popup: ORDER_MORE_BOX_REMOVE_WARNING_POPUP,
      item,
      storageType,
      itemIdFromReorder
    }));
  }
  if (isDraft) {
    return dispatch(showPopup({
      popup: REMOVE_ITEM_FROM_CART_POPUP,
      itemId,
      storageType,
      itemIdFromReorder,
      isRemoveFromBox
    }));
  }
  dispatch(showPopup({
    popup: REMOVE_ITEM_FROM_CART_POPUP,
    itemId,
    storageType,
    itemIdFromReorder,
    isRemoveFromBox
  }));
};
export const openEditDialog = (item, storageType) => (dispatch, getState) => {
  const state = getState();
  const cartItems = cartItemsSelector(state);
  const draftItems = draftItemsSelector(state);
  const isDraft = storageType === OrderStorageTypes.DRAFT;
  const items = isDraft ? draftItems : cartItems;
  const fullBox = items.filter(({
    boxItemsId
  }) => item.boxItemsId && boxItemsId === item.boxItemsId);
  const isNotecard = isItemBoxSpecialNotecard(item);
  if (Boolean(fullBox.length) && fullBox.every(isOrderMore) && !isBox(item) && item.originalInventoryId && item.inventoryId) {
    return dispatch(showPopup({
      popup: ORDER_MORE_BOX_EDIT_WARNING_POPUP,
      item,
      storageType
    }));
  }
  if (!fullBox.length && item.originalInventoryId && item.inventoryId && isOrderMore(item)) {
    return dispatch(showPopup({
      popup: ORDER_MORE_BOX_EDIT_WARNING_POPUP,
      item,
      storageType
    }));
  }
  if (isNotecard) {
    dispatch(setBoxNotecardBuilderBoxItemsId(item.boxItemsId));
    return dispatch(showPopup({
      popup: EDIT_BOX_NOTECARD_POPUP
    }));
  }
  dispatch(rebuildItem(item, storageType));
};
export const setSelectedItemEmbellishmentMethod = ({
  itemId,
  embellishmentMethodId,
  productionTimeIdx,
  storageType = OrderStorageTypes.CART
}) => dispatch => {
  dispatch(hidePopup());
  dispatch(showPopup({
    popup: PRODUCTION_TIME_DRAWER,
    itemId,
    confirmedEmbellishmentMethodId: embellishmentMethodId,
    confirmedProductionTimeIdx: productionTimeIdx,
    storageType
  }));
};
export const OPEN_CART_PAGE = `${PREFIX}OPEN_CART_PAGE`;
export const openCartPage = () => dispatch => {
  dispatch({
    type: OPEN_CART_PAGE
  });
};
export const OPEN_DELIVERY_PAGE = `${PREFIX}OPEN_DELIVERY_PAGE`;
export const openDeliveryPage = () => dispatch => {
  dispatch({
    type: OPEN_DELIVERY_PAGE
  });
};