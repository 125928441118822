import { createApi } from 'swag-client-common/libs/rapi';
const FavouriteProductsApi = createApi({
  getAllFavouriteProductIds: {
    url: '/api/v1/favourite-products',
    method: 'get'
  },
  getFavouriteProducts: {
    url: '/api/v1/favourite-products/list',
    method: 'get'
  },
  toggle: {
    url: '/api/v1/favourite-products',
    method: 'put'
  }
});
export default FavouriteProductsApi;