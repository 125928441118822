import { POPUP_SHOWN } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { singleItemLogosSelector, maxPrintColorsSelector } from '../account-orders/account-orders.selectors';
import { singleItemLogosSelector as companyItemLogosSelector, maxPrintColorsSelector as companyMaxPrintColorsSelector } from '../company-orders/company-orders.selectors';
import { prefillData } from './item-pantones.actions';
import { getFirstLogoIdSelector, getListOfLogosIds, normalizeLogos } from './item-pantones.utils';
export default function ({
  dispatch,
  getState
}) {
  return next => action => {
    if (action.type === POPUP_SHOWN && action.payload.popup === 'confirmItemPantones') {
      const {
        itemId,
        orderId,
        type,
        isDisabled
      } = action.payload.params;
      let logos;
      let maxPrintColors;
      if (type === 'account') {
        logos = singleItemLogosSelector(getState(), {
          orderId,
          itemId
        });
        maxPrintColors = maxPrintColorsSelector(getState(), {
          orderId,
          itemId
        });
      }
      if (type === 'company') {
        logos = companyItemLogosSelector(getState(), {
          orderId,
          itemId
        });
        maxPrintColors = companyMaxPrintColorsSelector(getState(), {
          orderId,
          itemId
        });
      }
      const logosIds = getListOfLogosIds(logos);
      const normalizedLogos = normalizeLogos(logos);
      const firstLogoId = getFirstLogoIdSelector(logos);
      dispatch(prefillData({
        orderId,
        itemId,
        logos: normalizedLogos,
        selectedLogo: firstLogoId,
        logosIds,
        maxPrintColors,
        isDisabled
      }));
    }
    next(action);
  };
}