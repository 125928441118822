function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export const getIntersection = array => {
  if (!array.length) {
    return [];
  }
  return array.reduce((set, next) => {
    return set.filter(element => next.includes(element));
  }, array[0]);
};
export default function getUniqueSizeSet(products) {
  const sizes = products.reduce((set, product) => {
    const {
      hasSize,
      sizeId,
      sizeDependentPrice,
      productSize
    } = product;
    if (hasSize && sizeId && !sizeDependentPrice && productSize !== null && productSize !== void 0 && productSize.options) {
      return [...set, productSize.options];
    }
    return set;
  }, []);
  const sizeSet = getIntersection(sizes);
  return sizeSet.reduce((set, next) => _objectSpread({}, set, {
    [next]: next
  }), {});
}