import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/product';
export const ProductApi = createApi({
  getSupplierStockLevels: {
    url: `${baseUrl}/supplier-stock-levels`,
    method: 'get'
  },
  getGiveawayNotecard: {
    url: `${baseUrl}/giveaway-notecard`,
    method: 'get'
  },
  getSpecialBoxProducts: {
    url: `${baseUrl}/special-box-products`
  },
  getAddonsMinQuantityForSwagBundle: {
    url: `${baseUrl}/bundle-addons-min-quantity`,
    method: 'get'
  }
});