import { browserHistory } from 'react-router';
import { PRODUCT_SORTING } from 'swag-common/constants/product.constants';
import { SWAG_SERVER_PROD } from 'swag-common/config/constants';
import { getItemShippingCost, getDataForGettingItemShippingCost } from 'swag-common/business-logic/order/get-item-shipping-cost.service';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { CLEAR_LOCATION_STATE } from '../../customer/redux/routing/routing.reducer';
export const toSlug = str => str ? str.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-') : '';
export const getFilter = (code, isForClient = false) => {
  switch (code) {
    case PRODUCT_SORTING.MOST_POPULAR:
      return 'Most Popular';
    case PRODUCT_SORTING.CUSTOM:
      return 'No Sorting';
    case PRODUCT_SORTING.NEWEST:
      return isForClient ? 'Newest' : 'By "New" label';
    case PRODUCT_SORTING.HIGHEST_PRICE:
      return 'Highest price';
    case PRODUCT_SORTING.LOWEST_PRICE:
      return 'Lowest price';
    case PRODUCT_SORTING.RELEVANCE:
      return 'Most Relevant';
    case PRODUCT_SORTING.HOTTEST:
      return isForClient ? 'Hottest' : 'By "Hot" label';
    case PRODUCT_SORTING.LATEST:
      return 'Latest';
    default:
      return `Unexpected code ${code}`;
  }
};
export const getShippingServiceName = code => {
  switch (code) {
    case '14':
      return 'UPS Next Day Air Early';
    case '01':
      return 'Next Day Air';
    case '13':
      return 'Next Day Air Saver';
    case '59':
      return '2nd Day Air A.M.';
    case '02':
      return '2nd Day Air';
    case '12':
      return '3 Day Select';
    case '03':
      return 'Ground';
    default:
      return `unexpected code ${code}`;
  }
};
export function getShipmentCharge(item, items) {
  if (!item.shipmentCharges) {
    return 0;
  }
  const totalCharges = getItemShippingCost(getDataForGettingItemShippingCost(item, items));
  return item.upsCharge || totalCharges;
}
export const isKSPlan = plan => plan && plan._id === 'kickstarter';
export const goToPageWithReload = url => {
  window.location.href = url;
};
export const getNameOfFileFromUrl = url => {
  if (!url) {
    return '';
  }
  const regexResult = url.match(/[^/]+$/i) || [];
  return regexResult.length > 0 ? regexResult[0] : '';
};
export const getImageWithParams = ({
  url,
  format = 'jpg',
  height = 100
}) => {
  const fileName = getNameOfFileFromUrl(url);
  const {
    IMAGE_CACHE_URL
  } = window.swag.env;
  if (process.env.SWAG_SERVER === SWAG_SERVER_PROD) {
    return `${IMAGE_CACHE_URL}/convert/swag-${process.env.SWAG_SERVER}/${fileName}?format=${format}&height=${height}`;
  }
  return url;
};
export const getBoxesCatalogLink = () => {
  const {
    BOXES_CATALOG_S3_PATH
  } = window.swag.env;
  return BOXES_CATALOG_S3_PATH;
};

/**
 *
 * @param {String} seoSlug
 */
export function getProductLink(seoSlug) {
  return `${APP_ROUTES.PRODUCT}/${seoSlug}`;
}
export const scrollIntoView = (id, isIndentAdded = false, indent = 100) => {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }
  const size = element.getBoundingClientRect();
  let top = size.top;
  if (isIndentAdded) {
    top = size.top - indent;
  }
  window.scrollBy({
    top,
    left: 0,
    behavior: 'smooth'
  });
};
export function scrollToItem(itemId, {
  behavior = 'smooth',
  yOffset = 0
} = {}) {
  if (!itemId) {
    return null;
  }
  const element = document.getElementById(itemId);
  if (!element) {
    return null;
  }
  const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({
    top: yCoordinate,
    behavior
  });
}
export function instantScrollTop() {
  window.scrollTo(0, 0);
}
export function isPdf(link = 'pdf') {
  const ext = link.split('.').reverse()[0];
  return link.includes('data:application/pdf') || ext === 'pdf';
}
let count = 0;
let draftLocation = '';
export const onUpdateRouter = (store, dispatch) => () => {
  var _state$routing, _state$routing$locati, _state$routing2;
  const state = store.getState();
  const action = (_state$routing = state.routing) === null || _state$routing === void 0 ? void 0 : (_state$routing$locati = _state$routing.locationBeforeTransitions) === null || _state$routing$locati === void 0 ? void 0 : _state$routing$locati.action;
  const isForward = action === 'PUSH';
  const isReplace = action === 'REPLACE';
  const isReturn = action === 'POP';
  const {
    scrollY,
    location
  } = ((_state$routing2 = state.routing) === null || _state$routing2 === void 0 ? void 0 : _state$routing2.locationState) || {};
  const isSameLocation = location === window.location.href;
  const isSameDraftLocation = location === draftLocation;
  const clearData = () => {
    count = 0;
    draftLocation = '';
    dispatch({
      type: CLEAR_LOCATION_STATE
    });
  };
  const shouldReset = isForward && count > 0 || isReplace && count > 2;
  if (shouldReset && isSameDraftLocation) {
    return clearData();
  }
  if (shouldReset && !isSameDraftLocation) {
    count = 0;
  }
  if (location) {
    count++;
    draftLocation = location;
  }
  if (isReturn && isSameLocation && scrollY) {
    window.scrollTo(0, scrollY);
    return clearData();
  }
};
export const redirectWithSavingScroll = pathname => () => {
  return browserHistory.push({
    pathname,
    state: {
      scrollY: window.scrollY,
      location: window.location.href
    }
  });
};