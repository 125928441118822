const isValidQuantityByIncrement = (quantity, quantIncrement, variants) => {
  if (!quantIncrement || quantity === undefined) {
    return true;
  }
  if (quantity % quantIncrement !== 0) {
    return false;
  }
  if (!variants) {
    return true;
  }
  return !variants.find(({
    quantities
  }) => quantities.find(q => q % quantIncrement !== 0));
};
export default isValidQuantityByIncrement;