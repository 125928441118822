import { USER_ROLES } from 'swag-common/constants/user-roles';
export function hasMyCompanyItem({
  userRole,
  orderApprovalFlow,
  userIsHeadOfCompanyDepartment
}) {
  if (!orderApprovalFlow) {
    return false;
  }
  return userRole === USER_ROLES.COMPANY_SUPERVISOR || userIsHeadOfCompanyDepartment;
}