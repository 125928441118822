import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/email';
export const EmailApi = createApi({
  requestFreeSample: {
    url: `${baseUrl}/free-sample`,
    method: 'post'
  },
  sendContactRequest: {
    url: `${baseUrl}/contact`,
    method: 'post'
  },
  enterprise: {
    url: `${baseUrl}/enterprise`,
    method: 'post'
  },
  customSwag: {
    url: `${baseUrl}/custom-swag`,
    method: 'post'
  },
  sendMockup: {
    url: `${baseUrl}/share-design`,
    method: 'post'
  },
  shareProductLink: {
    url: `${baseUrl}/share-product`,
    method: 'post'
  },
  requestEnterprise: {
    url: `${baseUrl}/request-enterprise`,
    method: 'post'
  },
  requestAutomation: {
    url: `${baseUrl}/request-automation`,
    method: 'post'
  },
  requestZapier: {
    url: `${baseUrl}/request-zapier`,
    method: 'post'
  },
  requestShopify: {
    url: `${baseUrl}/request-shopify`,
    method: 'post'
  },
  requestPatagonia: {
    url: `${baseUrl}/request-patagonia`,
    method: 'post'
  },
  requestInABox: {
    url: `${baseUrl}/request-in-a-box`,
    method: 'post'
  },
  requestGiveaway: {
    url: `${baseUrl}/request-giveaway`,
    method: 'post'
  },
  requestDemo: {
    url: `${baseUrl}/request-demo`,
    method: 'post'
  }
});