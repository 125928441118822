import { browserHistory } from 'react-router';
import { SPECIAL_ROUTES } from 'swag-client-common/constants/app-special-routes.dynamic';
import { PRE_BUILD_LIMITED_CART_ALREADY_PLACED, PRE_BUILD_REGULAR_CART_ALREADY_PLACED } from 'swag-common/constants/error-codes';
export const handleCartUnavailableError = (dispatch, getState, error) => {
  if ([PRE_BUILD_LIMITED_CART_ALREADY_PLACED, PRE_BUILD_REGULAR_CART_ALREADY_PLACED].includes(error.code)) {
    browserHistory.push({
      pathname: SPECIAL_ROUTES.CART_UNAVAILABLE,
      state: {
        shouldShowShopMoreBtn: error.code !== PRE_BUILD_LIMITED_CART_ALREADY_PLACED
      }
    });
  }
};