function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { TENANT_ORDER_APPROVAL_STATUS } from 'swag-common/constants/order-statuses.constants';
import { recalculateProceededOrder } from 'swag-common/business-logic/order/recalculate-proceeded-order.logic';
import { PREDEFINED_TENANTS } from 'swag-common/constants/main-swag.constants';
import { isItemBlankSampleOrPrintedSample } from './items/is-item-sample.service';

// Helpers

const _useFnOnShipmentCharge = (shipmentCharge, fn, ...params) => {
  const newShipmentCharge = _objectSpread({}, shipmentCharge);
  if (shipmentCharge.upsCharge) {
    newShipmentCharge.upsCharge = fn(shipmentCharge.upsCharge, ...params);
  }
  if (shipmentCharge.totalCharges) {
    newShipmentCharge.totalCharges = fn(shipmentCharge.totalCharges, ...params);
  }
  return newShipmentCharge;
};
const _useFnOnItemShipmentCharges = (item, fn, ...params) => {
  var _item$shipmentCharges;
  return _objectSpread({}, item, {
    shipmentCharges: (_item$shipmentCharges = item.shipmentCharges) === null || _item$shipmentCharges === void 0 ? void 0 : _item$shipmentCharges.map(c => fn(c, ...params)),
    fullBoxShipmentCharge: item.fullBoxShipmentCharge ? fn(item.fullBoxShipmentCharge, ...params) : item.fullBoxShipmentCharge,
    boxShipmentCharge: item.boxShipmentCharge ? fn(item.boxShipmentCharge, ...params) : item.boxShipmentCharge
  });
};
const _useFnOnProductPrices = (product, fn, ...params) => {
  if (!product) {
    return product;
  }
  return _objectSpread({}, product, {
    categoryPrice: fn(product.categoryPrice, ...params),
    price: Array.isArray(product.price) ? product.price.map(priceArr => priceArr.map(arr => arr.map(p => fn(p, ...params)))) : product.price,
    screenPrice: fn(product.screenPrice, ...params),
    basePrice: fn(product.basePrice, ...params),
    printRunMorePrice: product.printRunMorePrice ? fn(product.printRunMorePrice, ...params) : product.printRunMorePrice,
    printRunPrices: Array.isArray(product.printRunPrices) ? product.printRunPrices.map(arr => arr.map(p => fn(p, ...params))) : product.printRunPrices
  }, product.productSettings ? {
    productSettings: product.productSettings.map(settings => _objectSpread({}, settings, {
      screenPrice: fn(settings.screenPrice, ...params),
      printRunMorePrice: settings.printRunMorePrice ? fn(settings.printRunMorePrice, ...params) : settings.printRunMorePrice,
      printRunPrices: Array.isArray(settings.printRunPrices) ? settings.printRunPrices.map(arr => arr.map(p => fn(p, ...params))) : settings.printRunPrices
    }))
  } : {});
};

// Multiplier
const _getMultiplier = multiplier => 1 + multiplier / 100;
const applyMultiplier = (price, multiplier = 0) => Number.isFinite(price) ? Math.round(price * _getMultiplier(multiplier)) : null;
const discardMultiplier = (value, multiplier = 0) => Math.round(parseInt(value, 10) / _getMultiplier(multiplier));
const applyMultiplierToProduct = (product, multiplier = 0) => _useFnOnProductPrices(product, applyMultiplier, multiplier);
const discardMultiplierToProduct = (product, multiplier = 0) => _useFnOnProductPrices(product, discardMultiplier, multiplier);
const applyMultiplierToFacets = (facets, multiplier = 0) => _objectSpread({}, facets, {
  price: {
    global: {
      min: applyMultiplier(facets.price.global.min, multiplier),
      max: applyMultiplier(facets.price.global.max, multiplier)
    }
  }
});
const applyMultiplierToPriceFilters = (price, multiplier = 0) => {
  if (!price) {
    return price;
  }
  const values = price.split(',') || [];
  if (!values.length) {
    return price;
  }
  return values.map(v => discardMultiplier(v, multiplier)).join();
};
const applyMultiplierToFilterRange = (range, multiplier = 0) => _objectSpread({}, range, {
  price: applyMultiplier(range.price, multiplier)
});
const applyMultiplierToShipmentCharge = (shipmentCharge, multiplier = 0) => _useFnOnShipmentCharge(shipmentCharge, applyMultiplier, multiplier);
const applyMultiplierToItemShipmentCharges = (item, multiplier = 0) => _useFnOnItemShipmentCharges(item, applyMultiplierToShipmentCharge, multiplier);
const applyMultiplierToShipmentChargesWithTaxes = (charges, multiplier = 0) => _objectSpread({}, charges, {
  cheapestShipment: applyMultiplierToShipmentCharge(charges.cheapestShipment, multiplier)
});
const applyMultiplierToOrder = (order, multiplier = 0) => _objectSpread({}, order, {
  items: order.items.map(item => _objectSpread({}, item, {
    prod: applyMultiplierToProduct(item.prod, multiplier)
  }))
});

// Reduction
const applyReduction = (price, reduction = 100) => Math.round(price * reduction / 100);

// Tenant charge rate
const calculateTenantChargeRate = ({
  multiplier,
  reduction
}) => {
  const tenantChargeRate = reduction / _getMultiplier(multiplier);
  return Math.round(tenantChargeRate * 100) / 100;
};
const applyTenantChargeRate = (value, tenantChargeRate = 100) => Math.round(value * (tenantChargeRate / 100));
const applyTenantChargeRateToShipmentCharge = (shipmentCharge, tenantChargeRate = 100) => _useFnOnShipmentCharge(shipmentCharge, applyTenantChargeRate, tenantChargeRate);
const applyTenantChargeRateToItemShipmentCharges = (item, tenantChargeRate = 100) => _useFnOnItemShipmentCharges(item, applyTenantChargeRateToShipmentCharge, tenantChargeRate);
const applyTenantChargeRateToProduct = (product, tenantChargeRate = 100) => _useFnOnProductPrices(product, applyTenantChargeRate, tenantChargeRate);
const applyTenantChargeRateToOrder = (order, tenantChargeRate = 100) => _objectSpread({}, order, {
  total: applyTenantChargeRate(order.total, tenantChargeRate),
  items: order.items.map(item => {
    const isSample = isItemBlankSampleOrPrintedSample(item);
    const rateToApply = isSample ? 100 : tenantChargeRate;
    return applyTenantChargeRateToItemShipmentCharges(_objectSpread({}, item, {
      absoluteItemDiscount: applyTenantChargeRate(item.absoluteItemDiscount, rateToApply),
      price: applyTenantChargeRate(item.price, rateToApply),
      setupFeeDeductionDiscount: applyTenantChargeRate(item.setupFeeDeductionDiscount, rateToApply),
      sampleDeductionDiscount: applyTenantChargeRate(item.sampleDeductionDiscount, rateToApply),
      prod: applyTenantChargeRateToProduct(item.prod, rateToApply)
    }), rateToApply);
  })
});
export {
// Multiplier
applyMultiplier, discardMultiplier, discardMultiplierToProduct, applyMultiplierToOrder, applyMultiplierToFacets, applyMultiplierToProduct, applyMultiplierToPriceFilters, applyMultiplierToFilterRange, applyMultiplierToShipmentCharge, applyMultiplierToItemShipmentCharges, applyMultiplierToShipmentChargesWithTaxes,
// Reduction
applyReduction,
// Tenant charge rate
applyTenantChargeRate, calculateTenantChargeRate, applyTenantChargeRateToOrder, applyTenantChargeRateToProduct, calculateTenantChargedAmount };
const calculateTenantChargedAmount = (order, tenantSettings) => {
  const {
    tenantMultiplier,
    tenantReduction,
    tenantOrderApprovalStatus,
    tenant
  } = order;
  if (tenant !== PREDEFINED_TENANTS.POSTAL && tenantOrderApprovalStatus !== TENANT_ORDER_APPROVAL_STATUS.APPROVED && !tenantSettings) {
    return 0;
  }
  const multiplier = tenantMultiplier || (tenantSettings === null || tenantSettings === void 0 ? void 0 : tenantSettings.multiplier) || 0;
  const reduction = tenantReduction || (tenantSettings === null || tenantSettings === void 0 ? void 0 : tenantSettings.reduction) || 100;
  const tenantChargeRate = calculateTenantChargeRate({
    multiplier,
    reduction
  });
  const {
    paymentPrices: {
      grandTotal
    }
  } = recalculateProceededOrder(applyTenantChargeRateToOrder(order, tenantChargeRate));
  return grandTotal;
};