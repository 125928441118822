import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/upload';
const UploadApi = createApi({
  upload: {
    url: `${baseUrl}/`,
    method: 'post'
  },
  uploadInventoryProductImage: {
    url: `${baseUrl}/custom-product-image`,
    method: 'post'
  },
  uploadWarehouseProductImage: {
    url: `${baseUrl}/warehouse-product-image`,
    method: 'post'
  }
});
export default UploadApi;