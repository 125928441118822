import { checkIsSplitShippedToCustomer, checkIsSplitShippedToInventory } from 'swag-common/utils/order/is-item-split-shipped';
export const TOOLTIP_INDIVIDUAL_ITEM_NAME = 'Individual Item';
export const getTooltipItemName = (editedItem, sharedItem, boxName) => {
  const isEditedSplitShippedToCustomer = editedItem && checkIsSplitShippedToCustomer(editedItem);
  const isEditedSplitShippedToInventory = editedItem && checkIsSplitShippedToInventory(editedItem);
  const isSharedSplitShippedToCustomer = checkIsSplitShippedToCustomer(sharedItem);
  const isSharedSplitShippedToInventory = checkIsSplitShippedToInventory(sharedItem);
  if (isEditedSplitShippedToInventory && isSharedSplitShippedToCustomer) {
    return TOOLTIP_INDIVIDUAL_ITEM_NAME;
  }
  if (isEditedSplitShippedToCustomer && isSharedSplitShippedToInventory) {
    return `${TOOLTIP_INDIVIDUAL_ITEM_NAME} (Warehouse)`;
  }
  return boxName;
};