import { createSelector } from 'reselect';
import { isLogoTypeProductSelector } from '../product/product.selectors';
const companyArtFolderRootSelector = state => state.companyArtFolder;
export const logosListSelector = createSelector(companyArtFolderRootSelector, state => state.list.slice(0, 5));
export const fullLogosListSelector = createSelector(companyArtFolderRootSelector, state => state.list);
export const shouldShowCompanyArtFolderSelector = createSelector(logosListSelector, isLogoTypeProductSelector, (list, isLogoTypeProduct) => {
  if (checkIfShouldSkipCompanyArtFolderFlow()) {
    return false;
  }
  return list.length > 0 && isLogoTypeProduct;
});
export const isLogosListLoadingSelector = createSelector(companyArtFolderRootSelector, state => state.loading);
export const isInitialLoadingSelector = createSelector(companyArtFolderRootSelector, fullLogosListSelector, (state, logos) => state.loading && logos.length === 0);
export const isPartLoadingSelector = createSelector(companyArtFolderRootSelector, fullLogosListSelector, (state, logos) => state.loading && logos.length > 0);
export const showMoreBtnSelector = createSelector(companyArtFolderRootSelector, isLogosListLoadingSelector, (state, loading) => !loading && state.pagination.total > 5);
export const selectedLogoViewSelector = createSelector(companyArtFolderRootSelector, state => state.view);
export const isLogosListEmptySelector = createSelector(fullLogosListSelector, isLogosListLoadingSelector, (logos, isLogosListLoading) => !isLogosListLoading && logos.length === 0);
export const showLogosListSelector = createSelector(fullLogosListSelector, logos => logos.length > 0);
export const getCurrentPageSelector = createSelector(companyArtFolderRootSelector, state => state.pagination.page);
export const getPerPageSelector = createSelector(companyArtFolderRootSelector, state => state.pagination.perPage);
export const hasMoreLogosSelector = createSelector(companyArtFolderRootSelector, fullLogosListSelector, (state, logos) => state.pagination.total > logos.length);
export function checkIfShouldSkipCompanyArtFolderFlow() {
  return localStorage.getItem('skipCompanyArtFolderFlow');
}