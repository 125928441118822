import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
export const reloadAndShowNotification = ({
  notificationName
}) => {
  if (window.location.search.includes('utm_source=email')) {
    location.href = `${location.origin}${APP_ROUTES.PROFILE_SETTINGS}/?notification=${notificationName}`;
  } else if (window.location.search) {
    location.href = `${location.href}&notification=${notificationName}`;
  } else {
    location.href = `${location.href}?notification=${notificationName}`;
  }
};