import { createSelector } from 'reselect';
import { getComplexColorInfoByPMS } from 'swag-common/utils/colors';
import { isSuperSpeedItem } from 'swag-common/utils/items/is-item-super-speed.util';
import { capitalize } from 'swag-common/utils/strings';
import { allOrdersSelector, hasMoreItemsToReviewPantoneColorsSelector } from '../account-orders/account-orders.selectors';
import { companyOrdersSelector } from '../company-orders/company-orders.selectors';
export const itemPantonesRootSelector = state => state.itemPantones;
export const logosSelector = createSelector(itemPantonesRootSelector, ({
  logos
}) => logos || []);
export const selectedLogoIdSelector = createSelector(itemPantonesRootSelector, ({
  selectedLogo
}) => selectedLogo);
export const logosIdsSelector = createSelector(itemPantonesRootSelector, ({
  logosIds
}) => logosIds);
export const maxPrintColorsSelector = createSelector(itemPantonesRootSelector, ({
  maxPrintColors
}) => maxPrintColors);
export const selectedOrderIdSelector = createSelector(itemPantonesRootSelector, ({
  orderId
}) => orderId);
export const selectedItemIdSelector = createSelector(itemPantonesRootSelector, ({
  itemId
}) => itemId);
export const selectedOrderSelector = createSelector(selectedOrderIdSelector, allOrdersSelector, companyOrdersSelector, (orderId, orders, companyOrders) => {
  let selectedOrder = orders.find(({
    _id
  }) => _id === orderId);
  if (!selectedOrder) {
    selectedOrder = companyOrders.find(({
      _id
    }) => _id === orderId);
  }
  return selectedOrder;
});
export const selectedItemSelector = createSelector(selectedOrderSelector, selectedItemIdSelector, (order, itemId) => {
  return order.items.find(({
    _id
  }) => _id === itemId);
});
export const productColorSelector = createSelector(selectedItemSelector, item => {
  const variant = item.prod.variants[item.variants[0].variantIndex];
  return variant && variant.color;
});
export const isSuperSpeedSelectedItemSelector = createSelector(selectedItemSelector, item => {
  return isSuperSpeedItem(item);
});
export const itemColorLimitSelector = createSelector(selectedItemSelector, isSuperSpeedSelectedItemSelector, (item, isSuperSpeedItem) => {
  var _item$prod2;
  if (isSuperSpeedItem) {
    var _item$prod, _item$prod$productSet, _item$prod$productSet2;
    return (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : (_item$prod$productSet = _item$prod.productSettings) === null || _item$prod$productSet === void 0 ? void 0 : (_item$prod$productSet2 = _item$prod$productSet[0]) === null || _item$prod$productSet2 === void 0 ? void 0 : _item$prod$productSet2.superSpeed.maxPrintColors;
  }
  return (_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : _item$prod2.maxPrintColors;
});
export const selectedLogoSelector = createSelector(logosSelector, selectedLogoIdSelector, (logos, selectedLogo) => logos[selectedLogo]);
export const productInfoSelector = createSelector(selectedItemSelector, selectedLogoSelector, (item, logo) => {
  var _item$prod$name, _item$prod3, _Object$values$find$f, _Object$values$find, _item$customerMockups;
  const name = (_item$prod$name = (_item$prod3 = item.prod) === null || _item$prod3 === void 0 ? void 0 : _item$prod3.name) !== null && _item$prod$name !== void 0 ? _item$prod$name : '';
  const mockup = (_Object$values$find$f = (_Object$values$find = Object.values((_item$customerMockups = item.customerMockups) !== null && _item$customerMockups !== void 0 ? _item$customerMockups : {}).find(mockup => mockup.location === logo.location && mockup.variantIndex === item.variants[0].variantIndex)) === null || _Object$values$find === void 0 ? void 0 : _Object$values$find.fileName) !== null && _Object$values$find$f !== void 0 ? _Object$values$find$f : '';
  return {
    name,
    mockup
  };
});
export const selectedLogoColorsSelector = createSelector(selectedLogoSelector, logo => (logo.colorsToPrint || []).map(capitalize).map(getComplexColorInfoByPMS));
export const selectedLogoPreviewSelector = createSelector(selectedLogoSelector, logo => logo.preview);
export const isLastLogoSelector = createSelector(logosIdsSelector, selectedLogoIdSelector, (logos, selected) => logos.indexOf(selected) === logos.length - 1);
export const isFirstLogoSelector = createSelector(logosIdsSelector, selectedLogoIdSelector, (logos, selected) => logos.indexOf(selected) === 0);
export const progressMessageSelector = createSelector(logosIdsSelector, selectedLogoIdSelector, (logos, selected) => {
  if (logos.length < 2) {
    return null;
  }
  return `Design ${logos.indexOf(selected) + 1} of ${logos.length}`;
});
export const isAddColorEnabledSelector = createSelector(itemColorLimitSelector, selectedLogoColorsSelector, (maxPrintColors, colors) => {
  if (maxPrintColors) {
    return colors.length >= maxPrintColors ? false : true;
  }
  return true;
});
export const isErrorMessageShown = createSelector(itemColorLimitSelector, selectedLogoColorsSelector, (maxPrintColors, colors) => {
  if (maxPrintColors) {
    return colors.length > maxPrintColors;
  }
  return false;
});
export const isRemoveColorEnabledSelector = createSelector(selectedLogoColorsSelector, colors => (colors === null || colors === void 0 ? void 0 : colors.length) > 1);
export const confirmTextSelector = createSelector(isLastLogoSelector, hasMoreItemsToReviewPantoneColorsSelector, (isLastLogo, hasMoreItemsToReviewPantoneColors) => {
  if (!isLastLogo || hasMoreItemsToReviewPantoneColors) {
    return 'Approve and Continue';
  }
  return 'Approve';
});
export const cancelTextSelector = createSelector(isFirstLogoSelector, isFirstLogo => isFirstLogo ? null : 'Previous Design');