function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { SettingsTypes } from 'swag-common/interfaces';
import { UPDATE_SUPER_SPEED_SUCCESS, GET_SETTINGS_SUCCESS, GET_SETTINGS_HISTORY_SUCCESS, GET_SETTINGS_REQUEST, GET_SETTINGS_FAILURE, UPDATE_DEFAULT_WAREHOUSE_REQUEST, UPDATE_DEFAULT_WAREHOUSE_FAILURE, UPDATE_DEFAULT_WAREHOUSE_SUCCESS, SAVE_SHIPPING_MARGIN_REQUEST, SAVE_SHIPPING_MARGIN_SUCCESS, SAVE_SHIPPING_MARGIN_FAILURE, UPDATE_SHIPPING_MARGIN, GET_REPLACEMENT_PRICE_RANGE_SUCCESS, GET_HUBSPOT_CALENDAR_LINKS_SUCCESS } from './settings.actions';
import { GET_SUPER_SPEED_SUCCESS } from './settings-other.actions';
const initialState = {
  isLoading: false,
  isLoaded: false,
  superSpeed: {
    enabled: false,
    multiplier: 0,
    numOfDays: 1
  },
  replacementPriceRange: null,
  defaultWarehouse: null,
  initialShippingMargin: null,
  shippingMargin: null,
  history: [],
  hubspotCalendarLinks: {}
};
const formatShippingMargin = shippingMargin => Object.keys(shippingMargin).reduce((acc, key) => _objectSpread({}, acc, {
  [key]: shippingMargin[key] * 100
}), {
  domesticShippingMargin: 0,
  internationalShippingMargin: 0,
  taxesAndDutiesMargin: 0
});
export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
    case UPDATE_DEFAULT_WAREHOUSE_REQUEST:
    case SAVE_SHIPPING_MARGIN_REQUEST:
      return _objectSpread({}, state, {
        isLoading: true,
        isLoaded: false
      });
    case GET_SUPER_SPEED_SUCCESS:
    case UPDATE_SUPER_SPEED_SUCCESS:
      {
        const {
          config
        } = action.payload;
        return _objectSpread({}, state, {
          isLoaded: true,
          superSpeed: _objectSpread({}, config)
        });
      }
    case GET_SETTINGS_SUCCESS:
      {
        const defaultWarehouseSettings = action.payload.find(({
          type
        }) => type === SettingsTypes.DefaultWarehouse);
        const shippingMarginSettings = action.payload.find(({
          type
        }) => type === SettingsTypes.ShippingMargin);
        const shippingMargin = formatShippingMargin(shippingMarginSettings.config);
        return _objectSpread({}, state, {
          isLoading: false,
          defaultWarehouse: defaultWarehouseSettings.config.value,
          initialShippingMargin: shippingMargin,
          shippingMargin
        });
      }
    case UPDATE_DEFAULT_WAREHOUSE_SUCCESS:
      return _objectSpread({}, state, {
        isLoading: false,
        defaultWarehouse: action.payload
      });
    case UPDATE_SHIPPING_MARGIN:
      return _objectSpread({}, state, {
        shippingMargin: _objectSpread({}, state.shippingMargin, action.payload)
      });
    case SAVE_SHIPPING_MARGIN_SUCCESS:
      {
        const shippingMargin = formatShippingMargin(action.payload);
        return _objectSpread({}, state, {
          isLoading: false,
          initialShippingMargin: shippingMargin,
          shippingMargin
        });
      }
    case GET_SETTINGS_HISTORY_SUCCESS:
      return _objectSpread({}, state, {
        history: action.payload
      });
    case GET_SETTINGS_FAILURE:
    case UPDATE_DEFAULT_WAREHOUSE_FAILURE:
    case SAVE_SHIPPING_MARGIN_FAILURE:
      return _objectSpread({}, state, {
        isLoading: false
      });
    case GET_REPLACEMENT_PRICE_RANGE_SUCCESS:
      {
        return _objectSpread({}, state, {
          replacementPriceRange: action.payload.config
        });
      }
    case GET_HUBSPOT_CALENDAR_LINKS_SUCCESS:
      {
        return _objectSpread({}, state, {
          hubspotCalendarLinks: action.payload.config
        });
      }
    default:
      return state;
  }
};