import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { isPermissionErrorPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { PermissionErrorPopup as PermissionErrorPopupComponent } from './permission-error-popup';
const mapStateToProps = createStructuredSelector({
  isOpen: isPermissionErrorPopupShownSelector
});
const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(hidePopup());
  }
});
export const PermissionErrorPopup = connect(mapStateToProps, mapDispatchToProps)(PermissionErrorPopupComponent);