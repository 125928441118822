function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { OrderApi } from 'swag-client-common/api/order.api';
import { LOADER_CSV_STATE, PROGRESS_LOADING, SELECT_FULFILLMENT } from './kickstarter-shipping-constants';
const PREFIX = 'KICKSTARTER_SHIPPING_';
export const CHANGE_STEP = `${PREFIX}CHANGE_STEP`;
export const moveToStep = ({
  step
}) => ({
  type: CHANGE_STEP,
  body: step
});
export const applyProposal = () => (dispatch, getState) => {
  const state = getState();
  const tShirts = state.cart.items.filter(item => `${item.prod.type}` === '1');
  if (tShirts.length === 1) {
    dispatch(confirmKSFlowItems(tShirts));
    dispatch(moveToStep({
      step: LOADER_CSV_STATE
    }));
  } else {
    dispatch(moveToStep({
      step: SELECT_FULFILLMENT
    }));
  }
};
export const UPLOAD_CSV_STARTED = `${PREFIX}UPLOAD_CSV_STARTED`;
export const UPLOAD_CSV_SUCCESS = `${PREFIX}UPLOAD_CSV_SUCCESS`;
export const UPLOAD_CSV_FAIL = `${PREFIX}UPLOAD_CSV_FAIL`;
const sendShippingCSVToServer = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: UPLOAD_CSV_STARTED
    });
  },
  fn: OrderApi.uploadShippingCSV,
  success: body => ({
    type: UPLOAD_CSV_SUCCESS,
    body
  }),
  error: err => ({
    type: UPLOAD_CSV_FAIL,
    err,
    error: err
  }),
  postSuccess: dispatch => {
    dispatch(moveToStep({
      step: PROGRESS_LOADING
    }));
  }
});
export const uploadShippingCSV = ({
  file
}) => (dispatch, getState) => {
  const data = new FormData();
  const orderToken = getState().cart.orderToken;
  data.append('shippingCSV', file);
  dispatch(sendShippingCSVToServer(_objectSpread({}, data, {
    orderToken
  })));
};
export const EMULATE_PROGRESS_STEP = `${PREFIX}EMULATE_PROGRESS_STEP`;
export const EMULATE_PROGRESS_DONE = `${PREFIX}EMULATE_PROGRESS_DONE`;
const nextStep = () => ({
  type: EMULATE_PROGRESS_STEP
});
const done = ksFulfillment => ({
  type: EMULATE_PROGRESS_DONE,
  ksFulfillment
});
const progressDone = () => (dispatch, getState) => {
  const {
    kickstarterShipping: {
      parsingShipmentPrice,
      shipmentPrices,
      parsingProgress
    }
  } = getState();
  const ksFulfillment = parsingShipmentPrice + shipmentPrices[parsingProgress];
  dispatch(done(ksFulfillment));
};
export const emulateProgressStep = () => (dispatch, getState) => {
  const {
    kickstarterShipping: {
      parsingProgress,
      validCount
    }
  } = getState();
  if (parsingProgress + 1 < validCount) {
    return dispatch(nextStep());
  }
  dispatch(progressDone());
};
export const APPLY_KS_FLOW_STARTED = `${PREFIX}APPLY_KS_FLOW_STARTED`;
export const APPLY_KS_FLOW_SUCCESS = `${PREFIX}APPLY_KS_FLOW_SUCCESS`;
export const APPLY_KS_FLOW_FAIL = `${PREFIX}APPLY_KS_FLOW_FAIL`;
export const applyKSFlow = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: APPLY_KS_FLOW_STARTED
    });
  },
  fn: OrderApi.applyKSFLow,
  success: body => ({
    type: APPLY_KS_FLOW_SUCCESS,
    body
  }),
  error: err => ({
    type: APPLY_KS_FLOW_FAIL,
    err,
    error: err
  }),
  postSuccess: dispatch => {
    dispatch(hidePopup());
  }
});
export const CONFIRM_KS_FLOW_ITEMS = `${PREFIX}CONFIRM_KS_FLOW_ITEMS`;
export const confirmKSFlowItems = items => ({
  type: CONFIRM_KS_FLOW_ITEMS,
  items
});