function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { isSuperSpeedActiveSelector } from 'swag-client-common/redux/settings/settings.selectors';
import { STATE_LOADING } from '../redux.constants';
import { capitalize } from '../../../../../common/utils/strings';
import { isLimitedEditionSelector, superSpeedProductMaxPrintColorsSelector, variantSelector } from '../product/product.selectors';
import { selectPantonesPopupSelector } from '../../components/popups/select-pantones-popup/connect/select-pantones-popup.selectors';
import { shouldShowCompanyArtFolderSelector } from '../company-art-folder/company-art-folder.selectors';
const WHITE_COLOR = 'white c';
const BLACK_COLOR = 'black c';
const convertToSelector = state => state.logoEditing.convertTo;
export const isSuccessSelector = state => state.logoEditing.isSuccess;
export const logoHandlingProgressSelector = state => state.logoEditing.logoHandlingProgress;
export const logoHandlingStatusSelector = state => state.logoEditing.logoHandlingStatus;
const selectedColorsSelector = state => state.logoEditing.selectedColors;
export const logoLocationSelector = state => state.logoEditing.location;
export const showOverlaySelector = state => state.logoEditing.showOverlay;
export const isRemoveBGCheckboxShown = state => state.logoEditing.hasBackground && !state.logoEditing.convertTo;
const mainProductMaxPrintColorsSelector = state => state.logoEditing.productData.maxPrintColors;
export const maxPrintColorsSelector = createSelector(mainProductMaxPrintColorsSelector, mainProductMaxPrintColors => {
  return mainProductMaxPrintColors;
});
export const actualMaxPrintColorsSelector = createSelector(mainProductMaxPrintColorsSelector, superSpeedProductMaxPrintColorsSelector, isSuperSpeedActiveSelector, (mainProductMaxPrintColors, ssMaxPrintColors, isSuperSpeedActive) => {
  if (isSuperSpeedActive) {
    return ssMaxPrintColors;
  }
  return mainProductMaxPrintColors;
});
export const isMaxPrintColorsNotExceededSelector = createSelector(selectedColorsSelector, actualMaxPrintColorsSelector, (selectedColors, maxPrintColors) => {
  return maxPrintColors === 0 || selectedColors.length === 1 || selectedColors.length < maxPrintColors;
});
export const isRevertButtonShownSelector = createSelector(isMaxPrintColorsNotExceededSelector, convertToSelector, (isMaxPrintColorsNotExceeded, convertTo) => isMaxPrintColorsNotExceeded && !!convertTo);
export const isColorAddingPossibleSelector = createSelector(isMaxPrintColorsNotExceededSelector, convertToSelector, (isMaxPrintColorsNotExceeded, convertTo) => isMaxPrintColorsNotExceeded && !convertTo);
export const logoUploadStatusSelector = createSelector(isSuccessSelector, logoHandlingProgressSelector, logoHandlingStatusSelector, isLimitedEditionSelector, (isSuccess, progress, status, isLimitedEdition) => ({
  isSuccess,
  progress,
  status,
  isLimitedEdition
}));
export const capitalizePantoneNames = colors => colors.map(color => {
  const capitalizedName = capitalize(color.pms);
  return _objectSpread({}, color, {
    pms: capitalizedName
  });
});
export const convertToOneColorPopupSelector = state => {
  const {
    logoEditing: {
      preview,
      selectedColors,
      convertTo
    }
  } = state;
  const {
    color: variantColor
  } = variantSelector(state) || {};
  const colors = selectedColors.slice();
  const whiteColorAbsent = colors.every(color => color.pms !== WHITE_COLOR);
  const blackColorAbsent = colors.every(color => color.pms !== BLACK_COLOR);
  if (whiteColorAbsent) {
    colors.unshift({
      pms: WHITE_COLOR,
      hex: '#ffffff',
      id: 'white'
    });
  }
  if (blackColorAbsent) {
    colors.unshift({
      pms: BLACK_COLOR,
      hex: '#000000',
      id: 'black'
    });
  }
  const capitalizedColors = capitalizePantoneNames([...colors]);
  return {
    loading: state.logoEditing.state === STATE_LOADING,
    variantColor,
    colors: capitalizedColors,
    convertTo,
    isButtonDisabled: state.logoEditing.state === STATE_LOADING,
    isRevertButtonShown: isRevertButtonShownSelector(state) && convertTo,
    isLimitedEdition: isLimitedEditionSelector(state),
    preview
  };
};
export const maxPrintColorsPopupSelector = state => {
  const {
    logoEditing: {
      selectedColors
    },
    product: {
      view
    }
  } = state;
  const isLimitedEdition = isLimitedEditionSelector(state);
  // @ts-ignore
  const actualMaxPrintColors = actualMaxPrintColorsSelector(state);
  const shouldShowCompanyArtFolder = shouldShowCompanyArtFolderSelector(state);
  return _objectSpread({}, selectPantonesPopupSelector(state, {
    isDisabled: false
  }), {
    view: view,
    maxPrintColors: actualMaxPrintColors,
    isLimitedEdition,
    isButtonDisabled: selectedColors.length > actualMaxPrintColors,
    shouldShowCompanyArtFolder
  });
};
export const logoToUploadViewSelector = state => state.logoEditing.logoToUploadView;
export const logoToUploadFileSelector = state => state.logoEditing.logoToUploadFile;