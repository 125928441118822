function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createApi } from 'swag-client-common/libs/rapi';
import { ApprovalUrl } from 'swag-common/utils/urls/approval.url';
import creditsApi, { baseUrl, customStoreBaseUrl } from 'swag-client-common/api/inventory.api';
const inventoryApiGeneral = createApi({
  create: {
    url: baseUrl,
    method: 'post'
  },
  update: {
    url: baseUrl,
    method: 'put'
  },
  list: {
    url: baseUrl,
    method: 'get'
  },
  listProducts: {
    url: '/custom-store-api/v1/product/inventory/:inventoryId',
    method: 'get'
  },
  removeProduct: {
    url: `${baseUrl}/:inventoryId/products/:productId/remove`,
    method: 'post'
  },
  listShipouts: {
    url: `${baseUrl}/shipouts`,
    method: 'get'
  },
  getUsers: {
    url: `${baseUrl}/:inventoryId/users`,
    method: 'get'
  },
  updatePermissions: {
    url: `${baseUrl}/users/permissions`,
    method: 'put'
  },
  removeUser: {
    url: `${baseUrl}/users/delete`,
    method: 'post'
  },
  removeInvite: {
    url: `${baseUrl}/users/invite`,
    method: 'put'
  },
  addColorsToProduct: {
    url: `${baseUrl}/add-colors-to-product`,
    method: 'put'
  },
  last: {
    url: `${baseUrl}/last`,
    method: 'get'
  },
  setActive: {
    url: `${baseUrl}/set-active`,
    method: 'post'
  },
  history: {
    url: `${baseUrl}/history`,
    method: 'get'
  },
  getCommonCards: {
    url: `${baseUrl}/:inventoryId/payment-method/common`,
    method: 'get'
  },
  approval: {
    url: `${baseUrl}/:inventoryId/approval`,
    method: 'get'
  },
  updateApproval: {
    url: `${baseUrl}/:inventoryId/approval`,
    method: 'put'
  },
  getApprovalDistributions: {
    url: `${baseUrl}/:inventoryId/approval/distributions`,
    method: 'get'
  },
  hasPendingDistributions: {
    url: `${baseUrl}/:inventoryId/approval/has-pending`,
    method: 'get'
  },
  approveDistribution: {
    url: `${customStoreBaseUrl}${ApprovalUrl.approveDistribution(':inventoryId', ':id')}`,
    method: 'post'
  },
  rejectDistribution: {
    url: `${customStoreBaseUrl}${ApprovalUrl.rejectDistribution(':inventoryId', ':id')}`,
    method: 'post'
  },
  getInProductionData: {
    url: `${baseUrl}/:inventoryId/product/:productId/get-in-production`,
    method: 'get'
  },
  getBundleInStockDateSimple: {
    url: `${baseUrl}/:inventoryId/product/bundle-in-stock-simple`,
    method: 'get'
  },
  markUserAsDemoShown: {
    url: `${baseUrl}/set-demo-shown`,
    method: 'put'
  },
  saveCustomImages: {
    url: `${baseUrl}/:inventoryId/product/:productId/set-custom-images`,
    method: 'put'
  },
  getStatus: {
    url: `${baseUrl}/status`,
    method: 'get'
  }
});
const inventoryApiManagement = createApi({
  deleteInventory: {
    url: `${baseUrl}/:inventoryId`,
    method: 'delete'
  },
  getPendingActivities: {
    url: `${baseUrl}/pending-activities/:inventoryId`,
    method: 'get'
  }
}, {
  replaceUnnecessaryFields: true
});
const baseGiveawayUrl = `${baseUrl}/:inventoryId/giveaway`;
const giveawayApi = createApi({
  saveForLater: {
    url: `${baseGiveawayUrl}/save-for-later/:giveawayId`,
    method: 'post'
  },
  setCompleted: {
    url: `${baseGiveawayUrl}/complete/:giveawayId`,
    method: 'post'
  },
  saveDraft: {
    url: `${baseGiveawayUrl}/save-draft/:giveawayId`,
    method: 'post'
  },
  clearDraft: {
    url: `${baseGiveawayUrl}/clear-draft/:giveawayId`,
    method: 'post'
  },
  getById: {
    url: `${baseGiveawayUrl}/:giveawayId`,
    method: 'get'
  },
  removeById: {
    url: `${baseGiveawayUrl}/:giveawayId`,
    method: 'delete'
  },
  getDraft: {
    url: `${baseGiveawayUrl}/draft`,
    method: 'get'
  },
  getCompleted: {
    url: `${baseGiveawayUrl}/campaigns`,
    method: 'get'
  },
  getCompletedGiveawayById: {
    url: `${baseGiveawayUrl}/campaigns/:giveawayId`,
    method: 'get'
  },
  getFeedback: {
    url: `${baseGiveawayUrl}/:giveawayId/feedback`,
    method: 'get'
  },
  getChargeLinks: {
    url: `${baseGiveawayUrl}/:giveawayId/charge-links/`,
    method: 'get'
  },
  getLast: {
    url: `${baseGiveawayUrl}/last-owned`,
    method: 'get'
  },
  createSingleLink: {
    url: `${baseGiveawayUrl}/:giveawayId/link`,
    method: 'post'
  },
  editCustomSingleLink: {
    url: `${baseGiveawayUrl}/:giveawayId/link/:id`,
    method: 'put'
  },
  getLinks: {
    url: `${baseGiveawayUrl}/:giveawayId/link`,
    method: 'get'
  },
  endGiveaway: {
    url: `${baseGiveawayUrl}/:giveawayId/end`,
    method: 'post'
  },
  getPendingLinks: {
    url: `${baseGiveawayUrl}/:giveawayId/links/pending/`,
    method: 'get'
  },
  getPayedLinks: {
    url: `${baseGiveawayUrl}/:giveawayId/links/payed`,
    method: 'get'
  },
  payFees: {
    url: `${baseGiveawayUrl}/:giveawayId/pay-fees`,
    method: 'post'
  },
  updateAutopay: {
    url: `${baseGiveawayUrl}/:giveawayId/autopay`,
    method: 'post'
  },
  outOfStock: {
    url: `${baseGiveawayUrl}/:giveawayId/out-of-stock`,
    method: 'get'
  },
  getCompletedLinks: {
    url: `${baseGiveawayUrl}/:giveawayId/links/completed/`,
    method: 'get'
  },
  removeLink: {
    url: `${baseGiveawayUrl}/:giveawayId/link/:id`,
    method: 'delete'
  },
  getShipments: {
    url: `${baseGiveawayUrl}/shipments`,
    method: 'get'
  },
  getPrices: {
    url: `${baseGiveawayUrl}/:giveawayId/links/prices`,
    method: 'post'
  },
  searchOnPopup: {
    url: `${baseGiveawayUrl}/:giveawayId/links/search`,
    method: 'get'
  },
  filterPayFees: {
    url: `${baseGiveawayUrl}/:giveawayId/links/filter`,
    method: 'get'
  }
}, {
  replaceUnnecessaryFields: true
});
const giveawayFileUploadCreator = (inventoryId, giveawayId) => createApi({
  uploadLinksCsv: {
    url: `${baseGiveawayUrl}/:giveawayId/link-csv`.replace(':inventoryId', inventoryId).replace(':giveawayId', giveawayId),
    method: 'post'
  }
});
export const InventoryApi = _objectSpread({}, inventoryApiGeneral, inventoryApiManagement, {
  credits: _objectSpread({}, creditsApi),
  giveaway: _objectSpread({}, giveawayApi, {
    giveawayFileUploadCreator
  })
});