export const PERIODS = [{
  label: 'Last Week',
  value: 3600 * 24 * 7 * 1000
}, {
  label: 'Last 30 Days',
  value: 3600 * 24 * 30 * 1000
}, {
  label: 'Past 6 Months',
  value: 3600 * 24 * 30 * 6 * 1000
}, {
  label: 'All',
  value: Infinity
}];