import pickSkuForLineItem from 'swag-common/business-logic/order/pick-sku-for-line-item.logic';
export const getProductKey = (id, position) => {
  return `${id}:${position}`;
};
export const getProductId = productKey => productKey.split(':')[0];
export const getBoxProducts = (customProducts, swagProduct) => {
  return customProducts.map(product => {
    const productInBox = swagProduct.products.find(_product => String(_product._id) === String(product._id));
    if (productInBox) {
      return product;
    }
    return null;
  }).filter(Boolean);
};
export const getOrderMoreItemSku = product => {
  const pickedSku = pickSkuForLineItem(product, [{
    variantIndex: product.variantInd
  }]);
  return pickedSku || product.name || 'Full design product';
};
export const getStandaloneQuantity = (quantities, quantityPerBox, product, boxQuantity) => {
  const {
    minQuant
  } = product;
  const totalBasedOnVariant = quantities.reduce((sum, quantity) => sum + quantity, 0);
  const requestedQuantityForBox = boxQuantity * quantityPerBox;
  if (minQuant === totalBasedOnVariant && totalBasedOnVariant === requestedQuantityForBox) {
    return 0;
  }
  if (minQuant > requestedQuantityForBox && minQuant > totalBasedOnVariant) {
    return minQuant - requestedQuantityForBox;
  }
  return totalBasedOnVariant - requestedQuantityForBox;
};