function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { getVariantWithNearestColor } from 'swag-common/utils/product/get-nearest-color-variant.util';
import applyQuantityToProduct from 'swag-common/utils/product/apply-quantity-to-product.util';
import applyQuantityToSingleVariant from 'swag-common/utils/product/apply-quantity-to-variant.util';
import { getProductOutOfStockThreshold } from 'swag-common/utils/product/product.utils';
import ProductApi from '../../api/product.api';
import SizeApi from '../../api/size.api';
import { sizeLoadedSelector, sizeIndexSelector, sizeSelector, minimumQuantitySelector, splitOrderModeSelector, selectedVariantsSelector, activeProductImageSelector, selectedVariantsInStockLevelsSelector, productSelector } from './related-product.selectors';
const PREFIX = 'RELATED_PRODUCT_';
export const OPEN = `${PREFIX}OPEN`;
export const openRelatedProduct = product => (dispatch, getState) => {
  const state = getState();
  const variant = getVariantWithNearestColor(product.variants, state.product.product.variants[state.product.variantIndex].color);
  dispatch({
    type: OPEN,
    payload: {
      product,
      selectedVariants: [variant.index],
      variantIndex: variant.index,
      productLoaded: true
    }
  });
};
export const REBUILD = `${PREFIX}REBUILD`;
export const rebuildRelatedProduct = product => ({
  type: REBUILD,
  payload: {
    product: _objectSpread({}, product, {
      productLoaded: true
    })
  }
});
export const RESET_POPUP_MODE = `${PREFIX}RESET_POPUP_MODE`;
export const resetPopupMode = () => ({
  type: RESET_POPUP_MODE
});
export const FETCH_SIZE_SUCCESS = `${PREFIX}FETCH_SIZE_SUCCESS`;
export const FETCH_SIZE_FAIL = `${PREFIX}FETCH_SIZE_FAIL`;
export const fetchSize = createAsyncAction({
  fn: SizeApi.getById,
  success: body => ({
    type: FETCH_SIZE_SUCCESS,
    size: body.size
  }),
  error: errorAction(FETCH_SIZE_FAIL),
  postSuccess: (dispatch, getState) => {
    const state = getState();
    const minQuant = minimumQuantitySelector(state);
    if (state.relatedProduct.quantities[0] && state.relatedProduct.quantities[0].length === 0) {
      const defaultQuantity = Math.ceil(minQuant / 100) * 100;
      dispatch(handleGeneralQuantityChange(defaultQuantity));
    }
  }
});
export const init = () => (dispatch, getState) => {
  const state = getState();
  const {
    relatedProduct: {
      product: {
        size,
        hasSize
      }
    }
  } = state;
  if (hasSize) {
    dispatch(fetchSize({
      id: size._id || size
    }));
  } else {
    dispatch({
      type: FETCH_SIZE_SUCCESS
    });
    const minQuant = minimumQuantitySelector(state);
    const defaultQuantity = Math.ceil(minQuant / 100) * 100;
    dispatch(handleGeneralQuantityChange(defaultQuantity));
  }
};
export const CHANGE_VARIANT = `${PREFIX}CHANGE_VARIANT`;
export const ADD_SELECTED_VARIANT = `${PREFIX}ADD_SELECTED_VARIANT`;
export const selectVariant = variantIndex => (dispatch, getState) => {
  const state = getState();
  const splitOrderMode = splitOrderModeSelector(state);
  const minQuant = minimumQuantitySelector(state);
  const activeImage = activeProductImageSelector(state);
  if (splitOrderMode) {
    dispatch({
      type: ADD_SELECTED_VARIANT,
      payload: {
        variantIndex
      }
    });
    dispatch(handleVariantGeneralQuantityChange(minQuant, variantIndex));
  } else {
    dispatch({
      type: CHANGE_VARIANT,
      payload: {
        variantIndex
      }
    });
    const defaultQuantity = Math.ceil(minQuant / 100) * 100;
    dispatch(handleGeneralQuantityChange(defaultQuantity));
  }
  if (!activeImage.isImageWithImprintArea) {
    dispatch(changeView('front'));
  }
};
export const UNSELECT_VARIANT = `${PREFIX}UNSELECT_VARIANT`;
export const unselectColor = variantIndex => (dispatch, getState) => {
  const state = getState();
  const splitOrderMode = splitOrderModeSelector(state);
  if (!splitOrderMode) {
    return;
  }
  dispatch({
    type: UNSELECT_VARIANT,
    payload: {
      variantIndex
    }
  });
};
export const TOGGLE_SPLIT_ORDER_OFF = `${PREFIX}TOGGLE_SPLIT_ORDER_OFF`;
export const TOGGLE_SPLIT_ORDER_ON = `${PREFIX}TOGGLE_SPLIT_ORDER_ON`;
export const confirmSplitOrder = splitOrderMode => dispatch => {
  if (splitOrderMode) {
    dispatch({
      type: TOGGLE_SPLIT_ORDER_ON
    });
  } else {
    dispatch({
      type: TOGGLE_SPLIT_ORDER_OFF
    });
  }
};
export const CHANGE_TIME = `${PREFIX}CHANGE_TIME`;
export const changeTime = time => ({
  type: CHANGE_TIME,
  time
});
export const CHANGE_VIEW = `${PREFIX}CHANGE_VIEW`;
export const changeView = view => ({
  type: CHANGE_VIEW,
  view
});
export const CHANGE_PREVIEW = `${PREFIX}CHANGE_PREVIEW`;
export const changePreview = view => ({
  type: CHANGE_PREVIEW,
  view
});
export const CHANGE_SIZE_INDEX = `${PREFIX}CHANGE_SIZE_INDEX`;
export const changeSizeIndex = index => ({
  type: CHANGE_SIZE_INDEX,
  payload: index
});
export const RESET_REDUCER = `${PREFIX}RESET_REDUCER`;
export const resetReducer = () => ({
  type: RESET_REDUCER
});
export const CHANGE_QUANTITIES = `${PREFIX}CHANGE_QUANTITIES`;
export const handleGeneralQuantityChange = quantity => (dispatch, getState) => {
  const state = getState();
  const sizeLoaded = sizeLoadedSelector(state);
  if (!sizeLoaded) {
    return;
  }
  const size = sizeSelector(state);
  const selectedVariants = selectedVariantsSelector(state);
  const selectedSizeIndex = sizeIndexSelector(state);
  const product = productSelector(state);
  const selectedVariantsInStockLevels = selectedVariantsInStockLevelsSelector(state);
  const quantities = applyQuantityToProduct({
    threshold: getProductOutOfStockThreshold(product),
    product,
    quantity,
    selectedVariants,
    selectedSizeIndex,
    sizeSettings: size,
    selectedVariantsInStockLevels
  });
  dispatch({
    type: CHANGE_QUANTITIES,
    payload: {
      quantities
    }
  });
};
export const handleVariantGeneralQuantityChange = (quantity, variantIndex) => (dispatch, getState) => {
  const state = getState();
  const selectedSizeIndex = sizeIndexSelector(state);
  const size = sizeSelector(state);
  const product = productSelector(state);
  const selectedVariantsInStockLevels = selectedVariantsInStockLevelsSelector(state);
  const quantities = applyQuantityToSingleVariant({
    threshold: getProductOutOfStockThreshold(product),
    product,
    quantity,
    selectedSizeIndex,
    size,
    selectedVariantsInStockLevels
  });
  return dispatch(changeSingleVariantQuantities(quantities, variantIndex));
};
export const CHANGE_SINGLE_VARIANT_QUANTITIES = `${PREFIX}CHANGE_SINGLE_VARIANT_QUANTITIES`;
export const changeSingleVariantQuantities = (quantities, variantIndex) => ({
  type: CHANGE_SINGLE_VARIANT_QUANTITIES,
  payload: {
    variantIndex,
    quantities
  }
});
export const CHANGE_SINGLE_QUANTITY = `${PREFIX}CHANGE_SINGLE_QUANTITY`;
export const changeSingleQuantity = (quantity, variantIndex, sizeIndex) => ({
  type: CHANGE_SINGLE_QUANTITY,
  payload: {
    variantIndex,
    quantity,
    sizeIndex
  }
});
export const CHANGE_FULL_SCREEN_MODE = `${PREFIX}CHANGE_FULL_SCREEN_MODE`;
export const changeFullScreenMode = () => ({
  type: CHANGE_FULL_SCREEN_MODE
});
export const CHANGE_MORE_IMAGE_MODE = `${PREFIX}CHANGE_MORE_IMAGE_MODE`;
export const changeMoreImageMode = () => ({
  type: CHANGE_MORE_IMAGE_MODE
});
export const CHANGE_METHOD_INDEX = `${PREFIX}CHANGE_METHOD_INDEX`;
export const changeMethodIndex = index => ({
  type: CHANGE_METHOD_INDEX,
  payload: {
    index
  }
});
export const GET_FONTS_SUCCESS = `${PREFIX}GET_FONTS_SUCCESS`;
export const GET_FONTS_FAILURE = `${PREFIX}GET_FONTS_FAILURE`;
export const getFonts = createAsyncAction({
  fn: ProductApi.getFonts,
  success: data => ({
    type: GET_FONTS_SUCCESS,
    payload: data
  }),
  error: errorAction(GET_FONTS_FAILURE)
});