const isSharedItem = ({
  prodId,
  asRelatedItemId,
  _id
}, items) => {
  if (!asRelatedItemId || !prodId) {
    return false;
  }
  return items === null || items === void 0 ? void 0 : items.some(item => item.prodId === prodId && item.asRelatedItemId === asRelatedItemId && (!_id || !item._id || String(item._id) !== String(_id)));
};
export default isSharedItem;