function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { autoReplenishInventoryFeatureEnabledSelector } from 'swag-client-common/redux/feature-toggles/feature-toggles.selectors';
import { popupParamsSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { checkIsApparelInBundle } from 'swag-client-common/utils/product.utils';
import customStoreProductToCustomProduct from 'swag-common/business-logic/custom-store-product/custom-store-product-to-custom-product.logic';
import isProductLowOnStock from 'swag-common/business-logic/custom-store-product/is-product-low-on-stock.logic';
import isProductOutOfStock from 'swag-common/business-logic/custom-store-product/is-product-out-of-stock.logic';
import mapRelationalBoxData from 'swag-common/business-logic/custom-store-product/map-relational-box-data.logic';
import { isProductInOngoingGiveaways } from 'swag-common/business-logic/giveaway/is-product-in-ongoing-giveaways';
import { getBoxSizeVariantSet } from 'swag-common/business-logic/products/get-box-size-set.logic';
import getUniqueSizeSet from 'swag-common/business-logic/products/get-unique-size-set.logic';
import { isProductNotificationEnabled } from 'swag-common/business-logic/products/is-product-notification-enabled.logic';
import { DEFAULT_CATEGORY_ID } from 'swag-common/constants/custom-store.constants';
import { InReservePopupTypes } from 'swag-common/constants/product.constants';
import { SwagBundlesStep } from 'swag-common/constants/swag-bundles.constants';
import { getVariantUniqueColorWithSizes } from 'swag-common/utils/custom-store-product/get-variants-unique-colors-with-sizes.util';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import { bundleInProductionCalculationLogic, bundleInStockCalculationLogic, getBundleQuantitiesForApparelContent, isBundle, isBundle as isBundleUtil } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { checkIsCustomProductForbiddenToShipInternationally, getCustomProductsForbiddenToShipInternationally } from 'swag-common/utils/order/validate-order-for-international-shipping.util';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { generateProductName } from 'swag-common/utils/distributions/generate-product-name.util';
import { mapProductsByName } from 'swag-common/utils/distributions/map-products-by-name.util';
import { getInHandDateFormatted, isGiveawayNotecard } from 'swag-common/utils/order';
import { checkIsProductAvailableForBundleCreation } from '../../utils/check-product-available-for-bundle.utils';
import { canDistributeFromInventorySelector, isInventoryAdminSelector, isLoadedInventoryEnteredSelector, pendingActivitiesInSelectedInventory, selectedFullInventorySelector } from '../inventory/inventory.selectors';
import { getVariantIndexesInCustomStore } from '../order-more/order-more.selectors.utils';
//todo remove cross bundle imports
import { isProductAvailableForAddingToGiveaway } from '../../../giveaway/redux/giveaway-landing/utils/is-product-available-for-adding-to-giveaway';
import { getReserveInformationByColor, sizesAndQuantityPerColorSelector, sortProductsBySizes } from './products.selectors.utils';
export const rootProductsSelector = state => state.products;
export const swagBundlesActiveStepSelector = state => state.swagBundles.step.activeStep;
export const isProductsLoadedSelector = createSelector(rootProductsSelector, products => products.isProductsLoaded);
export const isProductsLoadedWithEnteredSelector = createSelector(isProductsLoadedSelector, isLoadedInventoryEnteredSelector, (isLoadedProducts, isLoadedEntered) => isLoadedProducts && isLoadedEntered);
export const productsListSelector = createSelector(rootProductsSelector, products => products.list);
export const isMoreOrderedSelector = createSelector(rootProductsSelector, products => products.isMoreOrdered);
export const productsStoreListSelector = createSelector(rootProductsSelector, products => products.storeList);
export const productIdToManageSelector = createSelector(rootProductsSelector, products => {
  return products.productIdToManage;
});
export const productsSelector = createSelector(productsListSelector, productsStoreListSelector, (list, storeList) => list.length > 0 ? list : storeList.map(mapRelationalBoxData).map(customStoreProductToCustomProduct));
const productIdFromPropsSelector = (_, props) => props.productId;
export const productByProductIdSelector = createSelector(productIdFromPropsSelector, productsSelector, (productId, inventoryProducts) => {
  return inventoryProducts.find(({
    _id
  }) => _id === productId);
});
export const storeProductsSelector = createSelector(productsStoreListSelector, storeList => {
  return storeList.map(mapRelationalBoxData);
});
export const productsIdsSelector = createSelector(productsSelector, selectedFullInventorySelector, swagBundlesActiveStepSelector, (products, inventory, swagBundlesActiveStep) => {
  if (!inventory) {
    return [];
  }
  const {
    removedProductsIds = []
  } = inventory;
  const isSwagBundlesCreationFlow = swagBundlesActiveStep === SwagBundlesStep.selectProducts;
  return products.filter(product => {
    const {
      variants,
      swagProductId
    } = product;
    if (isGiveawayNotecard(swagProductId)) {
      return false;
    }
    if (isSwagBundlesCreationFlow && !checkIsProductAvailableForBundleCreation(product)) {
      return false;
    }
    const visibleVariants = variants.filter(v => !removedProductsIds.includes(String(v._id)));
    return visibleVariants.length !== 0;
  }).map(({
    _id
  }) => _id.toString());
});
export const nonDeletedProductsSelector = createSelector(productsSelector, productsIdsSelector, (products, ids) => products.filter(product => ids.includes(String(product._id))));
export const selectedProductsIdsSelector = createSelector(rootProductsSelector, products => products.selectedProducts.filter(Boolean));
export const selectedProductsSelector = createSelector(productsSelector, selectedProductsIdsSelector, (products, selectedProductsIds) => products.filter(({
  _id
}) => selectedProductsIds.includes(_id.toString())));
export const selectedBundlesSelector = createSelector(selectedProductsSelector, selectedProducts => selectedProducts.filter(product => isBundle(product.variants[0])));
export const selectedStoreProductsSelector = createSelector(storeProductsSelector, selectedProductsIdsSelector, (products, selectedProductsIds) => products.filter(({
  productData: {
    _id
  }
}) => selectedProductsIds.includes(_id.toString())));
export const areSomeProductsSelectedSelector = createSelector(selectedProductsSelector, selectedProducts => selectedProducts.length > 0);

// TODO check usages of this selector
export const selectedProductIdSelector = createSelector(rootProductsSelector, products => products.selectedProducts[0]);
export const selectedColorsSelector = createSelector(rootProductsSelector, products => products.selectedColors);
export const numberOfProductsSelector = createSelector(productsSelector, products => products.length);
export const numberOfSelectedProductsSelector = createSelector(selectedProductsIdsSelector, selectedProducts => selectedProducts.length);
export const productIdSelector = (_, {
  productId
}) => productId;
const singleProductResult = (productId, products, productIdToManage) => {
  return products.find(({
    _id
  }) => productId === _id) || products.find(({
    _id
  }) => productIdToManage === _id);
};
export const singleProductSelector = createSelector(productIdSelector, productsSelector, productIdToManageSelector, singleProductResult);
export const singleProductSelectorByProductId = createSelector(productIdSelector, productsSelector, productIdToManageSelector, (productId, products, productIdToManage) => singleProductResult(productId, products, productIdToManage));
export const singleStoreProductSelector = createSelector(productIdSelector, storeProductsSelector, productIdToManageSelector, singleProductResult);
export const isSingleProductSelectedSelector = (state, {
  productId
}) => {
  const selectedProducts = selectedProductsIdsSelector(state);
  return selectedProducts.includes(productId);
};
export const isSingleProductHasSizeSelector = createSelector(singleProductSelector, product => {
  var _product$swagProductI;
  return ((_product$swagProductI = product.swagProductId) === null || _product$swagProductI === void 0 ? void 0 : _product$swagProductI.hasSize) || product.sizeSettings || product.variants.length > 1 && isBox(product.swagProductId);
});
export const singleProductSizeSelector = createSelector(singleProductSelector, ({
  sizeSettings
}) => sizeSettings);
export const singleProductSingleColorVariantsSelector = (state, props) => {
  const {
    selectedColor
  } = props;
  const singleProduct = singleProductSelector(state, props);
  return singleProduct.variants.filter(({
    colorValue
  }) => colorValue === selectedColor);
};
export const singleBoxProductSizeSelector = createSelector(singleProductSelector, ({
  variants,
  swagProductId
}) => {
  if (!isBox(swagProductId)) {
    return [];
  }
  const [{
    boxContent
  }] = variants;
  const sizeSet = getUniqueSizeSet(boxContent.map(content => {
    const {
      product
    } = content;
    return _objectSpread({}, product, {
      sizeId: String(product.sizeIndex),
      hasSize: product.swagProductId.hasSize,
      sizeDependentPrice: product.swagProductId.sizeDependentPrice,
      productSize: product.sizeSettings
    });
  }));
  return Object.keys(sizeSet);
});
// selector which selects proper size for sizeDependentPrice = true products, such products have size, but only one size can be purchased at a time
export const singleProductPossibleSizesSelector = createSelector(isSingleProductHasSizeSelector, singleProductSizeSelector, singleProductSingleColorVariantsSelector, singleBoxProductSizeSelector, (hasSize, size, selectedColorVariants, boxOptions) => {
  if (hasSize && !size && boxOptions.length) {
    return {
      options: boxOptions
    };
  }
  if (!hasSize || !size) {
    return {
      options: []
    };
  }
  const onlyPossibleSettings = size.options.filter((option, index) => {
    return selectedColorVariants.some(({
      sizeIndex
    }) => sizeIndex === index);
  });
  return _objectSpread({}, size, {
    options: onlyPossibleSettings
  });
});
export const singleProductQuantityPerSizeEnabledSelector = createSelector(singleProductPossibleSizesSelector, singleProductSelector, ({
  options
}, {
  swagProductId
}) => {
  return options.length > 1 && (!(swagProductId !== null && swagProductId !== void 0 && swagProductId.sizeDependentPrice) || isBox(swagProductId));
});
export const getUniqueColorsOfProduct = product => {
  if (!product) {
    return [];
  }
  const {
    variants
  } = product;
  const uniqueColorsMap = variants.reduce((colorsMap, variant) => {
    const {
      colorValue,
      colorName,
      images = [],
      quantity = 0,
      inProductionQuantity = 0,
      inReserveQuantity = 0
    } = variant;
    return _objectSpread({}, colorsMap, {
      [colorValue]: {
        images,
        colorValue,
        colorName,
        inStock: (colorsMap[colorValue] ? colorsMap[colorValue].inStock : 0) + quantity,
        inProduction: (colorsMap[colorValue] ? colorsMap[colorValue].inProduction : 0) + inProductionQuantity,
        inReserveQuantity: (colorsMap[colorValue] ? colorsMap[colorValue].inReserveQuantity : 0) + inReserveQuantity
      }
    });
  }, {});
  return Object.keys(uniqueColorsMap).map(colorValue => uniqueColorsMap[colorValue]);
};
const getColorsByVariants = (variants, withSeparateQuantity = false) => {
  return variants.reduce((colorsMap, {
    colorValue,
    colorVariantId,
    colorName,
    images,
    quantity,
    inProductionQuantity,
    sizeIndex,
    sizeSettings,
    name,
    defaultImage,
    inReserveQuantity,
    parentId: productId,
    _id
  }) => {
    const color = colorsMap[colorValue];
    const isColorExists = !!color;
    const quantityResult = isColorExists ? colorsMap[colorValue].quantity + (quantity || 0) : quantity || 0;
    let reservedInformation;
    if (withSeparateQuantity) {
      const size = (sizeSettings === null || sizeSettings === void 0 ? void 0 : sizeSettings.options[sizeIndex]) || null;
      reservedInformation = getReserveInformationByColor({
        product: colorsMap[colorValue],
        size,
        quantity,
        quantityResult,
        inProductionQuantity,
        inReserveQuantity,
        isColorExists
      });
    }
    return _objectSpread({}, colorsMap, {
      [colorValue]: _objectSpread({
        colorValue,
        colorVariantId,
        colorName,
        images,
        quantity: quantityResult,
        name,
        defaultImage,
        productId
      }, withSeparateQuantity && _objectSpread({}, reservedInformation), {
        _id
      })
    });
  }, {});
};
const getColorsByVariantsBox = (variants, withSeparateQuantity = false) => {
  return variants.reduce((acc, product, currentIndex) => {
    const {
      colorValue,
      colorName,
      images,
      quantity,
      inProductionQuantity,
      inReserveQuantity,
      name,
      colorVariantId,
      parentId: productId,
      _id
    } = product;
    const color = acc[colorValue];
    const isColorExists = !!color;
    const quantityResult = isColorExists ? acc[colorValue].quantity + (quantity || 0) : quantity || 0;
    const sizes = Object.keys(getBoxSizeVariantSet(product));
    const size = sizes[currentIndex];
    let reservedInformation = {};
    if (withSeparateQuantity) {
      reservedInformation = getReserveInformationByColor({
        product: acc[colorValue],
        size,
        quantity,
        quantityResult,
        inProductionQuantity,
        inReserveQuantity,
        isColorExists
      });
    }
    return _objectSpread({}, acc, {
      [colorValue]: _objectSpread({
        colorValue,
        colorName,
        colorVariantId,
        images,
        name,
        quantity: quantityResult,
        productId: String(productId)
      }, withSeparateQuantity && _objectSpread({}, reservedInformation), {
        _id
      })
    });
  }, {});
};
const getColorsByBundle = (variants, withSeparateQuantity = false) => {
  var _bundleVariant$bundle;
  const bundleVariant = variants[0];
  const {
    colorValue,
    colorName,
    defaultImage,
    _id,
    parentId: productId
  } = bundleVariant;
  const bundleContentApparelsOnly = [];
  const bundleContentWithoutApparels = [];
  (_bundleVariant$bundle = bundleVariant.bundleContent) === null || _bundleVariant$bundle === void 0 ? void 0 : _bundleVariant$bundle.forEach(content => {
    if (checkIsApparelInBundle(content.product)) {
      bundleContentApparelsOnly.push(content);
    } else {
      bundleContentWithoutApparels.push(content);
    }
  });
  const apparelsCommonQuantity = getBundleQuantitiesForApparelContent(bundleContentApparelsOnly);
  const apparelsInStockQuantity = apparelsCommonQuantity.reduce((acc, variant) => acc + variant.quantity, 0);
  const apparelsInProductionQuantity = apparelsCommonQuantity.reduce((acc, variant) => acc + variant.inProductionQuantity, 0);
  const apparelTotal = apparelsInStockQuantity + apparelsInProductionQuantity;
  const otherCommonInStockQuantity = Math.min(...bundleContentWithoutApparels.map(content => bundleInStockCalculationLogic(content.product)));
  const otherCommonInProductionQuantity = Math.min(...bundleContentWithoutApparels.map(content => bundleInProductionCalculationLogic(otherCommonInStockQuantity)(content.product)));
  const otherTotal = otherCommonInStockQuantity + otherCommonInProductionQuantity;
  const isBundleHasApparels = bundleContentApparelsOnly.length > 0;
  const quantityResult = isBundleHasApparels ? Math.min(apparelsInStockQuantity, otherCommonInStockQuantity) : otherCommonInStockQuantity;
  const overallAvailable = isBundleHasApparels ? Math.min(apparelTotal, otherTotal) : otherTotal;
  const inProductionResult = overallAvailable - quantityResult;
  return {
    [colorValue]: {
      colorValue,
      colorName,
      images: [],
      defaultImage,
      quantity: quantityResult,
      inProduction: inProductionResult,
      overallAvailable,
      productId: String(productId),
      variants: apparelsCommonQuantity,
      name: bundleVariant.name,
      _id
    }
  };
};
export const getUniqueColorsOfProductWithQuantities = (product, withSeparateQuantity = false) => {
  const {
    variants
  } = product;
  const isProductBox = isBox(product.swagProductId);
  const isProductBundle = isBundle(product.variants[0]);
  let uniqueColorsProcessor;
  switch (true) {
    case isProductBox:
      {
        uniqueColorsProcessor = getColorsByVariantsBox;
        break;
      }
    case isProductBundle:
      {
        uniqueColorsProcessor = getColorsByBundle;
        break;
      }
    default:
      {
        uniqueColorsProcessor = getColorsByVariants;
      }
  }
  const uniqueColorsMap = uniqueColorsProcessor(variants, withSeparateQuantity);
  return Object.keys(uniqueColorsMap).map(colorValue => uniqueColorsMap[colorValue]);
};
export const singleProductUniqueColorsSelector = createSelector(singleProductSelector, getUniqueColorsOfProduct);
export const singleProductUniqueColorsParamsSelector = createSelector(singleProductUniqueColorsSelector, variants => getVariantUniqueColorWithSizes(variants));
export const singleProductUniqueColorsSelectorByProductId = createSelector(singleProductSelectorByProductId, getUniqueColorsOfProduct);
export const isSingleProductColoredSelector = createSelector(singleProductUniqueColorsSelector, productColors => productColors && productColors.length > 0);
export const isPullPossibleSelector = createSelector(singleProductSelector, ({
  swagProductId
}) => !isBox(swagProductId));
export const selectedProductsSizesAndQuantityPerColorSelector = createSelector(selectedProductsSelector, selectedFullProducts => {
  const productsMap = mapProductsByName(selectedFullProducts);
  return Object.values(productsMap).reduce((acc, products) => {
    const mappedProducts = products.map((product, index, array) => {
      const name = generateProductName(product.name, array.length, index);
      const _sizesAndQuantityPerC = sizesAndQuantityPerColorSelector(product, InReservePopupTypes.IN_STOCK_AVAILABLE),
        {
          variants: stockVariants
        } = _sizesAndQuantityPerC,
        rest = _objectWithoutProperties(_sizesAndQuantityPerC, ["variants"]);
      const {
        variants: inProductionVariants
      } = sizesAndQuantityPerColorSelector(product, InReservePopupTypes.IN_PRODUCTION_AVAILABLE);
      const uniqueColorVariants = getVariantUniqueColorWithSizes([...stockVariants, ...inProductionVariants]);
      const isBundle = isBundleUtil(product.variants[0]);
      return _objectSpread({}, rest, {
        stockVariants,
        inProductionVariants,
        variantsParams: uniqueColorVariants,
        name,
        isBundle
      });
    });
    return [...acc, ...mappedProducts];
  }, []);
});
export const singleProductIsBoxSelector = createSelector(singleProductSelector, ({
  swagProductId
}) => isBox(swagProductId));
export const singleProductInStockSelector = createSelector(singleProductSelector, ({
  inStock
}) => inStock);
export const isSingleProductInStockSelector = createSelector(singleProductSelector, ({
  inStock
}) => inStock > 0);
export const singleProductNameSelector = createSelector(singleProductSelector, ({
  name
}) => name);
export const singleColorDataSelector = createSelector(singleProductSingleColorVariantsSelector, variants => ({
  colorName: variants[0].colorName,
  colorValue: variants[0].colorValue,
  color: variants[0].colorValue
}));
export const isProductDeletePopupOpenSelector = createSelector(rootProductsSelector, products => products.deletePopupOpen);
export const singleProductSelectedColorSelector = createSelector(singleProductSelector, selectedColorsSelector, (product, selectedColors) => product && selectedColors[product._id]);
export const singleProductSelectedColorSelectorByProductId = createSelector(singleProductSelectorByProductId, selectedColorsSelector, (product, selectedColors) => product && selectedColors[product._id]);
export const singleProductVariantsBySelectedColorSelector = createSelector(singleProductSelector, singleProductSelectedColorSelector, (product, selectedColor) => {
  if (!selectedColor) {
    return product;
  }
  return _objectSpread({}, product, {
    variants: product.variants.filter(variant => variant.colorValue === selectedColor)
  });
});
export const singleProductVariantsBySelectedColorSelectorByProductId = createSelector(singleProductSelectorByProductId, singleProductSelectedColorSelectorByProductId, (product, selectedColor) => {
  if (!selectedColor) {
    return product;
  }
  return _objectSpread({}, product, {
    variants: product.variants.filter(variant => variant.colorValue === selectedColor)
  });
});
export const singleProductVariantsIdsSelectorByProductId = createSelector(singleProductSelectorByProductId, product => product.variants.map(variant => String(variant._id)));
export const isProductBundleByProductId = createSelector(singleProductSelectorByProductId, product => isBundleUtil(product.variants[0]));
export const sizesAndQuantitiesPerColorSelector = type => createSelector(popupParamsSelector, productsSelector, ({
  productId
}, products) => {
  const customProduct = products.find(({
    _id
  }) => String(_id) === productId);
  if (!customProduct) {
    return null;
  }
  return sizesAndQuantityPerColorSelector(customProduct, type);
});
export const singleProductBySelectedColorSelectorByProductId = createSelector(singleProductVariantsBySelectedColorSelectorByProductId, product => {
  const {
    variants,
    inReserveStockQuantity,
    inReserveProductionQuantity,
    inStock,
    inProduction,
    inReserveQuantity
  } = product;
  const [firstVariant] = variants;
  let quantities = {
    inReserveStock: 0,
    inReserveProduction: 0,
    inStock: 0,
    inProduction: 0,
    inReserved: 0
  };
  if (isBundle(firstVariant)) {
    quantities = {
      inReserveStock: inReserveStockQuantity,
      inReserveProduction: inReserveProductionQuantity,
      inStock,
      inProduction,
      inReserved: inReserveQuantity
    };
  } else {
    quantities = product.variants.reduce((quantities, variant) => {
      const {
        inReserveProductionQuantity,
        inReserveStockQuantity,
        quantity,
        inProductionQuantity,
        inReserveQuantity
      } = variant;
      return {
        inStock: quantities.inStock + quantity,
        inProduction: quantities.inProduction + inProductionQuantity,
        inReserved: quantities.inReserved + inReserveQuantity,
        inReserveStock: quantities.inReserveStock + inReserveStockQuantity,
        inReserveProduction: quantities.inReserveProduction + inReserveProductionQuantity
      };
    }, quantities);
  }
  return _objectSpread({}, product, quantities, {
    defaultImage: product.variants[0].defaultImage,
    inHandDate: getInHandDateFormatted({
      inHandDate: product.inHandDate,
      isStrictInHandDate: product.isStrictInHandDate
    })
  });
});
export const singleInboundProductVariantsSelector = createSelector(singleProductSelector, product => _objectSpread({}, product, {
  variants: sortProductsBySizes(product.variants.filter(variant => variant.isInbound))
}));
export const singleInboundProductVariantsBySelectedColorSelector = createSelector(singleProductVariantsBySelectedColorSelector, product => _objectSpread({}, product, {
  variants: product.variants.filter(variant => variant.isInbound)
}));
export const selectedColorLowOnStockSelector = createSelector(singleInboundProductVariantsBySelectedColorSelector, product => product.variants.filter(isProductLowOnStock).filter(isProductNotificationEnabled));
export const isSelectedColorLowOnStockSelector = createSelector(selectedColorLowOnStockSelector, lowOnStockVariants => lowOnStockVariants.length > 0);
export const isSelectedColorOutOfStockSelector = createSelector(singleInboundProductVariantsBySelectedColorSelector, product => {
  const outOfStockVariants = product.variants.filter(isProductOutOfStock).filter(isProductNotificationEnabled);
  return outOfStockVariants.length > 0;
});
export const isNotificationEnabledOnProductByColorSelector = createSelector(selectedColorLowOnStockSelector, lowOnStockVariants => {
  const notificationEnabledVariants = lowOnStockVariants.filter(isProductNotificationEnabled);
  return notificationEnabledVariants.length > 0;
});
export const boxProductsSelector = createSelector(singleProductSelector, product => product.variants[0].boxContent.map(({
  product: {
    swagProductId,
    sizeIndex,
    sizeSettings
  },
  quantity
}) => _objectSpread({}, swagProductId, {
  sizeIndex,
  productSize: sizeSettings,
  sizeId: sizeSettings && sizeSettings._id,
  quantity
})));
export const hasBoxSizesSelector = createSelector(boxProductsSelector, boxProducts => {
  const uniqueSizeSet = Object.keys(getUniqueSizeSet(boxProducts));
  return uniqueSizeSet.length > 0;
});
export const outOfStoreProductsSelector = createSelector(storeProductsSelector, products => products.filter(({
  isPublished
}) => !isPublished).sort(({
  createdAt
}, {
  createdAt: previousCreatedAt
}) => createdAt > previousCreatedAt ? -1 : 1).map(({
  _id
}) => _id));
export const selectedOutOfStoreProductsSelector = createSelector(selectedProductsIdsSelector, outOfStoreProductsSelector, (selectedProducts, outOfStoreProducts) => selectedProducts.filter(_id => outOfStoreProducts.includes(_id)));
export const inStoreProductsSelector = createSelector(storeProductsSelector, products => products.filter(({
  isPublished
}) => isPublished).sort(({
  createdAt
}, {
  createdAt: previousCreatedAt
}) => createdAt > previousCreatedAt ? -1 : 1).map(({
  _id
}) => _id));
export const selectedInStoreProductsSelector = createSelector(selectedProductsIdsSelector, inStoreProductsSelector, (selectedProducts, inStoreProducts) => selectedProducts.filter(_id => inStoreProducts.includes(_id)));
export const isRemoveAvailableSelector = createSelector(selectedInStoreProductsSelector, inStore => {
  return inStore.length > 0;
});
export const isAddToStoreAvailableSelector = createSelector(selectedOutOfStoreProductsSelector, outOfStore => {
  return outOfStore.length > 0;
});
export const swagProductSelector = createSelector(singleProductSelector, product => product === null || product === void 0 ? void 0 : product.swagProductId);
export const singleProductPreorderAllowedSelector = createSelector(singleStoreProductSelector, ({
  isPreorderAllowed
}) => isPreorderAllowed);
export const singleProductPreorderCanBeAllowedSelector = createSelector(singleStoreProductSelector, ({
  preorderCanBeAllowed
}) => preorderCanBeAllowed);
export const productCategoryIdsSelector = createSelector(singleStoreProductSelector, product => product.categoryId);
export const singleProductImagesNumberSelector = createSelector(singleProductUniqueColorsSelector, productColors => {
  return productColors.reduce((allImagesNumber, {
    images
  }) => {
    return allImagesNumber + images.length;
  }, 0);
});
export const singleProductColorWithoutImagesSelector = createSelector(singleProductUniqueColorsSelector, productColors => {
  return !!productColors.find(variant => !variant.images || variant.images.length === 0);
});
export const selectedToManagePropertiesProductNameSelector = createSelector(selectedProductsSelector, products => products.map(product => product.name));
export const selectedToManagePropertiesProductCategorySelector = createSelector(selectedStoreProductsSelector, products => {
  const product = products[0];
  return product.categoryId && product.categoryId[0] || DEFAULT_CATEGORY_ID;
});
export const isFullDesignSelector = createSelector(swagProductSelector, product => isProductFullDesign(product));
export const variantIndexesInCustomStoreSelector = createSelector(singleProductSelector, isFullDesignSelector, singleProductIsBoxSelector, (product, isFullDesign, isSelectedProductABox) => getVariantIndexesInCustomStore(product, isFullDesign, isSelectedProductABox));
export const isProductInGiveawaySelector = createSelector(singleProductSelector, pendingActivitiesInSelectedInventory, (product, pendingActivities) => {
  return pendingActivities && isProductInOngoingGiveaways(product._id, pendingActivities.ongoingGiveaways);
});
export const singleProductCanBeDeletedSelector = createSelector(singleProductSelector, isInventoryAdminSelector, isProductInGiveawaySelector, (product, isInventoryAdmin, isProductInGiveaway) => {
  return product.inProduction === 0 && product.inStock === 0 && isInventoryAdmin && !isProductInGiveaway && !isBundleUtil(product.variants[0]);
});
export const singleProductCanBeInGiveawaySelector = createSelector(singleProductSelector, product => {
  return !!product.inStock;
});
export const isAvailableGiveawayForSelectedProductsSelector = createSelector(selectedProductsSelector, products => {
  return products.every(isProductAvailableForAddingToGiveaway);
});
export const orderMoreProductIdSelector = createSelector(state => state.orderMore, orderMore => orderMore.selectedProductId);
export const isSingleProductMoreOrderedSuccessfulSelector = createSelector(isMoreOrderedSelector, productIdSelector, orderMoreProductIdSelector, (isMoreOrdered, productId, orderMoreId) => {
  return isMoreOrdered && Boolean(orderMoreId) && Boolean(productId) && orderMoreId === productId;
});
export const selectedProductToManageNameSelector = createSelector(singleProductSelector, product => product ? product.name : null);
export const singleProductDefaultCustomImagesSelector = createSelector(singleProductSelector, product => {
  if (!product) {
    return {};
  }
  return product.variants.reduce((map, {
    settings,
    colorValue
  }) => {
    if (settings && settings.images.original) {
      return _objectSpread({}, map, {
        [colorValue]: settings.images.original
      });
    }
    return map;
  }, {});
});
export const singleProductCustomImagesSelector = createSelector(rootProductsSelector, products => {
  return products.productImages || {};
});
export const singleProductUploadedImagesSelector = createSelector(rootProductsSelector, products => products.uploadedImages || []);
export const isAnyImagesWereUploadedSelector = createSelector(singleProductUploadedImagesSelector, images => images.length > 0);
export const singleProductImagesToRemove = createSelector(singleProductCustomImagesSelector, singleProductUploadedImagesSelector, (productImages, uploadedImages) => {
  if (!uploadedImages.length) {
    return [];
  }
  const productImagesList = Object.values(productImages);
  return uploadedImages.filter(image => !productImagesList.includes(image));
});
export const isProductSourcingProductSelector = createSelector(singleProductBySelectedColorSelectorByProductId, product => {
  var _variants$every;
  const {
    variants
  } = product;
  return variants === null || variants === void 0 ? void 0 : (_variants$every = variants.every) === null || _variants$every === void 0 ? void 0 : _variants$every.call(variants, variant => variant.isProductSourcing);
});
export const isAutoReplenishInventoryPossibleSelector = createSelector(canDistributeFromInventorySelector, autoReplenishInventoryFeatureEnabledSelector, (canDistributeFromInventory, isAutoReplenishInventoryFeatureEnabled) => {
  return !canDistributeFromInventory && isAutoReplenishInventoryFeatureEnabled;
});
export const singleBundleProductSelector = createSelector(productIdSelector, productsSelector, productIdToManageSelector, (productId, products, productIdToManage) => {
  var _product$variants;
  const product = products.find(({
    _id
  }) => productId === _id) || products.find(({
    _id
  }) => productIdToManage === _id);
  return product === null || product === void 0 ? void 0 : (_product$variants = product.variants) === null || _product$variants === void 0 ? void 0 : _product$variants[0];
});
export const singleBundleContentVariantsSelector = createSelector(singleBundleProductSelector, bundle => {
  var _bundle$bundleContent, _bundle$bundleContent2;
  return bundle !== null && bundle !== void 0 && (_bundle$bundleContent = bundle.bundleContent) !== null && _bundle$bundleContent !== void 0 && _bundle$bundleContent.length ? bundle === null || bundle === void 0 ? void 0 : (_bundle$bundleContent2 = bundle.bundleContent) === null || _bundle$bundleContent2 === void 0 ? void 0 : _bundle$bundleContent2.map(variant => {
    const {
      product = null
    } = variant;
    return _objectSpread({}, product !== null && product !== void 0 ? product : {}, variant);
  }) : [];
});
export const allBundlesInInventorySelector = createSelector(productsSelector, products => products.filter(product => isBundle(product.variants[0])));
export const noInternationalShippingProductsSelector = createSelector(selectedProductsSelector, selectedProducts => getCustomProductsForbiddenToShipInternationally(selectedProducts));
export const selectedProductsVariantsToSwagProductsMapSelector = createSelector(selectedProductsSelector, selectedProducts => selectedProducts.reduce((acc, product) => {
  product.variants.map(variant => {
    var _product$swagProductI2;
    acc[String(variant._id)] = ((_product$swagProductI2 = product.swagProductId) === null || _product$swagProductI2 === void 0 ? void 0 : _product$swagProductI2._id) || '';
  });
  return acc;
}, {}));
export const isProductForbiddenToShipInternationallySelector = createSelector(singleProductSelector, checkIsCustomProductForbiddenToShipInternationally);