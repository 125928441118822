import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { pageCountSelector, pageSelector, perpageSelector } from '../../../../redux/product-listing/product-listing.selectors';
import { locationSelector } from '../../../../redux/common/common.selectors';
import { Helmet as HelmetComponent } from './helmet';
const mapStateToProps = createStructuredSelector({
  location: locationSelector,
  currentPageNumber: pageSelector,
  currentPerPage: perpageSelector,
  pageCount: pageCountSelector
});
export const Helmet = connect(mapStateToProps)(HelmetComponent);