import { hideLoader } from 'swag-client-common/redux/common/common.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { startProceedLoading } from '../../../customer/redux/checkout/checkout.actions';
import { validateBillingAddress } from '../billing-address/billing-address.actions';
import { billingAddressInvalidInputsSelector } from '../billing-address/billing-address.selectors';
const PREFIX = 'STRIPE_FORM_';
export const EDIT_FORM_FIELD = `${PREFIX}EDIT_FORM_FIELD`;
export const editField = (field, value) => ({
  type: EDIT_FORM_FIELD,
  field,
  value
});
export const CARD_INFO_VALIDATED = `${PREFIX}BILLING_ADDRESS_VALIDATED`;
export const getToken = (dispatch, address, stripe, card, onTokenReceive) => {
  dispatch(startProceedLoading());
  const name = address.cardHolder || `${address.firstName} ${address.lastName}`;
  const billingAddress = {
    name,
    address_line1: `${address.street || ''}, ${address.unit || ''}`,
    address_city: address.city,
    address_state: address.state,
    address_zip: address.zipcode,
    address_country: address.country
  };
  stripe.createToken(card, billingAddress).then(response => {
    if (response.error) {
      dispatch({
        type: STRIPE_FORM_SUBMIT_ERROR,
        error: response.error.message
      });
      dispatch(hideLoader());
      dispatch(showNotification({
        text: response.error.message
      }));
    } else {
      onTokenReceive(response.token);
    }
  });
  dispatch({
    type: STRIPE_FORM_SUBMIT_STARTED
  });
};
export const STRIPE_FORM_SUBMIT_STARTED = `${PREFIX}STRIPE_FORM_SUBMIT_STARTED`;
export const STRIPE_FORM_SUBMIT_ERROR = `${PREFIX}STRIPE_FORM_SUBMIT_ERROR`;
export const STRIPE_FORM_SUBMIT_SUCCESS = `${PREFIX}STRIPE_FORM_SUBMIT_SUCCESS`;
export const submitForm = (stripe, card, onTokenReceive) => (dispatch, getState) => {
  dispatch(validateBillingAddress());
  const state = getState();
  const billingAddressInvalidInputs = billingAddressInvalidInputsSelector(getState());
  if (billingAddressInvalidInputs.length) {
    return;
  }
  const {
    address
  } = state.billingAddress;
  getToken(dispatch, address, stripe, card, onTokenReceive);
};
export const STRIPE_FORM_VALIDATION_RESET = `${PREFIX}STRIPE_FORM_VALIDATION_RESET`;
export const resetInventoryStripeFormValidations = () => ({
  type: STRIPE_FORM_VALIDATION_RESET
});