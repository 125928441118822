import _map from "lodash/map";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Price } from 'swag-common/utils/price';
import * as colorsUtils from 'swag-common/utils/colors';
import { getRelatedItemsPricing } from 'swag-common/business-logic';
import { isInBox } from 'swag-common/utils/order/item-is-in-box.util';
import { DesignUploadMode } from 'swag-common/interfaces';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { groupItemsAsRelatedAndDefault } from 'swag-common/business-logic/order/group-items-as-related-and-default.logic';
import { getModifiedItemNameAndOriginalFromItem } from 'swag-common/business-logic/inventory-product-name/get-modified-item-name-and-original.logic';
import { getDataForGettingItemShippingCost, getItemShippingCost } from 'swag-common/business-logic/order/get-item-shipping-cost.service';
import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { getRealSetupFee } from 'swag-common/utils/items/get-real-setup-fee';
import { isRelatedItem } from 'swag-common/utils/items/is-item-shared-or-related';
import { isCancelledItem } from 'swag-common/utils/order';
const {
  formatPrice
} = Price;
const getColorSideInfo = currentItemColorData => {
  const sides = Object.keys(currentItemColorData);
  return sides.reduce((acc, side, index) => {
    const uniquePantonesNumbers = currentItemColorData[side];
    const ending = uniquePantonesNumbers > 1 ? 's' : '';
    const colon = index === sides.length - 1 ? '' : ', ';
    return `${acc}${uniquePantonesNumbers} color${ending} ${side} print${colon}`;
  }, '');
};
function getSizeBreakdown(item) {
  var _item$prod, _item$prod$size;
  const quantityBySize = {};
  if (!item.variants || !((_item$prod = item.prod) !== null && _item$prod !== void 0 && (_item$prod$size = _item$prod.size) !== null && _item$prod$size !== void 0 && _item$prod$size.options)) {
    return null;
  }
  item.variants.forEach(variant => {
    variant.quantities.forEach((qty, index) => {
      var _item$prod2, _item$prod2$size;
      const currentSize = item === null || item === void 0 ? void 0 : (_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : (_item$prod2$size = _item$prod2.size) === null || _item$prod2$size === void 0 ? void 0 : _item$prod2$size.options[index];
      if (!qty || !currentSize) {
        return;
      }
      if (!quantityBySize[currentSize]) {
        quantityBySize[currentSize] = 0;
      }
      quantityBySize[currentSize] += qty;
    });
  });
  return _map(quantityBySize, (quantity, size) => `${size} - ${quantity}`).join(', ');
}
function getOrderedItemsColors(item) {
  var _item$prod3, _item$prod3$variants, _item$prod4;
  const colorNames = [];
  if (!item.variants || !((_item$prod3 = item.prod) !== null && _item$prod3 !== void 0 && (_item$prod3$variants = _item$prod3.variants) !== null && _item$prod3$variants !== void 0 && _item$prod3$variants.length) || !((_item$prod4 = item.prod) !== null && _item$prod4 !== void 0 && _item$prod4.size)) {
    return null;
  }
  item.variants.forEach(variant => {
    var _item$prod5, _item$prod5$variants$;
    const name = item === null || item === void 0 ? void 0 : (_item$prod5 = item.prod) === null || _item$prod5 === void 0 ? void 0 : (_item$prod5$variants$ = _item$prod5.variants[variant.variantIndex]) === null || _item$prod5$variants$ === void 0 ? void 0 : _item$prod5$variants$.name;
    if (name) {
      colorNames.push(name);
    }
  });
  return colorNames.join(', ');
}
const prepareNonRelatedItems = (item, items) => {
  const {
    prod = {},
    logos,
    texts,
    price,
    quantity,
    setupFee,
    upsCharge,
    isSharedItem,
    isRelatedItem
  } = item;
  const isItemInBox = isInBox(item);
  const productName = getModifiedItemNameAndOriginalFromItem(item);
  const isLogoTypeProduct = prod.designUploadMode === DesignUploadMode.logo;
  const currentItemColorData = colorsUtils.getUniqueColorsNumberForAllSides(logos, texts);
  const colorSideInfo = getColorSideInfo(currentItemColorData);
  const productDescription = isLogoTypeProduct && colorSideInfo ? `${productName} design (${colorSideInfo})` : productName;
  const shipping = upsCharge || getItemShippingCost(getDataForGettingItemShippingCost(item, items));
  const totalPrice = isItemInBox ? price + item.tax : price + shipping + item.tax;
  const realSetupFee = getRealSetupFee({
    isShared: isSharedItem,
    isRelated: isRelatedItem,
    item
  });
  const currentItemInfo = {
    text: [productDescription],
    quantity: [item.quantity],
    unitPrice: [formatPrice((price - realSetupFee) / quantity)],
    totalPrice: formatPrice(totalPrice),
    setupFee: formatPrice(setupFee),
    shipping: formatPrice(shipping),
    isInBox: isItemInBox,
    colors: getOrderedItemsColors(item),
    sizeBreakdown: getSizeBreakdown(item),
    isCurrentItemRelated: false,
    isAllRelatedItemsCrossedOut: false,
    tax: formatPrice(item.tax),
    crossedOut: isCancelledItem(item),
    crossedOutRelatedItemIndices: [],
    lineItemIds: String(item._id)
  };
  return currentItemInfo;
};
export const prepareItemsInfoForInvoice = items => {
  const itemsWithSharedAndRelatedBool = items.map(i => _objectSpread({}, i, {
    isSharedItem: isSharedItem(i, items),
    isRelatedItem: isRelatedItem(i, items, true)
  }));
  const itemsWithSetupFee = groupItemsAsRelatedAndDefault(itemsWithSharedAndRelatedBool, {
    forEmail: false,
    unwindBoxItems: false,
    skipInventory: true
  }).items;
  const itemsInfo = [];
  const {
    length
  } = itemsWithSetupFee;
  for (let i = 0; i < length; i++) {
    const currentItem = itemsWithSetupFee[i];
    // It is not true for standalone related item in case when the second related item is in box.
    const isCurrentItemRelated = Array.isArray(currentItem) && currentItem.length > 1;
    const isBoxItem = Array.isArray(currentItem) && Boolean(currentItem[0].boxItemsId);
    switch (true) {
      case isCurrentItemRelated && !isBoxItem:
        {
          const relatedItemsGroup = currentItem;
          const prodNames = [];
          const quantities = [];
          const unitPrices = [];
          const shippingCharges = [];
          const fees = [];
          let shippingForRelated = 0;
          let tax = 0;
          const lineItemIds = [];
          const crossedOutRelatedItemIndices = [];
          let crossedOut = false;
          for (let j = 0; j < relatedItemsGroup.length; j++) {
            const currentItem = relatedItemsGroup[j];
            const productName = getModifiedItemNameAndOriginalFromItem(currentItem);
            const {
              logos,
              texts
            } = currentItem;
            const currentItemColorData = colorsUtils.getUniqueColorsNumberForAllSides(logos, texts);
            const productDescription = isProductFullDesign(currentItem.prod) ? productName : `${productName} design (${getColorSideInfo(currentItemColorData)})`;
            const shipping = currentItem.upsCharge || getItemShippingCost(getDataForGettingItemShippingCost(currentItem, items));
            prodNames.push(productDescription);
            quantities.push(currentItem.quantity);
            fees.push(currentItem.setupFee);
            shippingCharges.push(formatPrice(shipping));
            const realSetupFee = getRealSetupFee({
              item: currentItem,
              items,
              setupFee: currentItem.setupFee
            });
            unitPrices.push(formatPrice((currentItem.price - realSetupFee) / currentItem.quantity));
            const isCancelled = isCancelledItem(currentItem);
            if (!isCancelled) {
              shippingForRelated += shipping;
              tax += currentItem.tax;
            }
            lineItemIds.push(String(currentItem._id));
            if (crossedOut === false) {
              crossedOut = isCancelled;
            }
            if (isCancelled) {
              crossedOutRelatedItemIndices.push(j);
            }
          }
          const {
            total,
            setupFee
          } = getRelatedItemsPricing(relatedItemsGroup);
          const totalPrice = total + shippingForRelated + tax;
          const currentItemInfo = {
            text: prodNames,
            quantity: quantities,
            shipping: shippingCharges,
            unitPrice: unitPrices,
            totalPrice: formatPrice(totalPrice),
            setupFee: formatPrice(setupFee),
            isCurrentItemRelated,
            tax: formatPrice(tax),
            colors: getOrderedItemsColors(currentItem),
            sizeBreakdown: getSizeBreakdown(currentItem),
            isInBox: false,
            crossedOut,
            crossedOutRelatedItemIndices,
            isAllRelatedItemsCrossedOut: crossedOutRelatedItemIndices.length === relatedItemsGroup.length,
            lineItemIds: lineItemIds.join(',')
          };
          itemsInfo.push(currentItemInfo);
          break;
        }
      default:
        {
          const currentItemInfo = currentItem.map(item => prepareNonRelatedItems(item, items));
          itemsInfo.push(...currentItemInfo);
          break;
        }
    }
  }
  return itemsInfo;
};