import { createSelector } from 'reselect';
import { NEW_DEPARTMENT_ID } from 'swag-client-common/utils/company-departments';
export const departmentsSelector = state => state.companyDepartments.departments;
export const companyUsersSelector = state => state.companyDepartments.companyUsers;
export const currentDepartmentIdSelector = state => state.companyDepartments.currentEditingDepartmentId;
export const departmentsLoadingSelector = state => state.companyDepartments.departmentsLoading;
export const companyUsersLoadingSelector = state => state.companyDepartments.companyUsersLoading;
export const departmentFromProps = (state, props) => props.department;
export const loadingSelector = createSelector([departmentsLoadingSelector, companyUsersLoadingSelector], (departmentsLoading, companyUsersLoading) => departmentsLoading || companyUsersLoading);
export const currentDepartmentSelector = createSelector([currentDepartmentIdSelector, departmentsSelector], (id, departments) => departments.find(item => item._id === id));
export const hasNoDepartmentsMessageSelector = createSelector([loadingSelector, departmentsSelector], (loading, departments) => !loading && departments.length === 0);
export const isEditDisabledSelector = createSelector([currentDepartmentIdSelector], currentDepartmentId => currentDepartmentId === NEW_DEPARTMENT_ID);
export const companyUserDropdownValuesSelector = createSelector([companyUsersSelector], users => users.map(({
  _id,
  email
}) => ({
  value: _id,
  label: email
})));
export const companyUserNameSelector = createSelector([companyUsersSelector, departmentFromProps], (users, department) => {
  if (!department) {
    return '';
  }
  const user = users.find(item => item._id === department.userId);
  if (!user) {
    return '';
  }
  return user.email;
});