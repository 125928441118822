function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { PRODUCT_BUILDER_MODES } from 'swag-client-common/utils/constants';
import { PRODUCTION_TIME_STANDARD_INDEX } from 'swag-common/constants/product.constants';
import { ADD_ITEM_TO_CART } from 'swag-client-common/redux/cart/cart.actions';
import { getDefaultProductionTimeIdx } from '../product/product.utils';
import { OPEN, REBUILD, CHANGE_VARIANT, CHANGE_QUANTITIES, CHANGE_SINGLE_VARIANT_QUANTITIES, CHANGE_SINGLE_QUANTITY, CHANGE_TIME, CHANGE_VIEW, CHANGE_SIZE_INDEX, RESET_REDUCER, TOGGLE_SPLIT_ORDER_ON, TOGGLE_SPLIT_ORDER_OFF, CHANGE_PREVIEW, UNSELECT_VARIANT, ADD_SELECTED_VARIANT, CHANGE_FULL_SCREEN_MODE, CHANGE_MORE_IMAGE_MODE, CHANGE_METHOD_INDEX, FETCH_SIZE_SUCCESS, FETCH_SIZE_FAIL, RESET_POPUP_MODE } from './related-product.actions';
export const initialState = {
  product: {
    _id: '',
    variants: [],
    imagesForSpec: []
  },
  size: {},
  selectedVariants: [0],
  quantities: {
    0: []
  },
  variantIndex: 0,
  imageIndex: 0,
  sizeIndex: 0,
  methodIndex: 0,
  splitOrderMode: false,
  productLoaded: false,
  sizeLoaded: false,
  logoNeverUploaded: true,
  selectedProductionTime: PRODUCTION_TIME_STANDARD_INDEX,
  view: 'front',
  serviceCode: '03',
  discount: false,
  mode: PRODUCT_BUILDER_MODES.CREATE_MODE,
  fullScreenMode: false,
  showMoreImageMode: false,
  embellishmentMethod: null,
  designNotes: '',
  attachments: [],
  popupMode: PRODUCT_BUILDER_MODES.BUILD_MODE,
  quantitiesReady: false
};
export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN:
      {
        var _product$productSetti, _product$productSetti2, _product$productSetti3;
        const {
          product,
          selectedVariants,
          variantIndex,
          productLoaded
        } = action.payload;
        const selectedProductionTime = getDefaultProductionTimeIdx((_product$productSetti = (_product$productSetti2 = product.productSettings) === null || _product$productSetti2 === void 0 ? void 0 : (_product$productSetti3 = _product$productSetti2[0]) === null || _product$productSetti3 === void 0 ? void 0 : _product$productSetti3.productionTimeList) !== null && _product$productSetti !== void 0 ? _product$productSetti : []);
        return _objectSpread({}, state, {
          productLoaded,
          selectedProductionTime,
          selectedVariants: [...selectedVariants],
          variantIndex,
          product: _objectSpread({}, state.product, product),
          popupMode: PRODUCT_BUILDER_MODES.BUILD_MODE
        });
      }
    case REBUILD:
      {
        const {
          product
        } = action.payload;
        return _objectSpread({}, state, product, {
          splitOrderMode: product.selectedVariants.length > 1,
          product: _objectSpread({}, state.product, product.product),
          popupMode: PRODUCT_BUILDER_MODES.REBUILD_MODE
        });
      }
    case RESET_POPUP_MODE:
      {
        return _objectSpread({}, state, {
          popupMode: PRODUCT_BUILDER_MODES.BUILD_MODE
        });
      }
    case FETCH_SIZE_SUCCESS:
      {
        const {
          size
        } = action;
        return _objectSpread({}, state, {
          size,
          sizeLoaded: true
        });
      }
    case FETCH_SIZE_FAIL:
      return state;
    case CHANGE_VARIANT:
      {
        const {
          variantIndex
        } = action.payload;
        const {
          quantities: prevQuantities,
          variantIndex: prevVariantIndex
        } = state;
        const quantities = {};
        quantities[variantIndex] = prevQuantities[prevVariantIndex];
        return _objectSpread({}, state, {
          variantIndex,
          selectedVariants: [variantIndex],
          quantities
        });
      }
    case ADD_SELECTED_VARIANT:
      {
        const {
          variantIndex
        } = action.payload;
        const selectedVariants = [...state.selectedVariants, variantIndex];
        return _objectSpread({}, state, {
          selectedVariants
        });
      }
    case CHANGE_TIME:
      return _objectSpread({}, state, {
        selectedProductionTime: action.time
      });
    case CHANGE_VIEW:
      return _objectSpread({}, state, {
        view: action.view
      });
    case CHANGE_PREVIEW:
      {
        return _objectSpread({}, state, {
          view: action.view
        });
      }
    case CHANGE_SIZE_INDEX:
      {
        const newSizeIndex = action.payload;
        const {
          selectedVariants,
          sizeIndex: previousSizeIndex
        } = state;
        const quantities = _objectSpread({}, state.quantities);
        selectedVariants.forEach(variantIndex => {
          const currentQuantity = quantities[variantIndex][previousSizeIndex];
          quantities[variantIndex] = quantities[variantIndex].map((quantity, sizeIndex) => sizeIndex === newSizeIndex ? currentQuantity : 0);
        });
        return _objectSpread({}, state, {
          quantities,
          sizeIndex: newSizeIndex
        });
      }
    case ADD_ITEM_TO_CART:
      {
        return initialState;
      }
    case RESET_REDUCER:
      {
        return _objectSpread({}, initialState);
      }
    case CHANGE_SINGLE_VARIANT_QUANTITIES:
      {
        const {
          quantities,
          variantIndex
        } = action.payload;
        const updatedQuantities = _objectSpread({}, state.quantities, {
          [variantIndex]: quantities
        });
        return _objectSpread({}, state, {
          quantities: updatedQuantities
        });
      }
    case CHANGE_SINGLE_QUANTITY:
      {
        const {
          quantity,
          variantIndex,
          sizeIndex
        } = action.payload;
        const updatedVariantQuantities = [...state.quantities[variantIndex]];
        updatedVariantQuantities[sizeIndex] = quantity;
        const updatedQuantities = _objectSpread({}, state.quantities, {
          [variantIndex]: updatedVariantQuantities
        });
        return _objectSpread({}, state, {
          quantities: updatedQuantities
        });
      }
    case CHANGE_QUANTITIES:
      {
        const {
          quantities
        } = action.payload;
        return _objectSpread({}, state, {
          quantities,
          quantitiesReady: true
        });
      }
    case TOGGLE_SPLIT_ORDER_ON:
      {
        return _objectSpread({}, state, {
          splitOrderMode: true
        });
      }
    case TOGGLE_SPLIT_ORDER_OFF:
      {
        const selectedVariants = [...state.selectedVariants];
        selectedVariants.splice(1, selectedVariants.length - 1);
        return _objectSpread({}, state, {
          splitOrderMode: false,
          selectedVariants,
          variantIndex: selectedVariants[0]
        });
      }
    case UNSELECT_VARIANT:
      {
        const {
          variantIndex: variantIndexToRemove
        } = action.payload;
        const selectedVariants = [...state.selectedVariants];
        const quantities = _objectSpread({}, state.quantities);
        const index = state.selectedVariants.findIndex(variantIndex => variantIndex === variantIndexToRemove);
        if (index !== -1 && selectedVariants.length > 1) {
          selectedVariants.splice(index, 1);
          delete quantities[variantIndexToRemove];
        }
        return _objectSpread({}, state, {
          selectedVariants,
          variantIndex: selectedVariants[0],
          quantities
        });
      }
    case CHANGE_FULL_SCREEN_MODE:
      {
        return _objectSpread({}, state, {
          fullScreenMode: !state.fullScreenMode
        });
      }
    case CHANGE_MORE_IMAGE_MODE:
      {
        return _objectSpread({}, state, {
          showMoreImageMode: !state.showMoreImageMode
        });
      }
    case CHANGE_METHOD_INDEX:
      {
        return _objectSpread({}, state, {
          methodIndex: action.payload.index
        });
      }
    default:
      return state;
  }
}