function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
import { EDIT_FIELD, VALIDATED, RESET, SAME_TO_SHIPPING, SAME_AS_SHIPPING_WAS_SET } from './billing-address.actions';
const initialState = {
  errors: {},
  sameAsShipping: false,
  sameAsShippingWasSet: false,
  addressValid: false,
  mandatoryKeys: ['firstName', 'lastName', 'street', 'city', 'country'],
  address: {
    firstName: '',
    lastName: '',
    street: '',
    unit: '',
    city: '',
    state: '',
    country: COUNTRY_CODES.US,
    zipcode: ''
  }
};
export default function billingAddressReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_FIELD:
      {
        const {
          field,
          value
        } = action;
        const newAddress = _objectSpread({}, state.address, {
          [action.field]: action.value
        });
        if (field === 'country' && value !== COUNTRY_CODES.US) {
          newAddress.state = '';
        }
        return _objectSpread({}, state, {
          address: newAddress,
          sameAsShipping: false
        });
      }
    case VALIDATED:
      {
        const {
          errors
        } = action;
        return _objectSpread({}, state, {
          errors,
          addressValid: !Object.keys(errors).length
        });
      }
    case SAME_AS_SHIPPING_WAS_SET:
      {
        return _objectSpread({}, state, {
          sameAsShippingWasSet: true
        });
      }
    case SAME_TO_SHIPPING:
      {
        const {
          address
        } = action;
        return _objectSpread({}, initialState, {
          address,
          addressValid: true,
          sameAsShipping: true
        });
      }
    case RESET:
      {
        return _objectSpread({}, state, initialState);
      }
    default:
      {
        return state;
      }
  }
}