function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { SENT_STATUS_RESET, REQUEST_ENTERPRISE_FAILURE, REQUEST_PATAGONIA_FAILURE, REQUEST_IN_A_BOX_CONTACT_FAILURE, REQUEST_ENTERPRISE_SUCCESS, REQUEST_PATAGONIA_SUCCESS, REQUEST_IN_A_BOX_CONTACT_SUCCESS, REQUEST_GIVEAWAY_CONTACT_FAILURE, REQUEST_GIVEAWAY_CONTACT_SUCCESS, REQUEST_AUTOMATION_CONTACT_FAILURE, REQUEST_AUTOMATION_CONTACT_SUCCESS, REQUEST_ZAPIER_CONTACT_FAILURE, REQUEST_ZAPIER_CONTACT_SUCCESS, REQUEST_SHOPIFY_CONTACT_SUCCESS, REQUEST_SHOPIFY_CONTACT_FAILURE, REQUEST_RESELLER_FORM_SUCCESS, REQUEST_RESELLER_FORM_FAILURE, GET_RESELLER_LANDING_DATA_FAILURE, GET_RESELLER_LANDING_DATA_SUCCESS } from './landings.actions';
const initialState = {
  text: '',
  additionalText: '',
  sent: false,
  isShown: false,
  resellerLandingData: {}
};
export function landingReducer(state = initialState, action) {
  switch (action.type) {
    case SENT_STATUS_RESET:
      {
        return _objectSpread({}, initialState);
      }
    case REQUEST_ENTERPRISE_FAILURE:
    case REQUEST_PATAGONIA_FAILURE:
    case REQUEST_IN_A_BOX_CONTACT_FAILURE:
    case REQUEST_GIVEAWAY_CONTACT_FAILURE:
    case REQUEST_AUTOMATION_CONTACT_FAILURE:
    case REQUEST_ZAPIER_CONTACT_FAILURE:
    case REQUEST_SHOPIFY_CONTACT_FAILURE:
    case REQUEST_RESELLER_FORM_FAILURE:
    case GET_RESELLER_LANDING_DATA_FAILURE:
      {
        return _objectSpread({}, state, {
          isShown: true,
          text: 'Sorry!',
          additionalText: 'Something goes wrong.'
        });
      }
    case REQUEST_ENTERPRISE_SUCCESS:
    case REQUEST_PATAGONIA_SUCCESS:
    case REQUEST_IN_A_BOX_CONTACT_SUCCESS:
    case REQUEST_GIVEAWAY_CONTACT_SUCCESS:
    case REQUEST_AUTOMATION_CONTACT_SUCCESS:
    case REQUEST_ZAPIER_CONTACT_SUCCESS:
    case REQUEST_SHOPIFY_CONTACT_SUCCESS:
    case REQUEST_RESELLER_FORM_SUCCESS:
      {
        return _objectSpread({}, state, {
          isShown: true,
          text: 'Thank you!',
          additionalText: 'We will be in touch soon.'
        });
      }
    case GET_RESELLER_LANDING_DATA_SUCCESS:
      {
        return _objectSpread({}, state, {
          resellerLandingData: _objectSpread({}, state.resellerLandingData, action.payload)
        });
      }
    default:
      {
        return state;
      }
  }
}