export const SOCKET_EVENTS = {
  SYSTEM_SOCKET_MSG: 'systemSocketMessage',
  CONNECT_IF_NOT_CONNECTED: 'connect_if_not_connected',
  RESET_CONNECTION: 'reset_connection',
  IS_SW_SUPPORTED: 'is_sw_supported',
  FORCE_RECONNECT: 'force_reconnect',
  DETECT_COLORS: 'detectColors',
  DETECT_COLORS_PROGRESS: 'detectColors:statusChange',
  DETECT_COLORS_COMPLETED: 'detectColors:completed',
  DETECT_COLORS_MANY_COLORS: 'detectColors:tooManyColors',
  DETECT_COLORS_ERROR: 'detectColors:error',
  VALIDATE_SHIPOUT_PROGRESS: 'validateShipout:progress',
  VALIDATE_SHIPOUT_ERROR: 'validateShipout:error',
  VALIDATE_SHIPOUT_CANCEL: 'validateShipout:cancel',
  CALCULATE_SHIPPING: 'calculateShipping',
  CALCULATE_SHIPPING_PROGRESS: 'calculateShipping:progress',
  CANCEL_CALCULATE_SHIPPING: 'calculateShipping:cancel',
  CHECK_DISTRIBUTION_STATUS_REQUEST: 'checkDistributionStatus:request',
  CHECK_DISTRIBUTION_STATUS_PROGRESS: 'checkDistributionStatus:progress',
  CHECK_DISTRIBUTION_STATUS_SUCCESS: 'checkDistributionStatus:success',
  CHECK_DISTRIBUTION_STATUS_CANCEL: 'checkDistributionStatus:cancel',
  CHECK_DISTRIBUTION_STATUS_SKIPPED: 'checkDistributionStatus:skipped',
  SEND_HOT_PRODUCT: 'hotProduct:send',
  GET_HOT_PRODUCT: 'hotProduct:get',
  GET_HOT_PRODUCT_AUTOTEST: 'hotProductAutotest:get'
};