import withProps from 'swag-client-common/components/utils/with-props';
import { HeaderComponent } from 'swag-client-common/components/ecommerce-header';
import SearchProducts from '../../../info/search-products';
import { SwagAccount } from './components/swag-account';
import { ProductListingActiveFiltersRow, SearchResultsActiveFiltersRow } from './components/filters-row-panel';
import { ProductListingPanel, SearchResultsPageProductListingPanel } from './components/product-listing-panel';
export const Header = withProps(HeaderComponent, {
  SwagAccount,
  ProductListingPanel,
  SearchProducts,
  FiltersRow: ProductListingActiveFiltersRow
});
export const SearchResultsPageHeader = withProps(Header, {
  SwagAccount,
  ProductListingPanel: SearchResultsPageProductListingPanel,
  SearchProducts,
  SearchResultsPageProductListingPanel,
  FiltersRow: SearchResultsActiveFiltersRow
});