function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import AuthApi from 'swag-client-common/api/auth.api';
import { getUserInventories } from '../inventory/inventory.actions';
const PREFIX = 'SET_PASSWORD_';
export const SET_PASSWORD_LOADING = `${PREFIX}PASSWORD_SET_LOADING`;
export const SET_PASSWORD_SUCCESS = `${PREFIX}PASSWORD_SET_SUCCESS`;
export const SET_PASSWORD_ERROR = `${PREFIX}PASSWORD_SET_ERROR`;
export const setPassword = createAsyncAction({
  transformData: (data, getState) => {
    const {
      token
    } = getState().setPassword;
    return _objectSpread({}, data, {
      token,
      agreeWithTerms: undefined
    });
  },
  preFn: dispatch => {
    dispatch({
      type: SET_PASSWORD_LOADING
    });
  },
  fn: AuthApi.setPassword,
  success: body => dispatch => {
    dispatch({
      type: SET_PASSWORD_SUCCESS,
      body
    });
    dispatch(getUserInventories());
  },
  error: errorAction(SET_PASSWORD_ERROR)
});
export const TOKEN = `${PREFIX}TOKEN`;
export const setPasswordInitialState = ({
  token,
  mode
}) => ({
  type: TOKEN,
  token,
  mode
});