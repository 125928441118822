import { createSelector } from 'reselect';
import { ResellerAdminMode } from 'swag-common/constants/reseller.constants';
import { USER_ROLES } from 'swag-common/constants/user-roles';
import { checkIsResellerTenant } from 'swag-common/utils/check-is-reseller-tenant.util';
import { checkIsInvoiceMode, checkIsPresentationMode, checkIsResellerAdminMode, checkIsOrderCreationMode } from 'swag-common/utils/reseller/check-is-reseller-admin-mode.util';
import { getHasResellerOrderNetOrACHPaymentMethod } from 'swag-common/utils/reseller/get-has-reseller-order-net-or-ach';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { urlReplacer } from 'swag-client-common/libs/url-modifier';
import { AdminMode } from 'swag-common/constants/admin.constants';
import { checkIsAdminMode } from 'swag-common/utils/admin/check-is-admin-mode';
import { PRE_BUILT_CART_LABEL } from 'swag-common/constants/pre-built-cart.constants';
import { cartTotalSelector, preBuiltCartTypeSelector } from '../cart/cart.selectors';
import { userEmailSelector } from '../../../customer/redux/user/user.selectors';
export { isInventoryTermsAcceptedSelector, isUserPostalSupervisor, userHasActiveOrOwnDeletedInventorySelector, userHasInventoriesSelector, isCustomInkImpersonateFlowSelector } from './user-other.selectors';
const pathnameSelector = state => {
  const location = state.routing.locationBeforeTransitions || window.location;
  return urlReplacer(location === null || location === void 0 ? void 0 : location.pathname) || '';
};
export const userSelector = state => state.user;
export const roleSelector = createSelector(userSelector, user => user.role);
export const loggedInUserSelector = createSelector(userSelector, user => user.isLoggedIn);
export const userPoliciesSelector = createSelector(userSelector, user => user.policies || []);
export const userIdSelector = createSelector(userSelector, user => user._id);
export const emailSelector = createSelector(userSelector, user => user.email);
export const loggedInUserIdSelector = createSelector(userSelector, user => user.isLoggedIn && user._id ? user._id : null);
export const unauthenticatedUserAlreadyExistsSelector = createSelector(userSelector, user => user.unauthenticatedUserAlreadyExists);
export const hasOwnDeletedInventorySelector = createSelector(userSelector, user => user.hasOwnDeletedInventories);
export const inventoriesCountSelector = createSelector(userSelector, user => user.inventoriesCount);
export const isAdminLoginAsUserSelector = createSelector(userSelector, user => user.isAdminLoginAsUser);
export const isSwagAdminLoggedInAsResellerUserSelector = createSelector(userSelector, isAdminLoginAsUserSelector, (user, isAdminLoggedAsUser) => isAdminLoggedAsUser && checkIsResellerTenant(user.tenant));
export const isResellerAdminLoggedAsResellerUserSelector = createSelector(userSelector, user => user.isResellerAdminLoggedAsResellerUser);
export const isAdminLoggedInAsResellerUserSelector = createSelector(isSwagAdminLoggedInAsResellerUserSelector, isResellerAdminLoggedAsResellerUserSelector, (isSwagAdminLoggedInAsResellerUser, isResellerAdminLoggedAsResellerUser) => isSwagAdminLoggedInAsResellerUser || isResellerAdminLoggedAsResellerUser);
export const adminModeSelector = createSelector(userSelector, user => user === null || user === void 0 ? void 0 : user.adminMode);
export const resellerAdminModeSelector = createSelector(userSelector, user => user === null || user === void 0 ? void 0 : user.resellerAdminMode);
export const companyMarginSelector = createSelector(userSelector, user => {
  var _user$companyMargin;
  return (_user$companyMargin = user === null || user === void 0 ? void 0 : user.companyMargin) !== null && _user$companyMargin !== void 0 ? _user$companyMargin : 0;
});
export const isAdminLoggedInModeSelector = createSelector(adminModeSelector, resellerAdminModeSelector, (adminMode, resellerAdminMode) => !!resellerAdminMode || !!adminMode);
export const isResellerInPresentationModeSelector = createSelector(resellerAdminModeSelector, resellerAdminMode => checkIsPresentationMode(resellerAdminMode));
export const isResellerInInvoiceModeSelector = createSelector(resellerAdminModeSelector, mode => checkIsInvoiceMode(mode));
export const isResellerInOrderCreationModeSelector = createSelector(resellerAdminModeSelector, mode => checkIsOrderCreationMode(mode));
export const isSwagAdminModeSelector = createSelector(adminModeSelector, checkIsAdminMode);
export const isResellerAdminModeSelector = createSelector(resellerAdminModeSelector, checkIsResellerAdminMode);
export const isAdminModeSelector = createSelector(isSwagAdminModeSelector, isResellerAdminModeSelector, (isSwagAdminMode, isResellerAdminMode) => isSwagAdminMode || isResellerAdminMode);
export const preBuiltCartUserEmailSelector = createSelector(userSelector, user => {
  return (user === null || user === void 0 ? void 0 : user.preBuiltCartUserEmail) || '';
});
export const preBuiltCartUserSelector = createSelector(userSelector, user => {
  return (user === null || user === void 0 ? void 0 : user.preBuiltCartUser) || {};
});
export const isPreBuiltCartUserInventoryTermAcceptedSelector = createSelector(preBuiltCartUserSelector, preBuiltCartUser => {
  var _preBuiltCartUser$pas;
  return Boolean(preBuiltCartUser === null || preBuiltCartUser === void 0 ? void 0 : (_preBuiltCartUser$pas = preBuiltCartUser.passedActions) === null || _preBuiltCartUser$pas === void 0 ? void 0 : _preBuiltCartUser$pas.inventoryTermAccepted);
});
export const swagAdminModeLabelSelector = createSelector(adminModeSelector, preBuiltCartTypeSelector, preBuiltCartUserEmailSelector, (adminMode, preBuiltCartType, preBuiltCartUserEmail) => {
  if (preBuiltCartType && preBuiltCartUserEmail) {
    if (adminMode === AdminMode.CreatingCart) {
      return `Building a ${PRE_BUILT_CART_LABEL[preBuiltCartType]} cart for ${preBuiltCartUserEmail}`;
    }
    if (adminMode === AdminMode.EditingCart) {
      return `Editing a ${PRE_BUILT_CART_LABEL[preBuiltCartType]} cart for ${preBuiltCartUserEmail}`;
    }
  }
  return '';
});
export const resellerAdminModeLabelSelector = createSelector(resellerAdminModeSelector, preBuiltCartTypeSelector, preBuiltCartUserEmailSelector, isAdminLoggedInAsResellerUserSelector, userEmailSelector, (resellerAdminMode, preBuiltCartType, preBuiltCartUserEmail, isAdminLoggedInAsResellerUser, userEmail) => {
  if (resellerAdminMode === ResellerAdminMode.Browse || !resellerAdminMode && isAdminLoggedInAsResellerUser) {
    return `You are currently browsing as ${preBuiltCartUserEmail || userEmail}`;
  }
  if (resellerAdminMode === ResellerAdminMode.CreatingCart) {
    return `Building a ${PRE_BUILT_CART_LABEL[preBuiltCartType]} cart for ${preBuiltCartUserEmail}`;
  }
  if (resellerAdminMode === ResellerAdminMode.EditingCart) {
    return `Editing a ${PRE_BUILT_CART_LABEL[preBuiltCartType]} cart for ${preBuiltCartUserEmail}`;
  }
  if (resellerAdminMode === ResellerAdminMode.CreatingPresentation) {
    return `Creating presentation for ${preBuiltCartUserEmail}`;
  }
  if (resellerAdminMode === ResellerAdminMode.EditingPresentation) {
    return `Editing presentation for ${preBuiltCartUserEmail}`;
  }
  if (resellerAdminMode === ResellerAdminMode.CreatingInvoice) {
    return `Creating invoice for ${preBuiltCartUserEmail}`;
  }
  if (resellerAdminMode === ResellerAdminMode.EditingInvoice) {
    return `Editing invoice for ${preBuiltCartUserEmail}`;
  }
  if (resellerAdminMode === ResellerAdminMode.OrderCreation) {
    return `Placing an order for ${preBuiltCartUserEmail}`;
  }
  return '';
});
export const resellerUserBonusCommissionSelector = createSelector(userSelector, cartTotalSelector, ({
  resellerBonusCommission = 0,
  resellerBonusCommissionThreshold = 0
}, cartTotal) => {
  return cartTotal < resellerBonusCommissionThreshold ? resellerBonusCommission : 0;
});
export const resellerAdminModeInfoBarTextSelector = createSelector(resellerAdminModeSelector, companyMarginSelector, pathnameSelector, resellerUserBonusCommissionSelector, (resellerAdminMode, companyMargin, path, bonusCommission) => {
  const discountValue = Math.round((companyMargin + bonusCommission) * 100);
  if (resellerAdminMode === ResellerAdminMode.OrderCreation) {
    switch (path) {
      case APP_ROUTES.CHECKOUT:
      case APP_ROUTES.DELIVERY:
        return 'Keep in mind, these are the prices you will be paying.';
      default:
        return `Keep in mind, these are the prices your customer will be paying. You will have a ${discountValue}% discount when placing the order.`;
    }
  }
  if (checkIsInvoiceMode(resellerAdminMode)) {
    return `Keep in mind, these are the prices your customer will be paying. You will have a ${discountValue}% discount when placing the order.`;
  }
  return '';
});
export const userAdminRoleSelector = createSelector(userSelector, user => user.isAdminLoginAsUser ? user.adminRole : '');
export const userAdminPoliciesSelector = createSelector(userSelector, user => user.isAdminLoginAsUser ? user.adminRolePolicies : []);
export const isAdminLoginAsUserAndNotAsOpsTeamAndNotAsCustomInkAdminSelector = createSelector(userSelector, user => user.isAdminLoginAsUser && !user.isResellerAdminLoggedAsResellerUser && user.adminRole && user.adminRole !== USER_ROLES.OPS_TEAM && user.adminRole !== USER_ROLES.CUSTOM_INK_PRO_ADMIN);
export const isCreditsInfoShownSelector = createSelector(userSelector, user => {
  var _user$passedActions;
  return (_user$passedActions = user.passedActions) === null || _user$passedActions === void 0 ? void 0 : _user$passedActions.creditsInfoShown;
});
export const loginFailureCodeSelector = createSelector(userSelector, userState => userState.loginFailureCode);
export const hasResellerUserNetOrAchSelector = createSelector(userSelector, ({
  tenant,
  paymentMethod
}) => {
  if (!tenant) {
    return false;
  }
  return getHasResellerOrderNetOrACHPaymentMethod({
    paymentMethod,
    tenant
  });
});
export const isTaxExemptSelector = createSelector(userSelector, ({
  isTaxExempt
}) => isTaxExempt);
export const isUserResellerAdminSelector = createSelector(userSelector, ({
  isResellerAdmin
}) => isResellerAdmin);
export const isUserResellerCompanyOwnerSelector = createSelector(userSelector, ({
  isResellerCompanyOwner
}) => isResellerCompanyOwner);