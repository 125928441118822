import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { loadingSelector } from '../../../redux/company-departments/company-departments.selectors';
import { deleteCompanyDepartment } from '../../../redux/company-departments/company-departments.actions';
import { DeleteCompanyDepartmentPopupComponent } from './delete-company-department-popup';
const mapStateToProps = createStructuredSelector({
  loading: loadingSelector
});
const mapDispatchToProps = dispatch => ({
  onDeleteSubmit: id => {
    dispatch(deleteCompanyDepartment(id));
  },
  onDeleteCancel: () => {
    dispatch(hidePopup());
  }
});
export const DeleteCompanyDepartmentPopup = connect(mapStateToProps, mapDispatchToProps)(DeleteCompanyDepartmentPopupComponent);