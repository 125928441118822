function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { requiredValidator, validate } from 'swag-client-common/utils/validators';
const PREFIX = 'BILLING_ADDRESS_';
export const EDIT_FIELD = `${PREFIX}EDIT_FIELD`;
export const editField = (field, value) => ({
  type: EDIT_FIELD,
  field,
  value
});
export const VALIDATED = `${PREFIX}VALIDATED`;
export const validateBillingAddress = () => (dispatch, getState) => {
  const {
    billingAddress: {
      address,
      mandatoryKeys
    }
  } = getState();
  const errors = mandatoryKeys.reduce((all, key) => {
    const error = validate(address[key], requiredValidator);
    return error ? _objectSpread({}, all, {
      [key]: error
    }) : all;
  }, {});
  dispatch({
    type: VALIDATED,
    payload: errors
  });
};