function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { BOX_BUILDER_SELECT_PRODUCTS_DRAWER, CART_BOX_BUILDER_POPUP, ORDER_MORE_BOX_EDIT_BOX, ORDER_MORE_BOX_ADD_PRODUCTS, ORDER_MORE_BOX_EDIT_QUANTITY_WARNING_POPUP, PREVENT_UNSAVED_BOX_BUILDER_PROGRESS_POPUP, SUPER_SPEED_BOX_CONFIRMATION, CONFIRM_TRANSFORM_SUPER_SPEED_ITEMS_POPUP, REMOVE_ITEM_FROM_CART_POPUP } from 'swag-client-common/constants';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { loggedInUserIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { OrderApi } from 'swag-client-common/api/order.api';
import { formatCart } from 'swag-client-common/redux/cart/cart.utils';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { formatCmyk } from 'swag-common/utils/cmyk.util';
import { CartErrorCodes } from 'swag-common/constants/error-codes';
import { OrderStorageTypes } from 'swag-common/constants/main-swag.constants';
import { getFullBox, isBox, isItemBoxAddon } from 'swag-common/utils/order/item-is-box.util';
import { draftTokenSelector, orderVersionSelector, draftVersionSelector } from 'swag-client-common/redux/cart/cart.selectors';
import { getSuperSpeedSettings } from 'swag-client-common/redux/settings/settings-other.actions';
import { isOrderMore } from 'swag-common/business-logic/order/is-order-more-item.logic';
import { DesignUploadMode } from 'swag-common/interfaces';
import { uploadBrownBoxLogoToAWS, uploadCustomBoxLogoToAWS } from 'swag-client-common/redux/box-builder/box-builder.actions';
import { cartItemsSelector } from '../inventory/inventory.selectors';
import { getAddonProductsForBox } from '../product/product.actions';
import { addOnProductsForBoxSelector, flatDraftItemsSelector, specialBoxProductsSelector, draftItemsSelector } from '../cart/cart.selectors';
import { getItemFromSpecialBoxProduct } from '../cart/cart.utils';
import { notecardEditorDataSelector } from '../notecard-editor/notecard-editor.selectors';
import { flatCartItemsSelector } from '../cart/cart.selectors.no-circular';
import { getNotecardDesignData } from '../notecard-editor/notecard-editor.utils';
import { ANIMATION_TRANSITION_TIME } from './box-builder.constants';
import { addonCardItemsSelector, boxNameSelector, coloredBoxInfoSelector, currentBoxesQuantitySelector, customBoxInfoSelector, editedQuantityInCartDataSelector, fullyCustomBoxInfoSelector, productCardItemsSelector, selectedBoxTypeSelector, editingBoxNameBoxIdSelector, selectedOrExistedFullyCustomBoxInfoSelector, selectedOrExistedColoredBoxInfoSelector, customBoxInfoValuesSelector, brownBoxInfoValuesSelector, editingBoxSelector, editingBoxTypeBoxIdSelector } from './box-builder.selectors';
import { saveBoxToCart } from './add-box-to-cart';
import { makeBoxItemForCart, addSpecialProductTypeToBox, makeBoxTapeItemForCart, generateSaveBoxItselfUpdatedItems } from './box-builder.utils';
import { editBoxItems } from './edit-box-items';
const PREFIX = 'BOX_BUILDER_';
const handleBoxSaveError = (dispatch, getState, error) => {
  switch (error.code) {
    case CartErrorCodes.INVALID_ORDER_VERSION:
      dispatch(showNotification({
        text: error.message
      }));
      break;
    case CartErrorCodes.SUPER_SPEED_DISABLED:
      dispatch(showPopup({
        popup: CONFIRM_TRANSFORM_SUPER_SPEED_ITEMS_POPUP
      }));
      break;
    default:
      dispatch(showNotification({
        text: 'Changes was not saved, something went wrong'
      }));
      break;
  }
};
export const BOX_INFO_STEP_COMPLETED = `${PREFIX}BOX_INFO_STEP_COMPLETED`;
export const changeBoxInfoStepCompleted = payload => ({
  type: BOX_INFO_STEP_COMPLETED,
  payload
});
export const SAVE_BOX_NAME = `${PREFIX}SAVE_BOX_NAME`;
export const saveBoxName = payload => ({
  type: SAVE_BOX_NAME,
  payload
});
export const TYPE_BOX_NAME = `${PREFIX}TYPE_BOX_NAME`;
export const typeBoxName = payload => ({
  type: TYPE_BOX_NAME,
  payload
});
export const SAVE_BOX_TYPE = `${PREFIX}SAVE_BOX_TYPE`;
export const saveBoxType = boxType => ({
  type: SAVE_BOX_TYPE,
  payload: boxType
});
export const CHOOSE_BOX_TYPE = `${PREFIX}CHOOSE_BOX_TYPE`;
export const chooseBoxType = boxType => ({
  type: CHOOSE_BOX_TYPE,
  payload: boxType
});
export const SAVE_COLORED_BOX_INFO = `${PREFIX}SAVE_COLORED_BOX_INFO`;
export const saveColoredBoxInfo = boxColor => ({
  type: SAVE_COLORED_BOX_INFO,
  payload: boxColor
});
export const SELECT_COLORED_BOX_INFO = `${PREFIX}SELECT_COLORED_BOX_INFO`;
export const selectColoredBoxInfo = boxColor => ({
  type: SELECT_COLORED_BOX_INFO,
  payload: boxColor
});
export const TYPE_FULLY_CUSTOM_BOX_INFO = `${PREFIX}TYPE_FULLY_CUSTOM_BOX_INFO`;
export const typeFullyCustomBoxInfo = note => ({
  type: TYPE_FULLY_CUSTOM_BOX_INFO,
  payload: note
});
export const SET_CUSTOM_BOX_COLOR = `${PREFIX}SET_CUSTOM_BOX_COLOR`;
export const setCustomBoxColor = color => ({
  type: SET_CUSTOM_BOX_COLOR,
  payload: color
});
export const SAVE_CUSTOM_BOX_INFO = `${PREFIX}SAVE_CUSTOM_BOX_INFO`;
export const saveCustomBoxInfo = ({
  color
}) => ({
  type: SAVE_CUSTOM_BOX_INFO,
  payload: {
    color
  }
});
export const SAVE_BROWN_BOX_INFO = `${PREFIX}SAVE_BROWN_BOX_INFO`;
export const saveBrownBoxInfo = () => ({
  type: SAVE_BROWN_BOX_INFO
});
export const SAVE_FULLY_CUSTOM_BOX_INFO = `${PREFIX}SAVE_FULLY_CUSTOM_BOX_INFO`;
export const saveFullyCustomBoxInfo = note => ({
  type: SAVE_FULLY_CUSTOM_BOX_INFO,
  payload: note
});
export const RESET_BOX_BUILDER_STATE = `${PREFIX}RESET_BOX_BUILDER_STATE`;
export const resetBoxBuilderState = () => ({
  type: RESET_BOX_BUILDER_STATE
});
export const SELECT_BACK_BUTTON = `${PREFIX}SELECT_BACK_BUTTON`;
export const selectBackButton = step => ({
  type: SELECT_BACK_BUTTON,
  payload: step
});
export const CHANGE_CURRENT_BOXES_QUANTITY = `${PREFIX}CHANGE_CURRENT_BOXES_QUANTITY`;
export const changeCurrentBoxesQuantity = currentBoxesQuantity => ({
  type: CHANGE_CURRENT_BOXES_QUANTITY,
  payload: currentBoxesQuantity
});
export const OPEN_EDIT_QUANTITY_IN_CART = `${PREFIX}OPEN_EDIT_QUANTITY_IN_CART`;
export const openEditQuantityInCartPopup = (itemId, variantIndex) => ({
  type: OPEN_EDIT_QUANTITY_IN_CART,
  payload: {
    itemId,
    variantIndex
  }
});
export const SAVE_NEW_QUANTITY_IN_CART_FOR_ITEM_PRODUCT_CARD = `${PREFIX}SAVE_NEW_QUANTITY_IN_CART_FOR_ITEM_PRODUCT_CARD`;
export const saveNewQuantityInCartForItemProductCard = offsetQuantity => ({
  type: SAVE_NEW_QUANTITY_IN_CART_FOR_ITEM_PRODUCT_CARD,
  payload: {
    offsetQuantity
  }
});
export const EDIT_CURRENT_QUANTITY_IN_CART = `${PREFIX}EDIT_CURRENT_QUANTITY_IN_CART`;
export const editCurrentQuantityInCart = quantities => ({
  type: EDIT_CURRENT_QUANTITY_IN_CART,
  payload: quantities
});
export const CLOSE_EDIT_QUANTITY_IN_CART = `${PREFIX}CLOSE_EDIT_QUANTITY_IN_CART`;
export const closeEditQuantityInCartPopup = () => ({
  type: CLOSE_EDIT_QUANTITY_IN_CART
});
export const SAVE_SELECTED_CART_ITEMS_TO_BOX = `${PREFIX}SAVE_SELECTED_CART_ITEMS_TO_BOX`;
export const saveSelectedCartItemsToBox = () => ({
  type: SAVE_SELECTED_CART_ITEMS_TO_BOX
});
export const CHANGE_PRODUCT_CARD_SELECTIONS = `${PREFIX}CHANGE_PRODUCT_CARD_SELECTIONS`;
export const changeProductCardSelections = ({
  isSelected,
  itemId,
  variantIndex,
  prefillQuantityPerBox
}) => ({
  type: CHANGE_PRODUCT_CARD_SELECTIONS,
  payload: {
    isSelected,
    itemId,
    variantIndex,
    prefillQuantityPerBox
  }
});
export const CHANGE_PRODUCT_CARD_QUANTITY_PER_BOX = `${PREFIX}CHANGE_PRODUCT_CARD_QUANTITY_PER_BOX`;
export const changeProductCardQuantityPerBox = ({
  quantityPerBox,
  itemId,
  variantIndex
}) => ({
  type: CHANGE_PRODUCT_CARD_QUANTITY_PER_BOX,
  payload: {
    quantityPerBox,
    itemId,
    variantIndex
  }
});
export const CHANGE_PRODUCT_CARD_QUANTITIES = `${PREFIX}CHANGE_PRODUCT_CARD_QUANTITIES`;
export const changeProductCardQuantities = ({
  quantities,
  itemId,
  variantIndex
}) => ({
  type: CHANGE_PRODUCT_CARD_QUANTITIES,
  payload: {
    quantities,
    itemId,
    variantIndex
  }
});
export const CHANGE_ADDON_CARD_SELECTIONS = `${PREFIX}CHANGE_ADDON_CARD_SELECTIONS`;
export const changeAddonCardSelections = ({
  isSelected,
  productId,
  prefillQuantityPerBox
}) => ({
  type: CHANGE_ADDON_CARD_SELECTIONS,
  payload: {
    isSelected,
    productId,
    prefillQuantityPerBox
  }
});
export const CHANGE_ADDON_CARD_QUANTITY_PER_BOX = `${PREFIX}CHANGE_ADDON_CARD_QUANTITY_PER_BOX`;
export const changeAddonCardQuantityPerBox = ({
  quantityPerBox,
  productId
}) => ({
  type: CHANGE_ADDON_CARD_QUANTITY_PER_BOX,
  payload: {
    quantityPerBox,
    productId
  }
});
export const onCloseBoxBuilderPopup = dispatch => hasUnsavedChanges => {
  if (hasUnsavedChanges) {
    dispatch(hidePopup({
      shouldControlScroll: false
    }));
    setTimeout(() => {
      dispatch(showPopup({
        popup: PREVENT_UNSAVED_BOX_BUILDER_PROGRESS_POPUP
      }));
    }, 250);
  } else {
    dispatch(hidePopup({
      callback: () => dispatch(resetBoxBuilderState()),
      callbackDelay: ANIMATION_TRANSITION_TIME
    }));
  }
};
export const showSuperSpeedBoxConfirmation = () => dispatch => {
  dispatch(hidePopup({
    shouldControlScroll: false
  }));
  setTimeout(() => {
    dispatch(showPopup({
      popup: SUPER_SPEED_BOX_CONFIRMATION
    }));
  }, 250);
};
export const UPDATE_CART_ITEMS_LOADING = `${PREFIX}UPDATE_CART_ITEMS_LOADING`;
export const UPDATE_CART_ITEMS_SUCCESS = `${PREFIX}UPDATE_CART_ITEMS_SUCCESS`;
export const UPDATE_CART_ITEMS_FAILED = `${PREFIX}UPDATE_CART_ITEMS_FAILED`;
export const saveNewBoxToCart = createAsyncAction({
  transformData: (_, getState) => {
    const state = getState();
    const items = [];
    const cartItems = cartItemsSelector(state);
    const selectedBoxType = selectedBoxTypeSelector(state);
    const boxTypes = specialBoxProductsSelector(state) || [];
    const coloredBoxInfo = coloredBoxInfoSelector(state);
    const fullyCustomBoxInfo = fullyCustomBoxInfoSelector(state);
    const brownBoxInfoValues = brownBoxInfoValuesSelector(state);
    const customBoxInfo = customBoxInfoSelector(state);
    const boxName = boxNameSelector(state);
    const productCardItems = productCardItemsSelector(state);
    const currentBoxesQuantity = currentBoxesQuantitySelector(state);
    const addonCardItems = addonCardItemsSelector(state);
    const addonList = addOnProductsForBoxSelector(state);
    const editedQuantityInCart = editedQuantityInCartDataSelector(state);
    const prod = boxTypes.find(i => i.specialProductType === selectedBoxType);
    const boxTypeProduct = boxTypes.find(i => i.specialProductType === SPECIAL_PRODUCT_TYPES.BOX_TAPE);
    const boxItem = makeBoxItemForCart({
      prod,
      boxName,
      selectedBoxType
    });
    const boxTapeItem = makeBoxTapeItemForCart({
      prod: boxTypeProduct,
      selectedBoxType,
      initialCreationDate: Date.now()
    });
    switch (selectedBoxType) {
      case SPECIAL_PRODUCT_TYPES.FULLY_CUSTOM_BOX:
        {
          if (fullyCustomBoxInfo) {
            boxItem.designNotes = fullyCustomBoxInfo.note;
          }
          break;
        }
      case SPECIAL_PRODUCT_TYPES.COLORED_BOX:
        {
          if (coloredBoxInfo && prod) {
            const {
              color
            } = coloredBoxInfo;
            const variantIndex = prod.variants.findIndex(v => v.color === color);
            boxItem.variants = [{
              quantities: [currentBoxesQuantity],
              variantIndex
            }];
          }
          break;
        }
      case SPECIAL_PRODUCT_TYPES.BROWN_BOX:
        {
          if (brownBoxInfoValues) {
            const {
              tapeLogo
            } = brownBoxInfoValues;
            boxTapeItem.logos = {
              front: [tapeLogo]
            };
          }
          break;
        }
      default:
        {
          if (customBoxInfo) {
            const {
              color,
              logo
            } = customBoxInfo;
            boxItem.boxColor = formatCmyk(color);
            boxItem.logos = {
              front: [logo]
            };
          }
          break;
        }
    }
    items.push(...saveBoxToCart({
      cartItems,
      productCardItems,
      addonCardItems,
      addonList,
      currentBoxesQuantity,
      boxItem,
      boxTapeItem,
      editedQuantityInCart
    }));
    return formatCart({
      items
    }, getState);
  },
  preFn: dispatch => dispatch({
    type: UPDATE_CART_ITEMS_LOADING
  }),
  fn: OrderApi.updateCart,
  success: res => ({
    type: UPDATE_CART_ITEMS_SUCCESS,
    body: res.cart
  }),
  error: error => ({
    type: UPDATE_CART_ITEMS_FAILED,
    error
  }),
  postError: handleBoxSaveError,
  postSuccess: dispatch => {
    onCloseBoxBuilderPopup(dispatch)(false);
  }
});
export const uploadBoxLogo = file => dispatch => dispatch(uploadLogoToAWS(file));
export const uploadLogoToAWS = file => (dispatch, getState) => {
  const state = getState();
  const selectedBoxType = selectedBoxTypeSelector(state);
  if (selectedBoxType === SPECIAL_PRODUCT_TYPES.BROWN_BOX) {
    dispatch(uploadBrownBoxLogoToAWS(file));
  } else {
    dispatch(uploadCustomBoxLogoToAWS(file));
  }
};
export const SET_BOX_BUILDER_EDIT_MODE_BOX_ITEMS_ID = `${PREFIX}SET_BOX_BUILDER_EDIT_MODE_BOX_ITEMS_ID`;
export const setBoxBuilderEditModeBoxItemsId = id => ({
  type: SET_BOX_BUILDER_EDIT_MODE_BOX_ITEMS_ID,
  payload: id
});
export const SET_BOX_TYPE_EDIT_ID = `${PREFIX}SET_BOX_TYPE_EDIT_ID`;
export const setEditingBoxTypeBoxId = id => ({
  type: SET_BOX_TYPE_EDIT_ID,
  payload: id
});
export const saveEditedBoxName = createAsyncAction({
  transformData: (_, getState) => {
    const state = getState();
    const name = boxNameSelector(state);
    const id = editingBoxNameBoxIdSelector(state);
    const cartItems = cartItemsSelector(state);
    const cartDraftItems = draftItemsSelector(state);
    const items = cartItems.map(item => item._id === id ? _objectSpread({}, item, {
      name
    }) : item);
    const isCartItem = items.some(item => item._id === id);
    const draftItems = cartDraftItems.map(item => item._id === id ? _objectSpread({}, item, {
      name
    }) : item);
    if (!isCartItem) {
      const draftToken = draftTokenSelector(state);
      const orderVersion = orderVersionSelector(state);
      const draftVersion = draftVersionSelector(state);
      return {
        userId: loggedInUserIdSelector(state),
        draftItems,
        draftToken,
        orderVersion,
        draftVersion
      };
    }
    return formatCart({
      items
    }, getState);
  },
  preFn: dispatch => dispatch({
    type: UPDATE_CART_ITEMS_LOADING
  }),
  fn: OrderApi.updateCart,
  success: res => {
    return res.cart ? {
      type: UPDATE_CART_ITEMS_SUCCESS,
      body: res.cart
    } : {
      type: UPDATE_DRAFT_ITEMS_SUCCESS,
      body: res.draft
    };
  },
  error: error => ({
    type: UPDATE_CART_ITEMS_FAILED,
    error
  }),
  postError: handleBoxSaveError,
  postSuccess: dispatch => {
    onCloseBoxBuilderPopup(dispatch)(false);
  }
});
export const saveEditedBoxItselfInfo = createAsyncAction({
  transformData: (_, getState) => {
    var _customBoxInfoValuesS;
    const state = getState();
    const cartItems = cartItemsSelector(state);
    const editingBox = editingBoxSelector(state);
    const selectedBoxType = selectedBoxTypeSelector(state);
    const boxTypes = specialBoxProductsSelector(state);
    const coloredBoxInfo = selectedOrExistedColoredBoxInfoSelector(state);
    const brownBoxInfo = brownBoxInfoValuesSelector(state);
    const fullyCustomBoxInfo = selectedOrExistedFullyCustomBoxInfoSelector(state);
    const customBoxInfo = (_customBoxInfoValuesS = customBoxInfoValuesSelector(state)) !== null && _customBoxInfoValuesS !== void 0 ? _customBoxInfoValuesS : {};
    const updatedItems = generateSaveBoxItselfUpdatedItems({
      box: editingBox,
      boxTypes,
      cartItems,
      selectedBoxType,
      brownBoxInfo,
      customBoxInfo,
      coloredBoxInfo,
      fullyCustomBoxInfo,
      initialCreationDate: Date.now()
    });
    return formatCart({
      items: updatedItems
    }, getState);
  },
  preFn: dispatch => dispatch({
    type: UPDATE_CART_ITEMS_LOADING
  }),
  fn: OrderApi.updateCart,
  success: res => ({
    type: UPDATE_CART_ITEMS_SUCCESS,
    body: res.cart
  }),
  error: error => ({
    type: UPDATE_CART_ITEMS_FAILED,
    error
  }),
  postError: handleBoxSaveError,
  postSuccess: dispatch => {
    onCloseBoxBuilderPopup(dispatch)(false);
  }
});
export const SET_BOX_NAME_EDIT_ID = `${PREFIX}SET_BOX_NAME_EDIT_ID`;
export const setEditingBoxNameBoxId = id => ({
  type: SET_BOX_NAME_EDIT_ID,
  payload: id
});
export const UPDATE_DRAFT_ITEMS_SUCCESS = `${PREFIX}UPDATE_DRAFT_ITEMS_SUCCESS`;
export const saveEditedBoxItems = createAsyncAction({
  transformData: ({
    isSpecialProductsFlow,
    specialProductFlowData,
    boxId,
    isCart
  }, getState) => {
    const state = getState();
    const items = [];
    const cartItems = isCart ? flatCartItemsSelector(state) : flatDraftItemsSelector(state);
    if (isSpecialProductsFlow) {
      //for crinkle paper and notecard
      if (specialProductFlowData) {
        const specialItems = addSpecialProductTypeToBox(cartItems, specialProductFlowData);
        items.push(...specialItems);
      }
      if (isCart) {
        return formatCart({
          items
        }, getState);
      }
      const draftToken = draftTokenSelector(state);
      const orderVersion = orderVersionSelector(state);
      const draftVersion = draftVersionSelector(state);
      return {
        userId: loggedInUserIdSelector(state),
        draftItems: items,
        draftToken,
        orderVersion,
        draftVersion
      };
    }
    const productCardItems = productCardItemsSelector(state);
    const currentBoxesQuantity = currentBoxesQuantitySelector(state);
    const addonCardItems = addonCardItemsSelector(state);
    const editedQuantityInCart = editedQuantityInCartDataSelector(state);
    const addonList = addOnProductsForBoxSelector(state);
    const {
      boxItemsId
    } = cartItems.find(i => i._id === boxId);
    items.push(...editBoxItems({
      cartItems,
      productCardItems,
      addonCardItems,
      addonList,
      currentBoxesQuantity,
      boxItemsId,
      editedQuantityInCart
    }));
    return formatCart({
      items
    }, getState);
  },
  preFn: dispatch => dispatch({
    type: UPDATE_CART_ITEMS_LOADING
  }),
  fn: OrderApi.updateCart,
  success: res => {
    return res.cart ? {
      type: UPDATE_CART_ITEMS_SUCCESS,
      body: res.cart
    } : {
      type: UPDATE_DRAFT_ITEMS_SUCCESS,
      body: res.draft
    };
  },
  error: error => ({
    type: UPDATE_CART_ITEMS_FAILED,
    error
  }),
  postError: handleBoxSaveError,
  postSuccess: dispatch => {
    onCloseBoxBuilderPopup(dispatch)(false);
  }
});
export const startEditBoxQuantity = itemId => (dispatch, getState) => {
  const cartItems = cartItemsSelector(getState());
  const item = cartItems.find(i => i._id === itemId);
  const box = cartItems.find(i => isBox(i) && i.boxItemsId === (item === null || item === void 0 ? void 0 : item.boxItemsId));
  if (!box) {
    throw Error(`There is no box for item ${itemId}.`);
  }
  dispatch(getAddonProductsForBox());
  dispatch(setBoxBuilderEditModeBoxItemsId(box._id));
  dispatch(showPopup({
    popup: BOX_BUILDER_SELECT_PRODUCTS_DRAWER
  }));
  if (isItemBoxAddon(item)) {
    dispatch(saveSelectedCartItemsToBox());
  }
};
export const showBoxBuilderPopupInCustomBoxStep = () => (dispatch, getState) => {
  const id = editingBoxTypeBoxIdSelector(getState());
  dispatch(hidePopup({
    shouldControlScroll: false
  }));
  setTimeout(() => {
    if (id) {
      dispatch(setEditingBoxTypeBoxId(id));
    }
    dispatch(showPopup({
      popup: CART_BOX_BUILDER_POPUP
    }));
  }, 250);
};
export const startEditBoxBuilderMode = itemId => (dispatch, getState) => {
  var _fullBox$;
  const state = getState();
  const cartItems = cartItemsSelector(state);
  const item = cartItems.find(i => i._id === itemId);
  const fullBox = cartItems.filter(i => i.boxItemsId && item.boxItemsId === i.boxItemsId);
  if (Boolean(fullBox.length) && fullBox.every(isOrderMore) && fullBox !== null && fullBox !== void 0 && (_fullBox$ = fullBox[0]) !== null && _fullBox$ !== void 0 && _fullBox$.inventoryId) {
    return dispatch(showPopup({
      popup: ORDER_MORE_BOX_EDIT_QUANTITY_WARNING_POPUP,
      item: {
        _id: item._id
      }
    }));
  }
  dispatch(startEditBoxQuantity(itemId));
};
export const onEditBoxClickAction = boxId => (dispatch, getState) => {
  const state = getState();
  const cartItems = cartItemsSelector(state);
  const boxItself = cartItems.find(i => i._id === boxId);
  const fullBox = getFullBox(boxItself, cartItems);
  if (isShowWarningOrderMorePopupPopup(fullBox)) {
    return dispatch(showPopup({
      popup: ORDER_MORE_BOX_EDIT_BOX,
      item: boxItself
    }));
  }
  dispatch(getSuperSpeedSettings());
  dispatch(setEditingBoxTypeBoxId(boxId));
  dispatch(showPopup({
    popup: CART_BOX_BUILDER_POPUP
  }));
};
export const onAddProductsClickAction = boxId => (dispatch, getState) => {
  const state = getState();
  const cartItems = cartItemsSelector(state);
  const boxItself = cartItems.find(i => i._id === boxId);
  const fullBox = getFullBox(boxItself, cartItems);
  if (isShowWarningOrderMorePopupPopup(fullBox)) {
    return dispatch(showPopup({
      popup: ORDER_MORE_BOX_ADD_PRODUCTS,
      item: boxItself
    }));
  }
  dispatch(getAddonProductsForBox());
  dispatch(setBoxBuilderEditModeBoxItemsId(boxId));
  dispatch(showPopup({
    popup: BOX_BUILDER_SELECT_PRODUCTS_DRAWER
  }));
};
const isShowWarningOrderMorePopupPopup = fullBox => {
  var _fullBox$2;
  return Boolean(fullBox.length) && fullBox.every(isOrderMore) && (fullBox === null || fullBox === void 0 ? void 0 : (_fullBox$2 = fullBox[0]) === null || _fullBox$2 === void 0 ? void 0 : _fullBox$2.inventoryId);
};
export const toggleSpecialProductInBox = (boxItemsId, specialProductType, values) => (dispatch, getState) => {
  const {
    isNotecardPicked,
    crinklePaperVariantIndex,
    notecardDefaultImage
  } = values;
  const state = getState();
  const specialBoxProducts = specialBoxProductsSelector(state);
  const cartItems = cartItemsSelector(state);
  const draftItems = draftItemsSelector(state);
  let isCart = true;
  let boxItemItself = cartItems.find(item => item.boxItemsId === boxItemsId && item.prod.designUploadMode === DesignUploadMode.box);
  if (!boxItemItself) {
    isCart = false;
    boxItemItself = draftItems.find(item => item.boxItemsId === boxItemsId && item.prod.designUploadMode === DesignUploadMode.box);
  }
  const fullBox = getFullBox(boxItemItself, isCart ? cartItems : draftItems);

  // BOX NOTECARD ABSENT FLOW
  if (isNotecardPicked === false) {
    const specialBoxProductItemToRemove = fullBox.find(item => {
      var _item$prod;
      return ((_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.specialProductType) === specialProductType;
    });
    if (!specialBoxProductItemToRemove) {
      return dispatch(saveEditedBoxItems({
        isSpecialProductsFlow: true,
        specialProductFlowData: {
          boxItemsId,
          isBoxNotecardAbsent: true
        },
        isCart
      }));
    }
    return dispatch(showPopup({
      popup: REMOVE_ITEM_FROM_CART_POPUP,
      itemId: specialBoxProductItemToRemove._id,
      absentNotecardInBox: boxItemItself._id,
      storageType: isCart ? OrderStorageTypes.CART : OrderStorageTypes.DRAFT,
      isRemoveFromBox: true
    }));
  }
  const specialBoxProduct = specialBoxProducts.find(product => product.specialProductType === specialProductType);
  if (!specialBoxProduct) {
    return null;
  }

  //  UPDATE BOX CRINKLE PAPER OR NOTECARD FLOW
  const existingCrinklePaperOrNotecard = fullBox.find(item => {
    var _item$prod2;
    return ((_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : _item$prod2.specialProductType) === specialProductType;
  });
  const notecardEditorData = notecardEditorDataSelector(state);
  const notecardDesignData = getNotecardDesignData(notecardEditorData, notecardDefaultImage);
  if (existingCrinklePaperOrNotecard) {
    const specialProductItem = _objectSpread({}, existingCrinklePaperOrNotecard, getItemFromSpecialBoxProduct({
      boxItemItself,
      specialBoxProduct,
      variantIndex: crinklePaperVariantIndex,
      notecardDesignData
    }));
    return dispatch(saveEditedBoxItems({
      isSpecialProductsFlow: true,
      specialProductFlowData: {
        boxItemsId,
        specialProductItem
      },
      isCart
    }));
  }
  //CREATE NEW BOX NOTECARD OR CRINKLE PAPER FLOW
  const specialProductItem = getItemFromSpecialBoxProduct({
    boxItemItself,
    specialBoxProduct,
    notecardDesignData,
    variantIndex: crinklePaperVariantIndex
  });
  dispatch(saveEditedBoxItems({
    isSpecialProductsFlow: true,
    specialProductFlowData: {
      boxItemsId,
      specialProductItem,
      isBoxNotecardAbsent: Boolean(boxItemItself.isBoxNotecardAbsent)
    },
    isCart
  }));
};