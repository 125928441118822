import { ZIP_PATTERNS } from 'swag-common/constants/validations';
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
const MIN_ZIP_CODE_LENGTH_US = 5;
export const INVALID_ZIP_CODE_MESSAGE_DEFAULT = 'Zip code is invalid';
export const INVALID_ZIP_CODE_MESSAGE_BY_COUNTRY = {
  [COUNTRY_CODES.US]: `Zip code is invalid. Should be at least ${MIN_ZIP_CODE_LENGTH_US} numbers`,
  [COUNTRY_CODES.CA]: 'Zip code is invalid. Should be in format: A1A 1A1'
};
export const INVALID_ZIP_CODE_MESSAGE_NO_COUNTRY = "Can't validate zip code without country";
const getDefaultInvalidZipMessage = country => {
  return INVALID_ZIP_CODE_MESSAGE_BY_COUNTRY[country] || INVALID_ZIP_CODE_MESSAGE_DEFAULT;
};
const validateZipCodeUs = (zip = '') => {
  if (zip.length < MIN_ZIP_CODE_LENGTH_US) {
    return getDefaultInvalidZipMessage(COUNTRY_CODES.US);
  }
  const regex = new RegExp(ZIP_PATTERNS[COUNTRY_CODES.US]);
  const isValid = regex.test(zip);
  if (!isValid) {
    return getDefaultInvalidZipMessage(COUNTRY_CODES.US);
  }
  return null;
};
const validateZipCodeCa = zip => {
  const regex = new RegExp(ZIP_PATTERNS[COUNTRY_CODES.CA]);
  const isValid = regex.test(zip);
  if (!isValid) {
    return getDefaultInvalidZipMessage(COUNTRY_CODES.CA);
  }
  return null;
};
export const validateZipCodeByCountry = (zip, country) => {
  if (!zip) {
    return INVALID_ZIP_CODE_MESSAGE_DEFAULT;
  }
  if (!country) {
    return INVALID_ZIP_CODE_MESSAGE_NO_COUNTRY;
  }
  switch (true) {
    case country === COUNTRY_CODES.US:
      {
        return validateZipCodeUs(zip);
      }
    case country === COUNTRY_CODES.CA:
      {
        return validateZipCodeCa(zip);
      }
    default:
      return null;
  }
};