import { OrderApi } from 'swag-client-common/api/order.api';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from '../notification/notification.actions';
const PREFIX = 'BOX_BUILDER_';
export const UPLOAD_BROWN_BOX_LOGO_LOADING = `${PREFIX}UPLOAD_BROWN_BOX_LOGO_REQUESTED`;
export const UPLOAD_BROWN_BOX_LOGO_FAIL = `${PREFIX}UPLOAD_BROWN_BOX_LOGO_FAIL`;
export const UPLOAD_BROWN_BOX_LOGO_SUCCESS = `${PREFIX}UPLOAD_BROWN_BOX_LOGO_SUCCESS`;
export const uploadBrownBoxLogoToAWS = createAsyncAction({
  transformData: file => {
    const formData = new FormData();
    formData.set('logo', file);
    formData.set('view', 'front');
    return formData;
  },
  preFn: dispatch => dispatch({
    type: UPLOAD_BROWN_BOX_LOGO_LOADING
  }),
  fn: OrderApi.convertLogo,
  success: body => ({
    type: UPLOAD_BROWN_BOX_LOGO_SUCCESS,
    body
  }),
  error: error => ({
    type: UPLOAD_BROWN_BOX_LOGO_FAIL,
    error
  }),
  postError: dispatch => {
    dispatch(showNotification({
      text: 'Logo for box was not saved, something went wrong'
    }));
  }
});
export const UPLOAD_CUSTOM_BOX_LOGO_LOADING = `${PREFIX}UPLOAD_CUSTOM_BOX_LOGO_REQUESTED`;
export const UPLOAD_CUSTOM_BOX_LOGO_FAIL = `${PREFIX}UPLOAD_CUSTOM_BOX_LOGO_FAIL`;
export const UPLOAD_CUSTOM_BOX_LOGO_SUCCESS = `${PREFIX}UPLOAD_CUSTOM_BOX_LOGO_SUCCESS`;
export const uploadCustomBoxLogoToAWS = createAsyncAction({
  transformData: file => {
    const formData = new FormData();
    formData.set('logo', file);
    formData.set('view', 'front');
    return formData;
  },
  preFn: dispatch => dispatch({
    type: UPLOAD_CUSTOM_BOX_LOGO_LOADING
  }),
  fn: OrderApi.convertLogo,
  success: body => ({
    type: UPLOAD_CUSTOM_BOX_LOGO_SUCCESS,
    body
  }),
  error: error => ({
    type: UPLOAD_CUSTOM_BOX_LOGO_FAIL,
    error
  }),
  postError: dispatch => {
    dispatch(showNotification({
      text: 'Logo for box was not saved, something went wrong'
    }));
  }
});