import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { OrderApi } from 'swag-client-common/api/order.api';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { singleItemLogosSelector } from '../account-orders/account-orders.selectors';
import { showReviewPantoneColorsPopup, resetApprovePantoneColorsItem } from '../account-orders/account-orders.actions';
import { updateLogosWithColors } from './item-pantones.utils';
import { selectedLogoIdSelector, isLastLogoSelector, logosIdsSelector, selectedItemIdSelector } from './item-pantones.selectors';
const PREFIX = 'ITEM_PANTONES_';
export const PREFILL_DATA = `${PREFIX}PREFILL_DATA`;
export const prefillData = logosData => ({
  payload: logosData,
  type: PREFILL_DATA
});
export const CHANGE_SELECTED = `${PREFIX}CHANGE_SELECTED`;
export const confirm = () => (dispatch, getState) => {
  const state = getState();
  const isLastLogo = isLastLogoSelector(state);
  const selectedLogoId = selectedLogoIdSelector(state);
  const logosIds = logosIdsSelector(state);
  if (isLastLogo) {
    return dispatch(approveColors());
  }
  const nextLogo = logosIds.find((id, index) => logosIds[index - 1] === selectedLogoId);
  dispatch({
    type: CHANGE_SELECTED,
    payload: nextLogo
  });
};
export const selectPrevious = () => (dispatch, getState) => {
  const state = getState();
  const selectedLogoId = selectedLogoIdSelector(state);
  const logosIds = logosIdsSelector(state);
  const previousLogo = logosIds.find((id, index) => logosIds[index + 1] === selectedLogoId);
  dispatch({
    type: CHANGE_SELECTED,
    payload: previousLogo
  });
};
export const ADD_COLOR = `${PREFIX}ADD_COLOR`;
export const addColor = colorData => ({
  type: ADD_COLOR,
  payload: colorData
});
export const REMOVE_COLOR = `${PREFIX}REMOVE_COLOR`;
export const removeColor = colorData => ({
  type: REMOVE_COLOR,
  payload: colorData
});
export const APPROVE_COLORS_STARTED = `${PREFIX}APPROVE_COLORS_STARTED`;
export const APPROVE_COLORS_SUCCESS = `${PREFIX}APPROVE_COLORS_SUCCESS`;
export const APPROVE_COLORS_ERROR = `${PREFIX}APPROVE_COLORS_ERROR`;
const approveColors = createAsyncAction({
  transformData: (data, getState) => {
    const {
      itemId,
      orderId,
      logos: updatedColorsData
    } = getState().itemPantones;
    const logos = singleItemLogosSelector(getState(), {
      orderId,
      itemId
    });
    const updatedLogos = updateLogosWithColors(logos, updatedColorsData);
    return {
      itemId,
      orderId,
      logos: updatedLogos
    };
  },
  preFn: dispatch => {
    dispatch({
      type: APPROVE_COLORS_STARTED
    });
  },
  fn: OrderApi.approveColors,
  success: order => ({
    type: APPROVE_COLORS_SUCCESS,
    order
  }),
  error: error => ({
    type: APPROVE_COLORS_ERROR,
    payload: {
      error
    },
    error
  }),
  postSuccess: (dispatch, getState, transformedData) => {
    dispatch(hidePopup());
    dispatch(resetApprovePantoneColorsItem(transformedData.itemId));
    dispatch(showReviewPantoneColorsPopup());
  },
  postError: dispatch => {
    dispatch(hidePopup());
    dispatch(showNotification({
      text: 'Colors were already approved'
    }));
  }
});
export const closePantoneColors = () => (dispatch, getState) => {
  const state = getState();
  const itemId = selectedItemIdSelector(state);
  dispatch(hidePopup());
  dispatch(resetApprovePantoneColorsItem(itemId));
  dispatch(showReviewPantoneColorsPopup());
};