import _isEqual from "lodash/isEqual";
import { isBox } from 'swag-common/utils/order/item-is-box.util';
const formatVariants = item => {
  return item.variants.map(v => v.variantIndex).sort();
};
export const checkIfItemNeedCustomerMockupsRecreation = (oldItem, newItem) => {
  var _oldItem$notecardDesi, _newItem$notecardDesi, _oldItem$notecardDesi2, _newItem$notecardDesi2;
  if (!oldItem || !newItem) {
    return true;
  }
  if (isBox(oldItem) && isBox(newItem)) {
    return false;
  }
  const isLogosEqual = _isEqual(oldItem.logos, newItem.logos);
  const isVariantsEqual = _isEqual(formatVariants(oldItem), formatVariants(newItem));
  const isNotecardDesignColorEqual = _isEqual((_oldItem$notecardDesi = oldItem.notecardDesignData) === null || _oldItem$notecardDesi === void 0 ? void 0 : _oldItem$notecardDesi.color, (_newItem$notecardDesi = newItem.notecardDesignData) === null || _newItem$notecardDesi === void 0 ? void 0 : _newItem$notecardDesi.color);
  const isNotecardDesignContentEqual = _isEqual((_oldItem$notecardDesi2 = oldItem.notecardDesignData) === null || _oldItem$notecardDesi2 === void 0 ? void 0 : _oldItem$notecardDesi2.textContent, (_newItem$notecardDesi2 = newItem.notecardDesignData) === null || _newItem$notecardDesi2 === void 0 ? void 0 : _newItem$notecardDesi2.textContent);
  return !isLogosEqual || !isVariantsEqual || !isNotecardDesignColorEqual || !isNotecardDesignContentEqual;
};