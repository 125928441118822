import { SOCKET_EVENTS } from 'swag-common/constants/socket-events';
import { initSharedWorkerFallback, isSharedWorkerSupported } from '../fallback-shared-worker';
import { SharedWorker } from '../worker-wrapper';
if (!isSharedWorkerSupported) {
  initSharedWorkerFallback();
}
const sharedWorker = new SharedWorker( /* webpackChunkName: "ecommerce-worker" */
new URL('./ecommerce-socket.worker.ts', import.meta.url));
if (isSharedWorkerSupported) {
  sharedWorker.port.start();
  sharedWorker.port.postMessage({
    type: SOCKET_EVENTS.IS_SW_SUPPORTED,
    payload: isSharedWorkerSupported
  });
} else {
  sharedWorker.start();
}
export const port = sharedWorker.port;