import _reset from "swag-client-common/redux-form-actions/reset";
import _getFormValues from "redux-form/es/getFormValues";
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { HUBSPOT_FIELDS } from 'swag-common/constants/hubspot';
import { ViewIdToScroll } from 'swag-common/constants/other';
import { scrollIntoView } from '../../../common/utils/tricks';
import { EmailApi } from '../../api/email.api';
import { CONTACT_FORM } from '../redux.constants';
const PREFIX = 'CONTACT_';
export const REQUEST_SENT = `${PREFIX}REQUEST_SENT`;
export const REQUEST_SUCCESS = `${PREFIX}REQUEST_SUCCESS`;
export const REQUEST_ERROR = `${PREFIX}REQUEST_ERROR`;
export const SENT_STATUS_RESET = `${PREFIX}SENT_STATUS_RESET`;
export const requestContact = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REQUEST_SENT
    });
  },
  transformData: (_, getState) => {
    const values = _getFormValues(CONTACT_FORM)(getState());
    return {
      name: `${values[HUBSPOT_FIELDS.FIRST_NAME]} ${values[HUBSPOT_FIELDS.LAST_NAME]}`,
      email: values[HUBSPOT_FIELDS.EMAIL],
      reason: values[HUBSPOT_FIELDS.REASON],
      message: values[HUBSPOT_FIELDS.MESSAGE]
    };
  },
  fn: EmailApi.sendContactRequest,
  success: () => ({
    type: REQUEST_SUCCESS
  }),
  error: error => ({
    type: REQUEST_ERROR,
    error
  }),
  postSuccess: dispatch => {
    dispatch(_reset(CONTACT_FORM));
    scrollIntoView(ViewIdToScroll);
    setTimeout(() => {
      dispatch({
        type: SENT_STATUS_RESET
      });
    }, 5000);
  }
});