import { createSelector } from 'reselect';
import { checkIsCustomInkTenant } from 'swag-common/utils/check-is-custom-ink-tenant.util';
import { INVENTORY_TERMS_ACCEPTED } from 'swag-client-common/constants/local-storage-keys';
const userSelector = state => state.user;
const hasOwnDeletedInventorySelector = createSelector(userSelector, user => user.hasOwnDeletedInventories);
export const userHasInventoriesSelector = createSelector(userSelector, user => user.hasInventories);
export const isInventoryTermsAcceptedSelector = createSelector(userSelector, user => {
  var _user$passedActions;
  const isAccepted = localStorage.getItem(INVENTORY_TERMS_ACCEPTED);
  return ((_user$passedActions = user.passedActions) === null || _user$passedActions === void 0 ? void 0 : _user$passedActions.inventoryTermAccepted) || Boolean(isAccepted);
});
export const userHasActiveOrOwnDeletedInventorySelector = createSelector(userHasInventoriesSelector, hasOwnDeletedInventorySelector, (hasInventory, hasDeletedInventory) => hasInventory || hasDeletedInventory);
export const isUserPostalSupervisor = () => false;
export const isCustomInkImpersonateFlowSelector = createSelector(userSelector, user => user.isCustomInkImpersonateFlow && checkIsCustomInkTenant(user.tenant));