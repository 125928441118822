function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { POPUP_HIDDEN } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { CLEAR_CART, REMOVE_PROMOCODE_SUCCESS } from 'swag-client-common/redux/cart/cart.actions';
import { STATE_LOADING, STATE_SUCCESS, STATE_IDLE, STATE_ERROR } from '../redux.constants';
import { CHANGE_STEP, UPLOAD_CSV_FAIL, UPLOAD_CSV_STARTED, UPLOAD_CSV_SUCCESS, EMULATE_PROGRESS_STEP, EMULATE_PROGRESS_DONE, CONFIRM_KS_FLOW_ITEMS, APPLY_KS_FLOW_STARTED, APPLY_KS_FLOW_SUCCESS, APPLY_KS_FLOW_FAIL } from './kickstarter-shipping.actions';
import * as constants from './kickstarter-shipping-constants';
const initialState = {
  step: constants.PROPOSAL_STATE,
  uploadState: STATE_IDLE,
  totalAmount: 0,
  validCount: 0,
  ksFulfillment: 0,
  parsingProgress: 0,
  parsingShipmentPrice: 0,
  progressIsDone: false,
  items: [],
  error: ''
};
export default function kickstarterShippingReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_STEP:
      return _objectSpread({}, state, {
        step: action.body
      });
    case UPLOAD_CSV_STARTED:
      return _objectSpread({}, state, {
        uploadState: STATE_LOADING,
        error: ''
      });
    case UPLOAD_CSV_FAIL:
      return _objectSpread({}, state, {
        uploadState: STATE_ERROR,
        error: action.err.message
      });
    case UPLOAD_CSV_SUCCESS:
      // console.dir(action.body);
      return _objectSpread({}, state, {
        uploadState: STATE_SUCCESS,
        parsingProgress: 0,
        parsingShipmentPrice: 0,
        progressIsDone: false,
        error: ''
      }, action.body);
    case EMULATE_PROGRESS_STEP:
      {
        const parsingProgress = state.parsingProgress + 1;
        const parsingShipmentPrice =
        // @ts-ignore
        state.parsingShipmentPrice + state.shipmentPrices[state.parsingProgress];
        return _objectSpread({}, state, {
          parsingProgress,
          parsingShipmentPrice
        });
      }
    case EMULATE_PROGRESS_DONE:
      {
        return _objectSpread({}, state, {
          parsingProgress: state.parsingProgress + 1,
          progressIsDone: true,
          parsingShipmentPrice: action.ksFulfillment,
          ksFulfillment: action.ksFulfillment,
          step: state.validCount !== state.totalAmount ? constants.PROGRESS_DONE : constants.RESULT_STATE
        });
      }
    case APPLY_KS_FLOW_STARTED:
      {
        return _objectSpread({}, state, {
          uploadState: STATE_LOADING,
          error: ''
        });
      }
    case REMOVE_PROMOCODE_SUCCESS:
    case APPLY_KS_FLOW_SUCCESS:
      {
        return _objectSpread({}, initialState);
      }
    case APPLY_KS_FLOW_FAIL:
      {
        return _objectSpread({}, state, {
          uploadState: STATE_ERROR,
          error: action.err.message
        });
      }
    case CONFIRM_KS_FLOW_ITEMS:
      {
        return _objectSpread({}, state, {
          items: action.items
        });
      }
    case POPUP_HIDDEN:
    case CLEAR_CART:
      {
        return initialState;
      }
    default:
      {
        return state;
      }
  }
}