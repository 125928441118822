function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const defaultSizePercentages = {
  XS: 3,
  S: 17,
  M: 26,
  L: 26,
  XL: 17,
  '2XL': 8,
  '3XL': 3
};
const getPercentages = ({
  sizeOptions,
  itemsToSkip
}) => {
  const sizeOptionsWithoutSkipped = sizeOptions.map((size, index) => itemsToSkip && itemsToSkip.includes(index) ? 0 : size);
  const excessiveSizesOptions = sizeOptionsWithoutSkipped.filter(size => !Object.keys(defaultSizePercentages).includes(String(size)));
  let sizePercentages = defaultSizePercentages;
  if (excessiveSizesOptions.length) {
    const sizeWithSmallestAmount = sizeOptionsWithoutSkipped.reduce((acc, sizeOption) => {
      const accPercentage = defaultSizePercentages[acc];
      const optionPercentage = defaultSizePercentages[sizeOption];
      if (!accPercentage) {
        return sizeOption;
      }
      return optionPercentage < accPercentage ? sizeOption : acc;
    }, sizeOptionsWithoutSkipped[0]);
    sizePercentages = _objectSpread({}, defaultSizePercentages, {
      [sizeWithSmallestAmount]: defaultSizePercentages[sizeWithSmallestAmount] - 1
    }, Object.fromEntries(excessiveSizesOptions.map(size => [size, 1 / excessiveSizesOptions.length])));
  }
  const availableSizes = Object.keys(sizePercentages).filter(size => sizeOptionsWithoutSkipped.includes(size));
  const totalCoefficient = availableSizes.reduce((total, size) => total + sizePercentages[size], 0);
  return sizeOptionsWithoutSkipped.map(size => size === 0 ? 0 : sizePercentages[size] / totalCoefficient * 100);
};
const spreadQuantitiesBetweenSizes = ({
  quantity,
  sizeOptions,
  itemsToSkip
}) => {
  const percentages = getPercentages({
    sizeOptions,
    itemsToSkip
  });
  const quantities = percentages.map(percentage => Math.floor(quantity / 100 * percentage));
  const {
    extraQuantity,
    biggestQuantityIndex
  } = quantities.reduce((acc, quantity, index) => {
    if (quantities[acc.biggestQuantityIndex] < quantity) {
      acc.biggestQuantityIndex = index;
    }
    acc.extraQuantity -= quantity;
    return acc;
  }, {
    extraQuantity: quantity,
    biggestQuantityIndex: 0
  });
  quantities[biggestQuantityIndex] += extraQuantity;
  return quantities;
};
const spreadQuantitiesEqually = ({
  quantity,
  itemsQuantityToSpread,
  itemsToSkip
}) => {
  const actualItemsNumber = itemsQuantityToSpread - Number(itemsToSkip === null || itemsToSkip === void 0 ? void 0 : itemsToSkip.length);
  const singleQuantity = Math.floor(quantity / actualItemsNumber);
  const extraQuantity = quantity % actualItemsNumber;
  let quantities = [];
  if (itemsQuantityToSpread > 1) {
    quantities = new Array(itemsQuantityToSpread).fill(singleQuantity).map((quantity, index) => itemsToSkip && itemsToSkip.includes(index) ? 0 : quantity);
  } else {
    return [quantity];
  }
  const indexToAddExtraQuantityTo = quantities.findIndex((_, index) => !(itemsToSkip !== null && itemsToSkip !== void 0 && itemsToSkip.includes(index)));
  quantities[indexToAddExtraQuantityTo] = singleQuantity + extraQuantity;
  return quantities;
};
export const spreadQuantities = ({
  quantity,
  itemsQuantityToSpread,
  allQuantityTo = null,
  itemsToSkip = [],
  sizeOptions
}) => {
  if (Number.isInteger(allQuantityTo)) {
    const quantities = new Array(itemsQuantityToSpread).fill(0);
    quantities[allQuantityTo] = quantity;
    return quantities;
  }
  if (sizeOptions && ['M', 'L'].every(size => sizeOptions.includes(size))) {
    return spreadQuantitiesBetweenSizes({
      quantity,
      sizeOptions,
      itemsToSkip
    });
  }
  return spreadQuantitiesEqually({
    quantity,
    itemsQuantityToSpread,
    itemsToSkip
  });
};