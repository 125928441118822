export function getSharedItemsWithItemItself(item, items, excludeRootId) {
  const {
    asRelatedItemId,
    prodId
  } = item;
  const sharedItems = items.filter(item => asRelatedItemId && item.asRelatedItemId === asRelatedItemId && item.prodId === prodId);
  const itemsToProcess = sharedItems.length ? sharedItems : [item];
  if (excludeRootId) {
    return itemsToProcess.filter(({
      _id
    }) => String(_id) !== String(item._id));
  }
  return itemsToProcess;
}