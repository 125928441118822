import { BOX_SINGLE_LOGO } from 'swag-client-common/constants';
import { PRINTING_METHODS } from '../product/product.constants';
export function getColorsToPrint(colorsData) {
  const {
    convertTo,
    selectedColors
  } = colorsData;
  if (convertTo) {
    return [convertTo.pms];
  }
  return selectedColors.map(color => color.pms);
}
export function getPrintingMethod({
  colorsToPrint,
  hasDtgOption,
  minimumColorsForDtg
}) {
  if (hasDtgOption && colorsToPrint.length >= minimumColorsForDtg) {
    return PRINTING_METHODS.DTG;
  }
  return PRINTING_METHODS.SCREENPRINTING;
}
export function getMaxColorCount({
  isBox,
  location,
  products,
  defaultMaxPrintColors
}) {
  if (!isBox) {
    return defaultMaxPrintColors;
  }
  if (location === BOX_SINGLE_LOGO) {
    const minimum = Math.min.apply(null, products.map(product => product.maxPrintColors).filter(color => !!color));
    if (minimum === Infinity) {
      return 0;
    }
    return minimum;
  }
  const productId = location.split(':')[1];
  if (!productId) {
    return defaultMaxPrintColors;
  }
  const product = products.find(product => product._id === productId);
  if (!product) {
    return defaultMaxPrintColors;
  }
  return product.maxPrintColors;
}