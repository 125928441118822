export const getSplitShippedItems = items => {
  const splitShippedItems = items.filter(({
    _id,
    boxItemsId,
    asRelatedItemId,
    prodId
  }, _, allItems) => {
    return allItems.find(item => {
      return item.asRelatedItemId === asRelatedItemId && item.prodId === prodId && String(item._id) !== String(_id) && (item === null || item === void 0 ? void 0 : item.boxItemsId) === null && boxItemsId === null && (item === null || item === void 0 ? void 0 : item.splitShippedType);
    });
  });
  return splitShippedItems;
};
export const hasSplitShippedItems = items => {
  const splitShippedItems = getSplitShippedItems(items);
  const splitShippedItemsIds = splitShippedItems.map(item => item._id);
  const hasSplitShippedItems = items.some(({
    _id
  }) => splitShippedItemsIds.includes(_id));
  return hasSplitShippedItems;
};