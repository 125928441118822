import { ResellerApi } from 'swag-client-common/api/reseller.api';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
const PREFIX = 'RESELLER_HOME_PAGE_';
export const GET_CUSTOM_HOME_PAGE_DATA_REQUEST = `${PREFIX}GET_CUSTOM_HOME_PAGE_DATA_REQUEST`;
export const GET_CUSTOM_HOME_PAGE_DATA_SUCCESS = `${PREFIX}GET_CUSTOM_HOME_PAGE_DATA_SUCCESS`;
export const getCustomHomePageData = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_CUSTOM_HOME_PAGE_DATA_REQUEST
  }),
  transformData: () => {
    const {
      categoriesBlock,
      promoProductBlock
    } = window.swag.storeSettings.customHomePageSettings || {};
    return {
      categories: JSON.stringify((categoriesBlock === null || categoriesBlock === void 0 ? void 0 : categoriesBlock.categories) || []),
      promoProducts: JSON.stringify((promoProductBlock === null || promoProductBlock === void 0 ? void 0 : promoProductBlock.productsPublicIds) || [])
    };
  },
  fn: ResellerApi.getCustomHomePageData,
  success: payload => ({
    type: GET_CUSTOM_HOME_PAGE_DATA_SUCCESS,
    payload
  })
});