import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { STRIPE_FORM_SUBMIT_SUCCESS } from '../../../custom-store-admin/redux/stripe-form/stripe-form.actions';
import { neededTokensSelector } from '../checkout/checkout.selectors';
import { startProceedLoading } from '../checkout/checkout.actions';
const PREFIX = 'STRIPE_FORM_';
export const EDIT_FORM_FIELD = PREFIX + 'EDIT_FORM_FIELD';
export const editField = (field, value) => ({
  type: EDIT_FORM_FIELD,
  field,
  value
});
export const CARD_INFO_VALIDATED = PREFIX + 'BILLING_ADDRESS_VALIDATED';
export const STRIPE_FORM_SUBMIT_STARTED = PREFIX + 'STRIPE_FORM_SUBMIT_STARTED';
export const STRIPE_FORM_SUBMIT_ERROR = PREFIX + 'STRIPE_FORM_SUBMIT_ERROR';
export const RESET_CARD_INFO = PREFIX + 'RESET_CARD_INFO ';
export const getToken = (address, stripe, card, onTokenReceive) => async (dispatch, getState) => {
  dispatch(startProceedLoading());
  const state = getState();
  const neededTokens = neededTokensSelector(state);
  const name = address.cardHolder || `${address.firstName} ${address.lastName}`;
  const billingAddress = {
    name,
    address_line1: `${address.street || ''}, ${address.unit || ''}`,
    address_city: address.city,
    address_state: address.state,
    address_zip: address.zipcode,
    address_country: address.country
  };
  const tokensResult = await Promise.all(
  // eslint-disable-next-line prefer-spread
  Array.apply(null, Array(neededTokens)).map(() => stripe.createToken(card, billingAddress).then(response => {
    return response;
  })));
  const firstErrorResponse = tokensResult.find(response => response.error);
  if (firstErrorResponse) {
    dispatch({
      type: STRIPE_FORM_SUBMIT_ERROR,
      // @todo. auto generated. fix this error
      // @ts-ignore
      error: firstErrorResponse.error.message
    });
    // @todo. auto generated. fix this error
    // @ts-ignore
    dispatch(showNotification({
      text: firstErrorResponse.error.message
    }));
  } else {
    dispatch({
      type: STRIPE_FORM_SUBMIT_SUCCESS
    });

    // @todo. auto generated. fix this error
    // @ts-ignore
    onTokenReceive(tokensResult.map(response => response.token.id));
  }
};
export const submitForm = (stripe, card, onTokenReceive) => (dispatch, getState) => {
  const state = getState();
  const {
    stripeForm: {
      invalidInputs
    }
  } = state;
  if (invalidInputs.length) {
    return;
  }
  const {
    address
  } = state.billingAddress;
  dispatch(getToken(address, stripe, card, onTokenReceive));
};
export const resetStripeValidation = () => ({
  type: RESET_CARD_INFO
});
export const SET_STRIPE_FIELD_VALIDATION_ERROR = 'SET_STRIPE_FIELD_VALIDATION_ERROR';
export const setStripeFieldValidationError = error => {
  return {
    type: SET_STRIPE_FIELD_VALIDATION_ERROR,
    payload: error
  };
};
export const SET_STRIPE_FIELD_VALIDATION_EMPTY = 'SET_STRIPE_FIELD_VALIDATION_EMPTY';
export const setStripeFieldEmpty = empty => {
  return {
    type: SET_STRIPE_FIELD_VALIDATION_EMPTY,
    payload: empty
  };
};
export const SET_STRIPE_FIELD_VALIDATION_COMPLETED = 'SET_STRIPE_FIELD_VALIDATION_COMPLETED';
export const setStripeFieldCompleted = complete => {
  return {
    type: SET_STRIPE_FIELD_VALIDATION_COMPLETED,
    payload: complete
  };
};