import { LoginPopup } from 'swag-client-common/components/popups/login-popup';
import { showPopup, hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { isLoginPopupShownSelector, popupParamsSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { connect } from 'react-redux';
import { login } from 'swag-client-common/redux/user/user.actions';
import { resendVerificationEmail } from 'swag-client-common/redux/user/user-additional.actions';
import { FORGOT_PASSWORD_POPUP, SIGNUP_POPUP } from 'swag-client-common/constants';
import { CLIENT_LOGIN_SUCCESS } from 'swag-client-common/redux/user/user.actions';
import { postponeAction } from '../../../middleware/postpone-actions.middleware';
import { STATE_LOADING, STATE_ERROR } from '../../../redux/redux.constants';
import { getUserInventories, preselectDefaultInventory, GET_USER_INVENTORIES_SUCCESS } from '../../../redux/inventory/inventory.actions';
import { mergeRecentlyViewedForUser } from '../../../redux/product/product.actions';
import { isLimitedEditionSelector } from '../../../redux/product/product.selectors';
export const loginFormSelector = reduxState => {
  const {
    loginForm: {
      error,
      state,
      mode,
      passwordResetState,
      passwordResetErrorMessage,
      errorCode
    }
  } = reduxState;
  const params = popupParamsSelector(reduxState);
  return {
    errorMessage: error,
    mode,
    passwordResetState,
    passwordResetErrorMessage,
    notVerifiedEmail: errorCode === 110,
    isAccountBannedByIp: errorCode === 112,
    isError: state === STATE_ERROR,
    isLoading: state === STATE_LOADING,
    isOpen: isLoginPopupShownSelector(reduxState),
    title: params && params.title,
    isLabelFixed: params && params.isLabelFixed,
    isLimitedEdition: isLimitedEditionSelector(reduxState)
  };
};
export const loginFormDispatcher = (dispatch, props) => ({
  onSubmit: values => {
    const {
      state
    } = props;
    if (state !== STATE_LOADING) {
      dispatch(login(values));
      postponeAction({
        actionCreator: mergeRecentlyViewedForUser,
        untilAction: CLIENT_LOGIN_SUCCESS
      });
      postponeAction({
        actionCreator: getUserInventories,
        untilAction: CLIENT_LOGIN_SUCCESS
      });
      postponeAction({
        actionCreator: preselectDefaultInventory,
        untilAction: GET_USER_INVENTORIES_SUCCESS
      });
    }
  },
  onPasswordForgot: () => {
    dispatch(showPopup({
      popup: FORGOT_PASSWORD_POPUP
    }));
  },
  onResendVerificationEmail: () => {
    dispatch(resendVerificationEmail());
  },
  onShowSignUp: () => {
    dispatch(hidePopup());
    dispatch(showPopup({
      popup: SIGNUP_POPUP
    }));
  },
  onClose: () => {
    dispatch(hidePopup());
  }
});
export default connect(loginFormSelector, loginFormDispatcher)(LoginPopup);