import { createApi } from 'swag-client-common/libs/rapi';
const UserApi = createApi({
  fetch: {
    url: '/api/v1/user',
    method: 'get'
  },
  me: {
    url: '/api/v1/user/me',
    method: 'get'
  },
  getByCompany: {
    url: '/api/v1/user/by-company',
    method: 'get'
  },
  updateProfile: {
    url: '/api/v1/user/profile',
    method: 'put'
  },
  checkExists: {
    url: '/api/v1/user/exists/:email',
    method: 'get'
  },
  acceptInventoryTerms: {
    url: '/custom-store-api/v1/user/accept-inventory-terms',
    method: 'put'
  }
});
export default UserApi;