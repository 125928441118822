function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mobMenuState } from 'swag-client-common/utils/constants';
import { PREFETCH_SUCCESS } from '../prefetch/prefetch.actions';
import { OPEN_FILTER_POPUP } from '../product-listing/product-listing.actions';
import { CHANGE_PAGE_PARAMS, GET_DONATE_WIDGET_SUCCESS, GET_CORE_PRODUCTS_COUNT_SUCCESS, CHANGE_IS_SHOW_MENU_STATE } from './pages.actions';
const initialState = {
  metaTags: {},
  scrollTop: false,
  // @todo. auto generated. fix this error
  // @ts-ignore
  donateWidgetValue: null,
  hasDonateWidget: false,
  isShownMobMenu: {
    isShown: false,
    state: mobMenuState.MAIN
  },
  coreProductsCount: {
    drinkware: 0,
    bags: 0,
    office: 0,
    tech: 0,
    apparel: 0
  }
};
export default function (state = initialState, {
  type,
  payload
}) {
  switch (type) {
    case PREFETCH_SUCCESS:
      {
        return _objectSpread({}, state, {
          metaTags: payload.metaTags
        });
      }
    case GET_DONATE_WIDGET_SUCCESS:
      {
        const {
          hasDonateWidget,
          donateWidgetValue
        } = payload;
        return _objectSpread({}, state, {
          hasDonateWidget,
          donateWidgetValue
        });
      }
    case CHANGE_PAGE_PARAMS:
      {
        const {
          name,
          value
        } = payload;
        return _objectSpread({}, state, {
          [name]: value
        });
      }
    case GET_CORE_PRODUCTS_COUNT_SUCCESS:
      {
        return _objectSpread({}, state, {
          coreProductsCount: payload
        });
      }
    case CHANGE_IS_SHOW_MENU_STATE:
      {
        const {
          isShown,
          state: mobMenuState
        } = payload;
        return _objectSpread({}, state, {
          isShownMobMenu: {
            isShown,
            state: mobMenuState
          }
        });
      }
    case OPEN_FILTER_POPUP:
      {
        return _objectSpread({}, state, {
          isShownMobMenu: _objectSpread({}, state.isShownMobMenu, {
            isShown: false
          })
        });
      }
    default:
      return state;
  }
}