import { isItemBoxSpecialCrinklePaper } from 'swag-common/utils/order/item-is-box.util';
import { getPriceInCentsForItemByWeight } from './get-price-for-item-by-weight.logic';
export function getReceivingFeeForSingleItem(item) {
  var _prod$itemWeight;
  const {
    prod,
    quantity
  } = item;
  const weight = (_prod$itemWeight = prod === null || prod === void 0 ? void 0 : prod.itemWeight) !== null && _prod$itemWeight !== void 0 ? _prod$itemWeight : 0;
  return quantity * getPriceInCentsForItemByWeight(weight) + (!isItemBoxSpecialCrinklePaper(item) ? 3500 : 0);
}