import { getProductName } from 'swag-common/utils/product/product.utils';
import { isBox } from 'swag-common/utils/order/item-is-box.util';
import { capitalize } from 'swag-common/utils/strings';
export function getProductNameFromItem(item) {
  var _item$prod;
  const prod = (_item$prod = item.prod) !== null && _item$prod !== void 0 ? _item$prod : {};
  const boxName = item.name || getProductName(prod);
  return isBox(item) ? boxName : getProductName(prod);
}
export function getFormattedProductNameFromItem(item) {
  const name = getProductNameFromItem(item);
  return isBox(item) ? name : capitalize(name);
}
export function getModifiedProductNameFromItem(item) {
  return isBox(item) ? item.modifiedProductName : capitalize(item.modifiedProductName);
}
export function getNameForItem(item) {
  return item.modifiedProductName || getProductNameFromItem(item);
}
export function getModifiedItemNameAndOriginal(originalName, modifiedName) {
  const isNewName = originalName && originalName !== modifiedName;
  if (!modifiedName) {
    return originalName;
  }
  if (!isNewName) {
    return modifiedName;
  }
  return `${modifiedName} (${originalName})`;
}
export function getModifiedItemNameAndOriginalFromItem(item) {
  const productName = getProductNameFromItem(item);
  return getModifiedItemNameAndOriginal(productName, item.modifiedProductName);
}