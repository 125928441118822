import { createSelector } from 'reselect';
import { getIsDynamicBundle } from 'swag-client-common/utils/reseller/get-is-dynamic-bundle.util';
import { TenantStoreSettingStoreTypes } from 'swag-common/constants/main-swag.constants';
const tenantStoreRoot = state => {
  return state.tenantStore;
};
export const tenantsStoreAreAdditionalScriptsLoadedSelector = createSelector(tenantStoreRoot, tenantStore => tenantStore.areAdditionalScriptsLoaded);
export const isDynamicBundleSelector = () => getIsDynamicBundle();
export const isResellerStoreTypeSelector = () => {
  const {
    swag: {
      storeSettings
    }
  } = window;
  return (storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.storeType) === TenantStoreSettingStoreTypes.RESELLER;
};
export const isResellerContractSignedSelector = () => {
  const {
    swag: {
      storeSettings
    }
  } = window;
  return Boolean(storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.contractSignedAt);
};
export const isResellerStoreWithSignedResellerContractSelector = createSelector(isResellerStoreTypeSelector, isResellerContractSignedSelector, (isResellerStore, isResellerContractSigned) => {
  if (!isResellerStore) {
    return true;
  }
  return isResellerContractSigned;
});
export const isResellerContractPendingSignWidgetShownSelector = createSelector(isResellerStoreTypeSelector, isResellerContractSignedSelector, (isResellerStoreType, isResellerContractSigned) => {
  return isResellerStoreType && !isResellerContractSigned;
});