import * as ITEM_STATUSES from './item-statuses.constants';
export const DEAL_STAGES = {
  CLOSEDWON: 'closedwon',
  QUALIFIEDTOBUY: 'qualifiedtobuy'
};
export const GROUP_NAME = 'SWAG';
export const OBJECT_TYPE = 'Contact';
export const E_COMMERCE_PIPELINE = 'default'; // at production hubspot e-commerce pipeline is a default one

export const SOURCES = {
  INTEGRATION: 'integration'
};
export const DEAL_TYPES = {
  ABANDONED_ORDER: 'Abandoned Order',
  FULL_ORDER: 'Full Order',
  SAMPLE_ORDER: 'Sample Order'
};
export const ENGAGEMENT_TYPES = {
  EMAIL: 'EMAIL',
  CALL: 'CALL',
  MEETING: 'MEETING',
  TASK: 'TASK',
  NOTE: 'NOTE'
};
export const FORM_TYPE_KEYS = {
  ENTERPRISE: 'ENTERPRISE',
  PATAGONIA: 'PATAGONIA',
  INVENTORY: 'INVENTORY',
  INTELLIGENT: 'INTELLIGENT',
  IN_A_BOX: 'IN_A_BOX',
  CUSTOM_SWAG: 'CUSTOM_SWAG',
  CONTACT: 'CONTACT',
  CONTACT_DETAILS_DELIVERY_CHECKOUT: 'CONTACT_DETAILS_DELIVERY_CHECKOUT',
  SIGNUP: 'SIGNUP',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  GIVEAWAY: 'GIVEAWAY',
  AUTOMATION: 'AUTOMATION',
  ZAPIER: 'ZAPIER',
  SHOPIFY: 'SHOPIFY',
  DEMO: 'DEMO',
  LIMITED_EDITION: 'LIMITED_EDITION',
  INSTANT_QUOTE: 'INSTANT_QUOTE'
};
export const FORM_TYPES = {
  [FORM_TYPE_KEYS.ENTERPRISE]: 'Enterprise Form',
  [FORM_TYPE_KEYS.PATAGONIA]: 'Patagonia Form',
  [FORM_TYPE_KEYS.INTELLIGENT]: 'Intelligent Form',
  [FORM_TYPE_KEYS.IN_A_BOX]: 'In a box Form',
  [FORM_TYPE_KEYS.CUSTOM_SWAG]: 'Custom swag Form',
  [FORM_TYPE_KEYS.INVENTORY]: 'Inventory Form',
  [FORM_TYPE_KEYS.CONTACT]: 'Contact Form',
  [FORM_TYPE_KEYS.CONTACT_DETAILS_DELIVERY_CHECKOUT]: 'Contact details Form at delivery/checkout',
  [FORM_TYPE_KEYS.SIGNUP]: 'Signup Form',
  [FORM_TYPE_KEYS.OUT_OF_STOCK]: 'Out of stock Form',
  [FORM_TYPE_KEYS.GIVEAWAY]: 'Giveaway Form',
  [FORM_TYPE_KEYS.AUTOMATION]: 'Automation Form',
  [FORM_TYPE_KEYS.ZAPIER]: 'Zapier Form',
  [FORM_TYPE_KEYS.SHOPIFY]: 'Shopify Form',
  [FORM_TYPE_KEYS.DEMO]: 'Demo Request Form',
  [FORM_TYPE_KEYS.LIMITED_EDITION]: 'Limited Edition Form',
  [FORM_TYPE_KEYS.INSTANT_QUOTE]: 'Instant Quote Lead Gen'
};
export const HUBSPOT_FIELDS = {
  EMAIL: 'email',
  /**
   * @deprecated Use `HUBSPOT_FIELDS.FULL_NAME`
   */
  NAME: 'name',
  FULL_NAME: 'fullName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE_NUMBER: 'phoneNumber',
  /**
   * @deprecated Use `HUBSPOT_FIELDS.COMPANY_NAME`
   */
  COMPANY: 'company',
  COMPANY_NAME: 'companyName',
  COMPANY_URL: 'companyUrl',
  COMPANY_DEPARTMENT_NAME: 'companyDepartmentName',
  REASON: 'reason',
  MESSAGE: 'message',
  /**
   * @deprecated Use `HUBSPOT_FIELDS.EMPLOYEES_COUNT`
   */
  EMPLOYEES_QTY: 'employeesQty',
  EMPLOYEES_COUNT: 'employeesCount',
  YEARLY: 'yearly',
  REWARDS: 'rewards',
  EACH_REWARD: 'eachReward',
  BUDGET: 'budget',
  SHIP_DATE: 'shipDate',
  BOX_COUNT: 'boxCount',
  LAUNCH_DATE: 'launchDate',
  RECIPIENTS_QTY: 'recipientsQty',
  DEVIVERED_DATE: 'deliveredDate',
  USE_OUR_API: 'useOurApi',
  URL: 'url',
  APPS: 'apps',
  SWAG_IN_A_BOX: 'swagInABox',
  COMPANY_SIZE: 'companySize',
  BULK_ORDERING: 'bulkOrdering',
  SWAG_GIVEAWAY: 'swagGiveaway',
  SWAG_AUTOMATION: 'swagAutomation',
  SWAG_COMPANY_STORES: 'swagCompanyStores',
  SWAG_DISTRIBUTION: 'swagDistribution',
  INSTANT_QUOTE_URL: 'iq_url',
  INSTANT_QUOTE_PRODUCT: 'iq_product',
  INSTANT_QUOTE_QUANTITY: 'iq_quantity',
  INSTANT_QUOTE_PRINT_LOC: 'iq_print_loc',
  INSTANT_QUOTE_COLORS: 'iq_colors',
  INSTANT_QUOTE_AMOUNT: 'iq_amount',
  ZIP: 'zip',
  COUNTRY: 'country',
  COMMENT: 'comment'
};
export const SIGNUP = [{
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}];
const CUSTOM_SWAG = [{
  label: 'Name',
  name: HUBSPOT_FIELDS.NAME
}, {
  label: 'Phone number',
  name: HUBSPOT_FIELDS.PHONE_NUMBER
}, {
  label: 'Company URL',
  name: HUBSPOT_FIELDS.COMPANY_URL
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}];
const CONTACT_DETAILS_DELIVERY_CHECKOUT = [{
  label: 'First name',
  name: HUBSPOT_FIELDS.FIRST_NAME
}, {
  label: 'Last name',
  name: HUBSPOT_FIELDS.LAST_NAME
}, {
  label: 'Phone number',
  name: HUBSPOT_FIELDS.PHONE_NUMBER,
  required: false
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY,
  required: false
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company department name',
  name: HUBSPOT_FIELDS.COMPANY_DEPARTMENT_NAME,
  required: false
}];
const CONTACT = [{
  label: 'Name',
  name: HUBSPOT_FIELDS.NAME
}, {
  label: 'Message',
  name: HUBSPOT_FIELDS.MESSAGE
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Reason',
  name: HUBSPOT_FIELDS.REASON
}];
const ENTERPRISE = [{
  label: 'First name',
  name: HUBSPOT_FIELDS.FIRST_NAME
}, {
  label: 'Last name',
  name: HUBSPOT_FIELDS.LAST_NAME
}, {
  label: 'Phone number',
  name: HUBSPOT_FIELDS.PHONE_NUMBER
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY_NAME
}, {
  label: 'Company Website',
  name: HUBSPOT_FIELDS.COMPANY_URL
}, {
  label: 'Count of employees',
  name: HUBSPOT_FIELDS.EMPLOYEES_COUNT
}, {
  label: 'Spend yearly',
  name: HUBSPOT_FIELDS.YEARLY
}];
const INVENTORY = [{
  label: 'First name',
  name: HUBSPOT_FIELDS.FIRST_NAME
}, {
  label: 'Last name',
  name: HUBSPOT_FIELDS.LAST_NAME
}, {
  label: 'Phone number',
  name: HUBSPOT_FIELDS.PHONE_NUMBER
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY_NAME
}, {
  label: 'Company Website',
  name: HUBSPOT_FIELDS.COMPANY_URL
}, {
  label: 'Count of employees',
  name: HUBSPOT_FIELDS.EMPLOYEES_COUNT,
  required: false
}, {
  label: 'Spend yearly',
  name: HUBSPOT_FIELDS.YEARLY,
  required: false
}];
const INTELLIGENT = [{
  label: 'First name',
  name: HUBSPOT_FIELDS.FIRST_NAME
}, {
  label: 'Last name',
  name: HUBSPOT_FIELDS.LAST_NAME
}, {
  label: 'Phone number',
  name: HUBSPOT_FIELDS.PHONE_NUMBER
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY_NAME
}, {
  label: 'Company Website',
  name: HUBSPOT_FIELDS.COMPANY_URL
}, {
  label: 'Each reward',
  name: HUBSPOT_FIELDS.EACH_REWARD
}, {
  label: 'Rewards',
  name: HUBSPOT_FIELDS.REWARDS
}];
const PATAGONIA = [{
  label: 'First name',
  name: HUBSPOT_FIELDS.FIRST_NAME
}, {
  label: 'Last name',
  name: HUBSPOT_FIELDS.LAST_NAME
}, {
  label: 'Phone number',
  name: HUBSPOT_FIELDS.PHONE_NUMBER
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY_NAME
}, {
  label: 'Company Website',
  name: HUBSPOT_FIELDS.COMPANY_URL
}];
const IN_A_BOX = [{
  label: 'First name',
  name: HUBSPOT_FIELDS.FIRST_NAME
}, {
  label: 'Last name',
  name: HUBSPOT_FIELDS.LAST_NAME
}, {
  label: 'Phone number',
  name: HUBSPOT_FIELDS.PHONE_NUMBER
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY_NAME
}, {
  label: 'Company Website',
  name: HUBSPOT_FIELDS.COMPANY_URL
}, {
  label: 'Budget',
  name: HUBSPOT_FIELDS.BUDGET,
  required: false
}, {
  label: 'Ship date',
  name: HUBSPOT_FIELDS.SHIP_DATE,
  required: true
}, {
  label: 'Box count',
  name: HUBSPOT_FIELDS.BOX_COUNT,
  required: false
}];
const GIVEAWAY = [{
  label: 'First name',
  name: HUBSPOT_FIELDS.FIRST_NAME
}, {
  label: 'Last name',
  name: HUBSPOT_FIELDS.LAST_NAME
}, {
  label: 'Phone number',
  name: HUBSPOT_FIELDS.PHONE_NUMBER
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY_NAME
}, {
  label: 'Company Website',
  name: HUBSPOT_FIELDS.COMPANY_URL
}, {
  label: 'Budget',
  name: HUBSPOT_FIELDS.BUDGET,
  required: false
}, {
  label: 'Recipients quantity',
  name: HUBSPOT_FIELDS.RECIPIENTS_QTY,
  required: false
}, {
  label: 'Reason for sending a gift',
  name: HUBSPOT_FIELDS.REASON,
  required: false
}, {
  label: 'Delivered date',
  name: HUBSPOT_FIELDS.DEVIVERED_DATE,
  required: false
}];
const AUTOMATION = [{
  label: 'First name',
  name: HUBSPOT_FIELDS.FIRST_NAME
}, {
  label: 'Last name',
  name: HUBSPOT_FIELDS.LAST_NAME
}, {
  label: 'Phone number',
  name: HUBSPOT_FIELDS.PHONE_NUMBER
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY_NAME
}, {
  label: 'Company Website',
  name: HUBSPOT_FIELDS.COMPANY_URL
}, {
  label: 'Employees quantity',
  name: HUBSPOT_FIELDS.EMPLOYEES_QTY
}, {
  label: 'Use of our API',
  name: HUBSPOT_FIELDS.USE_OUR_API
}];
const ZAPIER = [{
  label: 'First name',
  name: HUBSPOT_FIELDS.FIRST_NAME
}, {
  label: 'Last name',
  name: HUBSPOT_FIELDS.LAST_NAME
}, {
  label: 'Phone number',
  name: HUBSPOT_FIELDS.PHONE_NUMBER
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY_NAME
}, {
  label: 'Company Website',
  name: HUBSPOT_FIELDS.COMPANY_URL
}, {
  label: 'Employees quantity',
  name: HUBSPOT_FIELDS.EMPLOYEES_COUNT
}, {
  label: 'Apps integrate',
  name: HUBSPOT_FIELDS.APPS
}];
const SHOPIFY = [{
  label: 'First name',
  name: HUBSPOT_FIELDS.FIRST_NAME
}, {
  label: 'Last name',
  name: HUBSPOT_FIELDS.LAST_NAME
}, {
  label: 'Phone number',
  name: HUBSPOT_FIELDS.PHONE_NUMBER
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY_NAME
}, {
  label: 'Company Website',
  name: HUBSPOT_FIELDS.COMPANY_URL
}];
const DEMO = [{
  label: 'Name',
  name: HUBSPOT_FIELDS.NAME
}, {
  label: 'Company Name',
  name: HUBSPOT_FIELDS.COMPANY_NAME
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company Size',
  name: HUBSPOT_FIELDS.COMPANY_SIZE,
  required: false
}, {
  label: 'Bulk Ordering',
  name: HUBSPOT_FIELDS.BULK_ORDERING,
  required: false
}, {
  label: 'Swag Distribution',
  name: HUBSPOT_FIELDS.SWAG_DISTRIBUTION,
  required: false
}, {
  label: 'Swag In A Box',
  name: HUBSPOT_FIELDS.SWAG_IN_A_BOX,
  required: false
}, {
  label: 'Swag Gift Giveaway',
  name: HUBSPOT_FIELDS.SWAG_GIVEAWAY,
  required: false
}, {
  label: 'Swag Company Stores',
  name: HUBSPOT_FIELDS.SWAG_COMPANY_STORES,
  required: false
}, {
  label: 'Swag Automation',
  name: HUBSPOT_FIELDS.SWAG_AUTOMATION,
  required: false
}];
const OUT_OF_STOCK = [{
  label: 'Full name',
  name: HUBSPOT_FIELDS.FULL_NAME,
  required: false
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY,
  required: false
}, {
  label: 'url',
  name: HUBSPOT_FIELDS.URL
}];
const LIMITED_EDITION = [{
  label: 'Full name',
  name: HUBSPOT_FIELDS.FULL_NAME,
  required: false
}, {
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'Company',
  name: HUBSPOT_FIELDS.COMPANY,
  required: false
}, {
  label: 'url',
  name: HUBSPOT_FIELDS.URL
}];
const INSTANT_QUOTE = [{
  label: 'Email',
  name: HUBSPOT_FIELDS.EMAIL
}, {
  label: 'IQ Colors',
  name: HUBSPOT_FIELDS.INSTANT_QUOTE_COLORS,
  required: false
}, {
  label: 'IQ Print Location',
  name: HUBSPOT_FIELDS.INSTANT_QUOTE_PRINT_LOC,
  required: false
}, {
  label: 'IQ Quantity',
  name: HUBSPOT_FIELDS.INSTANT_QUOTE_QUANTITY,
  required: false
}, {
  label: 'Postal code',
  name: HUBSPOT_FIELDS.ZIP,
  required: false
}, {
  label: 'Country/Region',
  name: HUBSPOT_FIELDS.COUNTRY,
  required: false
}, {
  label: 'IQ Amount',
  name: HUBSPOT_FIELDS.INSTANT_QUOTE_AMOUNT,
  required: false
}, {
  label: 'IQ Product',
  name: HUBSPOT_FIELDS.INSTANT_QUOTE_PRODUCT
}, {
  label: 'IQ URL',
  name: HUBSPOT_FIELDS.INSTANT_QUOTE_URL
}];
export const FORM_FIELDS = {
  CUSTOM_SWAG,
  CONTACT,
  ENTERPRISE,
  INVENTORY,
  INTELLIGENT,
  IN_A_BOX,
  PATAGONIA,
  SIGNUP,
  CONTACT_DETAILS_DELIVERY_CHECKOUT,
  OUT_OF_STOCK,
  GIVEAWAY,
  AUTOMATION,
  ZAPIER,
  SHOPIFY,
  DEMO,
  LIMITED_EDITION,
  INSTANT_QUOTE
};
export const ORDER_STATUSES = {
  [ITEM_STATUSES.REFUNDED]: 'Refunded',
  [ITEM_STATUSES.CANCELLED]: 'Cancelled',
  [ITEM_STATUSES.DISPLAY_ALL]: 'Display all',
  [ITEM_STATUSES.REJECTED]: 'Rejected',
  [ITEM_STATUSES.PENDING_APPROVAL]: 'Pending approval',
  [ITEM_STATUSES.INITIAL]: 'Waiting for PO',
  [ITEM_STATUSES.PO_RECEIVED]: 'PO received',
  [ITEM_STATUSES.PREPAYMENT_RECEIVED]: 'Prepayment received',
  [ITEM_STATUSES.PAID]: 'Paid',
  [ITEM_STATUSES.COLORS_REVIEW_BY_RESELLER_REQUESTED]: 'Requested colors review by Reseller',
  [ITEM_STATUSES.COLORS_REVIEW_BY_CUSTOMER_REQUESTED]: 'Requested colors review by Customer',
  [ITEM_STATUSES.COLORS_APPROVED_BY_CUSTOMER]: 'Colors approved by Customer',
  [ITEM_STATUSES.SENT_TO_VENDOR]: 'Order sent to vendor',
  [ITEM_STATUSES.MOCKUP_APPROVAL_BY_RESELLER]: 'Mockup sent to Reseller',
  [ITEM_STATUSES.MOCKUP_REVISION_BY_RESELLER]: 'Reseller asked for revisions',
  [ITEM_STATUSES.MOCKUP_APPROVAL_BY_CUSTOMER]: 'Mockup sent to Customer',
  [ITEM_STATUSES.MOCKUP_REVISION_BY_CUSTOMER]: 'Customer asked for revisions',
  [ITEM_STATUSES.MOCKUP_APPROVED_BY_CUSTOMER]: 'Mockup approved by Customer',
  [ITEM_STATUSES.IN_PRODUCTION]: 'In production',
  [ITEM_STATUSES.CONTINUED_PRODUCTION]: 'Continued production',
  [ITEM_STATUSES.NOTICE_SENT_WAREHOUSE]: 'Notice sent to Warehouse',
  [ITEM_STATUSES.SHIPPED]: 'Product shipped to customer',
  [ITEM_STATUSES.SHIPPED_TO_CUSTOMER]: 'Product shipped to customer',
  [ITEM_STATUSES.DELIVERED]: 'Delivered to customer',
  [ITEM_STATUSES.DELIVERED_TO_WAREHOUSE]: 'Delivered to warehouse',
  [ITEM_STATUSES.READY_FOR_DISTRIBUTION]: 'Ready for distribution',
  [ITEM_STATUSES.FINAL_PAYMENT]: 'Final payment is received'
};