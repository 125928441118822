import { LocationValidationErrorCode, LocationVerificationCode } from 'swag-common/interfaces/location';
export function isAddressSuggestedByError(err) {
  return err.status === 422 && isAddressSuggestedByErrorData(err);
}
export function isAddressNotFoundByError(err) {
  return err.status === 422 && isAddressNotFoundByErrorData(err);
}
export function isAddressNotExistsByError(err) {
  return err.status === 422 && isAddressNotExistsByErrorData(err);
}
export function isAddressSuggestedByErrorData(err) {
  return err && (!err.code || err.code === LocationValidationErrorCode.addressError) && err.verificationCode === LocationVerificationCode.suggested;
}
export function isAddressNotFoundByErrorData(err) {
  return err && (!err.code || err.code === LocationValidationErrorCode.addressError) && err.verificationCode === LocationVerificationCode.notFound;
}
export function isAddressNotExistsByErrorData(err) {
  return err && err.code === LocationValidationErrorCode.stateError && err.verificationCode === LocationVerificationCode.notFound;
}