export const addMonth = date => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + 1);
  return newDate;
};
export const addMonthsToUTCDate = ({
  sourceDate,
  numberOfMonths
}) => {
  const dateCopy = new Date(sourceDate.getTime());
  const dateCopyUTC = new Date(Date.UTC(dateCopy.getFullYear(), dateCopy.getMonth(), dateCopy.getDate()));
  const dayOfMonth = dateCopyUTC.getDate();
  const endOfDesiredMonth = new Date(dateCopyUTC.getTime());
  endOfDesiredMonth.setUTCMonth(dateCopyUTC.getMonth() + numberOfMonths + 1, 0);
  const daysInMonth = endOfDesiredMonth.getDate();
  if (dayOfMonth >= daysInMonth) {
    // If we're already at the end of the month, then this is the correct date
    // and we're done.
    return endOfDesiredMonth;
  } else {
    dateCopyUTC.setUTCFullYear(endOfDesiredMonth.getFullYear(), endOfDesiredMonth.getMonth(), dayOfMonth);
    return dateCopyUTC;
  }
};
export const subtractMonthsFromUTCDate = ({
  sourceDate,
  numberOfMonths
}) => {
  return addMonthsToUTCDate({
    sourceDate,
    numberOfMonths: -numberOfMonths
  });
};