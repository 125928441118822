import _reset from "swag-client-common/redux-form-actions/reset";
import { v4 } from 'uuid';
import { browserHistory } from 'react-router';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { predefinedNotifications } from 'swag-client-common/redux/notification/notification.constants';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import UserApi from 'swag-client-common/api/user.api';
import AuthApi from 'swag-client-common/api/auth.api';
import { addOrderToken } from 'swag-client-common/redux/cart/cart.utils';
import { CLEAR_CART, getCartWithoutDraft } from 'swag-client-common/redux/cart/cart.actions';
import { getAllFavouriteProductIds } from 'swag-client-common/redux/favourite-products/favourite-products.actions';
import { ifCollectSizesBeforeRequest } from 'swag-client-common/redux/collect-sizes/collect-sizes.actions';
import { mergeHotProductsUserHistory } from 'swag-client-common/redux/hot-products/hot-products.actions';
import { port } from 'swag-client-common/shared-worker/ecommerce/ecommerce-worker-runner';
import { SOCKET_EVENTS } from 'swag-common/constants/socket-events';
import { removeDraftToken, setHotProductsToken } from 'swag-client-common/utils/local-storage.util';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { PREDEFINED_TENANTS } from 'swag-common/constants/main-swag.constants';
import { INVENTORY_TERMS_ACCEPTED, INVENTORY_UPSELL_KEY } from 'swag-client-common/constants/local-storage-keys';
import { OrderApi } from 'swag-client-common/api/order.api';
// @todo imports from non common directories are forbidden

import { ResellerApi } from 'swag-client-common/api/reseller.api';
import { getUserInventories } from '../../../customer/redux/inventory/inventory.actions';
// @todo imports from non common directories are forbidden
import { showHotProducts } from '../../../customer/utils/show-hot-products';
import { draftTokenSelector, orderTokenSelector } from '../cart/cart.selectors';
import * as RedirectConstants from './redirect.constants';
import { isResellerAdminLoggedAsResellerUserSelector, userIdSelector } from './user.selectors';
import { isCustomInkImpersonateFlowSelector } from './user-other.selectors';
const PREFIX = 'USER_';
export const HANDLE_LOGIN_FAIL = `${PREFIX}HANDLE_LOGIN_FAIL`;
export const handleLoginFail = code => ({
  type: HANDLE_LOGIN_FAIL,
  payload: code
});
export const FETCH_PROFILE_STARTED = `${PREFIX}FETCH_PROFILE_STARTED`;
export const FETCH_PROFILE_SUCCESS = `${PREFIX}FETCH_PROFILE_SUCCESS`;
export const FETCH_PROFILE_FAIL = `${PREFIX}FETCH_PROFILE_FAIL`;
export const fetchProfile = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: FETCH_PROFILE_STARTED
    });
  },
  fn: UserApi.fetch,
  success: body => ({
    type: FETCH_PROFILE_SUCCESS,
    body
  }),
  error: errorAction(FETCH_PROFILE_FAIL),
  postError: (dispatch, getState) => {
    var _orderTokenSelector, _draftTokenSelector;
    if ([PREDEFINED_TENANTS.SENDOSO, PREDEFINED_TENANTS.POSTAL].some(el => el === bundle_type)) {
      return;
    }
    const state = getState();
    const orderToken = (_orderTokenSelector = orderTokenSelector(state)) !== null && _orderTokenSelector !== void 0 ? _orderTokenSelector : '';
    const draftToken = (_draftTokenSelector = draftTokenSelector(state)) !== null && _draftTokenSelector !== void 0 ? _draftTokenSelector : '';
    showHotProducts({
      dispatch,
      userId: null,
      orderToken,
      draftToken
    });
  },
  postSuccess: dispatch => {
    dispatch(mergeHotProductsUserHistory());
    dispatch(ifCollectSizesBeforeRequest());
  }
});
export const CLIENT_LOGIN_STARTED = `${PREFIX}CLIENT_LOGIN_STARTED`;
export const CLIENT_LOGIN_ERROR = `${PREFIX}CLIENT_LOGIN_ERROR`;
export const CLIENT_LOGIN_SUCCESS = `${PREFIX}CLIENT_LOGIN_SUCCESS`;
export const CLIENT_LOGIN_REMOVE_NOTIFICATION = `${PREFIX}CLIENT_LOGIN_REMOVE_NOTIFICATION`;
export const updateInventoryUpsellWidgetState = createAsyncAction({
  fn: OrderApi.setInventoryUpsellWidgetState,
  success: () => () => {
    localStorage.removeItem(INVENTORY_UPSELL_KEY);
  },
  error: () => () => ''
});
export const login = createAsyncAction({
  transformData: addOrderToken,
  preFn: dispatch => {
    dispatch({
      type: CLIENT_LOGIN_STARTED
    });
  },
  fn: AuthApi.login,
  success: body => ({
    type: CLIENT_LOGIN_SUCCESS,
    body
  }),
  error: errorAction(CLIENT_LOGIN_ERROR),
  postSuccess: dispatch => {
    dispatch(showNotification({
      text: predefinedNotifications.login
    }));
    dispatch(hidePopup());
    dispatch(getCartWithoutDraft());
    dispatch(getUserInventories());
    dispatch(getAllFavouriteProductIds());
    dispatch(mergeHotProductsUserHistory());
    dispatch(ifCollectSizesBeforeRequest());
    localStorage.removeItem(INVENTORY_TERMS_ACCEPTED);
    const inventoryUpsellKey = localStorage.getItem(INVENTORY_UPSELL_KEY);
    if (inventoryUpsellKey) {
      dispatch(updateInventoryUpsellWidgetState({
        temporaryKey: inventoryUpsellKey
      }));
    }
    const params = new URLSearchParams(location.search);
    const redirectLink = params.get('redirectLink');
    if (redirectLink) {
      const isInventoryLink = redirectLink.includes('inventory');
      if (isInventoryLink) {
        window.location.href = redirectLink;
      } else {
        browserHistory.push(redirectLink);
      }
    }
  }
});
export const CLIENT_SIGNUP_STARTED = `${PREFIX}CLIENT_SIGNUP_STARTED`;
export const CLIENT_SIGNUP_ERROR = `${PREFIX}CLIENT_SIGNUP_ERROR`;
export const CLIENT_SIGNUP_SUCCESS = `${PREFIX}CLIENT_SIGNUP_SUCCESS`;
export const signup = createAsyncAction({
  transformData: addOrderToken,
  preFn: dispatch => {
    dispatch({
      type: CLIENT_SIGNUP_STARTED
    });
  },
  fn: AuthApi.signup,
  success: body => ({
    type: CLIENT_SIGNUP_SUCCESS,
    body
  }),
  error: errorAction(CLIENT_SIGNUP_ERROR),
  postSuccess: dispatch => {
    dispatch(showNotification({
      text: predefinedNotifications.signup
    }));
    dispatch(hidePopup());
  }
});
export const LOGOUT_SUCCESS = `${PREFIX}LOGOUT_SUCCESS`;
export const LOGOUT_FAIL = `${PREFIX}LOGOUT_FAIL`;
export const signout = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: CLEAR_CART,
      payload: {
        orderToken: null,
        draftToken: null
      }
    });
    removeDraftToken();
  },
  transformData: addOrderToken,
  fn: AuthApi.signout,
  success: () => dispatch => {
    dispatch({
      type: LOGOUT_SUCCESS
    });
    window.onbeforeunload = null;
    window.location.href = APP_ROUTES.HOME;
  },
  postSuccess: (dispatch, getState) => {
    var _orderTokenSelector2, _draftTokenSelector2, _userIdSelector;
    setHotProductsToken(v4());
    // Temporary solution, will be adjusted after moving send new hot product cycle to cron
    port.postMessage({
      type: SOCKET_EVENTS.RESET_CONNECTION
    });
    const state = getState();
    const orderToken = (_orderTokenSelector2 = orderTokenSelector(state)) !== null && _orderTokenSelector2 !== void 0 ? _orderTokenSelector2 : '';
    const draftToken = (_draftTokenSelector2 = draftTokenSelector(state)) !== null && _draftTokenSelector2 !== void 0 ? _draftTokenSelector2 : '';
    const userId = (_userIdSelector = userIdSelector(state)) !== null && _userIdSelector !== void 0 ? _userIdSelector : null;
    showHotProducts({
      dispatch,
      userId,
      orderToken,
      draftToken
    });
  },
  error: errorAction(LOGOUT_FAIL)
});
export const LOGOUT_AS_USER_SUCCESS = `${PREFIX}LOGOUT_AS_USER_SUCCESS`;
export const LOGOUT_AS_USER_FAIL = `${PREFIX}LOGOUT_AS_USER_FAIL`;
export const logoutAsUser = () => (dispatch, getState) => {
  const state = getState();
  const isResellerAdminLoggedAsResellerUser = isResellerAdminLoggedAsResellerUserSelector(state);
  const isCustomInkImpersonateFlow = isCustomInkImpersonateFlowSelector(state);
  if (isCustomInkImpersonateFlow) {
    window.location.replace('/api/v1/auth/logout-as-custom-ink-user');
    return;
  }
  if (isResellerAdminLoggedAsResellerUser) {
    window.location.replace('/api/v1/auth/logout-as-reseller-user');
    return;
  }
  dispatch(logoutAsSwagUser());
};
export const logoutAsSwagUser = createAsyncAction({
  fn: AuthApi.logoutAsUser,
  success: () => ({
    type: LOGOUT_AS_USER_SUCCESS
  }),
  error: errorAction(LOGOUT_AS_USER_FAIL),
  postSuccess: () => {
    removeDraftToken();
    setHotProductsToken(v4());
    window.onbeforeunload = null;
    window.location.href = RedirectConstants.logout;
  }
});
export const UPDATE_USER_PROFILE = `${PREFIX}UPDATE_USER_PROFILE`;
export const updateUserProfileAction = profile => ({
  type: UPDATE_USER_PROFILE,
  payload: profile
});
export const UPDATE_PROFILE_STARTED = `${PREFIX}UPDATE_PROFILE_STARTED`;
export const UPDATE_PROFILE_ERROR = `${PREFIX}UPDATE_PROFILE_ERROR`;
export const UPDATE_PROFILE_SUCCESS = `${PREFIX}UPDATE_PROFILE_SUCCESS`;
export const updateProfile = createAsyncAction({
  preFn: (dispatch, getState, data) => {
    const {
      user,
      user: {
        isLoading
      }
    } = getState();

    // tslint:disable-next-line:triple-equals
    const isSameValues = Object.keys(data).some(key => data[key] == user[key]);
    if (isLoading && isSameValues) {
      return true;
    }
    dispatch({
      type: UPDATE_PROFILE_STARTED
    });
  },
  fn: UserApi.updateProfile,
  success: body => ({
    type: UPDATE_PROFILE_SUCCESS,
    body
  }),
  error: errorAction(UPDATE_PROFILE_ERROR)
});
export const CHANGE_PASSWORD_STARTED = `${PREFIX}CHANGE_PASSWORD_STARTED`;
export const CHANGE_PASSWORD_SUCCESS = `${PREFIX}CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_ERROR = `${PREFIX}CHANGE_PASSWORD_ERROR`;
export const FORM_RESET = `${PREFIX}FORM_RESET`;
export const changePassword = createAsyncAction({
  preFn: (dispatch, getState) => {
    const {
      profilePasswordForm: {
        isProfilePasswordLoading
      }
    } = getState();
    if (isProfilePasswordLoading) {
      return true;
    }
    dispatch({
      type: CHANGE_PASSWORD_STARTED
    });
  },
  fn: AuthApi.changePassword,
  success: () => ({
    type: CHANGE_PASSWORD_SUCCESS
  }),
  postSuccess: dispatch => dispatch(_reset('changePassword')),
  error: errorAction(CHANGE_PASSWORD_ERROR)
});
export const SET_PASSWORD_LOADING = `${PREFIX}PASSWORD_SET_LOADING`;
export const SET_PASSWORD_SUCCESS = `${PREFIX}PASSWORD_SET_SUCCESS`;
export const SET_PASSWORD_ERROR = `${PREFIX}PASSWORD_SET_ERROR`;
export const setPasswordByLoggedIn = createAsyncAction({
  preFn: (dispatch, getState) => {
    const {
      setPassword: {
        isSetPasswordLoading
      }
    } = getState();
    if (isSetPasswordLoading) {
      return true;
    }
    dispatch({
      type: SET_PASSWORD_LOADING
    });
  },
  fn: AuthApi.setPasswordByLoggedIn,
  success: () => ({
    type: SET_PASSWORD_SUCCESS
  }),
  postSuccess: dispatch => dispatch(_reset('setPasswordByLoggedIn')),
  error: errorAction(SET_PASSWORD_ERROR)
});
export const CHECK_USER_EXISTS_STARTED = `${PREFIX}CHECK_USER_EXISTS_STARTED`;
export const CHECK_USER_EXISTS_SUCCESS = `${PREFIX}CHECK_USER_EXISTS_SUCCESS`;
export const CHECK_USER_EXISTS_ERROR = `${PREFIX}CHECK_USER_EXISTS_ERROR`;
export const checkUserExists = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: CHECK_USER_EXISTS_STARTED
    });
  },
  fn: UserApi.checkExists,
  success: user => ({
    type: CHECK_USER_EXISTS_SUCCESS,
    payload: user
  }),
  error: errorAction(CHECK_USER_EXISTS_ERROR)
});
export const acceptResellerContractSuccess = `${PREFIX}ACCEPT_RESELLER_CONTRACT_SUCCESS`;
export const signResellerContract = createAsyncAction({
  fn: ResellerApi.acceptContract,
  success: () => ({
    type: acceptResellerContractSuccess
  }),
  postSuccess: (dispatch, getState, transformedData, res) => {
    return window.location.replace(res.redirectUrl);
  }
});