import { SPLIT_SHIPPED_ITEM_TYPE } from 'swag-common/constants/item-sources';
import { getSplitShippedItems } from 'swag-common/business-logic/items/get-split-shipped-items';
//@TODO: check if can be replaced with getSplitShippedCouple
export const getSplitShippedToCustomerCouple = ({
  toCustomerItem,
  allItems
}) => {
  if (toCustomerItem.splitShippedType !== SPLIT_SHIPPED_ITEM_TYPE.toCustomer) {
    return null;
  }
  const allSplitShippedItems = getSplitShippedItems(allItems);
  const splitShippedCouple = allSplitShippedItems.find(_item => {
    return _item.asRelatedItemId == toCustomerItem.asRelatedItemId && _item._id !== toCustomerItem._id && _item.splitShippedType === SPLIT_SHIPPED_ITEM_TYPE.toInventory;
  });
  if (!splitShippedCouple) {
    return null;
  }
  return splitShippedCouple;
};
export const getSplitShippedCouple = ({
  item,
  allItems
}) => {
  const allSplitShippedItems = getSplitShippedItems(allItems);
  const splitShippedCouple = allSplitShippedItems.find(_item => {
    return _item.asRelatedItemId == item.asRelatedItemId && _item._id !== item._id && _item.splitShippedType !== item.splitShippedType;
  });
  if (!splitShippedCouple) {
    return null;
  }
  return splitShippedCouple;
};
export const checkIsSplitShippedToCustomerHasCouple = ({
  toCustomerItem,
  allItems
}) => {
  return Boolean(getSplitShippedToCustomerCouple({
    toCustomerItem,
    allItems
  }));
};