function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { checkUserExists } from 'swag-client-common/redux/user/user.actions';
import { email as emailValidator } from 'swag-client-common/utils/redux-form-validators';
import { DEFAULT_INVENTORY_OPTION } from 'swag-common/constants/inventory';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { hasOwnDeletedInventorySelector, isInventoryTermsAcceptedSelector } from 'swag-client-common/redux/user/user.selectors';
import { Price } from 'swag-client-common/utils/price';
import { ACH, CREDIT_CARD } from 'swag-common/constants/payment-methods';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { PAYMENT_STATES } from 'swag-client-common/utils/constants';
import { checkoutIsBuyMoreCreditsFlowSelector, checkoutMoreToBuyCreditsSelector, deliverySelector, inventoryPaymentMethodFormSelector, isCreateMyInventorySelectedSelector, selectedByUserCardToPaySelector } from '../checkout/checkout.selectors';
import { isLoggedInSelector } from '../user/user.selectors';
import { PAYMENT_POPUP_OPEN, preparePaymentToInventoryProceed } from '../checkout/checkout.actions';
import InventoryApi from '../../api/inventory.api';
import { selectedInventoryIdSelector, defaultSelectedInventoryIdSelector, hasOnlySharedInventoriesSelector } from './inventory.selectors';
const PREFIX = 'INVENTORY_';
export const GET_PAYMENT_METHODS_REQUEST = `${PREFIX}GET_PAYMENT_METHODS_REQUEST`;
export const GET_PAYMENT_METHODS_SUCCESS = `${PREFIX}GET_PAYMENT_METHODS_SUCCESS`;
export const GET_PAYMENT_METHODS_FAILURE = `${PREFIX}GET_PAYMENT_METHODS_FAILURE`;
export const getPaymentMethods = createAsyncAction({
  preFn: (dispatch, getState) => {
    const isLoggedIn = isLoggedInSelector(getState());
    if (!isLoggedIn) {
      return true;
    }
    dispatch({
      type: GET_PAYMENT_METHODS_REQUEST
    });
  },
  fn: InventoryApi.paymentMethods,
  success: ({
    settings
  }) => ({
    type: GET_PAYMENT_METHODS_SUCCESS,
    payload: {
      settings
    }
  }),
  error: error => ({
    type: GET_PAYMENT_METHODS_FAILURE,
    payload: error,
    error
  })
});
export const GET_USER_INVENTORIES_STARTED = `${PREFIX}GET_USER_INVENTORIES_STARTED`;
export const GET_USER_INVENTORIES_ERROR = `${PREFIX}GET_USER_INVENTORIES_ERROR`;
export const GET_USER_INVENTORIES_SUCCESS = `${PREFIX}GET_USER_INVENTORIES_SUCCESS`;
export const getUserInventories = createAsyncAction({
  preFn: (dispatch, getState) => {
    const isLoggedIn = isLoggedInSelector(getState());
    if (!isLoggedIn) {
      return true;
    }
    dispatch({
      type: GET_USER_INVENTORIES_STARTED
    });
  },
  transformData: data => {
    return _objectSpread({}, data, {
      excludePull: true
    });
  },
  fn: InventoryApi.list,
  success: data => ({
    type: GET_USER_INVENTORIES_SUCCESS,
    payload: data
  }),
  error: error => ({
    type: GET_USER_INVENTORIES_ERROR,
    error
  })
});
export const preselectDefaultInventory = () => (dispatch, getState) => {
  const state = getState();
  const selectedInventoryId = selectedInventoryIdSelector(state);
  const defaultSelectedInventoryId = defaultSelectedInventoryIdSelector(state);
  dispatch(selectInventory(selectedInventoryId || defaultSelectedInventoryId));
};
export const checkSelectedInventory = inventoryId => (dispatch, getState) => {
  const state = getState();
  const hasOnlySharedInventories = hasOnlySharedInventoriesSelector(state);
  const isCreateMyInventorySelected = isCreateMyInventorySelectedSelector(state);
  const hasOwnDeletedInventories = hasOwnDeletedInventorySelector(state);
  const isInventoryTermsAccepted = isInventoryTermsAcceptedSelector(state);
  if (hasOnlySharedInventories && !hasOwnDeletedInventories && !isCreateMyInventorySelected && inventoryId === DEFAULT_INVENTORY_OPTION.value && !isInventoryTermsAccepted) {
    return dispatch(showPopup({
      popup: 'confirmCreateMyInventoryPopup'
    }));
  }
  dispatch(selectInventory(inventoryId));
};
export const SELECT_INVENTORY = `${PREFIX}SELECT_INVENTORY`;
export const selectInventory = _id => ({
  type: SELECT_INVENTORY,
  payload: _id
});
export const DESELECT_INVENTORY = `${PREFIX}DESELECT_INVENTORY`;
export const deselectInventory = () => ({
  type: DESELECT_INVENTORY
});
export const checkUserExistsByDeliveryEmail = () => (dispatch, getState) => {
  const {
    email
  } = deliverySelector(getState());
  const deliveryEmailError = emailValidator(email);
  if (deliveryEmailError) {
    return;
  }
  dispatch(checkUserExists({
    email
  }));
};
export const GET_LAST_INVENTORY_STARTED = `${PREFIX}GET_LAST_INVENTORY_STARTED`;
export const GET_LAST_INVENTORY_SUCCESS = `${PREFIX}GET_LAST_INVENTORY_SUCCESS`;
export const GET_LAST_INVENTORY_ERROR = `${PREFIX}GET_LAST_INVENTORY_ERROR`;
export const getLastInventoryId = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_LAST_INVENTORY_STARTED
  }),
  fn: InventoryApi.last,
  success: ({
    lastInventoryId
  }) => ({
    type: GET_LAST_INVENTORY_SUCCESS,
    payload: lastInventoryId
  }),
  error: error => ({
    type: GET_LAST_INVENTORY_ERROR,
    payload: error
  })
});
export const SELECT_CARD = `${PREFIX}SELECT_CARD`;
export const selectCard = selectedCard => ({
  type: SELECT_CARD,
  payload: {
    selectedCard
  }
});
export const SELECT_DISTRIBUTE_OPTION = `${PREFIX}SELECT_DISTRIBUTE_OPTION`;
export const selectDistributeOption = option => ({
  type: SELECT_DISTRIBUTE_OPTION,
  payload: {
    option
  }
});
export const SELECT_DISTRIBUTION_AREA = `${PREFIX}SELECT_DISTRIBUTION_AREA`;
export const selectDistributionArea = area => ({
  type: SELECT_DISTRIBUTION_AREA,
  payload: {
    area
  }
});
export const BUY_CREDITS_REQUEST = `${PREFIX}BUY_CREDITS_REQUEST`;
export const BUY_CREDITS_SUCCESS = `${PREFIX}BUY_CREDITS_SUCCESS`;
export const BUY_CREDITS_FAILURE = `${PREFIX}BUY_CREDITS_FAILURE`;
export const buyCreditsFactory = ({
  paymentMethod = CREDIT_CARD
}) => {
  return createAsyncAction({
    preFn: dispatch => {
      dispatch({
        type: BUY_CREDITS_REQUEST
      });
    },
    transformData: (data, getState) => {
      const state = getState();
      const inventoryId = selectedInventoryIdSelector(state);
      const amount = checkoutMoreToBuyCreditsSelector(state);
      const isCreditsToBuyMoreFlow = checkoutIsBuyMoreCreditsFlowSelector(state);
      const commonBody = {
        inventoryId,
        paymentMethod,
        isCreditsToBuyMoreFlow,
        amount: Price.addDecimalToNumber(amount)
      };
      if (paymentMethod === ACH) {
        return _objectSpread({}, commonBody);
      }
      const {
        stripeToken,
        savedCard
      } = data;
      if (savedCard) {
        const card = selectedByUserCardToPaySelector(state);
        return _objectSpread({}, commonBody, {
          cardId: card._id
        });
      }
      const form = inventoryPaymentMethodFormSelector(state);
      if (form) {
        const {
          savePaymentDetails
        } = form.values;
        return _objectSpread({}, commonBody, {
          stripeToken,
          savePaymentDetails
        });
      }
      return _objectSpread({}, commonBody, {
        stripeToken
      });
    },
    fn: InventoryApi.credits.buy,
    success: credits => dispatch => {
      dispatch({
        type: BUY_CREDITS_SUCCESS,
        payload: credits
      });
      dispatch(hidePopup());
      dispatch({
        type: PAYMENT_POPUP_OPEN,
        isOpen: true,
        paymentState: PAYMENT_STATES.INVENTORY_SELECT_CREDITS
      });
    },
    error: error => dispatch => {
      dispatch({
        type: BUY_CREDITS_FAILURE,
        error
      });
      dispatch(showNotification({
        text: (error === null || error === void 0 ? void 0 : error.message) || 'Oops, something went wrong'
      }));
    }
  });
};
export const payForCreditsByCard = buyCreditsFactory({
  paymentMethod: CREDIT_CARD
});
export const payForCreditsByInvoice = buyCreditsFactory({
  paymentMethod: ACH
});
export const buyCreditsByCard = (stripe, card) => (dispatch, getState) => {
  const paymentForm = inventoryPaymentMethodFormSelector(getState());
  if (paymentForm) {
    return dispatch(preparePaymentToInventoryProceed(stripe, card, stripeTokens => dispatch(payForCreditsByCard({
      stripeToken: stripeTokens[0]
    }))));
  }
  dispatch(payForCreditsByCard({
    savedCard: true
  }));
};
export const buyCreditsByInvoice = () => dispatch => {
  dispatch(payForCreditsByInvoice());
};