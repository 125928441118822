function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { FULL_DESIGN_DEFAULT_VARIANT_INDEX, SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import isFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { getDefaultImageFromItem } from 'swag-common/utils/items/get-default-image-from-item.util';
import { FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME, FULL_DESIGN_PRODUCT_DEFAULT_COLOR_VALUE } from '../../constants/custom-store.constants';
export function mapSwagItemToCustomProductParams(orderItem, swagProduct = {}) {
  const {
    logos,
    texts,
    price,
    quantity,
    embellishmentMethod,
    sizeInd,
    isReorder,
    customProductId,
    textImages,
    initialCreationDate,
    instructions,
    isCustomizedBlank = false,
    versions = [],
    variants: itemVariants
  } = orderItem;
  const variants = itemVariants.length ? itemVariants : [{
    variantIndex: FULL_DESIGN_DEFAULT_VARIANT_INDEX,
    quantities: [quantity]
  }];
  const {
    productSize,
    descr = '',
    specs = '',
    name = '',
    _id = '',
    descriptionForWarehouse = ''
  } = swagProduct;
  const {
    productColors,
    hasNoColors
  } = getQuantitiesBasedOnColors(swagProduct, variants);
  const specialProductProps = {};
  if (swagProduct.specialProductType === SPECIAL_PRODUCT_TYPES.CUSTOM_BOX) {
    specialProductProps.boxColor = orderItem.boxColor;
  }
  return _objectSpread({
    name,
    specs,
    logos,
    versions,
    sizeInd,
    isReorder: Boolean(isReorder),
    instructions,
    customProductId,
    isCustomizedBlank,
    descriptionForWarehouse,
    texts: texts || {},
    swagProductId: _id,
    description: descr,
    colors: productColors,
    price: price / quantity,
    isFullDesign: hasNoColors,
    sizeSettings: productSize !== null && productSize !== void 0 && productSize._id ? String(productSize._id) : '',
    defaultImage: getDefaultImageFromItem(orderItem),
    defaultColorImageIndex: variants[0].variantIndex,
    embellishmentMethod: embellishmentMethod || undefined,
    textImages: adjustTextImagesToProperFormat(textImages),
    initialCreationDate: initialCreationDate !== null && initialCreationDate !== void 0 ? initialCreationDate : Date.now()
  }, specialProductProps);
}
function adjustTextImagesToProperFormat(textImages) {
  if (Array.isArray(textImages)) {
    return textImages;
  }
  if (!textImages) {
    return [];
  }
  return Object.keys(textImages).reduce((allImages, location) => [...allImages, ...textImages[location]], []);
}
function getCustomProductColors(purchasedVariants, productVariants = [], hasNoColors) {
  if (hasNoColors) {
    return purchasedVariants.map(({
      quantities
    }) => {
      return {
        quantities,
        variantIndex: FULL_DESIGN_DEFAULT_VARIANT_INDEX,
        name: FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME,
        color: FULL_DESIGN_PRODUCT_DEFAULT_COLOR_VALUE
      };
    });
  }
  return purchasedVariants.map(({
    variantIndex,
    quantities
  }) => {
    const {
      color,
      name
    } = productVariants[variantIndex];
    return {
      variantIndex,
      color,
      name,
      quantities
    };
  });
}
export function getQuantitiesBasedOnColors(product = {}, itemVariants = []) {
  const {
    variants: productVariants
  } = product;
  const hasNoColors = isBox(product) || isFullDesign(product);
  const productColors = getCustomProductColors(itemVariants, productVariants, hasNoColors);
  return {
    hasNoColors,
    productColors
  };
}
export function getItemProductColors(item) {
  const variants = item.variants.length ? item.variants : [{
    variantIndex: FULL_DESIGN_DEFAULT_VARIANT_INDEX,
    quantities: [item.quantity]
  }];
  const {
    productColors
  } = getQuantitiesBasedOnColors(item.prod, variants);
  return productColors;
}