const PREFIX = 'COMMON_';
export const ERROR = `${PREFIX}ERROR`;
// const errorFn = err => ({ type: ERROR, err });
export const CHAT_REQUIRED = `${PREFIX}CHAT_REQUIRED`;
export const requestChat = () => ({
  type: CHAT_REQUIRED
});
export const BLOCK_PAGE = `${PREFIX}BLOCK_PAGE`;
export const blockPage = isPageBlocked => ({
  type: BLOCK_PAGE,
  payload: {
    isPageBlocked
  }
});
export const LOADING_TURN_ON = `${PREFIX}LOADING_TURN_ON`;
export const loadingTurnOn = () => ({
  type: LOADING_TURN_ON
});
export const LOADING_TURN_OFF = `${PREFIX}LOADING_TURN_OFF`;
export const loadingTurnOff = () => ({
  type: LOADING_TURN_OFF
});