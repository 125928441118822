import { OrderApi } from 'swag-client-common/api/order.api';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
const PREFIX = 'NOTECARD_EDITOR_';
export const SET_NOTECARD_EDITOR_INITIAL_VALUES = `${PREFIX}SET_NOTECARD_EDITOR_INITIAL_VALUES`;
export const setNotecardEditorInitialValues = payload => ({
  type: SET_NOTECARD_EDITOR_INITIAL_VALUES,
  payload
});
export const RESET_NOTECARD_EDITOR = `${PREFIX}RESET_NOTECARD_EDITOR`;
export const resetNotecardEditor = () => ({
  type: RESET_NOTECARD_EDITOR
});
export const CHANGE_NOTECARD_COLOR = `${PREFIX}CHANGE_NOTECARD_COLOR`;
export const changeNotecardColor = color => ({
  type: CHANGE_NOTECARD_COLOR,
  payload: color
});
export const UPLOAD_NOTECARD_LOGO_REQUEST = `${PREFIX}UPLOAD_NOTECARD_LOGO_REQUEST`;
export const UPLOAD_NOTECARD_LOGO_FAIL = `${PREFIX}UPLOAD_NOTECARD_LOGO_FAIL`;
export const UPLOAD_NOTECARD_LOGO_SUCCESS = `${PREFIX}UPLOAD_NOTECARD_LOGO_SUCCESS`;
export const uploadNotecardLogo = createAsyncAction({
  transformData: file => {
    const formData = new FormData();
    formData.set('logo', file);
    formData.set('view', 'front');
    return formData;
  },
  preFn: dispatch => dispatch({
    type: UPLOAD_NOTECARD_LOGO_REQUEST
  }),
  fn: OrderApi.convertLogo,
  success: body => ({
    type: UPLOAD_NOTECARD_LOGO_SUCCESS,
    payload: body
  }),
  error: error => ({
    type: UPLOAD_NOTECARD_LOGO_FAIL,
    error
  }),
  postError: dispatch => {
    dispatch(showNotification({
      text: 'Logo for notecard was not saved, something went wrong'
    }));
  }
});
export const DELETE_NOTECARD_LOGO = `${PREFIX}DELETE_NOTECARD_LOGO`;
export const deleteNotecardLogo = () => ({
  type: DELETE_NOTECARD_LOGO
});
export const UPDATE_NOTECARD_TEXT_CONTENT = `${PREFIX}UPDATE_NOTECARD_TEXT_CONTENT`;
export const updateNotecardTextContent = html => ({
  type: UPDATE_NOTECARD_TEXT_CONTENT,
  payload: html
});