const postponedActions = {};
export const postponeAction = ({
  actionCreator,
  untilAction
}) => {
  postponedActions[untilAction] = postponedActions[untilAction] ? [...postponedActions[untilAction], actionCreator] : [actionCreator];
};
const dispatchPostponedAction = (triggerAction, dispatch) => {
  postponedActions[triggerAction].forEach(actionCreator => {
    dispatch(actionCreator());
  });
};
export default function ({
  dispatch
}) {
  return next => action => {
    next(action);
    if (postponedActions[action.type]) {
      dispatchPostponedAction(action.type, dispatch);
    }
  };
}