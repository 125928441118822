export const adjustQuantityToQuantIncrement = ({
  quantity,
  quantIncrement
}) => {
  if (quantIncrement <= 1) {
    return quantity;
  }
  const remainder = quantity % quantIncrement;
  if (!remainder) {
    return quantity;
  }
  return quantity + quantIncrement - remainder;
};