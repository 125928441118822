export const getUpsellQuantity = ({
  priceBreaks,
  priceBreaksWillBeShownFrom,
  userQuantity
}) => {
  const nextPriceBreakIndex = priceBreaks.findIndex(pb => pb > userQuantity);
  const nextPriceBreakToShowFrom = priceBreaksWillBeShownFrom[nextPriceBreakIndex];
  return {
    isWithinRange: nextPriceBreakToShowFrom <= userQuantity,
    nextPriceBreak: priceBreaks[nextPriceBreakIndex] || null
  };
};