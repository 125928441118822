export function downloadFileInBrowser({
  blob,
  name,
  url,
  inNewTab
}) {
  if (!global.document) {
    return;
  }
  const a = global.document.createElement('a');
  const href = url || global.window.URL.createObjectURL(blob);
  a.href = href;
  if (inNewTab) {
    a.target = '_blank';
  }
  if (name) {
    a.download = name;
  }
  a.style.display = 'none';
  const body = global.document.querySelector('body');
  if (body) {
    body.appendChild(a);
  }
  a.click();
  if (body) {
    body.removeChild(a);
  }
  global.window.URL.revokeObjectURL(url);
}
export async function downloadFileInBrowserWithLoader({
  url,
  name,
  onStartLoading,
  onEndLoading,
  onError
}) {
  onStartLoading();
  try {
    const result = await fetch(`${window.location.origin}${url}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const blob = await result.blob();
    downloadFileInBrowser({
      blob,
      name
    });
  } catch (e) {
    onError(JSON.stringify(e));
  } finally {
    onEndLoading();
  }
}