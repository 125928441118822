import { getLastDayOfMonthNumber, getFirstDayOfMonthNumber } from './days.util';
export const isLastDayOfYear = date => {
  return date.getDate() === getLastDayOfMonthNumber(date) && date.getMonth() === 11;
};
export const isFirstDayOfYear = date => {
  return date.getDate() === getFirstDayOfMonthNumber(date) && date.getMonth() === 0;
};
export const setStartOfYear = date => new Date(Date.UTC(date.getFullYear(), 0, 1, 0, 0, 0, 0));
export const subtractYears = (date, years) => {
  const result = new Date(date);
  result.setFullYear(result.getFullYear() - years);
  return result;
};