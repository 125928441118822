import { spreadQuantities } from 'swag-common/utils/product/product.utils';
import { getIndexesOfOutOfStock } from 'swag-client-common/utils/product.utils';
function applyQuantityToSingleVariant({
  product,
  quantity,
  selectedSizeIndex,
  size,
  selectedVariantsInStockLevels = [],
  threshold
}) {
  const {
    hasSize,
    sizeDependentPrice: sizeDependent,
    isProductAlwaysInStock,
    variants
  } = product;
  if (!hasSize) {
    return [quantity];
  }
  if (sizeDependent) {
    return spreadQuantities({
      quantity,
      itemsQuantityToSpread: size.options.length,
      allQuantityTo: selectedSizeIndex
    });
  }
  const isAlwaysInStockForSelectedVariant = variants[selectedSizeIndex].isAlwaysInStockVariant;
  const itemsToSkip = isProductAlwaysInStock || isAlwaysInStockForSelectedVariant ? [] : getIndexesOfOutOfStock(selectedVariantsInStockLevels, selectedSizeIndex, threshold);
  return spreadQuantities({
    quantity,
    itemsQuantityToSpread: size.options.length,
    allQuantityTo: null,
    itemsToSkip: itemsToSkip,
    sizeOptions: size.options
  });
}
export default applyQuantityToSingleVariant;