import { getOrderItemImage } from 'swag-common/business-logic/items/get-order-item-image.logic';
export function getItemMockupsBasedOnProductImage(item) {
  var _variants$;
  const {
    prod,
    customerMockups = {},
    sizeInd,
    variants = []
  } = item;
  const variantInd = ((_variants$ = variants[0]) === null || _variants$ === void 0 ? void 0 : _variants$.variantIndex) || 0;
  if (!prod) {
    return [];
  }
  const image = getOrderItemImage({
    product: prod,
    customerMockups,
    variantInd,
    sizeInd
  });
  return [(image === null || image === void 0 ? void 0 : image.url) || ''].filter(Boolean);
}