function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { browserHistory } from 'react-router';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { LOCATION_CHANGE } from 'react-router-redux';
import { CLIENT_LOGIN_SUCCESS } from 'swag-client-common/redux/user/user.actions';
export default (({
  getState
}) => next => action => {
  const {
    type,
    payload
  } = action;
  const isForbiddenRoute = payload && [APP_ROUTES.DELIVERY, APP_ROUTES.CHECKOUT].includes(payload.pathname);
  if (type === CLIENT_LOGIN_SUCCESS) {
    const parsedURL = new URL(window.location.href);
    if (parsedURL.pathname.includes('checkout')) {
      browserHistory.replace(_objectSpread({}, parsedURL, {
        pathname: APP_ROUTES.CART
      }));
    }
  }
  if (type === LOCATION_CHANGE && isForbiddenRoute && !getState().routing.locationBeforeTransitions) {
    const parsedURL = new URL(window.location.href);
    browserHistory.replace(_objectSpread({}, parsedURL, {
      pathname: APP_ROUTES.CART
    }));
  }
  next(action);
});