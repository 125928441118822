import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { snackbarNotificationsSelector } from '../../../redux/snackbar/snackbar.selectors';
import { removeNotification } from '../../../redux/snackbar/snackbar.actions';
import { Snackbar as SnackbarComponent } from './snackbar';
const mapStateToProps = createStructuredSelector({
  notifications: snackbarNotificationsSelector
});
const mapDispatchToProps = dispatch => ({
  removeNotification: key => {
    dispatch(removeNotification(key));
  }
});
export const Snackbar = connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent);