import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import AuthApi from 'swag-client-common/api/auth.api';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import UserApi from 'swag-client-common/api/user.api';
const PREFIX = 'USER_';
export const RESET_PASSWORD_STARTED = `${PREFIX}RESET_PASSWORD_STARTED`;
export const RESET_PASSWORD_SUCCESS = `${PREFIX}RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_ERROR = `${PREFIX}RESET_PASSWORD_ERROR`;
export const resetPassword = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: RESET_PASSWORD_STARTED
    });
  },
  fn: AuthApi.forgotPassword,
  success: () => ({
    type: RESET_PASSWORD_SUCCESS
  }),
  error: errorAction(RESET_PASSWORD_ERROR)
});
export const RESEND_VERIFICATION_EMAIL_LOADING = `${PREFIX}RESEND_VERIFICATION_EMAIL_LOADING`;
export const RESEND_VERIFICATION_EMAIL_SUCCESS = `${PREFIX}RESEND_VERIFICATION_EMAIL_SUCCESS`;
export const RESEND_VERIFICATION_EMAIL_ERROR = `${PREFIX}RESEND_VERIFICATION_EMAIL_ERROR`;
export const resendVerificationEmail = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: RESEND_VERIFICATION_EMAIL_LOADING
    });
  },
  transformData: (data, getState) => {
    const {
      values
    } = getState().form.login;
    return {
      email: values.login
    };
  },
  fn: AuthApi.resendVerification,
  success: () => ({
    type: RESEND_VERIFICATION_EMAIL_SUCCESS
  }),
  error: errorAction(RESEND_VERIFICATION_EMAIL_ERROR),
  postError: dispatch => {
    dispatch(showNotification({
      text: "Sorry, we couldn't resend you a verification email"
    }));
  },
  postSuccess: dispatch => {
    dispatch(showNotification({
      text: 'Verification email was successfully sent'
    }));
  }
});
export const SESSION_PING_SUCCESS = `${PREFIX}SESSION_PING_SUCCESS`;
export const SESSION_PING_FAIL = `${PREFIX}SESSION_PING_FAIL`;
export const sessionPing = createAsyncAction({
  fn: UserApi.me,
  success: () => ({
    type: SESSION_PING_SUCCESS
  }),
  error: errorAction(SESSION_PING_FAIL)
});