function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import uuidv1 from 'uuid/v1';
import { PRODUCT_BUILDER_MODES } from 'swag-client-common/utils/constants';
import { TOOLBAR_ITEMS } from 'swag-client-common/constants';
import { DEFAULT_QUANTITY } from 'swag-client-common/constants';
import { DesignUploadMode } from 'swag-common/interfaces';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { LOGO_UPLOAD_BLANK_OPTIONS, PRODUCTION_TIME_STANDARD_INDEX } from 'swag-common/constants/product.constants';
import { REBUILDING_ITEM_REQUESTED, ADD_RELATED_ITEMS_TO_CART, ADD_ITEM_TO_CART } from 'swag-client-common/redux/cart/cart.actions';
import { getLeftTime } from 'swag-common/utils/date-lib';
import { spreadQuantities } from 'swag-common/utils/product/product.utils';
import { getDataForGettingItemShippingCost, getItemShippingCost } from 'swag-common/business-logic/order/get-item-shipping-cost.service';
import { isProductQuantityPerSizeEnabled } from 'swag-common/utils/custom-store-product/is-product-quantity-per-size-enabled.util';
import { REMOVE, ADD_TEXT, ADD_LOGO, SELECT_CURRENT, CHANGE_LOGO_DIMENSIONS } from '../logo/logo.actions';
import { ADD_COLOR, PANTONES_CONFIRMED, REMOVE_COLOR } from '../logo-editing/logo-editing.actions';
import { REORDER_ITEM_REQUESTED } from '../account-orders/account-orders.actions';
import { REBUILD_CUSTOMER_MOCKUP } from '../account-mockups/account-mockups.actions';
import { APPLY_SIZE_SUBMISSION_MOCKUP } from '../collect-sizes/collect-sizes.actions';
import { getProductVariantsToChoose } from './product.selectors';
import { FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_FAIL, FETCH_SIZE_SUCCESS, FETCH_SIZE_FAIL, CHANGE_VARIANT, CHANGE_QUANTITIES, CHANGE_SINGLE_VARIANT_QUANTITIES, CHANGE_SINGLE_QUANTITY, CHANGE_ASSOCIATED_PRODUCT_QUANTITY, CHANGE_TIME, CHANGE_VIEW, CHANGE_DISCOUNT, CHANGE_SIZE_INDEX, CHANGE_DESIGN_INFO, RESET_REDUCER, TOGGLE_SPLIT_ORDER_ON, TOGGLE_SPLIT_ORDER_OFF, CHANGE_PREVIEW, UNSELECT_VARIANT, ADD_SELECTED_VARIANT, CHANGE_FULL_SCREEN_MODE, CHANGE_MORE_IMAGE_MODE, PRELOAD_SUGGESTED_PRODUCT, CHANGE_METHOD_INDEX, TOGGLE_ADD_TEXT, UPLOAD_ATTACHMENT_SUCCESS, REMOVE_ATTACHMENT, GET_RELATED_PRODUCTS_SUCCESS, ADD_ASSOCIATED_PRODUCT, REMOVE_ASSOCIATED_PRODUCT, GET_SUGGESTED_PRODUCTS_SUCCESS, FETCH_SWAG_SAMPLE_BOX_SUCCESS, CHANGE_PRODUCT_IMAGE_HOVER_STATUS, CHANGE_OPENED_TOOLBAR_ITEM, TOGGLE_FORCE_HIDE_TOOLBAR, CHANGE_RELATED_PRODUCTS_POPUP_VISIBILITY, UPLOAD_ATTACHMENT_REQUEST, UPLOAD_ATTACHMENT_FAILURE, CHANGE_IS_CUSTOMIZED_BLANK, FETCH_PRODUCT_START, MOUSE_ON_EMBELLISHMENT_METHOD, MOUSE_OUT_EMBELLISHMENT_METHOD, RESET_INVENTORY_ID, UPSELL_DISMISS_PRODUCT_BUILDER, SET_IS_LE_TIME_EXPIRED, SET_SIZE_SUBMISSION_ID } from './product.actions';
import { getDefaultProductionTimeIdx, getRelatedProductVariantQuantities } from './product.utils';
const TOOLBAR_LOGO_RELATED_ITEMS = [TOOLBAR_ITEMS.CENTER_LOGO, TOOLBAR_ITEMS.CONVERT_COLORS, TOOLBAR_ITEMS.EDIT_COLORS];
export const initialState = {
  readyForPromo: undefined,
  itemId: undefined,
  reorderId: undefined,
  product: {
    _id: '',
    variants: [],
    imagesForSpec: [],
    associatedProducts: []
  },
  size: {},
  selectedVariants: [],
  quantities: {
    0: []
  },
  variantIndex: 0,
  imageIndex: 0,
  sizeIndex: 0,
  methodIndex: 0,
  sampleDeductionDiscount: 0,
  splitOrderMode: false,
  productLoaded: false,
  isQuantityManuallyChanged: false,
  sizeLoaded: false,
  logoNeverUploaded: true,
  selectedProductionTime: PRODUCTION_TIME_STANDARD_INDEX,
  isCustomizedBlank: false,
  view: 'front',
  serviceCode: '03',
  discount: false,
  mode: PRODUCT_BUILDER_MODES.CREATE_MODE,
  fullScreenMode: false,
  showMoreImageMode: false,
  embellishmentMethod: null,
  embellishmentMethodMouseOn: null,
  storageType: null,
  designNotes: '',
  attachments: [],
  adminAttachments: [],
  isAddTextMode: false,
  associatedProducts: [],
  selectedAssociatedProducts: [],
  asRelatedItemId: uuidv1(),
  isReorder: false,
  setupFeeDeductionDiscount: 0,
  suggestedProducts: [],
  listOfNearestVariants: {},
  isMultipleLogosMode: false,
  boxItemsId: null,
  isReorderFromSwagSampleBox: false,
  swagSampleBox: null,
  isProductImageHovered: false,
  isToolbarForceHidden: false,
  openedToolbarItem: null,
  isOpenRelatedProductsPopup: false,
  isLoading: false,
  getProductIsLoading: true,
  inventoryId: null,
  upsellDismissedStates: {
    productBuilder: false,
    checkout: false
  },
  initialCreationDate: null,
  isLETimeExpired: false,
  superSpeed: {},
  sizeSubmissionId: null
};
const getRouteBoxItemsId = (isReorder, boxItemsId, isBox) => {
  if (!isReorder && boxItemsId) {
    return boxItemsId;
  }
  if (isReorder && isBox) {
    return uuidv1();
  }
  return null;
};
const getMethodIndex = (selectedMethod, methods) => {
  if (methods && methods.length > 1) {
    return methods.findIndex(method => method.name === selectedMethod);
  }
  if (methods && methods.length === 1) {
    return 0;
  }
};
export function productReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ASSOCIATED_PRODUCT:
      {
        const {
          relatedProduct,
          productionTimeList
        } = action;
        const item = _objectSpread({}, relatedProduct, {
          asRelatedItemId: state.asRelatedItemId,
          relatedMode: 'build'
        });
        let selectedAssociatedProducts;
        const setupFeeDeductionDiscount = state.setupFeeDeductionDiscount;
        if (state.mode === PRODUCT_BUILDER_MODES.REBUILD_MODE) {
          selectedAssociatedProducts = [...state.selectedAssociatedProducts.filter(p => p.product._id !== relatedProduct.product._id)];
          const target = state.selectedAssociatedProducts.filter(p => p.product._id === relatedProduct.product._id)[0];
          if (target) {
            selectedAssociatedProducts.push(_objectSpread({}, target, relatedProduct, {
              relatedMode: 'rebuild'
            }));
          } else {
            selectedAssociatedProducts.push(item);
          }
        } else {
          selectedAssociatedProducts = [...state.selectedAssociatedProducts.filter(p => p.product._id !== relatedProduct.product._id), item];
        }
        const selectedProductionTime = getDefaultProductionTimeIdx(productionTimeList);
        return _objectSpread({}, state, {
          setupFeeDeductionDiscount,
          selectedAssociatedProducts,
          selectedProductionTime
        });
      }
    case REMOVE_ASSOCIATED_PRODUCT:
      {
        const selectedAssociatedProducts = state.selectedAssociatedProducts.filter(product => product.product._id !== action.productId);
        return _objectSpread({}, state, {
          selectedAssociatedProducts
        });
      }
    case FETCH_SWAG_SAMPLE_BOX_SUCCESS:
      {
        const {
          swagSampleBox
        } = action;
        return _objectSpread({}, state, {
          swagSampleBox
        });
      }
    case CHANGE_PRODUCT_IMAGE_HOVER_STATUS:
      {
        const {
          isProductImageHovered
        } = action.payload;
        return _objectSpread({}, state, {
          isProductImageHovered
        });
      }
    case CHANGE_OPENED_TOOLBAR_ITEM:
      {
        const {
          openedToolbarItem
        } = action.payload;
        return _objectSpread({}, state, {
          openedToolbarItem
        });
      }
    case SELECT_CURRENT:
      {
        const {
          logoId
        } = action.payload;
        let newOpenedToolbarItem = null;
        if (logoId) {
          newOpenedToolbarItem = TOOLBAR_ITEMS.DESIGN_NOTE !== state.openedToolbarItem ? newOpenedToolbarItem : state.openedToolbarItem;
        }
        return _objectSpread({}, state, {
          openedToolbarItem: newOpenedToolbarItem
        });
      }
    case FETCH_PRODUCT_SUCCESS:
      {
        var _action$payload, _action$payload$limit;
        const product = _objectSpread({}, state.product, action.payload);
        const currentViewForFullDesignProduct = product.imagesWithoutImprintArea && product.imagesWithoutImprintArea.length && product.imagesWithoutImprintArea[0].id;
        const isCustomizedBlank = product.logoUploadBlankOption === LOGO_UPLOAD_BLANK_OPTIONS.ONLY_BLANK ? true : state.isCustomizedBlank;
        const chosenProductSetting = product.productSettings.find(setting => {
          var _setting$embellishmen;
          return isCustomizedBlank ? !setting.embellishmentMethodId : ((_setting$embellishmen = setting.embellishmentMethod) === null || _setting$embellishmen === void 0 ? void 0 : _setting$embellishmen.name) === state.embellishmentMethod;
        });
        const selectedProductionTime = chosenProductSetting !== null && chosenProductSetting !== void 0 && chosenProductSetting.productionTimeList[state.selectedProductionTime] ? state.selectedProductionTime : PRODUCTION_TIME_STANDARD_INDEX;
        if ((_action$payload = action.payload) !== null && _action$payload !== void 0 && (_action$payload$limit = _action$payload.limitedEdition) !== null && _action$payload$limit !== void 0 && _action$payload$limit.isLimitedEdition) {
          product.isAlwaysInStock = false;
        }
        let boxItemsId = null;
        if (state.mode !== PRODUCT_BUILDER_MODES.CREATE_MODE) {
          boxItemsId = state.boxItemsId;
        } else if (product.designUploadMode === DesignUploadMode.box) {
          boxItemsId = uuidv1();
        }

        // Update quantities in case of re-order of item with product that has changed sizes.
        const quantities = _objectSpread({}, state.quantities);
        Object.entries(quantities).forEach(([variantIndex, quantitiesPerVariant]) => {
          const hasSizeDependentPrice = product.sizeDependentPrice;
          const hasSizeAmountChanged = quantitiesPerVariant.length && product.productSize && quantitiesPerVariant.length !== product.productSize.count;
          if (hasSizeAmountChanged && !hasSizeDependentPrice) {
            const totalQuantityPerVariant = quantitiesPerVariant.reduce((a, b) => a + b, 0);
            quantities[variantIndex] = spreadQuantities({
              quantity: totalQuantityPerVariant,
              itemsQuantityToSpread: product.productSize.count
            });
          }
        });
        return _objectSpread({}, state, {
          quantities,
          product: product,
          productLoaded: true,
          getProductIsLoading: false,
          isCustomizedBlank: isCustomizedBlank,
          methodIndex: isCustomizedBlank ? 0 : getMethodIndex(state.embellishmentMethod, product.embellishmentMethods),
          view: isProductFullDesign(product) ? currentViewForFullDesignProduct : Object.keys(product.variants[0].images)[0],
          selectedProductionTime,
          boxItemsId,
          isLETimeExpired: product.limitedEdition.expirationDate ? getLeftTime(new Date(product.limitedEdition.expirationDate)) <= 0 : false
        });
      }
    case FETCH_PRODUCT_FAIL:
      return _objectSpread({}, state, {
        getProductIsLoading: false
      });
    case FETCH_PRODUCT_START:
      return _objectSpread({}, state, {
        getProductIsLoading: true
      });
    case FETCH_SIZE_SUCCESS:
      {
        const {
          size
        } = action;
        return _objectSpread({}, state, {
          size,
          sizeLoaded: true
        });
      }
    case TOGGLE_FORCE_HIDE_TOOLBAR:
      {
        const {
          payload
        } = action;
        return _objectSpread({}, state, {
          isToolbarForceHidden: payload
        });
      }
    case FETCH_SIZE_FAIL:
      return state;
    case CHANGE_VARIANT:
      {
        const {
          variantIndex
        } = action.payload;
        const {
          quantities: prevQuantities,
          variantIndex: prevVariantIndex,
          splitOrderMode,
          product: {
            designUploadMode
          }
        } = state;
        const quantities = _objectSpread({}, prevQuantities);
        quantities[variantIndex] = prevQuantities[prevVariantIndex];
        if (variantIndex !== prevVariantIndex && !splitOrderMode && designUploadMode !== DesignUploadMode.fullDesign) {
          delete quantities[prevVariantIndex];
        }
        return _objectSpread({}, state, {
          variantIndex,
          selectedVariants: [variantIndex],
          quantities
        });
      }
    case ADD_SELECTED_VARIANT:
      {
        const {
          variantIndex
        } = action.payload;
        const selectedVariants = [...state.selectedVariants, variantIndex];
        return _objectSpread({}, state, {
          selectedVariants
        });
      }
    case CHANGE_TIME:
      return _objectSpread({}, state, {
        selectedProductionTime: action.time
      });
    case CHANGE_VIEW:
      return _objectSpread({}, state, {
        view: action.view
      });
    case CHANGE_PREVIEW:
      {
        const nextState = _objectSpread({}, state);
        if (TOOLBAR_LOGO_RELATED_ITEMS.includes(state.openedToolbarItem)) {
          nextState.openedToolbarItem = null;
        }
        nextState.view = action.view;
        return nextState;
      }
    case CHANGE_DISCOUNT:
      return _objectSpread({}, state, {
        discount: !state.discount
      });
    case CHANGE_IS_CUSTOMIZED_BLANK:
      {
        const {
          isCustomizedBlank
        } = action.payload;
        return _objectSpread({}, state, {
          isCustomizedBlank,
          setupFeeDeductionDiscount: initialState.setupFeeDeductionDiscount,
          methodIndex: isCustomizedBlank ? 0 : state.selectedProductionTime
        });
      }
    case CHANGE_SIZE_INDEX:
      {
        const newSizeIndex = action.payload;
        const {
          selectedVariants,
          sizeIndex: previousSizeIndex
        } = state;
        const quantities = _objectSpread({}, state.quantities);
        selectedVariants.forEach(variantIndex => {
          const currentQuantity = quantities[variantIndex][previousSizeIndex];
          quantities[variantIndex] = quantities[variantIndex].map((quantity, sizeIndex) => sizeIndex === newSizeIndex ? currentQuantity : 0);
        });
        return _objectSpread({}, state, {
          quantities,
          sizeIndex: newSizeIndex
        });
      }
    case CHANGE_DESIGN_INFO:
      {
        const {
          notes
        } = action.payload;
        return _objectSpread({}, state, {
          designNotes: notes
        });
      }
    case UPLOAD_ATTACHMENT_SUCCESS:
      {
        const {
          attachments
        } = action.payload;
        const newAttachments = attachments.map(attachment => _objectSpread({}, attachment, {
          isCustomersAttachment: true
        }));
        const updatedAttachments = newAttachments.reduce((all, next) => {
          const index = all.findIndex(a => a.originalFileName === next.originalFileName);
          if (index !== -1) {
            all[index] = next;
            return all;
          }
          return [...all, next];
        }, [...state.attachments.filter(a => !!a.src)]);
        return _objectSpread({}, state, {
          isLoading: false,
          attachments: updatedAttachments
        });
      }
    case REMOVE_ATTACHMENT:
      {
        const {
          key
        } = action.payload;
        return _objectSpread({}, state, {
          attachments: state.attachments.filter(attachment => attachment.key !== key)
        });
      }
    case ADD_RELATED_ITEMS_TO_CART:
    case RESET_REDUCER:
      {
        return _objectSpread({}, initialState, {
          asRelatedItemId: uuidv1(),
          initialCreationDate: Date.now()
        });
      }
    case REORDER_ITEM_REQUESTED:
    case REBUILDING_ITEM_REQUESTED:
      {
        const {
          item: {
            discount,
            variants,
            prodTime,
            designNotes,
            sizeInd,
            _id,
            embellishmentMethod,
            setupFeeDeductionDiscount,
            attachments,
            readyForPromo,
            price,
            reorderId,
            reorderItemChainId,
            itemId,
            isSample,
            isPrintedSample,
            asRelatedItemId,
            adminAttachments,
            boxItemsId,
            isReorderFromSwagSampleBox,
            isCustomizedBlank,
            modifiedProductName,
            initialCreationDate,
            sendosoSKUs,
            isReorder: isReorderItem
          },
          item,
          relatedItems,
          storageType,
          upsellDismissedStates
        } = action.payload;
        const quantities = variants.reduce((allQuantities, variant) => {
          var _item$prod, _item$prod2, _item$prod5, _item$prod6;
          let quantityToSpread = DEFAULT_QUANTITY;
          if (isReorderFromSwagSampleBox && boxItemsId && variant.quantities) {
            quantityToSpread = variant.quantities[0];
          }
          if ((isSample || isPrintedSample) && !isProductQuantityPerSizeEnabled(item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.productSize, item === null || item === void 0 ? void 0 : (_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : _item$prod2.sizeDependentPrice)) {
            var _item$prod3, _item$prod4;
            variant.quantities[sizeInd] = DEFAULT_QUANTITY > (item === null || item === void 0 ? void 0 : (_item$prod3 = item.prod) === null || _item$prod3 === void 0 ? void 0 : _item$prod3.minQuant) ? DEFAULT_QUANTITY : item === null || item === void 0 ? void 0 : (_item$prod4 = item.prod) === null || _item$prod4 === void 0 ? void 0 : _item$prod4.minQuant;
          }
          return _objectSpread({}, allQuantities, {
            [variant.variantIndex]: (isSample || isPrintedSample) && isProductQuantityPerSizeEnabled(item === null || item === void 0 ? void 0 : (_item$prod5 = item.prod) === null || _item$prod5 === void 0 ? void 0 : _item$prod5.productSize, item === null || item === void 0 ? void 0 : (_item$prod6 = item.prod) === null || _item$prod6 === void 0 ? void 0 : _item$prod6.sizeDependentPrice) && spreadQuantities({
              quantity: quantityToSpread,
              itemsQuantityToSpread: variant.quantities.length
            }) || variant.quantities
          });
        }, {});
        const selectedVariants = variants.map(({
          variantIndex
        }) => variantIndex);
        const isReorderMode = action.type === REORDER_ITEM_REQUESTED;
        let relatedItemId = asRelatedItemId || initialState.asRelatedItemId;
        if (boxItemsId && !asRelatedItemId || !asRelatedItemId && !isReorderMode) {
          relatedItemId = null;
        }
        if (isReorderMode) {
          relatedItemId = uuidv1();
        }
        const routeBoxId = getRouteBoxItemsId(isReorderMode, boxItemsId, false);
        const mode = isReorderMode ? PRODUCT_BUILDER_MODES.REORDER_MODE : PRODUCT_BUILDER_MODES.REBUILD_MODE;
        let selectedAssociatedProducts = [];
        if (relatedItems) {
          selectedAssociatedProducts = relatedItems.map(item => {
            const relatedItemQuantities = item.variants.reduce((allQuantities, variant) => _objectSpread({}, allQuantities, {
              [variant.variantIndex]: getRelatedProductVariantQuantities(item, variant)
            }), {});
            const relatedItemSelectedVariants = item.variants.map(({
              variantIndex
            }) => variantIndex);
            return _objectSpread({}, item, {
              itemId: _id || itemId,
              selectedVariants: relatedItemSelectedVariants,
              quantities: relatedItemQuantities,
              product: _objectSpread({}, item.prod),
              selectedProductionTime: item.prodTime,
              sizeIndex: item.sizeInd,
              splitOrderMode: selectedVariants.length > 1,
              variantIndex: relatedItemSelectedVariants[0],
              size: item.prod.size,
              relatedMode: 'rebuild',
              asRelatedItemId: relatedItemId,
              inventoryId: item.inventoryId || null,
              upsellDismissedStates,
              initialCreationDate,
              sendosoSKUs
            });
          });
        }
        let sampleDeductionDiscount = 0;
        if (readyForPromo) {
          if (isReorderFromSwagSampleBox) {
            sampleDeductionDiscount = getItemShippingCost(getDataForGettingItemShippingCost(item));
          } else if (item.sampleDeductionDiscount) {
            sampleDeductionDiscount = item.sampleDeductionDiscount;
          } else {
            sampleDeductionDiscount = Math.round(price);
          }
        }
        return _objectSpread({}, state, {
          mode,
          discount,
          reorderId,
          isReorder: isReorderMode || isReorderItem,
          reorderItemChainId,
          quantities,
          designNotes,
          attachments,
          readyForPromo,
          adminAttachments,
          selectedVariants,
          embellishmentMethod,
          modifiedProductName,
          initialCreationDate,
          isReorderFromSwagSampleBox,
          isCustomizedBlank,
          methodIndex: isCustomizedBlank ? 0 : -1,
          selectedAssociatedProducts,
          storageType: storageType || null,
          sizeIndex: sizeInd,
          itemId: _id || itemId,
          boxItemsId: routeBoxId,
          sampleDeductionDiscount,
          asRelatedItemId: relatedItemId,
          inventoryId: item.inventoryId || null,
          selectedProductionTime: prodTime,
          variantIndex: selectedVariants[0],
          splitOrderMode: selectedVariants.length > 1,
          setupFeeDeductionDiscount: setupFeeDeductionDiscount || 0,
          upsellDismissedStates,
          sendosoSKUs
        });
      }
    case REBUILD_CUSTOMER_MOCKUP:
      {
        const {
          item
        } = action.payload;
        const {
          _id,
          variants,
          discount,
          prodTime,
          designNotes,
          sizeInd,
          embellishmentMethod,
          sampleDeductionDiscount
        } = item;
        const selectedVariants = variants.map(({
          variantIndex
        }) => variantIndex);
        const quantities = variants.reduce((allQuantities, variant) => _objectSpread({}, allQuantities, {
          [variant.variantIndex]: variant.quantities
        }), {});
        return _objectSpread({}, state, {
          mode: PRODUCT_BUILDER_MODES.REBUILD_MOCKUP_MODE,
          itemId: _id,
          discount,
          quantities,
          selectedProductionTime: prodTime,
          designNotes,
          sizeIndex: sizeInd,
          splitOrderMode: selectedVariants.length > 1,
          variantIndex: selectedVariants[0],
          selectedVariants,
          embellishmentMethod,
          sampleDeductionDiscount
        });
      }
    case APPLY_SIZE_SUBMISSION_MOCKUP:
      {
        const {
          item
        } = action.payload;
        const {
          sizeInd,
          embellishmentMethod,
          selectedVariants,
          variantIndex,
          quantities
        } = item;
        return _objectSpread({}, state, {
          sizeIndex: sizeInd,
          variantIndex,
          selectedVariants,
          quantities,
          embellishmentMethod,
          splitOrderMode: selectedVariants.length > 1
        });
      }
    case CHANGE_SINGLE_VARIANT_QUANTITIES:
      {
        const {
          quantities,
          variantIndex
        } = action.payload;
        const updatedQuantities = _objectSpread({}, state.quantities, {
          [variantIndex]: quantities
        });
        return _objectSpread({}, state, {
          quantities: updatedQuantities
        });
      }
    case CHANGE_SINGLE_QUANTITY:
      {
        const {
          quantity,
          variantIndex,
          sizeIndex
        } = action.payload;
        const updatedVariantQuantities = [...state.quantities[variantIndex]];
        updatedVariantQuantities[sizeIndex] = quantity;
        const updatedQuantities = _objectSpread({}, state.quantities, {
          [variantIndex]: updatedVariantQuantities
        });
        return _objectSpread({}, state, {
          quantities: updatedQuantities
        });
      }
    case CHANGE_ASSOCIATED_PRODUCT_QUANTITY:
      {
        const {
          productId,
          variantIndex,
          sizeIndex,
          quantity
        } = action.payload;
        const updatedSelectedAssociatedProducts = state.selectedAssociatedProducts.map(item => {
          if (item.product._id === productId) {
            return _objectSpread({}, item, {
              quantities: _objectSpread({}, item.quantities, {
                [variantIndex]: item.quantities[variantIndex].map((sizeQuantity, index) => index === sizeIndex ? quantity : sizeQuantity)
              })
            });
          }
          return _objectSpread({}, item);
        });
        return _objectSpread({}, state, {
          selectedAssociatedProducts: updatedSelectedAssociatedProducts
        });
      }
    case CHANGE_QUANTITIES:
      {
        const {
          quantities,
          selectedAssociatedProductsPayload,
          isQuantityManuallyChanged
        } = action.payload;
        if (selectedAssociatedProductsPayload) {
          const selectedAssociatedProducts = state.selectedAssociatedProducts.map(item => {
            const targetPayload = selectedAssociatedProductsPayload.filter(i => i.relatedProductId === item.product._id)[0];
            return _objectSpread({}, item, {
              quantities: targetPayload.quantities
            });
          });
          return _objectSpread({}, state, {
            quantities,
            selectedAssociatedProducts,
            isQuantityManuallyChanged
          });
        }
        return _objectSpread({}, state, {
          quantities,
          isQuantityManuallyChanged
        });
      }
    case TOGGLE_SPLIT_ORDER_ON:
      {
        return _objectSpread({}, state, {
          splitOrderMode: true
        });
      }
    case TOGGLE_SPLIT_ORDER_OFF:
      {
        const quantities = _objectSpread({}, state.quantities);
        const selectedVariants = [...state.selectedVariants];
        selectedVariants.splice(1, selectedVariants.length - 1);
        const variantIndex = selectedVariants[0];
        const variantQuantity = quantities[variantIndex];
        return _objectSpread({}, state, {
          splitOrderMode: false,
          selectedVariants,
          variantIndex,
          quantities: {
            [variantIndex]: variantQuantity
          }
        });
      }
    case UNSELECT_VARIANT:
      {
        const {
          variantIndex: variantIndexToRemove
        } = action.payload;
        const selectedVariants = [...state.selectedVariants];
        const quantities = _objectSpread({}, state.quantities);
        const index = state.selectedVariants.findIndex(variantIndex => variantIndex === variantIndexToRemove);
        if (index !== -1 && selectedVariants.length > 1) {
          selectedVariants.splice(index, 1);
          delete quantities[variantIndexToRemove];
        }
        return _objectSpread({}, state, {
          selectedVariants,
          variantIndex: selectedVariants[0],
          quantities
        });
      }
    case CHANGE_FULL_SCREEN_MODE:
      {
        return _objectSpread({}, state, {
          fullScreenMode: !state.fullScreenMode
        });
      }
    case CHANGE_MORE_IMAGE_MODE:
      {
        return _objectSpread({}, state, {
          showMoreImageMode: !state.showMoreImageMode
        });
      }
    case PRELOAD_SUGGESTED_PRODUCT:
      {
        const {
          info: {
            listOfNearestVariants,
            id
          }
        } = action.payload;
        const variantsToChoose = getProductVariantsToChoose(state.product);
        const selectedVariant = variantsToChoose.find((item, index) => (listOfNearestVariants === null || listOfNearestVariants === void 0 ? void 0 : listOfNearestVariants[id]) === index);
        const selectedVariantIndex = (selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.index) || (listOfNearestVariants === null || listOfNearestVariants === void 0 ? void 0 : listOfNearestVariants[id]);
        return _objectSpread({}, state, {
          selectedVariants: [selectedVariantIndex],
          variantIndex: selectedVariantIndex
        });
      }
    case CHANGE_METHOD_INDEX:
      {
        return _objectSpread({}, state, {
          methodIndex: action.payload.index
        });
      }
    case TOGGLE_ADD_TEXT:
      {
        return _objectSpread({}, state, {
          isAddTextMode: action.payload.isAddTextMode
        });
      }
    case GET_RELATED_PRODUCTS_SUCCESS:
      {
        const {
          associatedProducts
        } = action.payload;
        const selectedAssociatedProducts = state.selectedAssociatedProducts.map(associatedProduct => {
          const product = associatedProducts.find(product => product._id === associatedProduct.product._id);
          const productSettings = product ? product.productSettings : associatedProduct.product.productSettings;
          return _objectSpread({}, associatedProduct, {
            product: _objectSpread({}, associatedProduct.product, {
              productSettings
            })
          });
        });
        return _objectSpread({}, state, {
          associatedProducts,
          selectedAssociatedProducts
        });
      }
    case GET_SUGGESTED_PRODUCTS_SUCCESS:
      {
        return _objectSpread({}, state, {
          suggestedProducts: action.payload.products,
          listOfNearestVariants: action.payload.listWithNearestColor
        });
      }
    case REMOVE:
      {
        let result = state;
        if (TOOLBAR_LOGO_RELATED_ITEMS.includes(state.openedToolbarItem)) {
          result = _objectSpread({}, state, {
            openedToolbarItem: null
          });
        }
        if (state.setupFeeDeductionDiscount) {
          return _objectSpread({}, result, {
            setupFeeDeductionDiscount: 0,
            isReorder: false,
            reorderId: null,
            reorderItemChainId: null
          });
        }
        return result;
      }
    case ADD_TEXT:
    case ADD_LOGO:
    case REMOVE_COLOR:
    case ADD_COLOR:
    case CHANGE_LOGO_DIMENSIONS:
    case PANTONES_CONFIRMED:
      {
        if (state.setupFeeDeductionDiscount) {
          return _objectSpread({}, state, {
            setupFeeDeductionDiscount: 0,
            isReorder: false,
            reorderId: null,
            reorderItemChainId: null
          });
        }
        return state;
      }
    case ADD_ITEM_TO_CART:
      {
        if (state.setupFeeDeductionDiscount) {
          return _objectSpread({}, state, {
            setupFeeDeductionDiscount: 0,
            isReorder: false,
            reorderId: null,
            reorderItemChainId: null
          });
        }
        return _objectSpread({}, state, {
          boxItemsId: null
        });
      }
    case CHANGE_RELATED_PRODUCTS_POPUP_VISIBILITY:
      {
        return _objectSpread({}, state, {
          isOpenRelatedProductsPopup: !state.isOpenRelatedProductsPopup
        });
      }
    case UPLOAD_ATTACHMENT_REQUEST:
      {
        return _objectSpread({}, state, {
          isLoading: true
        });
      }
    case UPLOAD_ATTACHMENT_FAILURE:
      {
        return _objectSpread({}, state, {
          isLoading: false
        });
      }
    case MOUSE_ON_EMBELLISHMENT_METHOD:
      {
        return _objectSpread({}, state, {
          embellishmentMethodMouseOn: action.payload
        });
      }
    case MOUSE_OUT_EMBELLISHMENT_METHOD:
      {
        return _objectSpread({}, state, {
          embellishmentMethodMouseOn: null
        });
      }
    case RESET_INVENTORY_ID:
      {
        return _objectSpread({}, state, {
          inventoryId: null
        });
      }
    case UPSELL_DISMISS_PRODUCT_BUILDER:
      {
        return _objectSpread({}, state, {
          upsellDismissedStates: {
            productBuilder: true
          }
        });
      }
    case SET_IS_LE_TIME_EXPIRED:
      {
        return _objectSpread({}, state, {
          isLETimeExpired: true
        });
      }
    case SET_SIZE_SUBMISSION_ID:
      {
        return _objectSpread({}, state, {
          sizeSubmissionId: action.payload.id
        });
      }
    default:
      return state;
  }
}