import _isEqual from "lodash/isEqual";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { Price } from 'swag-client-common/utils/price';
import * as utils from 'swag-common/utils/order';
import { getTaxes, getGrandTotal } from 'swag-common/utils/order';
import { isDeliverToFulfillmentCenter } from 'swag-common/utils/order/is-deliver-to-fulfillment-center.util';
import * as ITEM_STATUSES from 'swag-common/constants/item-statuses.constants';
import { PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
import { calculateSetupFeeValue, getMaxSetupFee } from 'swag-common/business-logic';
import { getItemPriceMultiplier } from 'swag-common/business-logic/get-item-price-multiplier.logic';
import checkIsItemAssociated from 'swag-common/utils/order/check-is-item-assosiated.util';
import { DesignUploadMode } from 'swag-common/interfaces';
import { getDataForGettingItemShippingCost, getItemShippingCost } from 'swag-common/business-logic/order/get-item-shipping-cost.service';
import { getOrderItemImage } from 'swag-common/business-logic/items/get-order-item-image.logic';
import { PRODUCTION_MOCKUP_STATUSES } from 'swag-common/constants/order-statuses.constants';
import { getListOfSampleDeductionDiscounts, getColumnSpan } from '../account-orders/account-orders.utils';
import { userCompanyDepartments } from '../user/user.selectors';
export const pendingOrdersSelector = state => state.pendingOrders.orders;
export const loadingSelector = state => state.pendingOrders.loading;
export const samplePromoItemsInCartSelector = () => false;
export const singleOrderSelector = (state, props) => {
  const {
    orderId
  } = props;
  return state.pendingOrders.orders.find(o => o._id === orderId);
};
export const paymentMethodSelector = createSelector(singleOrderSelector, order => order.paymentMethod);
export const shippingAddressSelector = createSelector(singleOrderSelector, ({
  address,
  unit,
  city,
  state,
  country,
  zip
}) => {
  let result = '';
  if (address) {
    result += `${address}, `;
  }
  if (unit) {
    result += `${unit}, `;
  }
  if (city) {
    result += `${city}, `;
  }
  if (state) {
    result += `${state}, `;
  }
  if (country) {
    result += `${country}, `;
  }
  if (zip) {
    result += `${zip}`;
  }
  return result;
});
export const noOrdersMessageSelector = createSelector(pendingOrdersSelector, orders => orders.length === 0);
export const singleOrderNumberSelector = createSelector(singleOrderSelector, order => order.num);
export const singleOrderTotalSelector = createSelector(singleOrderSelector, order => getGrandTotal(order));
export const singleOrderItemsSelector = createSelector(singleOrderSelector, order => utils.groupItemsIntoBoxes(order.items));
export const singleOrderMeasurementSelector = createSelector(singleOrderSelector, order => order.measurement);
export const singleOrderKsFlowSelector = createSelector(singleOrderSelector, order => order.ksFlow);
export const singleOrderKsFulfillmentSelector = createSelector(singleOrderSelector, order => order.ksFulfillment);
export const singleOrderKsTrackingsSelector = createSelector(singleOrderSelector, order => order.ksTrackings);
export const singleOrderItemsNumberSelector = createSelector(singleOrderItemsSelector, items => items.length);
export const singleOrderInvoiceLinkSelector = () => null;
export const singleOrderItemsIdsSelector = createSelector(singleOrderItemsSelector, items => {
  const relatedItems = items.reduce((acc, item) => {
    if (item.asRelatedItemId) {
      return _objectSpread({}, acc, {
        [item.asRelatedItemId]: (acc[item.asRelatedItemId] || []).concat(item)
      });
    }
    return acc;
  }, {});
  const itemIds = [];
  items.forEach(item => {
    if (relatedItems[item.asRelatedItemId]) {
      const relatedIds = relatedItems[item.asRelatedItemId].map(i => i._id);
      const isIdsExistAlready = itemIds.find(element => _isEqual(element, relatedIds));
      if (!isIdsExistAlready) {
        itemIds.push(relatedIds);
      }
    } else {
      if (item._id) {
        itemIds.push([item._id]);
      }
    }
  });
  return itemIds;
});
export const singleOrderTaxesPriceSelector = createSelector(singleOrderSelector, order => getTaxes(order));
export const singleOrderPromocodeDiscountPriceSelector = createSelector(singleOrderSelector, order => order.promocodeDiscount);
export const singleOrderConfirmedAtPriceSelector = createSelector(singleOrderSelector, order => order.confirmedAt);
export const singleOrdersFulfillmentPerItemSelector = createSelector(singleOrderItemsSelector, singleOrderKsFulfillmentSelector, (items, ksFulfillment) => {
  const ksItemsNumber = items.reduce((number, item) => item.ksFlow ? number + 1 : number, 0);
  return ksFulfillment / ksItemsNumber;
});
export const singleOrderDeductionDiscountsSelector = createSelector(singleOrderItemsSelector, items => getListOfSampleDeductionDiscounts(items));
export const singleItemInOrderSelector = (state, props) => {
  const items = singleOrderItemsSelector(state, props);
  const {
    itemId
  } = props;
  return items.find(i => i._id === itemId);
};
export const itemProdSelector = createSelector(singleItemInOrderSelector, item => item.prod);
export const itemStatusSelector = createSelector(singleItemInOrderSelector, item => item.status);
export const itemTrackingNumberSelector = createSelector(singleItemInOrderSelector, item => item.trackingNumber);
export const itemQuantitySelector = createSelector(singleItemInOrderSelector, item => item.quantity);
export const itemBlendedQuantitySelector = createSelector(singleItemInOrderSelector, item => item.blendedQuantity);
export const itemVariantsSelector = createSelector(singleItemInOrderSelector, item => item.variants);
export const itemShipmentChargesSelector = createSelector(singleItemInOrderSelector, item => item.shipmentCharges);
export const itemServiceCodeSelector = createSelector(singleItemInOrderSelector, item => item.serviceCode);
export const singleOrderShipmentDiscountSelector = createSelector(singleOrderSelector, order => order.shipmentDiscount);
export const singleOrderSetupFeeDiscountSelector = createSelector(singleOrderSelector, order => order.setupFeeDiscount);
export const userInfoSelector = createSelector([singleOrderSelector, userCompanyDepartments], (order, companyDepartments) => {
  const companyDepartment = companyDepartments.find(item => item._id === order.companyDepartmentId);
  const baseInfoString = `${order.firstName} ${order.lastName} - ${order.email}`;
  if (companyDepartment) {
    return `${baseInfoString}, ${companyDepartment.name} department`;
  }
  return baseInfoString;
});
export const singleItemSetupFeeSelector = createSelector(singleItemInOrderSelector, singleOrderItemsSelector, (item, items) => {
  const {
    isSample,
    isPrintedSample,
    prodTime
  } = item;
  const relatedItems = items.filter(i => i.asRelatedItemId === item.asRelatedItemId);
  const multiplier = getItemPriceMultiplier(item);
  let setupFee = calculateSetupFeeValue({
    product: item.prod,
    logos: item.logos,
    texts: null,
    isSample: isSample || isPrintedSample,
    prodTime,
    multiplier
  });
  if (relatedItems.length > 1) {
    const fees = getMaxSetupFee(items);
    setupFee = fees[item.asRelatedItemId] * item.quantity / item.blendedQuantity;
  }
  if (!!item.setupFeeDeductionDiscount && item.isReorder) {
    setupFee -= item.setupFeeDeductionDiscount;
  }
  return setupFee;
});
const singleItemsIsRelatedSelector = createSelector(singleOrderItemsSelector, singleItemInOrderSelector, checkIsItemAssociated);
export const itemPriceSelector = createSelector(singleItemInOrderSelector, singleOrderItemsSelector, singleItemsIsRelatedSelector, (item, items, isRelated) => {
  const {
    setupFeeDeductionDiscount = 0,
    price,
    asRelatedItemId
  } = item;
  if (isRelated) {
    const relatedItems = items.filter(i => i.asRelatedItemId === asRelatedItemId);
    const relatedItemsTotal = relatedItems.reduce((total, i) => total + i.price, 0);
    const setupFee = getMaxSetupFee(relatedItems);
    return relatedItemsTotal - setupFee[asRelatedItemId];
  }
  if (item.prod.designUploadMode === DesignUploadMode.box) {
    return [item.price, ...item.products.map(i => i.price)].reduce((all, next) => all + next, 0);
  }
  return price - setupFeeDeductionDiscount;
});
export const itemSizeIndSelector = createSelector(singleItemInOrderSelector, item => item.sizeInd);
export const itemKsFlowSelector = createSelector(singleItemInOrderSelector, item => item.ksFlow);
export const itemCustomerMockupsSelector = createSelector(singleItemInOrderSelector, item => item.customerMockups);
export const itemProductionMockupsSelector = createSelector(singleItemInOrderSelector, item => item.productionMockups);
export const itemShippingCompanySelector = createSelector(singleItemInOrderSelector, item => item.shippingCompany);
export const itemReadyForPromoSelector = () => false;
export const samplePromoItemPriceSelector = createSelector(singleOrderMeasurementSelector, singleOrderPromocodeDiscountPriceSelector, singleOrderItemsNumberSelector, singleItemInOrderSelector, (measurement, promocodeDiscount, itemsCount, item) => {
  const {
    price,
    quantity
  } = item;
  const discount = measurement === PromocodeMeasurementEnum.ABSOLUTE && Math.round(promocodeDiscount / itemsCount) || promocodeDiscount;
  return Price.applyDiscount(price / quantity, discount, measurement);
});
export const promoItemWasReorderedSelector = () => false;
export const itemImageSelector = createSelector(itemVariantsSelector, itemProdSelector, itemCustomerMockupsSelector, itemSizeIndSelector, (variants, prod, customerMockups, sizeInd) => {
  const variantInd = variants && variants[0].variantIndex;
  const imageData = getOrderItemImage({
    product: prod,
    customerMockups,
    variantInd,
    sizeInd
  });
  return imageData && imageData.url;
});
export const isDeliverToFulfillmentCenterSelector = createSelector(singleOrderSelector, isDeliverToFulfillmentCenter);
export const itemShipmentPriceSelector = createSelector(singleItemInOrderSelector, item => getItemShippingCost(getDataForGettingItemShippingCost(item)));
export const itemApprovedProductionMockupSelector = createSelector(itemProductionMockupsSelector, productionMockups => {
  const approvedProductionMockups = productionMockups && productionMockups.filter(i => i.status === PRODUCTION_MOCKUP_STATUSES.APPROVED_BY_CUSTOMER);
  return approvedProductionMockups && approvedProductionMockups[approvedProductionMockups.length - 1];
});
export const setupFeeAndShipmentDiscountSelector = createSelector(singleOrderSelector, order => Price.getAdditionalDiscountValues(order));
export const singleOrderAbsoluteDiscountValueSelector = createSelector(singleOrderSelector, setupFeeAndShipmentDiscountSelector, ({
  items,
  measurement,
  promocodeDiscount
}, {
  setupFeeDiscount
}) => Price.getDiscount(items, promocodeDiscount, measurement, setupFeeDiscount));
export const typeOrderSelector = () => 'account';
const itemsByIdsSelector = (state, props) => {
  const {
    relatedItems
  } = props;
  const order = singleOrderSelector(state, props);
  return order.items.filter(item => relatedItems.find(id => id === item._id));
};
export const shipmentItemsTotalSelector = createSelector(itemsByIdsSelector, items => items.reduce((shipmentTotal, item) => getItemShippingCost(getDataForGettingItemShippingCost(item, items)) + shipmentTotal, 0));
export const isOrderForCustomStoreSelector = createSelector(singleOrderSelector, order => !!order.orderForCustomStore);
export const isOrderPendingApprovalSelector = createSelector(singleOrderSelector, order => !!order.items.find(item => item.status === ITEM_STATUSES.PENDING_APPROVAL));
export const getRelatedItemsIdsSelector = (state, props) => props.relatedItems;
export const getRelatedItemsSelector = createSelector(singleOrderSelector, getRelatedItemsIdsSelector, ({
  items
}, relateIds) => items.filter(({
  _id
}) => relateIds.includes(_id)));
export const footerEmptyColumnsNumberSelector = createSelector(isDeliverToFulfillmentCenterSelector, isToFulfillmentCenter => getColumnSpan(5, isToFulfillmentCenter));
export const relatedItemsTotalSelector = createSelector(getRelatedItemsSelector, shipmentItemsTotalSelector, isDeliverToFulfillmentCenterSelector, (relatedItems, shipmentTotal) => {
  const totalPriceForItems = relatedItems.reduce((total, item) => item.price + total, 0);
  return totalPriceForItems + shipmentTotal;
});
export const relatedItemsSetupFeeSelector = createSelector(itemsByIdsSelector, items => {
  const {
    asRelatedItemId,
    setupFeeDeductionDiscount
  } = items[0];
  const setupFee = getMaxSetupFee(items);
  return setupFee[asRelatedItemId] - setupFeeDeductionDiscount;
});
export const itemPricePerPieceSelector = createSelector(singleItemSetupFeeSelector, itemPriceSelector, singleItemsIsRelatedSelector, itemQuantitySelector, itemBlendedQuantitySelector, (setupFee, price, isRelated, quantity, blendedQuantity) => isRelated ? price / blendedQuantity : (price - setupFee) / quantity);
export const customProductIdSelector = createSelector(singleItemInOrderSelector, ({
  customProductId
}) => customProductId);