import { getProductImage } from 'swag-common/utils/product/product.utils';
import { hasOwnProperties } from 'swag-common/utils/has-own-properties';
export const getOrderItemImage = ({
  product,
  customerMockups,
  variantInd = 0,
  sizeInd = 0
}) => {
  const image = getProductImage({
    product,
    variantInd,
    view: 'front',
    sizeInd
  });
  const fallback = {
    url: (image === null || image === void 0 ? void 0 : image.url) || product.logo
  };
  if (hasOwnProperties(customerMockups)) {
    var _customerMockup$fileN;
    const customerMockupsArray = Object.values(customerMockups);
    const customerMockup = customerMockupsArray.find(mockup => mockup.variantIndex === variantInd);
    return {
      url: (_customerMockup$fileN = customerMockup === null || customerMockup === void 0 ? void 0 : customerMockup.fileName) !== null && _customerMockup$fileN !== void 0 ? _customerMockup$fileN : customerMockupsArray[0].fileName
    };
  }
  return fallback;
};