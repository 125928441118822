import { createSelector } from 'reselect';
import { userHasInventoriesSelector, isResellerInOrderCreationModeSelector, isPreBuiltCartUserInventoryTermAcceptedSelector } from 'swag-client-common/redux/user/user.selectors';
import { isForbiddenForInventoryItem } from 'swag-common/utils/items/is-forbidden-for-inventory.util';
import { isLoggedInSelector } from '../user/user.selectors';
import { addressSummaryInventoryNameSelector, cartItemsSelector } from './inventory.selectors';
export const inventoryAddressTextSelector = createSelector(addressSummaryInventoryNameSelector, name => `Adding to ${name}`);
export const shouldShowDeliveryLabelSelector = () => true;
const forbiddenForInventoryItemNamesSelector = createSelector(cartItemsSelector, items => {
  return items.reduce((acc, item) => {
    if (isForbiddenForInventoryItem(item)) {
      acc.push(item.prod.name);
    }
    return acc;
  }, []);
});
export const sampleItemsSelector = createSelector(cartItemsSelector, items => {
  return items.find(item => item.isSample === true);
});
export const printedSampleItemsSelector = createSelector(cartItemsSelector, items => {
  return items.find(item => item.isPrintedSample === true);
});
const getForbiddenForInventoryMessage = itemNames => {
  return `${itemNames.join(', ')} cannot be stored using our inventory feature.`;
};
export const restrictionTooltipMessagesSelector = createSelector(forbiddenForInventoryItemNamesSelector, sampleItemsSelector, printedSampleItemsSelector, isResellerInOrderCreationModeSelector, isPreBuiltCartUserInventoryTermAcceptedSelector, (forbiddenForInventoryItemNames, sampleItems, printedSampleItems, isResellerInOrderCreationMode, isPreBuiltCartUserInventoryTermAccepted) => {
  const messages = [];
  if (forbiddenForInventoryItemNames.length > 0) {
    const message = getForbiddenForInventoryMessage(forbiddenForInventoryItemNames);
    messages.push(message);
  }
  if (sampleItems || printedSampleItems) {
    messages.push('Samples cannot be stored using our inventory feature.');
  }
  if (isResellerInOrderCreationMode && !isPreBuiltCartUserInventoryTermAccepted) {
    messages.push('Inventory agreement was not signed by the customer');
  }
  return messages;
});
export const isNotInventoryLabelVisibleSelector = () => true;
export const showChangeDeliveryAddressButtonSelector = () => true;
export const isWarehouseAllowedSelector = () => {
  const {
    swag: {
      storeSettings
    }
  } = window;
  return Boolean(storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.warehouseAllowed);
};
export const isWarehouseAllowedForDeliverySelector = createSelector(isWarehouseAllowedSelector, isLoggedInSelector, userHasInventoriesSelector, (warehouseAllowed, isLoggedIn, userHasInventories) => {
  return isLoggedIn && userHasInventories || warehouseAllowed;
});
export const showInventoryLinkSelector = createSelector(userHasInventoriesSelector, isWarehouseAllowedSelector, (userHasInventories, isWarehouseAllowed) => {
  return userHasInventories || isWarehouseAllowed;
});