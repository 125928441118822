import { createSelector } from 'reselect';
import { userHasInventoriesSelector, isInventoryTermsAcceptedSelector, inventoriesCountSelector, unauthenticatedUserAlreadyExistsSelector, isResellerInInvoiceModeSelector, isResellerInPresentationModeSelector } from 'swag-client-common/redux/user/user.selectors';
import { isLoggedInSelector, userWarehouseLocationSelector } from '../user/user.selectors';
import { inventoriesSelector, userHasOneOwnInventorySelector, demoInventoryIdSelector, selectedInventoryIdSelector, userLastInventoryIdSelector, selectedDistributeOptionSelector } from '../inventory/inventory.selectors';
import { deliverToFulfillmentCenterSelector, isCreateMyInventorySelectedSelector, inventoryAcceptedSelector, acceptContractSelector, deliverySelector } from './checkout.selectors';
import { widgetIsOpenSelector } from './checkout.selectors-other';
export const isUserExistSelector = createSelector(unauthenticatedUserAlreadyExistsSelector, isLoggedInSelector, deliverToFulfillmentCenterSelector, (userAlreadyExists, isLoggedIn, deliverToFulfillmentCenter) => userAlreadyExists && !isLoggedIn && deliverToFulfillmentCenter);
export const activeStepSelector = createSelector(acceptContractSelector, inventoryAcceptedSelector, (contractAccepted, inventoryAccepted) => {
  if (contractAccepted) {
    return 2;
  }
  if (inventoryAccepted) {
    return 1;
  }
  return 0;
});
export const isUserAcceptedContractAndHaveNoInventoriesSelector = createSelector(isInventoryTermsAcceptedSelector, userHasInventoriesSelector, (isTermsAccepted, hasInventory) => isTermsAccepted && !hasInventory);
export const showAddToInventoryWidgetSelector = createSelector(widgetIsOpenSelector, deliverToFulfillmentCenterSelector, (widgetIsOpen, deliverToFulfillmentCenter) => !!widgetIsOpen && deliverToFulfillmentCenter);
export const showInventoryInfoBlockSelector = createSelector(isInventoryTermsAcceptedSelector, acceptContractSelector, isCreateMyInventorySelectedSelector, isUserAcceptedContractAndHaveNoInventoriesSelector, isResellerInInvoiceModeSelector, isResellerInPresentationModeSelector, (isInventoryTermsAccepted, acceptContract, isCreateMyInventorySelected, isUserAcceptedContractAndHaveNoInventories, isResellerInInvoiceMode, isResellerInPresentationMode) => {
  const isRegularInventoryDisplayConditionMet = (!isInventoryTermsAccepted || isCreateMyInventorySelected) && !isUserAcceptedContractAndHaveNoInventories && !acceptContract;
  return isResellerInPresentationMode || isResellerInInvoiceMode || isRegularInventoryDisplayConditionMet;
});
export const showStepperSelector = createSelector(showInventoryInfoBlockSelector, acceptContractSelector, (showInventoryInfoBlock, contractAccepted) => showInventoryInfoBlock || contractAccepted);
export const hasInventoryDropdownToShowSelector = createSelector(isLoggedInSelector, userHasInventoriesSelector, inventoriesSelector, isCreateMyInventorySelectedSelector, activeStepSelector, (isLoggedIn, userHasInventory, inventories, isCreateMyInventorySelected, step) => (isLoggedIn || userHasInventory) && inventories.length > 0 && !(isCreateMyInventorySelected && step !== 2));
export const isAbleToCreateInventory = createSelector(isLoggedInSelector, userHasInventoriesSelector, (isLoggedIn, userHasInventory) => isLoggedIn && userHasInventory);
export const showInventoryDropdownSelector = createSelector(isLoggedInSelector, isInventoryTermsAcceptedSelector, hasInventoryDropdownToShowSelector, inventoriesSelector, userHasOneOwnInventorySelector, isAbleToCreateInventory, (isLoggedIn, isInventoryTermsAccepted, userHasInventory, inventories, userHasOneOwnInventory, isAbleToCreateInventory) => {
  return (isLoggedIn && isInventoryTermsAccepted || !isLoggedIn) && (inventories.length > 1 || isAbleToCreateInventory) && userHasInventory && !userHasOneOwnInventory;
});
export const showProcessDescriptionSelector = createSelector(showInventoryInfoBlockSelector, isUserExistSelector, inventoriesCountSelector, hasInventoryDropdownToShowSelector, activeStepSelector, isUserAcceptedContractAndHaveNoInventoriesSelector, (showInventoryInfoBlock, isUserExist, inventoriesCount, hasInventoryToShow, step, isUserAcceptedContractAndHaveNoInventories) => !showInventoryInfoBlock && (isUserExist && inventoriesCount > 0 || hasInventoryToShow || isUserAcceptedContractAndHaveNoInventories) || step === 2);
export const showDistributionAreaSelectSelector = createSelector(showProcessDescriptionSelector, userLastInventoryIdSelector, selectedInventoryIdSelector, inventoriesSelector, isLoggedInSelector, demoInventoryIdSelector, userWarehouseLocationSelector, (showDescription, userLastInventoryId, selectedId, list, isLoggedIn, demoInventory, userWarehouse) => {
  if (userWarehouse || isLoggedIn && !(selectedId || userLastInventoryId) && !demoInventory) {
    return false;
  }
  const inventory = list.find(({
    _id
  }) => _id === selectedId);
  if (!inventory) {
    return showDescription;
  }
  return showDescription && !(inventory !== null && inventory !== void 0 && inventory.warehouseLocation);
});
export const isFinishInventoryFormButtonDisabled = createSelector(deliverySelector, selectedDistributeOptionSelector, (delivery, selectedDistributeOption) => {
  const {
    company
  } = delivery;
  const requiredValues = [selectedDistributeOption, company];
  return requiredValues.some(v => !v);
});