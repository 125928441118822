import { variables } from 'swag-client-common/styles/variables-js';
export const styles = {
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  typography: {
    fontFamily: variables.fontFamilyRegular
  },
  palette: {
    primary: {
      light: variables.colorPrimaryLighten,
      main: variables.colorPrimary,
      dark: variables.colorPrimaryDarken,
      contrastText: variables.colorWhite
    },
    secondary: {
      light: variables.colorAccentLighten,
      main: variables.colorAccent,
      dark: variables.colorAccentLighten,
      contrastText: variables.colorWhite
    }
  }
};
export const v0Styles = {
  datePicker: {
    selectColor: variables.colorPrimary,
    primary2Color: variables.colorPrimary,
    headerColor: variables.colorPrimary
  },
  fontFamily: variables.fontFamilyRegular,
  palette: {
    primary1Color: variables.colorPrimary,
    accent1Color: variables.colorAccent
  },
  raisedButton: {
    primaryColor: variables.colorPrimary,
    secondaryColor: variables.colorAccent
  },
  textField: {
    floatingLabelColor: '#37474f'
  },
  toggle: {
    trackOnColor: variables.colorPrimary,
    thumbOnColor: variables.colorPrimary
  },
  slider: {
    selectionColor: variables.colorAccent,
    rippleColor: variables.colorAccent
  },
  chip: {
    deleteIconColor: variables.colorAlternativePrimary
  },
  // @ts-ignore
  datePicker: {
    headerColor: variables.colorPrimary,
    selectColor: variables.colorPrimary
  }
};