import { createApi } from 'swag-client-common/libs/rapi';
const AuthApi = createApi({
  login: {
    url: '/api/v1/auth/login',
    method: 'post'
  },
  signup: {
    url: '/api/v1/auth/signup',
    method: 'post'
  },
  signout: {
    url: '/api/v1/auth/signout',
    method: 'post'
  },
  logoutAsUser: {
    url: '/api/v1/auth/logout-as-user',
    method: 'post'
  },
  verify: {
    url: '/api/v1/auth/activate/:token',
    method: 'get'
  },
  resendVerification: {
    url: '/api/v1/auth/send-verification-email',
    method: 'post'
  },
  changePassword: {
    url: '/api/v1/auth/change-password',
    method: 'post'
  },
  forgotPassword: {
    url: '/api/v1/auth/forgot-password',
    method: 'post'
  },
  setPassword: {
    url: '/api/v1/auth/set-password',
    method: 'post'
  },
  setPasswordByLoggedIn: {
    url: '/api/v1/auth/set-password-by-logged-in',
    method: 'post'
  },
  checkInventoryInvitation: {
    url: '/custom-store-api/v1/auth/inventory/invite/:inviteId',
    method: 'get'
  },
  acceptInventoryInvitation: {
    url: '/custom-store-api/v1/auth/inventory/invite',
    method: 'post'
  }
});
export default AuthApi;