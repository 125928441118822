import { FETCH_PROFILE_FAIL } from 'swag-client-common/redux/user/user.actions';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
export function authUnauthorizedHandler() {
  return next => action => {
    if (action !== null && action !== void 0 && action.error) {
      const error = action.error;
      if (error.status === 401 && action.type !== FETCH_PROFILE_FAIL) {
        window.location.href = `${APP_ROUTES.HOME}?login=true`;
      }
    }
    next(action);
  };
}