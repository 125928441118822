import { REQUEST_GIVEAWAY_CONTACT_SUCCESS, REQUEST_IN_A_BOX_CONTACT_SUCCESS } from '../../redux/landings/landings.actions';
export const handleSomeFormDates = (actionType, formValues) => {
  switch (actionType) {
    case REQUEST_GIVEAWAY_CONTACT_SUCCESS:
    case REQUEST_IN_A_BOX_CONTACT_SUCCESS:
      {
        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        const shipDate = formValues.shipDate;
        const deliveredDate = formValues.deliveredDate;
        if (shipDate && shipDate instanceof Date) {
          formValues.shipDate = shipDate.toLocaleDateString('en-US', options);
        }
        if (deliveredDate && deliveredDate instanceof Date) {
          formValues.deliveredDate = deliveredDate.toLocaleDateString('en-US', options);
        }
        return formValues;
      }
    default:
      return formValues;
  }
};