import { useEffect, useState } from 'react';
export function useLoadedCount(items, initialCount = 0) {
  const [countItems, setCountItems] = useState(initialCount);
  useEffect(() => {
    if (countItems < items.length) {
      setCountItems(items.length);
    }
  }, [items]);
  return {
    countItems,
    emptyItems: countItems === 0
  };
}