import { capitalize } from 'swag-common/utils/strings';
export function getUserFullName(user, shouldCapitalize = false) {
  if (!user) {
    return '';
  }
  const {
    firstName,
    lastName
  } = user;
  if (shouldCapitalize) {
    return `${capitalize(firstName)} ${capitalize(lastName)}`.trim();
  }
  return `${firstName} ${lastName}`.trim();
}
export function getUserNameBasedOnEmail(user, emailOnly) {
  if (!user) {
    return '';
  }
  const {
    firstName,
    lastName,
    email
  } = user;
  if (!firstName && !lastName || emailOnly) {
    return email;
  }
  return `${firstName} ${lastName}`.trim();
}