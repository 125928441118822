import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { PUBLIC_API_NOTIFICATIONS } from 'swag-client-common/redux/notification/notification.constants';
import { getToken } from '../stripe-form/stripe-form.actions';
import { ApiSettingsApi } from '../../api/api-settings.api';
import { paymentDetailsPublicApiFormSelector } from './api-settings.selectors';
const PREFIX = 'API_SETTINGS_';
export const ADD_REDIRECT_URL_SUCCESS = `${PREFIX}ADD_REDIRECT_URL_SUCCESS`;
export const ADD_REDIRECT_URL_ERROR = `${PREFIX}ADD_REDIRECT_URL_ERROR`;
export const addRedirectUrl = createAsyncAction({
  fn: ApiSettingsApi.createRedirectUrl,
  success: body => ({
    type: ADD_REDIRECT_URL_SUCCESS,
    payload: body
  }),
  error: errorAction(ADD_REDIRECT_URL_ERROR),
  postError: (dispatch, getState, error) => {
    dispatch(showNotification({
      text: error.message
    }));
  }
});
export const DELETE_REDIRECT_URL_SUCCESS = `${PREFIX}DELETE_REDIRECT_URL_SUCCESS`;
export const DELETE_REDIRECT_URL_ERROR = `${PREFIX}DELETE_REDIRECT_URL_ERROR`;
export const deleteRedirectUrl = createAsyncAction({
  fn: ApiSettingsApi.deleteRedirectUrl,
  success: body => ({
    type: DELETE_REDIRECT_URL_SUCCESS,
    payload: body
  }),
  error: errorAction(DELETE_REDIRECT_URL_ERROR),
  postError: (dispatch, getState, error) => {
    dispatch(showNotification({
      text: error.message
    }));
  }
});
export const GET_API_CREDENTIALS_REQUEST = `${PREFIX}GET_API_CREDENTIALS_REQUEST`;
export const GET_API_CREDENTIALS_SUCCESS = `${PREFIX}GET_API_CREDENTIALS_SUCCESS`;
export const GET_API_CREDENTIALS_ERROR = `${PREFIX}GET_API_CREDENTIALS_ERROR`;
export const getApiCredentials = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_API_CREDENTIALS_REQUEST
    });
  },
  fn: ApiSettingsApi.getApiCredentials,
  success: body => ({
    type: GET_API_CREDENTIALS_SUCCESS,
    payload: body
  }),
  error: errorAction(GET_API_CREDENTIALS_ERROR),
  postError: (dispatch, getState, error) => {
    dispatch(showNotification({
      text: error.message
    }));
  }
});
export const RESET_API_CREDENTIALS_REQUEST = `${PREFIX}RESET_API_CREDENTIALS_REQUEST`;
export const RESET_API_CREDENTIALS_SUCCESS = `${PREFIX}RESET_API_CREDENTIALS_SUCCESS`;
export const RESET_API_CREDENTIALS_ERROR = `${PREFIX}RESET_API_CREDENTIALS_ERROR`;
export const resetApiCredentials = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: RESET_API_CREDENTIALS_REQUEST
    });
  },
  fn: ApiSettingsApi.resetApiCredentials,
  success: body => ({
    type: RESET_API_CREDENTIALS_SUCCESS,
    payload: body
  }),
  error: errorAction(RESET_API_CREDENTIALS_ERROR),
  postError: (dispatch, getState, error) => {
    dispatch(showNotification({
      text: error.message
    }));
  },
  postSuccess: dispatch => {
    dispatch(showNotification({
      text: 'API credentials have been successfully reset.'
    }));
  }
});
export const ADD_CREDIT_CARD_SUCCESS = `${PREFIX}ADD_CREDIT_CARD_SUCCESS`;
export const ADD_CREDIT_CARD_ERROR = `${PREFIX}ADD_CREDIT_CARD_ERROR`;
export const addPublicApiCreditCard = createAsyncAction({
  fn: ApiSettingsApi.createCreditCard,
  success: body => ({
    type: ADD_CREDIT_CARD_SUCCESS,
    payload: body
  }),
  postSuccess: dispatch => {
    dispatch(showNotification({
      text: PUBLIC_API_NOTIFICATIONS.CREDIT_CARD_SAVED
    }));
  },
  error: errorAction(ADD_CREDIT_CARD_ERROR),
  postError: (dispatch, getState, error) => {
    dispatch(showNotification({
      text: error.message
    }));
  }
});
const sendRequest = (address, stripe, card, onTokenReceive) => dispatch => {
  dispatch(getToken(address, stripe, card, onTokenReceive));
};
export const addCreditCard = (stripe, card) => (dispatch, getState) => {
  const state = getState();
  const values = paymentDetailsPublicApiFormSelector(state);
  if (!values) {
    return;
  }
  dispatch(sendRequest(values, stripe, card, payload => dispatch(addPublicApiCreditCard({
    firstName: values.firstName,
    lastName: values.lastName,
    cardToken: payload[0]
  }))));
};
export const REMOVE_CREDIT_CARD_SUCCESS = `${PREFIX}REMOVE_CREDIT_CARD_SUCCESS`;
export const REMOVE_CREDIT_CARD_ERROR = `${PREFIX}REMOVE_CREDIT_CARD_ERROR`;
export const removePublicApiCreditCard = createAsyncAction({
  fn: ApiSettingsApi.deleteCreditCard,
  success: body => ({
    type: REMOVE_CREDIT_CARD_SUCCESS,
    payload: body
  }),
  postSuccess: dispatch => {
    dispatch(showNotification({
      text: PUBLIC_API_NOTIFICATIONS.CREDIT_CARD_DELETED
    }));
  },
  error: errorAction(REMOVE_CREDIT_CARD_ERROR),
  postError: (dispatch, getState, error) => {
    dispatch(showNotification({
      text: error.message
    }));
  }
});