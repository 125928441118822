function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { DesignUploadMode } from 'swag-common/interfaces';
import { OrderApi } from 'swag-client-common/api/order.api';
import { PRODUCTION_MOCKUP_STATUSES } from 'swag-common/constants/order-statuses.constants';
import { NOTECARD_REVIEW_PREVENT_CLOSE_POPUP } from 'swag-client-common/constants';
import { FRONT, PRINT_SIDE } from 'swag-common/constants/product.constants';
import { resetNotecardEditor, setNotecardEditorInitialValues } from '../notecard-editor/notecard-editor.actions';
import { isNotecardEditorChangedSelector, notecardEditorDataSelector } from '../notecard-editor/notecard-editor.selectors';
import { buildNotecard, getNotecardDesignData } from '../notecard-editor/notecard-editor.utils';
import { getMockupToReview } from './mockup-manager.utils';
import { currentStepSelector, itemIdSelector, mockupSelector, orderIdSelector } from './mockup-manages.selectors';
import { NOTECARD_STEPS } from './mockup-manager.constants';
const PREFIX = 'MOCKUP_MANAGER_';
export const USER_SKIPPED_BEFORE_APPROVING_MOCKUP = `${PREFIX}USER_SKIPPED_BEFORE_APPROVING_MOCKUP`;
export const skipBeforeApprovingMockup = () => ({
  type: USER_SKIPPED_BEFORE_APPROVING_MOCKUP
});
export const RESET_STATE = `${PREFIX}RESET_STATE`;
export const resetMockupManagerState = () => ({
  type: RESET_STATE
});
export const SET_MOCKUP_MANAGER_DATA = `${PREFIX}SET_MOCKUP_MANAGER_DATA`;
export const viewMockup = _ref => {
  let {
      productionMockups = [],
      notecardDesignData,
      logos
    } = _ref,
    rest = _objectWithoutProperties(_ref, ["productionMockups", "notecardDesignData", "logos"]);
  return dispatch => {
    const isNotecardReview = !!(notecardDesignData !== null && notecardDesignData !== void 0 && notecardDesignData.textContent) || !!(notecardDesignData !== null && notecardDesignData !== void 0 && notecardDesignData.color);
    if (isNotecardReview) {
      const sideLogos = (logos === null || logos === void 0 ? void 0 : logos[PRINT_SIDE[FRONT]]) || (logos === null || logos === void 0 ? void 0 : logos[DesignUploadMode.fullDesign]) || [];
      const logo = sideLogos[0] || null;
      dispatch(setNotecardEditorInitialValues({
        color: notecardDesignData.color,
        textContent: notecardDesignData.textContent,
        logo
      }));
    }
    dispatch(setMockupManagerData(_objectSpread({
      mockup: getMockupToReview(productionMockups),
      isNotecardReview
    }, rest)));
  };
};
export const setMockupManagerData = payload => {
  return _objectSpread({
    type: SET_MOCKUP_MANAGER_DATA
  }, payload);
};
export const EDIT_MOCKUP_CHANGE_REQUEST = `${PREFIX}EDIT_MOCKUP_CHANGE_REQUEST`;
export const editMockupChangeRequest = value => ({
  type: EDIT_MOCKUP_CHANGE_REQUEST,
  value
});
export const REQUEST_CHANGES = `${PREFIX}REQUEST_CHANGES`;
export const requestChanges = payload => ({
  type: REQUEST_CHANGES,
  payload
});
export const SET_IS_LOGO_UPLOAD_COMPLETED = `${PREFIX}SET_IS_LOGO_UPLOAD_COMPLETED`;
export const setIsLogoUploadCompleted = isLogoUploadCompleted => ({
  type: SET_IS_LOGO_UPLOAD_COMPLETED,
  payload: {
    isLogoUploadCompleted
  }
});
export const closeMockupManagerPopup = () => dispatch => {
  dispatch(hidePopup());
  dispatch(resetNotecardEditor());
  dispatch(resetMockupManagerState());
};
export const REQUESTING_CHANGES_MOCKUP_STARTED = `${PREFIX}REQUESTING_CHANGES_MOCKUP_STARTED`;
export const REQUESTING_CHANGES_ERROR = `${PREFIX}REQUESTING_CHANGES_MOCKUP_ERROR`;
export const REQUESTING_CHANGES_SUCCESS = `${PREFIX}REQUESTING_CHANGES_MOCKUP_SUCCESS`;
export const requestMockupChanges = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REQUESTING_CHANGES_MOCKUP_STARTED
    });
  },
  fn: OrderApi.reviewMockup,
  success: order => ({
    type: REQUESTING_CHANGES_SUCCESS,
    order
  }),
  error: errorAction(REQUESTING_CHANGES_ERROR),
  postSuccess: dispatch => {
    dispatch(closeMockupManagerPopup());
  }
});
export const APPROVING_MOCKUP_STARTED = `${PREFIX}APPROVING_MOCKUP_STARTED`;
export const APPROVING_ERROR = `${PREFIX}APPROVING_MOCKUP_ERROR`;
export const APPROVING_SUCCESS = `${PREFIX}APPROVING_MOCKUP_SUCCESS`;
export const approveMockup = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: APPROVING_MOCKUP_STARTED
    });
  },
  fn: OrderApi.reviewMockup,
  success: order => ({
    type: APPROVING_SUCCESS,
    order
  }),
  error: errorAction(APPROVING_ERROR),
  postSuccess: dispatch => {
    dispatch(closeMockupManagerPopup());
  }
});
export const submitReviewedNotecard = mockupContainer => async (dispatch, getState) => {
  try {
    dispatch({
      type: REQUESTING_CHANGES_MOCKUP_STARTED
    });
    const state = getState();
    const itemId = itemIdSelector(state);
    const orderId = orderIdSelector(state);
    const mockup = mockupSelector(state);
    const notecardEditorData = notecardEditorDataSelector(state);
    const defaultImage = await buildNotecard(mockupContainer);
    dispatch(requestMockupChanges({
      itemId,
      orderId,
      status: PRODUCTION_MOCKUP_STATUSES.DECLINED_BY_CUSTOMER,
      mockup: _objectSpread({}, mockup, {
        notecardDesignData: getNotecardDesignData(notecardEditorData, defaultImage)
      })
    }));
  } catch (e) {
    dispatch(errorAction(REQUESTING_CHANGES_ERROR)(e));
  }
};
export const handleMockupManagerClose = () => (dispatch, getState) => {
  const state = getState();
  const step = currentStepSelector(state);
  if (NOTECARD_STEPS.includes(step)) {
    const isNotecardEditorChanged = isNotecardEditorChangedSelector(state);
    if (isNotecardEditorChanged) {
      return dispatch(showPopup({
        popup: NOTECARD_REVIEW_PREVENT_CLOSE_POPUP
      }));
    }
  }
  dispatch(closeMockupManagerPopup());
};