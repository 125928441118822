function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { isItemBlankSampleOrPrintedSample } from 'swag-common/utils/items/is-item-sample.service';
import { isProceededItemSuperSpeed } from 'swag-common/utils/items/is-item-super-speed.util';
import { isItemInBox } from 'swag-common/utils/order/is-item-in-box.util';
import { Price } from 'swag-common/utils/price';
import { getBrands } from 'swag-common/utils/google-tag-manager/get-brands.util';
import { GOOGLE_TAG_MANAGER_EVENT } from '../../../common/constants/google-tag-manager';
import { getCategory } from './get-category.util';
import { getColor } from './get-color.util';
import { getParentCategory } from './get-parent-category.util';
export const getItemsForGoogleTagManager = (item, itemPositionInCart, categories, eventType) => {
  const product = item.prod;
  const gtmItems = item.variants.map(variant => {
    const quantity = variant.quantities.reduce((accum, quantity) => {
      return accum + quantity;
    }, 0);
    return _objectSpread({
      index: itemPositionInCart,
      item_id: (item === null || item === void 0 ? void 0 : item._id) || '',
      item_name: (product === null || product === void 0 ? void 0 : product.name) || '',
      item_brand: getBrands(product === null || product === void 0 ? void 0 : product.productAttributes),
      item_category: getParentCategory(product === null || product === void 0 ? void 0 : product.categories, categories),
      item_category2: getCategory(product === null || product === void 0 ? void 0 : product.categories, categories),
      item_variant: getColor(product === null || product === void 0 ? void 0 : product.variants, variant.variantIndex),
      price: Price.formatNumber(Price.getItemPricePerUnit(item)),
      quantity
    }, eventType !== GOOGLE_TAG_MANAGER_EVENT.ADD_TO_CART && {
      is_item_in_box: isItemInBox(item),
      box_item_id: item.boxItemsId || ''
    }, eventType === GOOGLE_TAG_MANAGER_EVENT.PURCHASE && {
      is_super_speed: isProceededItemSuperSpeed(item),
      is_sample: isItemBlankSampleOrPrintedSample(item),
      shipping_tier: getShippingTier(item)
    });
  });
  return gtmItems;
};
export const getShippingTier = ({
  deliveryTime,
  shipmentCharges = []
}) => {
  const shipmentChargeItem = shipmentCharges.find(shipmentCharge => shipmentCharge.maxDays === deliveryTime);
  return (shipmentChargeItem === null || shipmentChargeItem === void 0 ? void 0 : shipmentChargeItem.name) || '';
};
export const getItemsToSend = (items, categoriesMap, eventType) => items.reduce((accum, item, itemIndex) => {
  accum.push(...getItemsForGoogleTagManager(item, itemIndex, Object.values(categoriesMap), eventType));
  return accum;
}, []);
export const getValue = items => Price.formatNumber(Price.getTotal(items));