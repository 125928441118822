import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { UtilApi } from '../../api/util.api';
const PREFIX = 'PAST_WORK_';
export const GET_PAST_WORK_IMAGES_SUCCESS = `${PREFIX}GET_IMAGES_SUCCESS`;
export const GET_PAST_WORK_IMAGES_FAIL = `${PREFIX}GET_IMAGES_FAIL`;
export const GET_PAST_WORK_IMAGES_STARTED = `${PREFIX}GET_IMAGES_STARTED`;
export const getPastWorkImages = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_PAST_WORK_IMAGES_STARTED
    });
  },
  fn: UtilApi.getImages,
  success: images => {
    return {
      type: GET_PAST_WORK_IMAGES_SUCCESS,
      payload: {
        images
      }
    };
  },
  error: err => ({
    type: GET_PAST_WORK_IMAGES_FAIL,
    err,
    error: err
  })
});
export const SET_PAST_WORK_CURRENT_PAGE = `${PREFIX}SET_CURRENT_PAGE`;
export const setCurrentPage = ({
  page
}) => ({
  type: SET_PAST_WORK_CURRENT_PAGE,
  payload: {
    page
  }
});