function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import nearestColor from 'nearest-color';
import colorConvert from 'color-convert';
import { aPMS, aRGB, colorFamilies } from './colors.constants';
const getColors = str => str.split(',');
function suggestClosestPMS(hexColors, {
  addCSuffix
} = {
  addCSuffix: false
}) {
  const pantones = hexColors.map(removeHashSymbol).map(closestPMSColorMatchingByDistance).map(getComplexColorInfoByPMS);
  if (addCSuffix) {
    return pantones.map(addCSuffixToPantoneData);
  }
  return pantones;
}
function addCSuffixToPantoneData(pantoneData) {
  const originalName = pantoneData.pms;
  return _objectSpread({}, pantoneData, {
    pms: `${originalName} c`
  });
}
const removeHashSymbol = str => {
  if (typeof str === 'string' && str[0] === '#') {
    return str.slice(1);
  }
  return str;
};
const addHashSymbol = str => {
  if (typeof str === 'string' && str[0] === '#') {
    return str;
  }
  return `#${str}`;
};
const getComplexColorInfoByPMS = pms => ({
  id: genId(),
  pms,
  hex: addHashSymbol(PMS2HEX(pms))
});

/**
 * Generate custom id
 * Math.random should be unique because of its seeding algorithm.
 *
 * @return {string}
 */
const genId = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

/**
 * Utils function to get rgb values from hex
 *
 * @param hex {String}
 * @return {r,g,b}
 */
const HEX2RGBA = hex => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
    a: 1
  } : null;
};
const RGBAStringToRGBAObject = (string = '') => {
  const keys = ['r', 'g', 'b', 'a'];
  const values = string.replace(/[^\d,]/g, '').split(',');
  return keys.reduce((set, key, index) => {
    return _objectSpread({}, set, {
      [key]: values[index] || 0
    });
  }, {});
};
const RGBAObjectToRGBString = ({
  r,
  g,
  b
}) => `${r},${g},${b}`;
const hexToRgbaString = (hexColor, alpha) => {
  const rgb = HEX2RGBA(hexColor);
  if (!rgb) {
    return null;
  }
  return `rgba(${rgb.r},${rgb.g},${rgb.b}, ${alpha})`;
};
const pantoneNumberRegexp = /^(.+[^\s])(\s?[Cc]{1})$/;
function normalizePantoneName(pms) {
  const lowerCasedPms = pms.toLowerCase();
  const unformattedPantoneFound = aPMS.indexOf(lowerCasedPms) > -1;
  if (unformattedPantoneFound) {
    return lowerCasedPms;
  }
  const pantoneNumberExtracted = pantoneNumberRegexp.exec(lowerCasedPms);
  const pantoneName = pantoneNumberExtracted && pantoneNumberExtracted[1];
  return pantoneName;
}
function findPantoneIndex(pms) {
  const normalizedPms = normalizePantoneName(pms);
  return aPMS.indexOf(normalizedPms);
}
function PMS2HEX(pms = '') {
  const pantoneIndex = findPantoneIndex(pms);
  if (pantoneIndex >= 0) {
    return aRGB[pantoneIndex];
  }
  return '';
}
function RGB2PMS(rgb) {
  const i = aRGB.indexOf(rgb);
  if (i >= 0) {
    return aPMS[i];
  }
  return '';
}
function RGB2HEX({
  r,
  g,
  b
}) {
  // Ensure values are within the 0-255 range
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  // Convert to hex and format as 2-digit hex numbers with padding
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}
const closestPMSColorMatchingByDistance = rgb => {
  let pantoneColorNotFound = true;
  let pantoneColor;
  let iteration = 0;
  let distance = 0;
  while (pantoneColorNotFound && iteration < 1000) {
    pantoneColor = PMSColorMatching(rgb, distance)[0];
    pantoneColorNotFound = !pantoneColor;
    distance += 1;
    iteration++;
  }
  return pantoneColor;
};
function PMSColorMatching(rgb, distance) {
  if (!distance) {
    distance = 16;
  }
  const a = [];
  const aDis = [];
  // exact pms
  const m = RGB2PMS(rgb);
  if (m !== '') {
    // @todo. auto generated. fix this error
    // @ts-ignore
    a[0] = m;
  }
  // search near color
  const r = parseInt(rgb.substr(0, 2), 16);
  const g = parseInt(rgb.substr(2, 2), 16);
  const b = parseInt(rgb.substr(4, 2), 16);
  for (let i = 0; i < aRGB.length; i++) {
    const rgb1 = aRGB[i];
    const r1 = parseInt(rgb1.substr(0, 2), 16);
    const g1 = parseInt(rgb1.substr(2, 2), 16);
    const b1 = parseInt(rgb1.substr(4, 2), 16);
    // 3D distance
    // @todo. auto generated. fix this error
    // @ts-ignore
    aDis[i] = Math.sqrt(Math.pow(r - r1, 2) + Math.pow(g - g1, 2) + Math.pow(b - b1, 2));
  }
  for (let i = 0; i < aDis.length; i++) {
    if (aDis[i] <= distance) {
      // @todo. auto generated. fix this error
      // @ts-ignore
      if (a.indexOf(aPMS[i]) === -1) {
        // @todo. auto generated. fix this error
        // @ts-ignore
        a.push(aPMS[i]);
      }
    }
  }
  return a;
}
function getPantoneColor(hex) {
  const colors = suggestClosestPMS([hex], {
    addCSuffix: true
  });
  return colors[0];
}
function getUniqueColorsNumberForSide(logos, texts, side) {
  const logosPerSide = logos[side];
  const textsPerSide = texts ? Object.values(texts).filter(text => text.location === side) : [];
  const setOfColors = new Set();
  logosPerSide.reduce((colorsSet, logo) => {
    var _logo$colors;
    logo === null || logo === void 0 ? void 0 : (_logo$colors = logo.colors) === null || _logo$colors === void 0 ? void 0 : _logo$colors.colorsToPrint.forEach(color => {
      colorsSet.add(color);
    });
    return colorsSet;
  }, setOfColors);
  textsPerSide.reduce((colorsSet, text) => {
    const color = getPantoneColor(text.color);
    colorsSet.add(color.pms);
    return colorsSet;
  }, setOfColors);
  return setOfColors.size;
}
export function getUniqueColorsNumberForAllSides(logos = {}, texts) {
  const sides = Object.keys(logos);
  const colorNumbersPerSides = {};
  for (let i = 0; i < sides.length; i++) {
    const currentSide = sides[i];
    colorNumbersPerSides[currentSide] = getUniqueColorsNumberForSide(logos, texts, currentSide);
  }
  return colorNumbersPerSides;
}
function getAmountOfUniqueColorsForAllSides(logos, texts) {
  const colors = getUniqueColorsNumberForAllSides(logos, texts);
  return Object.values(colors).reduce((colorsSum, number) => number + colorsSum, 0);
}
const colorFamiliesHEXMap = colorFamilies.reduce((acc, colorFamily) => {
  acc[colorFamily] = '#' + colorConvert.keyword.hex(colorFamily);
  return acc;
}, {});
function findColorFamilyLib(hexColor) {
  let searchedColorFamily;
  if (findColorFamilyLib[hexColor]) {
    return findColorFamilyLib[hexColor];
  }
  const nearestColorData = nearestColor.from(colorFamiliesHEXMap)(hexColor);

  // eslint-disable-next-line prefer-const
  searchedColorFamily = nearestColorData.name;

  // Cache result
  findColorFamilyLib[hexColor] = searchedColorFamily;
  return searchedColorFamily;
}
const getBrightnessFromHexColor = hexColor => {
  const RED_BRIGHTNESS_RATE = 299;
  const GREEN_BRIGHTNESS_RATE = 587;
  const BLUE_BRIGHTNESS_RATE = 114;
  const rgbaColor = HEX2RGBA(hexColor);
  if (!rgbaColor) {
    return 0;
  }
  const {
    r,
    g,
    b
  } = rgbaColor;
  return (r * RED_BRIGHTNESS_RATE + g * GREEN_BRIGHTNESS_RATE + b * BLUE_BRIGHTNESS_RATE) / 1000;
};
export { PMS2HEX, normalizePantoneName, HEX2RGBA, hexToRgbaString, RGB2HEX, getColors, suggestClosestPMS, getComplexColorInfoByPMS, removeHashSymbol, getUniqueColorsNumberForSide, getAmountOfUniqueColorsForAllSides, RGBAStringToRGBAObject, RGBAObjectToRGBString, findColorFamilyLib, getBrightnessFromHexColor };