import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/page-management';
export const UtilApi = createApi({
  masksCount: {
    url: `${baseUrl}/get-donated`,
    method: 'get'
  },
  getImages: {
    url: `${baseUrl}/get-past-work-images`,
    method: 'get'
  },
  coreProductsCount: {
    url: `${baseUrl}/get-core-products-count`,
    method: 'get'
  }
});