export const LOGO_HANDLING_STATUSES = {
  UPLOADING_IMAGE: 'uploading-image',
  SPLITTING_IMAGE_TO_PIXELS: 'splitting-to-pixels',
  COUNTING_COLORS: 'counting-colors',
  EXTRACTING_MEANINGFUL_COLORS: 'extracting-meaningful',
  ANALYZING_SHADOW_COLORS: 'analyzing-shadow-colors',
  FILTERING_MINOR_COLORS: 'filtering-minor-colors',
  PREPARING_PREVIEW: 'preparing-preview',
  LOADING: 'loading',
  SUCCESS: 'success',
  FINISHED: 'finished',
  CLOSE: 'close'
};
export const COLORS_DETECTION_ERRORS = {
  TOO_MANY_COLORS: 'too-many-colors'
};