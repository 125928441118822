function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CLIENT_LOGIN_STARTED, CLIENT_LOGIN_ERROR, CLIENT_LOGIN_SUCCESS, CLIENT_LOGIN_REMOVE_NOTIFICATION } from 'swag-client-common/redux/user/user.actions';
import { RESET_PASSWORD_STARTED, RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS } from 'swag-client-common/redux/user/user-additional.actions';
import { STATE_IDLE, STATE_LOADING, STATE_ERROR, STATE_SUCCESS } from '../redux.constants';
const initialState = {
  state: STATE_IDLE,
  error: '',
  passwordResetErrorMessage: '',
  passwordResetState: STATE_IDLE,
  errorCode: null
};
export default function (state = initialState, action) {
  switch (action.type) {
    case CLIENT_LOGIN_STARTED:
      {
        return _objectSpread({}, state, {
          state: STATE_LOADING,
          error: '',
          errorCode: null
        });
      }
    case CLIENT_LOGIN_ERROR:
      {
        return _objectSpread({}, state, {
          state: STATE_ERROR,
          error: action.error.message,
          errorCode: action.error.code
        });
      }
    case CLIENT_LOGIN_SUCCESS:
      {
        return _objectSpread({}, state, {
          state: STATE_SUCCESS,
          error: '',
          errorCode: null
        });
      }
    case CLIENT_LOGIN_REMOVE_NOTIFICATION:
      {
        return initialState;
      }
    case RESET_PASSWORD_STARTED:
      return _objectSpread({}, state, {
        passwordResetState: STATE_LOADING,
        passwordResetErrorMessage: '',
        errorCode: null
      });
    case RESET_PASSWORD_SUCCESS:
      return _objectSpread({}, state, {
        passwordResetState: STATE_SUCCESS,
        passwordResetErrorMessage: '',
        errorCode: null
      });
    case RESET_PASSWORD_ERROR:
      return _objectSpread({}, state, {
        passwordResetState: STATE_ERROR,
        passwordResetErrorMessage: action.error.message,
        errorCode: null
      });
    default:
      {
        return state;
      }
  }
}