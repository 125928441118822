import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { getSocials } from 'swag-client-common/utils/social-links.utils';
import { isPreBuiltLimitedCartOpenedByCustomerSelector } from 'swag-client-common/redux/cart/cart.selectors';
import { isResellerInOrderCreationModeSelector } from 'swag-client-common/redux/user/user.selectors';
import { WEEK_DAYS } from 'swag-common/constants/days.constant';
const getScheduler = hoursOperations => {
  if (!(hoursOperations !== null && hoursOperations !== void 0 && hoursOperations.some(({
    from,
    to
  }) => typeof from === 'number' && typeof to === 'number'))) {
    return null;
  }
  return transformSchedule(hoursOperations);
};
export const footerDataSelector = state => {
  const {
    swag: {
      storeSettings
    }
  } = window;
  const isCustomerOpenPreBuiltLimitedCart = isPreBuiltLimitedCartOpenedByCustomerSelector(state);
  const isResellerInOrderCreationMode = isResellerInOrderCreationModeSelector(state);
  let logoLinkUrl = APP_ROUTES.ALL_SWAG;
  if (isCustomerOpenPreBuiltLimitedCart || isResellerInOrderCreationMode) {
    logoLinkUrl = APP_ROUTES.CART;
  } else if (storeSettings !== null && storeSettings !== void 0 && storeSettings.logoLinkUrl) {
    logoLinkUrl = storeSettings.logoLinkUrl;
  } else if (storeSettings !== null && storeSettings !== void 0 && storeSettings.isHomePageActive) {
    logoLinkUrl = APP_ROUTES.HOME;
  }
  return {
    secondaryColor: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.secondaryColor,
    primaryColor: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.primaryColor,
    email: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.companyEmail,
    companyName: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.companyName,
    scheduler: getScheduler(storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.hoursOperations),
    phone: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.companyPhone,
    socials: getSocials(storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.socials),
    logo: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.logoUrl,
    logoLinkUrl,
    timezone: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.timezone,
    showPhone: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.companyShowPhone,
    showEmail: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.companyShowEmail
  };
};
function transformSchedule(hoursOperations) {
  return WEEK_DAYS.map(day => {
    const daySchedule = hoursOperations === null || hoursOperations === void 0 ? void 0 : hoursOperations.find(schedule => schedule.day === day);
    return {
      day,
      from: (daySchedule === null || daySchedule === void 0 ? void 0 : daySchedule.from) !== undefined ? transformTime(daySchedule.from) : null,
      to: (daySchedule === null || daySchedule === void 0 ? void 0 : daySchedule.to) !== undefined ? transformTime(daySchedule.to) : null
    };
  });
}
function transformTime(time) {
  const amOrPm = time >= 12 ? 'pm' : 'am';
  const hours = time % 12 || 12;
  return `${hours}:00${amOrPm}`;
}