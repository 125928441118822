import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { PreBuiltCartAction, ResellerAdminMode } from 'swag-common/constants/reseller.constants';
import { logoutAsUser } from 'swag-client-common/redux/user/user.actions';
import { removeOriginalUserRole } from 'swag-client-common/utils/local-storage.util';
import { SpaceStyledConfirmPopup } from 'swag-client-common/components/popups/space-styled-confirm-popup';
import { isResellerAdminModeExitPopup } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { resellerPreBuiltCartSave } from 'swag-client-common/redux/cart/cart.actions';
import { checkIsResellerAdminMode } from 'swag-common/utils/reseller/check-is-reseller-admin-mode.util';
const ownPropsSelector = (state, props) => props;
const titleSelector = createSelector(ownPropsSelector, ({
  resellerAdminMode
}) => {
  if (resellerAdminMode === ResellerAdminMode.CreatingCart) {
    return 'Building a cart';
  }
  if (resellerAdminMode === ResellerAdminMode.EditingCart) {
    return 'Editing a cart';
  }
  if (resellerAdminMode === ResellerAdminMode.CreatingPresentation) {
    return 'Creating presentation';
  }
  if (resellerAdminMode === ResellerAdminMode.EditingPresentation) {
    return 'Editing presentation';
  }
  if (resellerAdminMode === ResellerAdminMode.CreatingInvoice) {
    return 'Creating invoice';
  }
  if (resellerAdminMode === ResellerAdminMode.EditingInvoice) {
    return 'Editing invoice';
  }
  if (resellerAdminMode === ResellerAdminMode.OrderCreation) {
    return 'Creating order';
  }
});
const textSelector = createSelector(ownPropsSelector, ({
  resellerAdminMode
}) => {
  if ([ResellerAdminMode.CreatingCart, ResellerAdminMode.CreatingPresentation, ResellerAdminMode.CreatingInvoice, ResellerAdminMode.OrderCreation].includes(resellerAdminMode)) {
    return 'Are you sure you want to exit? All your progress will be lost if you proceed.';
  }
  if (resellerAdminMode === ResellerAdminMode.EditingCart) {
    return 'Are you sure you want to exit? The edits made to the cart will be saved and you will be redirected back to the dashboard.';
  }
  if (resellerAdminMode === ResellerAdminMode.EditingPresentation) {
    return 'Are you sure you want to exit? The edits made to the presentation will be saved and you will be redirected back to the dashboard.';
  }
  if (resellerAdminMode === ResellerAdminMode.EditingInvoice) {
    return 'Are you sure you want to exit? The edits made to the invoice will be saved and you will be redirected back to the dashboard.';
  }
});
const confirmBtnTextSelector = createSelector(ownPropsSelector, ({
  resellerAdminMode
}) => {
  if ([ResellerAdminMode.CreatingCart, ResellerAdminMode.CreatingPresentation].includes(resellerAdminMode)) {
    return 'Exit';
  }
  if ([ResellerAdminMode.EditingCart, ResellerAdminMode.EditingPresentation].includes(resellerAdminMode)) {
    return 'Save & Exit';
  }
});
const mapStateToProps = createStructuredSelector({
  open: isResellerAdminModeExitPopup,
  title: titleSelector,
  text: textSelector,
  confirmBtnTextSelector: confirmBtnTextSelector
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onConfirmClick: () => {
    const {
      resellerAdminMode
    } = ownProps;
    if (resellerAdminMode === ResellerAdminMode.Browse) {
      removeOriginalUserRole();
      dispatch(logoutAsUser());
    }
    if (checkIsResellerAdminMode(resellerAdminMode)) {
      dispatch(resellerPreBuiltCartSave({
        action: PreBuiltCartAction.Exit
      }));
    }
  },
  onCloseClick: () => {
    dispatch(hidePopup());
  }
});
export const ConfirmExitResellerModePopup = connect(mapStateToProps, mapDispatchToProps)(SpaceStyledConfirmPopup);