import _isValid from "redux-form/es/isValid";
import { createSelector } from 'reselect';
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
import { PAYMENT_DETAILS_PUBLIC_API } from 'swag-client-common/constants';
export const rootSelector = state => state.apiSettings;
export const clientIdSelector = createSelector(rootSelector, state => state.clientId);
export const clientSecretSelector = createSelector(rootSelector, state => state.clientSecret);
export const redirectUrlsSelector = createSelector(rootSelector, state => state.redirectUrls);
export const isLoadingSelector = createSelector(rootSelector, state => state.isLoading);
export const creditCardSelector = createSelector(rootSelector, state => state.creditCard);
export const isCreditCardExistSelector = createSelector(creditCardSelector, Boolean);
export const isCreditCardInfoValid = state => _isValid(PAYMENT_DETAILS_PUBLIC_API)(state);
export const paymentDetailsPublicApiFormSelector = createSelector(state => state.form[PAYMENT_DETAILS_PUBLIC_API], form => form ? form.values : {});
export const initialCreditCardValuesSelector = createSelector(creditCardSelector, card => {
  if (card) {
    const {
      name,
      country,
      address,
      city,
      expireMonth,
      expireYear,
      last4,
      state: countryState = '',
      zip,
      lastName,
      firstName
    } = card;
    const [street, unit] = address.split(',');
    return {
      city,
      unit: unit ? unit.trim() : '',
      state: countryState ? countryState.trim() : '',
      street: street ? street.trim() : '',
      country,
      lastName,
      firstName,
      cvc: '***',
      holder: name,
      zipcode: zip,
      number: `**** **** **** ${last4}`,
      expDate: `${expireMonth} / ${getExpireDate(expireYear)}`
    };
  }
  return {
    country: COUNTRY_CODES.US,
    state: ''
  };
});
const getExpireDate = date => +String(date).slice(-2);