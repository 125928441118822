import { createSelector } from 'reselect';
import { USER_ROLES } from 'swag-common/constants/user-roles';
export const userSelector = state => state.user;
export const isCustomStoreAdminSelector = createSelector(userSelector, user => user.role === USER_ROLES.ENTERPRISE_ADMIN);
export const isLoggedInSelector = createSelector(userSelector, user => user.isLoggedIn);
export const profileFetchedSelector = createSelector(userSelector, user => user.profileFetched);
export const isApiAccessEnabledSelector = createSelector(userSelector, user => user.publicApiSettings.isApiAccessEnabled);
export const userEmailSelector = createSelector(userSelector, user => user.email);
export const userIdSelector = createSelector(userSelector, user => user._id);
export const userRoleSelector = createSelector(userSelector, user => user.role);
export const isLoadingSelector = createSelector(userSelector, user => user.isLoading);
export const userCompanyEmailDomainSelector = createSelector(userSelector, user => user.companyEmailDomain);
export const isCompanyOrdersAvailableSelector = createSelector(userSelector, user => Boolean(user.isCompanyOrdersAvailable));
export const isOrderApprovalFlowSelector = createSelector(userSelector, user => Boolean(user.orderApprovalFlow));
export const userCompanyDepartments = createSelector(userSelector, user => user.companyDepartments);
export const isUserCompanySupervisorSelector = createSelector(userSelector, user => user.role === USER_ROLES.COMPANY_SUPERVISOR);
export const userWarehouseLocationSelector = createSelector(userSelector, user => user.warehouseLocation);
export const isApiPaymentMethodCardSelector = createSelector(userSelector, user => user.publicApiSettings.apiPaymentMethod === 'creditCard');
export const isPasswordNotSetSelector = createSelector(userSelector, user => user.passwordNotSet);