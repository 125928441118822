import _intersection from "lodash/intersection";
import _flatten from "lodash/flatten";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import checkIsReceiverDomestic from 'swag-common/utils/shipout/check-is-receiver-domestic';
import checkIsReceiverInternational from 'swag-common/utils/shipout/check-is-receiver-international';
import { FlatRatesTypes } from 'swag-common/constants/shipping.constants';
export function getPossibleDeliveriesList(receivers, getDeliveryCodesFunction, getPossibleDeliveries, getDeliveryServiceCode) {
  const domesticReceivers = receivers.filter(checkIsReceiverDomestic);
  const internationalReceivers = receivers.filter(checkIsReceiverInternational);
  const domesticDeliveryCodesByReceivers = domesticReceivers.map(getDeliveryCodesFunction).filter(Boolean);
  const internationalDeliveryCodesByReceivers = internationalReceivers.map(getDeliveryCodesFunction).filter(Boolean);
  const hasDomesticShipments = Boolean(domesticDeliveryCodesByReceivers.length);
  const hasInternationalShipments = Boolean(internationalDeliveryCodesByReceivers.length);
  const hasOnlyDomesticShipments = hasDomesticShipments && !hasInternationalShipments;
  const hasOnlyInternationalShipments = hasInternationalShipments && !hasDomesticShipments;
  if (hasOnlyDomesticShipments || hasOnlyInternationalShipments) {
    const receivers = hasOnlyDomesticShipments ? domesticReceivers : internationalReceivers;
    const deliveryReceiversCodes = hasOnlyDomesticShipments ? domesticDeliveryCodesByReceivers : internationalDeliveryCodesByReceivers;
    const deliveryUsageMap = _flatten(deliveryReceiversCodes).reduce(buildUsageMap, {});
    const mostUsedCodes = Object.keys(deliveryUsageMap).filter(moreThanHalfOfReceiversHasServiceCode(receivers, deliveryUsageMap));
    const deliveriesMap = mostUsedCodes.reduce(prepareMainInfoAboutDeliveries(receivers, getPossibleDeliveries, 0), {});
    return receivers.reduce(countShippingPriceForPossibleDeliveries(mostUsedCodes, getPossibleDeliveries, getDeliveryServiceCode), deliveriesMap);
  }
  const domesticCommonDeliveryCodes = _intersection(...domesticDeliveryCodesByReceivers);
  const deliveryCodes = addFlatRateOptionIfNeeded(domesticCommonDeliveryCodes, receivers, getDeliveryServiceCode);
  const internationalShippingPrice = internationalReceivers.reduce((acc, cur) => {
    var _getPossibleDeliverie;
    return acc + (((_getPossibleDeliverie = getPossibleDeliveries(cur)[getDeliveryServiceCode(cur)]) === null || _getPossibleDeliverie === void 0 ? void 0 : _getPossibleDeliverie.shippingPrice) || 0);
  }, 0);
  const deliveriesMap = deliveryCodes.reduce(prepareMainInfoAboutDeliveries(receivers, getPossibleDeliveries, internationalShippingPrice), {});
  return domesticReceivers.reduce(countShippingPriceForPossibleDeliveries(deliveryCodes, getPossibleDeliveries, getDeliveryServiceCode), deliveriesMap);
}
const buildUsageMap = (usageMap, cur) => {
  if (Object.prototype.hasOwnProperty.call(usageMap, cur)) {
    usageMap[cur] += 1;
  } else {
    usageMap[cur] = 1;
  }
  return usageMap;
};
const addFlatRateOptionIfNeeded = (deliveryCodes, receivers, getDeliveryServiceCode) => {
  const domesticFlatRateReceiver = receivers.find(r => getDeliveryServiceCode(r) === FlatRatesTypes.Domestic);
  const internationalFlatRateReceiver = receivers.find(r => getDeliveryServiceCode(r) === FlatRatesTypes.International);
  if (!deliveryCodes.includes(FlatRatesTypes.Domestic) && domesticFlatRateReceiver) {
    return [FlatRatesTypes.Domestic, ...deliveryCodes];
  }
  if (!deliveryCodes.includes(FlatRatesTypes.International) && !deliveryCodes.includes(FlatRatesTypes.Domestic) && internationalFlatRateReceiver) {
    return [FlatRatesTypes.International, ...deliveryCodes];
  }
  return deliveryCodes;
};
const moreThanHalfOfReceiversHasServiceCode = (receivers, deliveryUsageMap) => k => {
  return deliveryUsageMap[k] >= receivers.length / 2;
};
const prepareMainInfoAboutDeliveries = (receivers, getPossibleDeliveries, initialValue) => (res, cur) => {
  const receiver = receivers.find(r => Object.keys(getPossibleDeliveries(r)).includes(cur));
  const deliveries = getPossibleDeliveries(receiver);
  res[cur] = _objectSpread({}, deliveries[cur], {
    shippingPrice: initialValue
  });
  return res;
};
const countShippingPriceForPossibleDeliveries = (mostUsedCodes, getPossibleDeliveries, getDeliveryServiceCode) => (acc, cur) => {
  mostUsedCodes.forEach(code => {
    if (acc[code]) {
      const currentByCode = getPossibleDeliveries(cur)[code];
      const currentBySelector = getPossibleDeliveries(cur)[getDeliveryServiceCode(cur)];
      const delivery = currentByCode || currentBySelector;
      acc[code].shippingPrice += delivery ? delivery.shippingPrice : 0;
    }
  });
  if (acc[FlatRatesTypes.International]) {
    const deliveryWithTheSamePrice = Object.keys(acc).filter(key => key !== FlatRatesTypes.International && acc[key].shippingPrice === acc[FlatRatesTypes.International].shippingPrice);
    if (deliveryWithTheSamePrice.length) {
      deliveryWithTheSamePrice.forEach(key => {
        delete acc[key];
      });
    }
  }
  return acc;
};