import { checkIsProductSampleBox, checkIsProductColoredeBox, checkIsProductCustomBox, checkIsProductFullyCustomBox, checkIsProductBrownBox } from 'swag-common/utils/product/product.utils';
export function isItemSampleBox(item) {
  return item.prod && checkIsProductSampleBox(item.prod);
}
export function isItemColoredBox(item) {
  return item.prod && checkIsProductColoredeBox(item.prod);
}
export function isItemCustomBox(item) {
  return item.prod && checkIsProductCustomBox(item.prod);
}
export function isItemFullyCustomBox(item) {
  return item.prod && checkIsProductFullyCustomBox(item.prod);
}
export function isItemBrownBox(item) {
  return item.prod && checkIsProductBrownBox(item.prod);
}