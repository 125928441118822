import { suggestClosestPMS } from 'swag-common/utils/colors';
import { SOCKET_EVENTS } from 'swag-common/constants/socket-events';
import { BC_ECOMMERCE, isBroadcastChannelSupported } from 'swag-client-common/shared-worker/broadcast-chanel';
import { port } from 'swag-client-common/shared-worker/ecommerce/ecommerce-worker-runner';
import { BCEvents } from 'swag-client-common/shared-worker/shared-worker.events';
import { COLORS_DETECTION_ERRORS } from '../redux/logo-editing/logo-editing.constants';
const MINIMUM_TIME_TO_UPDATE_STATUS = 500;
export function detectColors({
  filePath,
  filterPhotos,
  onProgress,
  onError,
  onSuccess
}) {
  const queue = [];
  let completed = false;
  const interval = setInterval(() => {
    const queueElement = queue.shift();
    if (queueElement) {
      queueElement();
    }
    if (queue.length === 0 && completed) {
      clearInterval(interval);
    }
  }, MINIMUM_TIME_TO_UPDATE_STATUS);
  const setupColorDetection = () => {
    port.postMessage({
      type: SOCKET_EVENTS.DETECT_COLORS,
      payload: filePath
    });
    BC_ECOMMERCE.addEventListener(BCEvents.MESSAGE, event => {
      const {
        type,
        payload
      } = isBroadcastChannelSupported ? event.data : event;
      switch (type) {
        case SOCKET_EVENTS.DETECT_COLORS_PROGRESS:
          {
            const {
              status,
              progress
            } = payload;
            if (completed) {
              break;
            }
            queue.push(onProgress.bind(null, {
              status,
              progress
            }));
            break;
          }
        case SOCKET_EVENTS.DETECT_COLORS_COMPLETED:
          {
            const {
              hexColors,
              hasBackground,
              preview,
              previewWOBackground
            } = payload;
            const detectedColors = suggestClosestPMS(hexColors, {
              addCSuffix: true
            });
            queue.push(() => onSuccess({
              detectedColors,
              hasBackground,
              preview,
              previewWOBackground
            }));
            completed = true;
            break;
          }
        case SOCKET_EVENTS.DETECT_COLORS_MANY_COLORS:
          {
            if (filterPhotos) {
              onError({
                error: COLORS_DETECTION_ERRORS.TOO_MANY_COLORS
              });
            }
            break;
          }
        case SOCKET_EVENTS.DETECT_COLORS_ERROR:
          {
            onError({
              error: payload.error
            });
            break;
          }
        default:
          {
            break;
          }
      }
    });
  };
  setupColorDetection();
}