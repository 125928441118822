import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isResellerContractPendingSignWidgetShownSelector } from 'swag-client-common/redux/tenant-store/tenant-store.selectors';
import { isUserResellerCompanyOwnerSelector } from 'swag-client-common/redux/user/user.selectors';
import { ResellerContractPendingSignWidgetComponent } from 'swag-client-common/components/reseller-contract-pending-sign-widget/reseller-contract-pending-sign-widget';
import { signResellerContract } from 'swag-client-common/redux/user/user.actions';
const mapStateToProps = createStructuredSelector({
  isWidgetShown: isResellerContractPendingSignWidgetShownSelector,
  isResellerOwner: isUserResellerCompanyOwnerSelector
});
const mapDispatchToProps = dispatch => ({
  acceptResellerContract: () => {
    dispatch(signResellerContract());
  }
});
export const ResellerContractPendingSignWidget = connect(mapStateToProps, mapDispatchToProps)(ResellerContractPendingSignWidgetComponent);