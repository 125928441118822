function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export const getFirstLogoIdSelector = logos => {
  const allLogos = Object.keys(logos).reduce((acc, locationName) => [...logos[locationName], ...acc], []);
  return allLogos[0].id;
};
export const getListOfLogosIds = logos => Object.keys(logos).reduce((allLogos, locationName) => [...logos[locationName], ...allLogos], []).map(({
  id
}) => id);
export const normalizeLogos = logos => Object.keys(logos).reduce((allLogos, locationName) => {
  const locationLogos = logos[locationName].reduce((acc, logo) => {
    const {
      id,
      preview,
      location,
      colors: {
        colorsToPrint
      }
    } = logo;
    return _objectSpread({}, acc, {
      [logo.id]: {
        colorsToPrint,
        id,
        preview,
        location
      }
    });
  }, {});
  return _objectSpread({}, allLogos, locationLogos);
}, {});
export const updateLogosWithColors = (oldLogos, logosColorsInfo) => Object.keys(oldLogos).reduce((updatedLogos, location) => {
  const singleLocationLogos = oldLogos[location].map(logo => {
    const {
      colorsToPrint
    } = logosColorsInfo[logo.id];
    return _objectSpread({}, logo, {
      colors: _objectSpread({}, logo.colors, {
        colorsNumber: colorsToPrint.length,
        colorsToPrint,
        isConfirmedByCustomer: true
      })
    });
  });
  return _objectSpread({}, updatedLogos, {
    [location]: singleLocationLogos
  });
}, {});