function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { EDIT_FORM_FIELD, STRIPE_FORM_SUBMIT_STARTED, STRIPE_FORM_SUBMIT_ERROR, RESET_CARD_INFO, CARD_INFO_VALIDATED, SET_STRIPE_FIELD_VALIDATION_ERROR, SET_STRIPE_FIELD_VALIDATION_EMPTY, SET_STRIPE_FIELD_VALIDATION_COMPLETED } from './stripe-form.actions';
const initialState = {
  error: undefined,
  stripeLoaded: false,
  stripeLoadedError: false,
  cardInfo: {
    number: '',
    cvc: '',
    expDate: ''
  },
  invalidInputs: [],
  cardValidationError: {
    number: null,
    expiry: null,
    cvc: null
  },
  cardValidationEmpty: {
    number: true,
    expiry: true,
    cvc: true
  },
  cardValidationCompleted: {
    number: false,
    expiry: false,
    cvc: false
  }
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case EDIT_FORM_FIELD:
      {
        const {
          invalidInputs
        } = state;
        const newInvalidInputs = [...invalidInputs];
        const invalidFieldIndex = invalidInputs.indexOf(action.field);
        if (invalidFieldIndex > -1) {
          newInvalidInputs.splice(invalidFieldIndex, 1);
        }
        return _objectSpread({}, state, {
          invalidInputs: newInvalidInputs,
          cardInfo: _objectSpread({}, state.cardInfo, {
            [action.field]: action.value
          })
        });
      }
    case CARD_INFO_VALIDATED:
      {
        const {
          invalidInputs
        } = action;
        return _objectSpread({}, state, {
          invalidInputs
        });
      }
    case STRIPE_FORM_SUBMIT_STARTED:
      {
        return _objectSpread({}, state, {
          error: null
        });
      }
    case STRIPE_FORM_SUBMIT_ERROR:
      {
        return _objectSpread({}, state, {
          error: action.error
        });
      }
    case RESET_CARD_INFO:
      {
        return _objectSpread({}, state, {
          cardInfo: {
            number: '',
            cvc: '',
            expDate: ''
          },
          cardValidationError: {
            number: null,
            expiry: null,
            cvc: null
          },
          cardValidationEmpty: {
            number: true,
            expiry: true,
            cvc: true
          },
          cardValidationCompleted: {
            number: false,
            expiry: false,
            cvc: false
          }
        });
      }
    case SET_STRIPE_FIELD_VALIDATION_ERROR:
      {
        return _objectSpread({}, state, {
          cardValidationError: _objectSpread({}, state.cardValidationError, action.payload)
        });
      }
    case SET_STRIPE_FIELD_VALIDATION_EMPTY:
      {
        return _objectSpread({}, state, {
          cardValidationEmpty: _objectSpread({}, state.cardValidationEmpty, action.payload)
        });
      }
    case SET_STRIPE_FIELD_VALIDATION_COMPLETED:
      {
        return _objectSpread({}, state, {
          cardValidationCompleted: _objectSpread({}, state.cardValidationCompleted, action.payload)
        });
      }
    default:
      return state;
  }
});