import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { LOGIN_POPUP, LOGIN_FOR_FAVOURITES_LINK_TITLE } from 'swag-client-common/constants';
import { totalFavouritesLengthSelector, isLoadingToggleFavouriteProductSelector } from 'swag-client-common/redux/favourite-products/favourite-products.selectors';
import { withConditionalRendering } from 'swag-client-common/components/utils/with-conditional-rendering';
import { hideForLimitedBuyingCartView } from 'swag-client-common/redux/cart/cart.utils';
import { FavouritesLink as FavouritesLinkComponent } from './favourites-link';
const mapStateToProps = createStructuredSelector({
  itemsNumber: totalFavouritesLengthSelector,
  isLoading: isLoadingToggleFavouriteProductSelector
});
const mapDispatchToProps = (dispatch, props) => ({
  onClick: props.isLoggedIn ? null : e => {
    e.preventDefault();
    dispatch(showPopup({
      popup: LOGIN_POPUP,
      title: LOGIN_FOR_FAVOURITES_LINK_TITLE
    }));
  }
});
export const FavouritesLink = withConditionalRendering(connect(mapStateToProps, mapDispatchToProps)(FavouritesLinkComponent), hideForLimitedBuyingCartView);