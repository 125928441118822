import { getProductImage } from 'swag-common/utils/product/product.utils';
import { hasOwnProperties } from 'swag-common/utils/has-own-properties';
export const getOrderItemImage = ({
  product,
  customerMockups,
  variantInd = 0,
  sizeInd = 0
}) => {
  const image = getProductImage({
    product,
    variantInd,
    view: 'front',
    sizeInd
  });
  const fallback = image && {
    url: image.url
  };
  if (hasOwnProperties(customerMockups)) {
    return {
      url: Object.values(customerMockups)[0].fileName
    };
  }
  return fallback;
};