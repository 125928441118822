import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { reloadAndShowNotification } from 'swag-client-common/redux/notification/notification.utils';
import AuthApi from 'swag-client-common/api/auth.api';
import { mergeRecentlyViewedForUser } from '../product/product.actions';
const PREFIX = 'ACCOUNT_VERIFICATION_';
export const VERIFY_STARTED = `${PREFIX}VERIFY_STARTED`;
export const VERIFY_ERROR = `${PREFIX}VERIFY_ERROR`;
export const VERIFY_SUCCESS = `${PREFIX}VERIFY_SUCCESS`;
export const verifyEmail = createAsyncAction({
  fn: AuthApi.verify,
  preFn: dispatch => {
    dispatch({
      type: VERIFY_STARTED
    });
  },
  success: body => ({
    type: VERIFY_SUCCESS,
    body
  }),
  error: VERIFY_ERROR,
  postSuccess: dispatch => {
    reloadAndShowNotification({
      notificationName: 'emailVerified'
    });
    dispatch(mergeRecentlyViewedForUser());
  }
});