function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import pickSkuForLineItem from 'swag-common/business-logic/order/pick-sku-for-line-item.logic';
import { isBox, isItemBoxSpecialTape } from 'swag-common/utils/order/item-is-box.util';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { isBrownBoxTape } from 'swag-common/utils/order';
import { formatCmyk } from 'swag-common/utils/cmyk.util';

//todo refactor usage from custom store folder
import { getProductKey } from '../../../custom-store-admin/redux/order-more-box/order-more-box.utils';
export const makeBoxItemForCart = ({
  prod,
  boxName,
  selectedBoxType
}) => ({
  prod,
  prodId: prod._id,
  colors: 0,
  price: prod.price[0][0][0],
  blendedPrice: prod.price[0][0][0],
  quantity: 0,
  textImages: [],
  setupFeeDeductionDiscount: 0,
  blendedQuantity: null,
  initialCreationDate: Date.now(),
  isCustomizedBlank: false,
  isMainItem: true,
  isReorder: false,
  discount: false,
  attachments: [],
  texts: {},
  sizeInd: 0,
  itemSku: selectedBoxType,
  logos: {},
  prodTime: 0,
  designNotes: '',
  adminAttachments: [],
  name: boxName,
  variants: [{
    quantities: [0],
    variantIndex: 0
  }],
  originalBoxType: selectedBoxType
});
export const makeFakeAddonItemForCart = ({
  prod,
  info,
  currentBoxesQuantity
}) => ({
  textImages: [],
  texts: {},
  logos: {},
  colors: 0,
  prodId: prod === null || prod === void 0 ? void 0 : prod._id,
  prod,
  prodTime: 0,
  itemSku: pickSkuForLineItem(prod, [{
    variantIndex: 0
  }]),
  initialCreationDate: Date.now(),
  sizeInd: 0,
  blendedQuantity: info.quantityPerBox * currentBoxesQuantity,
  quantity: info.quantityPerBox * currentBoxesQuantity,
  quantityPerBox: info.quantityPerBox,
  isReorder: false,
  discount: false,
  attachments: [],
  adminAttachments: [],
  setupFeeDeductionDiscount: 0,
  boxItemsId: info.boxItemsId,
  isMainItem: true,
  designNotes: '',
  isCustomizedBlank: true,
  variants: [{
    quantities: [info.quantityPerBox * currentBoxesQuantity],
    variantIndex: 0
  }]
});
export const makeBoxTapeItemForCart = ({
  prod,
  selectedBoxType,
  initialCreationDate
}) => {
  if (!prod || selectedBoxType !== SPECIAL_PRODUCT_TYPES.BROWN_BOX) {
    return null;
  }
  return {
    prod,
    prodId: prod._id,
    colors: 1,
    price: prod.price[0][0][0],
    blendedPrice: prod.price[0][0][0],
    quantity: 0,
    quantityPerBox: 1,
    textImages: [],
    setupFeeDeductionDiscount: 0,
    blendedQuantity: null,
    initialCreationDate,
    isCustomizedBlank: false,
    isMainItem: true,
    isReorder: false,
    discount: false,
    attachments: [],
    texts: {},
    sizeInd: 0,
    itemSku: prod.specialProductType,
    logos: {},
    prodTime: 0,
    designNotes: '',
    adminAttachments: [],
    name: prod.name,
    variants: [{
      quantities: [0],
      variantIndex: 0
    }]
  };
};
export const addSpecialProductTypeToBox = (cartItems, specialProductFlowData) => {
  const {
    boxItemsId,
    isBoxNotecardAbsent,
    specialProductItem
  } = specialProductFlowData;
  const newItems = [...cartItems];
  if (isBoxNotecardAbsent !== undefined) {
    const boxIndex = newItems.findIndex(item => item.boxItemsId === boxItemsId && isBox(item));
    newItems[boxIndex] = _objectSpread({}, newItems[boxIndex], {
      isBoxNotecardAbsent
    });
  }
  if (specialProductItem) {
    const existedItemIndex = cartItems.findIndex(item => item._id === specialProductItem._id);
    if (existedItemIndex > 0) {
      newItems[existedItemIndex] = specialProductItem;
    } else {
      const lastBoxItemIndex = newItems.map(item => item.boxItemsId).lastIndexOf(boxItemsId);
      newItems.splice(lastBoxItemIndex, 0, specialProductItem);
    }
  }
  return newItems;
};
const getCardItemValidationPayload = error => {
  if (!error) {
    return {
      errorText: null,
      isQtyError: false
    };
  }
  const {
    isSuccessed,
    message,
    isMoreThanAvailableInCart
  } = error;
  return {
    isQtyError: isMoreThanAvailableInCart,
    errorText: isSuccessed ? null : message
  };
};
export const populateCardItemWithValidation = validations => cardItem => {
  const {
    itemId,
    variant
  } = cardItem;
  const productKey = getProductKey(itemId, variant.variantIndex || 0);
  const errorPayload = getCardItemValidationPayload(validations[productKey]);
  return _objectSpread({}, cardItem, errorPayload);
};
export const getAmountOfSelectedProducts = cardItems => {
  return cardItems.reduce((amount, {
    isSelected
  }) => isSelected ? amount + 1 : amount, 0);
};
export const defineAsSplittedItem = (currentItem, boxItemsId) => cardItem => {
  const {
    _id,
    prodId,
    asRelatedItemId
  } = currentItem;
  return cardItem._id !== _id && cardItem.prodId === prodId && cardItem.asRelatedItemId === asRelatedItemId && cardItem.boxItemsId === boxItemsId;
};
export const generateSaveBoxItselfUpdatedItems = ({
  box,
  boxTypes,
  cartItems,
  selectedBoxType,
  brownBoxInfo,
  customBoxInfo,
  coloredBoxInfo,
  fullyCustomBoxInfo,
  initialCreationDate
}) => {
  var _oldTapeItem$logos$fr, _oldTapeItem$logos, _oldTapeItem$logos$fr2;
  const boxTypesProducts = boxTypes || [];
  const prod = boxTypesProducts.find(i => i.specialProductType === selectedBoxType);
  const tape = boxTypesProducts.find(i => i.specialProductType === SPECIAL_PRODUCT_TYPES.BOX_TAPE);
  const tapeItem = makeBoxTapeItemForCart({
    prod: tape,
    selectedBoxType,
    initialCreationDate
  });
  const oldTapeItem = cartItems.find(isBrownBoxTape);
  const oldLogo = (_oldTapeItem$logos$fr = oldTapeItem === null || oldTapeItem === void 0 ? void 0 : (_oldTapeItem$logos = oldTapeItem.logos) === null || _oldTapeItem$logos === void 0 ? void 0 : (_oldTapeItem$logos$fr2 = _oldTapeItem$logos.front) === null || _oldTapeItem$logos$fr2 === void 0 ? void 0 : _oldTapeItem$logos$fr2[0]) !== null && _oldTapeItem$logos$fr !== void 0 ? _oldTapeItem$logos$fr : null;
  const boxItemChanges = {
    itemSku: selectedBoxType
  };
  if (box.isReorder) {
    const isBoxTypeUpdated = selectedBoxType !== box.itemSku;
    boxItemChanges.isReorder = !isBoxTypeUpdated;
  }
  let variantIndex = 0;
  switch (selectedBoxType) {
    case SPECIAL_PRODUCT_TYPES.FULLY_CUSTOM_BOX:
      {
        if (fullyCustomBoxInfo) {
          const {
            note
          } = fullyCustomBoxInfo;
          boxItemChanges.designNotes = note;
          boxItemChanges.logos = {};
          boxItemChanges.boxColor = '';
        }
        break;
      }
    case SPECIAL_PRODUCT_TYPES.BROWN_BOX:
      {
        if (brownBoxInfo && tapeItem) {
          const {
            tapeLogo
          } = brownBoxInfo;
          boxItemChanges.boxColor = '';
          boxItemChanges.designNotes = '';
          if (tapeLogo !== null && tapeLogo !== void 0 && tapeLogo.id) {
            tapeItem.logos = {
              front: [tapeLogo]
            };
            boxItemChanges.logos = {
              front: [tapeLogo]
            };
            break;
          }
          if (!(tapeLogo !== null && tapeLogo !== void 0 && tapeLogo.id) && customBoxInfo) {
            const {
              logo
            } = customBoxInfo;
            tapeItem.logos = {
              front: logo ? [logo] : [oldLogo]
            };
            break;
          }
        }
        break;
      }
    case SPECIAL_PRODUCT_TYPES.COLORED_BOX:
      {
        if (coloredBoxInfo) {
          const {
            color
          } = coloredBoxInfo;
          variantIndex = prod.variants.findIndex(v => v.color === color);
          boxItemChanges.logos = {};
          boxItemChanges.boxColor = '';
          boxItemChanges.designNotes = '';
        }
        break;
      }
    default:
      {
        if (customBoxInfo) {
          const {
            color,
            logo
          } = customBoxInfo;
          boxItemChanges.boxColor = formatCmyk(color);
          boxItemChanges.designNotes = '';
          boxItemChanges.logos = {
            front: [logo]
          };
        }
        break;
      }
  }
  const isSelectedBrownBox = selectedBoxType === SPECIAL_PRODUCT_TYPES.BROWN_BOX;
  const isSelectedBoxTape = item => {
    return isItemBoxSpecialTape(item) && item.boxItemsId === box.boxItemsId;
  };
  const isBoxTapeShouldBeEdited = (item, isBoxTypeChanged) => {
    return isSelectedBoxTape(item) && !isBoxTypeChanged && isSelectedBrownBox;
  };
  const isBoxTapeShouldBeRemoved = (item, isBoxTypeChanged) => {
    return isSelectedBoxTape(item) && isBoxTypeChanged && !isSelectedBrownBox;
  };
  const createBoxTape = ({
    boxItemsId,
    quantity,
    variants
  }) => _objectSpread({}, tapeItem, {
    boxItemsId,
    quantity,
    variants: [{
      quantities: variants[0].quantities,
      variantIndex
    }]
  });
  const updatedItems = cartItems.reduce((all, item) => {
    const {
      _id: itemId,
      prod: itemProd,
      variants
    } = item;
    const originalBoxType = itemProd === null || itemProd === void 0 ? void 0 : itemProd.specialProductType;
    const isBoxTypeChanged = isSelectedBrownBox ? !(originalBoxType === SPECIAL_PRODUCT_TYPES.BROWN_BOX || originalBoxType === SPECIAL_PRODUCT_TYPES.BOX_TAPE) : originalBoxType !== selectedBoxType;
    if (itemId === box._id) {
      const isBoxTapeShouldBeAdded = isSelectedBrownBox && isBoxTypeChanged;
      all.push(_objectSpread({}, item, boxItemChanges, {
        prod,
        prodId: (prod === null || prod === void 0 ? void 0 : prod._id) || '',
        variants: [{
          quantities: variants[0].quantities,
          variantIndex
        }],
        originalBoxType: isBoxTypeChanged ? null : originalBoxType
      }));
      if (isBoxTapeShouldBeAdded) {
        all.push(createBoxTape(item));
      }
      return all;
    }
    if (isBoxTapeShouldBeEdited(item, isBoxTypeChanged)) {
      all.push(createBoxTape(item));
      return all;
    }
    if (isBoxTapeShouldBeRemoved(item, isBoxTypeChanged)) {
      return all;
    }
    all.push(item);
    return all;
  }, []);
  return updatedItems;
};