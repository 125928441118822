import { email as emailValidator } from 'swag-client-common/utils/redux-form-validators';
export const validateShareDesignForm = data => {
  const {
    email,
    colors,
    customerMockups,
    specs,
    descr,
    prodTime,
    price
  } = data;
  const emailError = emailValidator(email);
  const oneOptionSelected = colors || specs || descr || prodTime || price || customerMockups;
  const optionsError = !oneOptionSelected && 'Please, select at least one option';
  return emailError || optionsError;
};