function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import ProductApi from '../../api/product.api';
import { customerStore } from '../swag.store';
import { map, calcPrices, updateLocations, updateColor, checkValid } from '../common/common.utils';
const PREFIX = 'KICKSTARTER_FORM_';
export const RESET_FORM = `${PREFIX}RESET_FORM`;
export const reset = () => ({
  type: RESET_FORM
});
export const GET_PRODUCTS_STARTED = `${PREFIX}GET_PRODUCTS_STARTED`;
export const GET_PRODUCTS_ERROR = `${PREFIX}GET_PRODUCTS_ERROR`;
export const GET_PRODUCTS_SUCCESS = `${PREFIX}GET_PRODUCTS_SUCCESS`;
export const getProducts = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_PRODUCTS_STARTED
    });
  },
  fn: ProductApi.getByNames,
  success: body => {
    const {
      products,
      orderItem
    } = customerStore.getState().kickstarter;
    return {
      type: GET_PRODUCTS_SUCCESS,
      products: calcPrices(products.map(p => _objectSpread({}, p, {
        data: body.products.find(pr => pr.name === p.product)
      })), orderItem)
    };
  },
  error: error => ({
    type: GET_PRODUCTS_ERROR,
    error
  })
});
export const CHANGE_FIELD = `${PREFIX}CHANGE_FIELD`;
export const change = (field, nextValue, index) => {
  const state = customerStore.getState().kickstarter;
  const orderItem = _objectSpread({}, state.orderItem);
  let value = parseInt(nextValue, 10);
  let amountErr = state.amountErr;
  const orderItemColors = Object.values(orderItem.logos).reduce((all, nextLogo) => {
    const colorNumber = nextLogo[0].colors.colorsNumber;
    return [...all, colorNumber];
  }, []);
  const orderItemPrintColors = Object.values(orderItem.logos).reduce((all, nextLogo) => {
    const colorsToPrint = nextLogo[0].colors.colorsToPrint;
    return [...all, colorsToPrint];
  }, []);
  let zeroColorsIndex = orderItemColors.findIndex(v => v === 0);
  if (isNaN(value)) {
    value = 0;
  }
  switch (field) {
    case 'amount':
      {
        if (checkValid('amount', value)) {
          const {
            minQuant
          } = state.products[0].data;
          orderItem.quantity = value;
          amountErr = value < minQuant ? `You cannot order less than ${minQuant}` : '';
        } else {
          value = state.amount;
        }
        break;
      }
    case 'locations':
      {
        if (checkValid('locations', value)) {
          const {
            images,
            zeroIndex,
            logos
          } = updateLocations(orderItemColors, value,
          // @ts-ignore
          orderItemPrintColors);
          orderItem.images = images;
          orderItem.logos = logos;
          zeroColorsIndex = zeroIndex;
        } else {
          value = state.locations;
        }
        break;
      }
    case 'colors':
      {
        if (!checkValid('colors', value)) {
          value = orderItem.images[map[index]].colors.colorsNumber;
        }
        const {
          images,
          zeroIndex,
          logos
        } = updateColor(orderItemColors, value, index);
        orderItem.images = images;
        orderItem.logos = logos;
        zeroColorsIndex = zeroIndex;
        break;
      }
    default:
      break;
  }
  const nextState = _objectSpread({}, state, {
    [field]: value,
    orderItem,
    amountErr
  });
  nextState.displayPrices = nextState.amountErr === '' && nextState.amount !== 0 && nextState.locations !== 0 && (zeroColorsIndex === -1 || zeroColorsIndex >= nextState.locations);
  if (nextState.displayPrices) {
    nextState.products = calcPrices(state.products, orderItem);
  }
  return {
    type: CHANGE_FIELD,
    nextState
  };
};
export const SHOW_SPECS = `${PREFIX}SHOW_SPECS`;
export const showSpecs = ({
  product
}) => ({
  type: SHOW_SPECS,
  images: product.data.variants[0].images,
  content: product.data.specs,
  name: product.label
});
export const HIDE_SPECS = `${PREFIX}HIDE_SPECS`;
export const hideSpecs = () => ({
  type: HIDE_SPECS
});
export const SHOW_TEE_IMAGE = `${PREFIX}SHOW_TEE_IMAGE`;
export const showTeeImage = ({
  images,
  shownImage
}) => ({
  type: SHOW_TEE_IMAGE,
  images,
  shownImage
});
export const HIDE_TEE_IMAGE = `${PREFIX}HIDE_TEE_IMAGE`;
export const hideTeeImage = () => ({
  type: HIDE_TEE_IMAGE
});