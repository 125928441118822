import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
const prefix = 'SHOW_MOCKUPS_';
export const SELECT_ITEM = `${prefix}SELECT_CART_ITEM`;
export const selectItem = item => ({
  type: SELECT_ITEM,
  payload: {
    item
  }
});
export const showCustomerMockups = item => dispatch => {
  dispatch(selectItem(item));
  dispatch(showPopup({
    popup: 'openImage'
  }));
};
export const RESET = `${prefix}RESET`;
export const reset = () => ({
  type: RESET
});