import { CART_LINK_PREFIX } from 'swag-common/constants/main-swag.constants';
export function urlModifier(url) {
  return url;
}
export function urlReplacer(url) {
  return url;
}
export const SOCKET_PATH = null;
export const TRANSPORTS = ['websocket'];
export function getCartLinkId() {
  var _window, _window$location;
  if (typeof window !== 'undefined' && (_window = window) !== null && _window !== void 0 && (_window$location = _window.location) !== null && _window$location !== void 0 && _window$location.pathname.includes(CART_LINK_PREFIX)) {
    var _window2, _window2$location, _window2$location$pat, _window2$location$pat2;
    return (_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$location = _window2.location) === null || _window2$location === void 0 ? void 0 : (_window2$location$pat = _window2$location.pathname) === null || _window2$location$pat === void 0 ? void 0 : (_window2$location$pat2 = _window2$location$pat.split('/')) === null || _window2$location$pat2 === void 0 ? void 0 : _window2$location$pat2[2];
  }
}
export function cartUrlModifier(url) {
  var _window3, _window3$location;
  if (typeof window !== 'undefined' && (_window3 = window) !== null && _window3 !== void 0 && (_window3$location = _window3.location) !== null && _window3$location !== void 0 && _window3$location.pathname.includes(CART_LINK_PREFIX)) {
    const cartLinkId = getCartLinkId();
    return `${CART_LINK_PREFIX}${cartLinkId}${url}`;
  }
  return url;
}