function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import request from 'superagent';
import { urlModifier } from './url-modifier';
const noop = (data, done) => done(null, data);
export function createApi(actions, options = {}) {
  const instance = {};
  Object.keys(actions).forEach(key => {
    instance[key] = createRequest(actions[key], options);
  });
  return instance;
}
function createRequest(opts, additionalOptions = {}) {
  const {
    url,
    method = 'get',
    validate = noop
  } = opts;
  const baseUrl = url;
  const sendType = method === 'get' || method === 'header' ? 'query' : 'send';
  const urlParams = getUrlParams(baseUrl);
  return (data = {}, done) => validate(data, () => {
    const url = replaceUrlParams(baseUrl, urlParams, data);
    const req = request(method, urlModifier(url));
    if (data) {
      if (additionalOptions.replaceUnnecessaryFields) {
        req[sendType](replaceParamsFromData(urlParams, data));
      } else {
        req[sendType](data);
      }
    }
    req.set('csrf-token', window.swag.csrfToken);
    return new Promise((resolve, reject) => {
      req.end((err, res) => {
        if (err) {
          const result = res ? res.body || err : err;
          if (!done) {
            return reject(result);
          }
          return done(result);
        }
        if (!done) {
          return resolve(res.body);
        }
        resolve(done(null, res.body));
      });
    });
  });
}
function getUrlParams(url) {
  const re = /:([^/?]+)/g;
  const params = {};
  let g;
  while (g = re.exec(url)) {
    params[g[1]] = g[0];
  }
  return params;
}
function replaceUrlParams(url, urlParams, data) {
  if (!data) {
    return url;
  }
  const paramKeys = Object.keys(urlParams);
  return paramKeys.reduce((updatedUrl, key) => updatedUrl.replace(urlParams[key], data[key]), url);
}
function replaceParamsFromData(urlParams = {}, data = {}) {
  const newData = _objectSpread({}, data);
  const keys = Object.keys(urlParams);
  keys.forEach(key => {
    delete newData[key];
  });
  return newData;
}