import { createApi } from 'swag-client-common/libs/rapi';
export const customStoreBaseUrl = '/custom-store-api/v1';
export const inventoryBaseUrl = '/inventory';
export const baseUrl = `${customStoreBaseUrl}${inventoryBaseUrl}`;
export const baseCreditsApi = `${baseUrl}/:inventoryId/credits`;
const creditsApi = createApi({
  buy: {
    url: `${baseCreditsApi}/buy`,
    method: 'post'
  },
  get: {
    url: `${baseCreditsApi}/`,
    method: 'get'
  },
  withdraw: {
    url: `${baseCreditsApi}/withdraw`,
    method: 'post'
  }
});
export default creditsApi;