import { calculateSetupFeeValue } from 'swag-common/business-logic';
import { getItemPriceMultiplier } from 'swag-common/business-logic/get-item-price-multiplier.logic';
import { DesignUploadMode } from 'swag-common/interfaces';
export default function getSetupFeeDeductionDiscount(item, featureFlags) {
  const {
    prod,
    logos,
    texts,
    isSample,
    isPrintedSample,
    prodTime,
    products
  } = item;
  const isSampleItem = isSample || isPrintedSample;
  const isBoxTypeProduct = (prod === null || prod === void 0 ? void 0 : prod.designUploadMode) === DesignUploadMode.box && !isSampleItem;
  const isLogoTypeProduct = (prod === null || prod === void 0 ? void 0 : prod.designUploadMode) === DesignUploadMode.logo && !isSampleItem;
  let setupFee = 0;
  const multiplier = getItemPriceMultiplier(item);
  if (isBoxTypeProduct && products) {
    setupFee = products.reduce((all, product) => {
      return all + calculateSetupFeeValue({
        product: product.prod,
        logos: product.logos,
        texts: null,
        isSample: isSampleItem,
        prodTime,
        multiplier,
        featureFlags
      });
    }, 0);
  }
  if (isLogoTypeProduct) {
    setupFee = calculateSetupFeeValue({
      product: prod,
      logos,
      texts,
      isSample: isSampleItem,
      prodTime,
      multiplier,
      featureFlags
    });
  }
  return Math.round(setupFee);
}