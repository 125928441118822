import { detect } from 'detect-browser';
export const changeProtocol = url => url.replace(/^https:\/\//i, 'http://');
export const isMobile = () => window.matchMedia('only screen and (max-width: 767px)').matches;
export const is1024andSmaller = () => window.matchMedia('only screen and (max-width: 1024px)').matches;
export const isTablet = () => window.matchMedia('only screen and (max-width: 1149px)').matches;
export const isScreenWithBigResolution = () => window.matchMedia('only screen and (min-width: 1441px)').matches;
export const isMobileFilter = () => window.matchMedia('only screen and (max-width: 768px)').matches;
export const isLandscapeOrientation = () => window.matchMedia('(orientation: landscape)').matches;
export const isPortraitOrientation = () => window.matchMedia('(orientation: portrait)').matches;
export const isTallScreen = () => window.innerWidth / window.innerHeight < 1.4;
export const getCurrentBrowserWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
export const detectIE = () => {
  const ua = window.navigator.userAgent;

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
};
export const isSafari = () => {
  const browser = detect();
  return (browser === null || browser === void 0 ? void 0 : browser.name) === 'safari';
};
export const getWidthInBasedOnScreenWidth = width => {
  return Math.floor(width * window.innerWidth / 1920);
};
export const showTooltipBasedOnContentWidth = ({
  selector,
  width,
  node
}) => {
  const minWidth = getWidthInBasedOnScreenWidth(width);
  if (node) {
    const text = node.querySelectorAll(`[data-cell="${selector}"] span`)[0];
    const width = text ? text.clientWidth : 0;
    return width < minWidth;
  }
  return false;
};
export const handleOnEnterPressed = (e, callback) => {
  if (e.key === 'Enter' && callback) {
    callback();
  }
};
export const handleOnEscapeAndEnterPressed = ({
  event,
  onEnterCallback,
  onEscapeCallback
}) => {
  if (event.key === 'Enter') {
    onEnterCallback();
    return;
  }
  if (event.key === 'Escape') {
    onEscapeCallback();
    return;
  }
};
export const handleOnEscapePressed = (e, callback) => {
  if (e.key === 'Escape' && callback) {
    callback();
  }
};