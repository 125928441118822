function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapSizesToOneVariantColor } from 'swag-common/utils/custom-store-product/map-sizes-to-one-color.util';
import checkIsBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import checkIsBoxWithSizes from 'swag-common/business-logic/custom-store-product/is-box-with-sizes.logic';
import getBoxSizeSet from 'swag-common/business-logic/products/get-box-size-set.logic';
import checkIsCustomProductApparel from 'swag-common/utils/custom-product/check-is-custom-product-apparel';
export const sizesAndQuantityPerColorSelector = (product, type) => {
  if (!product) {
    return {
      sizes: {
        name: null,
        options: [],
        count: 0,
        isVisible: true
      },
      variants: [],
      inStock: 0,
      quantityPerSizeEnabled: false,
      quantityPerColorEnabled: false,
      productId: '',
      name: '',
      inProduction: 0,
      inReserve: 0
    };
  }
  const {
    name,
    inStock = 0,
    inProduction = 0,
    inReserveQuantity = 0,
    variants,
    sizeSettings
  } = product;
  const swagProduct = product.swagProductId;
  const sizeDependentPrice = swagProduct === null || swagProduct === void 0 ? void 0 : swagProduct.sizeDependentPrice;
  const selectedSize = variants[0].sizeIndex;
  const isBox = checkIsBox(swagProduct);
  const isBoxWithSizes = isBox && checkIsBoxWithSizes(product);
  const isApparel = checkIsCustomProductApparel(product) || isBoxWithSizes;
  const quantityPerSizeEnabled = !sizeDependentPrice && sizeSettings && sizeSettings.options.length > 1 || isBoxWithSizes;
  let sizes = sizeSettings || null;
  if (isBoxWithSizes) {
    const boxSizeSet = getBoxSizeSet(product);
    sizes = {
      name: 'Box size',
      options: Object.keys(boxSizeSet),
      count: Number(boxSizeSet.length),
      _id: null,
      isVisible: true
    };
  }
  const variantsByColor = mapSizesToOneVariantColor(variants, sizes, isBox, type);
  const filteredVariants = type ? variantsByColor.filter(variant => variant.quantity > 0) : variantsByColor;
  return {
    name,
    inStock,
    inReserve: inReserveQuantity,
    inProduction,
    isApparel,
    variants: filteredVariants,
    sizes,
    selectedSize,
    quantityPerSizeEnabled,
    quantityPerColorEnabled: true,
    productId: product._id
  };
};
export function sortProductsBySizes(variants) {
  const isBox = Boolean(variants[0] && variants[0].boxContent && variants[0].boxContent.length > 0);
  const sortedVariants = [...variants];
  if (!isBox) {
    sortedVariants.sort((a, b) => a.sizeIndex - b.sizeIndex);
    return sortedVariants;
  }
  sortedVariants.sort((a, b) => {
    const aFirstSizedContent = a.boxContent.find(getFirstSizeContent);
    const bFirstSizedContent = b.boxContent.find(getFirstSizeContent);
    if (!aFirstSizedContent || !bFirstSizedContent) {
      return 0;
    }
    return aFirstSizedContent.product.sizeIndex - bFirstSizedContent.product.sizeIndex;
  });
  return sortedVariants;
}
function getFirstSizeContent({
  product
}) {
  return product.sizeSettings && product.sizeSettings.options && !product.swagProductId.sizeDependentPrice && product.swagProductId.hasSize;
}
export function getReserveInformationByColor({
  product,
  size,
  quantity,
  quantityResult,
  inProductionQuantity,
  inReserveQuantity,
  isColorExists
}) {
  let overallReserved = 0;
  let inReserveStock = 0;
  let inReserveProduction = 0;
  const quantityAvailable = quantity + inProductionQuantity - inReserveQuantity;
  const inReserveStockQuantity = quantity > inReserveQuantity ? inReserveQuantity : quantity;
  const inReserveProductionQuantity = inReserveQuantity > quantity ? inReserveQuantity - quantity : 0;
  const colorVariants = isColorExists ? [...product.variants, {
    size,
    quantity,
    inReserveQuantity,
    inProductionQuantity,
    quantityAvailable: quantityAvailable > 0 ? quantityAvailable : 0
  }] : [{
    size,
    quantity,
    inReserveQuantity,
    inProductionQuantity,
    quantityAvailable: quantityAvailable > 0 ? quantityAvailable : 0
  }];
  const inProduction = isColorExists ? product.inProduction + inProductionQuantity : inProductionQuantity;
  overallReserved = isColorExists ? product.overallReserved + inReserveQuantity : inReserveQuantity;
  inReserveStock = isColorExists ? product.inReserveStock + inReserveStockQuantity : inReserveStockQuantity;
  inReserveProduction = isColorExists ? product.inReserveProduction + inReserveProductionQuantity : inReserveProductionQuantity;
  const overallAvailable = quantityResult + inProduction - overallReserved;
  return {
    size,
    inProduction,
    inReserveStock,
    inReserveProduction,
    overallReserved,
    overallAvailable,
    variants: colorVariants
  };
}
export const getProductsWithOneColorVariants = (product, color) => {
  if (!color) {
    return product;
  }
  return _objectSpread({}, product, {
    variants: product.variants.filter(({
      colorValue
    }) => colorValue === color)
  });
};