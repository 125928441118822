function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { EDIT_BOX_NOTECARD_PREVENT_CLOSE_POPUP } from 'swag-client-common/constants';
import { allItemsInCartAndDraftSelector } from '../cart/cart.selectors';
import { notecardColorSelector, notecardLogoPreviewSelector, notecardTextContentSelector } from '../notecard-editor/notecard-editor.selectors';
import { SET_NOTECARD_EDITOR_INITIAL_VALUES, resetNotecardEditor } from '../notecard-editor/notecard-editor.actions';
import { toggleSpecialProductInBox } from '../box-builder/box-builder.actions';
import { buildNotecard } from '../notecard-editor/notecard-editor.utils';
import { BOX_NOTECARD_STEPS } from './box-notecard-editor.reducer';
import { stepSelector, selectedBoxItemsIdSelector } from './box-notecard-editor.selectors';
const PREFIX = 'BOX_NOTECARD_EDITOR_';
export const RESET_POPUP = `${PREFIX}RESET_POPUP`;
export const resetPopup = () => ({
  type: RESET_POPUP
});
export const MOVE_TO_POPUP_STEP = `${PREFIX}MOVE_TO_POPUP_STEP`;
export const moveToPopupStep = step => ({
  type: MOVE_TO_POPUP_STEP,
  payload: step
});
export const SET_BOX_NOTECARD_BUILDER_BOX_ID = `${PREFIX}SET_BOX_NOTECARD_BUILDER_BOX_ID`;
export const setBoxNotecardBuilderBoxItemsId = boxItemsId => (dispatch, getState) => {
  const state = getState();
  const allItems = allItemsInCartAndDraftSelector(state);
  let notecardData = {};
  const notecardSavedInBox = allItems.find(item => item.boxItemsId === boxItemsId && item.prod.specialProductType === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD);
  if (notecardSavedInBox) {
    var _notecardSavedInBox$l, _notecardSavedInBox$l2;
    notecardData = _objectSpread({}, notecardSavedInBox.notecardDesignData, {
      logo: (_notecardSavedInBox$l = notecardSavedInBox.logos) === null || _notecardSavedInBox$l === void 0 ? void 0 : (_notecardSavedInBox$l2 = _notecardSavedInBox$l.front) === null || _notecardSavedInBox$l2 === void 0 ? void 0 : _notecardSavedInBox$l2[0]
    });
  }
  dispatch({
    type: SET_NOTECARD_EDITOR_INITIAL_VALUES,
    payload: notecardData
  });
  return dispatch({
    type: SET_BOX_NOTECARD_BUILDER_BOX_ID,
    payload: {
      boxItemsId
    }
  });
};
export const SET_BOX_NOTECARD_LOADING = `${PREFIX}SET_BOX_NOTECARD_LOADING`;
export const setBoxNotecardLoading = value => ({
  type: SET_BOX_NOTECARD_LOADING,
  payload: value
});
export const onBoxNotecardDesignClose = () => (dispatch, getState) => {
  var _notecardSavedInBox$n, _notecardSavedInBox$n2, _notecardSavedInBox$l3;
  const state = getState();
  const allItems = allItemsInCartAndDraftSelector(state);
  const step = stepSelector(state);
  const boxItemsId = selectedBoxItemsIdSelector(state);
  const textContent = notecardTextContentSelector(state);
  const color = notecardColorSelector(state);
  const logo = notecardLogoPreviewSelector(state);
  const notecardSavedInBox = allItems.find(item => item.boxItemsId === boxItemsId && item.prod.specialProductType === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD);
  const isTextChanged = textContent !== (notecardSavedInBox === null || notecardSavedInBox === void 0 ? void 0 : (_notecardSavedInBox$n = notecardSavedInBox.notecardDesignData) === null || _notecardSavedInBox$n === void 0 ? void 0 : _notecardSavedInBox$n.textContent);
  const isColorChanged = color !== (notecardSavedInBox === null || notecardSavedInBox === void 0 ? void 0 : (_notecardSavedInBox$n2 = notecardSavedInBox.notecardDesignData) === null || _notecardSavedInBox$n2 === void 0 ? void 0 : _notecardSavedInBox$n2.color);
  const isLogoChanged = logo !== (notecardSavedInBox === null || notecardSavedInBox === void 0 ? void 0 : (_notecardSavedInBox$l3 = notecardSavedInBox.logos.front) === null || _notecardSavedInBox$l3 === void 0 ? void 0 : _notecardSavedInBox$l3[0].originalPreview);
  if (step === BOX_NOTECARD_STEPS.TEXT_CONTENT && (isTextChanged || isLogoChanged || isColorChanged)) {
    return dispatch(showPopup({
      popup: EDIT_BOX_NOTECARD_PREVENT_CLOSE_POPUP
    }));
  }
  dispatch(closeBoxNotecardEditorPopup());
};
export const submitBoxNotecard = mockupContainer => async (dispatch, getState) => {
  dispatch(setBoxNotecardLoading(true));
  const state = getState();
  const boxItemsId = selectedBoxItemsIdSelector(state);
  const notecardDefaultImage = await buildNotecard(mockupContainer);
  dispatch(toggleSpecialProductInBox(boxItemsId, SPECIAL_PRODUCT_TYPES.BOX_NOTECARD, {
    isNotecardPicked: true,
    notecardDefaultImage
  }));
  dispatch(closeBoxNotecardEditorPopup());
};
export const closeBoxNotecardEditorPopup = () => dispatch => {
  dispatch(hidePopup());
  dispatch(resetPopup());
  dispatch(resetNotecardEditor());
};