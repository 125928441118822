import { INVENTORY_PERMISSION_LEVELS } from '../../constants/inventory';
const {
  PUSH,
  PUSH_AND_PULL,
  PULL
} = INVENTORY_PERMISSION_LEVELS;
export const isAdmin = (admins, userId) => {
  return Boolean(admins.find(id => String(id) === String(userId)));
};
export const isOwner = (ownerId, userId) => {
  return String(ownerId) === String(userId);
};
export const isPushAndPullOnly = (canTake, canPut, userId) => {
  return Boolean(canTake.find(id => String(id) === String(userId))) && Boolean(canPut.find(id => String(id) === String(userId)));
};
export const isPullOnly = (canTake, canPut, userId) => {
  return Boolean(canTake.find(id => String(id) === String(userId))) && !canPut.find(id => String(id) === String(userId));
};
export const isPutOnly = (canTake, canPut, userId) => {
  return !canTake.find(id => String(id) === String(userId)) && Boolean(canPut.find(id => String(id) === String(userId)));
};
export const getUserIds = users => users.map(({
  _id
}) => _id);
export const getPermission = (userId, canTake, canPut) => {
  if (isPullOnly(canTake, canPut, userId)) {
    return PULL;
  }
  if (isPutOnly(canTake, canPut, userId)) {
    return PUSH;
  }
  if (isPushAndPullOnly(canTake, canPut, userId)) {
    return PUSH_AND_PULL;
  }
};
export const canPullOnlyFromInventory = (inventory, userId) => {
  const {
    canPutUserIds,
    canTakeUserIds
  } = inventory;
  return getPermission(userId, canTakeUserIds, canPutUserIds) === PULL;
};
export const canPutOnlyFromInventory = (inventory, userId) => {
  const {
    canPutUserIds,
    canTakeUserIds
  } = inventory;
  return getPermission(userId, canTakeUserIds, canPutUserIds) === PUSH;
};
export function getUserPermissionLevel(inventory, userId) {
  const {
    adminsIds,
    canPutUserIds,
    canTakeUserIds,
    ownerId
  } = inventory;
  switch (true) {
    case isAdmin(adminsIds, userId):
      {
        return INVENTORY_PERMISSION_LEVELS.ADMIN;
      }
    case isPullOnly(canTakeUserIds, canPutUserIds, userId):
      {
        return INVENTORY_PERMISSION_LEVELS.PULL;
      }
    case isPushAndPullOnly(canTakeUserIds, canPutUserIds, userId):
      {
        return INVENTORY_PERMISSION_LEVELS.PUSH_AND_PULL;
      }
    case isPutOnly(canPutUserIds, canPutUserIds, userId):
      {
        return INVENTORY_PERMISSION_LEVELS.PUSH;
      }
    case isOwner(ownerId, userId):
      {
        return INVENTORY_PERMISSION_LEVELS.OWNER;
      }
    default:
      return INVENTORY_PERMISSION_LEVELS.NO_ACCESS;
  }
}