export const isRedirectWrapperStateExists = (state, action) => {
  return !!state && !!state.allowNewLocation && action === 'REPLACE';
};
export const forceRedirectToPath = pathname => {
  // @ts-ignore
  window.location = pathname;
};
export const forcedPath = (route, query = {}) => ({
  pathname: route,
  query,
  state: {
    force: true
  }
});