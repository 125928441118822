import { PRODUCT_SORTING } from 'swag-common/constants/product.constants';
export const ALL_SWAG_DESCRIPTION_TEXT = 'We all have some company branded or custom logo items lurking around, but what makes the promo item something you want to keep? At Swag we aim to help you create unique promotional merchandise and high quality items that stand the test of time.';
export const SUPER_SPEED_DESCRIPTION_TEXT = 'Check out our curated selection of Super Speed products that we can produce on accelerated timelines!';
export const SUPER_SPEED_FOR_BOX_DESCRIPTION_TEXT = 'Check out our selection of Super Speed products that you can get on accelerated timelines! We will produce and distribute these items much faster than our standard products. All of these Super Speed products can be shipped directly to you, held in inventory, or combined with other Super Speed products to create a box. Please note if you add any non Super Speed products to your box, Super Speed timelines will no longer apply.';
export const SUPER_SPEED_CATEGORY_LABEL = 'Super Speed Products';
export const MENU_ITEMS = [{
  name: PRODUCT_SORTING.RELEVANCE,
  isForClient: false
}, {
  name: PRODUCT_SORTING.MOST_POPULAR,
  isForClient: false
}, {
  name: PRODUCT_SORTING.NEWEST,
  isForClient: true
}, {
  name: PRODUCT_SORTING.HOTTEST,
  isForClient: true
}, {
  name: PRODUCT_SORTING.HIGHEST_PRICE,
  isForClient: false
}, {
  name: PRODUCT_SORTING.LOWEST_PRICE,
  isForClient: false
}];