export const budget = [{
  value: 'Less than $50',
  label: 'Less than $50'
}, {
  value: '$50 - $100',
  label: '$50 - $100'
}, {
  value: '$101 - $200',
  label: '$101 - $200'
}, {
  value: '$201 - $500',
  label: '$201 - $500'
}, {
  value: '$501 - $1,000',
  label: '$501 - $1,000'
}, {
  value: '$1,000+',
  label: '$1,000+'
}];
export const boxes = [{
  value: '100 - 250',
  label: '100 - 250'
}, {
  value: '251 - 500',
  label: '251 - 500'
}, {
  value: '501 - 1,000',
  label: '501 - 1,000'
}, {
  value: '1,000+',
  label: '1,000+'
}];
export const yearly = [{
  value: '$1K - $50K',
  label: '$1K - $50K'
}, {
  value: '$51K - $100K',
  label: '$51K - $100K'
}, {
  value: '$101K - $200K',
  label: '$101K - $200K'
}, {
  value: '$201K - $500K',
  label: '$201K - $500K'
}, {
  value: '$501K - $1M',
  label: '$501K - $1M'
}, {
  value: '$1M+',
  label: '$1M+'
}];
export const employees = [{
  value: '1 - 10',
  label: '1 - 10'
}, {
  value: '11 - 50',
  label: '11 - 50'
}, {
  value: '51 - 200',
  label: '51 - 200'
}, {
  value: '201 - 500',
  label: '201 - 500'
}, {
  value: '501 - 1,000',
  label: '501 - 1,000'
}, {
  value: '1,001 - 5,000',
  label: '1,001 - 5,000'
}, {
  value: '5,001 - 10,000',
  label: '5,001 - 10,000'
}, {
  value: '10,000+',
  label: '10,000+'
}];
export const eachReward = [{
  value: '$1 - $5',
  label: '$1 - $5'
}, {
  value: '$6 -$25',
  label: '$6 - $25'
}, {
  value: '$26 - $50',
  label: '$26 - $50'
}, {
  value: '$51 - $100',
  label: '$51 - $100'
}, {
  value: '$101+',
  label: '$101+'
}];
export const rewards = [{
  value: '1 - 100',
  label: '1 - 100'
}, {
  value: '101 - 250',
  label: '101 - 250'
}, {
  value: '251 - 1,000',
  label: '251 - 1,000'
}, {
  value: '1,001 - 5,000',
  label: '1,001 - 5,000'
}, {
  value: '5,001 - 10,000',
  label: '5,001 - 10,000'
}, {
  value: '10,000+',
  label: '10,000+'
}];
export const apiUses = [{
  value: 'Automate sending swag',
  label: 'Automate sending swag'
}, {
  value: 'Zapier',
  label: 'Zapier'
}, {
  value: 'Become a partner',
  label: 'Become a partner'
}, {
  value: 'Other',
  label: 'Other'
}];
export const reasons = [{
  value: 'On-boarding new hires',
  label: 'On-boarding new hires'
}, {
  value: 'Engage with remote team',
  label: 'Engage with remote team'
}, {
  value: 'Holiday / gifting',
  label: 'Holiday / gifting'
}, {
  value: 'Reward customers',
  label: 'Reward customers'
}, {
  value: 'Send swag to leads',
  label: 'Send swag to leads'
}, {
  value: 'Humanize events',
  label: 'Humanize events'
}];
export const apps = ['BambooHR', 'Eventbrite', 'Hopin', 'Hubspot', 'Mailchimp', 'Marketo', 'Salesforce', 'Shopify', 'Other'];