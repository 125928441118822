import { HUBSPOT_FIELDS } from 'swag-common/constants/hubspot';
import { required, email, maxLength, minLength } from 'swag-client-common/utils/redux-form-validators';
import classes from './request-demo-popup.scss';
export const popupClasses = {
  paper: classes.popupPaper,
  container: classes.popupContainer
};
export const formControlLabelClasses = {
  label: classes.checkboxLabel,
  root: classes.checkboxLabelRoot
};
export const checkboxClasses = {
  colorPrimary: classes.checkbox
};
export const companySizeOptions = [{
  value: '1 - 10',
  label: '1 - 10'
}, {
  value: '11 - 50',
  label: '11 - 50'
}, {
  value: '51 - 200',
  label: '51 - 200'
}, {
  value: '201 - 500',
  label: '201 - 500'
}, {
  value: '501 - 1,000',
  label: '501 - 1,000'
}, {
  value: '1,001 - 5,000',
  label: '1,001 - 5,000'
}, {
  value: '5,001 - 10,000',
  label: '5,001 - 10,000'
}, {
  value: '10,000+',
  label: '10,000+'
}];
export const checkboxes = [{
  label: 'Bulk Ordering',
  name: HUBSPOT_FIELDS.BULK_ORDERING
}, {
  label: 'Swag Distribution',
  name: HUBSPOT_FIELDS.SWAG_DISTRIBUTION
}, {
  label: 'Swag In a Box',
  name: HUBSPOT_FIELDS.SWAG_IN_A_BOX
}, {
  label: 'Corporate Gifting',
  name: HUBSPOT_FIELDS.SWAG_GIVEAWAY
}, {
  label: 'Company Stores',
  name: HUBSPOT_FIELDS.SWAG_COMPANY_STORES
}, {
  label: 'Swag Automation',
  name: HUBSPOT_FIELDS.SWAG_AUTOMATION
}];
export const inputs = [{
  name: HUBSPOT_FIELDS.NAME,
  label: 'Your Name*',
  validation: [required, maxLength(50), minLength(2)]
}, {
  name: HUBSPOT_FIELDS.COMPANY_NAME,
  label: 'Company Name*',
  validation: [required, maxLength(50), minLength(2)]
}, {
  name: HUBSPOT_FIELDS.EMAIL,
  label: 'Your Email*',
  validation: [required, email]
}];