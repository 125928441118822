import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { UtilApi } from '../../api/util.api';
const PREFIX = 'PAGES_';
export const GET_DONATE_WIDGET_FAIL = `${PREFIX}GET_DONATE_WIDGET_FAIL`;
export const GET_DONATE_WIDGET_SUCCESS = `${PREFIX}GET_DONATE_WIDGET_SUCCESS`;
export const getDonateWidgetData = createAsyncAction({
  fn: UtilApi.masksCount,
  success: body => {
    return {
      type: GET_DONATE_WIDGET_SUCCESS,
      payload: body
    };
  },
  error: errorAction(GET_DONATE_WIDGET_FAIL)
});
export const CHANGE_PAGE_PARAMS = `${PREFIX}_CHANGE_PAGE_PARAMS`;
export const changePageParams = (name, value) => ({
  type: CHANGE_PAGE_PARAMS,
  payload: {
    name,
    value
  }
});
export const GET_CORE_PRODUCTS_COUNT_FAIL = `${PREFIX}GET_CORE_PRODUCTS_COUNT_FAIL`;
export const GET_CORE_PRODUCTS_COUNT_SUCCESS = `${PREFIX}GET_CORE_PRODUCTS_COUNT_SUCCESS`;
export const getCoreProductsCount = createAsyncAction({
  fn: UtilApi.coreProductsCount,
  success: body => {
    return {
      type: GET_CORE_PRODUCTS_COUNT_SUCCESS,
      payload: body
    };
  },
  error: errorAction(GET_CORE_PRODUCTS_COUNT_FAIL)
});
export const CHANGE_IS_SHOW_MENU_STATE = `${PREFIX}CHANGE_IS_SHOW_MENU_STATE`;
export const changeIsShownMobMenuState = (isShown, state) => ({
  type: CHANGE_IS_SHOW_MENU_STATE,
  payload: {
    isShown,
    state
  }
});