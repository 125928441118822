function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { RESET_LOGOS_PAGINATION } from 'swag-client-common/redux/company-art-folder/company-art-folder.actions';
import { GET_LOGOS_LIST_REQUEST, GET_LOGOS_LIST_SUCCESS, GET_LOGOS_LIST_FAIL, SET_UPLOAD_LOGO_VIEW, DEFAULT_ART_FOLDER_PER_PAGE, DELETE_ART_FOLDER_LOGO_REQUEST, DELETE_ART_FOLDER_LOGO_FAIL } from './company-art-folder.actions';
const initialState = {
  loading: false,
  list: [],
  error: null,
  view: '',
  pagination: {
    page: 0,
    total: 0,
    perPage: DEFAULT_ART_FOLDER_PER_PAGE
  }
};
export const companyArtFolderReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case DELETE_ART_FOLDER_LOGO_REQUEST:
    case GET_LOGOS_LIST_REQUEST:
      {
        return _objectSpread({}, state, {
          loading: true
        });
      }
    case DELETE_ART_FOLDER_LOGO_FAIL:
      {
        return _objectSpread({}, state, {
          loading: false
        });
      }
    case GET_LOGOS_LIST_SUCCESS:
      {
        return _objectSpread({}, state, {
          list: payload.pagination.page === 1 ? [...payload.list] : [...state.list, ...payload.list],
          loading: false,
          pagination: _objectSpread({}, state.pagination, payload.pagination)
        });
      }
    case GET_LOGOS_LIST_FAIL:
      {
        return _objectSpread({}, state, initialState, {
          error: payload
        });
      }
    case SET_UPLOAD_LOGO_VIEW:
      {
        return _objectSpread({}, state, {
          view: payload
        });
      }
    case RESET_LOGOS_PAGINATION:
      {
        var _payload$perPage;
        return _objectSpread({}, state, {
          list: [],
          pagination: {
            page: 0,
            total: 0,
            perPage: (_payload$perPage = payload.perPage) !== null && _payload$perPage !== void 0 ? _payload$perPage : state.pagination.perPage
          }
        });
      }
    default:
      {
        return state;
      }
  }
};