const getTotalInStockInventoryLevelsBySku = (sku = '', supplierInventoryLevels) => {
  return supplierInventoryLevels[sku] || 0;
};
export const calculateInStockAmountForAllVariants = ({
  selectedVariants,
  supplierInventoryLevels = {},
  productIsAlwaysInStock
}) => {
  if (!selectedVariants.length || !Object.values(supplierInventoryLevels).length) {
    return null;
  }
  return selectedVariants.reduce((acc, v) => {
    const isInfinite = v.isAlwaysInStockVariant || productIsAlwaysInStock;
    if (isInfinite) {
      return Infinity;
    }
    if (v.sizeSkus && Object.values(v.sizeSkus).length) {
      const count = Object.values(v.sizeSkus).reduce((acc, sku) => acc + getTotalInStockInventoryLevelsBySku(sku, supplierInventoryLevels), 0);
      return acc + count;
    }
    return acc + getTotalInStockInventoryLevelsBySku(v.productSku, supplierInventoryLevels);
  }, 0);
};