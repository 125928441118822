function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { INVITATION_STARTED, INVITATION_SUCCESS, INVITATION_ERROR, CHECK_INVITATION_SUCCESS } from './invitation.actions';
const initialState = {
  isSuccess: false,
  error: null,
  invitationMessage: null,
  isLoading: false,
  inviteId: null,
  name: null,
  inventoryName: null
};
export const invitationReducer = (state = initialState, action) => {
  var _action$error;
  switch (action.type) {
    case INVITATION_STARTED:
      return _objectSpread({}, state, {
        error: null,
        isLoading: true
      });
    case INVITATION_SUCCESS:
      return _objectSpread({}, state, {
        error: null,
        isSuccess: true,
        isLoading: false
      });
    case INVITATION_ERROR:
      return _objectSpread({}, state, {
        error: (_action$error = action.error) === null || _action$error === void 0 ? void 0 : _action$error.message,
        isLoading: false
      });
    case CHECK_INVITATION_SUCCESS:
      {
        const {
          inviteId,
          message,
          name,
          inventoryName
        } = action.payload;
        return _objectSpread({}, state, {
          inviteId,
          name,
          inventoryName,
          invitationMessage: message
        });
      }
    default:
      return state;
  }
};