import { Price } from 'swag-client-common/utils/price';
import { getShipment, getTaxes, getTotal, getGrandTotal } from 'swag-common/utils/order';
import { ADD_ITEM_TO_CART, CHECKOUT_STARTED } from 'swag-client-common/redux/cart/cart.actions';
import { POPUP_SHOWN } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { VIDEO_POPUP } from 'swag-client-common/constants';
import { DOWNLOAD_PRESENTATION, PROCEED_ORDER_SUCCESS, PROCEED_DUTY_ORDER_SUCCESS, DOWNLOAD_INVOICE } from '../redux/checkout/checkout.actions';
import { flattenCategories, getDefaultCategory } from '../utils/data.utils';
import { FETCH_PRODUCT_SUCCESS } from '../redux/product/product.actions';
import { GET_SHIPMENT_WITH_TAXES_STARTED } from '../redux/instant-quote/instant-quote.actions';
import { ADD_LOGO } from '../redux/logo/logo.actions';
import { DESIGN_UPLOAD_SUCCESS } from '../redux/logo-editing/logo-editing.actions';
import { GO_TO_SEARCH_RESULTS_PAGE } from '../redux/search-products/search-products.actions';
export function gaTrackingMiddleware({
  getState
}) {
  return next => action => {
    switch (action.type) {
      case FETCH_PRODUCT_SUCCESS:
        {
          const {
            name,
            _id
          } = action.payload;
          const {
            productListing: {
              currentCategoryName
            }
          } = getState();
          ga('require', 'ec');
          ga('ec:addProduct', {
            id: _id,
            name,
            // Product name. Required.
            category: currentCategoryName
          });
          ga('ec:setAction', 'detail');
          ga('set', 'page', window.location.pathname);
          ga('send', 'pageview', window.location.pathname);
          ga('send', 'event', name, 'DETAIL', `opened product page for product "${name}"`);
          break;
        }
      case GET_SHIPMENT_WITH_TAXES_STARTED:
        {
          const {
            product: {
              product
            }
          } = getState();
          ga('require', 'ec');
          ga('set', 'page', window.location.pathname);
          ga('send', 'event', product.name, 'INSTANT_QUOTE', `instant quote click for "${product.name}"`);
          break;
        }
      case DESIGN_UPLOAD_SUCCESS:
        {
          const {
            product: {
              product
            }
          } = getState();
          ga('require', 'ec');
          ga('set', 'page', window.location.pathname);
          ga('send', 'event', product.name, 'DESIGN_UPLOAD', `design upload for "${product.name}"`);
          break;
        }
      case ADD_LOGO:
        {
          const {
            product: {
              product
            }
          } = getState();
          ga('require', 'ec');
          ga('set', 'page', window.location.pathname);
          ga('send', 'event', product.name, 'LOGO_UPLOAD', `logo upload for "${product.name}"`);
          break;
        }
      case ADD_ITEM_TO_CART:
        {
          const {
            addedItems,
            order: {
              items
            }
          } = action.body;
          ga('require', 'ec');
          addedItems.forEach(item => {
            const {
              productListing: {
                categories
              }
            } = getState();
            const foundItem = items.find(orderItem => orderItem.asRelatedItemId === item.asRelatedItemId);
            if (foundItem) {
              const defaultProductCategory = getDefaultCategory(foundItem.prod.categories);
              const foundCategory = categories.find(category => category._id === defaultProductCategory.categoryId);
              const productName = foundItem.prod.name;
              ga('ec:addProduct', {
                id: foundItem.prod._id,
                name: productName,
                category: foundCategory && foundCategory.name,
                sku: foundItem.prod.num,
                price: foundItem.price,
                quantity: foundItem.quantity
              });
              ga('ec:setAction', 'add');
              ga('set', 'page', window.location.pathname);
              ga('send', 'event', productName, 'ADD_TO_CART', `add to cart a product "${productName}"`); // Send data using an event.
            }
          });

          break;
        }
      case CHECKOUT_STARTED:
        {
          ga('require', 'ec');
          const {
            cart: {
              items
            },
            productListing: {
              categories
            }
          } = getState();
          items.forEach(item => {
            const defaultProductCategory = getDefaultCategory(item.prod.categories);
            const foundCategory = categories.find(category => category._id === defaultProductCategory.categoryId);
            const productName = item.prod.name;
            ga('ec:addProduct', {
              id: item.prod._id,
              name: productName,
              category: foundCategory && foundCategory.name,
              sku: item.prod.num,
              price: item.price,
              quantity: item.quantity
            });
          });
          ga('ec:setAction', 'checkout', {
            step: 1
          });
          ga('set', 'page', window.location.pathname);
          ga('send', 'pageview', window.location.pathname);
          ga('send', 'event', 'CHECKOUT_ENTER', 'CHECKOUT_ENTER', 'entered checkout');
          break;
        }
      case PROCEED_ORDER_SUCCESS:
      case PROCEED_DUTY_ORDER_SUCCESS:
        {
          const order = action.body.order;
          const {
            productListing
          } = getState();
          let categories = [];
          if (Array.isArray(productListing.categories)) {
            categories = flattenCategories(productListing.categories);
          } else {
            // eslint-disable-next-line no-console
            console.error('Categories are not array. Please check the type of categories field');
          }
          const grandTotal = getGrandTotal(order);
          const shipment = getShipment(order);
          const taxes = getTaxes(order);
          const total = getTotal(order);
          const revenue = Price.formatPrice(grandTotal - shipment, {
            addComma: false
          });
          ga('require', 'ec');
          order.items.forEach(item => {
            const defaultProductCategory = getDefaultCategory(item.prod.categories);
            const itemCategory = categories.find(c => c._id === defaultProductCategory.categoryId);
            ga('ec:addProduct', {
              id: item.prod._id,
              name: item.prod.name,
              // Product name. Required.
              category: itemCategory && itemCategory.name || 'All swag',
              // Product Category or variation.
              sku: item.prod.num,
              price: Price.formatPrice(item.price / item.quantity, {
                addComma: false
              }),
              // Product price.
              quantity: item.quantity // Product Quantity.
            });
          });

          ga('ec:setAction', 'purchase', {
            id: order.num,
            affiliation: 'swag.com',
            // Affiliation (string).
            revenue,
            // Revenue (number).
            shipping: Price.formatPrice(shipment, {
              addComma: false
            }),
            // Shipping.
            tax: Price.formatPrice(Math.round(taxes * total), {
              addComma: false
            }) // Tax.
          });

          ga('send', 'pageview', window.location.pathname);
          ga('send', 'event', order.num, 'PURCHASE', `purchase with order number "${order.num}"`);
          break;
        }
      case GO_TO_SEARCH_RESULTS_PAGE:
        {
          const {
            query
          } = action.payload;
          if (query.trim().length >= 3) {
            const formattedQuery = query.replace(' ', '+');
            ga('send', 'pageview', `/search/?query=${formattedQuery}`);
          }
          break;
        }
      case POPUP_SHOWN:
        {
          const {
            popup
          } = action.payload;
          if (popup === VIDEO_POPUP) {
            ga('send', {
              hitType: 'event',
              eventCategory: 'Video',
              eventAction: 'Play video',
              eventLabel: 'Homepage video'
            });
          }
          break;
        }
      case DOWNLOAD_PRESENTATION:
        {
          ga('send', {
            hitType: 'event',
            eventCategory: 'Download',
            eventAction: 'Download presentation',
            eventLabel: 'Download checkout PDF presentation'
          });
          break;
        }
      case DOWNLOAD_INVOICE:
        {
          ga('send', {
            hitType: 'event',
            eventCategory: 'Download',
            eventAction: 'Download invoice',
            eventLabel: 'Download checkout invoice'
          });
          break;
        }
      default:
    }
    next(action);
  };
}