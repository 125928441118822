import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fullProductsListLinkSelector, fullProductsListLabelSelector, getCurrentCategoryNameSelector, isFullRangeButtonHiddenSelector, isFilterOpenedSelector } from '../../../../redux/product-listing/product-listing.selectors';
import AdviceBanner from './advice-banner';
import adviceDispatcher from './connect/advice-banner.dispatcher';
import { dynamicContactsSelector } from './connect/advice-banner.selectors-other';
const mapStateToProps = createStructuredSelector({
  fullProductsListLabel: fullProductsListLabelSelector,
  fullProductsListLink: fullProductsListLinkSelector,
  isFilterOpened: isFilterOpenedSelector,
  currentCategoryName: getCurrentCategoryNameSelector,
  isFullRangeButtonHidden: isFullRangeButtonHiddenSelector,
  contacts: dynamicContactsSelector
});
export default connect(mapStateToProps, adviceDispatcher)(AdviceBanner);