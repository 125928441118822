function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import LocationApi from 'swag-client-common/api/location.api';
import { CONFIRM_YOUR_ADDRESS_POPUP } from 'swag-client-common/constants';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { LocationErrorCode } from 'swag-common/interfaces/location';
const prefix = 'AUTOCOMPLETE_';
export const SET_MANUAL_ADDRESS_ENTER = `${prefix}SET_MANUAL_ADDRESS_ENTER`;
export const setManualAddressEnter = payload => ({
  type: SET_MANUAL_ADDRESS_ENTER,
  payload: payload || null
});
export const GET_AUTOCOMPLETE_LIST_SUCCESS = `${prefix}GET_AUTOCOMPLETE_LIST_SUCCESS`;
export const autocompleteListSuccessAction = autocompleteList => ({
  type: GET_AUTOCOMPLETE_LIST_SUCCESS,
  payload: {
    autocompleteList
  }
});
export const SET_AUTOCOMPLETE_LIST = `${prefix}SET_AUTOCOMPLETE_LIST`;
export const resetAutocompleteListAction = () => {
  return {
    type: SET_AUTOCOMPLETE_LIST,
    payload: {
      autocompleteList: []
    }
  };
};
export const SET_SELECTED_PLACE_BY_ID = `${prefix}SET_SELECTED_PLACE_BY_ID`;
export const setSelectedPlaceById = address => ({
  type: SET_SELECTED_PLACE_BY_ID,
  payload: {
    address
  }
});
export const SET_AUTOCOMPLETE_LOADING = `${prefix}SET_LOADING`;
export function setAutocompleteLoadingAction(isLoading = true) {
  return {
    type: SET_AUTOCOMPLETE_LOADING,
    payload: {
      isLoading
    }
  };
}
const commonAsyncActionParams = {
  transformData: params => Object.entries(params).reduce((acc, [key, value]) => {
    return _objectSpread({}, acc, {
      [key]: encodeURIComponent(value)
    });
  }, {}),
  preFn: dispatch => {
    dispatch(setAutocompleteLoadingAction());
  },
  postSuccess: dispatch => dispatch(setAutocompleteLoadingAction(false)),
  error: (error, _, dispatch) => {
    if (error.code === LocationErrorCode.locationRequestError) {
      dispatch(setManualAddressEnter({
        isAddressEnteredManually: true
      }));
    }
    return setAutocompleteLoadingAction(false);
  }
};
export const getAddressesFactory = () => createAsyncAction(_objectSpread({}, commonAsyncActionParams, {
  fn: LocationApi.get,
  success: autocompleteListSuccessAction
}));
export const getAddressByPlaceFactory = () => createAsyncAction(_objectSpread({}, commonAsyncActionParams, {
  fn: LocationApi.getByPlace,
  success: autocompleteListSuccessAction
}));
export const getPlaceByIdFactory = params => createAsyncAction({
  preFn: dispatch => {
    dispatch(params.showAction());
  },
  fn: LocationApi.getPlaceById,
  success: (address, _, dispatch) => {
    dispatch(params.setAddressAction(address));
    dispatch(setSelectedPlaceById(address));
    return resetAutocompleteListAction();
  },
  postSuccess: (dispatch, getState, requestPayload, response) => {
    if (params.getByIdPostAction) {
      dispatch(params.getByIdPostAction(response));
    }
    dispatch(params.hideAction());
  },
  error: (error, _, dispatch) => {
    if (error.code === LocationErrorCode.locationRequestError) {
      dispatch(setManualAddressEnter({
        isAddressEnteredManually: true
      }));
    }
    return params.hideAction();
  }
});
export function createAutocompleteActions({
  showAction,
  hideAction,
  setAddressAction,
  getByIdPostAction
}) {
  return {
    getPlaceById: getPlaceByIdFactory({
      showAction,
      hideAction,
      setAddressAction,
      getByIdPostAction
    }),
    getAddressByPlace: getAddressByPlaceFactory(),
    getAddresses: getAddressesFactory(),
    setManualAddressEnter: setManualAddressEnter
  };
}
export function showManualAddressEnterPopup(params) {
  return showPopup(_objectSpread({
    popup: CONFIRM_YOUR_ADDRESS_POPUP
  }, params));
}