import { browserHistory } from 'react-router';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { SHARE_MOCKUP_DESIGN_DRAWER } from 'swag-client-common/constants';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { MockupApi } from '../../api/mockup.api';
import { selectedMockupsDataSelector } from './account-mockups.selectors';
const PREFIX = 'ACCOUNT_MOCKUPS_';
export const FETCH_MOCKUPS_REQUEST = `${PREFIX}FETCH_MOCKUPS_REQUEST`;
export const FETCH_MOCKUPS_SUCCESS = `${PREFIX}FETCH_MOCKUPS_SUCCESS`;
export const FETCH_MOCKUPS_ERROR = `${PREFIX}FETCH_MOCKUPS_ERROR`;
export const fetchMockups = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: FETCH_MOCKUPS_REQUEST
    });
  },
  fn: MockupApi.getUserItems,
  success: mockups => ({
    type: FETCH_MOCKUPS_SUCCESS,
    payload: {
      mockups
    }
  }),
  error: error => ({
    type: FETCH_MOCKUPS_ERROR,
    payload: {
      error
    },
    error
  })
});
export const REMOVE_MOCKUPS_REQUEST = `${PREFIX}REMOVE_MOCKUPS_REQUEST`;
export const REMOVE_MOCKUPS_SUCCESS = `${PREFIX}REMOVE_MOCKUPS_SUCCESS`;
export const REMOVE_MOCKUPS_ERROR = `${PREFIX}REMOVE_MOCKUPS_ERROR`;
export const removeMockup = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REMOVE_MOCKUPS_REQUEST
    });
  },
  fn: MockupApi.removeItem,
  success: mockups => ({
    type: REMOVE_MOCKUPS_SUCCESS,
    payload: {
      mockups
    }
  }),
  postSuccess: dispatch => {
    dispatch(fetchMockups());
  },
  error: error => ({
    type: REMOVE_MOCKUPS_ERROR,
    payload: {
      error
    },
    error
  })
});
export const TOGGLE_MOCKUP = `${PREFIX}TOGGLE_MOCKUP`;
export const toggleMockup = id => ({
  type: TOGGLE_MOCKUP,
  payload: {
    id
  }
});
export const REBUILD_CUSTOMER_MOCKUP = `${PREFIX}REBUILD_CUSTOMER_MOCKUP`;
export const editMockup = id => (dispatch, getState) => {
  const accountMockups = getState().accountMockups;
  const mockups = accountMockups.mockups.slice();
  const selectedMockup = mockups.find(mockup => mockup._id === id);
  browserHistory.push(`${APP_ROUTES.PRODUCT}/${selectedMockup.item.prodId}`);
  dispatch({
    type: REBUILD_CUSTOMER_MOCKUP,
    payload: {
      item: selectedMockup.item,
      mockupId: id
    }
  });
};
export const startSharing = () => (dispatch, getState) => {
  const selectedItems = selectedMockupsDataSelector(getState());
  const productNames = selectedItems.map(item => item.prod.name);
  dispatch(showPopup({
    popup: SHARE_MOCKUP_DESIGN_DRAWER,
    payload: {
      itemsToShare: selectedItems,
      from: 'mockups',
      productName: productNames
    }
  }));
};