export const getSuperSpeedProductIdFromLS = () => {
  return localStorage.getItem('superSpeedProductId');
};
export const getSuperSpeedFromLS = () => {
  return Boolean(getSuperSpeedProductIdFromLS());
};
export const setSuperSpeedProductIdInLS = productId => {
  !!productId && localStorage.setItem('superSpeedProductId', productId);
};
export const removeSuperSpeedProductIdFromLS = () => {
  localStorage.removeItem('superSpeedProductId');
};