import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { EmailApi } from '../../api/email.api';
const PREFIX = 'ENTERPRISE_FORM_';
export const EDIT_FIELD = PREFIX + 'EDIT_FIELD';
export const editField = (field, value) => ({
  type: EDIT_FIELD,
  field,
  value
});
export const SUBMIT_SUCCESS = PREFIX + 'SUBMIT_SUCCESS';
export const SUBMIT_STARTED = PREFIX + 'SUBMIT_STARTED';
export const SUBMIT_ERROR = PREFIX + 'SUBMIT_ERROR';
export const FORM_STATUS_RESET = PREFIX + 'FORM_STATUS_RESET';
export const submitForm = createAsyncAction({
  fn: EmailApi.enterprise,
  preFn: dispatch => {
    dispatch({
      type: SUBMIT_STARTED
    });
  },
  success: () => ({
    type: SUBMIT_SUCCESS
  }),
  error: error => ({
    type: SUBMIT_ERROR,
    error
  }),
  postSuccess: dispatch => {
    setTimeout(() => {
      dispatch({
        type: FORM_STATUS_RESET
      });
    }, 3000);
  }
});