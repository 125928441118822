function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { SET_PASSWORD_LOADING, SET_PASSWORD_ERROR, SET_PASSWORD_SUCCESS } from 'swag-client-common/redux/user/user.actions';
import { modeEnum } from 'swag-client-common/components/partials/set-password-form/set-password-form.interfaces';
import { TOKEN, SET_PASSWORD_LOADING as RESET_PASSWORD_LOADING, SET_PASSWORD_ERROR as RESET_PASSWORD_ERROR, SET_PASSWORD_SUCCESS as RESET_PASSWORD_SUCCESS } from '../set-password/set-password.actions';
const initialState = {
  isLoading: false,
  isSuccess: false,
  err: null,
  token: '',
  mode: modeEnum.forgotPassword
};
export default function setPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case TOKEN:
      {
        const {
          token,
          mode
        } = action;
        return _objectSpread({}, state, {
          token,
          mode
        });
      }
    case RESET_PASSWORD_LOADING:
    case SET_PASSWORD_LOADING:
      {
        return _objectSpread({}, state, {
          isLoading: true,
          err: null
        });
      }
    case RESET_PASSWORD_ERROR:
    case SET_PASSWORD_ERROR:
      {
        return _objectSpread({}, state, {
          isLoading: false,
          err: {
            type: action.err.type,
            code: action.err.code,
            message: action.err.message
          }
        });
      }
    case RESET_PASSWORD_SUCCESS:
    case SET_PASSWORD_SUCCESS:
      {
        return _objectSpread({}, state, {
          isLoading: false,
          err: null,
          isSuccess: true
        });
      }
    default:
      return state;
  }
}