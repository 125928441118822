import _sortBy from "lodash/sortBy";
import { getFullBox, isBox } from '../../utils/order/item-is-box.util';
export function isOrderMoreItemsInitial(before, after) {
  if (!before || !after) {
    return {};
  }
  const modificationsMap = {};
  const regularItems = after.filter(i => !i.boxItemsId);
  const boxItems = after.filter(i => Boolean(i.boxItemsId) && !isBox(i));
  const boxesItself = after.filter(isBox);
  if (!after.length) {
    return {};
  }
  regularItems.forEach(item => {
    const itemSliceBeforeEditing = item.customProductId && before[item.customProductId];
    if (itemSliceBeforeEditing) {
      const isLogosValid = areLogosTheSame(itemSliceBeforeEditing.logos, item.logos);
      const isEmbellishmentMethodValid = checkEmbellishmentMethod(itemSliceBeforeEditing, item);
      const isSizeValid = checkSize(itemSliceBeforeEditing, item);
      modificationsMap[String(item._id)] = isLogosValid && isEmbellishmentMethodValid && isSizeValid;
    }
  });
  boxItems.forEach(item => {
    const itemSliceBeforeEditing = before[`${item.customProductId}:${item.boxItemsId}`];
    const fullBox = getFullBox(item, after);
    const beforeBoxItems = Object.keys(before).filter(key => item.boxItemsId && key.includes(item.boxItemsId));
    const boxItem = boxesItself.find(_item => _item.boxItemsId === item.boxItemsId);
    if (beforeBoxItems.length !== fullBox.length && boxItem) {
      modificationsMap[String(boxItem._id)] = false;
    }
    if (itemSliceBeforeEditing) {
      const isQuantityValid = checkQuantityPerBox(itemSliceBeforeEditing, item);
      const isLogosValid = areLogosTheSame(itemSliceBeforeEditing.logos, item.logos);
      const isSizeValid = checkSize(itemSliceBeforeEditing, item);
      const isVariantsValid = checkVariants(itemSliceBeforeEditing, item);
      const isEmbellishmentMethodValid = checkEmbellishmentMethod(itemSliceBeforeEditing, item);

      // if product has different color, it should affect only box item
      if (!isVariantsValid && boxItem) {
        modificationsMap[String(boxItem._id)] = false;
      }
      modificationsMap[String(item._id)] = isQuantityValid && isLogosValid && isSizeValid && isEmbellishmentMethodValid && isVariantsValid;
    }
  });
  boxesItself.filter(item => !Object.prototype.hasOwnProperty.call(modificationsMap, String(item._id))).forEach(item => {
    const itemSliceBeforeEditing = before[`${item.customProductId}:${item.boxItemsId}`];
    const isBoxTypeValid = checkBoxType(itemSliceBeforeEditing, item);
    modificationsMap[String(item._id)] = isBoxTypeValid && boxItems.filter(i => i.boxItemsId === item.boxItemsId).every(i => modificationsMap[String(i._id)]) && item.customProductCreated;
  });
  return modificationsMap;
}
function checkBoxType(before, after) {
  if (!before) {
    return true;
  }
  return before.itemSku === after.itemSku;
}
function checkQuantityPerBox(before, after) {
  return Number(before.quantityPerBox) === Number(after.quantityPerBox) || isBox(after);
}
function checkSize(before, after) {
  return Number(before.sizeInd) === Number(after.sizeInd);
}
export function checkVariants(before, after) {
  var _beforeVariants$, _afterVariants$;
  if (!before.variants && !after.variants) {
    return true;
  }
  const beforeVariants = before.variants || [];
  const afterVariants = after.variants || [];
  const beforeLength = beforeVariants.length;
  const afterLength = afterVariants.length;
  const isLengthSimilar = afterLength === 1 && beforeLength === 1;
  if (!beforeVariants[0] && afterVariants[0].variantIndex) {
    return isLengthSimilar;
  }
  const isVariantsSimilar = ((_beforeVariants$ = beforeVariants[0]) === null || _beforeVariants$ === void 0 ? void 0 : _beforeVariants$.variantIndex) === ((_afterVariants$ = afterVariants[0]) === null || _afterVariants$ === void 0 ? void 0 : _afterVariants$.variantIndex);
  return isLengthSimilar && isVariantsSimilar;
}
function checkEmbellishmentMethod(before, after) {
  if (!before.embellishmentMethod && !after.embellishmentMethod) {
    return true;
  }
  return before.embellishmentMethod === after.embellishmentMethod;
}
export function areLogosTheSame(beforeLogos, afterLogos) {
  if (!beforeLogos && !afterLogos) {
    return true;
  }
  const allBeforeLogos = beforeLogos || {};
  const allAfterLogos = afterLogos || {};
  const beforeSidesCount = Object.keys(allBeforeLogos).length;
  const beforeLogosCount = Object.values(allBeforeLogos).reduce((total, cur) => total + cur.length, 0);
  const afterSidesCount = Object.keys(allAfterLogos).length;
  const afterLogosCount = Object.values(allAfterLogos).reduce((total, cur) => total + cur.length, 0);
  const isLogosSizeTheSame = Object.keys(allAfterLogos).every(side => {
    const beforeLogos = allBeforeLogos[side] || [];
    const afterLogos = allAfterLogos[side] || [];
    const afterSideLogos = JSON.stringify(afterLogos.map(getLogoSlice));
    const beforeSideLogos = JSON.stringify(beforeLogos.map(getLogoSlice));
    return beforeSideLogos === afterSideLogos;
  });
  return beforeSidesCount === afterSidesCount && beforeLogosCount === afterLogosCount && isLogosSizeTheSame;
}
function getLogoSlice(logo) {
  const {
    colors
  } = logo;
  const logoSlice = {
    colors: {
      colorsToPrint: _sortBy((colors === null || colors === void 0 ? void 0 : colors.colorsToPrint) || []),
      selectedColorsPMS: _sortBy(((colors === null || colors === void 0 ? void 0 : colors.selectedColors) || []).map(({
        pms
      }) => pms)),
      selectedColorsHEX: _sortBy(((colors === null || colors === void 0 ? void 0 : colors.selectedColors) || []).map(({
        hex
      }) => hex))
    }
  };
  return logoSlice;
}