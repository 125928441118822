import _isFinite from "lodash/isFinite";
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
export const checkItemsHaveSkuQtyIssue = ({
  lvls,
  splitter,
  items,
  testedProdIds
}) => {
  const totalQtyPerSku = getTotalQtyPerSku({
    items,
    testedProdIds,
    splitter
  });
  let hasIssue = false;
  for (const key in totalQtyPerSku) {
    const [prodId, sku] = key.split(splitter);
    const lvl = lvls === null || lvls === void 0 ? void 0 : lvls.find(lvl => String(lvl.productId) === prodId && lvl.variantSku === sku);
    if (_isFinite(lvl === null || lvl === void 0 ? void 0 : lvl.inStock) && lvl && lvl.inStock < totalQtyPerSku[key]) {
      hasIssue = true;
    }
  }
  return hasIssue;
};
const getTotalQtyPerSku = ({
  items,
  splitter,
  testedProdIds
}) => items.reduce((acc, item) => {
  var _item$prod, _item$prod2, _item$prod3, _item$prod3$productSi, _item$prod4, _item$variants;
  const prodId = String((_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod._id);
  const num = (_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : _item$prod2.num;
  const isFD = isProductFullDesign(item.prod);
  const sizeOptions = ((_item$prod3 = item.prod) === null || _item$prod3 === void 0 ? void 0 : (_item$prod3$productSi = _item$prod3.productSize) === null || _item$prod3$productSi === void 0 ? void 0 : _item$prod3$productSi.options) || [];
  const hasSize = !!((_item$prod4 = item.prod) !== null && _item$prod4 !== void 0 && _item$prod4.hasSize);
  if (!testedProdIds.includes(prodId)) {
    return acc;
  }
  (_item$variants = item.variants) === null || _item$variants === void 0 ? void 0 : _item$variants.forEach(({
    variantIndex,
    quantities
  }) => {
    if (isFD) {
      const key = `${prodId}${splitter}${num}`;
      const isInAcc = (key in acc);
      acc[key] = isInAcc ? acc[key] + quantities[0] : quantities[0];
    } else {
      var _item$prod5, _item$prod5$variants;
      const prodVariant = (_item$prod5 = item.prod) === null || _item$prod5 === void 0 ? void 0 : (_item$prod5$variants = _item$prod5.variants) === null || _item$prod5$variants === void 0 ? void 0 : _item$prod5$variants[variantIndex];
      if (prodVariant) {
        if (hasSize && prodVariant.sizeSkus) {
          sizeOptions.forEach((size, ind) => {
            var _prodVariant$sizeSkus;
            const sku = (_prodVariant$sizeSkus = prodVariant.sizeSkus) === null || _prodVariant$sizeSkus === void 0 ? void 0 : _prodVariant$sizeSkus[size];
            const key = `${prodId}${splitter}${sku}`;
            const isInAcc = (key in acc);
            if (quantities[ind] && sku) {
              acc[key] = isInAcc ? acc[key] + quantities[ind] : quantities[ind];
            }
          });
        } else {
          const key = `${prodId}${splitter}${prodVariant.productSku}`;
          const isInAcc = (key in acc);
          acc[key] = isInAcc ? acc[key] + quantities[0] : quantities[0];
        }
      }
    }
  });
  return acc;
}, {});