import _sumBy2 from "lodash/sumBy";
import _isEqual2 from "lodash/isEqual";
import _omit2 from "lodash/omit";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTotalCount } from 'swag-client-common/utils/product.utils';
import { sortBoxContentInSwagItems } from 'swag-common/business-logic/order/get-order-of-box-content-items.logic';
import { isBox, isItemBoxAddon, isItemBoxSpecialCrinklePaper, isItemBoxSpecialNotecard, isItemBoxSpecialTape } from 'swag-common/utils/order/item-is-box.util';
import { getSharedItemsWithItemItself } from 'swag-common/business-logic/items/get-shared-items-with-item-itself.logic';
import { subtractQuantities } from 'swag-client-common/utils/order-item.utils';
import { makeFakeAddonItemForCart } from './box-builder.utils';
import { createSplittedItems, getNewSplittedItemForNonBox, getNewSplittedItemsForBoxByVariant } from './add-box-to-cart';
import { getNonBoxReminderInCart, isExistNonBoxReminderInCart, moveBoxItemsFromBox } from './remove-box-items';
import { getBlendedQuantity, getCartItemWithEditedQuantities } from './box-builder.selectors';
export const editBoxItems = ({
  cartItems,
  productCardItems,
  addonCardItems,
  addonList,
  currentBoxesQuantity,
  boxItemsId,
  editedQuantityInCart
}) => {
  const itemsDeleteFromCartIds = [];
  const newBoxItems = [];
  const newCartItems = getSortedByBoxItemsId(cartItems).reduce((acc, cartItem) => {
    const cartItemWithEditedQuantity = getCartItemWithEditedQuantities(cartItem, cartItems, editedQuantityInCart, currentBoxesQuantity, boxItemsId);
    const productCardsThatHaveCreatedCartQuantity = getProductCardsThatHaveCreatedCartQuantity({
      cartItem,
      productCardItems,
      boxItemsId,
      cartItems
    });
    if (isItemBoxAddon(cartItemWithEditedQuantity)) {
      if (isItemShouldBeDeletedFromBox({
        cartItem,
        productCardItems: addonCardItems,
        boxItemsId
      })) {
        itemsDeleteFromCartIds.push((cartItemWithEditedQuantity === null || cartItemWithEditedQuantity === void 0 ? void 0 : cartItemWithEditedQuantity._id) || '');
        acc.push(cartItemWithEditedQuantity);
        return acc;
      } else {
        //addon was edited

        const info = addonCardItems.find(addon => cartItemWithEditedQuantity.boxItemsId === boxItemsId && addon.itemId === cartItemWithEditedQuantity.prodId && addon.isSelected);
        if (!info) {
          acc.push(cartItem);
          return acc;
        }
        acc.push(_objectSpread({}, cartItem, {
          blendedQuantity: info.quantityPerBox * currentBoxesQuantity,
          quantity: info.quantityPerBox * currentBoxesQuantity,
          quantityPerBox: info.quantityPerBox,
          variants: [{
            quantities: [info.quantityPerBox * currentBoxesQuantity],
            variantIndex: 0
          }]
        }));
        return acc;
      }
    }
    if (isItemEditedBox(cartItemWithEditedQuantity, boxItemsId)) {
      return [...acc, cartItemWithEditedQuantity];
    }
    if (isItemNeverBeenSelectedForBox({
      cartItem: cartItemWithEditedQuantity,
      productCardItems
    })) {
      //if item is not selected in past and now
      return [...acc, cartItemWithEditedQuantity];
    } else if (isItemShouldBeDeletedFromBox({
      cartItem: cartItemWithEditedQuantity,
      productCardItems,
      boxItemsId
    })) {
      //if item is unselected from box
      if (cartItemWithEditedQuantity._id) {
        itemsDeleteFromCartIds.push(cartItemWithEditedQuantity._id);
      }
      return [...acc, cartItemWithEditedQuantity];
    } else if (isItemSelectedAsNewForBox({
      cartItem: cartItemWithEditedQuantity,
      productCardItems
    })) {
      //if item is selected new for box
      const {
        splittedNonBoxQuantity = 0,
        splittedNonBoxVariants = [],
        splittedBoxVariants = []
      } = createSplittedItems({
        cartItem: cartItemWithEditedQuantity,
        productCardItems,
        currentBoxesQuantity
      });
      if (splittedBoxVariants.length) {
        newBoxItems.push(...getNewSplittedItemsForBoxByVariant({
          splittedBoxVariants,
          cartItem: cartItemWithEditedQuantity,
          currentBoxesQuantity,
          boxItemsId
        }));
      }
      if (splittedNonBoxQuantity) {
        acc.push(getNewSplittedItemForNonBox({
          cartItem: cartItemWithEditedQuantity,
          splittedNonBoxVariants,
          splittedNonBoxQuantity
        }));
      }
      return acc;
    } else if (isItemInBoxWasEdited({
      cartItem,
      productCardItems,
      boxItemsId
    })) {
      var _splittedNonBoxVarian;
      // if was edited quantityPerBox or quantity per size
      const nonBoxRemainderItem = getNonBoxReminderInCart({
        alreadyHandledItems: acc,
        cartItems,
        cartItem: cartItemWithEditedQuantity
      });
      const {
        splittedNonBoxQuantity = 0,
        splittedNonBoxVariants = [],
        splittedBoxVariants = []
      } = createSplittedItems({
        cartItem: cartItemWithEditedQuantity,
        productCardItems,
        currentBoxesQuantity
      });

      // if not exist reminder but should be created
      if (!nonBoxRemainderItem && getTotalCount((_splittedNonBoxVarian = splittedNonBoxVariants[0]) === null || _splittedNonBoxVarian === void 0 ? void 0 : _splittedNonBoxVarian.quantities)) {
        const newItem = getNewSplittedItemForNonBox({
          cartItem: cartItemWithEditedQuantity,
          splittedNonBoxVariants,
          splittedNonBoxQuantity
        });
        acc.unshift(_omit2(newItem, '_id'));
      }
      const newAcc = acc.reduce((res, item) => {
        //if reminder exist
        if (nonBoxRemainderItem && item._id === nonBoxRemainderItem._id) {
          const newReminderVariants = getNewReminderVariants({
            nonBoxRemainderItem,
            splittedNonBoxVariants
          });
          if (!newReminderVariants.length) {
            // should be deleted, because all was selected to box
            return res;
          } else {
            //should be updated
            return [...res, _objectSpread({}, item, {
              quantity: getTotalCount(newReminderVariants.map(v => getTotalCount(v.quantities))),
              variants: newReminderVariants
            })];
          }
        } else {
          return [...res, item];
        }
      }, []);
      return [...newAcc, ...getNewSplittedItemsForBoxByVariant({
        splittedBoxVariants,
        cartItem: cartItemWithEditedQuantity,
        currentBoxesQuantity,
        boxItemsId,
        isEditMode: true
      })];
    } else if (productCardsThatHaveCreatedCartQuantity.length > 0) {
      const newlyCreatedStandaloneItems = productCardsThatHaveCreatedCartQuantity.map(productCardItem => {
        const newlyCreatedStandaloneItem = getNewlyCreatedStandaloneItems({
          productCardItem,
          cartItemWithEditedQuantity
        });
        acc.unshift(newlyCreatedStandaloneItem);
        return newlyCreatedStandaloneItem;
      });
      acc.push(getCurrentBoxItemRegardingNewlyCreatedStandaloneItems({
        newlyCreatedStandaloneItems,
        cartItemWithEditedQuantity
      }));
      return acc;
    } else if (isItemInBoxWasNotEditedButReminderChangedFromCartQuantityPopup({
      cartItem,
      cartItems,
      cartItemWithEditedQuantity,
      productCardItems,
      boxItemsId
    })) {
      const nonBoxRemainderItem = getNonBoxReminderInCart({
        alreadyHandledItems: acc,
        cartItems,
        cartItem: cartItemWithEditedQuantity
      });
      const {
        splittedNonBoxVariants = [],
        splittedBoxVariants = []
      } = createSplittedItems({
        cartItem: cartItemWithEditedQuantity,
        productCardItems,
        currentBoxesQuantity
      });
      const newAcc = acc.reduce((res, item) => {
        if (nonBoxRemainderItem && item._id === nonBoxRemainderItem._id) {
          const newReminderVariants = getNewReminderVariants({
            nonBoxRemainderItem,
            splittedNonBoxVariants
          });
          if (splittedNonBoxVariants !== null && splittedNonBoxVariants !== void 0 && splittedNonBoxVariants[0] && !newReminderVariants.some(v => v.variantIndex === splittedNonBoxVariants[0].variantIndex)) {
            //add as multicolor if no matched variantIndex
            newReminderVariants.push(splittedNonBoxVariants[0]);
          }
          if (!newReminderVariants.length) {
            // should be deleted, because all was selected to box
            return res;
          }
          const quantity = getTotalCount(newReminderVariants.map(v => getTotalCount(v.quantities)));
          if (quantity <= 0) {
            return res;
          }
          //should be updated
          return [...res, _objectSpread({}, item, {
            quantity: getTotalCount(newReminderVariants.map(v => getTotalCount(v.quantities))),
            variants: newReminderVariants
          })];
        }
        return [...res, item];
      }, []);
      return [...newAcc, ...getNewSplittedItemsForBoxByVariant({
        boxItemsId,
        splittedBoxVariants,
        cartItem: cartItemWithEditedQuantity,
        currentBoxesQuantity: currentBoxesQuantity || 0,
        isEditMode: true
      })];
    } else if (isItemInBoxCrinklePaperOrNotecardOrTape({
      cartItem,
      boxItemsId
    })) {
      return [...acc, _objectSpread({}, cartItem, {
        quantity: currentBoxesQuantity,
        blendedQuantity: currentBoxesQuantity,
        variants: cartItem.variants.map(v => _objectSpread({}, v, {
          quantities: [currentBoxesQuantity]
        }))
      })];
    } else {
      return [...acc, cartItemWithEditedQuantity];
    }
  }, []);
  if (addonCardItems !== null && addonCardItems !== void 0 && addonCardItems.length && addonList !== null && addonList !== void 0 && addonList.length) {
    newBoxItems.push(...addonCardItems.reduce((acc, addonCardItem) => {
      var _newCartItems$find;
      const isInCart = ((_newCartItems$find = newCartItems.find(i => i.prodId === addonCardItem.itemId)) === null || _newCartItems$find === void 0 ? void 0 : _newCartItems$find.boxItemsId) === boxItemsId;
      if (isInCart) {
        return acc;
      }
      const prod = addonList.find(addon => addon._id === addonCardItem.itemId);
      const addon = makeFakeAddonItemForCart({
        prod,
        info: addonCardItem,
        currentBoxesQuantity
      });
      const addonInCart = newCartItems.find(({
        _id
      }) => _id === addonCardItem.itemId);
      //add new addons
      return addonCardItem.isSelected && !addonInCart ? [...acc, _objectSpread({}, addon, {
        boxItemsId
      })] : acc;
    }, []));
  }
  const cartWithUnselectedBoxItems = moveBoxItemsFromBox({
    cartItems: newCartItems,
    itemsDeleteFromCartIds,
    newBoxItems
  });
  const cartWithSortedBoxItems = getCartWithSortedBoxItems({
    cartWithUnselectedBoxItems,
    newBoxItems,
    boxItemsId
  });
  return getCartItemsWithCorrectBlendedQuantities(cartWithSortedBoxItems);
};

// helpers ------------------------------------------

const isItemInBoxCrinklePaperOrNotecardOrTape = ({
  cartItem,
  boxItemsId
}) => {
  const isSameBox = cartItem.boxItemsId === boxItemsId;
  const isProductWithSpecialTypeForBox = isItemBoxSpecialCrinklePaper(cartItem) || isItemBoxSpecialNotecard(cartItem) || isItemBoxSpecialTape(cartItem);
  return isSameBox && isProductWithSpecialTypeForBox;
};
const isItemInBoxWasNotEditedButReminderChangedFromCartQuantityPopup = ({
  cartItem,
  cartItems,
  cartItemWithEditedQuantity,
  productCardItems,
  boxItemsId
}) => {
  if (!isExistNonBoxReminderInCart({
    cartItem,
    cartItems
  })) {
    return false;
  }
  if (isItemInBoxWasEdited({
    cartItem,
    productCardItems,
    boxItemsId
  })) {
    return false;
  }
  const productCardItem = productCardItems.find(c => c.itemId === cartItem._id);
  const notEditedVariant = cartItem.variants.find(v => {
    var _productCardItem$vari;
    return v.variantIndex === (productCardItem === null || productCardItem === void 0 ? void 0 : (_productCardItem$vari = productCardItem.variant) === null || _productCardItem$vari === void 0 ? void 0 : _productCardItem$vari.variantIndex);
  });
  const editedVariant = cartItemWithEditedQuantity.variants.find(v => {
    var _productCardItem$vari2;
    return v.variantIndex === (productCardItem === null || productCardItem === void 0 ? void 0 : (_productCardItem$vari2 = productCardItem.variant) === null || _productCardItem$vari2 === void 0 ? void 0 : _productCardItem$vari2.variantIndex);
  });
  if (!productCardItem || !notEditedVariant || !editedVariant) {
    return false;
  }
  if (_isEqual2(notEditedVariant.quantities, editedVariant.quantities)) {
    return false;
  }
  return true;
};
function getSplittedNonBoxQuantity({
  remainingQuantityInCart,
  totalQuantityInCart
}) {
  if (totalQuantityInCart === null) {
    return remainingQuantityInCart || 0;
  }
  return totalQuantityInCart || 0;
}
const getProductCardItem = (productCardItems, cartItem) => {
  const productInCart = productCardItems.find(({
    itemId,
    isSelected
  }) => itemId === cartItem._id && isSelected);
  return productInCart || null;
};
const getCartWithSortedBoxItems = ({
  cartWithUnselectedBoxItems,
  newBoxItems,
  boxItemsId
}) => {
  const boxItems = cartWithUnselectedBoxItems.filter(i => i.boxItemsId === boxItemsId);
  return cartWithUnselectedBoxItems.reduce((acc, item) => {
    if (item.boxItemsId === boxItemsId && isBox(item)) {
      const sortedItems = sortBoxContentInSwagItems([...boxItems, ...newBoxItems]);
      acc.push(...sortedItems);
      return acc;
    } else if (item.boxItemsId === boxItemsId && !isBox(item)) {
      return acc;
    }
    acc.push(item);
    return acc;
  }, []);
};
function getCartItemsWithCorrectBlendedQuantities(items) {
  return items.map(cartItem => {
    const sharedItems = getSharedItemsWithItemItself(cartItem, items);
    const blendedQuantity = getBlendedQuantity(cartItem, sharedItems);
    return _objectSpread({}, cartItem, {
      blendedQuantity
    });
  });
}
function isItemInBoxWasEdited({
  cartItem,
  productCardItems,
  boxItemsId
}) {
  if (cartItem.boxItemsId !== boxItemsId) {
    return false;
  }
  return productCardItems.some(productCardItem => {
    var _cartItem$variants;
    if (!productCardItem.isSelected || productCardItem.itemId !== cartItem._id) {
      return false;
    }
    const currentVariant = (_cartItem$variants = cartItem.variants) === null || _cartItem$variants === void 0 ? void 0 : _cartItem$variants.find(v => {
      var _productCardItem$vari3;
      return v.variantIndex === ((_productCardItem$vari3 = productCardItem.variant) === null || _productCardItem$vari3 === void 0 ? void 0 : _productCardItem$vari3.variantIndex);
    });
    if (productCardItem.quantityPerBox !== cartItem.quantityPerBox) {
      return true;
    }
    return !_isEqual2(productCardItem.quantitiesItem, (currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.quantities) || productCardItem.quantitiesItem);
  });
}
function getProductCardsThatHaveCreatedCartQuantity({
  cartItem,
  cartItems,
  productCardItems,
  boxItemsId
}) {
  if (cartItem.boxItemsId !== boxItemsId) {
    return [];
  }
  return productCardItems.filter(productCardItem => {
    if (!productCardItem.isSelected) {
      return false;
    }
    if (productCardItem.itemId !== cartItem._id) {
      return false;
    }
    const standaloneSharedItem = cartItems.find(item => item.asRelatedItemId === cartItem.asRelatedItemId && !item.boxItemsId);
    if (standaloneSharedItem) {
      return false;
    }
    return getTotalCount(productCardItem.cartQuantities) > 0;
  });
}
const isItemNeverBeenSelectedForBox = ({
  cartItem,
  productCardItems
}) => {
  const productCardItem = getProductCardItem(productCardItems, cartItem);
  return !cartItem.boxItemsId && !productCardItem;
};
const isItemShouldBeDeletedFromBox = ({
  cartItem,
  productCardItems,
  boxItemsId
}) => {
  const isIdsEqual = productCardItem => isItemBoxAddon(cartItem) ? productCardItem.itemId === cartItem.prodId : productCardItem.itemId === cartItem._id;
  const productCardItem = productCardItems.find(productCardItem => isIdsEqual(productCardItem) && !productCardItem.isSelected);
  return Boolean(cartItem.boxItemsId && cartItem.boxItemsId === boxItemsId && productCardItem);
};
const isItemSelectedAsNewForBox = ({
  cartItem,
  productCardItems
}) => {
  const productCardItem = getProductCardItem(productCardItems, cartItem);
  return Boolean(!cartItem.boxItemsId && productCardItem);
};
const isItemEditedBox = (cartItem, boxItemsId) => {
  return Boolean(cartItem.boxItemsId === boxItemsId && isBox(cartItem));
};
const getSortedByBoxItemsId = cartItems => [...cartItems.filter(i => !i.boxItemsId), ...cartItems.filter(i => i.boxItemsId)];
function getNewlyCreatedStandaloneItems({
  cartItemWithEditedQuantity,
  productCardItem
}) {
  var _productCardItem$vari4;
  const variantIndex = (productCardItem === null || productCardItem === void 0 ? void 0 : (_productCardItem$vari4 = productCardItem.variant) === null || _productCardItem$vari4 === void 0 ? void 0 : _productCardItem$vari4.variantIndex) || 0;
  return _omit2(getNewSplittedItemForNonBox({
    cartItem: cartItemWithEditedQuantity,
    splittedNonBoxVariants: [{
      variantIndex,
      quantities: productCardItem.cartQuantities
    }],
    splittedNonBoxQuantity: getSplittedNonBoxQuantity({
      remainingQuantityInCart: productCardItem.remainingQuantityInCart,
      totalQuantityInCart: productCardItem.totalQuantityInCart
    })
  }), '_id');
}
function getCurrentBoxItemRegardingNewlyCreatedStandaloneItems({
  cartItemWithEditedQuantity,
  newlyCreatedStandaloneItems
}) {
  return _objectSpread({}, cartItemWithEditedQuantity, {
    variants: cartItemWithEditedQuantity.variants.map(variant => {
      const newlyCreatedStandaloneItemOfCurrentVariant = newlyCreatedStandaloneItems.find(newlyCreatedStandaloneItem => {
        return newlyCreatedStandaloneItem.variants.some(innerVariant => innerVariant.variantIndex === variant.variantIndex);
      });
      if (!newlyCreatedStandaloneItemOfCurrentVariant) {
        return variant;
      }
      const currentVariant = newlyCreatedStandaloneItemOfCurrentVariant.variants.find(innerVariant => innerVariant.variantIndex === variant.variantIndex);
      if (!currentVariant) {
        return variant;
      }
      return _objectSpread({}, variant, {
        quantities: subtractQuantities({
          decreasingQuantity: variant.quantities,
          subtractionQuantity: currentVariant.quantities
        }, false)
      });
    }),
    quantity: cartItemWithEditedQuantity.quantity - _sumBy2(newlyCreatedStandaloneItems, item => item.quantity)
  });
}
const getNewReminderVariants = ({
  nonBoxRemainderItem,
  splittedNonBoxVariants
}) => {
  const isVariantIndexExistInNonBoxRemainderItem = nonBoxRemainderItem.variants.some(v => {
    var _splittedNonBoxVarian2;
    return v.variantIndex === (splittedNonBoxVariants === null || splittedNonBoxVariants === void 0 ? void 0 : (_splittedNonBoxVarian2 = splittedNonBoxVariants[0]) === null || _splittedNonBoxVarian2 === void 0 ? void 0 : _splittedNonBoxVarian2.variantIndex);
  });
  const nonBoxRemainderItemVariants = nonBoxRemainderItem.variants.filter(Boolean);
  return isVariantIndexExistInNonBoxRemainderItem ? nonBoxRemainderItemVariants.reduce((acc, v) => {
    var _splittedNonBoxVarian3;
    if (v.variantIndex === (splittedNonBoxVariants === null || splittedNonBoxVariants === void 0 ? void 0 : (_splittedNonBoxVarian3 = splittedNonBoxVariants[0]) === null || _splittedNonBoxVarian3 === void 0 ? void 0 : _splittedNonBoxVarian3.variantIndex)) {
      const newQuantities = v.quantities.map((value, index) => {
        var _splittedNonBoxVarian4, _splittedNonBoxVarian5;
        return value + (splittedNonBoxVariants === null || splittedNonBoxVariants === void 0 ? void 0 : (_splittedNonBoxVarian4 = splittedNonBoxVariants[0]) === null || _splittedNonBoxVarian4 === void 0 ? void 0 : (_splittedNonBoxVarian5 = _splittedNonBoxVarian4.quantities) === null || _splittedNonBoxVarian5 === void 0 ? void 0 : _splittedNonBoxVarian5[index]) || 0;
      });
      return newQuantities.every(q => q === 0) ? acc : [...acc, _objectSpread({}, v, {
        quantities: newQuantities
      })];
    }
    return [...acc, v];
  }, []) : [...nonBoxRemainderItemVariants, ...splittedNonBoxVariants];
};