function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { SHARE_MOCKUP_DESIGN_DRAWER } from 'swag-client-common/constants';
import { POPUP_SHOWN } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { EDIT_EMAIL, SELECT_FIELD, SEND_MOCKUP_FAILURE, SEND_MOCKUP_REQUEST, SEND_MOCKUP_SUCCESS } from './share-design.actions';
const initialState = {
  itemsToShare: null,
  customerMockups: true,
  descr: false,
  prodTime: false,
  price: false,
  specs: false,
  quantity: false,
  email: '',
  error: null,
  from: null,
  productName: null
};
export default function (state = initialState, action) {
  switch (action.type) {
    case POPUP_SHOWN:
      {
        const {
          popup
        } = action.payload;
        if (popup === SHARE_MOCKUP_DESIGN_DRAWER) {
          const {
            itemsToShare,
            from,
            productName
          } = action.payload.params.payload;
          return _objectSpread({}, state, {
            itemsToShare,
            from,
            productName
          });
        }
        return state;
      }
    case EDIT_EMAIL:
      {
        const {
          email
        } = action.payload;
        return _objectSpread({}, state, {
          email
        });
      }
    case SELECT_FIELD:
      {
        const {
          value,
          field
        } = action.payload;
        return _objectSpread({}, state, {
          [field]: value
        });
      }
    case SEND_MOCKUP_REQUEST:
      {
        return _objectSpread({}, state, {
          error: null
        });
      }
    case SEND_MOCKUP_SUCCESS:
      {
        return initialState;
      }
    case SEND_MOCKUP_FAILURE:
      {
        const {
          error
        } = action;
        return _objectSpread({}, state, {
          error
        });
      }
    default:
      return state;
  }
}