import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import UserApi from 'swag-client-common/api/user.api';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { showPredefinedNotification } from 'swag-client-common/redux/notification/notification.actions';
import { NOTIFICATIONS } from 'swag-client-common/redux/notification/notification.constants';
import CompanyDepartmentApi from '../../api/company-department.api';
const PREFIX = 'COMPANY_DEPARTMENTS_';
export const FETCH_COMPANY_DEPARTMENTS_REQUEST = `${PREFIX}FETCH_COMPANY_DEPARTMENTS_REQUEST`;
export const FETCH_COMPANY_DEPARTMENTS_SUCCESS = `${PREFIX}FETCH_COMPANY_DEPARTMENTS_SUCCESS`;
export const FETCH_COMPANY_DEPARTMENTS_ERROR = `${PREFIX}FETCH_COMPANY_DEPARTMENTS_ERROR`;
export const fetchCompanyDepartments = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: FETCH_COMPANY_DEPARTMENTS_REQUEST
    });
  },
  fn: CompanyDepartmentApi.getCompanyDepartments,
  success: departments => ({
    type: FETCH_COMPANY_DEPARTMENTS_SUCCESS,
    payload: departments
  }),
  error: error => ({
    type: FETCH_COMPANY_DEPARTMENTS_ERROR,
    payload: error,
    error
  })
});
export const FETCH_COMPANY_USERS_REQUEST = `${PREFIX}FETCH_COMPANY_USERS_REQUEST`;
export const FETCH_COMPANY_USERS_SUCCESS = `${PREFIX}FETCH_COMPANY_USERS_SUCCESS`;
export const FETCH_COMPANY_USERS_ERROR = `${PREFIX}FETCH_COMPANY_USERS_ERROR`;
export const fetchCompanyUsers = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: FETCH_COMPANY_USERS_REQUEST
    });
  },
  fn: UserApi.getByCompany,
  success: departments => ({
    type: FETCH_COMPANY_USERS_SUCCESS,
    payload: departments
  }),
  error: error => ({
    type: FETCH_COMPANY_USERS_ERROR,
    payload: error,
    error
  })
});
export const CREATE_COMPANY_DEPARTMENT_REQUEST = `${PREFIX}CREATE_COMPANY_DEPARTMENT_REQUEST`;
export const CREATE_COMPANY_DEPARTMENT_SUCCESS = `${PREFIX}CREATE_COMPANY_DEPARTMENT_SUCCESS`;
export const CREATE_COMPANY_DEPARTMENT_ERROR = `${PREFIX}CREATE_COMPANY_DEPARTMENT_ERROR`;
export const createCompanyDepartment = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: CREATE_COMPANY_DEPARTMENT_REQUEST
    });
  },
  fn: CompanyDepartmentApi.createCompanyDepartment,
  success: department => ({
    type: CREATE_COMPANY_DEPARTMENT_SUCCESS,
    payload: department
  }),
  error: error => ({
    type: CREATE_COMPANY_DEPARTMENT_ERROR,
    payload: error,
    error
  }),
  postSuccess: dispatch => {
    dispatch(showPredefinedNotification({
      // @todo. auto generated. fix this error
      // @ts-ignore
      notificationName: NOTIFICATIONS.DEPARTMENT_CREATED
    }));
  }
});
export const UPDATE_COMPANY_DEPARTMENT_REQUEST = `${PREFIX}UPDATE_COMPANY_DEPARTMENT_REQUEST`;
export const UPDATE_COMPANY_DEPARTMENT_SUCCESS = `${PREFIX}UPDATE_COMPANY_DEPARTMENT_SUCCESS`;
export const UPDATE_COMPANY_DEPARTMENT_ERROR = `${PREFIX}UPDATE_COMPANY_DEPARTMENT_ERROR`;
export const updateCompanyDepartment = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: UPDATE_COMPANY_DEPARTMENT_REQUEST
    });
  },
  fn: CompanyDepartmentApi.updateCompanyDepartment,
  success: department => ({
    type: UPDATE_COMPANY_DEPARTMENT_SUCCESS,
    payload: department
  }),
  error: error => ({
    type: UPDATE_COMPANY_DEPARTMENT_ERROR,
    payload: error,
    error
  }),
  postSuccess: dispatch => {
    dispatch(showPredefinedNotification({
      // @todo. auto generated. fix this error
      // @ts-ignore
      notificationName: NOTIFICATIONS.DEPARTMENT_EDITED
    }));
  }
});
export const DELETE_COMPANY_DEPARTMENT_REQUEST = `${PREFIX}DELETE_COMPANY_DEPARTMENT_REQUEST`;
export const DELETE_COMPANY_DEPARTMENT_SUCCESS = `${PREFIX}DELETE_COMPANY_DEPARTMENT_SUCCESS`;
export const DELETE_COMPANY_DEPARTMENT_ERROR = `${PREFIX}DELETE_COMPANY_DEPARTMENT_ERROR`;
export const deleteCompanyDepartment = id => dispatch => {
  dispatch({
    type: DELETE_COMPANY_DEPARTMENT_REQUEST
  });
  CompanyDepartmentApi.removeCompanyDepartment({
    id
  }).then(() => {
    dispatch({
      type: DELETE_COMPANY_DEPARTMENT_SUCCESS,
      payload: id
    });
    dispatch(hidePopup());
    dispatch(showPredefinedNotification({
      // @todo. auto generated. fix this error
      // @ts-ignore
      notificationName: NOTIFICATIONS.DEPARTMENT_DELETED
    }));
  }).catch(err => {
    dispatch({
      type: DELETE_COMPANY_DEPARTMENT_ERROR,
      payload: err
    });
  });
};
export const ADD_BLANK_DEPARTMENT = `${PREFIX}ADD_BLANK_DEPARTMENT`;
export const addBlankDepartment = data => ({
  type: ADD_BLANK_DEPARTMENT,
  payload: data
});
export const CANCEL_CREATING_NEW_DEPARTMENT = `${PREFIX}CANCEL_CREATING_NEW_DEPARTMENT`;
export const cancelCreatingNewDepartment = () => ({
  type: CANCEL_CREATING_NEW_DEPARTMENT
});
export const SELECT_DEPARTMENT_TO_EDIT = `${PREFIX}SELECT_DEPARTMENT_TO_EDIT`;
export const selectDepartmentToEdit = id => ({
  type: SELECT_DEPARTMENT_TO_EDIT,
  payload: id
});