import { createSelector, createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { isRemoveItemFromCartPopupShownSelector, popupParamsSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { isBox, isItemBoxSpecialNotecard, isItemBoxAddon } from 'swag-common/utils/order/item-is-box.util';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { removeItemFromCart, removeItemFromDraft } from 'swag-client-common/redux/cart/cart.actions';
import { cartItemToRemoveSelector, isItemInDraftSelector, cartItemBoxItemItselfSelector, isRemoveFromBoxSelector } from '../../../redux/cart/cart.selectors';
import { setBoxNotecard, updateItem } from '../../../redux/cart/cart.actions';
import { RemoveItemFromCartPopupComponent } from './remove-item-from-cart-popup';
const popupMessageSelect = createSelector(cartItemToRemoveSelector, cartItemBoxItemItselfSelector, (itemToDelete, boxOfItemToDelete) => {
  if (isBox(itemToDelete)) {
    return `Are you sure you want to delete the ${itemToDelete.name} (box) from your cart? All of the individual products will remain in your cart as standalone products. All box-specific products: add-ons, crinkle paper and notecards will be deleted.`;
  }
  if (isItemBoxSpecialNotecard(itemToDelete) || isItemBoxAddon(itemToDelete)) {
    return `Are you sure you want to remove this product from the ${boxOfItemToDelete === null || boxOfItemToDelete === void 0 ? void 0 : boxOfItemToDelete.name} (box)?`;
  }
  if (!boxOfItemToDelete) {
    return 'Are you sure you want to remove product from cart?';
  }
  return `Are you sure you want to remove this product from the ${boxOfItemToDelete === null || boxOfItemToDelete === void 0 ? void 0 : boxOfItemToDelete.name} (box)? This product will remain in your cart as a standalone product.`;
});
const mapStateToProps = createStructuredSelector({
  item: cartItemToRemoveSelector,
  isRemoveFromBox: isRemoveFromBoxSelector,
  open: isRemoveItemFromCartPopupShownSelector,
  isDraft: isItemInDraftSelector,
  cartItemBoxItemItself: cartItemBoxItemItselfSelector,
  params: popupParamsSelector,
  message: popupMessageSelect
});
const mapDispatchToProps = dispatch => ({
  onConfirm: (item, isDraft, cartItemBoxItemItself, itemIdFromReorder, isRemoveFromBox) => {
    if (isDraft) {
      dispatch(removeItemFromDraft({
        id: item._id,
        itemIdFromReorder,
        isRemoveFromBox
      }));
    } else {
      dispatch(removeItemFromCart({
        id: item._id,
        isRemoveFromBox
      }));
    }
    dispatch(hidePopup());
    if (isItemBoxSpecialNotecard(item)) {
      dispatch(updateItem({
        id: cartItemBoxItemItself._id,
        isBoxNotecardAbsent: true
      }));
      dispatch(setBoxNotecard(item.boxItemsId, false));
    }
  },
  onClose: () => {
    dispatch(hidePopup());
  }
});
export const RemoveItemFromCartPopup = connect(mapStateToProps, mapDispatchToProps)(RemoveItemFromCartPopupComponent);