function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { isStatedItem } from 'swag-common/utils/order';
import { PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
import { Price } from 'swag-common/utils/price';
import { getDiscountForItems } from 'swag-common/business-logic/order/get-discount-for-items.service';
export function recalculateProceededOrder(fullOrder, calculateOnlyForItems) {
  const validItemsFilter = calculateOnlyForItems ? item => calculateOnlyForItems.includes(String(item._id)) : isStatedItem;
  const updatedItems = fullOrder.items;
  const validItems = updatedItems.filter(validItemsFilter);
  const correctOrder = _objectSpread({}, fullOrder, {
    taxesPrice: validItems.reduce((res, item) => (item.tax || 0) + res, 0),
    items: validItems
  });
  const {
    items
  } = correctOrder;
  const total = Price.getTotalWithoutDiscounts(items);
  correctOrder.total = total;
  const taxesPrice = Price.getTotalTaxes(correctOrder);
  correctOrder.taxesPrice = taxesPrice;
  const shipmentPrices = Price.getTotalShipment(correctOrder);
  correctOrder.shipmentCustomerTotal = shipmentPrices.shipmentCustomerTotal;
  const discountAmount = getDiscountForItems(fullOrder, fullOrder.items.filter(validItemsFilter));
  correctOrder.measurement = PromocodeMeasurementEnum.ABSOLUTE;
  correctOrder.promocodeDiscount = discountAmount;
  const priceToCharge = Price.getPriceToCharge(correctOrder);
  const {
    setupFeeDiscount,
    shipmentDiscount
  } = Price.getAdditionalDiscountValues(correctOrder);
  return {
    setupFeeDiscount,
    shipmentDiscount,
    taxesPrice,
    total,
    shipmentCustomerTotal: shipmentPrices.shipmentCustomerTotal,
    discountWithoutAdditionals: discountAmount,
    paymentPrices: {
      total,
      taxes: taxesPrice,
      shipment: shipmentPrices.shipmentCustomerTotal,
      discount: discountAmount,
      grandTotal: priceToCharge
    }
  };
}