import _get from "lodash/get";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { spreadQuantities } from 'swag-common/utils/product/product.utils';
import { getIndexesOfOutOfStock } from 'swag-client-common/utils/product.utils';
function applyQuantityToProduct({
  product,
  quantity,
  selectedVariants,
  selectedSizeIndex,
  sizeSettings,
  selectedVariantsInStockLevels = [],
  threshold
}) {
  if (!product) {
    return {
      0: [0]
    };
  }
  const {
    hasSize,
    sizeDependentPrice,
    isAlwaysInStock,
    variants
  } = product;
  if (!hasSize) {
    return applyToNoSizeProduct(quantity, selectedVariants);
  }
  if (sizeDependentPrice) {
    return applyToSizeDependentProduct(quantity, sizeSettings, selectedVariants, selectedSizeIndex);
  }
  const isAlwaysInStockForSelectedVariants = selectedVariants.reduce((acc, index) => {
    var _variants$index;
    acc[index] = ((_variants$index = variants[index]) === null || _variants$index === void 0 ? void 0 : _variants$index.isAlwaysInStockVariant) || false;
    return acc;
  }, {});
  return applyToMultiSizeProduct(quantity, sizeSettings, selectedVariants, selectedVariantsInStockLevels, isAlwaysInStock, isAlwaysInStockForSelectedVariants, threshold);
}
export function applyToNoSizeProduct(quantity, selectedVariants) {
  const variantsQuantitiesArray = spreadQuantities({
    quantity,
    itemsQuantityToSpread: selectedVariants.length
  });
  return variantsQuantitiesArray.reduce((quantities, variantQuantity, index) => _objectSpread({}, quantities, {
    [selectedVariants[index]]: [variantQuantity]
  }), {});
}
export function applyToSizeDependentProduct(quantity, size, selectedVariants, selectedSizeIndex) {
  const variantsQuantitiesArray = spreadQuantities({
    quantity,
    itemsQuantityToSpread: selectedVariants.length
  });
  return variantsQuantitiesArray.reduce((quantities, variantQuantity, index) => {
    const variantQuantities = spreadQuantities({
      quantity: variantQuantity,
      itemsQuantityToSpread: _get(size, 'options.length', 1),
      sizeOptions: size.options,
      allQuantityTo: selectedSizeIndex
    });
    return _objectSpread({}, quantities, {
      [selectedVariants[index]]: variantQuantities
    });
  }, {});
}
export function applyToMultiSizeProduct(quantity, size, selectedVariants, selectedVariantsInStockLevels, isProductAlwaysInStock, isAlwaysInStockForSelectedVariants, threshold) {
  const variantsQuantitiesArray = spreadQuantities({
    quantity,
    itemsQuantityToSpread: selectedVariants.length
  });
  return variantsQuantitiesArray.reduce((quantities, variantQuantity, index) => {
    const itemsToSkip = isProductAlwaysInStock || isAlwaysInStockForSelectedVariants[index] ? [] : getIndexesOfOutOfStock(selectedVariantsInStockLevels, index, threshold);
    const variantQuantities = spreadQuantities({
      quantity: variantQuantity,
      itemsQuantityToSpread: size.options.length,
      itemsToSkip,
      sizeOptions: size.options
    });
    return _objectSpread({}, quantities, {
      [selectedVariants[index]]: variantQuantities
    });
  }, {});
}
export default applyQuantityToProduct;