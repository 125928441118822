import { connect } from 'react-redux';
import { popupParamsSelector, isShowPromoPageStartingModal } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import PromoPageStartingPopup from './promo-page-starting-popup';
const mapStateToProps = state => {
  const params = popupParamsSelector(state);
  return {
    description: params ? params.description : '',
    isOpen: isShowPromoPageStartingModal(state),
    title: params ? params.title : ''
  };
};
const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(hidePopup())
});
export default connect(mapStateToProps, mapDispatchToProps)(PromoPageStartingPopup);