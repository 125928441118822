import _cloneDeep2 from "lodash/cloneDeep";
import _isEmpty2 from "lodash/isEmpty";
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ADDITIONAL_DISCOUNT_TYPES, PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
import { getDraftToken, getOrderToken, removeDraftToken, removeOrderToken, setDraftToken, setOrderToken } from 'swag-client-common/utils/local-storage.util';
import { UPDATE_CART_ITEMS_SUCCESS, UPDATE_DRAFT_ITEMS_SUCCESS } from '../../../customer/redux/box-builder/box-builder.actions';
import { FETCH_SPECIAL_BOX_PRODUCTS_FAIL, FETCH_SPECIAL_BOX_PRODUCTS_LOADING, FETCH_SPECIAL_BOX_PRODUCTS_SUCCESS } from '../product/product.actions';
import { GET_CART, CART_LOADED, ADD_ITEM_TO_CART, ADD_ITEM_TO_CART_ERROR, ERROR, PROMOCODE_APPLIED_ERROR, CHANGE_SIZE_INDEX_IN_ITEM, EDIT_ITEM_IN_CART_SUCCESS, SELECT_ITEM, REMOVE_PROMOCODE_SUCCESS, REMOVE_ITEM_FROM_CART_SUCCESS, REMOVE_ITEM_FROM_CART_FAIL, SELECT_SUGGESTED_PRODUCT, SET_INITIAL_DATA, GET_DRAFT_SUCCESS, REMOVE_ITEM_FROM_DRAFT_SUCCESS, EDIT_ITEM_IN_DRAFT_SUCCESS, ADD_ITEM_TO_DRAFT_SUCCESS, REBUILDING_ITEM_REQUESTED, SET_ITEM_ID, UPDATE_BOX_VALIDATIONS, RESET_BOX_VALIDATIONS, GET_CART_REQUEST, GET_DRAFT_REQUEST, GET_DRAFT_FAILURE, TOGGLE_CART_BADGE_LOADER, REMOVE_ITEM_FROM_CART_REQUEST, UPDATE_UPSELL_WIDGET_STATES, REPLACE_ITEM_SUCCESS, TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_REQUEST, TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_SUCCESS, TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_FAIL } from './cart.actions';
const {
  IS_FREE_SHIPPING,
  IS_FREE_SETUP_FEE,
  IS_TAXFREE
} = ADDITIONAL_DISCOUNT_TYPES;
export const initialState = {
  _id: null,
  cartLoaded: false,
  isLoading: false,
  items: [],
  draftItems: [],
  orderId: null,
  orderToken: getOrderToken(),
  draftToken: getDraftToken(),
  orderVersion: null,
  draftVersion: null,
  promocode: '',
  measurement: PromocodeMeasurementEnum.PERCENTAGES,
  taxes: 0,
  promocodeDiscount: 0,
  delivery: 1,
  plan: null,
  preTotal: 0,
  total: 0,
  shipmentCustomerTotal: 0,
  ksFulfillment: null,
  ksFlow: false,
  error: null,
  suggestedProducts: [],
  selectedSuggestedProduct: null,
  itemToEdit: null,
  shipmentDiscount: 0,
  boxValidations: {},
  futureItemsInCart: null,
  getCartIsLoading: false,
  getDraftIsLoading: false,
  cartBadgeIsLoading: false,
  deletingItemId: null,
  specialBoxProductsLoading: false,
  specialBoxProducts: [],
  pickedSpecialBoxesProducts: {},
  cart: {
    supplierInventoryLevels: [],
    suppliers: []
  },
  draft: {
    supplierInventoryLevels: [],
    suppliers: []
  },
  upsellDismissedStates: {},
  preBuiltCartShortId: null,
  preBuiltCartType: null,
  resellerInvoiceNum: null
};
export function commonCartReducer(state = initialState, action) {
  switch (action.type) {
    case REBUILDING_ITEM_REQUESTED:
      {
        const {
          storageType
        } = action.payload;
        return _objectSpread({}, state, {
          storageType
        });
      }
    case SET_ITEM_ID:
      {
        const {
          itemToEdit
        } = action.payload;
        return _objectSpread({}, state, {
          itemToEdit
        });
      }
    case GET_CART_REQUEST:
      {
        return _objectSpread({}, state, {
          getCartIsLoading: true
        });
      }
    case GET_DRAFT_REQUEST:
      {
        return _objectSpread({}, state, {
          getDraftIsLoading: true
        });
      }
    case GET_DRAFT_FAILURE:
      {
        return _objectSpread({}, state, {
          getDraftIsLoading: false
        });
      }
    case UPDATE_UPSELL_WIDGET_STATES:
      {
        const _action$payload = action.payload,
          {
            itemId
          } = _action$payload,
          rest = _objectWithoutProperties(_action$payload, ["itemId"]);
        return _objectSpread({}, state, {
          upsellDismissedStates: _objectSpread({}, state.upsellDismissedStates, {
            [itemId]: _objectSpread({}, state.upsellDismissedStates[itemId] || {}, rest)
          })
        });
      }
    case UPDATE_DRAFT_ITEMS_SUCCESS:
      {
        const {
          items = [],
          orderVersion
        } = action.body;
        return _objectSpread({}, state, {
          draftItems: items,
          draftVersion: orderVersion
        });
      }
    case REPLACE_ITEM_SUCCESS:
    case EDIT_ITEM_IN_CART_SUCCESS:
    case REMOVE_ITEM_FROM_CART_SUCCESS:
    case UPDATE_CART_ITEMS_SUCCESS:
    case GET_CART:
      {
        const {
          _id,
          items = [],
          promocode = '',
          promocodeDiscount,
          hasDefaultDiscount = false,
          measurement,
          plan = null,
          paymentMethod,
          ksFlow = null,
          total,
          shipmentCustomerTotal,
          ksFulfillment,
          shipmentDiscount = 0,
          setupFeeDiscount = 0,
          supplierInventoryLevels,
          suppliers,
          upsellDismissedStates,
          taxesPrice = 0,
          orderVersion,
          preBuiltCartShortId,
          preBuiltCartType,
          isConfirmed,
          resellerInvoiceNum,
          resellersCommission,
          productsCommission,
          isTaxExempt
        } = action.body;
        return _objectSpread({}, state, {
          _id,
          orderVersion,
          plan,
          items,
          ksFlow,
          measurement,
          paymentMethod,
          setupFeeDiscount,
          shipmentDiscount,
          hasDefaultDiscount,
          getCartIsLoading: false,
          cartLoaded: true,
          isLoading: false,
          resellersCommission: resellersCommission !== null && resellersCommission !== void 0 ? resellersCommission : 0,
          productsCommission: productsCommission !== null && productsCommission !== void 0 ? productsCommission : [],
          cart: {
            supplierInventoryLevels: supplierInventoryLevels || state.cart.supplierInventoryLevels,
            suppliers: suppliers || state.cart.suppliers
          },
          promocode,
          promocodeDiscount: parseInt(promocodeDiscount, 10) || initialState.promocodeDiscount,
          delivery: 1,
          total: total ? parseInt(total, 10) : 0,
          taxesPrice: taxesPrice ? parseInt(taxesPrice, 10) : 0,
          shipmentCustomerTotal: shipmentCustomerTotal ? parseInt(shipmentCustomerTotal, 10) : 0,
          ksFulfillment: ksFulfillment ? parseInt(ksFulfillment, 10) : 0,
          preTotal: parseInt(total, 10),
          [IS_FREE_SHIPPING]: !!action.body[IS_FREE_SHIPPING],
          [IS_FREE_SETUP_FEE]: !!action.body[IS_FREE_SETUP_FEE],
          [IS_TAXFREE]: !!action.body[IS_TAXFREE],
          upsellDismissedStates: _objectSpread({}, state.upsellDismissedStates, upsellDismissedStates),
          preBuiltCartShortId,
          preBuiltCartType,
          isConfirmed,
          resellerInvoiceNum,
          isTaxExempt
        });
      }
    case CART_LOADED:
      {
        return _objectSpread({}, state, {
          cartLoaded: true
        });
      }
    case PROMOCODE_APPLIED_ERROR:
      {
        const {
          message
        } = action.err;
        return _objectSpread({}, state, {
          error: {
            message
          }
        });
      }
    case ADD_ITEM_TO_CART:
      {
        const {
          order,
          orderToken,
          order: {
            items,
            version
          }
        } = action.body;
        if (orderToken) {
          setOrderToken(orderToken);
        }
        return _objectSpread({}, state, order, {
          orderVersion: version,
          items,
          orderToken: orderToken !== undefined ? orderToken : state.orderToken
        });
      }
    case ADD_ITEM_TO_DRAFT_SUCCESS:
      {
        const {
          draftToken,
          draftItems
        } = action.payload;
        if (draftToken) {
          setDraftToken(draftToken);
        }
        return _objectSpread({}, state, {
          draftItems,
          draftToken: draftToken !== undefined ? draftToken : state.orderToken
        });
      }
    case GET_DRAFT_SUCCESS:
    case EDIT_ITEM_IN_DRAFT_SUCCESS:
      {
        const {
          draftItems,
          draftToken,
          supplierInventoryLevels,
          suppliers,
          draftVersion,
          upsellDismissedStates
        } = action.payload;
        if (!_isEmpty2(draftToken)) {
          setDraftToken(draftToken);
        }
        return _objectSpread({}, state, {
          draftToken,
          draftVersion,
          draft: {
            supplierInventoryLevels: supplierInventoryLevels || state.draft.supplierInventoryLevels,
            suppliers: suppliers || state.draft.suppliers
          },
          draftItems: _cloneDeep2(draftItems),
          getDraftIsLoading: false,
          upsellDismissedStates: _objectSpread({}, state.upsellDismissedStates, upsellDismissedStates)
        });
      }
    case REMOVE_ITEM_FROM_DRAFT_SUCCESS:
      {
        const {
          draftItems,
          draftVersion
        } = action.payload;
        const isEmpty = !draftItems.length;
        const draftToken = isEmpty ? null : state.draftToken;
        if (isEmpty) {
          removeDraftToken();
        }
        return _objectSpread({}, state, {
          draftToken,
          draftItems,
          draftVersion
        });
      }
    case REMOVE_ITEM_FROM_CART_REQUEST:
      {
        return _objectSpread({}, state, {
          isLoading: true,
          deletingItemId: action.payload.id
        });
      }
    case CHANGE_SIZE_INDEX_IN_ITEM:
      {
        var _stateItems$index$var;
        const {
          itemId,
          sizeIndex
        } = action.payload;
        const items = state.items.slice();
        const index = items.findIndex(i => i._id === itemId);
        const stateItems = state.items;
        const quantities = (_stateItems$index$var = stateItems[index].variants) === null || _stateItems$index$var === void 0 ? void 0 : _stateItems$index$var[0].quantities.slice();
        items[index].variants[0].quantities = quantities.fill(0);
        items[index].variants[0].quantities[sizeIndex] = 1;
        items[index].sizeInd = sizeIndex;
        return _objectSpread({}, state, {
          items
        });
      }
    case ERROR:
    case REMOVE_ITEM_FROM_CART_FAIL:
      {
        switch (action.err.code) {
          case 102:
            {
              removeOrderToken();
              break;
            }
          default:
            break;
        }
        switch (action.err.status) {
          case '400':
            {
              removeOrderToken();
              break;
            }
          default:
            break;
        }
        return _objectSpread({}, state, {
          cartLoaded: false,
          getCartIsLoading: false,
          isLoading: false,
          deletingItemId: null
        });
      }
    case REMOVE_PROMOCODE_SUCCESS:
      {
        const {
          total,
          plan,
          promocode,
          promocodeDiscount,
          measurement,
          items,
          ksFlow,
          shipmentCustomerTotal,
          ksFulfillment,
          paymentMethod,
          taxesPrice,
          version: orderVersion
        } = action.body;
        return _objectSpread({}, state, {
          orderVersion,
          promocode: promocode || initialState.promocode,
          promocodeDiscount: parseInt(promocodeDiscount, 10) || initialState.promocodeDiscount,
          measurement: measurement || initialState.measurement,
          plan: plan || initialState.plan,
          total: parseInt(total, 10),
          shipmentCustomerTotal: parseInt(shipmentCustomerTotal, 10),
          ksFulfillment: parseInt(ksFulfillment, 10),
          paymentMethod,
          items,
          ksFlow,
          taxesPrice: parseInt(taxesPrice, 10),
          [IS_FREE_SHIPPING]: false,
          [IS_FREE_SETUP_FEE]: false,
          [IS_TAXFREE]: false,
          shipmentDiscount: 0,
          setupFeeDiscount: 0
        });
      }
    case SELECT_SUGGESTED_PRODUCT:
      {
        const {
          selectedSuggestedProduct
        } = action.payload;
        return _objectSpread({}, state, {
          selectedSuggestedProduct
        });
      }
    case SELECT_ITEM:
      {
        const selectedItem = _cloneDeep2(action.payload.item);
        delete selectedItem.setupFee;
        return _objectSpread({}, state, {
          selectedItem
        });
      }
    case ADD_ITEM_TO_CART_ERROR:
      {
        return _objectSpread({}, state);
      }
    case SET_INITIAL_DATA:
      {
        return _objectSpread({}, state, {
          error: initialState.error
        });
      }
    case UPDATE_BOX_VALIDATIONS:
      {
        return _objectSpread({}, state, {
          boxValidations: _objectSpread({}, state.boxValidations, action.payload.data)
        });
      }
    case RESET_BOX_VALIDATIONS:
      {
        return _objectSpread({}, state, {
          boxValidations: {}
        });
      }
    case TOGGLE_CART_BADGE_LOADER:
      return _objectSpread({}, state, {
        cartBadgeIsLoading: action.payload
      });
    case TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_REQUEST:
      {
        return _objectSpread({}, state, {
          isLoading: true,
          getDraftIsLoading: true,
          getCartIsLoading: true
        });
      }
    case TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_FAIL:
      {
        return _objectSpread({}, state, {
          isLoading: false,
          getDraftIsLoading: false,
          getCartIsLoading: false
        });
      }
    case TRANSFORM_SUPER_SPEED_ITEMS_TO_STANDARD_SUCCESS:
      {
        const {
          cart: {
            orderVersion,
            items,
            supplierInventoryLevels,
            suppliers,
            total,
            taxesPrice,
            shipmentCustomerTotal
          },
          draft,
          draftToken
        } = action.payload;
        return _objectSpread({}, state, {
          //cart update
          getCartIsLoading: false,
          cartLoaded: true,
          isLoading: false,
          items: items,
          orderVersion: orderVersion,
          cart: {
            supplierInventoryLevels: supplierInventoryLevels || state.cart.supplierInventoryLevels,
            suppliers: suppliers || state.cart.suppliers
          },
          total: total ? parseInt(total, 10) : 0,
          taxesPrice: taxesPrice ? parseInt(taxesPrice, 10) : 0,
          shipmentCustomerTotal: shipmentCustomerTotal ? parseInt(shipmentCustomerTotal, 10) : 0,
          preTotal: parseInt(total, 10),
          //draft update
          getDraftIsLoading: false,
          draftToken,
          draftVersion: draft.orderVersion,
          draft: {
            supplierInventoryLevels: draft.supplierInventoryLevels || state.draft.supplierInventoryLevels,
            suppliers: draft.suppliers || state.draft.suppliers
          },
          draftItems: draft.items
        });
      }
    case FETCH_SPECIAL_BOX_PRODUCTS_SUCCESS:
      {
        return _objectSpread({}, state, {
          specialBoxProducts: action.products,
          specialBoxProductsLoading: false
        });
      }
    case FETCH_SPECIAL_BOX_PRODUCTS_LOADING:
      {
        return _objectSpread({}, state, {
          specialBoxProductsLoading: true
        });
      }
    case FETCH_SPECIAL_BOX_PRODUCTS_FAIL:
      {
        return _objectSpread({}, state, {
          specialBoxProductsLoading: false
        });
      }
    default:
      {
        return state;
      }
  }
}