import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { SubcategoriesLinksComponent } from './subcategories-links';
const propsSelector = (state, props) => props;
const shouldShowSelector = createSelector(propsSelector, ({
  parentCategory,
  subcategories
}) => !!(parentCategory !== null && parentCategory !== void 0 && parentCategory.name) && !!subcategories.length);
const allSubcategoriesPropsSelector = createSelector(propsSelector, ({
  allSubcategories
}) => allSubcategories || []);
const mapStateToProps = createStructuredSelector({
  shouldShow: shouldShowSelector,
  allSubcategories: allSubcategoriesPropsSelector
});
export const SubcategoriesLinks = connect(mapStateToProps)(SubcategoriesLinksComponent);