function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { GET_ALL_FAV_PRODUCT_IDS_SUCCESS, GET_ALL_FAV_PRODUCT_IDS_STARTED, GET_ALL_FAV_PRODUCT_IDS_ERROR, ADD_FAVOURITE_PRODUCT_SUCCESS, REMOVE_FAVOURITE_PRODUCT_SUCCESS, GET_FAV_PRODUCTS_STARTED, GET_FAV_PRODUCTS_ERROR, GET_FAV_PRODUCTS_SUCCESS, TOGGLE_FAVOURITE_PRODUCT_STARTED, TOGGLE_FAVOURITE_PRODUCT_ERROR, RESET_FAV_PRODUCTS } from './favourite-products.actions';
const initialState = {
  allFavouriteProductIds: new Set(),
  page: 0,
  favouriteProducts: [],
  isLoadingFavouriteProducts: false,
  isLoadingAllFavouriteProductIds: false,
  isLoadingToggleFavouriteProduct: false,
  isFavouriteProductsPageInitialized: false
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FAV_PRODUCT_IDS_SUCCESS:
      {
        const allFavouriteProductIds = new Set(action.payload.map(item => item.productId || item.productAccumulatorId));
        return _objectSpread({}, state, {
          isLoadingAllFavouriteProductIds: false,
          allFavouriteProductIds,
          isFavouriteProductsPageInitialized: state.isFavouriteProductsPageInitialized ? state.isFavouriteProductsPageInitialized : allFavouriteProductIds.size === 0
        });
      }
    case ADD_FAVOURITE_PRODUCT_SUCCESS:
      {
        const {
          productId,
          productAccumulatorId
        } = action.payload;
        const addedId = productAccumulatorId || productId;
        const nextSet = new Set(Array.from(state.allFavouriteProductIds));
        nextSet.add(addedId);
        return _objectSpread({}, state, {
          allFavouriteProductIds: nextSet,
          isLoadingToggleFavouriteProduct: false
        });
      }
    case REMOVE_FAVOURITE_PRODUCT_SUCCESS:
      {
        const {
          productId,
          productAccumulatorId
        } = action.payload;
        const nextSet = new Set(Array.from(state.allFavouriteProductIds));
        const removedId = productAccumulatorId || productId;
        nextSet.delete(removedId);
        return _objectSpread({}, state, {
          allFavouriteProductIds: nextSet,
          isLoadingToggleFavouriteProduct: false,
          favouriteProducts: state.favouriteProducts.filter(item => item._id !== removedId)
        });
      }
    case TOGGLE_FAVOURITE_PRODUCT_ERROR:
      {
        return _objectSpread({}, state, {
          isLoadingToggleFavouriteProduct: false
        });
      }
    case TOGGLE_FAVOURITE_PRODUCT_STARTED:
      {
        return _objectSpread({}, state, {
          isLoadingToggleFavouriteProduct: true
        });
      }
    case GET_FAV_PRODUCTS_STARTED:
      {
        return _objectSpread({}, state, {
          isLoadingFavouriteProducts: true
        });
      }
    case GET_FAV_PRODUCTS_ERROR:
      {
        return _objectSpread({}, state, {
          isLoadingFavouriteProducts: false
        });
      }
    case GET_ALL_FAV_PRODUCT_IDS_STARTED:
      {
        return _objectSpread({}, state, {
          isLoadingAllFavouriteProductIds: true
        });
      }
    case GET_ALL_FAV_PRODUCT_IDS_ERROR:
      {
        return _objectSpread({}, state, {
          isLoadingAllFavouriteProductIds: false
        });
      }
    case RESET_FAV_PRODUCTS:
      {
        return _objectSpread({}, state, {
          page: initialState.page,
          favouriteProducts: initialState.favouriteProducts
        });
      }
    case GET_FAV_PRODUCTS_SUCCESS:
      {
        const {
          products,
          page
        } = action.payload;
        return _objectSpread({}, state, {
          isLoadingFavouriteProducts: false,
          isFavouriteProductsPageInitialized: true,
          page: page || state.page,
          favouriteProducts: state.favouriteProducts.concat(products)
        });
      }
    default:
      return state;
  }
}