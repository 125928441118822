import editImage from 'assets/img/gamification/edit.png';
import shoppingCartImage from 'assets/img/gamification/shopping_cart.png';
import likeFbImage from 'assets/img/gamification/like-fb.png';
import likeTwitterImage from 'assets/img/gamification/l-twitter.png';
import instImage from 'assets/img/gamification/inst.png';
import fbImage from 'assets/img/gamification/fb.png';
import twitterImage from 'assets/img/gamification/twitter.png';
import groupAddImage from 'assets/img/gamification/group_add.png';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
export const SELECT_LVL_ALL = {
  label: 'All levels',
  value: 'all'
};
export const PROGRESS_BAR_LABEL_TYPES = {
  signed_up: 'signed_up',
  order: 'order',
  like_facebook: 'like_facebook',
  follow_twitter: 'follow_twitter',
  follow_instagram: 'follow_instagram',
  share_facebook: 'share_facebook',
  share_twitter: 'share_twitter',
  share_friend: 'share_friend',
  other: 'other',
  correction: 'correction'
};
export const SCORE_TYPE_TITLES = {
  [PROGRESS_BAR_LABEL_TYPES.signed_up]: 'Sign up for an account',
  [PROGRESS_BAR_LABEL_TYPES.order]: 'Place an order',
  [PROGRESS_BAR_LABEL_TYPES.like_facebook]: 'Like us on Facebook',
  [PROGRESS_BAR_LABEL_TYPES.follow_twitter]: 'Follow us on Twitter',
  [PROGRESS_BAR_LABEL_TYPES.follow_instagram]: 'Follow us on Instagram',
  [PROGRESS_BAR_LABEL_TYPES.share_facebook]: 'Share us on Facebook',
  [PROGRESS_BAR_LABEL_TYPES.share_twitter]: 'Share us on Twitter',
  [PROGRESS_BAR_LABEL_TYPES.share_friend]: 'Share with a friend',
  [PROGRESS_BAR_LABEL_TYPES.other]: 'Bonus',
  [PROGRESS_BAR_LABEL_TYPES.correction]: 'Bonus'
};
export const SCORE_TYPES_DATA = {
  [PROGRESS_BAR_LABEL_TYPES.signed_up]: {
    key: PROGRESS_BAR_LABEL_TYPES.signed_up,
    title: SCORE_TYPE_TITLES[PROGRESS_BAR_LABEL_TYPES.signed_up],
    doneTitle: 'Signed up',
    iconUrl: editImage,
    score: 100
  },
  [PROGRESS_BAR_LABEL_TYPES.order]: {
    key: PROGRESS_BAR_LABEL_TYPES.order,
    title: SCORE_TYPE_TITLES[PROGRESS_BAR_LABEL_TYPES.order],
    doneTitle: 'Placed an order',
    iconUrl: shoppingCartImage,
    descr: '3 points for every dollar spent',
    link: APP_ROUTES.ALL_SWAG
  },
  [PROGRESS_BAR_LABEL_TYPES.like_facebook]: {
    key: PROGRESS_BAR_LABEL_TYPES.like_facebook,
    title: SCORE_TYPE_TITLES[PROGRESS_BAR_LABEL_TYPES.like_facebook],
    doneTitle: 'Like us on Facebook',
    iconUrl: likeFbImage,
    score: 100,
    comingSoon: true
  },
  [PROGRESS_BAR_LABEL_TYPES.follow_twitter]: {
    key: PROGRESS_BAR_LABEL_TYPES.follow_twitter,
    title: SCORE_TYPE_TITLES[PROGRESS_BAR_LABEL_TYPES.follow_twitter],
    doneTitle: 'Followed us on Twitter',
    iconUrl: likeTwitterImage,
    score: 100,
    comingSoon: true
  },
  [PROGRESS_BAR_LABEL_TYPES.follow_instagram]: {
    key: PROGRESS_BAR_LABEL_TYPES.follow_instagram,
    title: SCORE_TYPE_TITLES[PROGRESS_BAR_LABEL_TYPES.follow_instagram],
    doneTitle: 'Followed us on Instagram',
    iconUrl: instImage,
    score: 100,
    comingSoon: true
  },
  [PROGRESS_BAR_LABEL_TYPES.share_facebook]: {
    key: PROGRESS_BAR_LABEL_TYPES.share_facebook,
    title: SCORE_TYPE_TITLES[PROGRESS_BAR_LABEL_TYPES.share_facebook],
    doneTitle: 'Shared us on Facebook',
    iconUrl: fbImage,
    score: 100,
    comingSoon: true
  },
  [PROGRESS_BAR_LABEL_TYPES.share_twitter]: {
    key: PROGRESS_BAR_LABEL_TYPES.share_twitter,
    title: SCORE_TYPE_TITLES[PROGRESS_BAR_LABEL_TYPES.share_twitter],
    doneTitle: 'Shared us on Twitter',
    iconUrl: twitterImage,
    score: 100,
    comingSoon: true
  },
  [PROGRESS_BAR_LABEL_TYPES.share_friend]: {
    key: PROGRESS_BAR_LABEL_TYPES.share_friend,
    title: SCORE_TYPE_TITLES[PROGRESS_BAR_LABEL_TYPES.share_friend],
    doneTitle: 'Shared Swag with a Friend',
    iconUrl: groupAddImage,
    score: 100,
    comingSoon: true
  },
  [PROGRESS_BAR_LABEL_TYPES.other]: {
    title: SCORE_TYPE_TITLES[PROGRESS_BAR_LABEL_TYPES.other],
    doneTitle: 'Bonus',
    iconUrl: '',
    score: 100,
    comingSoon: true
  },
  [PROGRESS_BAR_LABEL_TYPES.correction]: {
    title: SCORE_TYPE_TITLES[PROGRESS_BAR_LABEL_TYPES.correction],
    doneTitle: 'Bonus',
    icon: '+'
  }
};
export const LABEL_WIDTH = 30; //pixels
export const PROGRESS_BAR_EL_ID = 'gamification-progress-bar';
export const DRAWERS = {
  GET_MORE: 'GET_MORE',
  ALL_ACTIVITY: 'ALL_ACTIVITY'
};