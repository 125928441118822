import { getSharedItemsWithItemItself } from 'swag-common/business-logic/items/get-shared-items-with-item-itself.logic';
export function filterSharedItemsForDiscountCalculation(items) {
  return items.filter(item => {
    var _sharedItems$;
    const sharedItems = getSharedItemsWithItemItself(item, items);
    return ((_sharedItems$ = sharedItems[0]) === null || _sharedItems$ === void 0 ? void 0 : _sharedItems$._id) === item._id;
  });
}
export function calculateSampleDeductionDiscount(items) {
  const filteredWithoutSharedItems = filterSharedItemsForDiscountCalculation(items);
  return filteredWithoutSharedItems.reduce((discount, item) => {
    if (item.sampleDeductionDiscount) {
      return discount + item.sampleDeductionDiscount;
    }
    return discount;
  }, 0);
}