import { bindActionCreators } from 'redux';
import { onRemoveBackgroundClick, onPantoneSelectionConfirm, onPantoneSelectionSkip, revertToOriginalPreview, convertLogoToOneColor, removeColor, addColor } from '../../../../redux/logo-editing/logo-editing.actions';
import { confirm, selectPrevious, addColor as addColorToConfirm, removeColor as removeColorToConfirm, closePantoneColors } from '../../../../redux/item-pantones/item-pantones.actions';
export const selectPantonesPopupDispatcher = dispatch => bindActionCreators({
  onRemoveBackgroundClick,
  onRevertOriginalPreviewClick: revertToOriginalPreview,
  onContinueClick: onPantoneSelectionConfirm,
  onCancelClick: onPantoneSelectionSkip,
  onClose: onPantoneSelectionSkip,
  onColorRemove: removeColor,
  onColorAdd: addColor,
  onColorConvert: convertLogoToOneColor
}, dispatch);
export const confirmItemPantonesDispatcher = dispatch => bindActionCreators({
  onContinueClick: confirm,
  onCancelClick: selectPrevious,
  onColorRemove: removeColorToConfirm,
  onColorAdd: addColorToConfirm,
  onClose: closePantoneColors
}, dispatch);