import { DesignUploadMode } from 'swag-common/interfaces';
export const checkProductIsNonPSOutOfStock = ({
  designUploadMode,
  productVariants,
  lvls,
  hasSize,
  isLimitedEdition
}) => {
  if (isLimitedEdition || !productVariants.length && designUploadMode !== DesignUploadMode.fullDesign) {
    return false;
  }
  if (designUploadMode === DesignUploadMode.fullDesign) {
    return Boolean(lvls.length && !lvls[0].isTrulyPSRecord);
  }
  return productVariants.every(({
    productSku: sku,
    sizeSkus
  }) => {
    if (hasSize) {
      if (!sizeSkus) {
        return false;
      }
      return Object.values(sizeSkus).every(sizeSku => isOutOfStockNonPs(lvls, sizeSku));
    }
    if (!sku) {
      return false;
    }
    return isOutOfStockNonPs(lvls, sku);
  });
};
const countTotalPossibleLvls = productVariants => {
  return productVariants.reduce((acc, variant) => {
    if (variant !== null && variant !== void 0 && variant.sizeSkus) {
      acc = acc + Object.keys(variant === null || variant === void 0 ? void 0 : variant.sizeSkus).length;
    } else {
      acc++;
    }
    return acc;
  }, 0);
};
export const getEarliestOutOfStockExpireDate = ({
  lvls,
  productVariants
}) => {
  var _lvls$0$outOfStockExp, _lvls$;
  const totalPossibleLvls = countTotalPossibleLvls(productVariants);
  if (!lvls.length || lvls.length < totalPossibleLvls || lvls.every(lvl => lvl.outOfStockExpireDate === null)) {
    return null;
  }
  return lvls.reduce((latestExpireDate, lvl) => latestExpireDate < ((lvl === null || lvl === void 0 ? void 0 : lvl.outOfStockExpireDate) || 0) || !lvl.outOfStockExpireDate ? latestExpireDate : lvl.outOfStockExpireDate, (_lvls$0$outOfStockExp = (_lvls$ = lvls[0]) === null || _lvls$ === void 0 ? void 0 : _lvls$.outOfStockExpireDate) !== null && _lvls$0$outOfStockExp !== void 0 ? _lvls$0$outOfStockExp : Infinity);
};
export const getLevelBySku = (lvls, productSku) => lvls === null || lvls === void 0 ? void 0 : lvls.find(lvl => lvl.variantSku === productSku);
export const isOutOfStockNonPs = (lvls, sku) => {
  const levelBySku = getLevelBySku(lvls, sku);
  return Boolean(levelBySku && !levelBySku.isTrulyPSRecord);
};