function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { connect } from 'react-redux';
import { ALL_SWAG_CATEGORY } from 'swag-client-common/constants';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { sortProducts } from '../../../../../../redux/product-listing/product-listing.actions';
import { sortProducts as searchResultsPageSortProducts } from '../../../../../../redux/search-results/search-results.actions';
import { isFiltersPopupShownSelector } from '../../../../../../redux/search-results/search-results.selectors';
import { isFilterOpenedSelector, isBoxesPageSelector, currentCategorySelector, currentParentCategorySelector, allSubcategoriesSelector } from '../../../../../../redux/product-listing/product-listing.selectors';
import { changePageParams } from '../../../../../../redux/pages/pages.actions';
import { isPageFoundSelector } from '../../../../../../redux/common/common.selectors';
import { ProductListingPanelComponent } from './product-listing-panel';
export const categoriesMenuSelector = (state, props) => {
  var _currentMainCategory$;
  const {
    categories,
    sorting,
    categoriesMap,
    appliedFilter,
    total,
    isVisible
  } = state.productListing;
  const isPageFound = isPageFoundSelector(state);
  const isFilterOpened = isFilterOpenedSelector(state) && !isBoxesPageSelector(state);
  const isCollectionsRoute = window.location.pathname.indexOf('collections') > -1;
  const currentCategory = currentCategorySelector(state);
  const parentCategory = currentParentCategorySelector(state);
  const currentMainCategory = parentCategory || currentCategory;
  const formattedCategories = categories.filter(c => c.isVisible).map(({
    _id,
    name,
    slug,
    isVisible,
    subcategories,
    isLogoCategory,
    publicId,
    isDescriptionShownByDefault
  }) => ({
    _id,
    name,
    isVisible,
    link: slug,
    slug,
    isLogoCategory,
    isActive: isCollectionsRoute && currentMainCategory._id === _id,
    subcategories: subcategories.filter(subcategory => subcategory.isVisible),
    publicId,
    isDescriptionShownByDefault
  }));
  const isBrowseProductPage = window.location.pathname.includes('collections');
  const allSubcategories = allSubcategoriesSelector(state);
  if (!isBrowseProductPage || !isPageFound) {
    return {
      categories: formattedCategories,
      currentMainCategory: {},
      allSubcategories
    };
  }
  const modifiedAppliedFilter = _objectSpread({}, appliedFilter || {});
  if (modifiedAppliedFilter.categoryId) {
    modifiedAppliedFilter.categoryId = appliedFilter.categoryId.filter(id => id !== currentMainCategory._id);
    if (!modifiedAppliedFilter.categoryId.length) {
      delete modifiedAppliedFilter.categoryId;
    }
  }
  const isActiveFilterBlockShown = !!Object.keys(modifiedAppliedFilter).length && isVisible && !(Object.keys(modifiedAppliedFilter).length === 0 && modifiedAppliedFilter.categoryId && currentMainCategory.slug !== ALL_SWAG_CATEGORY);
  const subcategories = (currentMainCategory === null || currentMainCategory === void 0 ? void 0 : (_currentMainCategory$ = currentMainCategory.subcategories) === null || _currentMainCategory$ === void 0 ? void 0 : _currentMainCategory$.length) > 0 ? currentMainCategory.subcategories.filter(subcategory => subcategory.isVisible) : null;
  return {
    sorting,
    subcategories,
    categoriesMap,
    isFilterOpened,
    currentCategory,
    currentMainCategory,
    isActiveFilterBlockShown,
    isSearch: false,
    productCount: total,
    categories: formattedCategories,
    isFilterSectionShown: isBrowseProductPage,
    currentSubcategoryId: currentCategory.parent_id ? currentCategory._id : null,
    allSubcategories
  };
};
export const searchResultsPageCategoriesMenuSelector = (state, props) => {
  var _currentMainCategory$2;
  const {
    categories,
    sorting,
    categoriesMap,
    appliedFilter,
    total,
    isVisible
  } = state.searchResults;
  const isPageFound = isPageFoundSelector(state);
  const isFilterOpened = isFiltersPopupShownSelector(state) && !isBoxesPageSelector(state);
  const currentCategory = currentCategorySelector(state);
  const parentCategory = currentParentCategorySelector(state);
  const currentMainCategory = parentCategory || currentCategory;
  const allSubcategories = allSubcategoriesSelector(state);
  const formattedCategories = categories.filter(c => c.isVisible).map(({
    _id,
    name,
    slug,
    subcategories,
    isLogoCategory
  }) => ({
    _id,
    name,
    isLogoCategory,
    link: slug,
    slug,
    isActive: currentMainCategory._id === _id,
    subcategories: subcategories.filter(subcategory => subcategory.isVisible)
  }));
  const isSearchResultsPage = window.location.pathname === APP_ROUTES.SEARCH_RESULTS;
  if (!isSearchResultsPage || !isPageFound) {
    return {
      categories: formattedCategories,
      currentMainCategory: {},
      allSubcategories
    };
  }
  const isActiveFilterBlockShown = Object.keys(appliedFilter).length && isVisible && !(Object.keys(appliedFilter).length === 0 && appliedFilter.categoryId && currentMainCategory.slug !== ALL_SWAG_CATEGORY);
  const subcategories = (currentMainCategory === null || currentMainCategory === void 0 ? void 0 : (_currentMainCategory$2 = currentMainCategory.subcategories) === null || _currentMainCategory$2 === void 0 ? void 0 : _currentMainCategory$2.length) > 0 ? currentMainCategory.subcategories.filter(subcategory => subcategory.isVisible) : null;
  return {
    sorting,
    subcategories,
    categoriesMap,
    isFilterOpened,
    currentCategory,
    currentMainCategory,
    isActiveFilterBlockShown,
    isSearch: true,
    categories: formattedCategories,
    isFilterSectionShown: isSearchResultsPage,
    productCount: total,
    currentSubcategoryId: currentCategory.parent_id ? currentCategory._id : null,
    allSubcategories
  };
};
const mapDispatchToProps = dispatch => ({
  sortProducts: ({
    sorting,
    categoryId
  }) => {
    dispatch(sortProducts({
      sorting,
      categoryId
    }));
  },
  onLinkClick: () => {
    dispatch(changePageParams('scrollTop', true));
  }
});
const searchResultsPageMatDispatchToProps = dispatch => ({
  sortProducts: ({
    sorting,
    categoryId
  }) => {
    dispatch(searchResultsPageSortProducts({
      sorting,
      categoryId
    }));
  },
  onLinkClick: () => {
    dispatch(changePageParams('scrollTop', true));
  }
});
export const ProductListingPanel = connect(categoriesMenuSelector, mapDispatchToProps)(ProductListingPanelComponent);
export const SearchResultsPageProductListingPanel = connect(searchResultsPageCategoriesMenuSelector, searchResultsPageMatDispatchToProps)(ProductListingPanelComponent);