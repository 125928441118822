import _isFinite from "lodash/isFinite";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Price } from 'swag-common/utils/price';
import { PromocodeMeasurementEnum } from '../../constants/promocode';
export function getDiscountForItems(order, itemsForDiscount) {
  if (itemsForDiscount.every(item => _isFinite(item.absoluteItemDiscount))) {
    // @todo. auto generated. fix this error
    // @ts-ignore
    return itemsForDiscount.reduce((acc, item) => acc + item.absoluteItemDiscount, 0);
  }
  const {
    setupFeeDiscount
  } = Price.getAdditionalDiscountValues(_objectSpread({}, order, {
    items: itemsForDiscount
  }));
  const {
    setupFeeDiscount: setupFeeDiscountTotal
  } = Price.getAdditionalDiscountValues(order);
  const priceBeforeDiscount = Price.getPriceOfOrderWithoutPromocodeDiscount(order);
  if (order.measurement === PromocodeMeasurementEnum.PERCENTAGES) {
    return Price.getDiscountLessThanTotal(priceBeforeDiscount, Price.getDiscount(itemsForDiscount, order.promocodeDiscount, order.measurement, setupFeeDiscount));
  }
  const orderItemsTotal = order.items.reduce((total, next) => total + next.price, 0);
  const itemsTotal = itemsForDiscount.reduce((total, next) => total + next.price, 0);
  const discountPart = orderItemsTotal ? itemsTotal / orderItemsTotal : 0;
  const discount = Price.getDiscountLessThanTotal(priceBeforeDiscount, Price.getDiscount(order.items, order.promocodeDiscount, order.measurement, setupFeeDiscountTotal));
  return Math.round(discount * discountPart);
}