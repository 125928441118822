function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import nearestColor from 'nearest-color';
export const getVariantWithNearestColor = (variants, color) => {
  if (!variants.length) {
    return null;
  }
  const colors = variants.reduce((previous, current) => {
    if (current.isHidden) {
      return previous;
    }
    return _objectSpread({}, previous, {
      [current.name]: current.color
    });
  }, {});
  const detectedNearestColor = findNearestColor(color, colors);
  return variants.find(variant => variant.color === detectedNearestColor);
};
const findNearestColor = (searchColor, whereToSearchColors) => {
  const find = nearestColor.from(whereToSearchColors);
  const nearest = find(searchColor);
  return nearest.value;
};