import _pick2 from "lodash/pick";
import { createSelector } from 'reselect';
import { ZAPIER_FORM, SHOPIFY_FORM, RESELLER_FORM } from '../redux.constants';
import { budget, boxes, employees, yearly, rewards, eachReward, apiUses, reasons, apps } from './landings.data';
const isShownSelector = state => state.landing.isShown;
const textSelector = state => state.landing.text;
const additionalTextSelector = state => state.landing.additionalText;
export const contactDeliveryBlockSelector = () => ({
  budget,
  boxes,
  reasons
});
export const contactApiBlockSelector = () => ({
  employees,
  apiUses
});
export const contactZapierSelector = () => ({
  employees,
  yearly,
  apps
});
export const contactBlockSelector = () => ({
  employees,
  yearly,
  rewards,
  eachReward
});
export const contactResellerSelector = () => ({
  employees,
  yearly
});
export const formSelector = createSelector(isShownSelector, textSelector, additionalTextSelector, (isShown, text, additionalText) => ({
  isShown,
  text,
  additionalText
}));
export const zapierFormSelector = createSelector(state => state.form, form => form[ZAPIER_FORM]);
export const shopifyFormSelector = createSelector(state => state.form, form => form[SHOPIFY_FORM]);
export const resellerFormSelector = createSelector(state => state.form, form => form[RESELLER_FORM]);
export const resellerLandingDataSelector = state => state.landing.resellerLandingData;
export const resellerLandingDataDocsSelector = createSelector(resellerLandingDataSelector, landingData => _pick2(landingData, ['termsOfServiceHTML', 'termsOfServiceUpdatedAt']));