import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { ERROR_CODE_TO_EXPLANATION } from 'swag-client-common/constants/error-code-explanations';
import { EmailApi } from '../../api/email.api';
import { itemsToShareSelector } from './share-design.selectors';
import { validateShareDesignForm } from './share-design.utils';
const PREFIX = 'SHARE_DESIGN_';
export const SELECT_FIELD = `${PREFIX}SELECT_FIELD`;
export const selectField = (field, value) => ({
  type: SELECT_FIELD,
  payload: {
    field,
    value
  }
});
export const EDIT_EMAIL = `${PREFIX}EDIT_EMAIL`;
export const editEmail = email => ({
  type: EDIT_EMAIL,
  payload: {
    email
  }
});
export const SEND_MOCKUP_REQUEST = `${PREFIX}SEND_MOCKUP_REQUEST`;
export const SEND_MOCKUP_SUCCESS = `${PREFIX}SEND_MOCKUP_SUCCESS`;
export const SEND_MOCKUP_FAILURE = `${PREFIX}SEND_MOCKUP_FAILURE`;
export const sendMockup = createAsyncAction({
  preFn: (dispatch, getState) => {
    const state = getState();
    const error = validateShareDesignForm(state.shareDesign);
    if (error) {
      dispatch({
        type: SEND_MOCKUP_FAILURE,
        error
      });
      return true;
    }
    dispatch({
      type: SEND_MOCKUP_REQUEST
    });
  },
  transformData: (data, getState) => {
    const state = getState();
    const {
      customerMockups,
      specs,
      descr,
      prodTime,
      price,
      quantity,
      email,
      from,
      productName
    } = state.shareDesign;
    const orderItems = itemsToShareSelector(state);
    return {
      options: {
        customerMockups,
        specs,
        descr,
        prodTime,
        price,
        quantity,
        email
      },
      emailData: {
        from,
        productName
      },
      orderItems
    };
  },
  fn: EmailApi.sendMockup,
  success: () => ({
    type: SEND_MOCKUP_SUCCESS
  }),
  error: () => ({
    type: SEND_MOCKUP_FAILURE
  }),
  postSuccess: dispatch => {
    dispatch(hidePopup());
    dispatch(showNotification({
      text: 'Success! Check your inbox ',
      hideIn: 6000
    }));
  },
  postError: (dispatch, getState, error) => {
    if (ERROR_CODE_TO_EXPLANATION[error.code]) {
      dispatch(showNotification({
        text: error.message
      }));
    } else {
      dispatch(showPopup({
        popup: 'bigAmountOfMockups'
      }));
    }
  }
});