const scriptPositions = ['head', 'body'];
export const loadScript = params => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.src = params.src;
  script.async = params.isAsync;
  script.type = params.type;
  if (params.position === 'head') {
    document.head.appendChild(script);
  } else {
    document.body.appendChild(script);
  }
  script.addEventListener('load', () => {
    resolve({
      isLoaded: true
    });
  });
  script.addEventListener('error', () => {
    reject({
      isLoaded: false
    });
  });
});