import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import GamificationApi from '../../api/gamification.api';
import { loadingTurnOn, loadingTurnOff } from '../common/common.actions';
const PREFIX = 'GAMIFICATION_';
export const GET_LEVELS_SUCCESS = `${PREFIX}GET_LEVELS_SUCCESS`;
export const getGamificationLevels = createAsyncAction({
  fn: GamificationApi.getLevels,
  preFn: dispatch => dispatch(loadingTurnOn()),
  success: ({
    levels
  }) => ({
    type: GET_LEVELS_SUCCESS,
    payload: {
      levels
    }
  }),
  postSuccess: dispatch => dispatch(loadingTurnOff()),
  error: error => ({
    type: 'LOADING_ERROR',
    error
  }),
  postError: dispatch => {
    dispatch(loadingTurnOff());
    dispatch(showPopup({
      popup: 'notifyPopup',
      title: 'Error while receiving gamification levels',
      textBody: 'There was error while getting gamification levels.'
    }));
  }
});
export const GET_TRANSACTIONS_SUCCESS = `${PREFIX}GET_GAM_TRANSACTIONS_SUCCESS`;
export const getGamificationTransactions = createAsyncAction({
  fn: GamificationApi.getTransactions,
  preFn: dispatch => dispatch(loadingTurnOn()),
  success: transactionsByLevels => ({
    type: GET_TRANSACTIONS_SUCCESS,
    payload: {
      transactionsByLevels
    }
  }),
  postSuccess: dispatch => dispatch(loadingTurnOff()),
  error: error => ({
    type: 'LOADING_ERROR',
    error
  }),
  postError: dispatch => {
    dispatch(loadingTurnOff());
    dispatch(showPopup({
      popup: 'notifyPopup',
      title: 'Error while receiving gamifications history',
      textBody: 'There was error while getting gamifications history.'
    }));
  }
});
export const GET_SCORE_SUCCESS = `${PREFIX}GET_SCORE_SUCCESS`;
export const getScoreHistory = createAsyncAction({
  fn: GamificationApi.getScoreHistory,
  preFn: dispatch => dispatch(loadingTurnOn()),
  success: body => ({
    type: GET_SCORE_SUCCESS,
    body
  }),
  postSuccess: dispatch => dispatch(loadingTurnOff()),
  error: error => ({
    type: 'LOADING_ERROR',
    error
  }),
  postError: dispatch => {
    dispatch(loadingTurnOff());
    dispatch(showPopup({
      popup: 'notifyPopup',
      title: 'Error while receiving gamification score',
      textBody: 'There was error while getting gamification score.'
    }));
  }
});
export const SET_LEVEL_PICKER = `${PREFIX}SET_LEVEL_PICKER`;
export const lvlOnChange = val => dispatch => {
  dispatch({
    type: SET_LEVEL_PICKER,
    val
  });
};
export const TOGGLE_DRAWER = `${PREFIX}TOGGLE_DRAWER`;
export const toggleDrawer = (type, open = false) => dispatch => {
  dispatch({
    type: TOGGLE_DRAWER,
    payload: {
      type,
      open
    }
  });
};