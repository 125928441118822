import isBox from '../custom-store-product/product-is-box.util';
import { isRelatedItem } from '../items/is-item-shared-or-related';
function checkIsItemAssociated(lineItems, itemToCheck) {
  const items = [];
  lineItems.forEach(item => {
    if (isBox(item.prod)) {
      // @todo. auto generated. fix this error
      // @ts-ignore
      return items.push(...item.products);
    }

    // @todo. auto generated. fix this error
    // @ts-ignore
    items.push(item);
  });
  return isRelatedItem(itemToCheck, items, true);
}
export default checkIsItemAssociated;