import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isLimitedEditionSelector } from '../../../../customer/redux/product/product.selectors';
import { toggleTOSCheckbox } from '../../../../customer/redux/checkout/checkout.actions';
import { isTOSAcceptedSelector } from '../../../../customer/redux/checkout/checkout.selectors';
import { TosCheckbox as Component } from './tos-checkbox';
const mapStateToProps = createStructuredSelector({
  isChecked: isTOSAcceptedSelector,
  isLimitedEdition: isLimitedEditionSelector
});
const mapDispatchToProps = dispatch => ({
  onChange: isChecked => {
    dispatch(toggleTOSCheckbox(isChecked));
  }
});
export const TosCheckbox = connect(mapStateToProps, mapDispatchToProps)(Component);