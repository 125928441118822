function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { FULL_DESIGN_DEFAULT_VARIANT_INDEX } from 'swag-common/constants/product.constants';
import { FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME } from 'swag-common/constants/custom-store.constants';
export const getVariantIndexesInCustomStore = (product, isFullDesignMode, isBox) => {
  if (isFullDesignMode || isBox) {
    return [FULL_DESIGN_DEFAULT_VARIANT_INDEX];
  }
  if (!product) {
    return [];
  }
  const {
    variants,
    swagProductId
  } = product;
  if (!swagProductId) {
    return [];
  }
  const {
    variants: swagProductVariants
  } = swagProductId;
  const customStoreProductColors = getCustomStoreProductColors(variants || []);
  return swagProductVariants.reduce((customProductsVariants, {
    variantId
  }, variantIndex) => {
    if (Object.prototype.hasOwnProperty.call(customStoreProductColors, variantId)) {
      return [...customProductsVariants, variantIndex];
    }
    return customProductsVariants;
  }, []);
};
export const getSizeIndex = product => {
  var _product$variants, _product$variants$;
  return (product === null || product === void 0 ? void 0 : (_product$variants = product.variants) === null || _product$variants === void 0 ? void 0 : (_product$variants$ = _product$variants[0]) === null || _product$variants$ === void 0 ? void 0 : _product$variants$.sizeIndex) || 0;
};
export const getVariantIndexesInCustomStoreWithColors = (product, isFullDesign, isBox) => {
  if (isFullDesign || isBox) {
    return {
      [FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME]: FULL_DESIGN_DEFAULT_VARIANT_INDEX
    };
  }
  if (!product) {
    return [];
  }
  const {
    variants,
    swagProductId
  } = product;
  if (!swagProductId) {
    return [];
  }
  const {
    variants: swagProductVariants
  } = swagProductId;
  const customStoreProductColors = getCustomStoreProductColors(variants || []);
  return swagProductVariants.reduce((customProductsVariants, {
    variantId
  }, variantIndex) => {
    if (Object.prototype.hasOwnProperty.call(customStoreProductColors, variantId)) {
      return _objectSpread({}, customProductsVariants, {
        [variantId]: variantIndex
      });
    }
    return customProductsVariants;
  }, {});
};
function getCustomStoreProductColors(variants) {
  return variants.reduce((colors, {
    colorValue,
    colorVariantId
  }) => {
    if (colorVariantId) {
      colors[colorVariantId] = colorValue;
    }
    return colors;
  }, {});
}