import { MILLISECONDS_IN_ONE_DAY, MILLISECONDS_IN_ONE_MINUTE, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY } from 'swag-common/constants/date';
import { isSameDate, isUSHoliday } from '.';
export const getUTCLastDayOfMonth = date => new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 0));
export const getLastDayOfMonth = date => new Date(date.getFullYear(), date.getMonth() + 1, 0);
export const getFirstDayOfMonth = date => new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0));
export const getLastDayOfMonthNumber = date => getLastDayOfMonth(date).getDate();
export const getFirstDayOfMonthNumber = date => getFirstDayOfMonth(date).getDate();
export const getLastMomentOfMonth = date => {
  const last = getUTCLastDayOfMonth(date);
  return new Date(Date.UTC(last.getFullYear(), last.getMonth(), last.getDate(), 23, 59, 59, 999));
};
export const setStartOfDay = date => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
export const setEndOfDay = date => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));
export const getYesterday = () => getDateDaysAgo(1);
export const getDateDaysAgo = days => {
  const today = new Date();
  return new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - days, 23, 59, 59, 999));
};
export const getDaysDiff = (start, end, fullDaysDiff) => {
  const startTS = start.getTime();
  const endTS = end.getTime();
  const diffTS = Math.abs(endTS - startTS);
  const divResult = diffTS / MILLISECONDS_IN_ONE_DAY;
  return fullDaysDiff ? Math.floor(divResult) : Math.ceil(divResult);
};
export const getBusinessDaysDiff = (start, end) => {
  let tmpDate;
  let businessDaysDiff = 0;
  const daysDiff = getDaysDiff(start, end, true);
  const startTS = start.getTime();
  const endTS = end.getTime();
  if (startTS > endTS) {
    tmpDate = new Date(end);
  } else {
    tmpDate = new Date(start);
  }
  let count = 0;
  while (count < daysDiff) {
    tmpDate = addDays(tmpDate, 1);
    count++;
    if (isWorkingDay(tmpDate)) {
      businessDaysDiff++;
    }
  }
  return businessDaysDiff;
};
export const addDay = date => addDays(date, 1);
export const addDays = (date, count) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + count, date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
};
export const subtractDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};
export const addHours = (date, hours) => {
  const result = new Date(date);
  result.setHours(result.getHours() + hours);
  return result;
};
export const subtractHours = (date, hours) => {
  const result = new Date(date);
  result.setHours(result.getHours() - hours);
  return result;
};
export const isLastDayOfMonth = date => {
  const lastDay = getLastDayOfMonth(date);
  return isSameDate(lastDay, date);
};
export const offsetRemover = date => {
  const offSet = date.getTimezoneOffset();
  const withoutOffset = new Date(date);
  withoutOffset.setMinutes(date.getUTCMinutes() + offSet);
  return withoutOffset;
};
export const addCurrentTimezoneOffset = date => {
  const timeOffsetInMS = date.getTimezoneOffset() * MILLISECONDS_IN_ONE_MINUTE;
  const dateWithOffset = new Date(date);
  dateWithOffset.setTime(date.getTime() - timeOffsetInMS);
  return dateWithOffset;
};
export const isWorkingDay = tmpDate => [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY].includes(tmpDate.getDay()) && !isUSHoliday(tmpDate);