function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ESTIMATE_DISTRIBUTION_PRODUCT_TYPE } from 'swag-common/constants/inventory';
import { HIDE_LOADER, SHOW_LOADER } from 'swag-client-common/redux/common/common.actions';
import { EstimateDistributionFeeScreen } from '../../constants/estimate-distribution-fee-screen.enum';
import { changeValueInArrayByIndex, deleteItemInArrayByIndex } from '../../utils/data.utils';
import { ADD_DISTRIBUTION_FEE_ADDRESS, CALCULATE_DISTRIBUTION_FEES_COSTS_FAILURE, CALCULATE_DISTRIBUTION_FEES_COSTS_REQUEST, CALCULATE_DISTRIBUTION_FEES_COSTS_SUCCESS, DECREMENT_DISTRIBUTION_FEE_PRODUCT_QUANTITY, DELETE_DISTRIBUTION_FEE_COUNTRY_AND_ZIP, INCREMENT_DISTRIBUTION_FEE_PRODUCT_QUANTITY, INIT_DISTRIBUTION_FEE, RESET_DISTRIBUTION_FEE, SELECT_SHIPPING_METHOD_SUCCESS, SET_DISTRIBUTION_FEE_COUNTRY_AND_ZIP_QUANTITY, SET_DISTRIBUTION_FEE_ESTIMATE_RECIPIENTS_QUANTITY, SET_DISTRIBUTION_FEE_ESTIMATE_STATES_FOR_COUNTRY, SET_DISTRIBUTION_FEE_PRODUCT_QUANTITY, SET_DISTRIBUTION_FEE_SCREEN, WIDGET_ON_LANDING_SET_ESTIMATES, WIDGET_ON_LANDING_SET_PRODUCT_TYPE, INIT_CALCULATION_RESULTS } from './distribution-fee-estimation.actions';
const distributionFeeEstimateInitialState = {
  isLoading: false,
  addresses: [],
  error: '',
  statesList: [],
  screen: EstimateDistributionFeeScreen.products,
  quantitiesInPackage: {},
  recipientsQuantity: 0,
  checkoutResults: [],
  type: ESTIMATE_DISTRIBUTION_PRODUCT_TYPE.product,
  widgetResults: null
};
export function distributionFeeEstimationReducer(state = distributionFeeEstimateInitialState, action) {
  switch (action.type) {
    case INIT_DISTRIBUTION_FEE:
      {
        const {
          payload: {
            productIds
          }
        } = action;
        return _objectSpread({}, distributionFeeEstimateInitialState, {
          screen: EstimateDistributionFeeScreen.products,
          quantitiesInPackage: productIds.reduce((acc, productId) => _objectSpread({}, acc, {
            [productId]: 1
          }), {})
        });
      }
    case SET_DISTRIBUTION_FEE_PRODUCT_QUANTITY:
      {
        const {
          payload: {
            productId,
            quantity
          }
        } = action;
        return _objectSpread({}, state, {
          quantitiesInPackage: _objectSpread({}, state.quantitiesInPackage, {
            [productId]: Number(quantity)
          })
        });
      }
    case INCREMENT_DISTRIBUTION_FEE_PRODUCT_QUANTITY:
      {
        const {
          payload: {
            productId
          }
        } = action;
        const quantity = (state.quantitiesInPackage[productId] || 0) + 1;
        return _objectSpread({}, state, {
          quantitiesInPackage: _objectSpread({}, state.quantitiesInPackage, {
            [productId]: quantity
          })
        });
      }
    case DECREMENT_DISTRIBUTION_FEE_PRODUCT_QUANTITY:
      {
        const {
          payload: {
            productId
          }
        } = action;
        const quantity = Math.max(0, (state.quantitiesInPackage[productId] || 0) - 1);
        return _objectSpread({}, state, {
          quantitiesInPackage: _objectSpread({}, state.quantitiesInPackage, {
            [productId]: quantity
          })
        });
      }
    case SET_DISTRIBUTION_FEE_SCREEN:
      {
        const {
          payload: {
            screen
          }
        } = action;
        return _objectSpread({}, state, {
          screen
        });
      }
    case CALCULATE_DISTRIBUTION_FEES_COSTS_SUCCESS:
      {
        const {
          costs
        } = action.payload;
        return _objectSpread({}, state, {
          isLoading: false,
          checkoutResults: costs
        });
      }
    case SELECT_SHIPPING_METHOD_SUCCESS:
      {
        const {
          costs
        } = action.payload;
        return _objectSpread({}, state, {
          checkoutResults: costs
        });
      }
    case SET_DISTRIBUTION_FEE_COUNTRY_AND_ZIP_QUANTITY:
      {
        const {
          payload: {
            index,
            quantity
          }
        } = action;
        return _objectSpread({}, state, {
          addresses: changeValueInArrayByIndex(index, state.addresses, {
            quantity
          })
        });
      }
    case ADD_DISTRIBUTION_FEE_ADDRESS:
      {
        return _objectSpread({}, state, {
          addresses: [...state.addresses, action.payload]
        });
      }
    case DELETE_DISTRIBUTION_FEE_COUNTRY_AND_ZIP:
      {
        const {
          payload: {
            index
          }
        } = action;
        return _objectSpread({}, state, {
          addresses: deleteItemInArrayByIndex(index, state.addresses)
        });
      }
    case SET_DISTRIBUTION_FEE_ESTIMATE_RECIPIENTS_QUANTITY:
      {
        return _objectSpread({}, state, {
          recipientsQuantity: action.payload
        });
      }
    case SET_DISTRIBUTION_FEE_ESTIMATE_STATES_FOR_COUNTRY:
      {
        return _objectSpread({}, state, {
          statesList: action.payload
        });
      }
    case WIDGET_ON_LANDING_SET_PRODUCT_TYPE:
      {
        return _objectSpread({}, state, {
          type: action.payload,
          widgetResults: null,
          error: ''
        });
      }
    case WIDGET_ON_LANDING_SET_ESTIMATES:
      {
        return _objectSpread({}, state, {
          widgetResults: action.payload,
          error: ''
        });
      }
    case SHOW_LOADER:
    case CALCULATE_DISTRIBUTION_FEES_COSTS_REQUEST:
      {
        return _objectSpread({}, state, {
          isLoading: true
        });
      }
    case HIDE_LOADER:
    case CALCULATE_DISTRIBUTION_FEES_COSTS_FAILURE:
      {
        return _objectSpread({}, state, {
          isLoading: false
        });
      }
    case RESET_DISTRIBUTION_FEE:
      {
        return _objectSpread({}, distributionFeeEstimateInitialState);
      }
    case INIT_CALCULATION_RESULTS:
      {
        return _objectSpread({}, state, {
          widgetResults: null
        });
      }
    default:
      {
        return state;
      }
  }
}