import { PRINT_SIDE } from 'swag-common/constants/product.constants';
export const getProductVariantImage = ({
  product: {
    variants
  },
  variantIndex = 0,
  sideName = PRINT_SIDE.front,
  sizeIndex = 0
}) => {
  var _variantImages$front;
  const variantImages = variants[variantIndex].images;
  let image = (_variantImages$front = variantImages.front) === null || _variantImages$front === void 0 ? void 0 : _variantImages$front[sizeIndex];
  if (!image) {
    var _Object$values$find, _variantImage$sizeInd;
    const variantImage = (_Object$values$find = Object.values(variantImages).find(array => {
      var _array$sizeIndex;
      return String(array === null || array === void 0 ? void 0 : (_array$sizeIndex = array[sizeIndex]) === null || _array$sizeIndex === void 0 ? void 0 : _array$sizeIndex.name).toLowerCase() === PRINT_SIDE.front;
    })) !== null && _Object$values$find !== void 0 ? _Object$values$find : Object.values(variantImages)[0];
    image = (_variantImage$sizeInd = variantImage === null || variantImage === void 0 ? void 0 : variantImage[sizeIndex]) !== null && _variantImage$sizeInd !== void 0 ? _variantImage$sizeInd : {
      url: ''
    };
  }
  return image;
};