import { connect } from 'react-redux';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { DEFAULT_INVENTORY_OPTION } from 'swag-common/constants/inventory';
import { selectInventory } from '../../../redux/inventory/inventory.actions';
import { toggleCreateMyInventorySelected } from '../../../redux/checkout/checkout.actions';
import { ConfirmCreateMyInventoryPopupComponent } from './confirm-create-my-inventory-popup';
const mapDispatchToProps = dispatch => ({
  onConfirm: () => {
    dispatch(selectInventory(DEFAULT_INVENTORY_OPTION.value));
    dispatch(toggleCreateMyInventorySelected(true));
    dispatch(hidePopup());
  },
  onCancel: () => {
    dispatch(hidePopup());
  }
});
export const ConfirmCreateMyInventoryPopup = connect(null, mapDispatchToProps)(ConfirmCreateMyInventoryPopupComponent);