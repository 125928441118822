import { PASSWORD_REQUIREMENTS, MIN_PASSWORD_LENGTH, ONE_LOWERCASE_CHARACTER, ONE_UPPERCASE_CHARACTER, ONE_NUMERIC_CHARACTER, ONE_SPECIAL_CHARACTER, MIN_PASSWORD_LENGTH_CHARACTERS, AT_LEAST_ONE_LOWERCASE_CHARACTER_REGEXP, AT_LEAST_ONE_UPPERCASE_CHARACTER_REGEXP, AT_LEAST_ONE_NUMERIC_CHARACTER_REGEXP, AT_LEAST_ONE_SPECIAL_CHARACTER_REGEXP } from 'swag-common/constants/validations';
const getRuleIdx = ruleStr => {
  return PASSWORD_REQUIREMENTS.findIndex(rule => rule === ruleStr);
};
export const getCompletedPasswordRequirements = (password = '') => {
  const completedPasswordRequirements = [];
  if (AT_LEAST_ONE_LOWERCASE_CHARACTER_REGEXP.test(password)) {
    completedPasswordRequirements.push(getRuleIdx(ONE_LOWERCASE_CHARACTER));
  }
  if (AT_LEAST_ONE_UPPERCASE_CHARACTER_REGEXP.test(password)) {
    completedPasswordRequirements.push(getRuleIdx(ONE_UPPERCASE_CHARACTER));
  }
  if (AT_LEAST_ONE_NUMERIC_CHARACTER_REGEXP.test(password)) {
    completedPasswordRequirements.push(getRuleIdx(ONE_NUMERIC_CHARACTER));
  }
  if (AT_LEAST_ONE_SPECIAL_CHARACTER_REGEXP.test(password)) {
    completedPasswordRequirements.push(getRuleIdx(ONE_SPECIAL_CHARACTER));
  }
  if (password.length >= MIN_PASSWORD_LENGTH) {
    completedPasswordRequirements.push(getRuleIdx(MIN_PASSWORD_LENGTH_CHARACTERS));
  }
  return completedPasswordRequirements;
};