import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
export const isProductSpecialNotecard = content => {
  var _content$product$swag;
  return ((_content$product$swag = content.product.swagProductId) === null || _content$product$swag === void 0 ? void 0 : _content$product$swag.specialProductType) === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD;
};
export const isProductSpecialCrinklePaper = content => {
  var _content$product$swag2;
  return ((_content$product$swag2 = content.product.swagProductId) === null || _content$product$swag2 === void 0 ? void 0 : _content$product$swag2.specialProductType) === SPECIAL_PRODUCT_TYPES.BOX_CRINKLE_PAPER;
};
export const isProductWithoutSpecificTypes = content => {
  return !isProductSpecialCrinklePaper(content) && !isProductSpecialNotecard(content);
};