function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { GET_PAST_WORK_IMAGES_SUCCESS, GET_PAST_WORK_IMAGES_FAIL, GET_PAST_WORK_IMAGES_STARTED, SET_PAST_WORK_CURRENT_PAGE } from './past-work.actions';
const initialState = {
  images: [],
  pagination: {
    page: 0
  },
  isLoading: false
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PAST_WORK_IMAGES_STARTED:
      {
        return _objectSpread({}, state, {
          isLoading: true
        });
      }
    case GET_PAST_WORK_IMAGES_SUCCESS:
      {
        const {
          images
        } = action.payload;
        return _objectSpread({}, state, {
          images: [...images],
          isLoading: false
        });
      }
    case SET_PAST_WORK_CURRENT_PAGE:
      {
        const {
          page
        } = action.payload;
        return _objectSpread({}, state, {
          pagination: _objectSpread({}, state.pagination, {
            page
          })
        });
      }
    case GET_PAST_WORK_IMAGES_FAIL:
      {
        return _objectSpread({}, initialState);
      }
    default:
      return state;
  }
}