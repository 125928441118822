function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { ActionConstants, DEFAULT_PRODUCTS_PER_PAGE } from 'swag-common/constants/main-swag.constants';
import FavouriteProductsApi from '../../api/favourite-products.api';
const PREFIX = 'FAVOURITE_PRODUCTS_';
export const GET_ALL_FAV_PRODUCT_IDS_STARTED = `${PREFIX}GET_ALL_FAV_PRODUCT_IDS_STARTED`;
export const GET_ALL_FAV_PRODUCT_IDS_ERROR = `${PREFIX}GET_ALL_FAV_PRODUCT_IDS_ERROR`;
export const GET_ALL_FAV_PRODUCT_IDS_SUCCESS = `${PREFIX}GET_ALL_FAV_PRODUCT_IDS_SUCCESS`;
export const getAllFavouriteProductIds = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_ALL_FAV_PRODUCT_IDS_STARTED
    });
  },
  fn: FavouriteProductsApi.getAllFavouriteProductIds,
  success: data => ({
    type: GET_ALL_FAV_PRODUCT_IDS_SUCCESS,
    payload: data
  }),
  error: error => ({
    type: GET_ALL_FAV_PRODUCT_IDS_ERROR,
    error
  })
});
export const GET_FAV_PRODUCTS_STARTED = `${PREFIX}GET_FAV_PRODUCTS_STARTED`;
export const GET_FAV_PRODUCTS_ERROR = `${PREFIX}GET_FAV_PRODUCTS_ERROR`;
export const GET_FAV_PRODUCTS_SUCCESS = `${PREFIX}GET_FAV_PRODUCTS_SUCCESS`;
export const getFavouriteProducts = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_FAV_PRODUCTS_STARTED
    });
  },
  transformData: data => {
    return _objectSpread({}, data, {
      perpage: data.perpage || DEFAULT_PRODUCTS_PER_PAGE
    });
  },
  fn: FavouriteProductsApi.getFavouriteProducts,
  success: (data, transformedData, dispatch, originalData) => {
    const payload = _objectSpread({}, data);
    if (originalData.perpage) {
      delete payload.page;
    }
    return {
      type: GET_FAV_PRODUCTS_SUCCESS,
      payload
    };
  },
  error: error => ({
    type: GET_FAV_PRODUCTS_ERROR,
    error
  })
});
export const TOGGLE_FAVOURITE_PRODUCT_STARTED = `${PREFIX}TOGGLE_FAVOURITE_PRODUCT_STARTED`;
export const TOGGLE_FAVOURITE_PRODUCT_ERROR = `${PREFIX}TOGGLE_FAVOURITE_PRODUCT_ERROR`;
export const ADD_FAVOURITE_PRODUCT_SUCCESS = `${PREFIX}ADD_FAVOURITE_PRODUCT_SUCCESS`;
export const REMOVE_FAVOURITE_PRODUCT_SUCCESS = `${PREFIX}REMOVE_FAVOURITE_PRODUCT_SUCCESS`;
export const toggleFavouriteProduct = ({
  productId,
  nextIsFavourite,
  productAccumulatorId
}) => async dispatch => {
  try {
    const action = nextIsFavourite ? ActionConstants.ADD : ActionConstants.REMOVE;
    const data = {
      action
    };
    if (productId) {
      data.productId = productId;
    } else {
      data.productAccumulatorId = productAccumulatorId;
    }
    dispatch({
      type: TOGGLE_FAVOURITE_PRODUCT_STARTED
    });
    const payload = await FavouriteProductsApi.toggle(data);
    if (action === ActionConstants.ADD) {
      return dispatch({
        type: ADD_FAVOURITE_PRODUCT_SUCCESS,
        payload
      });
    }
    return dispatch({
      type: REMOVE_FAVOURITE_PRODUCT_SUCCESS,
      payload
    });
  } catch (error) {
    dispatch({
      type: TOGGLE_FAVOURITE_PRODUCT_ERROR,
      error
    });
  }
};
export const RESET_FAV_PRODUCTS = `${PREFIX}RESET_FAV_PRODUCTS`;
export const resetFavouriteProductsState = () => ({
  type: RESET_FAV_PRODUCTS
});