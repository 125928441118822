import _getFormMeta from "redux-form/es/getFormMeta";
import _formValueSelector from "redux-form/es/formValueSelector";
import { createSelector } from 'reselect';
import { ReduxFormNames } from 'swag-client-common/constants';
import { getCompletedPasswordRequirements } from 'swag-client-common/utils/get-password-requirements.utils';
import { CONFIRM_ACTION_EXPIRED, CONFIRM_ACTION_USED } from 'swag-common/constants/error-codes';
import { MAX_PASSWORD_LENGTH, PASSWORD_REQUIREMENTS } from 'swag-common/constants/validations';
import { ConfirmationActionType } from 'swag-common/interfaces/confirmation-action.interface';
const isLoadingSelector = state => state.setPassword.isLoading;
const errorSelector = state => state.setPassword.err;
const isErrorSelector = createSelector(errorSelector, error => Boolean(error));
const isSuccessSelector = state => state.setPassword.isSuccess;
const modeSelector = state => state.setPassword.mode;
const errorMessageSelector = createSelector(errorSelector, error => {
  if (!error) {
    return '';
  }
  if (error.code === CONFIRM_ACTION_USED) {
    return 'This link was already used.';
  }
  if (error.code === CONFIRM_ACTION_EXPIRED) {
    const messages = ['This link is expired'];
    if (error.type === ConfirmationActionType.RESET_PASSWORD) {
      messages.push('You can request reset password again.');
    }
    return messages.join('. ');
  }
  return error.message;
});
const passwordSelector = state => {
  return _formValueSelector(ReduxFormNames.SetPasswordFromPageForm)(state, 'password') || '';
};
const completedPasswordRequirementsSelector = createSelector(passwordSelector, password => {
  return getCompletedPasswordRequirements(password);
});
const isPasswordLengthMoreThanMaxAllowedCharactersSelector = createSelector(passwordSelector, password => {
  return password.length > MAX_PASSWORD_LENGTH;
});
const isAllPasswordRequirementsCompletedSelector = createSelector(isPasswordLengthMoreThanMaxAllowedCharactersSelector, completedPasswordRequirementsSelector, (isPasswordLengthMoreThanMaxAllowedCharacters, completedPasswordRequirements) => {
  return !isPasswordLengthMoreThanMaxAllowedCharacters && completedPasswordRequirements.length === PASSWORD_REQUIREMENTS.length;
});
const isPasswordInputFocusedSelector = state => {
  var _formMeta$password;
  const formMeta = _getFormMeta(ReduxFormNames.SetPasswordFromPageForm)(state);
  return !!(formMeta !== null && formMeta !== void 0 && (_formMeta$password = formMeta.password) !== null && _formMeta$password !== void 0 && _formMeta$password.active);
};
export { isLoadingSelector, isErrorSelector, errorMessageSelector, isSuccessSelector, modeSelector, passwordSelector, completedPasswordRequirementsSelector, isAllPasswordRequirementsCompletedSelector, isPasswordLengthMoreThanMaxAllowedCharactersSelector, isPasswordInputFocusedSelector };