function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { isShowErrorPopupShownSelector, popupParamsSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { LOGO_UPLOAD_ERROR_TYPE, CART_ADD_ITEM_ERROR_TYPE, CART_ORDER_VERSION_ERROR_TYPE, LOGO_UPLOAD_SIZE_LIMIT_ERROR_TYPE, SUPER_SPEED_ERROR_TYPE, UNREGISTERED_USER_INVITE_ERROR_TYPE } from 'swag-client-common/constants';
import { SUPER_SPEED_ERR_MSG } from 'swag-common/constants/error.const';
import { isLimitedEditionSelector } from '../../../redux/product/product.selectors';
import { LOGO_UPLOAD_ERROR_TITLE, ADD_ITEM_CART_ERROR_TITLE, INVALID_ORDER_VERSION_ERROR_TITLE, SUPER_SPEED_ERROR_TITLE, DEFAULT_ERROR_MESSAGE, LOGO_UPLOAD_ERROR_MESSAGE, LOGO_UPLOAD_SIZE_LIMIT_ERROR_MESSAGE } from './constants';
import { ShowErrorPopupComponent } from './show-error-popup';
const defaultPopupData = {
  title: 'Something went wrong',
  isButtonShown: true,
  buttonText: 'OK'
};
const dataSelector = createSelector(popupParamsSelector, ({
  type,
  errorMessage,
  onButtonClick
}) => {
  switch (type) {
    case CART_ORDER_VERSION_ERROR_TYPE:
      return _objectSpread({}, defaultPopupData, {
        title: INVALID_ORDER_VERSION_ERROR_TITLE,
        errorMessage,
        buttonText: 'Refresh',
        onButtonClick: () => window.location.reload()
      });
    case CART_ADD_ITEM_ERROR_TYPE:
      return _objectSpread({}, defaultPopupData, {
        title: ADD_ITEM_CART_ERROR_TITLE,
        errorMessage
      });
    case SUPER_SPEED_ERROR_TYPE:
      return _objectSpread({}, defaultPopupData, {
        title: SUPER_SPEED_ERROR_TITLE,
        errorMessage: SUPER_SPEED_ERR_MSG
      });
    case LOGO_UPLOAD_ERROR_TYPE:
      return _objectSpread({}, defaultPopupData, {
        title: LOGO_UPLOAD_ERROR_TITLE,
        errorMessage: LOGO_UPLOAD_ERROR_MESSAGE
      });
    case LOGO_UPLOAD_SIZE_LIMIT_ERROR_TYPE:
      return _objectSpread({}, defaultPopupData, {
        title: LOGO_UPLOAD_ERROR_TITLE,
        errorMessage: LOGO_UPLOAD_SIZE_LIMIT_ERROR_MESSAGE
      });
    case UNREGISTERED_USER_INVITE_ERROR_TYPE:
      return _objectSpread({}, defaultPopupData, {
        errorMessage,
        onButtonClick
      });
    default:
      return _objectSpread({}, defaultPopupData, {
        errorMessage: errorMessage || DEFAULT_ERROR_MESSAGE
      });
  }
});
const mapStateToProps = createStructuredSelector({
  open: isShowErrorPopupShownSelector,
  isLimitedEdition: isLimitedEditionSelector,
  title: createSelector(dataSelector, ({
    title
  }) => title),
  errorMessage: createSelector(dataSelector, ({
    errorMessage
  }) => errorMessage),
  isButtonShown: createSelector(dataSelector, ({
    isButtonShown
  }) => isButtonShown),
  buttonText: createSelector(dataSelector, ({
    buttonText
  }) => buttonText),
  onButtonClick: createSelector(dataSelector, ({
    onButtonClick
  }) => onButtonClick)
});
const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(hidePopup())
});
export const ShowErrorPopup = connect(mapStateToProps, mapDispatchToProps)(ShowErrorPopupComponent);