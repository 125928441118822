function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { ALL_SWAG_CATEGORY } from 'swag-client-common/constants';
import { instantScrollTop } from 'swag-client-common/utils/tricks';
import ProductApi from '../../api/product.api';
import { CategoryApi } from '../../api/category.api';
import { createFilter } from '../product-listing/product-listing.utils';
import { SEARCH_SUCCESS_GA } from '../search-products/search-products.actions';
const PREFIX = 'SEARCH_RESULTS_';
export const SEARCH_REQUEST = `${PREFIX}SEARCH_REQUEST`;
export const SEARCH_SUCCESS = `${PREFIX}SEARCH_SUCCESS`;
export const SEARCH_ERROR = `${PREFIX}SEARCH_ERROR`;
export const searchProducts = createAsyncAction({
  preFn: (dispatch, getState, data) => {
    const state = getState().searchResults;
    const query = data && data.query || state.query;
    if (data && data.query !== state.query) {
      dispatch(resetFilter());
      dispatch(resetSorting());
      dispatch(resetProducts());
    }
    instantScrollTop();
    if (!query) {
      return true;
    }
    dispatch({
      type: SEARCH_REQUEST,
      payload: {
        query
      }
    });
  },
  transformData: (data, getState) => {
    const {
      query,
      appliedFilter,
      sorting,
      attributesMap
    } = getState().searchResults;
    const formattedFilter = createFilter(appliedFilter, attributesMap);
    if (!!Object.keys(formattedFilter).length && !formattedFilter.categoryId) {
      formattedFilter.categoryId = ALL_SWAG_CATEGORY;
    }
    const requestData = _objectSpread({
      query
    }, formattedFilter, data, {
      page: 1
    });
    if (sorting !== 'relevance') {
      requestData.sorting = sorting;
    }
    return requestData;
  },
  takeLatest: true,
  fn: ProductApi.search,
  success: body => ({
    type: SEARCH_SUCCESS,
    payload: body
  }),
  postSuccess(dispatch, _, transformedData, res) {
    dispatch({
      type: SEARCH_SUCCESS_GA,
      payload: _objectSpread({}, res, {
        query: transformedData.query,
        isQuickSearch: false
      })
    });
  },
  error: errorAction(SEARCH_ERROR)
});
export const GET_PRODUCTS_STARTED = `${PREFIX}GET_PRODUCTS_STARTED`;
export const GET_PRODUCTS_SUCCESS = `${PREFIX}GET_PRODUCTS_SUCCESS`;
export const GET_PRODUCTS_FAIL = `${PREFIX}GET_PRODUCTS_FAIL`;
export const getProducts = createAsyncAction({
  preFn: (dispatch, getState, data) => {
    const query = data && data.query || getState().searchResults.query;
    if (!query) {
      return true;
    }
    dispatch({
      type: GET_PRODUCTS_STARTED,
      payload: {
        query
      }
    });
  },
  transformData: (data, getState) => {
    const {
      query,
      appliedFilter,
      sorting,
      attributesMap
    } = getState().searchResults;
    const formattedFilter = createFilter(appliedFilter, attributesMap);
    if (!!Object.keys(formattedFilter).length && !formattedFilter.categoryId) {
      formattedFilter.categoryId = ALL_SWAG_CATEGORY;
    }
    const requestData = _objectSpread({
      query
    }, formattedFilter, {
      page: 1
    }, data);
    if (sorting !== 'relevance') {
      requestData.sorting = sorting;
    }
    return requestData;
  },
  takeLatest: true,
  fn: ProductApi.search,
  success: body => ({
    type: GET_PRODUCTS_SUCCESS,
    payload: body
  }),
  postSuccess(dispatch, _, transformedData, res) {
    if (transformedData.page === 1) {
      dispatch({
        type: SEARCH_SUCCESS_GA,
        payload: _objectSpread({}, res, {
          query: transformedData.query,
          isQuickSearch: false
        })
      });
    }
  },
  error: errorAction(GET_PRODUCTS_FAIL)
});
export const CHANGE_SELECTED_AND_APPLIED_FILTERS = `${PREFIX}CHANGE_SELECTED_AND_APPLIED_FILTERS`;
export const changeSelectedAndAppliedFilters = filtersData => ({
  type: CHANGE_SELECTED_AND_APPLIED_FILTERS,
  payload: filtersData
});
export const SORTING_CHANGED = `${PREFIX}SORTING_CHANGED`;
export const changeSorting = sorting => ({
  type: SORTING_CHANGED,
  sorting
});
export const sortProducts = sorting => (dispatch, getState) => {
  const {
    query
  } = getState().searchResults;
  instantScrollTop();
  dispatch(resetProducts());
  dispatch(changeSorting(sorting));
  const data = {
    query
  };
  if (sorting !== 'relevance') {
    data.sorting = sorting;
  }
  dispatch(searchProducts(data));
};
export const RESET_PRODUCTS = `${PREFIX}RESET_PRODUCTS`;
export const resetProducts = () => ({
  type: RESET_PRODUCTS
});
export const GET_FILTER_LOOKUPS_SUCCESS = `${PREFIX}GET_FILTER_LOOKUPS_SUCCESS`;
export const GET_FILTER_LOOKUPS_ERROR = `${PREFIX}GET_FILTER_LOOKUPS_ERROR`;
export const getProductListingFilterData = createAsyncAction({
  fn: ProductApi.filter,
  success: filter => ({
    type: GET_FILTER_LOOKUPS_SUCCESS,
    payload: _objectSpread({}, filter[0])
  }),
  error: errorAction(GET_FILTER_LOOKUPS_ERROR)
});
export const APPLY_FILTER = `${PREFIX}APPLY_FILTER`;
export const applyFilter = () => dispatch => {
  dispatch({
    type: APPLY_FILTER
  });
  instantScrollTop();
};
export const CHANGE_CHECKBOX_FILTER = `${PREFIX}CHANGE_CHECKBOX_FILTER`;
export const changeCheckboxFilter = filterData => ({
  type: CHANGE_CHECKBOX_FILTER,
  payload: filterData
});
export const CHANGE_RADIO_FILTER = `${PREFIX}CHANGE_RADIO_FILTER`;
export const changeRadioFilter = filterData => ({
  type: CHANGE_RADIO_FILTER,
  payload: filterData
});
export const CHANGE_PRICE_RANGE = `${PREFIX}CHANGE_PRICE_RANGE`;
export const changePriceRange = ({
  values
}) => ({
  type: CHANGE_PRICE_RANGE,
  payload: {
    range: values
  }
});
export const CHANGE_IS_FOR_BOX = `${PREFIX}CHANGE_IS_FOR_BOX`;
export const changeIsBox = filterData => ({
  type: CHANGE_IS_FOR_BOX,
  payload: filterData
});
export const CHANGE_IS_FOR_INVENTORY = `${PREFIX}CHANGE_IS_FOR_INVENTORY`;
export const changeIsInventory = filterData => ({
  type: CHANGE_IS_FOR_INVENTORY,
  payload: filterData
});
export const CHANGE_IS_FOR_SUPER_SPEED = `${PREFIX}CHANGE_IS_FOR_SUPER_SPEED`;
export const changeIsSuperSpeed = filterData => ({
  type: CHANGE_IS_FOR_SUPER_SPEED,
  payload: filterData
});
export const GET_CATEGORIES_SUCCESS = `${PREFIX}GET_CATEGORIES_SUCCESS`;
export const GET_CATEGORIES_FAIL = `${PREFIX}GET_CATEGORIES_FAIL`;
export const fetchCategories = createAsyncAction({
  fn: CategoryApi.getAll,
  success: body => onGetCategoriesSuccess(body),
  error: errorAction(GET_CATEGORIES_FAIL)
});
const onGetCategoriesSuccess = body => {
  const subcategories = body.categories.filter(cat => !!cat.parent_id);
  let mainCategories = body.categories.filter(cat => !cat.parent_id && cat.isVisible).map(cat => _objectSpread({}, cat, {
    subcategories: subcategories.filter(sub => sub.parent_id === cat._id)
  }));
  const categoriesMap = {};
  [...mainCategories, ...subcategories].forEach(cat => {
    categoriesMap[cat.slug] = cat;
  });
  const allSwag = {
    name: 'All Swag',
    slug: ALL_SWAG_CATEGORY,
    order: 1,
    subcategories: [],
    _id: ALL_SWAG_CATEGORY,
    isVisible: true
  };
  mainCategories = [allSwag, ...mainCategories];
  categoriesMap[allSwag.slug] = allSwag;
  return {
    type: GET_CATEGORIES_SUCCESS,
    categories: mainCategories,
    categoriesMap
  };
};
export const RESET_FILTER = `${PREFIX}RESET_FILTER`;
export const resetFilter = () => dispatch => {
  dispatch({
    type: RESET_FILTER
  });
};
export const RESET_SORTING = `${PREFIX}RESET_SORTING`;
export const resetSorting = () => dispatch => {
  dispatch({
    type: RESET_SORTING
  });
};
export const REMOVE_FILTER_ITEM = `${PREFIX}REMOVE_FILTER_ITEM`;
export const removeActiveFilter = (filterId, filterIndex) => dispatch => {
  dispatch({
    type: REMOVE_FILTER_ITEM,
    payload: {
      filterId,
      filterIndex
    }
  });
};
export const CHANGE_QUERY_STR = `${PREFIX}CHANGE_QUERY_STR`;
export const changeQueryString = query => ({
  type: CHANGE_QUERY_STR,
  payload: {
    query
  }
});
export const CHANGE_SELECTED_PRODUCT = `${PREFIX}CHANGE_SELECTED_PRODUCT`;
export const changeSelectedProduct = selectedProduct => ({
  type: CHANGE_SELECTED_PRODUCT,
  payload: {
    selectedProduct
  }
});
export const SET_FILTER_VISIBILITY = `${PREFIX}SET_FILTER_VISIBILITY`;
export const setFilterVisibility = isVisible => ({
  type: SET_FILTER_VISIBILITY,
  payload: {
    isVisible
  }
});
export const OPEN_FILTER_IN_SEARCH_POPUP = `${PREFIX}OPEN_FILTER_IN_SEARCH_POPUP`;
export const openFilterInSearchPopup = () => ({
  type: OPEN_FILTER_IN_SEARCH_POPUP
});
export const CLOSE_FILTER_IN_SEARCH_POPUP = `${PREFIX}CLOSE_FILTER_IN_SEARCH_POPUP`;
export const closeFilterInSearchPopup = () => ({
  type: CLOSE_FILTER_IN_SEARCH_POPUP
});