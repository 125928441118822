import _isFinite from 'lodash/isFinite';
import { PERCENT_TO_SHOW_UPSELL_WIDGET } from './config';
import { adjustQuantityToQuantIncrement } from './calculate-price-breaks-will-be-shown-from/adjust-quantity-to-quant-increment';
export const calculatePriceBreaksWillBeShownFrom = ({
  priceBreaks,
  inStock,
  quantIncrement,
  percentToNextPriceBreak = PERCENT_TO_SHOW_UPSELL_WIDGET
}) => priceBreaks.reduce((acc, pb) => {
  if (inStock !== null && _isFinite(inStock) && pb > inStock) {
    return acc;
  }
  let newPb = Math.ceil(pb / (1 + percentToNextPriceBreak / 100));
  newPb = adjustQuantityToQuantIncrement({
    quantity: newPb,
    quantIncrement
  });
  return [...acc, newPb];
}, []);