import { createSelector } from 'reselect';
import { isLogoTypeProductSelector } from '../product/product.selectors';
import { isLoggedInSelector } from '../user/user.selectors';
import { checkIfShouldSkipCompanyArtFolderFlow } from './company-art-folder.selectors';
const companyArtFolderRootSelector = state => state.companyArtFolder;
const logosListSelector = createSelector(companyArtFolderRootSelector, state => state.list.slice(0, 5));
export const shouldLoadCompanyArtFolderSelector = createSelector(logosListSelector, isLoggedInSelector, (list, isLoggedIn) => {
  if (!isLoggedIn || checkIfShouldSkipCompanyArtFolderFlow()) {
    return false;
  }
  return list.length === 0;
});
export const shouldSaveCompanyArtFolderSelector = createSelector(isLogoTypeProductSelector, isLoggedInSelector, (isLogoDesignUploadMode, isLoggedIn) => {
  return isLogoDesignUploadMode && isLoggedIn;
});