import { DesignUploadMode } from '../../interfaces';
function isItemHasSetupFeeDeductionDiscount(item) {
  return item.isReorder && item.setupFeeDeductionDiscount &&
  // @todo should be fixed
  // @ts-ignore
  item.prod.designUploadMode !== DesignUploadMode.box;
}
export function calculateSetupFeeDeductionDiscount(items) {
  const discountsByAsRelatedItemsId = items.reduce((result, item) => {
    if (isItemHasSetupFeeDeductionDiscount(item)) {
      result[item.asRelatedItemId] = item.setupFeeDeductionDiscount;
    }
    return result;
  }, {});
  return Object.values(discountsByAsRelatedItemsId).reduce((sum, num) => sum + num, 0);
}