import _reset from "swag-client-common/redux-form-actions/reset";
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { EmailApi } from '../../api/email.api';
import { REQUEST_DEMO_FORM } from '../redux.constants';
export const APPLY_AMBASSADOR_IDLE_MODE = 'APPLY_AMBASSADOR_IDLE_MODE';
export const APPLY_AMBASSADOR_SUCCESS = 'APPLY_AMBASSADOR_SUCCESS';
export const APPLY_AMBASSADOR_ERROR = 'APPLY_AMBASSADOR_ERROR';
export const notificationTimeout = 3000;
export const REQUEST_DEMO_REQUEST = 'REQUEST_DEMO_REQUEST';
export const REQUEST_DEMO_SUCCESS = 'REQUEST_DEMO_SUCCESS';
export const REQUEST_DEMO_FAILURE = 'REQUEST_DEMO_FAILURE';
export const requestDemo = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REQUEST_DEMO_REQUEST
    });
  },
  transformData: (data, getState) => {
    const {
      values
    } = getState().form[REQUEST_DEMO_FORM];
    return values;
  },
  fn: EmailApi.requestDemo,
  success: () => ({
    type: REQUEST_DEMO_SUCCESS
  }),
  error: REQUEST_DEMO_FAILURE,
  postSuccess: dispatch => {
    dispatch(_reset(REQUEST_DEMO_FORM));
    dispatch(hidePopup());
    dispatch(showNotification({
      text: 'Thank you! We will be in touch soon.'
    }));
  }
});