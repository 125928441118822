import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/location';
const LocationApi = createApi({
  get: {
    url: `${baseUrl}/autocomplete/country/:country/place/:place`,
    method: 'get'
  },
  getByPlace: {
    url: `${baseUrl}/autocomplete/country/:country/place/:place/container/:container`,
    method: 'get'
  },
  getPlaceById: {
    url: `${baseUrl}/place/:id`,
    method: 'get'
  },
  validate: {
    url: `${baseUrl}/validate`,
    method: 'post'
  }
});
export default LocationApi;