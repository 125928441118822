export function getVariantUniqueColorWithSizes(variants) {
  return variants.reduce((uniqueVariantsByColor, variant) => {
    const result = [...uniqueVariantsByColor];
    const {
      colorName,
      colorValue,
      quantities
    } = variant;
    const existedItemInListIndex = result.findIndex(params => params.colorName === colorName);
    if (existedItemInListIndex === -1) {
      return [...result, {
        colorName,
        colorValue,
        sizes: Object.keys(quantities || {})
      }];
    }
    return result;
  }, []);
}