function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CHANGE_NOTECARD_COLOR, DELETE_NOTECARD_LOGO, RESET_NOTECARD_EDITOR, SET_NOTECARD_EDITOR_INITIAL_VALUES, UPDATE_NOTECARD_TEXT_CONTENT, UPLOAD_NOTECARD_LOGO_FAIL, UPLOAD_NOTECARD_LOGO_REQUEST, UPLOAD_NOTECARD_LOGO_SUCCESS } from './notecard-editor.actions';
const initialState = {
  isLogoLoading: false,
  color: '',
  logo: null,
  textContent: '',
  initialState: null
};
export const notecardEditorReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case SET_NOTECARD_EDITOR_INITIAL_VALUES:
      {
        const {
          color,
          logo,
          textContent
        } = payload;
        return _objectSpread({}, state, {
          color,
          logo,
          textContent,
          initialState: payload
        });
      }
    case CHANGE_NOTECARD_COLOR:
      {
        return _objectSpread({}, state, {
          color: payload
        });
      }
    case UPLOAD_NOTECARD_LOGO_REQUEST:
      {
        return _objectSpread({}, state, {
          isLogoLoading: true
        });
      }
    case UPLOAD_NOTECARD_LOGO_FAIL:
      {
        return _objectSpread({}, state, {
          isLogoLoading: false
        });
      }
    case UPLOAD_NOTECARD_LOGO_SUCCESS:
      {
        return _objectSpread({}, state, {
          isLogoLoading: false,
          logo: payload
        });
      }
    case DELETE_NOTECARD_LOGO:
      {
        return _objectSpread({}, state, {
          logo: null
        });
      }
    case UPDATE_NOTECARD_TEXT_CONTENT:
      {
        return _objectSpread({}, state, {
          textContent: payload
        });
      }
    case RESET_NOTECARD_EDITOR:
      {
        return _objectSpread({}, state, initialState);
      }
    default:
      {
        return state;
      }
  }
};