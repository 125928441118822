import _isEqual from "lodash/isEqual";
import { createSelector } from 'reselect';
import { ACTIONS, WAREHOUSE_CODES_LABELS, shippingFieldsMap } from 'swag-common/interfaces';
import { LANDING_TYPES, OTHER_HUBSPOT_FORMS } from 'swag-common/constants/landings';
import { convertDateToHistoryFormat } from 'swag-common/utils/date-lib';
import { getSuperSpeedFromLS } from 'swag-client-common/utils/super-speed-local-storage.utils';
export const settingsSelector = state => state.settings;
export const superSpeedSettingsSelector = createSelector(settingsSelector, settings => settings.superSpeed);
export const isAvailableSuperSpeedSelector = createSelector(superSpeedSettingsSelector, ({
  enabled
}) => enabled);
export const isSuperSpeedActiveSelector = createSelector(isAvailableSuperSpeedSelector, getSuperSpeedFromLS, (isAvailableSuperSpeed, showSuperSpeedFromLS) => {
  return isAvailableSuperSpeed && showSuperSpeedFromLS;
});
export const defaultWarehouseSelector = createSelector(settingsSelector, settings => settings.defaultWarehouse);
export const initialShippingMarginSelector = createSelector(settingsSelector, settings => settings.initialShippingMargin);
export const shippingMarginSelector = createSelector(settingsSelector, settings => settings.shippingMargin);
export const rootHistorySelector = createSelector(settingsSelector, settings => settings.history);
export const isSaveShippingMarginButtonDisabledSelector = createSelector(initialShippingMarginSelector, shippingMarginSelector, (initialShippingMargin, shippingMargin) => _isEqual(initialShippingMargin, shippingMargin));
export const settingsHistorySelector = createSelector(rootHistorySelector, settingsHistory => {
  const history = [];
  settingsHistory.forEach(item => {
    const {
      data,
      createdAt,
      action
    } = item;
    const _id = '_id' in item ? item._id : '';
    if (action === ACTIONS.CHANGE_DEFAULT_WAREHOUSE && 'oldValue' in data) {
      const {
        oldValue,
        newValue,
        adminEmail
      } = data;
      history.push({
        _id,
        createdAt: convertDateToHistoryFormat(createdAt),
        title: 'Change default warehouse',
        description: `Default warehouse was changed from "${WAREHOUSE_CODES_LABELS[oldValue]}" to "${WAREHOUSE_CODES_LABELS[newValue]}" by ${adminEmail}`
      });
    }
    if (action === ACTIONS.UPDATE_SHIPPING_MARGIN && 'shippingMarginInfo' in data) {
      const {
        shippingMarginInfo
      } = data;
      const fieldsLength = shippingMarginInfo.length;
      const updatedFieldsText = shippingMarginInfo.reduce((acc, {
        field,
        oldValue,
        newValue
      }, index) => {
        const fieldName = shippingFieldsMap[field];
        const punctuationMark = index < fieldsLength - 1 ? ';' : '.';
        return `${acc} \n - ${fieldName}: from "${+oldValue * 100}%" to "${+newValue * 100}%"${punctuationMark}`;
      }, '');
      history.push({
        _id,
        createdAt: convertDateToHistoryFormat(createdAt),
        title: 'Update shipping margin',
        description: `Shipping margin setting was changed by ${data.adminEmail}: ${updatedFieldsText}`
      });
    }
  });
  return history;
});
export const isLoadingSelector = createSelector(settingsSelector, settings => settings.isLoading);
export const isLoadedSelector = createSelector(settingsSelector, settings => settings.isLoaded);
export const isShippingSettingsHiddenSelector = createSelector(isLoadingSelector, defaultWarehouseSelector, shippingMarginSelector, (isLoading, defaultWarehouse, shippingMargin) => isLoading && (!defaultWarehouse || !shippingMargin));
export const replacementPriceRangeSelector = createSelector(settingsSelector, settings => settings.replacementPriceRange);
export const replacementPriceRangeValueSelector = createSelector(replacementPriceRangeSelector, config => {
  var _config$replacementPr;
  return (_config$replacementPr = config === null || config === void 0 ? void 0 : config.replacementPriceRange) !== null && _config$replacementPr !== void 0 ? _config$replacementPr : null;
});
export const hubspotCalendarLinksSelector = createSelector(settingsSelector, settings => (settings === null || settings === void 0 ? void 0 : settings.hubspotCalendarLinks) || {});
export const inBoxHubspotCalendarLinkSelector = createSelector(hubspotCalendarLinksSelector, hubspotCalendarLinks => hubspotCalendarLinks[LANDING_TYPES.INABOX] || '');
export const giveawayHubspotCalendarLinkSelector = createSelector(hubspotCalendarLinksSelector, hubspotCalendarLinks => hubspotCalendarLinks[LANDING_TYPES.GIVEAWAY] || '');
export const inventoryHubspotCalendarLinkSelector = createSelector(hubspotCalendarLinksSelector, hubspotCalendarLinks => hubspotCalendarLinks[LANDING_TYPES.INVENTORY] || '');
export const shopifyHubspotCalendarLinkSelector = createSelector(hubspotCalendarLinksSelector, hubspotCalendarLinks => hubspotCalendarLinks[LANDING_TYPES.SHOPIFY] || '');
export const requestDemoHubspotCalendarLinkSelector = createSelector(hubspotCalendarLinksSelector, hubspotCalendarLinks => hubspotCalendarLinks[OTHER_HUBSPOT_FORMS.REQUEST_DEMO] || '');