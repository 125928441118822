import { checkIsResellerTenant } from 'swag-common/utils/check-is-reseller-tenant.util';
export const getIsReviewPantonesByCustomerAllowedFromOrder = ({
  tenant,
  specificReviewsByCustomerPermissions
}) => {
  var _window$swag$storeSet;
  if (!checkIsResellerTenant(tenant)) {
    return true;
  }
  const {
    isReviewPantonesByCustomerAllowed
  } = (_window$swag$storeSet = window.swag.storeSettings) !== null && _window$swag$storeSet !== void 0 ? _window$swag$storeSet : {};
  const {
    isSpecificReviewPantonesByCustomerAllowed
  } = specificReviewsByCustomerPermissions !== null && specificReviewsByCustomerPermissions !== void 0 ? specificReviewsByCustomerPermissions : {};
  if (isSpecificReviewPantonesByCustomerAllowed === null) {
    return Boolean(isReviewPantonesByCustomerAllowed);
  }
  return Boolean(isSpecificReviewPantonesByCustomerAllowed);
};
export const getIsReviewMockupsByCustomerAllowedFromOrder = ({
  tenant,
  specificReviewsByCustomerPermissions
}) => {
  var _window$swag$storeSet2;
  if (!checkIsResellerTenant(tenant)) {
    return true;
  }
  const {
    isReviewMockupsByCustomerAllowed
  } = (_window$swag$storeSet2 = window.swag.storeSettings) !== null && _window$swag$storeSet2 !== void 0 ? _window$swag$storeSet2 : {};
  const {
    isSpecificReviewMockupsByCustomerAllowed
  } = specificReviewsByCustomerPermissions !== null && specificReviewsByCustomerPermissions !== void 0 ? specificReviewsByCustomerPermissions : {};
  if (isSpecificReviewMockupsByCustomerAllowed === null) {
    return Boolean(isReviewMockupsByCustomerAllowed);
  }
  return Boolean(isSpecificReviewMockupsByCustomerAllowed);
};