const MONTH = 'MONTH';
const SUNDAY = 0;
const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 3;
const THURSDAY = 4;
const FRIDAY = 5;
const SATURDAY = 6;
const SECONDS_IN_ONE_MINUTE = 60;
const MINUTES_IN_ONE_HOUR = 60;
const HOURS_IN_ONE_DAY = 24;
const MILLISECONDS_IN_ONE_SECOND = 1000;
const MILLISECONDS_IN_ONE_MINUTE = SECONDS_IN_ONE_MINUTE * MILLISECONDS_IN_ONE_SECOND;
const MILLISECONDS_IN_ONE_HOUR = MINUTES_IN_ONE_HOUR * MILLISECONDS_IN_ONE_MINUTE;
const MILLISECONDS_IN_ONE_DAY = HOURS_IN_ONE_DAY * MILLISECONDS_IN_ONE_HOUR;
const SECONDS_IN_ONE_DAY = SECONDS_IN_ONE_MINUTE * MINUTES_IN_ONE_HOUR * HOURS_IN_ONE_DAY;
const SECONDS_IN_ONE_MONTH = 30 * SECONDS_IN_ONE_DAY;
const EST_LIMIT_HOURS = 14;
const WAREHOUSE_NOTICE_DAYS_TO_ADD = 2;
const WHIPLASH_NOTICE_LIMIT_HOURS = 13;
export const EXPIRATION_MONTH = 3;
export { MILLISECONDS_IN_ONE_DAY, MONTH, SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, EST_LIMIT_HOURS, WHIPLASH_NOTICE_LIMIT_HOURS, WAREHOUSE_NOTICE_DAYS_TO_ADD, MILLISECONDS_IN_ONE_HOUR, MILLISECONDS_IN_ONE_MINUTE, MILLISECONDS_IN_ONE_SECOND, SECONDS_IN_ONE_MONTH };