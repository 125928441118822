function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { LOCATION_CHANGE } from 'react-router-redux';
const initialState = {
  locationBeforeTransitions: null,
  locationState: null
};
export const CLEAR_LOCATION_STATE = 'CLEAR_LOCATION_STATE';
export const routingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      {
        var _locationBeforeTransi;
        const locationBeforeTransitions = action.payload;
        if (locationBeforeTransitions.action === 'POP') {
          return _objectSpread({}, state, {
            locationBeforeTransitions
          });
        }
        return _objectSpread({}, state, {
          locationBeforeTransitions,
          locationState: (_locationBeforeTransi = locationBeforeTransitions === null || locationBeforeTransitions === void 0 ? void 0 : locationBeforeTransitions.state) !== null && _locationBeforeTransi !== void 0 ? _locationBeforeTransi : state.locationState
        });
      }
    case CLEAR_LOCATION_STATE:
      {
        return _objectSpread({}, state, {
          locationState: null
        });
      }
    default:
      return state;
  }
};