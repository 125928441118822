import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { ProfileSettingsComponent } from 'swag-client-common/components/partials/profile-settings';
import { updateProfile } from 'swag-client-common/redux/user/user.actions';
import { userSelector } from 'swag-client-common/redux/user/user.selectors';
const mapStateToProps = createSelector(userSelector, user => ({
  initialValues: {
    firstName: user.firstName,
    lastName: user.lastName,
    company: user.company,
    phoneNumber: user.phoneNumber
  },
  email: user.email,
  isError: !!user.err,
  errorMessage: user.err,
  isSuccess: user.isSuccess,
  isLoading: user.isLoading
}));
const mapDispatchToProps = dispatch => ({
  onSubmit: values => dispatch(updateProfile({
    firstName: values.firstName,
    lastName: values.lastName,
    company: values.company,
    phoneNumber: values.phoneNumber
  }))
});
export const ProfileSettings = connect(mapStateToProps, mapDispatchToProps)(ProfileSettingsComponent);