import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { sessionPing } from 'swag-client-common/redux/user/user-additional.actions';
import { isProductPathSelector } from '../../redux/common/common.selectors';
import { isLoggedInSelector } from '../../redux/user/user.selectors';
import Root from './root';
const mapStateToProps = createStructuredSelector({
  isProductPage: isProductPathSelector,
  isLoggedIn: isLoggedInSelector
});
const mapDispatchToProps = dispatch => ({
  onWindowFocus: () => dispatch(sessionPing())
});
export default connect(mapStateToProps, mapDispatchToProps)(Root);