/*
  This is used to preserve gamification history in account orders/company orders.
  Because we only fetch paginated orders, our gamification history is not permanent
  And depends on current displaying orders
  To prevent this and preserve history (e.g. gamification labels), keep already seen orders
  So for example when i search for orders and nothing is found, gamification history
  And labels are preserved, even though we received empty orders array from backend.
*/

export const mergeOrdersWithPagination = ({
  page,
  prevPage,
  perPage,
  orders,
  prevOrders
}) => {
  let mergedOrders = prevOrders;
  if (page > prevPage) {
    mergedOrders = [...prevOrders, ...orders];
  } else if (page < prevPage) {
    // delete prevPage orders, update page orders, preserve all other
    // TODO: maybe grab preserved orders from Redux instead of making another request?
    mergedOrders = [...mergedOrders.slice(0, Math.min(0, page - 1) * perPage), ...orders];
  } else {
    // in case we changed pageSize or search, update existing orders with new ones
    // but persist all others in order to keep gamification the same
    mergedOrders = [...mergedOrders, ...orders].filter((mergedOrder, index, arr) => !arr.slice(index + 1, arr.length).find(order => order._id === mergedOrder._id));
  }
  return mergedOrders;
};