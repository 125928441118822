import { urlModifier } from '../../../client/scripts/common/libs/url-modifier';
import { S3GatewayPath } from '../../constants/s3-gateway.enum';
import { SWAG_AWS_S3BUCKET, SWAG_AWS_REGION } from '../../config/constants/aws-config';
const s3Url = `https://${SWAG_AWS_S3BUCKET}.s3.${SWAG_AWS_REGION}.amazonaws.com`;
const getSwagUrl = s3Path => {
  const apiPath = [S3GatewayPath.orderInvoices, S3GatewayPath.adminAttachments, S3GatewayPath.tenantInvoices].includes(s3Path) ? 'api' : 'custom-store-api';
  const url = urlModifier(`/${apiPath}/v1/s3-gateway/${s3Path}`);
  return `${typeof process !== 'undefined' ? process.env.BASE_URL || '' : ''}${url}`;
};
export const getClearS3Link = () => s3Url;
export const getDownloadLinkFromS3 = (link, s3Path) => {
  if (!link) {
    return link;
  }
  return link.replace(s3Url, getSwagUrl(s3Path));
};
export const getS3NameByFilename = filename => {
  if (!filename) {
    return filename;
  }
  return `${s3Url}/${filename}`;
};
export const getOrderInvoiceLink = order => {
  if (!order.invoice) {
    return null;
  }
  const url = urlModifier(`/api/v1/s3-gateway/${S3GatewayPath.orderInvoices}/${order._id.toString()}`);
  return `${typeof process !== 'undefined' ? process.env.BASE_URL || '' : ''}${url}`;
};
export const getOrderDraftPDFLink = id => {
  if (!id) {
    return undefined;
  }
  const url = urlModifier(`/api/v1/order/pdf-draft/${id}`);
  return `${typeof process !== 'undefined' ? process.env.BASE_URL || '' : ''}${url}`;
};
export const getOrderPresentationPDFLink = id => {
  if (!id) {
    return undefined;
  }
  const url = urlModifier(`/api/v1/order/pdf-presentation/${id}`);
  return `${typeof process !== 'undefined' ? process.env.BASE_URL || '' : ''}${url}`;
};
export const getWarehousingInvoiceLink = link => {
  if (!link) {
    return link;
  }
  return encodeURI(link.replace(s3Url, getSwagUrl(S3GatewayPath.warehousingFeeInvoices)));
};
export const getShipoutInvoiceLink = link => {
  if (!link) {
    return link;
  }
  return encodeURI(link.replace(s3Url, getSwagUrl(S3GatewayPath.shipoutInvoices)));
};
export const getCSVFileLink = link => {
  if (!link) {
    return link;
  }
  return encodeURI(link.replace(s3Url, getSwagUrl(S3GatewayPath.csvFileToSend)));
};
export const getOrderInvoiceLinkByS3Invoice = link => {
  if (!link) {
    return link;
  }
  return encodeURI(link.replace(s3Url, getSwagUrl(S3GatewayPath.orderInvoices)));
};
export const getAdminAttachmentsLinkByS3Invoice = link => {
  if (!link) {
    return link;
  }
  const filename = getS3FilePathFromFullS3Filename(link);
  return encodeURI(`${getSwagUrl(S3GatewayPath.adminAttachments)}?path=${filename}`);
};
export const getTenantInvoiceLinkByS3Invoice = link => {
  if (!link) {
    return link;
  }
  const filename = getS3FilePathFromFullS3Filename(link);
  return encodeURI(`${getSwagUrl(S3GatewayPath.tenantInvoices)}?path=${filename}`);
};
export const getDownloadLinkByOrderInvoice = order => order && order.invoice && getOrderInvoiceLinkByS3Invoice(order.invoice);
export const getDownloadLinkByOrder = order => order && getOrderInvoiceLink(order) || getDownloadLinkByOrderInvoice(order);
export const getS3FilePathFromFullS3Filename = fullS3Filename => {
  return fullS3Filename.replace(`${s3Url}/`, '');
};