import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
export const offerItems = [{
  title: 'Swag Bulk Ordering',
  description: 'Easily shop our curated selection of the highest quality swag.',
  link: APP_ROUTES.ALL_SWAG,
  color: 'blue'
}, {
  title: 'Swag Distribution',
  description: 'Send swag to one location or to thousands of locations at once.',
  link: APP_ROUTES.DISTRIBUTE,
  color: 'violet'
}, {
  title: 'Swag In a Box',
  description: 'Show your employees and customers some love. Send them the good stuff in a box!',
  link: APP_ROUTES.IN_A_BOX,
  color: 'pink'
}, {
  title: 'Corporate Gifting',
  description: "Allow your audience to choose what they want. We'll send it right to their door!",
  link: APP_ROUTES.GIVEAWAY,
  color: 'green'
}, {
  title: 'Company Stores',
  description: 'Easily create a company store to sell swag to your fans, employees and customers.',
  link: APP_ROUTES.SHOPIFY,
  color: 'yellow'
}, {
  title: 'Swag Automation',
  description: 'Integrate with our custom API and seamlessly automate your swag and gifting programs for free.',
  link: APP_ROUTES.AUTOMATION,
  color: 'orange'
}];
export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
};