export const getCartItemsCount = items => {
  const boxes = {};
  return items.reduce((total, item) => {
    const {
      boxItemsId,
      isBoxSampleWithoutBox,
      products
    } = item;
    if (isBoxSampleWithoutBox) {
      return total + products.length;
    }
    if (boxItemsId && boxes[boxItemsId]) {
      return total;
    }
    if (boxItemsId && !boxes[boxItemsId]) {
      boxes[boxItemsId] = true;
    }
    return total + 1;
  }, 0);
};