const capitalize = (str = '') => (str || '').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
const capitalizeFirstWord = str => str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
const makeFirstLetterLowerCase = (str, removeIndent = true) => {
  const result = str.charAt(0).toLowerCase() + str.slice(1);
  if (!removeIndent) {
    return result;
  }
  return result.split(' ').reduce((word, item, index) => {
    const formattedItem = item.replace(/[^\w\s]/gi, ' ').split(' ').join('');
    return index === 0 ? word + formattedItem : word + capitalizeFirstWord(formattedItem);
  }, '');
};
const getSubStringAfterSymbol = (string, symbol) => {
  const index = string.indexOf(symbol);
  return index === -1 ? '' : string.slice(index + 1, string.length);
};
const escape = string => {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
};
const escapeDoubleQuotes = string => {
  return string.replace(/"/g, "''");
};
const escapeCommas = string => {
  return string.replace(/[,\n]/g, '.');
};
const formatFieldForExport = (string = '') => {
  const text = (string ? String(string) : '').split('"').join("''");
  return `"${text}"`;
};
const pad = int => `${int < 10 ? '0' : ''}${int}`;
const priceIntegerAndFract = int => {
  const integer = `${Math.floor(int / 100)}`;
  const fract = pad(Math.round(int) % 100);
  return {
    integer,
    fract
  };
};
const formatNumberWithComma = value => {
  if (!value) {
    return '0';
  }
  let integer = String(value);
  for (let i = integer.length - 3; i > 0; i -= 3) {
    integer = `${integer.slice(0, i)},${integer.slice(i)}`;
  }
  return `${integer}`;
};
const formatNumber = int => {
  if (!int) {
    return 0;
  }
  const object = priceIntegerAndFract(int);
  const integer = object.integer;
  return formatNumberWithComma(integer);
};
const getOnlyNums = (value, keepSign) => {
  if (keepSign) {
    return value.replace(/[^-?\d]/g, '');
  }
  return value.replace(/[^\d]/g, '');
};
const normalizeNumber = (value, keepSign) => {
  if (!value) {
    return value;
  }
  const onlyNums = getOnlyNums(value, keepSign);
  return formatNumber(onlyNums);
};
const normalizeNumberInput = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  return onlyNums.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
const normalizeFloatNumberInput = value => {
  if (!value) {
    return value;
  }
  return value.toString().replace(/[^0-9.]/, '');
};
const normalizeHex = value => {
  if (!value) {
    return value;
  }
  return `#${value.replace('#', '')}`;
};
const trimTo = num => str => str.substr(0, num);
const pluralize = (count, word, suffix = 's', withoutNumber = false) => `${withoutNumber ? '' : count} ${word}${count !== 1 ? suffix : ''}`.trim();
function addSuffixToNumber(value) {
  const number = +value;
  const partFrom10 = number % 10;
  const partFrom100 = number % 100;
  if (partFrom10 == 1 && partFrom100 != 11) {
    return number + 'st';
  }
  if (partFrom10 == 2 && partFrom100 != 12) {
    return number + 'nd';
  }
  if (partFrom10 == 3 && partFrom100 != 13) {
    return number + 'rd';
  }
  return number + 'th';
}
const deleteLastComma = (length, index) => {
  if (index === length - 1) {
    return '';
  }
  return ', ';
};
const lowerCaseAndSplit = (string, separator = ',') => {
  return string.trim().toLowerCase().split(separator);
};
const truncateString = (productName, shortCutLimit) => {
  return (productName === null || productName === void 0 ? void 0 : productName.length) > shortCutLimit ? `${productName.substr(0, shortCutLimit)}...` : productName || '';
};

// result example: "first, second or third"
const arrayReadableEnumeration = (array, lastElemSeparator = 'or') => {
  return array.toString().replace(/,/g, ', ').replace(new RegExp(', ([a-zA-Z0-9]+)$'), ` ${lastElemSeparator} $1`);
};
const limitQueryString = (query = '', limit = 100) => {
  return query.slice(0, limit);
};
export { formatNumberWithComma, capitalize, capitalizeFirstWord, getSubStringAfterSymbol, formatNumber, normalizeNumber, normalizeHex, escape, normalizeNumberInput, normalizeFloatNumberInput, trimTo, pluralize, addSuffixToNumber, escapeDoubleQuotes, escapeCommas, formatFieldForExport, deleteLastComma, lowerCaseAndSplit, makeFirstLetterLowerCase, truncateString, arrayReadableEnumeration, limitQueryString };