import { isBox, isItemBoxSpecialNotecard, isItemBoxSpecialCrinklePaper, isItemBoxSpecialTape, isItemBoxSpecialProduct } from '../order/item-is-box.util';
import { isProceededItemSuperSpeed, isSuperSpeedItem } from './is-item-super-speed.util';
export const checkIsBoxItselfSuperSpeed = item => {
  var _item$prod;
  return Boolean(item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.isBoxForSuperSpeed);
};
export const checkIsWholeBoxSuperSpeed = items => {
  const box = items === null || items === void 0 ? void 0 : items.some(isBox);
  if (!box) {
    return false;
  }
  return items.reduce((res, item) => {
    if (!res) {
      return false;
    }
    if (isItemBoxSpecialNotecard(item) || isItemBoxSpecialCrinklePaper(item) || isItemBoxSpecialTape(item)) {
      return res;
    }
    if (isBox(item)) {
      return checkIsBoxItselfSuperSpeed(item);
    }
    return isSuperSpeedItem(item);
  }, true);
};
export const checkIsWholeBoxSuperSpeedWithoutAddons = items => {
  const box = items === null || items === void 0 ? void 0 : items.some(isBox);
  if (!box) {
    return false;
  }
  return items.reduce((res, item) => {
    if (!res) {
      return false;
    }
    if (isItemBoxSpecialProduct(item)) {
      return res;
    }
    if (isBox(item)) {
      return checkIsBoxItselfSuperSpeed(item);
    }
    return isSuperSpeedItem(item);
  }, true);
};
export const checkIsWholeProceededBoxSuperSpeed = items => {
  const box = items === null || items === void 0 ? void 0 : items.some(isBox);
  if (!box) {
    return false;
  }
  return items.every(item => isProceededItemSuperSpeed(item));
};