// Helpers

const forEachValue = (obj, cb) => Object.entries(obj).reduce((acc, [key, value]) => {
  acc[key] = cb(value);
  return acc;
}, {});
export const rgbaToRgb = (color, bgColor = {
  r: 255,
  g: 255,
  b: 255
}) => {
  const {
    r,
    g,
    b,
    a
  } = color;
  const {
    r: bgR,
    g: bgG,
    b: bgB
  } = bgColor;
  const r1 = (1 - a) * bgR + a * r;
  const g1 = (1 - a) * bgG + a * g;
  const b1 = (1 - a) * bgB + a * b;
  return forEachValue({
    r: r1,
    g: g1,
    b: b1
  }, Math.round);
};
export const rgbaToCmyk = color => {
  const {
    r,
    g,
    b
  } = rgbaToRgb(color);
  const r1 = r / 255;
  const g1 = g / 255;
  const b1 = b / 255;
  let c, m, y;
  const k = 1 - Math.max(r1, g1, b1);
  if (k === 1) {
    c = m = y = 0;
  } else {
    c = (1 - r1 - k) / (1 - k);
    m = (1 - g1 - k) / (1 - k);
    y = (1 - b1 - k) / (1 - k);
  }
  return forEachValue({
    c,
    m,
    y,
    k
  }, v => Math.round(v * 100));
};
const cmykToRgb = ({
  c,
  m,
  y,
  k
}) => forEachValue({
  r: c,
  g: m,
  b: y
}, v => Math.round(255 * (1 - v / 100) * (1 - k / 100)));
const componentToHex = c => {
  const hex = c.toString(16);
  return `${hex.length === 1 ? '0' : ''}${hex}`;
};
const rgbToHex = ({
  r,
  g,
  b
}) => '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
export const cmykToHex = ({
  c,
  m,
  y,
  k
}) => {
  const rgb = cmykToRgb({
    c,
    m,
    y,
    k
  });
  return rgbToHex(rgb);
};
export const formatCmyk = ({
  c,
  m,
  y,
  k
}) => `C-${c} M-${m} Y-${y} K-${k}`;
export const parseCmyk = cmyk => {
  const matchResult = cmyk.match(/\d+/g);
  const [c, m, y, k] = (matchResult || []).map(Number);
  return {
    c,
    m,
    y,
    k
  };
};
export const isCmykFormattedString = string => /^C-\d+ M-\d+ Y-\d+ K-\d+$/.test(string);