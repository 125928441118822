import { ProductApi } from 'swag-client-common/api/product.api';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
const PREFIX = 'PRODUCT_';
export const FETCH_SPECIAL_BOX_PRODUCTS_LOADING = `${PREFIX}FETCH_SPECIAL_BOX_PRODUCTS_LOADING`;
export const FETCH_SPECIAL_BOX_PRODUCTS_SUCCESS = `${PREFIX}FETCH_SPECIAL_BOX_PRODUCTS_SUCCESS`;
export const FETCH_SPECIAL_BOX_PRODUCTS_FAIL = `${PREFIX}FETCH_SPECIAL_BOX_PRODUCTS_FAIL`;
export const fetchSpecialBoxProducts = createAsyncAction({
  preFn: dispatch => dispatch({
    type: FETCH_SPECIAL_BOX_PRODUCTS_LOADING
  }),
  fn: ProductApi.getSpecialBoxProducts,
  success: ({
    products
  }) => ({
    type: FETCH_SPECIAL_BOX_PRODUCTS_SUCCESS,
    products
  }),
  error: errorAction(FETCH_SPECIAL_BOX_PRODUCTS_FAIL)
});