function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector, createStructuredSelector } from 'reselect';
import { getLink } from 'swag-client-common/utils/product.utils';
import { urlModifier } from 'swag-client-common/libs/url-modifier';
import { is1024andSmaller } from 'swag-client-common/utils/ui.utils';
import { getBundleTypeBasedOnResellerSettings } from 'swag-client-common/utils/reseller/get-bundle-type-based-on-reseller-settings.util';
import { BundleType } from 'swag-common/constants/main-swag.constants';
import { STATE_LOADING } from '../redux.constants';
export const searchProductsSelectorBase = state => state.searchProducts;
export const isActiveSelector = createSelector(searchProductsSelectorBase, state => state.isActive);
export const isSearchResultsShownSelector = createSelector(searchProductsSelectorBase, state => state.isSearchResultsShown);
export const isLoadingSelector = createSelector(searchProductsSelectorBase, state => state.loadingState === STATE_LOADING);
export const querySelector = createSelector(searchProductsSelectorBase, state => state.query);
export const productsSelector = createSelector(searchProductsSelectorBase, state => state.products);
export const didSearchSelector = createSelector(searchProductsSelectorBase, state => state.didSearch);
export const totalSelector = createSelector(searchProductsSelectorBase, state => state.total);
export const isOpeningProductFromCategorySelector = createSelector(searchProductsSelectorBase, state => state.isOpeningProductFromCategory);
export const searchSuggestionsSelector = createSelector(searchProductsSelectorBase, state => state.searchSuggestions);
export const searchResultsSelector = createSelector(productsSelector, products => {
  const {
    matchByName = [],
    matchByCategory = [],
    matchByTag = []
  } = products;
  const searchResults = [...matchByName, ...matchByTag, ...matchByCategory];
  const bundleType = getBundleTypeBasedOnResellerSettings();
  const searchResultsWithPricesAndLinks = searchResults.map(product => {
    const link = getLink(product);
    const logo = product.logoBranded ? product.logoBranded : product.logo;
    if (bundleType === BundleType.DYNAMIC) {
      return _objectSpread({}, product, {
        link: urlModifier(link),
        logo,
        minPriceToUse: product.minPriceDynamic,
        maxPriceToUse: product.maxPriceDynamic
      });
    }
    return _objectSpread({}, product, {
      link: urlModifier(link),
      logo,
      minPriceToUse: product.minPrice,
      maxPriceToUse: product.maxPrice
    });
  });
  return searchResultsWithPricesAndLinks;
});
export const noResultsSelector = createSelector(searchResultsSelector, didSearchSelector, isLoadingSelector, (searchResults, didSearch, isLoading) => searchResults.length === 0 && didSearch && !isLoading);
export const searchProductsSelector = createStructuredSelector({
  isActive: isActiveSelector,
  isSearchResultsShown: isSearchResultsShownSelector,
  isLoading: isLoadingSelector,
  isMobileView: () => is1024andSmaller(),
  query: querySelector,
  searchResults: searchResultsSelector,
  noResults: noResultsSelector,
  total: totalSelector,
  searchSuggestions: searchSuggestionsSelector
});
export const isSearchProductsOverlayShownSelector = createSelector(searchProductsSelector, ({
  isActive,
  isSearchResultsShown,
  searchResults,
  noResults,
  isLoading
}) => isActive && isSearchResultsShown && (searchResults.length > 0 || noResults || isLoading));