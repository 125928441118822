function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { goToPageWithReload } from 'swag-client-common/utils/tricks';
import { PAYMENT_STATES } from 'swag-client-common/utils/constants';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { createAsyncAction, emptyAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { OrderApi } from 'swag-client-common/api/order.api';
import UserApi from 'swag-client-common/api/user.api';
import { redirectWithSavingScroll } from 'swag-client-common/utils/tricks';
import { getSwagUser, removeOrderMoreBoxItemsValidityMap, setIsOrderTokenShouldBeRemoved } from 'swag-client-common/utils/local-storage.util';
import { updateUserProfileAction } from 'swag-client-common/redux/user/user.actions';
import { isAddressNotFoundByError, isAddressSuggestedByError } from 'swag-client-common/utils/manual-address-enter.utils';
import { showManualAddressEnterPopup } from 'swag-client-common/redux/partials/autocomplete/autocomplete.action';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { checkIsAddressValidationNeeded, getAddressesFromStateForAutocompleteSelection } from 'swag-client-common/redux/partials/autocomplete/autocomplete.utils';
import { DeliveryKeys } from 'swag-client-common/constants/delivery';
import { getDefaultValue } from 'swag-common/utils/shipping';
import { CREDITS, CREDIT_CARD } from 'swag-common/constants/payment-methods';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { SHOW_ERROR_POPUP, CART_ORDER_VERSION_ERROR_TYPE } from 'swag-client-common/constants';
import { CartErrorCodes } from 'swag-common/constants/error-codes';
import { isResellerInInvoiceModeSelector, loggedInUserIdSelector, resellerAdminModeSelector } from 'swag-client-common/redux/user/user.selectors';
import { INVENTORY_TERMS_ACCEPTED } from 'swag-client-common/constants/local-storage-keys';
import { ResellerAdminMode } from 'swag-common/constants/reseller.constants';
import { COUNTRIES_WITH_STATES } from 'swag-common/constants/shipping.constants';
import { InventoryApi } from '../../../custom-store-admin/api/inventory.api';
import { selectedOptionSelector as autocompleteSelectedOptionSelector, isAddressEnteredManuallySelector } from '../autocomplete/autocomplete.selectors';
import { selectedDistributeOptionSelector, selectedDistributionAreaSelector, selectedInventoryIdSelector, inventoriesSelector } from '../inventory/inventory.selectors';
import { getPaymentMethods, getUserInventories } from '../inventory/inventory.actions';
import { orderTokenSelector, inventoryIdFromCartItemSelector, orderVersionSelector } from '../cart/cart.selectors';
import { getToken } from '../stripe-form/stripe-form.actions';
import { setManualAddressEnter } from '../../../common/redux/partials/autocomplete/autocomplete.action';
import { setShowUpsellWidgetStatus } from '../cart/upsell-widget.actions';
import ItemsApi from '../../api/item.api';
import { getApprovalDepartmentName, getDeliveryFormFields } from './checkout.utils';
import { defaultInventoryAndUserCardsSelector, deliverToFulfillmentCenterSelector, deliveryCountrySelector, deliverySelector, initialPaymentStateSelector, inventoryPaymentMethodFormSelector, isLoggedInSelector, paymentStateSelector, previousPaymentStateSelector, selectedByUserCardToPaySelector } from './checkout.selectors';
export const PREFIX = 'CHECKOUT_';
export const ERROR = `${PREFIX}ERROR`;
export const START_CHECKOUT_LOADING = 'START_CHECKOUT_LOADING';
export const START_PROCEED_LOADING = 'START_PROCEED_LOADING';
export const startLoading = () => dispatch => {
  dispatch({
    type: START_CHECKOUT_LOADING
  });
};
export const startProceedLoading = () => ({
  type: START_PROCEED_LOADING
});
export const CLOSE_DELIVERY_SNACKBAR = `${PREFIX}CLOSE_DELIVERY_SNACKBAR`;
export const closeDeliverySnackBar = emptyAction(CLOSE_DELIVERY_SNACKBAR);
export const EDIT_CHECKOUT_FIELD = 'EDIT_CHECKOUT_FIELD';
export const edit = (field, value) => (dispatch, getState) => {
  const additionalData = {};
  const country = deliveryCountrySelector(getState());
  if (field === DeliveryKeys.Country && value !== country) {
    additionalData.state = getDefaultValue(value);
  }
  dispatch({
    type: EDIT_CHECKOUT_FIELD,
    field,
    value,
    additionalData
  });
};
export const checkDeliveryForm = () => (dispatch, getState) => {
  const delivery = deliverySelector(getState());
  const fields = getDeliveryFormFields();
  fields.map(field => dispatch(edit(field, delivery[field])));
};
export const TOGGLE_FULFILLMENT_CENTER_OPTION = `${PREFIX}TOGGLE_FULFILLMENT_CENTER_OPTION`;
export const toggleDeliverToFulfillmentCenterOption = value => ({
  type: TOGGLE_FULFILLMENT_CENTER_OPTION,
  payload: value
});
function getShowNotificationAcceptedContract() {
  return showNotification({
    text: 'Great! Please proceed to checkout'
  });
}
export const ACCEPT_INVENTORY_TERMS_ERROR = 'ACCEPT_INVENTORY_TERMS_ERROR';
export const ACCEPT_INVENTORY_TERMS_SUCCESS = 'ACCEPT_INVENTORY_TERMS_SUCCESS';
function acceptInventoryTermsSuccessAction(user) {
  return dispatch => {
    dispatch(updateUserProfileAction(user));
    dispatch({
      type: ACCEPT_INVENTORY_TERMS_SUCCESS
    });
    dispatch(acceptContractOption(true));
  };
}
export const acceptInventoryTermsRequest = createAsyncAction({
  preFn: dispatch => dispatch(startLoading()),
  fn: UserApi.acceptInventoryTerms,
  success: acceptInventoryTermsSuccessAction,
  error: errorAction(ACCEPT_INVENTORY_TERMS_ERROR),
  postSuccess: (dispatch, _, data) => {
    if (!(data !== null && data !== void 0 && data.withoutNotification)) {
      dispatch(getShowNotificationAcceptedContract());
    }
  }
});
export const ACCEPT_CONTRACT_OPTION = `${PREFIX}ACCEPT_CONTRACT_OPTION`;
export const acceptContractOption = value => ({
  type: ACCEPT_CONTRACT_OPTION,
  payload: value
});
export const onAcceptContractClickAction = params => (dispatch, getState) => {
  const isLoggedIn = isLoggedInSelector(getState());
  if (isLoggedIn) {
    dispatch(acceptInventoryTermsRequest(params));
    return;
  }
  localStorage.setItem(INVENTORY_TERMS_ACCEPTED, new Date().toISOString());
  dispatch(acceptContractOption(true));
  if (!(params !== null && params !== void 0 && params.withoutNotification)) {
    dispatch(getShowNotificationAcceptedContract());
  }
};
export const ACCEPT_INVENTORY_OPTION = `${PREFIX}ACCEPT_INVENTORY_OPTION`;
export const acceptInventoryOption = value => ({
  type: ACCEPT_INVENTORY_OPTION,
  payload: value
});
export const TOGGLE_CREATE_MY_INVENTORY_SELECTED = `${PREFIX}TOGGLE_CREATE_MY_INVENTORY_SELECTED`;
export const toggleCreateMyInventorySelected = value => ({
  type: TOGGLE_CREATE_MY_INVENTORY_SELECTED,
  payload: value
});
export const TOGGLE_ADD_TO_INVENTORY_WIDGET_OPEN = `${PREFIX}TOGGLE_ADD_TO_INVENTORY_WIDGET_OPEN`;
export const toggleAddToInventoryWidgetOpen = value => ({
  type: TOGGLE_ADD_TO_INVENTORY_WIDGET_OPEN,
  payload: value
});
export const TOGGLE_ADD_TO_INVENTORY_WIDGET_TOUCHED = `${PREFIX}TOGGLE_ADD_TO_INVENTORY_WIDGET_TOUCHED`;
export const toggleAddToInventoryWidgetTouched = value => ({
  type: TOGGLE_ADD_TO_INVENTORY_WIDGET_TOUCHED,
  payload: value
});
const OPERATING_STATE = 'NY';
export const UPDATE_TAXES = 'UPDATE_TAXES';
const updateTaxes = shippingState => {
  const taxes = shippingState === OPERATING_STATE ? 0 : 0.08;
  return {
    type: UPDATE_TAXES,
    taxes
  };
};
export const editShippingState = value => dispatch => {
  dispatch({
    type: EDIT_CHECKOUT_FIELD,
    field: 'state',
    value
  });
  dispatch(updateTaxes(value));
};
export const updateShippingName = () => (dispatch, getState) => {
  const {
    checkout: {
      delivery: {
        fname,
        lname,
        shippingNameUntouched
      }
    }
  } = getState();
  if (shippingNameUntouched) {
    dispatch(edit('lnameShipping', lname));
    dispatch(edit('fnameShipping', fname));
  }
};
export const editShippingName = (field, value) => dispatch => {
  const nonAddressInfo = [DeliveryKeys.FormPhone, DeliveryKeys.FirstNameShipping, DeliveryKeys.LastNameShipping];
  if (field === DeliveryKeys.FormPhone) {
    dispatch(edit(DeliveryKeys.PhoneNumber, value));
  } else {
    dispatch(edit(field, value));
  }
  if (!nonAddressInfo.includes(field)) {
    dispatch(shippingAddressEdit());
  }
  dispatch(shippingNameEdit());
};
export const SHIPPING_NAME_TOUCHED = 'SHIPPING_NAME_TOUCHED';
export const SHIPPING_ADDRESS_TOUCHED = `${PREFIX}SHIPPING_ADDRESS_TOUCHED`;
export const shippingNameEdit = emptyAction(SHIPPING_NAME_TOUCHED);
export const shippingAddressEdit = () => ({
  type: SHIPPING_ADDRESS_TOUCHED
});
export const PROCEED_ORDER_SUCCESS = 'PROCEED_ORDER_SUCCESS';
export const PROCEED_ORDER_FAIL = 'PROCEED_ORDER_FAIL';
export const refreshUpsellWidgetState = createAsyncAction({
  transformData: () => ({
    isDismissed: false
  }),
  fn: OrderApi.setInventoryUpsellWidgetState,
  success: () => setShowUpsellWidgetStatus(true),
  error: () => setShowUpsellWidgetStatus(true)
});
const proceedActionBuilder = fn => {
  return createAsyncAction({
    transformData: (data, getState) => {
      const state = getState();
      const orderToken = orderTokenSelector(state);
      const distributeOption = selectedDistributeOptionSelector(state);
      const distributionArea = selectedDistributionAreaSelector(state);
      const inventoryId = selectedInventoryIdSelector(state);
      const orderVersion = orderVersionSelector(state);
      const user = getSwagUser();
      const {
        capitalOneTransactionId
      } = user;
      const inventoryTermAccepted = localStorage.getItem(INVENTORY_TERMS_ACCEPTED);
      return _objectSpread({}, data, {
        orderToken,
        orderVersion,
        inventoryId,
        distributeOption,
        distributionArea,
        userId: loggedInUserIdSelector(state),
        transactionIds: {
          capitalOneTransactionId
        },
        inventoryTermAccepted
      });
    },
    preFn: dispatch => dispatch(startLoading()),
    fn,
    success: body => {
      setIsOrderTokenShouldBeRemoved();
      removeOrderMoreBoxItemsValidityMap();
      return {
        type: PROCEED_ORDER_SUCCESS,
        body
      };
    },
    error: errorAction(PROCEED_ORDER_FAIL),
    postSuccess: dispatch => {
      dispatch(setManualAddressEnter({
        isAddressEnteredManually: false
      }));
      dispatch(getUserInventories());
      dispatch(refreshUpsellWidgetState());
      localStorage.removeItem(INVENTORY_TERMS_ACCEPTED);
    },
    postError: (dispatch, getState, error) => {
      if (error.code === CartErrorCodes.INVALID_ORDER_VERSION) {
        dispatch(showPopup({
          popup: SHOW_ERROR_POPUP,
          type: CART_ORDER_VERSION_ERROR_TYPE,
          errorMessage: error.message
        }));
      }
    }
  });
};
export const proceedAction = proceedActionBuilder(OrderApi.proceed);
export const proceedAdminAction = proceedActionBuilder(OrderApi.proceedAdmin);
export const proceed = data => (dispatch, getState) => {
  const resellerAdminMode = resellerAdminModeSelector(getState());
  if (resellerAdminMode === ResellerAdminMode.OrderCreation) {
    return dispatch(proceedAdminAction(data || {}));
  }
  return dispatch(proceedAction(data || {}));
};
export const PROCEED_DUTY_ORDER_SUCCESS = `${PREFIX}PROCEED_DUTY_ORDER_SUCCESS`;
export const PROCEED_DUTY_ORDER_FAIL = `${PREFIX}PROCEED_DUTY_ORDER_FAIL`;
export const proceedDutyOrder = createAsyncAction({
  transformData: (data, getState) => {
    const state = getState();
    const inventoryId = selectedInventoryIdSelector(state);
    const distributeOption = selectedDistributeOptionSelector(state);
    const distributionArea = selectedDistributionAreaSelector(state);
    const deliverToFulfillmentCenter = deliverToFulfillmentCenterSelector(state);
    const orderVersion = orderVersionSelector(state);
    const userId = loggedInUserIdSelector(state);
    if (deliverToFulfillmentCenter) {
      return _objectSpread({}, data, {
        orderVersion,
        inventoryId,
        distributeOption,
        distributionArea,
        userId
      });
    }
    return _objectSpread({}, data, {
      orderVersion,
      userId
    });
  },
  preFn: dispatch => dispatch(startLoading()),
  fn: OrderApi.proceedDutyOrder,
  success: body => {
    setIsOrderTokenShouldBeRemoved();
    removeOrderMoreBoxItemsValidityMap();
    return {
      type: PROCEED_ORDER_SUCCESS,
      body
    };
  },
  error: errorAction(PROCEED_DUTY_ORDER_FAIL),
  postSuccess: dispatch => {
    dispatch(getUserInventories());
  },
  postError: (dispatch, getState, error) => {
    if (error.code === CartErrorCodes.INVALID_ORDER_VERSION) {
      dispatch(showPopup({
        popup: SHOW_ERROR_POPUP,
        type: CART_ORDER_VERSION_ERROR_TYPE,
        errorMessage: error.message
      }));
    }
  }
});
export const PAYMENT_POPUP_CLOSE = 'PAYMENT_POPUP_CLOSE';
export const closeDialog = () => (dispatch, getState) => {
  if (getState().checkout.paymentState === PAYMENT_STATES.SUCCESS) {
    goToPageWithReload(APP_ROUTES.HOME);
  }
  dispatch({
    type: RESET_POPUP_STATE
  });
  dispatch({
    type: PAYMENT_POPUP_CLOSE
  });
};
export const GET_RATES_REQUEST = `${PREFIX}GET_RATES_REQUEST`;
const getRatesRequest = emptyAction(GET_RATES_REQUEST);
export const CONFIRM_ADDRESS = 'CONFIRM_ADDRESS';
const confirmAddressSelection = type => dispatch => {
  dispatch({
    type: CONFIRM_ADDRESS,
    payload: type
  });
  dispatch(hidePopup());
  dispatch(getRates());
};
function openManualAddressEnterPopup(address) {
  return (dispatch, getState) => {
    const {
      enteredAddress,
      suggestedAddress
    } = getAddressesFromStateForAutocompleteSelection(deliverySelector(getState()), address);
    const confirmAddresses = [enteredAddress];
    if (suggestedAddress) {
      confirmAddresses.push(suggestedAddress);
    }
    dispatch(showManualAddressEnterPopup({
      confirmAddresses,
      onConfirmAddress: type => dispatch => {
        dispatch(confirmAddressSelection(type));
      },
      onEnterAddress: () => dispatch => {
        dispatch(hidePopup());
      }
    }));
  };
}
function openManualAddressErrorPopup() {
  return (dispatch, getState) => {
    const {
      enteredAddress
    } = getAddressesFromStateForAutocompleteSelection(deliverySelector(getState()));
    dispatch(showManualAddressEnterPopup({
      confirmAddresses: [enteredAddress],
      onConfirmAddress: type => dispatch => {
        dispatch(confirmAddressSelection(type));
      },
      onEnterAddress: () => dispatch => {
        dispatch(hidePopup());
      }
    }));
  };
}
export const GET_RATES_FAIL = `${PREFIX}GET_RATES_FAIL`;
export const GET_RATES_SUCCESS = `${PREFIX}GET_RATES_SUCCESS`;
export const getRatesApi = createAsyncAction({
  transformData: (data, getState) => {
    const state = getState();
    return _objectSpread({}, data, {
      email: data.email.toLowerCase(),
      orderVersion: orderVersionSelector(state),
      userId: loggedInUserIdSelector(state),
      isFromDeliveryPage: true
    });
  },
  preFn: dispatch => {
    dispatch(getRatesRequest());
  },
  fn: OrderApi.getRates,
  success: body => (dispatch, getState) => {
    const isDeliveryToFulfillmentCenter = deliverToFulfillmentCenterSelector(getState());
    const updatedIsDeliveryToFulfillmentCenter = body.order.deliverToFulfillmentCenter;
    if (isDeliveryToFulfillmentCenter !== updatedIsDeliveryToFulfillmentCenter) {
      dispatch(toggleDeliverToFulfillmentCenterOption(updatedIsDeliveryToFulfillmentCenter));
    }
    dispatch({
      type: GET_RATES_SUCCESS,
      body
    });
  },
  postSuccess: (dispatch, getState, data, res) => {
    redirectWithSavingScroll(APP_ROUTES.CHECKOUT)();
    if (res.order.promocodeWasRemoved) {
      dispatch(showNotification({
        text: 'Your promocode has expired'
      }));
    }
  },
  error: (err, transformedData, dispatch) => {
    switch (true) {
      case isAddressSuggestedByError(err):
        {
          dispatch(openManualAddressEnterPopup(err.address));
          break;
        }
      case isAddressNotFoundByError(err):
        {
          dispatch(openManualAddressErrorPopup());
          break;
        }
      case err.code === CartErrorCodes.INVALID_ORDER_VERSION:
        {
          dispatch(showPopup({
            popup: SHOW_ERROR_POPUP,
            type: CART_ORDER_VERSION_ERROR_TYPE,
            errorMessage: err.message
          }));
          break;
        }
      default:
        {
          if (!err.isZipError) {
            dispatch(showNotification({
              text: err.message,
              hideIn: 5000
            }));
          }
        }
    }
    return errorAction(GET_RATES_FAIL)(err);
  }
});
export const recalculateOrderCharges = createAsyncAction({
  transformData: (data, getState) => prepareDataToRates(getState()),
  preFn: dispatch => {
    dispatch(getRatesRequest());
  },
  fn: OrderApi.recalculateOrder,
  success: body => ({
    type: GET_RATES_SUCCESS,
    body
  }),
  error: errorAction(GET_RATES_FAIL)
});
export const getRates = () => (dispatch, getStore) => {
  const body = prepareDataToRates(getStore());
  dispatch(getRatesApi(body));
};
export const PAYMENT_POPUP_OPEN = `${PREFIX}PAYMENT_POPUP_OPEN`;
export const FETCH_CHECKOUT_CREDITS_STARTED = `${PREFIX}FETCH_CREDITS_STARTED`;
export const FETCH_CHECKOUT_CREDITS_SUCCESS = `${PREFIX}FETCH_CREDITS_SUCCESS`;
export const FETCH_CHECKOUT_CREDITS_FAILURE = `${PREFIX}FETCH_CREDITS_FAILURE`;
export const getCheckoutCredits = createAsyncAction({
  fn: InventoryApi.credits.get,
  preFn: dispatch => {
    dispatch({
      type: FETCH_CHECKOUT_CREDITS_STARTED
    });
  },
  success: data => ({
    type: FETCH_CHECKOUT_CREDITS_SUCCESS,
    payload: data
  }),
  error: errorAction(FETCH_CHECKOUT_CREDITS_FAILURE)
});
export const SET_CREDITS_MORE_TO_BUY = `${PREFIX}SET_CREDITS_MORE_TO_BUY`;
export const setCreditsMoreToBuy = value => ({
  type: SET_CREDITS_MORE_TO_BUY,
  payload: value
});
export const SET_BUY_MORE_FLOW = `${PREFIX}SET_BUY_MORE_FLOW`;
export const setBuyMoreFlow = value => ({
  type: SET_BUY_MORE_FLOW,
  payload: value
});
export const openPaymentPopup = () => async (dispatch, getState) => {
  await dispatch(getPaymentMethods());
  const state = getState();
  const paymentState = initialPaymentStateSelector(state);
  dispatch({
    type: PAYMENT_POPUP_OPEN,
    isOpen: true,
    paymentState
  });
};
export const GATHER_ADDRESS_INFO = `${PREFIX}GATHER_ADDRESS_INFO`;
export const gatherBillingAddressInfo = emptyAction(GATHER_ADDRESS_INFO);
export const GATHER_CARD_INFO = `${PREFIX}GATHER_CARD_INFO`;
export const gatherCardInfo = emptyAction(GATHER_CARD_INFO);
export const PREFILL_DELIVERY_CONTACT = `${PREFIX}PREFILL_DELIVERY_CONTACT`;
export const prefillDelivery = () => (dispatch, getState) => {
  const {
    checkout,
    user
  } = getState();
  const {
    firstName,
    lastName,
    phoneNumber,
    company,
    email,
    isResellerAdminLoggedAsResellerUser,
    preBuiltCartUser,
    preBuiltCartUserEmail
  } = user;
  const initialDelivery = checkout.delivery;
  let delivery;

  // Case for preBuild, Invoice, Order Creation, Carts.
  // For all these cases `preBuiltCartUserEmail` shouldn't be empty
  if (isResellerAdminLoggedAsResellerUser && preBuiltCartUserEmail) {
    delivery = {
      fname: initialDelivery.fname || initialDelivery.firstName || (preBuiltCartUser === null || preBuiltCartUser === void 0 ? void 0 : preBuiltCartUser.firstName) || '',
      lname: initialDelivery.lname || initialDelivery.lastName || (preBuiltCartUser === null || preBuiltCartUser === void 0 ? void 0 : preBuiltCartUser.lastName) || '',
      fnameShipping: initialDelivery.fnameShipping || initialDelivery.firstName || (preBuiltCartUser === null || preBuiltCartUser === void 0 ? void 0 : preBuiltCartUser.firstName) || '',
      lnameShipping: initialDelivery.fnameShipping || initialDelivery.lastName || (preBuiltCartUser === null || preBuiltCartUser === void 0 ? void 0 : preBuiltCartUser.lastName) || '',
      company: initialDelivery.company || (preBuiltCartUser === null || preBuiltCartUser === void 0 ? void 0 : preBuiltCartUser.company) || '',
      phoneNumber: initialDelivery.phoneNumber || (preBuiltCartUser === null || preBuiltCartUser === void 0 ? void 0 : preBuiltCartUser.phoneNumber) || '',
      email: initialDelivery.email || (preBuiltCartUser === null || preBuiltCartUser === void 0 ? void 0 : preBuiltCartUser.email) || preBuiltCartUserEmail
    };
  } else {
    delivery = {
      fname: initialDelivery.fname || firstName,
      lname: initialDelivery.lname || lastName,
      company: initialDelivery.company || company,
      phoneNumber: initialDelivery.phoneNumber || phoneNumber,
      email: initialDelivery.email || email
    };
  }
  dispatch({
    type: PREFILL_DELIVERY_CONTACT,
    delivery
  });
};
export const PREFILL_SHIPPING = `${PREFIX}PREFILL_SHIPPING`;
export const prefillShipping = () => (dispatch, getState) => {
  const state = getState();
  const isResellerInInvoiceMode = isResellerInInvoiceModeSelector(state);
  const {
    latestOrderShipping
  } = state.user;
  const {
    country,
    state: deliveryState,
    zip,
    city,
    address
  } = deliverySelector(state);
  const isDeliveryEmpty = !zip && !city && !address;

  // Reset default values. Retrieve delivery info only from the cart when in reseller invoice mode
  if (isResellerInInvoiceMode) {
    const state = COUNTRIES_WITH_STATES.includes(country) ? deliveryState !== null && deliveryState !== void 0 ? deliveryState : '' : '';
    return dispatch({
      type: PREFILL_SHIPPING,
      payload: {
        country: country || '',
        state
      }
    });
  }
  if (latestOrderShipping && isDeliveryEmpty) {
    dispatch({
      type: PREFILL_SHIPPING,
      payload: _objectSpread({}, latestOrderShipping)
    });
  }
};
export const proceedToCheckout = () => (dispatch, getState) => {
  const {
    checkout: {
      addressConfirmed
    }
  } = getState();
  if (addressConfirmed) {
    return redirectWithSavingScroll(APP_ROUTES.CHECKOUT)();
  }
  return dispatch(getRates());
};
const prepareDataToRates = store => {
  let {
    // eslint-disable-next-line prefer-const
    user: {
      paymentMethod,
      companyDepartments
    },
    checkout: {
      delivery: {
        fname,
        lname,
        phoneNumber,
        email,
        company,
        fnameShipping,
        lnameShipping,
        address,
        unit,
        city,
        state,
        country,
        zip,
        deliveryNote,
        // eslint-disable-next-line prefer-const
        isDeliveryPrefilled,
        // eslint-disable-next-line prefer-const
        companyDepartmentId,
        // eslint-disable-next-line prefer-const
        shippingAddressUntouched
      },
      // eslint-disable-next-line prefer-const
      isAddressEnteredManually: isEnteredAddressConfirmedFromPopup,
      // eslint-disable-next-line prefer-const
      deliverToFulfillmentCenter
    },
    // eslint-disable-next-line prefer-const
    cart: {
      orderToken
    },
    // eslint-disable-next-line prefer-const
    inventory: {
      selectedInventoryId: inventoryId
    }
  } = store;
  const distributeOption = selectedDistributeOptionSelector(store);
  const distributionArea = selectedDistributionAreaSelector(store);
  const isAutoCompleteTurnedOff = isAddressEnteredManuallySelector(store);
  const autocompleteSelection = autocompleteSelectedOptionSelector(store);
  const inventories = inventoriesSelector(store);
  const isAddressEnteredManually = !checkIsAddressValidationNeeded({
    isAutoCompleteTurnedOff,
    isManualAddressConfirmed: isEnteredAddressConfirmedFromPopup,
    shippingForm: {
      address,
      city,
      state,
      country,
      zip
    },
    autocompleteSelection,
    shippingAddressUntouched
  });
  fname = `${fname}`.trim();
  lname = `${lname}`.trim();
  phoneNumber = `${phoneNumber}`.trim();
  company = `${company}`.trim();
  email = `${email}`.trim();
  fnameShipping = `${fnameShipping}`.trim();
  lnameShipping = `${lnameShipping}`.trim();
  address = `${address}`.trim();
  unit = `${unit}`.trim();
  city = `${city}`.trim();
  state = `${state}`.trim();
  country = `${country}`.trim();
  zip = `${zip}`.trim();
  deliveryNote = `${deliveryNote}`.trim();
  const companyDepartmentName = getApprovalDepartmentName(companyDepartments, companyDepartmentId);
  const body = {
    email,
    company,
    orderToken,
    phoneNumber,
    paymentMethod,
    lastName: lname,
    firstName: fname,
    distributeOption,
    distributionArea,
    isDeliveryPrefilled,
    companyDepartmentId,
    companyDepartmentName,
    isAddressEnteredManually,
    deliverToFulfillmentCenter,
    inventoryId: inventories.length ? inventoryId : null
  };
  _extends(body, {
    deliveryNote
  });
  if (!deliverToFulfillmentCenter) {
    // tslint:disable-next-line: prefer-object-spread
    _extends(body, {
      fnameShipping,
      lnameShipping,
      city,
      state,
      country,
      zip,
      address,
      unit
    });
  }
  return body;
};
export const RESET_POPUP_STATE = `${PREFIX}RESET_POPUP_STATE`;
export const resetPaymentPopupState = () => dispatch => {
  dispatch({
    type: RESET_POPUP_STATE
  });
};
export const OPEN_CHECKOUT_PAGE = `${PREFIX}OPEN_CHECKOUT_PAGE`;
export const openCheckoutPage = () => dispatch => {
  dispatch({
    type: OPEN_CHECKOUT_PAGE
  });
};
export const CHECK_PAYMENT_METHOD_REQUEST = `${PREFIX}CHECK_PAYMENT_METHOD_REQUEST`;
export const CHECK_PAYMENT_METHOD_SUCCESS = `${PREFIX}CHECK_PAYMENT_METHOD_SUCCESS`;
export const CHECK_PAYMENT_METHOD_ERROR = `${PREFIX}CHECK_PAYMENT_METHOD_ERROR`;
export const checkPaymentMethodByUserEmailToProceedDutyOrder = createAsyncAction({
  preFn: dispatch => dispatch({
    type: CHECK_PAYMENT_METHOD_REQUEST
  }),
  fn: UserApi.fetch,
  success: () => ({
    type: CHECK_PAYMENT_METHOD_SUCCESS
  }),
  postSuccess: (dispatch, getState, data, user) => {
    const serverUserPaymentMethod = user.profile.paymentMethod;
    const clientUserPaymentMethod = getState().user.paymentMethod;
    // compare if server updated pay method while client edited order
    if (clientUserPaymentMethod === serverUserPaymentMethod) {
      dispatch(proceedDutyOrder({
        paymentMethod: clientUserPaymentMethod
      }));
    } else {
      // for loading new payment method
      window.location.reload();
    }
  },
  error: error => ({
    type: CHECK_PAYMENT_METHOD_ERROR,
    error
  })
});
export const SET_PAYMENT_STATE = `${PREFIX}SET_PAYMENT_STATE`;
export const setPaymentState = paymentState => ({
  type: SET_PAYMENT_STATE,
  payload: {
    paymentState
  }
});
export const setPreviousPaymentState = () => (dispatch, getState) => {
  const previousPaymentState = previousPaymentStateSelector(getState());
  dispatch({
    type: SET_PAYMENT_STATE,
    payload: {
      paymentState: previousPaymentState
    }
  });
};
export const preparePaymentToInventoryProceed = (stripe, card, onTokenReceive) => (dispatch, getState) => {
  const store = getState();
  const {
    values
  } = inventoryPaymentMethodFormSelector(store);
  const {
    holder,
    firstName,
    lastName,
    state,
    city,
    country,
    unit,
    zipcode,
    street
  } = values;
  const address = {
    state,
    city,
    country,
    unit,
    zipcode,
    street,
    firstName,
    lastName,
    cardHolder: holder
  };
  dispatch(getToken(address, stripe, card, onTokenReceive));
};
export const proceedInventory = (stripe, card) => (dispatch, getState) => {
  dispatch(startProceedLoading());
  const state = getState();
  const paymentState = paymentStateSelector(state);
  const paymentMethod = paymentState === PAYMENT_STATES.INVENTORY_SELECT_CREDITS ? CREDITS : CREDIT_CARD;
  switch (paymentState) {
    case PAYMENT_STATES.INVENTORY_PREFILLED_CARD:
      {
        const {
          inventoryCard,
          userCard
        } = defaultInventoryAndUserCardsSelector(state);
        if (!userCard && !inventoryCard) {
          return dispatch(showNotification({
            text: "You don't have default payment methods"
          }));
        }
        return dispatch(proceed({
          cardId: (userCard || inventoryCard || {})._id,
          paymentMethod
        }));
      }
    case PAYMENT_STATES.INVENTORY_SELECT_CARD:
      {
        const card = selectedByUserCardToPaySelector(state);
        return dispatch(proceed({
          cardId: card._id,
          paymentMethod
        }));
      }
    case PAYMENT_STATES.INVENTORY_SELECT_CREDITS:
      {
        return dispatch(proceed({
          paymentMethod
        }));
      }
    default:
      {
        const {
          values
        } = inventoryPaymentMethodFormSelector(state);
        const {
          firstName,
          lastName,
          savePaymentDetails
        } = values;
        return dispatch(preparePaymentToInventoryProceed(stripe, card, stripeTokens => dispatch(proceed({
          paymentMethod,
          stripeTokens,
          paymentData: {
            firstName,
            lastName,
            savePaymentDetails: Boolean(savePaymentDetails)
          }
        }))));
      }
  }
};
export const SET_SELECTED_ADDRESS_SUCCESS = 'SET_SELECTED_ADDRESS_SUCCESS';
export const setAddressAction = address => {
  return {
    type: SET_SELECTED_ADDRESS_SUCCESS,
    payload: {
      address: address || {
        state: 'AL',
        city: '',
        zip: ''
      }
    }
  };
};
export const RESET_DELIVERY_OPTION = `${PREFIX}RESET_DELIVERY_OPTION`;
export const resetDeliveryOption = () => ({
  type: RESET_DELIVERY_OPTION
});
export const setBuyMoreCreditsState = () => (dispatch, getState) => {
  const state = getState();
  const paymentState = initialPaymentStateSelector(state);
  const inventoryId = inventoryIdFromCartItemSelector(state);
  if (inventoryId) {
    dispatch(getCheckoutCredits({
      inventoryId
    }));
  }
  dispatch(setPaymentState(paymentState));
};
export const CHANGE_BUY_MORE_CREDITS = `${PREFIX}CHANGE_BUY_MORE_CREDITS`;
export const changeBuyMoreCredits = moreToBuy => ({
  type: CHANGE_BUY_MORE_CREDITS,
  payload: {
    moreToBuy
  }
});
export const TOGGLE_TOS_CHECKBOX = `${PREFIX}TOGGLE_TOS_CHECKBOX`;
export const toggleTOSCheckbox = isChecked => ({
  type: TOGGLE_TOS_CHECKBOX,
  payload: isChecked
});
export const DOWNLOAD_PRESENTATION = `${PREFIX}DOWNLOAD_PRESENTATION`;
export const downloadPresentation = () => ({
  type: DOWNLOAD_PRESENTATION
});
export const DOWNLOAD_INVOICE = `${PREFIX}DOWNLOAD_INVOICE`;
export const downloadCheckoutInvoice = () => ({
  type: DOWNLOAD_INVOICE
});
export const CHANGE_REPLACEMENT_ORDER_SEARCH_VALUE = `${PREFIX}CHANGE_REPLACEMENT_ORDER_SEARCH_VALUE`;
export const changeReplacementOrderSearchValue = value => ({
  type: CHANGE_REPLACEMENT_ORDER_SEARCH_VALUE,
  payload: value
});
export const ERASE_REPLACEMENT_ORDER_STATE = `${PREFIX}ERASE_REPLACEMENT_ORDER_STATE`;
export const eraseReplacementState = () => ({
  type: ERASE_REPLACEMENT_ORDER_STATE
});
export const searchReplacementOrder = ({
  search
}) => dispatch => {
  const cleanSearchStr = search.replace(/#/g, '');
  if (cleanSearchStr.length >= 3) {
    dispatch(searchReplacementOrderRequest({
      search: cleanSearchStr
    }));
  }
};
export const REPLACEMENT_ORDER_SEARCH_REQUEST = `${PREFIX}REPLACEMENT_ORDER_SEARCH_REQUEST`;
export const REPLACEMENT_ORDER_SEARCH_ERROR = `${PREFIX}REPLACEMENT_ORDER_SEARCH_ERROR`;
export const REPLACEMENT_ORDER_SEARCH_SUCCESS = `${PREFIX}REPLACEMENT_ORDER_SEARCH_SUCCESS`;
const REPLACEMENT_ITEMS_LIMIT = 11;
export const searchReplacementOrderRequest = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REPLACEMENT_ORDER_SEARCH_REQUEST
    });
  },
  transformData: ({
    search
  }) => {
    return {
      limit: REPLACEMENT_ITEMS_LIMIT,
      search
    };
  },
  fn: ItemsApi.getByNum,
  success: payload => ({
    type: REPLACEMENT_ORDER_SEARCH_SUCCESS,
    payload
  }),
  error: () => ({
    type: REPLACEMENT_ORDER_SEARCH_ERROR
  })
});
export const SELECT_ORIGINAL_ITEM_FOR_REPLACE = `${PREFIX}SELECT_ORIGINAL_ITEM_FOR_REPLACE`;
export const onSelectOriginalItemForReplaceAction = id => ({
  type: SELECT_ORIGINAL_ITEM_FOR_REPLACE,
  payload: id
});
export const COMMENTS_CHANGE_TEXT = `${PREFIX}COMMENTS_CHANGE_TEXT`;
export const onCommentsChangeAction = str => ({
  type: COMMENTS_CHANGE_TEXT,
  payload: str
});
export const REPLACEMENT_PERCENT_CHANGE = `${PREFIX}REPLACEMENT_PERCENT_CHANGE`;
export const onReplacementPriceRangeAction = payload => ({
  type: REPLACEMENT_PERCENT_CHANGE,
  payload
});
export const REPLACEMENT_IS_SILENT_CHANGE = `${PREFIX}REPLACEMENT_IS_SILENT_CHANGE`;
export const onReplacementIsSilentChangeAction = payload => ({
  type: REPLACEMENT_IS_SILENT_CHANGE,
  payload
});