function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
import { FETCH_PRODUCT_SUCCESS } from '../product/product.actions';
import { STATE_IDLE, STATE_SUCCESS, STATE_ERROR, STATE_LOADING } from '../redux.constants';
import { UPDATE_SIZE_INDEX_FIELD, UPDATE_LOCATIONS_FIELD, UPDATE_COLOR_FIELD, UPDATE_ZIP_FIELD, UPDATE_QUANTITY_FIELD, QUANTITY_ERROR, LOCATIONS_ERROR, UPDATE_COLOR_FIELD_ERROR, UPDATE_TOTAL_PRICE, PREFILL_DATA, CHANGE_SHIPPING_COUNTRY, GET_SHIPMENT_WITH_TAXES_STARTED, GET_SHIPMENT_WITH_TAXES_SUCCESS, GET_SHIPMENT_WITH_TAXES_ERROR, RESET, SEND_EMAIL_REQUEST_TO_HUBSPOT_SUCCESS, UPDATE_EMBELLISHMENT_METHOD } from './instant-quote.actions';
const initialState = {
  quantity: undefined,
  locationsError: undefined,
  status: STATE_IDLE,
  result: {
    quantity: 0,
    totalPrice: 0,
    taxesPrice: 0,
    setupFee: 0,
    pricePerItem: 0
  },
  shippingPrice: 'Please enter ZIP',
  sizeIndex: null,
  locations: 1,
  colorsNumbers: [],
  colorsNumbersErrors: [],
  zip: '',
  quantityError: '',
  locationsErr: '',
  colorErr: '',
  isPriceShown: false,
  shippingError: '',
  country: COUNTRY_CODES.US,
  isEmailRequestToHubspotFormSubmitted: false,
  embellishmentMethodId: null
};
const SHIPPING_ERROR = "Sorry! We're unable to quote shipping costs to your location. You can get shipping costs during checkout";
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_SUCCESS:
      return initialState;
    case UPDATE_ZIP_FIELD:
      {
        const {
          zip
        } = action.payload;
        return _objectSpread({}, state, {
          zip,
          shippingError: initialState.shippingError
        });
      }
    case UPDATE_SIZE_INDEX_FIELD:
      {
        const {
          sizeIndex
        } = action.payload;
        return _objectSpread({}, state, {
          sizeIndex
        });
      }
    case CHANGE_SHIPPING_COUNTRY:
      {
        const {
          country
        } = action.payload;
        return _objectSpread({}, state, {
          country
        });
      }
    case UPDATE_LOCATIONS_FIELD:
      {
        const {
          locations
        } = action.payload;
        return _objectSpread({}, state, {
          locations
        });
      }
    case UPDATE_COLOR_FIELD:
      {
        const {
          colorsNumber,
          locationIndex
        } = action.payload;
        const colorsNumbers = state.colorsNumbers.slice();
        colorsNumbers[locationIndex] = colorsNumber;
        return _objectSpread({}, state, {
          colorsNumbers
        });
      }
    case UPDATE_COLOR_FIELD_ERROR:
      {
        const {
          error,
          payload: {
            locationIndex
          }
        } = action;
        const colorsNumbersErrors = state.colorsNumbersErrors.slice();
        colorsNumbersErrors[locationIndex] = error;
        return _objectSpread({}, state, {
          colorsNumbersErrors
        });
      }
    case UPDATE_QUANTITY_FIELD:
      {
        return _objectSpread({}, state, {
          quantity: action.payload.quantity,
          quantityError: ''
        });
      }
    case QUANTITY_ERROR:
      {
        const {
          error,
          quantity
        } = action.payload;
        return _objectSpread({}, state, {
          quantityError: error,
          quantity
        });
      }
    case LOCATIONS_ERROR:
      {
        const {
          error
        } = action;
        return _objectSpread({}, state, {
          locationsError: error
        });
      }
    case PREFILL_DATA:
      {
        const {
          sizeIndex,
          quantity,
          locations,
          colorsNumbers,
          embellishmentMethodId
        } = action.payload;
        return _objectSpread({}, state, {
          sizeIndex,
          quantity,
          locations,
          colorsNumbers,
          embellishmentMethodId
        });
      }
    case UPDATE_TOTAL_PRICE:
      {
        const {
          totalPrice,
          taxesPrice,
          setupFee,
          pricePerItem,
          quantity,
          shippingPrice
        } = action.payload;
        return _objectSpread({}, state, {
          isPriceShown: true,
          result: {
            totalPrice,
            quantity,
            taxesPrice,
            setupFee,
            pricePerItem
          },
          shippingError: initialState.shippingError,
          shippingPrice: shippingPrice || 'Please enter ZIP'
        });
      }
    case GET_SHIPMENT_WITH_TAXES_SUCCESS:
      {
        return _objectSpread({}, state, {
          status: STATE_SUCCESS,
          shippingPrice: action.body.shippingPrice
        });
      }
    case GET_SHIPMENT_WITH_TAXES_STARTED:
      {
        return _objectSpread({}, state, {
          status: STATE_LOADING
        });
      }
    case GET_SHIPMENT_WITH_TAXES_ERROR:
      {
        const shippingPrice = 'Please enter ZIP';
        return _objectSpread({}, state, {
          status: STATE_ERROR,
          shippingError: SHIPPING_ERROR,
          shippingPrice
        });
      }
    case RESET:
      {
        return _objectSpread({}, initialState, {
          embellishmentMethodId: state.embellishmentMethodId,
          quantity: state.quantity,
          sizeIndex: state.sizeIndex
        });
      }
    case SEND_EMAIL_REQUEST_TO_HUBSPOT_SUCCESS:
      {
        return _objectSpread({}, state, {
          isEmailRequestToHubspotFormSubmitted: true
        });
      }
    case UPDATE_EMBELLISHMENT_METHOD:
      {
        const {
          embellishmentMethodId
        } = action.payload;
        return _objectSpread({}, state, {
          embellishmentMethodId
        });
      }
    default:
      return state;
  }
}