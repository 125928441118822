function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
export default class ChatboxManager extends React.Component {
  constructor(...args) {
    // @ts-ignore
    super(...args);
    _defineProperty(this, "isHidden", void 0);
    _defineProperty(this, "staysOnThePageTimer", void 0);
    _defineProperty(this, "timeout", void 0);
    _defineProperty(this, "apiLoaded", void 0);
    this.timeout = 30000;
    window.LC_API = window.LC_API || {};
    window.LC_API.on_after_load = () => {
      this.apiLoaded = true;
    };
    if (matchMedia('only screen and (max-width: 1024px)').matches) {
      this.isHidden = true;
      if (this.apiLoaded) {
        window.LC_API.hide_chat_window();
      } else {
        window.LC_API.on_after_load = () => {
          window.LC_API.hide_chat_window();
        };
      }
    }
  }
  componentDidMount() {
    if (!this.isHidden) {
      this.staysOnThePageTimer = setTimeout(() => {
        this.tryToShowChatbox();
      }, this.timeout);
    }
  }
  componentWillUnmount() {
    clearTimeout(this.staysOnThePageTimer);
  }
  tryToShowChatbox() {
    if (this.apiLoaded) {
      this.showChatbox();
    } else {
      window.LC_API.on_after_load = () => {
        this.showChatbox();
      };
    }
  }
  showChatbox() {
    window.LC_API.open_chat_window();
  }
  render() {
    return null;
  }
}