function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { LOCATION_CHANGE } from 'react-router-redux';
import { DEBOSSED_EMBELISHMENT_METHOD_POPUP } from '../../../common/constants';
import { POPUP_SHOWN } from '../../../common/redux/popup-manager/popup-manager.actions';
const initialState = {
  productId: null,
  hasDebossedPopupBeenShown: false,
  itemId: null,
  hasDebossedPopupBeenShownOnItemEdit: false
};
export function debossedPopupReducer(state = initialState, action) {
  switch (action.type) {
    case POPUP_SHOWN:
      {
        const {
          popup
        } = action.payload;
        const {
          isItemEdit,
          productId,
          itemId
        } = action.payload.params;
        if (popup === DEBOSSED_EMBELISHMENT_METHOD_POPUP && !isItemEdit) {
          return _objectSpread({}, state, {
            productId,
            hasDebossedPopupBeenShown: true
          });
        }
        if (popup === DEBOSSED_EMBELISHMENT_METHOD_POPUP && isItemEdit) {
          return _objectSpread({}, state, {
            itemId,
            hasDebossedPopupBeenShownOnItemEdit: true
          });
        }
        return state;
      }
    case LOCATION_CHANGE:
      {
        return _objectSpread({}, state, {
          productId: null,
          hasDebossedPopupBeenShown: false,
          hasDebossedPopupBeenShownOnItemEdit: false
        });
      }
    default:
      {
        return state;
      }
  }
}