import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/mockup';
export const MockupApi = createApi({
  save: {
    url: baseUrl,
    method: 'post'
  },
  update: {
    url: `${baseUrl}/:id`,
    method: 'put'
  },
  getUserItems: {
    url: `${baseUrl}/user`,
    method: 'get'
  },
  removeItem: {
    url: `${baseUrl}/:id`,
    method: 'delete'
  }
});