import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { browserHistory } from 'react-router';
import { ADD_ITEM_TO_CART, REPLACE_ITEM_SUCCESS, EDIT_ITEM_IN_CART_SUCCESS, MOVE_ITEM_TO_CART_SUCCESS, getCartInitial, getRecentlyViewedAndSuggested, MOVE_ITEM_TO_DRAFT_SUCCESS, REMOVE_ITEM_FROM_CART_SUCCESS, REMOVE_ITEM_FROM_DRAFT_SUCCESS } from 'swag-client-common/redux/cart/cart.actions';
import { CLIENT_LOGIN_SUCCESS, CLIENT_SIGNUP_SUCCESS } from 'swag-client-common/redux/user/user.actions';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { recalculateOrderCharges } from '../checkout/checkout.actions';
import { createDesign } from './product.actions';
export default function ({
  dispatch,
  getState
}) {
  return next => action => {
    next(action);
    switch (action.type) {
      case CLIENT_LOGIN_SUCCESS:
        {
          const {
            accountMockups: {
              isSaveGuestMockup
            },
            logo: {
              layers,
              logos,
              texts
            }
          } = getState();
          if (isSaveGuestMockup) {
            const savedEditorState = {
              logos,
              layers,
              texts
            };
            localStorage.setItem('savedEditorState', JSON.stringify(savedEditorState));
            const designMockupItem = localStorage.getItem('designMockupItem');
            const item = designMockupItem ? JSON.parse(designMockupItem) : null;
            dispatch(createDesign({
              item
            }));
            dispatch(hidePopup());
          }
          break;
        }
      case CLIENT_SIGNUP_SUCCESS:
        {
          const {
            accountMockups: {
              isSaveGuestMockup
            }
          } = getState();
          if (isSaveGuestMockup) {
            const designMockupItem = localStorage.getItem('designMockupItem');
            const item = designMockupItem ? JSON.parse(designMockupItem) : null;
            dispatch(createDesign({
              item
            }));
            dispatch(hidePopup());
            browserHistory.push(APP_ROUTES.ACCOUNT_SAVED_DESIGNS);
          }
          break;
        }
      case ADD_ITEM_TO_CART:
      case MOVE_ITEM_TO_CART_SUCCESS:
        {
          return dispatch(getRecentlyViewedAndSuggested());
        }
      case EDIT_ITEM_IN_CART_SUCCESS:
        {
          if (action.body.recalculateOrder) {
            return dispatch(recalculateOrderCharges());
          }
          break;
        }
      case REMOVE_ITEM_FROM_DRAFT_SUCCESS:
      case REMOVE_ITEM_FROM_CART_SUCCESS:
      case MOVE_ITEM_TO_DRAFT_SUCCESS:
        {
          dispatch(getRecentlyViewedAndSuggested());
          break;
        }
      case REPLACE_ITEM_SUCCESS:
        {
          if (action.body.recalculateOrder) {
            dispatch(recalculateOrderCharges());
          }
          dispatch(getRecentlyViewedAndSuggested());
          dispatch(getCartInitial());
          break;
        }
      default:
    }
  };
}