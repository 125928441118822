import { getComplexColorInfoByPMS } from 'swag-common/utils/colors';
import { MODES, TEXT } from '../select-pantones-popup.constants';
import { isRemoveBGCheckboxShown, isRevertButtonShownSelector, isColorAddingPossibleSelector, capitalizePantoneNames } from '../../../../redux/logo-editing/logo-editing.selectors';
import { isLimitedEditionSelector, variantColorSelector } from '../../../../redux/product/product.selectors';
import { selectedLogoColorsSelector, selectedLogoPreviewSelector, productColorSelector, progressMessageSelector, isAddColorEnabledSelector, confirmTextSelector, cancelTextSelector, isLastLogoSelector, isFirstLogoSelector, isRemoveColorEnabledSelector, itemColorLimitSelector, isErrorMessageShown, productInfoSelector } from '../../../../redux/item-pantones/item-pantones.selectors';
import { STATE_LOADING } from '../../../../redux/redux.constants';
export const selectPantonesPopupSelector = (state, props) => {
  var _colors;
  const {
    logoEditing: {
      convertTo,
      state: logoEditingState,
      selectedColors,
      isBackgroundRemoved,
      preview
    }
  } = state;
  const {
    isDisabled
  } = props;
  let colors = selectedColors.slice();
  if (convertTo) {
    colors = [convertTo];
  }
  colors = capitalizePantoneNames(colors).map(color => getComplexColorInfoByPMS(color.pms));
  const addColorEnabled = isColorAddingPossibleSelector(state) && !isDisabled;
  const removeColorEnabled = ((_colors = colors) === null || _colors === void 0 ? void 0 : _colors.length) > 1 && !isDisabled;
  const isRevertButtonShown = isRevertButtonShownSelector(state);
  const isLimitedEdition = isLimitedEditionSelector(state);
  return {
    colors,
    preview,
    addColorEnabled,
    removeColorEnabled,
    isBackgroundRemoved,
    isRevertButtonShown,
    isConverted: !!convertTo,
    isLimitedEdition,
    mode: MODES.PRODUCT_BUILDER,
    isConvertEnabled: true,
    confirmText: TEXT.CONFIRM,
    cancelText: TEXT.DO_THIS_LATER,
    isOneColorDetected: colors.length === 1,
    variantColor: variantColorSelector(state),
    loading: logoEditingState === STATE_LOADING,
    isRemoveBGCheckboxShown: isRemoveBGCheckboxShown(state),
    isLastLogo: false,
    isFirstLogo: false,
    maxPrintColors: 0,
    progressMessage: null,
    isErrorMessageShown: false
  };
};
export const confirmItemPantonesSelector = (state, props) => {
  const {
    isDisabled
  } = props;
  return {
    productInfo: productInfoSelector(state),
    colors: selectedLogoColorsSelector(state),
    preview: selectedLogoPreviewSelector(state),
    confirmText: confirmTextSelector(state),
    cancelText: cancelTextSelector(state),
    addColorEnabled: isAddColorEnabledSelector(state) && !isDisabled,
    removeColorEnabled: isRemoveColorEnabledSelector(state) && !isDisabled,
    isBackgroundRemoved: false,
    mode: MODES.DASHBOARD,
    isOneColorDetected: false,
    variantColor: productColorSelector(state),
    loading: false,
    isRemoveBGCheckboxShown: false,
    isLastLogo: isLastLogoSelector(state),
    isFirstLogo: isFirstLogoSelector(state),
    maxPrintColors: itemColorLimitSelector(state),
    progressMessage: progressMessageSelector(state),
    isErrorMessageShown: isErrorMessageShown(state)
  };
};