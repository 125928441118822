function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { HIDE_LOADER, SHOW_LOADER } from '../redux/common/common.actions';
/**
 * Have to hold that actions,
 * because of strange implementation of create async action function
 */
const createSuccessEmptyAction = () => {
  return {
    type: 'ASYNC_ACTION_SUCCESS'
  };
};
const createErrorEmptyAction = () => {
  return {
    type: 'ASYNC_ACTION_ERROR'
  };
};
export const createAsyncLoadedAction = params => {
  return createAsyncAction(_objectSpread({}, params, {
    preFn: (...paramsFn) => {
      paramsFn[0]({
        type: SHOW_LOADER
      });
      if (params.preFn) {
        return params.preFn(...paramsFn);
      }
    },
    success: (...paramsFn) => {
      paramsFn[2]({
        type: HIDE_LOADER
      });
      if (params.success) {
        const result = params.success(...paramsFn);
        if (result) {
          return result;
        }
      }
      return createSuccessEmptyAction();
    },
    error: (...paramsFn) => {
      paramsFn[2]({
        type: HIDE_LOADER
      });
      if (params.error) {
        const result = params.error(...paramsFn);
        if (result) {
          return result;
        }
      }
      return createErrorEmptyAction();
    }
  }));
};