function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Price } from 'swag-client-common/utils/price';
import { getItemPriceMultiplier } from 'swag-common/business-logic/get-item-price-multiplier.logic';
import { PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
import { DesignUploadMode } from 'swag-common/interfaces';
export function formatCartToInvoiceLineItems({
  resellerTierCommission,
  cartItems,
  resellerBonusCommission,
  resellerBonusCommissionThreshold
}) {
  const basePrices = getItemsBasePricesMap(cartItems);
  const orderTotal = Price.getTotalWithoutDiscounts(cartItems);
  const bonusCommissionToApply = orderTotal < resellerBonusCommissionThreshold ? resellerBonusCommission : 0;
  const lineItems = cartItems.map(item => {
    var _item$prod;
    const {
      prod,
      quantity,
      isSample,
      isPrintedSample
    } = item;
    const basePrice = basePrices[String(item._id)];
    const basePricePerItem = Math.round(basePrice / item.quantity);
    const productMarginReduction = prod === null || prod === void 0 ? void 0 : prod.resellerMarginReduction;
    const isBox = (prod === null || prod === void 0 ? void 0 : prod.designUploadMode) === DesignUploadMode.box;
    const assemblyFee = isBox ? ((prod === null || prod === void 0 ? void 0 : prod.assemblyFee) || 0) / 100 : 0;
    const pricePerItem = Math.round(item.price / item.quantity);
    const calculatedItemMultiplier = getItemMultiplier(pricePerItem, basePricePerItem, assemblyFee);
    const priceMultiplier = getItemPriceMultiplier(item);
    const lineItemMultiplier = priceMultiplier || calculatedItemMultiplier;
    const swagProfitWithTierAndBonusInCent = Price.getSwagProfitAmount({
      quantity,
      productMarginReduction,
      basePrice: basePricePerItem,
      tierCommission: resellerTierCommission,
      bonusCommission: bonusCommissionToApply,
      isSample: isSample || isPrintedSample
    });
    const lineItemCommission = getCommissionFromResellerPrice({
      basePrice: basePricePerItem,
      userPrice: pricePerItem,
      productMarginReduction,
      quantity,
      isSample: isSample || isPrintedSample
    });
    const maxValidPrice = getResellerPriceFromCommission({
      commission: 99,
      basePrice: basePricePerItem,
      productMarginReduction,
      quantity,
      isSample: isSample || isPrintedSample
    });
    const minValidPrice = getResellerPriceFromCommission({
      commission: 0,
      basePrice: basePricePerItem,
      productMarginReduction,
      quantity,
      isSample: isSample || isPrintedSample
    });
    return {
      _id: String(item._id),
      name: ((_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.name) || '',
      quantity: item.quantity,
      basePricePerItem: Price.formatPriceToString(basePricePerItem),
      productMarginReduction,
      maxValidPrice: Price.formatPriceToString(maxValidPrice),
      minValidPrice: Price.formatPriceToString(minValidPrice),
      resellerItemPrice: Price.formatPriceToString(swagProfitWithTierAndBonusInCent),
      userItemPrice: Price.formatPriceToString(pricePerItem),
      commission: lineItemCommission,
      taxes: Price.formatPriceToString(item.tax || 0),
      multiplier: lineItemMultiplier,
      assemblyFee,
      isSample: Boolean(isSample || isPrintedSample)
    };
  });
  return lineItems;
}
export function getResellerPriceFromCommission({
  commission,
  basePrice,
  productMarginReduction,
  quantity,
  isSample
}) {
  const swagProfitAmount = Price.getSwagProfitAmount({
    basePrice,
    productMarginReduction,
    quantity,
    isSample
  });
  const updatedPrice = swagProfitAmount * 100 / (100 - commission);
  return updatedPrice;
}
export function getCommissionFromResellerPrice({
  basePrice,
  userPrice,
  productMarginReduction,
  quantity,
  isSample = false
}) {
  if (!userPrice) {
    return 0;
  }
  const swagProfitAmount = Price.getSwagProfitAmount({
    basePrice,
    productMarginReduction,
    quantity,
    isSample
  });
  const resellerProfitAmount = userPrice - swagProfitAmount;
  return resellerProfitAmount / userPrice * 100;
}
export function validateLineItems(items) {
  const errors = {};
  items.forEach(item => {
    const {
      commission,
      maxValidPrice,
      minValidPrice,
      userItemPrice
    } = item;
    const error = {};
    if (commission > 99 || commission < 0) {
      error.commission = 'Commission should be between 0 and 99';
    }
    if (parseFloat(userItemPrice) < parseFloat(minValidPrice) || parseFloat(userItemPrice) > parseFloat(maxValidPrice)) {
      error.userItemPrice = `Price should be between ${minValidPrice} and ${maxValidPrice}`;
    }
    if (Object.keys(error).length > 0) {
      errors[item._id] = error;
    }
  });
  return errors;
}
export function formatInvoiceSavePayload(orderId, items, discountData, paymentTerms) {
  const lineItems = items.map(item => {
    return {
      _id: item._id,
      commission: Number(item.commission),
      taxes: Number(item.taxes) * 100,
      multiplier: Number(item.multiplier)
    };
  });
  const {
    discountType,
    discountValue
  } = discountData;
  const discountValueAmount = discountType === PromocodeMeasurementEnum.ABSOLUTE ? discountValue * 100 : Number(discountValue);
  return {
    orderId,
    lineItems,
    paymentTerms,
    discountData: _objectSpread({}, discountData, {
      discountValue: discountValueAmount
    })
  };
}
export function getItemsBasePricesMap(items) {
  const itemsWithBasePrices = Price.getOrderItemsBasePrices({
    items
  });
  return itemsWithBasePrices.reduce((acc, item) => {
    acc[String(item._id)] = item.price;
    return acc;
  }, {});
}
export function getItemMultiplier(price, basePrice, assemblyFee) {
  if (!price || !basePrice) {
    return 0;
  }
  const multiplier = ((price - assemblyFee) / (basePrice - assemblyFee) - 1) * 100;
  return multiplier;
}
export function recalculateResellerItemPrices({
  lineItems,
  resellerTierCommission,
  resellerBonusCommission,
  resellerBonusCommissionThreshold
}) {
  const orderTotalInCents = lineItems.reduce((acc, el) => acc + parseFloat(el.userItemPrice) * el.quantity * 100, 0);
  const bonusCommissionToApply = orderTotalInCents < resellerBonusCommissionThreshold ? resellerBonusCommission : 0;
  const updatedItems = lineItems.map(item => {
    const {
      quantity,
      productMarginReduction,
      basePricePerItem,
      isSample
    } = item;
    const swagProfitWithTierAndBonus = Price.getSwagProfitAmount({
      quantity,
      productMarginReduction,
      basePrice: parseFloat(basePricePerItem) * 100,
      tierCommission: resellerTierCommission,
      bonusCommission: bonusCommissionToApply,
      isSample
    });
    return _objectSpread({}, item, {
      resellerItemPrice: Price.formatPriceToString(swagProfitWithTierAndBonus / 100, true)
    });
  });
  return updatedItems;
}