import { INVENTORY_PERMISSION_LEVELS } from '../../constants/inventory';
import { canUserPutInto, canUserTakeFrom } from '../../business-logic/inventory';
import { isUserInventoryAdmin } from '../../business-logic/inventory/is-user-inventory-admin.logic';
import { isUserInventoryOwner } from '../../business-logic/inventory/is-user-inventory-owner.logic';

// eslint-disable-next-line @typescript-eslint/ban-types
function bindUserId(fn, userId) {
  return inventory => fn(inventory, userId);
}
export function getHighestDemoInventoryRole(userInventories, userId) {
  if (!userInventories.length || userInventories.some(bindUserId(isUserInventoryOwner, userId))) {
    return INVENTORY_PERMISSION_LEVELS.OWNER;
  }
  if (userInventories.some(bindUserId(isUserInventoryAdmin, userId))) {
    return INVENTORY_PERMISSION_LEVELS.ADMIN;
  }
  const canPut = userInventories.some(bindUserId(canUserPutInto, userId));
  const canTake = userInventories.some(bindUserId(canUserTakeFrom, userId));
  if (canPut && canTake) {
    return INVENTORY_PERMISSION_LEVELS.PUSH_AND_PULL;
  }
  if (canPut) {
    return INVENTORY_PERMISSION_LEVELS.PUSH;
  }
  return INVENTORY_PERMISSION_LEVELS.PULL;
}