import { SENDOSO_SYSTEM_USER_NOT_REGISTERED, INVALID_ORDER_VERSION_ERROR, GIVEAWAY_SUBMISSION_PRODUCTS_INVALID, GIVEAWAY_SUBMISSION_NO_LONGER_AVAILABLE, GIVEAWAY_SUBMISSION_PRODUCTS_AMOUNT_INVALID, POSTAL_SERVER_ERROR, SENDOSO_SHIP_NOTICE_IS_UNAVAILABLE, FORBIDDEN, BUNDLE_HAS_CUSTOM_PACKAGING_IN_STOCK_OR_IN_PRODUCTION, BUNDLE_HAS_ADVANCED_DISTRIBUTION } from 'swag-common/constants/error-codes';
import { INVALID_ORDER_VERSION_ERROR_MESSAGE } from 'swag-client-common/constants/index';
export const ERROR_CODE_TO_EXPLANATION = {
  [SENDOSO_SYSTEM_USER_NOT_REGISTERED]: 'Access token is missing.',
  [SENDOSO_SHIP_NOTICE_IS_UNAVAILABLE]: 'The ship notice is not yet available. Please try again in a few minutes.',
  [INVALID_ORDER_VERSION_ERROR]: INVALID_ORDER_VERSION_ERROR_MESSAGE,
  [GIVEAWAY_SUBMISSION_PRODUCTS_INVALID]: 'Some product is no longer available. Please refresh your page.',
  [GIVEAWAY_SUBMISSION_PRODUCTS_AMOUNT_INVALID]: 'Some product is no longer available. Please refresh your page.',
  [GIVEAWAY_SUBMISSION_NO_LONGER_AVAILABLE]: 'Giveaway no longer available.',
  [BUNDLE_HAS_CUSTOM_PACKAGING_IN_STOCK_OR_IN_PRODUCTION]: 'This box currently has custom packaging in stock or in production and cannot be deleted',
  [BUNDLE_HAS_ADVANCED_DISTRIBUTION]: 'This box currently has custom packaging in stock or in production and cannot be deleted',
  [POSTAL_SERVER_ERROR]: 'Internal server error. Please contact Postal Support.',
  [FORBIDDEN]: 'Sorry, you do not have permission to do this.'
};