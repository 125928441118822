function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export const flattenCategories = categories => categories.reduce((all, nextCategory) => {
  if (nextCategory.subcategories.length >= 1) {
    return [...all, ...nextCategory.subcategories];
  }
  return [...all, nextCategory];
}, []);
export function changeValueInArrayByIndex(index, arrayOfObjects, data) {
  const newData = _objectSpread({}, arrayOfObjects[index], data);
  return [...arrayOfObjects.slice(0, index), newData, ...arrayOfObjects.slice(index + 1, arrayOfObjects.length)];
}
export function deleteItemInArrayByIndex(index, arrayOfObjects) {
  return [...arrayOfObjects.slice(0, index), ...arrayOfObjects.slice(index + 1, arrayOfObjects.length)];
}
export const getDefaultCategory = categories => {
  return categories.find(category => category.isDefault) || categories[0];
};