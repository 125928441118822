function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTotalQuantityForSharedItems, isBox, isItemBoxSpecialProduct } from 'swag-common/utils/order/item-is-box.util';
import { checkIsSplitShippedToCustomer, checkIsSplitShippedToInventory } from 'swag-common/utils/order/is-item-split-shipped';
import { SPLIT_SHIPPED_ITEM_TYPE } from 'swag-common/constants/item-sources';
import { getBiggestScreenPrice } from '../../redux/product/product.utils';
import { getUpsellWidgetData } from '../get-upsell-widget-data.util';
import { updateItemQuantity } from './calculate-upsell-quantity/update-item-quantity.util';
import { getSplitShippedCouple } from './check-is-split-shipped-item-to-customer-has-inventory-couple';
const getSharedItemsList = (item, sharedItems) => {
  if (item && checkIsSplitShippedToCustomer(item)) {
    return sharedItems.filter(_item => _item.splitShippedType !== SPLIT_SHIPPED_ITEM_TYPE.toInventory);
  } else if (item && checkIsSplitShippedToInventory(item)) {
    const splitShippedToCustomer = getSplitShippedCouple({
      allItems: sharedItems,
      item
    });
    // TODO: handle shared for split-shipped
    return splitShippedToCustomer ? [splitShippedToCustomer] : [item];
  }
  return sharedItems;
};
const getQuantityToUse = ({
  boxQuantity,
  item,
  quantity,
  sharedItems
}) => {
  const updatedQuantityForBoxItem = boxQuantity && boxQuantity * (item.quantityPerBox || 1);
  let quantityToUse = typeof quantity === 'number' && isFinite(quantity) ? updatedQuantityForBoxItem || quantity : item.quantity;
  if (item && checkIsSplitShippedToInventory(item)) {
    const coupleToCustomer = getSplitShippedCouple({
      item,
      allItems: sharedItems
    });
    if (coupleToCustomer) {
      quantityToUse += coupleToCustomer.quantity;
    }
  }
  return quantityToUse;
};
export function getUpsellWidgetDataForCartItem({
  item,
  relatedItems,
  sharedItems,
  stockLevelsByProductIdAndVariantSku,
  setupFee,
  isUpsellDismissed = false,
  quantity = null,
  percentToNextPriceBreak,
  isNewPriceAllowedToBeEqualCurrent,
  boxQuantity,
  showSharedProducts,
  nextPriceBreak,
  shouldApplyInitialPB,
  shouldShowSplitShipped,
  featureFlags
}) {
  var _item$prod2;
  if (!item.prod) {
    throw new Error('Item product not found');
  }
  const fullSelectedVariants = item.variants.reduce((acc, selectedVariant) => {
    var _item$prod;
    const productVariant = (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.variants[selectedVariant.variantIndex];
    if (productVariant) {
      return [...acc, _objectSpread({}, productVariant, selectedVariant)];
    }
    return acc;
  }, []);
  const screenPrice = getBiggestScreenPrice(item.logos, item.texts, relatedItems.flatMap(item => item.prod.productSettings));
  const screenPriceWithFallback = isFinite(screenPrice) ? screenPrice : 0;
  const quantityToUse = getQuantityToUse({
    quantity,
    item,
    sharedItems,
    boxQuantity
  });
  const items = getSharedItemsList(item, sharedItems);
  const totalQuantityForSharedItems = getTotalQuantityForSharedItems(_objectSpread({}, item, {
    quantity: quantityToUse
  }), items);
  const product = _objectSpread({}, item.prod, {
    supplierInventoryLevels: stockLevelsByProductIdAndVariantSku[((_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : _item$prod2._id) || '']
  });
  const correctItems = items.map(relatedItem => {
    const quantity = String(item._id) === String(relatedItem._id) ? quantityToUse : relatedItem.quantity;
    return updateItemQuantity({
      item: _objectSpread({}, relatedItem, {
        prod: product
      }),
      quantity,
      blendedQuantity: totalQuantityForSharedItems,
      screenPrice: screenPriceWithFallback
    });
  });
  const associatedProductsQuantity = correctItems.reduce((acc, relatedItem) => {
    if (String(relatedItem._id) === String(item._id)) {
      return acc;
    }
    if (relatedItem.splitShippedType) {
      return acc;
    }
    return acc + relatedItem.quantity;
  }, 0);
  return getUpsellWidgetData({
    isBoxItem: isBox(item),
    isBoxSpecialProduct: isItemBoxSpecialProduct(item),
    item,
    isUpsellDismissed,
    product,
    quantity: quantityToUse,
    associatedProductsQuantity,
    items: correctItems,
    setupFee,
    selectedVariants: fullSelectedVariants,
    isWithRelatedProducts: Boolean(relatedItems.length),
    isWithSharedProducts: sharedItems.length > 1,
    showSharedProducts,
    percentToNextPriceBreak,
    isNewPriceAllowedToBeEqualCurrent,
    boxQuantity,
    screenPrice: screenPriceWithFallback,
    nextPriceBreak,
    shouldApplyInitialPB,
    isSplitShipped: item.splitShippedType !== null,
    shouldShowSplitShipped,
    featureFlags
  });
}