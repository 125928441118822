import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ProfilePasswordComponent } from 'swag-client-common/components/partials/profile-password';
import { changePassword, setPasswordByLoggedIn } from 'swag-client-common/redux/user/user.actions';
import { isErrorSelector, errorMessageSelector, isSuccessSelector, isLoadingSelector, isPasswordNotSetSelector, changeFormCompletedPasswordRequirementsSelector, isChangeFormAllPasswordRequirementsCompletedSelector, isChangeFormPasswordLengthMoreThanMaxAllowedCharactersSelector, isChangeFormPasswordInputFocusedSelector, isChangePasswordLoadingSelector, isChangePasswordErrorSelector, isChangePasswordSuccessSelector, errorMessageChangePasswordSelector, setFormCompletedPasswordRequirementsSelector, isSetFormAllPasswordRequirementsCompletedSelector, isSetFormPasswordLengthMoreThanMaxAllowedCharactersSelector, isSetFormPasswordInputFocusedSelector, isSetPasswordLoadingSelector, isSetPasswordErrorSelector, isSetPasswordSuccessSelector, errorMessageSetPasswordSelector } from '../../../../redux/profile-password-form/profile-password-form.selectors';
const mapStateToProps = createStructuredSelector({
  isError: isErrorSelector,
  errorMessage: errorMessageSelector,
  isSuccess: isSuccessSelector,
  isLoading: isLoadingSelector,
  isPasswordNotSet: isPasswordNotSetSelector,
  changeFormCompletedPasswordRequirements: changeFormCompletedPasswordRequirementsSelector,
  isChangeFormAllPasswordRequirementsCompleted: isChangeFormAllPasswordRequirementsCompletedSelector,
  isChangeFormPasswordLengthMoreThanMaxAllowedCharacters: isChangeFormPasswordLengthMoreThanMaxAllowedCharactersSelector,
  isChangeFormPasswordInputFocused: isChangeFormPasswordInputFocusedSelector,
  isChangePasswordLoading: isChangePasswordLoadingSelector,
  isChangePasswordError: isChangePasswordErrorSelector,
  isChangePasswordSuccess: isChangePasswordSuccessSelector,
  errorMessageChangePassword: errorMessageChangePasswordSelector,
  setFormCompletedPasswordRequirements: setFormCompletedPasswordRequirementsSelector,
  isSetFormAllPasswordRequirementsCompleted: isSetFormAllPasswordRequirementsCompletedSelector,
  isSetFormPasswordLengthMoreThanMaxAllowedCharacters: isSetFormPasswordLengthMoreThanMaxAllowedCharactersSelector,
  isSetFormPasswordInputFocused: isSetFormPasswordInputFocusedSelector,
  isSetPasswordLoading: isSetPasswordLoadingSelector,
  isSetPasswordError: isSetPasswordErrorSelector,
  isSetPasswordSuccess: isSetPasswordSuccessSelector,
  errorMessageSetPassword: errorMessageSetPasswordSelector
});
const mapDispatchToProps = dispatch => ({
  onChangeSubmit: values => {
    dispatch(changePassword(values));
  },
  onSetSubmit: values => {
    dispatch(setPasswordByLoggedIn(values));
  }
});
export const ProfilePassword = connect(mapStateToProps, mapDispatchToProps)(ProfilePasswordComponent);