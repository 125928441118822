export const isSameYear = (first, second) => {
  return first.getFullYear() === second.getFullYear();
};
export const isSameMonth = (first, second) => {
  if (!isSameYear(first, second)) {
    return false;
  }
  return first.getMonth() === second.getMonth();
};
export const isSameDate = (first, second) => {
  if (!isSameMonth(first, second)) {
    return false;
  }
  return first.getDate() === second.getDate();
};
export const isSameDateOrAfter = (first, second) => first >= second;