import { createSelector } from 'reselect';
import { capitalize } from 'swag-common/utils/strings';
import { getOrderItemImage } from 'swag-common/business-logic/items/get-order-item-image.logic';
import { popupParamsSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
const collectSizesRootSelector = state => state.collectSizes;
const ownPropsSizeSubmissionCardIdSelectors = (state, ownProps) => ownProps.cardId;
export const isUserCreatedCollectSizesBeforeSelector = createSelector(collectSizesRootSelector, state => state === null || state === void 0 ? void 0 : state.isUserCreatedCollectSizesBefore);
export const isSuccessfullySubmittedSelector = createSelector(collectSizesRootSelector, state => state === null || state === void 0 ? void 0 : state.isSuccessfullySubmitted);
export const generatedLinkSelector = createSelector(collectSizesRootSelector, state => state === null || state === void 0 ? void 0 : state.link);
export const collectSizesLoadingSelector = createSelector(collectSizesRootSelector, state => state === null || state === void 0 ? void 0 : state.loading);
export const wizardStepSelector = createSelector(collectSizesRootSelector, state => state === null || state === void 0 ? void 0 : state.step);
export const isSizeSubmissionsLoadingSelector = createSelector(collectSizesRootSelector, state => {
  var _state$isSizeSubmissi;
  return (_state$isSizeSubmissi = state === null || state === void 0 ? void 0 : state.isSizeSubmissionsLoading) !== null && _state$isSizeSubmissi !== void 0 ? _state$isSizeSubmissi : false;
});
export const cardIdsSelector = createSelector(collectSizesRootSelector, state => {
  var _state$sizeSubmission;
  return (_state$sizeSubmission = state === null || state === void 0 ? void 0 : state.sizeSubmissions.ids) !== null && _state$sizeSubmission !== void 0 ? _state$sizeSubmission : [];
});
export const isEmptySizeSubmissionsPageSelector = createSelector(isSizeSubmissionsLoadingSelector, cardIdsSelector, (isLoading, cardIds) => !cardIds.length && !isLoading);
export const cardDataSelector = createSelector(collectSizesRootSelector, state => {
  var _state$sizeSubmission2;
  return (_state$sizeSubmission2 = state === null || state === void 0 ? void 0 : state.sizeSubmissions.data) !== null && _state$sizeSubmission2 !== void 0 ? _state$sizeSubmission2 : {};
});
export const sizeSubmissionCardSelector = createSelector(ownPropsSizeSubmissionCardIdSelectors, collectSizesRootSelector, (cardId, state) => state.sizeSubmissions.data[cardId]);
export const hasMoreCardsSelector = createSelector(collectSizesRootSelector, state => state.sizeSubmissionPagination.total > state.sizeSubmissions.ids.length);
export const getPerPageSizeSubmissionSelector = createSelector(collectSizesRootSelector, state => state.sizeSubmissionPagination.perPage);
export const getCurrentPageSizeSubmissionSelector = createSelector(collectSizesRootSelector, state => state.sizeSubmissionPagination.page);
export const campaignRootSelector = createSelector(collectSizesRootSelector, state => state.campaign);
export const campaignLinkSelector = createSelector(campaignRootSelector, campaign => campaign === null || campaign === void 0 ? void 0 : campaign.link);
export const senderNameSelector = createSelector(campaignRootSelector, ({
  firstName,
  lastName
}) => capitalize(`${firstName}${lastName ? ' ' + lastName : ''}`));
export const senderEmailSelector = createSelector(campaignRootSelector, campaign => campaign.email);
export const productNameSelector = createSelector(campaignRootSelector, campaign => {
  var _campaign$product;
  return (_campaign$product = campaign.product) === null || _campaign$product === void 0 ? void 0 : _campaign$product.name;
});
export const productDescriptionForWarehouseSelector = createSelector(campaignRootSelector, campaign => {
  var _campaign$product2;
  return (_campaign$product2 = campaign.product) === null || _campaign$product2 === void 0 ? void 0 : _campaign$product2.descriptionForWarehouse;
});
export const productImageSelector = createSelector(campaignRootSelector, campaign => {
  var _mockup$variants$;
  const {
    product,
    mockup
  } = campaign;
  if (!product) {
    return '';
  }
  const variantInd = (mockup === null || mockup === void 0 ? void 0 : (_mockup$variants$ = mockup.variants[0]) === null || _mockup$variants$ === void 0 ? void 0 : _mockup$variants$.variantIndex) || 0;
  const image = getOrderItemImage({
    product: product,
    customerMockups: (mockup === null || mockup === void 0 ? void 0 : mockup.customerMockups) || {},
    variantInd
  });
  return image.url;
});
export const productSizeOptionsSelector = createSelector(campaignRootSelector, campaign => campaign.sizeOptions.map((option, index) => ({
  key: option,
  value: index,
  label: option
})));
export const companySelector = createSelector(campaignRootSelector, campaign => campaign.company);
export const productSpecsSelector = createSelector(campaignRootSelector, campaign => {
  var _campaign$product3;
  return (_campaign$product3 = campaign.product) === null || _campaign$product3 === void 0 ? void 0 : _campaign$product3.specs;
});
export const campaignIdSelector = createSelector(campaignRootSelector, campaign => campaign._id);
export const submitSizeEmailErrorSelector = createSelector(collectSizesRootSelector, state => state.submitSizeEmailError);
export const renameSizeSubmissionIdSelector = createSelector(popupParamsSelector, ({
  renameSizeSubmissionId
}) => renameSizeSubmissionId);
export const nameSizeSubmissionSelector = createSelector(renameSizeSubmissionIdSelector, cardDataSelector, (id, cardData) => {
  var _cardData$id$name, _cardData$id;
  return (_cardData$id$name = (_cardData$id = cardData[id]) === null || _cardData$id === void 0 ? void 0 : _cardData$id.name) !== null && _cardData$id$name !== void 0 ? _cardData$id$name : '';
});