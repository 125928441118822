import _isEmpty from "lodash/isEmpty";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { v4 } from 'uuid';
import { MEDIA_TYPES } from 'swag-client-common/utils/constants';
import { REBUILDING_ITEM_REQUESTED } from 'swag-client-common/redux/cart/cart.actions';
import { DesignUploadMode } from 'swag-common/interfaces';
import { BOX_SINGLE_LOGO } from 'swag-client-common/constants';
import { findAndRemoveNotExistsPrintLocations } from 'swag-client-common/utils/cart.util';
import { CHANGE_VIEW, RESET_REDUCER, GET_FONTS_SUCCESS, TOGGLE_ADD_TEXT, CHANGE_PREVIEW, FETCH_PRODUCT_SUCCESS } from '../product/product.actions';
import { REORDER_ITEM_REQUESTED } from '../account-orders/account-orders.actions';
import { REBUILD_CUSTOMER_MOCKUP } from '../account-mockups/account-mockups.actions';
import { DESIGN_UPLOAD_SUCCESS, RESET_LOGOS } from '../logo-editing/logo-editing.actions';
import { APPLY_SIZE_SUBMISSION_MOCKUP } from '../collect-sizes/collect-sizes.actions';
import { SET_ALL_LOGOS_AND_LAYERS, UPDATE_LOGO_DETAILS, REMOVE, SELECT_CURRENT, ADD_LOGO, UPDATE_LAYERS, CHANGE_LOGO_DIMENSIONS, FIRST_LOGO_UPLOAD, CHANGE_TEXT_FIELD, ADD_TEXT, ADD_NEW_PANTONE, APPLY_CHANGES_TO_ALL } from './logo.actions';
const initialState = {
  isColorsModified: undefined,
  selectedLogo: null,
  selectedText: null,
  activeView: 'front',
  isLogoDimensionsManuallyModified: false,
  layers: {},
  logos: {},
  texts: {},
  fonts: [],
  exactPantones: [],
  logoNeverUploaded: true
};
const initialTextState = {
  inscription: '',
  color: '#000000',
  fontSize: 33,
  // size of font in px
  fontFamily: {},
  location: initialState.activeView,
  rect: {
    top: 50,
    left: 50,
    angle: 0
  }
};
export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_VIEW:
      {
        return _objectSpread({}, state, {
          activeView: action.view
        });
      }
    case FETCH_PRODUCT_SUCCESS:
      {
        const {
          designUploadMode,
          products
        } = action.payload;
        const logos = _objectSpread({}, state.logos);
        const layers = _objectSpread({}, state.layers);
        if (designUploadMode !== DesignUploadMode.box || state.logoNeverUploaded) {
          return state;
        }
        const initialLogo = Object.values(logos).find(logo => logo.location === BOX_SINGLE_LOGO);
        if (initialLogo) {
          products.forEach(({
            _id
          }) => {
            const logoId = v4();
            const location = `${BOX_SINGLE_LOGO}:${_id}`;
            const isLogoExist = Object.values(logos).find(logo => logo.location.includes(_id));
            if (!isLogoExist) {
              logos[logoId] = _objectSpread({}, initialLogo, {
                id: logoId,
                location
              });
              layers[location] = [logoId];
            }
          });
        }
        return _objectSpread({}, state, {
          logos,
          layers
        });
      }
    case SET_ALL_LOGOS_AND_LAYERS:
      {
        const {
          layers,
          logos,
          texts
        } = action.payload;
        return _objectSpread({}, state, {
          layers,
          logos,
          texts
        });
      }
    case CHANGE_LOGO_DIMENSIONS:
      {
        const {
          rect,
          mediaId,
          type,
          isLogoDimensionsManuallyModified
        } = action.payload;
        if (_isEmpty(rect)) {
          return state;
        }
        const nextIsLogoDimensionsManuallyModified = state.isLogoDimensionsManuallyModified || isLogoDimensionsManuallyModified;
        return _objectSpread({}, state, {
          isLogoDimensionsManuallyModified: nextIsLogoDimensionsManuallyModified,
          [type]: _objectSpread({}, state[type], {
            [mediaId]: _objectSpread({}, state[type][mediaId], {
              rect: _objectSpread({}, state[type][mediaId].rect, rect),
              fontSize: rect.fontSize || state[type][mediaId].fontSize
            })
          })
        });
      }
    case UPDATE_LOGO_DETAILS:
      {
        const {
          colorsToPrint,
          detectedColors,
          selectedColors,
          originalPreview,
          convertTo,
          isBackgroundRemoved,
          hasBackground,
          preview,
          printingMethod,
          original,
          previewWOBackground,
          previewsConvertedToOneColor,
          originalFileName,
          rect,
          id,
          isColorsModified = false,
          etag
        } = action.payload;
        const logos = _objectSpread({}, state.logos, {
          [id]: _objectSpread({}, state.logos[id], {
            preview,
            originalPreview,
            original: original || state.logos[id].original,
            previewWOBackground,
            previewsConvertedToOneColor,
            originalFileName: originalFileName || state.logos[id].originalFileName,
            rect: rect || state.logos[id].rect,
            colors: _objectSpread({}, state.logos[id].colors, {
              detectedColors,
              selectedColors,
              colorsToPrint,
              convertTo,
              isBackgroundRemoved,
              hasBackground,
              printingMethod,
              colorsNumber: colorsToPrint.length
            }),
            etag
          })
        });
        return _objectSpread({}, state, {
          logos,
          isColorsModified
        });
      }
    case SELECT_CURRENT:
      {
        const {
          logoId
        } = action.payload;
        return _objectSpread({}, state, {
          selectedLogo: logoId
        });
      }
    case FIRST_LOGO_UPLOAD:
      {
        return _objectSpread({}, state, {
          logoNeverUploaded: false
        });
      }
    case ADD_LOGO:
      {
        const {
          additionalLogos,
          id
        } = action.payload;
        const layers = _objectSpread({}, state.layers);
        const logosToAdd = [action.payload, ...Object.values(additionalLogos)];
        const currentLocationLogos = Object.keys(state.logos).filter(logoId => state.logos[logoId].location === state.activeView);
        logosToAdd.forEach(logo => {
          const layersBasedOnLocation = layers[logo.location] ? [...layers[logo.location]] : [];
          if (layersBasedOnLocation.indexOf(logo.id) === -1) {
            layersBasedOnLocation.push(logo.id);
          }
          layers[logo.location] = layersBasedOnLocation;
        });
        const logos = logosToAdd.reduce((all, logo) => {
          const {
            colorsToPrint,
            detectedColors,
            selectedColors,
            originalPreview,
            convertTo,
            isBackgroundRemoved,
            hasBackground,
            preview,
            printingMethod,
            original,
            previewWOBackground,
            previewsConvertedToOneColor,
            id,
            location,
            originalFileName,
            etag
          } = logo;
          return _objectSpread({}, all, {
            [logo.id]: {
              preview,
              originalPreview,
              original,
              location,
              id,
              previewWOBackground,
              previewsConvertedToOneColor,
              originalFileName,
              rect: state.logos[id] && state.logos[id].rect,
              colors: {
                detectedColors,
                selectedColors,
                colorsToPrint,
                convertTo,
                isBackgroundRemoved,
                hasBackground,
                printingMethod,
                isConfirmedByCustomer: false,
                colorsNumber: colorsToPrint.length
              },
              etag
            }
          });
        }, {});
        return _objectSpread({}, state, {
          logos: _objectSpread({}, state.logos, logos),
          layers,
          selectedLogo: currentLocationLogos.length && id || null
        });
      }
    case DESIGN_UPLOAD_SUCCESS:
      {
        const {
          id,
          view,
          customerDesignLink,
          etag,
          customerDesignOriginalName
        } = action.payload;
        const logos = _objectSpread({}, state.logos, {
          [id]: {
            location: view,
            id,
            original: customerDesignLink,
            originalFileName: customerDesignOriginalName,
            colors: {
              colorsNumber: 0,
              colorsToPrint: ['Custom design']
            },
            etag
          }
        });
        const layers = _objectSpread({}, state.layers, {
          [view]: [id]
        });
        return _objectSpread({}, state, {
          logos,
          layers
        });
      }
    case REMOVE:
      {
        const {
          id
        } = action.payload;
        const mediaTypeToUpdate = state.logos[id] ? MEDIA_TYPES.LOGOS : MEDIA_TYPES.TEXTS;
        const updatedMedia = _objectSpread({}, state[mediaTypeToUpdate]);
        const location = updatedMedia[id].location;
        const layers = _objectSpread({}, state.layers);
        const locationToRemove = {
          [id]: location
        };
        if (location.includes(BOX_SINGLE_LOGO)) {
          const logos = Object.values(updatedMedia);
          logos.forEach(logo => {
            if (logo.location.includes(BOX_SINGLE_LOGO)) {
              locationToRemove[logo.id] = logo.location;
            }
          });
        }
        Object.keys(locationToRemove).forEach(mediaId => {
          const updatedLocationLayers = layers[locationToRemove[mediaId]].filter(item => item !== mediaId);
          if (updatedLocationLayers.length === 0) {
            delete layers[locationToRemove[mediaId]];
          } else {
            layers[locationToRemove[mediaId]] = updatedLocationLayers;
          }
          delete updatedMedia[mediaId];
        });
        return _objectSpread({}, state, {
          [mediaTypeToUpdate]: updatedMedia,
          layers
        });
      }
    case UPDATE_LAYERS:
      {
        const {
          layers,
          view
        } = action.payload;
        return _objectSpread({}, state, {
          layers: _objectSpread({}, state.layers, {
            [view]: layers
          })
        });
      }
    case REORDER_ITEM_REQUESTED:
    case REBUILD_CUSTOMER_MOCKUP:
    case REBUILDING_ITEM_REQUESTED:
    case APPLY_SIZE_SUBMISSION_MOCKUP:
      {
        const {
          item,
          item: {
            products,
            prod: {
              designUploadMode
            },
            logos: boxLogos
          }
        } = action.payload;
        const layers = {};
        if (designUploadMode === DesignUploadMode.box) {
          const logos = products.reduce((all, product) => {
            const productLogos = Object.keys(product.logos).reduce((acc, location) => {
              product.logos[location].forEach(logo => {
                const logoLocation = logo.location.includes(product.prodId) ? logo.location : `${location}:${product.prodId}`;
                all[logo.id] = logo;
                layers[logoLocation] = layers[logoLocation] || [];
                const isNew = !layers[logoLocation].find(id => id === logo.id);
                if (isNew) {
                  layers[logoLocation].push(logo.id);
                }
              });
            }, {});
            return _objectSpread({}, all, productLogos);
          }, {});
          if (boxLogos && boxLogos.front && boxLogos.front.length) {
            const originalLogo = boxLogos.front.pop();
            logos[originalLogo.id] = originalLogo;
            layers[BOX_SINGLE_LOGO] = [originalLogo.id];
          }
          const firstLogo = Object.values(layers).shift();
          return _objectSpread({}, state, {
            logos,
            layers,
            activeView: firstLogo ? firstLogo.location : initialState.activeView,
            logoNeverUploaded: false
          });
        }
        findAndRemoveNotExistsPrintLocations({
          product: item.prod,
          item
        });
        const logos = Object.keys(item.logos).reduce((acc, location) => {
          layers[location] = layers[location] || [];
          item.logos[location].forEach(logo => {
            acc[logo.id] = _objectSpread({}, logo, {
              location
            });
            layers[location].push(logo.id);
          });
          return acc;
        }, {});
        let texts = {};
        if (item.texts) {
          texts = _objectSpread({}, item.texts);
          Object.keys(item.texts).reduce((acc, id) => {
            const {
              location
            } = item.texts[id];
            layers[location] = layers[location] || [];
            layers[location].push(id);
            return acc;
          }, {});
        }
        if (_isEmpty(layers)) {
          return state;
        }
        // @todo. auto generated. fix this error
        // @ts-ignore
        const selectedLogo = Object.values(layers)[0][0];
        return _objectSpread({}, state, {
          logos,
          layers,
          texts,
          selectedLogo,
          logoNeverUploaded: false
        });
      }
    case CHANGE_TEXT_FIELD:
      {
        const {
          value,
          key
        } = action.payload;
        const updatedText = _objectSpread({}, state.texts[state.selectedLogo]);
        updatedText[key] = value;
        return _objectSpread({}, state, {
          texts: _objectSpread({}, state.texts, {
            [state.selectedLogo]: _objectSpread({}, updatedText, {
              fontFamily: _objectSpread({}, updatedText.fontFamily)
            })
          })
        });
      }
    case ADD_TEXT:
      {
        const selectedText = v4();
        const layersBasedOnLocation = state.layers[state.activeView] ? [...state.layers[state.activeView]] : [];
        layersBasedOnLocation.push(selectedText);
        return _objectSpread({}, state, {
          texts: _objectSpread({}, state.texts, {
            [selectedText]: _objectSpread({}, initialTextState, {
              fontFamily: _objectSpread({}, state.fonts[0]),
              location: state.activeView,
              type: 'texts',
              id: selectedText
            })
          }),
          selectedLogo: selectedText,
          layers: _objectSpread({}, state.layers, {
            [state.activeView]: layersBasedOnLocation
          })
        });
      }
    case GET_FONTS_SUCCESS:
      {
        const {
          fonts
        } = action.payload;
        return _objectSpread({}, state, {
          fonts
        });
      }
    case TOGGLE_ADD_TEXT:
      {
        const {
          isAddTextMode
        } = action.payload;
        const {
          selectedLogo,
          texts,
          activeView,
          layers
        } = state;
        const isEmptyTextExisted = texts[selectedLogo] && !texts[selectedLogo].inscription;
        if (!isAddTextMode && isEmptyTextExisted) {
          const updatedTexts = _objectSpread({}, texts);
          const updatedLayers = _objectSpread({}, layers);
          updatedLayers[activeView] = updatedLayers[activeView].filter(id => id !== selectedLogo);
          delete updatedTexts[selectedLogo];
          return _objectSpread({}, state, {
            texts: updatedTexts,
            selectedLogo: null,
            layers: updatedLayers
          });
        }
        return state;
      }
    case CHANGE_PREVIEW:
      {
        return _objectSpread({}, state, {
          activeView: action.view
        });
      }
    case ADD_NEW_PANTONE:
      {
        const {
          color
        } = action.payload;
        const pantones = [...state.exactPantones];
        const isExistInputtedPantone = pantones.find(pantone => pantone.pms === color.pms);
        if (!isExistInputtedPantone) {
          pantones.push(color);
        }
        return _objectSpread({}, state, {
          exactPantones: pantones,
          texts: _objectSpread({}, state.texts, {
            [state.selectedLogo]: _objectSpread({}, state.texts[state.selectedLogo], {
              color: color.hex
            })
          })
        });
      }
    case RESET_LOGOS:
      {
        return _objectSpread({}, state, {
          logos: {},
          layers: {}
        });
      }
    case APPLY_CHANGES_TO_ALL:
      {
        const {
          logos
        } = action.payload;
        return _objectSpread({}, state, {
          logos
        });
      }
    case RESET_REDUCER:
      return initialState;
    default:
      return state;
  }
}