import _keyBy2 from "lodash/keyBy";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector, createStructuredSelector } from 'reselect';
import { BundleType, FilterIds } from 'swag-common/constants/main-swag.constants';
import { getBundleTypeBasedOnResellerSettings } from 'swag-client-common/utils/reseller/get-bundle-type-based-on-reseller-settings.util';
import { facetsCombiner, getRangeMinEntity, getRangeMaxEntity, getProductionTimeName, getMinQuantityName, formatMinQuantFilter, getMaxPrintColorsName, formatImprintLocationsAmountFilter, isActiveProdTimeLessThan5DaysFilter, sortFiltersInMarketingOrder, getFilterDataOrder } from '../product-listing/product-listing.utils';
import { productIdSelector } from '../product/product.selectors';
import { scrollTopSelector } from '../pages/pages.selectors';
import { isSearchProductsOverlayShownSelector } from '../search-products/search-products.selectors';
import { productListingSelector, currentCategorySelector, currentParentCategorySelector } from '../product-listing/product-listing.selectors';
import { isFilterOpenTenantSelector } from '../product-listing/selectors/is-filter-open-tenant.selector';
import { hasProductSuperSpeedOptionSelector, itemSelector, limitedEditionSelector } from '../../components/page/components/product-card/product-card.selectors';
import { boxAndInventorySelector, shopCustomColorsSelector } from './tenant-specific-selectors';
const searchResultsReducer = state => state.searchResults;
export const isLoadingSelector = createSelector(searchResultsReducer, state => state.isLoading);
export const isFiltersPopupOpenSelector = createSelector(searchResultsReducer, state => state.isFilterPopupOpen);
export const isFiltersPopupShownSelector = createSelector(isFiltersPopupOpenSelector, isSearchProductsOverlayShownSelector, isFilterOpenTenantSelector, (isFilterShown, isOverlayShown, isFilterOpenTenant) => isFilterShown && !isOverlayShown && isFilterOpenTenant);
export const facetsSelector = createSelector(searchResultsReducer, state => state.facets);
export const querySelector = createSelector(searchResultsReducer, state => state.query);
export const productsSelector = createSelector(searchResultsReducer, state => state.products);
export const didSearchSelector = createSelector(searchResultsReducer, state => state.didSearch);
export const sortingSelector = createSelector(searchResultsReducer, state => state.sorting);
export const totalSelector = createSelector(searchResultsReducer, state => state.total);
export const selectedProductSelector = createSelector(searchResultsReducer, state => state.selectedProduct);
export const pageSelector = createSelector(searchResultsReducer, state => state.pagination.page);
export const lastPageSelector = createSelector(searchResultsReducer, state => state.pagination.lastPage);
export const errorSelector = createSelector(searchResultsReducer, state => state.error);
export const isActiveFilterBlockShownSelector = state => !!Object.keys(state.searchResults.appliedFilter).length;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const isProductsListFetchedSelector = () => {};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const subcategoriesSelector = () => {};
export const isScrollPositionShouldBeRestoredSelector = createSelector(scrollTopSelector, scrollTop => !scrollTop);
export const searchResultsSelector = createSelector(productsSelector, products => {
  const {
    matchByName = [],
    matchByCategory = [],
    matchByTag = []
  } = products;
  const searchResults = [...matchByName, ...matchByTag, ...matchByCategory];
  const bundleType = getBundleTypeBasedOnResellerSettings();
  return searchResults.map(product => {
    if (bundleType === BundleType.DYNAMIC) {
      return _objectSpread({}, product, {
        minPriceToUse: product.minPriceDynamic,
        maxPriceToUse: product.maxPriceDynamic
      });
    }
    return _objectSpread({}, product, {
      minPriceToUse: product.minPrice,
      maxPriceToUse: product.maxPrice
    });
  });
});
export const categoryByIdsSelector = createSelector(searchResultsReducer, searchResults => _keyBy2(searchResults.categories.reduce((result, category) => {
  if (category.subcategories) {
    return result.concat([category, ...category.subcategories]);
  }
  return result.concat(category);
}, []), '_id'));
export const noResultsSelector = createSelector(searchResultsSelector, didSearchSelector, isLoadingSelector, (searchResults, didSearch, isLoading) => searchResults.length === 0 && didSearch && !isLoading);
export const selectedFilterCountSelector = state => {
  const filters = appliedFilterSelector(state);
  return Object.values(filters).reduce((filterCount, nextFilter) => {
    if (Array.isArray(nextFilter)) {
      return filterCount + nextFilter.length;
    }
    return filterCount + 1;
  }, 0);
};
export const filterLookupsSelector = createSelector(searchResultsReducer, searchResults => searchResults.filterLookups);
export const appliedFilterSelector = createSelector(searchResultsReducer, searchResults => searchResults.appliedFilter);
export const selectedFilterSelector = createSelector(searchResultsReducer, searchResults => searchResults.selectedFilter);
export const isSuperSpeedActiveFilterSelector = createSelector(selectedFilterSelector, selectedFilter => selectedFilter.isSuperSpeed);
export const disabledFilterSelector = createSelector(isSuperSpeedActiveFilterSelector, isSuperSpeedActive => {
  if (isSuperSpeedActive) {
    return {
      productionTime: true
    };
  }
  return {};
});
const countOfFilterValues = filter => {
  let count = 0;
  Object.keys(filter).forEach(key => {
    if (Array.isArray(filter[key])) {
      count += filter[key].length;
    }
    if (typeof filter[key] === 'string' || typeof filter[key] === 'number') {
      count += 1;
    }
  });
  return count;
};
export const isSuperSpeedActiveWithoutProdTimeFiltersSelector = createSelector(selectedFilterSelector, ({
  isSuperSpeed,
  productionTime
}) => isSuperSpeed && !(productionTime !== null && productionTime !== void 0 && productionTime.length));
export const isActiveProdTimeLessThan5DaysFilterSelector = createSelector(selectedFilterSelector, ({
  productionTime
}) => isActiveProdTimeLessThan5DaysFilter(productionTime));
export const isSuperSpeedActiveWithProdTimeLessThan5DaysFilterSelector = createSelector(selectedFilterSelector, ({
  isSuperSpeed,
  productionTime
}) => {
  return isSuperSpeed && (!(productionTime !== null && productionTime !== void 0 && productionTime.length) || isActiveProdTimeLessThan5DaysFilter(productionTime));
});
export const showSuperSpeedIconSelector = createSelector(isSuperSpeedActiveWithProdTimeLessThan5DaysFilterSelector, isActiveProdTimeLessThan5DaysFilterSelector, hasProductSuperSpeedOptionSelector, (isSuperSpeedActiveFilter, isActiveProdTimeLessThan5DaysFilter, hasProductSuperSpeedOption) => (isSuperSpeedActiveFilter || isActiveProdTimeLessThan5DaysFilter) && hasProductSuperSpeedOption);
export const showProductLabelSelector = createSelector(itemSelector, limitedEditionSelector, showSuperSpeedIconSelector, ({
  productLabel
}, {
  isLimitedEdition
}, showSuperSpeedIcon) => {
  return !showSuperSpeedIcon && !isLimitedEdition && productLabel;
});
export const activeFiltersSelector = createSelector(categoryByIdsSelector, isFiltersPopupShownSelector, appliedFilterSelector, currentCategorySelector, currentParentCategorySelector, (categoryByIds, isFilterOpened, appliedFilter, currentCategory, parentCategory) => {
  const formattedFilter = _objectSpread({}, appliedFilter);
  if (appliedFilter.categoryId) {
    formattedFilter.categoryId = appliedFilter.categoryId.map(category => {
      const result = categoryByIds[category];
      if (result) {
        return result.name;
      }
    });
  }
  if (appliedFilter.productionTime) {
    formattedFilter.productionTime = [getProductionTimeName(appliedFilter.productionTime[0])];
  }
  if (appliedFilter.minQuantity) {
    formattedFilter.minQuantity = [formatMinQuantFilter(appliedFilter.minQuantity[0])];
  }
  if (appliedFilter.maxPrintColors) {
    formattedFilter.maxPrintColors = [getMaxPrintColorsName(appliedFilter.maxPrintColors[0])];
  }
  if (appliedFilter.imprintLocationsAmount) {
    formattedFilter.imprintLocationsAmount = [formatImprintLocationsAmountFilter(appliedFilter.imprintLocationsAmount[0])];
  }
  if (appliedFilter.price) {
    const [min, max] = appliedFilter.price;
    formattedFilter.price = `from $${min} - $${max}`;
  }
  if (appliedFilter.isBox) {
    formattedFilter.isBox = 'Box compatible';
  }
  if (appliedFilter.isInventory) {
    formattedFilter.isInventory = 'Inventory compatible';
  }
  if (appliedFilter.isSuperSpeed) {
    formattedFilter.isSuperSpeed = 'Super Speed';
  }
  const currentMainCategory = parentCategory || currentCategory;
  const subcategories = currentMainCategory.subcategories && currentMainCategory.subcategories.length > 0 ? currentMainCategory.subcategories : [];
  return {
    isFilterOpened,
    isSubcategoriesExist: Boolean(subcategories.length),
    shouldShowFiltersPanel: true,
    isSearch: false,
    filter: formattedFilter,
    columnCount: Math.round(countOfFilterValues(appliedFilter) / 2)
  };
});
const filterItemPropsSelector = (state, props) => props.filterItem;
const filterDataSelector = createSelector(filterItemPropsSelector, selectedFilterSelector, (filterItem, filterLookups) => {
  if (!filterItem) {
    return [];
  }
  const {
    filterData,
    filterId
  } = filterItem;
  return filterLookups[filterId] && filterLookups[filterId].filterData || filterData;
});
export const categoryFacetsSelector = createSelector(searchResultsReducer, searchResults => searchResults.facets.parentCategories || {});
export const priceFacetsSelector = createSelector(searchResultsReducer, searchResults => searchResults.facets.price || {});
export const maxPrintColorsFacetsSelector = createSelector(facetsSelector, facets => facets.maxPrintColorRanges || {});
export const categoriesSelector = createSelector(categoryFacetsSelector, categoryByIdsSelector, (categoryFacets, categoryByIds) => {
  const filterData = (categoryFacets.global || []).reduce((result, category) => {
    const foundCategory = categoryByIds[category.key];
    if (!foundCategory) {
      return result;
    }
    const count = category.doc_count;
    result.push({
      name: foundCategory.name,
      id: foundCategory._id,
      count
    });
    return result;
  }, []).filter(item => item && item.count).sort((a, b) => {
    if (a.name === 'Brands') {
      return 1;
    }
    if (b.name === 'Brands') {
      return -1;
    }
    return a.order - b.order;
  });
  return {
    filterName: 'Category',
    filterId: 'categoryId',
    filterData
  };
});
export const filterItemSelector = createSelector(filterLookupsSelector, selectedFilterSelector, filterDataSelector, disabledFilterSelector, (filterLookups, selectedFilter, data, disabledFilter) => ({
  selectedFilter,
  filterLookups,
  data,
  disabledFilter
}));
export const productAttributeFacetsSelector = createSelector(facetsSelector, facets => facets.attributes || {});
export const productAttributesSelector = createSelector(productAttributeFacetsSelector, facetsCombiner);
export const colorFamilyFacetsSelector = createSelector(facetsSelector, facets => facets.colorFamily || {});
export const minQuantityFacetsSelector = createSelector(facetsSelector, facets => facets.minQuantity || {});
export const embellishmentMethodFacetsSelector = createSelector(facetsSelector, facets => facets.embellishmentMethods || {});
export const imprintLocationsAmountFacetsSelector = createSelector(facetsSelector, facets => facets.imprintLocationsAmount || {});
export const colorFamilySelector = createSelector(colorFamilyFacetsSelector, colorFamilyFacets => {
  const filterData = (colorFamilyFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count && item.key !== 'no-family') {
      result.push({
        name: item.key,
        id: item.key,
        count
      });
    }
    return result;
  }, []);
  return {
    filterName: 'Color',
    filterId: FilterIds.colorFamily,
    filterData: getFilterDataOrder(FilterIds.colorFamily, filterData)
  };
});
export const productionTimeFacetsSelector = createSelector(facetsSelector, facets => facets.productionTime || {});
const createPriceFilterData = ({
  min,
  max
}) => {
  return [{
    name: 'min',
    id: 'min',
    data: getRangeMinEntity(min)
  }, {
    name: 'max',
    id: 'max',
    data: getRangeMaxEntity(max)
  }];
};
export const pricingSelector = createSelector(priceFacetsSelector, priceFacets => {
  const INITIAL_FACET = {
    max: 0,
    min: 0
  };
  const DEFAULT_PRICE_SETTINGS = {
    filterName: 'Price range',
    filterId: FilterIds.price
  };
  const {
    global = INITIAL_FACET
  } = priceFacets;
  return _objectSpread({}, DEFAULT_PRICE_SETTINGS, {
    filterData: createPriceFilterData(global)
  });
});
export const productionTimeSelector = createSelector(productionTimeFacetsSelector, productionTimeFacets => {
  const filterData = (productionTimeFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count) {
      result.push({
        name: getProductionTimeName(item.key),
        id: item.key,
        count
      });
    }
    return result;
  }, []);
  return {
    filterName: 'Production Time',
    filterId: FilterIds.productionTime,
    filterData
  };
});
export const minQuantitySelector = createSelector(minQuantityFacetsSelector, minQuantityFacets => {
  const filterData = (minQuantityFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count) {
      result.push({
        name: getMinQuantityName(item.key),
        id: item.key,
        count
      });
    }
    return result;
  }, []);
  return {
    filterName: 'Minimum Quantity',
    filterId: FilterIds.minQuantity,
    filterData
  };
});
export const embellishmentMethodSelector = createSelector(embellishmentMethodFacetsSelector, embellishmentMethodFacets => {
  const filterData = (embellishmentMethodFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count) {
      result.push({
        name: item.key,
        id: item.key,
        count
      });
    }
    return result;
  }, []).sort((a, b) => a.name.localeCompare(b.name, 'en', {
    sensitivity: 'base'
  }));
  return {
    filterName: 'Imprint method',
    filterId: FilterIds.embellishmentMethod,
    filterData
  };
});
export const maxPrintColorsSelector = createSelector(maxPrintColorsFacetsSelector, maxPrintColorsFacets => {
  const filterData = (maxPrintColorsFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count) {
      result.push({
        name: getMaxPrintColorsName(item.key),
        id: item.key,
        sortOrder: !item.to ? item.from : 10,
        count
      });
    }
    return result;
  }, []).sort((a, b) => a.sortOrder - b.sortOrder);
  return {
    filterName: 'Design Color Limit',
    filterId: FilterIds.maxPrintColors,
    filterData
  };
});
export const imprintLocationsAmountSelector = createSelector(imprintLocationsAmountFacetsSelector, imprintLocationsAmountFacets => {
  const filterData = (imprintLocationsAmountFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count) {
      result.push({
        name: formatImprintLocationsAmountFilter(item.key),
        id: item.key,
        count
      });
    }
    return result;
  }, []).sort(({
    id: a
  }, {
    id: b
  }) => {
    const firstNum = isFinite(+a) ? +a : 10;
    const secondNum = isFinite(+b) ? +b : 10;
    return firstNum - secondNum;
  });
  return {
    filterName: 'Locations to print (on Product)',
    filterId: FilterIds.imprintLocationsAmount,
    filterData
  };
});
export const sortedProductAttributesSelector = createSelector(productAttributesSelector, boxAndInventorySelector, maxPrintColorsSelector, imprintLocationsAmountSelector, embellishmentMethodSelector, (productAttributes, boxAndInventory, maxPrintColors, imprintLocationsAmount, embellishmentMethod) => {
  return sortFiltersInMarketingOrder([...productAttributes, boxAndInventory, maxPrintColors, imprintLocationsAmount, embellishmentMethod]);
});
export const searchProductsSelector = createStructuredSelector({
  isLoading: isLoadingSelector,
  query: querySelector,
  searchResults: searchResultsSelector,
  total: totalSelector,
  noResults: noResultsSelector,
  sorting: sortingSelector,
  page: pageSelector,
  lastPage: lastPageSelector,
  isActiveFilterBlockShown: isActiveFilterBlockShownSelector,
  productId: productIdSelector,
  isScrollPositionShouldBeRestored: isScrollPositionShouldBeRestoredSelector,
  selectedProduct: selectedProductSelector,
  filterCount: selectedFilterCountSelector,
  isFilterPopupOpen: isFiltersPopupShownSelector,
  productAttributes: sortedProductAttributesSelector,
  categories: categoriesSelector,
  colorFamily: colorFamilySelector,
  pricing: pricingSelector,
  productionTime: productionTimeSelector,
  minQuantity: minQuantitySelector,
  embellishmentMethod: embellishmentMethodSelector,
  boxAndInventory: boxAndInventorySelector,
  maxPrintColors: maxPrintColorsSelector,
  imprintLocationsAmount: imprintLocationsAmountSelector,
  productListing: productListingSelector,
  currentCategory: currentCategorySelector,
  customColors: shopCustomColorsSelector
});