import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/item';
const ItemsApi = createApi({
  update: {
    url: `${baseUrl}/update/:id`,
    method: 'PATCH'
  },
  getByNum: {
    url: `${baseUrl}/num`,
    method: 'get'
  }
});
export default ItemsApi;