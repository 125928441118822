function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { STATE_IDLE, STATE_LOADING, STATE_ERROR, STATE_SUCCESS } from '../redux.constants';
import { RESET_FORM, GET_PRODUCTS_STARTED, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_ERROR, CHANGE_FIELD, SHOW_SPECS, HIDE_SPECS, SHOW_TEE_IMAGE, HIDE_TEE_IMAGE } from './kickstarter.actions';
const initialState = {
  status: STATE_IDLE,
  error: '',
  amount: 24,
  amountErr: '',
  locations: 1,
  displayPrices: true,
  productsLoaded: false,
  products: [{
    label: '100% Cotton',
    product: 'swag.com cotton crew',
    price: 0,
    discountPrice: 0,
    data: {}
  }, {
    label: 'Poly-Blend',
    product: 'swag.com polyblend crew',
    price: 0,
    discountPrice: 0,
    data: {}
  }, {
    label: 'Tri-Blend',
    product: 'swag.com triblend crew',
    price: 0,
    discountPrice: 0,
    data: {}
  }],
  specsShown: null,
  teeImageShown: null,
  orderItem: {
    prodTime: 0,
    variantInd: 0,
    sizeInd: 0,
    quantity: 24,
    colors: 1,
    // ToDo: I guess it's a redundant value but for some reasons it's still checked in "_validateOrderItem" function
    logos: {
      front: [{
        colors: {
          colorsNumber: 1,
          colorsToPrint: ['1']
        }
      }],
      back: [{
        colors: {
          colorsNumber: 0,
          colorsToPrint: []
        }
      }],
      left: [{
        colors: {
          colorsNumber: 0,
          colorsToPrint: []
        }
      }],
      right: [{
        colors: {
          colorsNumber: 0,
          colorsToPrint: []
        }
      }]
    }
  }
};
export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_FORM:
      {
        return initialState;
      }
    case GET_PRODUCTS_STARTED:
      {
        return _objectSpread({}, state, {
          status: STATE_LOADING,
          error: ''
        });
      }
    case GET_PRODUCTS_SUCCESS:
      {
        return _objectSpread({}, state, {
          status: STATE_SUCCESS,
          error: '',
          products: action.products
        });
      }
    case GET_PRODUCTS_ERROR:
      {
        return _objectSpread({}, state, {
          status: STATE_ERROR,
          error: action.error.message
        });
      }
    case CHANGE_FIELD:
      {
        return action.nextState;
      }
    case SHOW_SPECS:
      {
        const {
          content,
          images,
          name
        } = action;
        return _objectSpread({}, state, {
          specsShown: {
            content,
            images,
            name
          }
        });
      }
    case HIDE_SPECS:
      {
        return _objectSpread({}, state, {
          specsShown: null
        });
      }
    case SHOW_TEE_IMAGE:
      {
        const {
          images,
          shownImage
        } = action;
        return _objectSpread({}, state, {
          teeImageShown: {
            images,
            shownImage
          }
        });
      }
    case HIDE_TEE_IMAGE:
      {
        return _objectSpread({}, state, {
          teeImageShown: null
        });
      }
    default:
      {
        return state;
      }
  }
}