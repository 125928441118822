import { MAX_PASSWORD_LENGTH } from 'swag-common/constants/validations';
const validate = values => {
  const errors = {};
  if (values.password && values.password.length > MAX_PASSWORD_LENGTH) {
    errors.password = `Maximum ${MAX_PASSWORD_LENGTH} characters`;
  }
  return errors;
};
const warn = values => {
  let passwordWarning = '';
  const password = values.password || '';
  if (!(/[0-9]+/.test(password) && /[a-z]+/.test(password) && /[A-Z]+/.test(password))) {
    passwordWarning = 'Medium security';
  }
  return {
    password: passwordWarning
  };
};
export { validate, warn };