function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ADD_ITEM_TO_CART_REQUEST, ADD_ITEM_TO_CART, ADD_ITEM_TO_DRAFT_REQUEST, ADD_ITEM_TO_DRAFT_SUCCESS, ADD_ITEM_TO_DRAFT_ERROR, ADD_ITEM_TO_CART_ERROR, EDIT_ITEM_IN_CART_REQUEST, EDIT_ITEM_IN_CART_SUCCESS, EDIT_ITEM_IN_CART_FAIL, REMOVE_PROMOCODE_REQUEST, REMOVE_PROMOCODE_SUCCESS, REMOVE_PROMOCODE_FAIL, REMOVE_ITEM_FROM_CART_REQUEST, REMOVE_ITEM_FROM_CART_SUCCESS, REMOVE_ITEM_FROM_CART_FAIL, PROMOCODE_APPLIED_SUCCESS, PROMOCODE_APPLIED_REQUEST, PROMOCODE_APPLIED_ERROR, REPLACE_ITEM_SUCCESS, EDIT_ITEM_IN_DRAFT_REQUEST, EDIT_ITEM_IN_DRAFT_SUCCESS, EDIT_ITEM_IN_DRAFT_FAIL, REMOVE_ITEM_FROM_DRAFT_FAIL, REMOVE_ITEM_FROM_DRAFT_SUCCESS, REMOVE_ITEM_FROM_DRAFT_REQUEST, ERROR } from 'swag-client-common/redux/cart/cart.actions';
import { RESEND_VERIFICATION_EMAIL_LOADING, RESEND_VERIFICATION_EMAIL_SUCCESS, RESEND_VERIFICATION_EMAIL_ERROR } from 'swag-client-common/redux/user/user-additional.actions';
import { GET_SHIPMENT_WITH_TAXES_SUCCESS, GET_SHIPMENT_WITH_TAXES_STARTED, GET_SHIPMENT_WITH_TAXES_ERROR } from '../instant-quote/instant-quote.actions';
import { GET_RATES_REQUEST, GET_RATES_SUCCESS, GET_RATES_FAIL, PROCEED_DUTY_ORDER_SUCCESS, PROCEED_DUTY_ORDER_FAIL, CHECK_PAYMENT_METHOD_REQUEST, CHECK_PAYMENT_METHOD_ERROR, CHECK_PAYMENT_METHOD_SUCCESS } from '../checkout/checkout.actions';
import { SEND_MOCKUP_FAILURE, SEND_MOCKUP_SUCCESS, SEND_MOCKUP_REQUEST } from '../share-design/share-design.actions';
import { SAVE_DESIGN_REQUEST, SAVE_DESIGN_ERROR, SAVE_DESIGN_SUCCESS, UPLOAD_ATTACHMENT_SUCCESS, UPLOAD_ATTACHMENT_FAILURE, UPLOAD_ATTACHMENT_REQUEST, ADD_PRODUCT_TO_STORE_REQUEST, ADD_PRODUCT_TO_STORE_SUCCESS, ADD_PRODUCT_TO_STORE_ERROR, FETCH_PRODUCT_START, FETCH_PRODUCT_END, GET_FONTS_SUCCESS, GET_FONTS_FAILURE, SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_REQUEST, SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_FAILURE, SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_SUCCESS } from '../product/product.actions';
import { APPROVE_COLORS_STARTED, APPROVE_COLORS_SUCCESS, APPROVE_COLORS_ERROR } from '../item-pantones/item-pantones.actions';
import { FETCH_USERS_ORDERS_SUCCESS, FETCH_USERS_ORDERS_ERROR, FETCH_USERS_ORDERS_REQUEST } from '../company-orders/company-orders.actions';
import { LOADING_STARTED, LOADING_ERROR, LOADING_SUCCESS } from '../account-orders/account-orders.actions';
import { REQUEST_IN_A_BOX_CONTACT_FAILURE, REQUEST_IN_A_BOX_CONTACT_SUCCESS, REQUEST_ENTERPRISE_FAILURE, REQUEST_ENTERPRISE_SUCCESS, REQUEST_ENTERPRISE_REQUEST, REQUEST_IN_A_BOX_CONTACT_REQUEST, REQUEST_PATAGONIA_REQUEST, REQUEST_PATAGONIA_SUCCESS, REQUEST_PATAGONIA_FAILURE, REQUEST_GIVEAWAY_CONTACT_REQUEST, REQUEST_GIVEAWAY_CONTACT_FAILURE, REQUEST_GIVEAWAY_CONTACT_SUCCESS, GET_RESELLER_LANDING_DATA_REQUEST, GET_RESELLER_LANDING_DATA_SUCCESS, GET_RESELLER_LANDING_DATA_FAILURE } from '../landings/landings.actions';
import { GET_PAYMENT_METHODS_REQUEST, GET_PAYMENT_METHODS_SUCCESS, GET_PAYMENT_METHODS_FAILURE, BUY_CREDITS_REQUEST, BUY_CREDITS_SUCCESS, BUY_CREDITS_FAILURE } from '../inventory/inventory.actions';
import { SELECT_SHIPPING_METHOD_SUCCESS, SELECT_SHIPPING_METHOD_FAILURE, SELECT_SHIPPING_METHOD_REQUEST } from '../distribution-fee-estimation/distribution-fee-estimation.actions';
import { UPDATE_CART_ITEMS_FAILED, UPDATE_CART_ITEMS_LOADING, UPDATE_CART_ITEMS_SUCCESS, UPDATE_DRAFT_ITEMS_SUCCESS } from '../box-builder/box-builder.actions';
import { BLOCK_PAGE, LOADING_TURN_ON, LOADING_TURN_OFF } from './common.actions';
const initialState = {
  showLoadingOverlay: false,
  isPageBlocked: false
};
export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_TURN_ON:
    case ADD_ITEM_TO_DRAFT_REQUEST:
    case ADD_PRODUCT_TO_STORE_REQUEST:
    case ADD_ITEM_TO_CART_REQUEST:
    case GET_SHIPMENT_WITH_TAXES_STARTED:
    case REMOVE_PROMOCODE_REQUEST:
    case REMOVE_ITEM_FROM_CART_REQUEST:
    case GET_RATES_REQUEST:
    case SEND_MOCKUP_REQUEST:
    case SAVE_DESIGN_REQUEST:
    case APPROVE_COLORS_STARTED:
    case UPLOAD_ATTACHMENT_REQUEST:
    case RESEND_VERIFICATION_EMAIL_LOADING:
    case PROMOCODE_APPLIED_REQUEST:
    case CHECK_PAYMENT_METHOD_REQUEST:
    case FETCH_USERS_ORDERS_REQUEST:
    case REQUEST_ENTERPRISE_REQUEST:
    case FETCH_PRODUCT_START:
    case LOADING_STARTED:
    case REQUEST_IN_A_BOX_CONTACT_REQUEST:
    case REQUEST_PATAGONIA_REQUEST:
    case REQUEST_GIVEAWAY_CONTACT_REQUEST:
    case EDIT_ITEM_IN_DRAFT_REQUEST:
    case REMOVE_ITEM_FROM_DRAFT_REQUEST:
    case EDIT_ITEM_IN_CART_REQUEST:
    case GET_PAYMENT_METHODS_REQUEST:
    case SELECT_SHIPPING_METHOD_REQUEST:
    case UPDATE_CART_ITEMS_LOADING:
    case SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_REQUEST:
    case BUY_CREDITS_REQUEST:
    case GET_RESELLER_LANDING_DATA_REQUEST:
      {
        return _objectSpread({}, state, {
          showLoadingOverlay: true
        });
      }
    case ERROR:
    case LOADING_TURN_OFF:
    case EDIT_ITEM_IN_CART_SUCCESS:
    case REPLACE_ITEM_SUCCESS:
    case UPDATE_DRAFT_ITEMS_SUCCESS:
    case EDIT_ITEM_IN_CART_FAIL:
    case REMOVE_PROMOCODE_SUCCESS:
    case REMOVE_PROMOCODE_FAIL:
    case REMOVE_ITEM_FROM_CART_SUCCESS:
    case REMOVE_ITEM_FROM_CART_FAIL:
    case GET_RATES_SUCCESS:
    case GET_SHIPMENT_WITH_TAXES_SUCCESS:
    case GET_SHIPMENT_WITH_TAXES_ERROR:
    case GET_RATES_FAIL:
    case SEND_MOCKUP_SUCCESS:
    case SEND_MOCKUP_FAILURE:
    case PROMOCODE_APPLIED_SUCCESS:
    case SAVE_DESIGN_ERROR:
    case SAVE_DESIGN_SUCCESS:
    case ADD_ITEM_TO_CART:
    case ADD_ITEM_TO_CART_ERROR:
    case PROMOCODE_APPLIED_ERROR:
    case UPLOAD_ATTACHMENT_FAILURE:
    case UPLOAD_ATTACHMENT_SUCCESS:
    case APPROVE_COLORS_SUCCESS:
    case ADD_PRODUCT_TO_STORE_SUCCESS:
    case ADD_PRODUCT_TO_STORE_ERROR:
    case RESEND_VERIFICATION_EMAIL_SUCCESS:
    case RESEND_VERIFICATION_EMAIL_ERROR:
    case APPROVE_COLORS_ERROR:
    case CHECK_PAYMENT_METHOD_ERROR:
    case CHECK_PAYMENT_METHOD_SUCCESS:
    case PROCEED_DUTY_ORDER_FAIL:
    case PROCEED_DUTY_ORDER_SUCCESS:
    case FETCH_USERS_ORDERS_ERROR:
    case FETCH_USERS_ORDERS_SUCCESS:
    case REQUEST_IN_A_BOX_CONTACT_FAILURE:
    case REQUEST_IN_A_BOX_CONTACT_SUCCESS:
    case REQUEST_ENTERPRISE_SUCCESS:
    case REQUEST_PATAGONIA_SUCCESS:
    case REQUEST_PATAGONIA_FAILURE:
    case FETCH_PRODUCT_END:
    case GET_FONTS_SUCCESS:
    case GET_FONTS_FAILURE:
    case LOADING_ERROR:
    case LOADING_SUCCESS:
    case REQUEST_ENTERPRISE_FAILURE:
    case EDIT_ITEM_IN_DRAFT_SUCCESS:
    case EDIT_ITEM_IN_DRAFT_FAIL:
    case REMOVE_ITEM_FROM_DRAFT_SUCCESS:
    case REMOVE_ITEM_FROM_DRAFT_FAIL:
    case ADD_ITEM_TO_DRAFT_SUCCESS:
    case ADD_ITEM_TO_DRAFT_ERROR:
    case GET_PAYMENT_METHODS_SUCCESS:
    case GET_PAYMENT_METHODS_FAILURE:
    case SELECT_SHIPPING_METHOD_SUCCESS:
    case SELECT_SHIPPING_METHOD_FAILURE:
    case SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_SUCCESS:
    case SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_FAILURE:
    case REQUEST_GIVEAWAY_CONTACT_SUCCESS:
    case REQUEST_GIVEAWAY_CONTACT_FAILURE:
    case UPDATE_CART_ITEMS_SUCCESS:
    case UPDATE_CART_ITEMS_FAILED:
    case BUY_CREDITS_FAILURE:
    case BUY_CREDITS_SUCCESS:
    case GET_RESELLER_LANDING_DATA_SUCCESS:
    case GET_RESELLER_LANDING_DATA_FAILURE:
      {
        return _objectSpread({}, state, {
          showLoadingOverlay: false
        });
      }
    case BLOCK_PAGE:
      {
        return _objectSpread({}, state, {
          isPageBlocked: action.payload.isPageBlocked
        });
      }
    default:
      {
        return state;
      }
  }
}