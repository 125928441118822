import _getFormMeta from "redux-form/es/getFormMeta";
import _formValueSelector from "redux-form/es/formValueSelector";
import { createSelector } from 'reselect';
import { getCompletedPasswordRequirements } from 'swag-client-common/utils/get-password-requirements.utils';
import { MAX_PASSWORD_LENGTH, PASSWORD_REQUIREMENTS } from 'swag-common/constants/validations';
import { ReduxFormNames } from 'swag-client-common/constants';
const invitationSelector = state => state.invitation;
export const isErrorSelector = createSelector(invitationSelector, invitation => !!invitation.error);
export const errorMessageSelector = createSelector(invitationSelector, invitation => invitation.error);
export const isSuccessSelector = createSelector(invitationSelector, invitation => invitation.isSuccess);
export const isLoadingSelector = createSelector(invitationSelector, invitation => invitation.isLoading);
export const inviteIdSelector = createSelector(invitationSelector, invitation => invitation.inviteId);
export const inviteNameSelector = createSelector(invitationSelector, invitation => invitation.name);
export const inviteInventoryNameSelector = createSelector(invitationSelector, invitation => invitation.inventoryName);
export const invitationMessageSelector = createSelector(invitationSelector, invitation => invitation.invitationMessage);
export const passwordSelector = state => {
  return _formValueSelector(ReduxFormNames.InvitationForm)(state, 'password') || '';
};
export const completedPasswordRequirementsSelector = createSelector(passwordSelector, password => {
  return getCompletedPasswordRequirements(password);
});
export const isPasswordLengthMoreThanMaxAllowedCharactersSelector = createSelector(passwordSelector, password => {
  return password.length > MAX_PASSWORD_LENGTH;
});
export const isAllPasswordRequirementsCompletedSelector = createSelector(isPasswordLengthMoreThanMaxAllowedCharactersSelector, completedPasswordRequirementsSelector, (isPasswordLengthMoreThanMaxAllowedCharacters, completedPasswordRequirements) => {
  return !isPasswordLengthMoreThanMaxAllowedCharacters && completedPasswordRequirements.length === PASSWORD_REQUIREMENTS.length;
});
export const isPasswordInputFocusedSelector = state => {
  var _formMeta$password;
  const formMeta = _getFormMeta(ReduxFormNames.InvitationForm)(state);
  return !!(formMeta !== null && formMeta !== void 0 && (_formMeta$password = formMeta.password) !== null && _formMeta$password !== void 0 && _formMeta$password.active);
};