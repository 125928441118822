export default function getBoxVariantSizeIndex(variantProducts) {
  const firstSizedContent = variantProducts.find(({
    sizeSettings
  }) => {
    return Boolean(sizeSettings);
  });
  if (!firstSizedContent) {
    return 0;
  }
  return firstSizedContent.sizeIndex;
}