import { createApi } from 'swag-client-common/libs/rapi';
const GamificationApi = createApi({
  getLevels: {
    url: '/api/v1/level/',
    method: 'get'
  },
  getTransactions: {
    url: '/api/v1/gamification/history/',
    method: 'get'
  },
  getScoreHistory: {
    url: '/api/v1/gamification/score',
    method: 'get'
  }
});
export default GamificationApi;