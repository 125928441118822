import { COLORS_REVIEW_BY_CUSTOMER_REQUESTED } from 'swag-common/constants/item-statuses.constants';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { FETCH_PROFILE_SUCCESS, FETCH_PROFILE_FAIL } from 'swag-client-common/redux/user/user.actions';
import { getCookie } from 'swag-client-common/utils/auth.utils';
import { LOGIN_POPUP, SIGNUP_POPUP } from 'swag-client-common/constants';
import { browserHistory } from 'react-router';
import { PRODUCTION_MOCKUP_STATUSES } from 'swag-common/constants/order-statuses.constants';
import { viewMockup } from '../redux/mockup-manager/mockup-manager.actions';
import { setPasswordInitialState } from '../redux/set-password/set-password.actions';
import { LOADING_SUCCESS } from '../redux/account-orders/account-orders.actions';
import { itemProductionMockupsSelector as accountItemProductionMockupsSelector } from '../redux/account-orders/account-orders.selectors';
import { isReviewMockupsByCustomerAllowedSelector, isReviewPantonesByCustomerAllowedSelector } from '../redux/account-orders/account-orders-other.selectors';
export default (({
  dispatch,
  getState
}) => next => action => {
  var _state$accountOrders, _state$accountOrders$, _order$items;
  next(action);
  const params = new URLSearchParams(location.search);
  const login = params.get('login');
  const redirectLink = params.get('redirectLink');
  if (action.type === FETCH_PROFILE_FAIL && login) {
    if (!redirectLink) {
      browserHistory.replace(location.pathname);
    }
    dispatch(showPopup({
      popup: LOGIN_POPUP,
      isLabelFixed: true
    }));
    return;
  }
  const signup = params.get('signup');
  if (action.type === FETCH_PROFILE_FAIL && signup) {
    browserHistory.replace(location.pathname);
    dispatch(showPopup({
      popup: SIGNUP_POPUP
    }));
    return;
  }
  const setPassword = params.get('setPassword');
  if (action.type === FETCH_PROFILE_SUCCESS && setPassword) {
    dispatch(setPasswordInitialState({
      token: getCookie('jwt'),
      mode: 'setPassword'
    }));
    dispatch(showPopup({
      popup: 'setPassword'
    }));
  }
  if (action.type !== LOADING_SUCCESS) {
    return;
  }
  const state = getState();
  const orderId = params.get('orderId');
  const itemId = params.get('itemId');
  if (!orderId || !itemId) {
    return;
  }
  const order = (_state$accountOrders = state.accountOrders) === null || _state$accountOrders === void 0 ? void 0 : (_state$accountOrders$ = _state$accountOrders.orders) === null || _state$accountOrders$ === void 0 ? void 0 : _state$accountOrders$.find(({
    _id
  }) => _id === orderId);
  const item = order === null || order === void 0 ? void 0 : (_order$items = order.items) === null || _order$items === void 0 ? void 0 : _order$items.find(({
    _id
  }) => _id === itemId);
  const confirmItemPantones = params.get('confirmItemPantones');
  const isReviewPantonesByCustomerAllowed = isReviewPantonesByCustomerAllowedSelector(state, {
    orderId
  });
  if (confirmItemPantones) {
    if (!item || item.status !== COLORS_REVIEW_BY_CUSTOMER_REQUESTED || !isReviewPantonesByCustomerAllowed) {
      return;
    }
    dispatch(showPopup({
      popup: 'confirmItemPantones',
      itemId,
      orderId,
      type: 'account'
    }));
    return;
  }
  const mockupManager = params.get('mockupManager');
  if (mockupManager) {
    const productionMockups = accountItemProductionMockupsSelector(state, {
      orderId,
      itemId
    });
    const isPendingApproveMockupExists = productionMockups.find(mockup => mockup.status === PRODUCTION_MOCKUP_STATUSES.PENDING_CUSTOMER_APPROVAL);
    const isReviewMockupsByCustomerAllowed = isReviewMockupsByCustomerAllowedSelector(state, {
      orderId
    });
    if (!isPendingApproveMockupExists || !isReviewMockupsByCustomerAllowed) {
      return;
    }
    dispatch(viewMockup({
      productionMockups,
      itemId,
      orderId,
      notecardDesignData: (item === null || item === void 0 ? void 0 : item.notecardDesignData) || null,
      logos: (item === null || item === void 0 ? void 0 : item.logos) || null
    }));
    dispatch(showPopup({
      popup: 'mockupManager'
    }));
    return;
  }
});