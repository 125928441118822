function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { PREFILL_DATA, CHANGE_SELECTED, ADD_COLOR, REMOVE_COLOR } from './item-pantones.actions';
const initialState = {
  logosIds: undefined,
  maxPrintColors: undefined,
  itemId: null,
  orderId: null,
  logos: null,
  logosList: [],
  selectedLogo: null
};
export default function itemPantonesReducer(state = initialState, action) {
  switch (action.type) {
    case PREFILL_DATA:
      {
        const {
          itemId,
          orderId,
          logos,
          selectedLogo,
          logosIds,
          maxPrintColors
        } = action.payload;
        return _objectSpread({}, state, {
          itemId,
          orderId,
          logos,
          selectedLogo,
          logosIds,
          maxPrintColors
        });
      }
    case CHANGE_SELECTED:
      {
        const {
          payload
        } = action;
        return _objectSpread({}, state, {
          selectedLogo: payload
        });
      }
    case ADD_COLOR:
      {
        const pantone = action.payload.pms.toLowerCase();
        const logo = state.logos[state.selectedLogo];
        const updatedLogo = _objectSpread({}, logo, {
          colorsToPrint: [...logo.colorsToPrint, pantone]
        });
        return _objectSpread({}, state, {
          logos: _objectSpread({}, state.logos, {
            [updatedLogo.id]: updatedLogo
          })
        });
      }
    case REMOVE_COLOR:
      {
        const pantone = action.payload.toLowerCase();
        const logo = state.logos[state.selectedLogo];
        const updatedLogo = _objectSpread({}, logo, {
          colorsToPrint: logo.colorsToPrint.filter(p => p.toLowerCase() !== pantone)
        });
        return _objectSpread({}, state, {
          logos: _objectSpread({}, state.logos, {
            [updatedLogo.id]: updatedLogo
          })
        });
      }
    default:
      return state;
  }
}