function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { INVENTORY_UPSELL_EDIT_QUANTITY_POPUP, CANCEL_UPSELL_EDIT_QUANTITY_CONFIRMATION_POPUP } from 'swag-client-common/constants';
import { EDIT_ITEM_IN_CART_FAIL, EDIT_ITEM_IN_CART_SUCCESS, editItemInCartRequest } from 'swag-client-common/redux/cart/cart.actions';
import { OrderApi } from 'swag-client-common/api/order.api';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { INVENTORY_UPSELL_KEY } from 'swag-client-common/constants/local-storage-keys';
import { formatCart } from 'swag-client-common/redux/cart/cart.utils';
import { updateUserProfileAction } from 'swag-client-common/redux/user/user.actions';
import { isLoggedInSelector } from '../user/user.selectors';
import { activeStepSelector } from '../checkout/inventory-widget.selector';
import { userSelector } from '../user/user.selectors';
import { acceptContractOption, acceptInventoryOption } from '../checkout/checkout.actions';
import { inventoryUpsellSelectedBoxApparelItems, upsellTotalVariantsQuantityMapSelector, isCheckoutUpsellQuantityEditedSelector, upsellInventoryNonZeroQuantitiesMapSelector, getUpdatedItemsWithUpsell } from './upsell-widget.selectors';
import { INVENTORY_UPSELL_EDIT_MODES } from './cart.constants';
const PREFIX = 'CART_UPSELL';
export const SET_UPSELL_WIDGET_STATUS = `${PREFIX}SET_UPSELL_WIDGET_STATUS`;
export const setShowUpsellWidgetStatus = status => ({
  type: SET_UPSELL_WIDGET_STATUS,
  payload: status
});
export const dismissInventoryUpsellWidget = createAsyncAction({
  transformData: () => ({
    isDismissed: true
  }),
  fn: OrderApi.setInventoryUpsellWidgetState,
  success: () => setShowUpsellWidgetStatus(false),
  postSuccess: (_, getState, __, res) => {
    const isLoggedIn = isLoggedInSelector(getState());
    if (!isLoggedIn) {
      localStorage.setItem(INVENTORY_UPSELL_KEY, res.key);
    }
  },
  error: () => showNotification({
    text: 'Oops! Something went wrong during dismissing upsell widget'
  })
});
export const getInventoryUpsellWidgetStatus = createAsyncAction({
  transformData: (_, getState) => {
    const isLoggedIn = isLoggedInSelector(getState());
    if (!isLoggedIn) {
      const temporaryKey = localStorage.getItem(INVENTORY_UPSELL_KEY) || '';
      return {
        temporaryKey
      };
    }
    return {};
  },
  fn: OrderApi.getInventoryUpsellWidgetState,
  success: ({
    isDismissed
  }) => setShowUpsellWidgetStatus(!isDismissed),
  error: () => setShowUpsellWidgetStatus(true)
});
export const CHANGE_UPSELL_INVENTORY_PRODUCT_QUANTITY = 'CHANGE_UPSELL_INVENTORY_PRODUCT_QUANTITY';
export const changeUpsellInventoryProductQuantity = (quantity, itemId) => ({
  type: CHANGE_UPSELL_INVENTORY_PRODUCT_QUANTITY,
  payload: {
    itemId,
    updatedQuantity: quantity
  }
});
export const INITIALIZE_UPSELL_WIDGET_PRODUCTS_INPUT_DATA = 'INITIALIZE_UPSELL_WIDGET_PRODUCTS_INPUT_DATA';
export const initializeUpsellWidgetProductsInputData = () => ({
  type: INITIALIZE_UPSELL_WIDGET_PRODUCTS_INPUT_DATA
});
export const openEditQuantityPopup = () => dispatch => {
  dispatch(initializeUpsellWidgetProductsInputData());
  dispatch(showPopup({
    popup: INVENTORY_UPSELL_EDIT_QUANTITY_POPUP
  }));
};
export const saveUpsellQuantities = createAsyncAction({
  transformData: (_, getState) => {
    const state = getState();
    const itemsWithUpsell = getUpdatedItemsWithUpsell(state);
    return _objectSpread({}, formatCart({
      items: itemsWithUpsell
    }, getState), {
      isInventoryUpsellUsed: true
    });
  },
  preFn: dispatch => {
    dispatch(editItemInCartRequest());
  },
  fn: OrderApi.updateCart,
  success: (res, {
    recalculateOrder
  }) => ({
    type: EDIT_ITEM_IN_CART_SUCCESS,
    body: res.cart ? _objectSpread({}, res.cart, {
      recalculateOrder
    }) : null
  }),
  postSuccess: dispatch => {
    dispatch(hidePopup());
    dispatch(setInventoryUpsellEditMode(INVENTORY_UPSELL_EDIT_MODES.INFO));
  },
  error: errorAction(EDIT_ITEM_IN_CART_FAIL),
  postError: (dispatch, _, error) => {
    dispatch(showNotification({
      text: error.message
    }));
  }
});
export const SET_INVENTORY_UPSELL_EDIT_MODE = 'SET_INVENTORY_UPSELL_EDIT_MODE';
export const setInventoryUpsellEditMode = mode => ({
  type: SET_INVENTORY_UPSELL_EDIT_MODE,
  payload: mode
});
export const SET_INVENTORY_UPSELL_SELECTED_ITEM_ID_EDIT = 'SET_INVENTORY_UPSELL_SELECTED_ITEM_ID_EDIT';
export const setInventoryUpsellSelectedItemIdForEdit = itemId => ({
  type: SET_INVENTORY_UPSELL_SELECTED_ITEM_ID_EDIT,
  payload: itemId
});
export const SET_INVENTORY_UPSELL_SELECTED_VARIANT_INDEX = 'SET_INVENTORY_UPSELL_SELECTED_VARIANT_INDEX';
export const setInventoryUpsellSelectedColor = (itemId, variantIndex) => ({
  type: SET_INVENTORY_UPSELL_SELECTED_VARIANT_INDEX,
  payload: {
    itemId,
    variantIndex
  }
});
export const SET_INVENTORY_UPSELL_VARIANT_QUANTITY = 'SET_INVENTORY_UPSELL_VARIANT_QUANTITY';
export const setInventoryUpsellVariantQuantity = params => ({
  type: SET_INVENTORY_UPSELL_VARIANT_QUANTITY,
  payload: params
});
export const RESET_INVENTORY_UPSELL_VARIANT_QUANTITY = 'RESET_INVENTORY_UPSELL_VARIANT_QUANTITY';
export const resetInventoryUpsellVariantQuantity = params => ({
  type: RESET_INVENTORY_UPSELL_VARIANT_QUANTITY,
  payload: params
});
export const SET_UPSELL_SEND_TO_INVENTORY_QUANTITY = 'SET_UPSELL_SEND_TO_INVENTORY_QUANTITY';
export const setUpsellSendToInventoryQuantity = params => ({
  type: SET_UPSELL_SEND_TO_INVENTORY_QUANTITY,
  payload: params
});
export const closeEditQuantityPopup = () => (dispatch, getState) => {
  const quantityWasChanged = isCheckoutUpsellQuantityEditedSelector(getState());
  if (quantityWasChanged) {
    dispatch(showPopup({
      popup: CANCEL_UPSELL_EDIT_QUANTITY_CONFIRMATION_POPUP
    }));
    return;
  }
  dispatch(setInventoryUpsellEditMode(INVENTORY_UPSELL_EDIT_MODES.INFO));
  dispatch(hidePopup());
};
export const upsellGoBackInventoryForm = () => (dispatch, getState) => {
  const state = getState();
  const step = activeStepSelector(state);
  const user = userSelector(state);
  switch (step) {
    case 1:
      dispatch(acceptInventoryOption(false));
      return;
    case 2:
      dispatch(acceptContractOption(false));
      dispatch(updateUserProfileAction(_objectSpread({}, user, {
        passedActions: _objectSpread({}, user.passedActions, {
          inventoryTermAccepted: false
        })
      })));
      dispatch(acceptInventoryOption(true));
      return;
    default:
      dispatch(setInventoryUpsellEditMode(INVENTORY_UPSELL_EDIT_MODES.INFO));
  }
};
export const confirmCancelUpsellEditQuantity = () => dispatch => {
  dispatch(setInventoryUpsellEditMode(INVENTORY_UPSELL_EDIT_MODES.INFO));
  dispatch(initializeUpsellWidgetProductsInputData());
  dispatch(hidePopup());
};
export const declineCancelUpsellEditQuantity = () => dispatch => {
  dispatch(showPopup({
    popup: INVENTORY_UPSELL_EDIT_QUANTITY_POPUP
  }));
};
export const SET_INVENTORY_UPSELL_BOX_APPAREL_QUANTITY = 'SET_INVENTORY_UPSELL_BOX_APPAREL_QUANTITY';
export const setInventoryUpsellBoxApparelQuantity = ({
  value,
  sizeIndex,
  sizeName
}) => (dispatch, getState) => {
  const state = getState();
  const apparelItemsInBox = inventoryUpsellSelectedBoxApparelItems(state);
  const variantQuantitiesMap = upsellTotalVariantsQuantityMapSelector(state);
  const payload = apparelItemsInBox.reduce((acc, item) => {
    var _item$prod, _item$prod$size;
    const variantsQuantities = variantQuantitiesMap[item._id];
    if (!variantsQuantities) {
      return acc;
    }
    const quantityPerBox = (item === null || item === void 0 ? void 0 : item.quantityPerBox) || 1;
    const sizeOptions = (item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : (_item$prod$size = _item$prod.size) === null || _item$prod$size === void 0 ? void 0 : _item$prod$size.options) || [];
    const sizeIndexByName = sizeOptions.indexOf(sizeName);
    const computedSizeIndex = sizeIndexByName === -1 ? sizeIndex : sizeIndexByName;
    const sizesQuantitiesInDBMap = item.variants.reduce((acc, {
      variantIndex,
      quantities
    }) => {
      acc[variantIndex] = quantities;
      return acc;
    }, {});
    acc[item._id] = Object.entries(variantsQuantities).reduce((acc, [variantIndex, sizesQuantities]) => {
      var _sizesQuantitiesInDBM;
      const updatedSizesQuantities = [...sizesQuantities];
      const variantIndexNumber = Number(variantIndex);
      const quantityInDB = sizesQuantitiesInDBMap === null || sizesQuantitiesInDBMap === void 0 ? void 0 : (_sizesQuantitiesInDBM = sizesQuantitiesInDBMap[Number(variantIndex)]) === null || _sizesQuantitiesInDBM === void 0 ? void 0 : _sizesQuantitiesInDBM[computedSizeIndex];
      if (isFinite(quantityInDB)) {
        updatedSizesQuantities[computedSizeIndex] = quantityInDB + value * quantityPerBox;
      }
      return _objectSpread({}, acc, {
        [variantIndexNumber]: updatedSizesQuantities
      });
    }, {});
    return acc;
  }, {});
  dispatch({
    payload,
    type: SET_INVENTORY_UPSELL_BOX_APPAREL_QUANTITY
  });
};
export const saveUpsellToCustomerQuantities = () => (dispatch, getState) => {
  const state = getState();
  const itemsToInventory = upsellInventoryNonZeroQuantitiesMapSelector(state);
  if (Object.keys(itemsToInventory).length) {
    dispatch(setInventoryUpsellEditMode(INVENTORY_UPSELL_EDIT_MODES.INVENTORY_FORM));
    return;
  }
  dispatch(saveUpsellQuantities());
};
export const RESET_UPSELL_SEND_TO_INVENTORY_QUANTITY = 'RESET_UPSELL_SEND_TO_INVENTORY_QUANTITY';
export const mergeSplitShippedItems = () => dispatch => {
  dispatch({
    type: RESET_UPSELL_SEND_TO_INVENTORY_QUANTITY
  });
  dispatch(saveUpsellQuantities());
};