import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import BlogApi from 'swag-client-common/api/blog.api';
const PREFIX = 'BLOG_';
export const GET_POSTS_SUCCESS = `${PREFIX}GET_POSTS_SUCCESS`;
export const GET_POSTS_FAILURE = `${PREFIX}GET_POSTS_FAILURE`;
export const getPosts = createAsyncAction({
  fn: BlogApi.posts,
  success: posts => ({
    type: GET_POSTS_SUCCESS,
    payload: {
      posts
    }
  }),
  error: error => ({
    type: GET_POSTS_FAILURE,
    payload: error,
    error
  })
});