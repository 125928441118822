import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import HotProductsApi from 'swag-client-common/api/hot-products.api';
import { setHotProductsToken } from 'swag-client-common/utils/local-storage.util';
import { port } from 'swag-client-common/shared-worker/ecommerce/ecommerce-worker-runner';
import { SOCKET_EVENTS } from 'swag-common/constants/socket-events';
import { showHotProducts, getHotProductsUserToken } from '../../../customer/utils/show-hot-products';
import { draftTokenSelector, orderTokenSelector } from '../cart/cart.selectors';
import { userIdSelector } from '../user/user.selectors';
const PREFIX = 'HOT-PRODUCTS_';
export const MERGE_HOT_PRODUCTS_USER_HISTORY_STARTED = `${PREFIX}MERGE_HOT_PRODUCTS_USER_HISTORY_STARTED`;
export const MERGE_HOT_PRODUCTS_USER_HISTORY_SUCCESS = `${PREFIX}MERGE_HOT_PRODUCTS_USER_HISTORY_SUCCESS`;
export const MERGE_HOT_PRODUCTS_USER_HISTORY_FAIL = `${PREFIX}MERGE_HOT_PRODUCTS_USER_HISTORY_FAIL`;
export const mergeHotProductsUserHistory = createAsyncAction({
  transformData: () => ({
    hotProductsUserToken: getHotProductsUserToken()
  }),
  preFn: dispatch => {
    dispatch({
      type: MERGE_HOT_PRODUCTS_USER_HISTORY_STARTED
    });
  },
  fn: HotProductsApi.merge,
  success: body => ({
    type: MERGE_HOT_PRODUCTS_USER_HISTORY_SUCCESS,
    body
  }),
  error: errorAction(MERGE_HOT_PRODUCTS_USER_HISTORY_FAIL),
  postError: (dispatch, getState) => {
    var _orderTokenSelector, _draftTokenSelector;
    const state = getState();
    const orderToken = (_orderTokenSelector = orderTokenSelector(state)) !== null && _orderTokenSelector !== void 0 ? _orderTokenSelector : '';
    const draftToken = (_draftTokenSelector = draftTokenSelector(state)) !== null && _draftTokenSelector !== void 0 ? _draftTokenSelector : '';
    showHotProducts({
      dispatch,
      userId: null,
      orderToken,
      draftToken
    });
  },
  postSuccess: (dispatch, getState, transformedData, res) => {
    var _orderTokenSelector2, _draftTokenSelector2, _userIdSelector;
    const currentToken = getHotProductsUserToken();
    if (currentToken !== res.newHotProductsUserToken) {
      setHotProductsToken(res.newHotProductsUserToken);
    }

    // Temporary solution, will be adjusted after moving send new hot product cycle to cron
    port.postMessage({
      type: SOCKET_EVENTS.RESET_CONNECTION
    });
    const state = getState();
    const orderToken = (_orderTokenSelector2 = orderTokenSelector(state)) !== null && _orderTokenSelector2 !== void 0 ? _orderTokenSelector2 : '';
    const draftToken = (_draftTokenSelector2 = draftTokenSelector(state)) !== null && _draftTokenSelector2 !== void 0 ? _draftTokenSelector2 : '';
    const userId = (_userIdSelector = userIdSelector(state)) !== null && _userIdSelector !== void 0 ? _userIdSelector : null;
    showHotProducts({
      dispatch,
      userId,
      orderToken,
      draftToken
    });
  }
});