import { createSelector } from 'reselect';
import { accumulatorCheck, getQuantityBySupplierInventoryLevels } from 'swag-common/utils/product/product.utils';
import { getLeftTime } from 'swag-common/utils/date-lib';
import { getLink } from 'swag-client-common/utils/product.utils';
export const itemSelector = (_, props) => props.item;
export const isAccumulatorSelector = createSelector(itemSelector, item => accumulatorCheck(item));
export const linkSelector = createSelector(itemSelector, item => getLink(item));
export const limitedEditionSelector = createSelector(itemSelector, ({
  limitedEdition
}) => limitedEdition !== null && limitedEdition !== void 0 ? limitedEdition : {
  isLimitedEdition: false,
  expirationDate: null,
  isInfinity: false
});
export const inStockSelector = createSelector(itemSelector, ({
  variants,
  supplierInventoryLevels
}) => {
  return getQuantityBySupplierInventoryLevels(variants, supplierInventoryLevels);
});
const isSomethingLeftInStockSelector = createSelector(itemSelector, inStockSelector, ({
  minQuant,
  supplierInventoryLevels
}, inStock) => {
  return (supplierInventoryLevels === null || supplierInventoryLevels === void 0 ? void 0 : supplierInventoryLevels.length) && inStock >= minQuant;
});
const isTimeStillRemainsSelector = createSelector(limitedEditionSelector, ({
  expirationDate
}) => getLeftTime(new Date(expirationDate || 0)) !== 0);
export const isOutOfStockSelector = createSelector(isSomethingLeftInStockSelector, limitedEditionSelector, isTimeStillRemainsSelector, (isSomethingLeftInStock, {
  isInfinity
}, isTimeStillRemains) => {
  return !isSomethingLeftInStock && !isInfinity || !isTimeStillRemains;
});
export const isLabelCenteredSelector = createSelector(isSomethingLeftInStockSelector, limitedEditionSelector, isTimeStillRemainsSelector, (isSomethingLeftInStock, {
  isInfinity
}, isTimeStillRemains) => {
  return isInfinity || !isTimeStillRemains || !isSomethingLeftInStock;
});
export const showInStockCountSelector = createSelector(isSomethingLeftInStockSelector, limitedEditionSelector, isTimeStillRemainsSelector, (isSomethingLeftInStock, {
  isInfinity
}, isTimeStillRemains) => {
  return !isInfinity && isTimeStillRemains && isSomethingLeftInStock;
});
export const hasProductSuperSpeedOptionSelector = createSelector(itemSelector, ({
  embellishmentMethodsData
}) => {
  return embellishmentMethodsData === null || embellishmentMethodsData === void 0 ? void 0 : embellishmentMethodsData.some(setting => setting.isSuperSpeed);
});
export const showLimitedEditionCountdownSelector = createSelector(limitedEditionSelector, ({
  isLimitedEdition,
  expirationDate
}) => {
  return isLimitedEdition && expirationDate;
});