function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import isFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { isLogoRequired } from 'swag-client-common/utils/order-item.utils';
export const containsDestinationConflictItems = items => {
  let containsSample = false;
  let containsRegularItemAvailableForInventory = false;
  let containsRegularItemForbiddenForInventory = false;
  items.forEach(({
    isSample,
    isPrintedSample,
    prod
  }) => {
    if (isSample || isPrintedSample) {
      containsSample = true;
    }
    if (!isSample && !isPrintedSample) {
      if (prod !== null && prod !== void 0 && prod.forbiddenForInventory) {
        containsRegularItemForbiddenForInventory = true;
      } else {
        containsRegularItemAvailableForInventory = true;
      }
    }
  });
  if (containsSample && containsRegularItemAvailableForInventory) {
    return true;
  }
  if (containsRegularItemAvailableForInventory && containsRegularItemForbiddenForInventory) {
    return true;
  }
  return false;
};
export const getItemsWithEmptyLogos = items => {
  const result = [];
  items === null || items === void 0 ? void 0 : items.forEach(item => {
    if (!item.logos || !isLogoRequired(item)) {
      return;
    }
    if (Object.keys(item.logos).length === 0) {
      result.push(item);
    }
  });
  return result;
};
export const getItemsWithRemovedPrintLocations = items => {
  const result = [];
  items.forEach(item => {
    if (!item.prod || !isLogoRequired(item)) {
      return;
    }
    const removedPrintLocations = getRemovedPrintLocations({
      product: item.prod,
      item
    });
    if (removedPrintLocations.length) {
      result.push(item);
    }
  });
  return result;
};
export const getRemovedPrintLocations = ({
  product,
  item
}) => {
  var _product$variants$;
  if (isFullDesign(product) || product.hidden) {
    return [];
  }
  const existPrintLocations = Array.isArray(product.variants) ? Object.keys(((_product$variants$ = product.variants[0]) === null || _product$variants$ === void 0 ? void 0 : _product$variants$.images) || {}) : [];
  const removedPrintLocations = [];
  for (const logo in item.logos) {
    if (!existPrintLocations.includes(logo)) {
      removedPrintLocations.push(logo);
    }
  }
  return removedPrintLocations;
};
export const removePrintLocationsFromLogo = (item, logoKeysToRemove) => {
  const newItemLogos = _objectSpread({}, item.logos);
  const newCustomerMockups = _objectSpread({}, item.customerMockups);
  for (const logo in item.logos) {
    if (logoKeysToRemove.includes(logo)) {
      delete newItemLogos[logo];
    }
  }
  const variantsIndex = [];
  for (const mockup in item.customerMockups) {
    var _item$customerMockups;
    if (logoKeysToRemove.includes((_item$customerMockups = item.customerMockups[mockup]) === null || _item$customerMockups === void 0 ? void 0 : _item$customerMockups.location)) {
      variantsIndex.push(item.customerMockups[mockup].variantIndex || -1);
      delete newCustomerMockups[mockup];
    }
  }
  for (const variant in item.variants) {
    if (variantsIndex.includes(item.variants[variant].variantIndex)) {
      item.variants[variant].variantIndex = 0;
    }
  }
  item.logos = newItemLogos;
  item.customerMockups = newCustomerMockups;
  return item;
};
export const findAndRemoveNotExistsPrintLocations = ({
  product,
  item
}) => {
  const removedPrintLocations = getRemovedPrintLocations({
    product,
    item
  });
  if (removedPrintLocations !== null && removedPrintLocations !== void 0 && removedPrintLocations.length) {
    removePrintLocationsFromLogo(item, removedPrintLocations);
  }
};