function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { browserHistory } from 'react-router';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { LOGIN_POPUP, SHOW_ERROR_POPUP, UNREGISTERED_USER_INVITE_ERROR_TYPE } from 'swag-client-common/constants';
import { showPopup, hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import AuthApi from 'swag-client-common/api/auth.api';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { inviteIdSelector, invitationMessageSelector } from './invitation.selectors';
const PREFIX = 'INVITATION_';
export const INVITATION_STARTED = `${PREFIX}INVITATION_STARTED`;
export const INVITATION_SUCCESS = `${PREFIX}INVITATION_SUCCESS`;
export const INVITATION_ERROR = `${PREFIX}INVITATION_ERROR`;
export const acceptInvitation = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: INVITATION_STARTED
    });
  },
  transformData: (data, getState) => {
    return _objectSpread({}, data, {
      inviteId: inviteIdSelector(getState())
    });
  },
  fn: AuthApi.acceptInventoryInvitation,
  success: () => ({
    type: INVITATION_SUCCESS
  }),
  error: error => ({
    type: INVITATION_ERROR,
    error: error
  }),
  postSuccess: dispatch => {
    dispatch(hidePopup());
    browserHistory.push(APP_ROUTES.HOME);
    dispatch(showNotification({
      text: 'Success! Now you can login!'
    }));
    dispatch(showPopup({
      popup: LOGIN_POPUP
    }));
  }
});
export const CHECK_INVITATION_STARTED = `${PREFIX}CHECK_INVITATION_STARTED`;
export const CHECK_INVITATION_SUCCESS = `${PREFIX}CHECK_INVITATION_SUCCESS`;
export const CHECK_INVITATION_ERROR = `${PREFIX}CHECK_INVITATION_ERROR`;
export const checkInvitation = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: CHECK_INVITATION_STARTED
    });
  },
  fn: AuthApi.checkInventoryInvitation,
  success: status => ({
    type: CHECK_INVITATION_SUCCESS,
    payload: status
  }),
  error: error => ({
    type: CHECK_INVITATION_ERROR,
    error: error
  }),
  postSuccess: (dispatch, getState) => {
    const inviteId = inviteIdSelector(getState());
    if (inviteId) {
      dispatch(showPopup({
        popup: 'invitationPopup'
      }));
    }
    const text = invitationMessageSelector(getState());
    if (text) {
      dispatch(showPopup({
        popup: SHOW_ERROR_POPUP,
        type: UNREGISTERED_USER_INVITE_ERROR_TYPE,
        errorMessage: text
      }));
    }
  },
  postError: (dispatch, getState, err) => {
    dispatch(showPopup({
      popup: SHOW_ERROR_POPUP,
      type: UNREGISTERED_USER_INVITE_ERROR_TYPE,
      errorMessage: err.message,
      onButtonClick: () => {
        browserHistory.push(APP_ROUTES.HOME);
        dispatch(hidePopup());
      }
    }));
  }
});