function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { autoSetCategory, resetFilter, resetProducts, setFilterVisibility, getProducts } from '../../../../../customer/redux/product-listing/product-listing.actions';
import { getUpdatedCategorySelector, highlightCategorySelector, setInitStateMobMenuSelector } from '../../../../../customer/redux/product-listing/product-listing.selectors';
import Category from './category';
const mapDispatchToProps = dispatch => ({
  onClearFilters: () => {
    dispatch(resetProducts());
    dispatch(resetFilter({
      redirectAllSwag: false,
      resetCategory: false
    }));
    dispatch(autoSetCategory());
    dispatch(getProducts());
    dispatch(setFilterVisibility(false));
  },
  gotoAllSwag: () => {
    dispatch(resetProducts());
    dispatch(resetFilter({
      redirectAllSwag: true
    }));
    dispatch(autoSetCategory());
    dispatch(getProducts());
    dispatch(setFilterVisibility(false));
  }
});
const mapStateToProps = createStructuredSelector({
  initState: (state, _ref) => {
    let {
        category: {
          subcategories
        }
      } = _ref,
      parentCategory = _objectWithoutProperties(_ref.category, ["subcategories"]);
    return setInitStateMobMenuSelector(state, parentCategory, subcategories);
  },
  isHighlightCategory: (state, {
    category
  }) => highlightCategorySelector(state, category),
  updatedCategory: (state, {
    category
  }) => getUpdatedCategorySelector(state, category)
});
export default connect(mapStateToProps, mapDispatchToProps)(Category);