function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import { isItemBoxWithoutSpecialTypesAndAddons, isItemBoxSpecialTypeOrAddon } from 'swag-common/utils/order/item-is-box.util';
// should collect cart items arr and arr of items _id which should be moved from box
// outside as standalone items and merged with remainder (if needed).
// expected result: return new cart items arr
export const moveBoxItemsFromBox = ({
  cartItems,
  itemsDeleteFromCartIds,
  newBoxItems
}) => {
  if (!itemsDeleteFromCartIds.length) {
    return cartItems;
  }
  const {
    shouldBeDeletedTooIds,
    shouldBeMergedWithReminders
  } = getIdsWitchShouldBeDeletedToo({
    cartItems,
    itemsDeleteFromCartIds,
    newBoxItems
  });
  const newCartItems = cartItems.reduce((newItems, cartItem) => {
    if (shouldBeDeletedTooIds.includes(String(cartItem._id))) {
      //remove item if whole box was deleted or deleted item was the last one
      return newItems;
    }
    if (!itemsDeleteFromCartIds.includes(String(cartItem._id)) && !shouldBeMergedWithReminders.includes(String(cartItem._id))) {
      //if item is not for deleting
      return isAlreadyInCheckedList({
        alreadyCheckedItems: newItems,
        cartItem
      }) ? newItems : [...newItems, cartItem];
    }
    if (isItemBoxSpecialTypeOrAddon(cartItem)) {
      //remove special type products
      return newItems;
    }
    if (isRegularBoxItem(cartItem)) {
      // remove regular box item and merge with remainder if needed
      if (isExistNonBoxReminderInCart({
        cartItems,
        cartItem
      }) || isExistNonBoxReminderInCart({
        cartItems: newItems,
        cartItem
      })) {
        return getCartItemsWithMergedItem({
          alreadyCheckedItems: newItems,
          cartItems,
          cartItem
        });
      }
      return [makeNonBoxItem(cartItem), ...newItems];
    }
    if (isBox(cartItem.prod)) {
      return newItems;
    }
    return newItems;
  }, []);
  return newCartItems;
};

// helpers ---------------------------------------------------------------

const isAlreadyInCheckedList = ({
  alreadyCheckedItems,
  cartItem
}) => {
  return !!alreadyCheckedItems.find(i => i._id === cartItem._id && i.prodId === cartItem.prodId && i.variants[0].variantIndex === cartItem.variants[0].variantIndex);
};
const isRegularBoxItem = item => {
  return !isBox(item.prod) && !!item.boxItemsId;
};
export const isExistNonBoxReminderInCart = ({
  cartItems,
  cartItem
}) => {
  return !!getNonBoxReminderInCart({
    cartItems,
    cartItem
  });
};
export const getNonBoxReminderInCart = ({
  alreadyHandledItems,
  cartItems,
  cartItem
}) => {
  const selectedCartItem = cartItems.find(item => checkIsItemReminder(item, cartItem));
  const alreadyHandledItem = alreadyHandledItems === null || alreadyHandledItems === void 0 ? void 0 : alreadyHandledItems.find(item => checkIsItemReminder(item, cartItem));
  return alreadyHandledItem || selectedCartItem || null;
};
const checkIsItemReminder = (item, cartItem) => {
  var _cartItem$prod;
  const {
    _id,
    asRelatedItemId,
    prod,
    boxItemsId
  } = item;
  return !boxItemsId && _id !== cartItem._id && (prod === null || prod === void 0 ? void 0 : prod._id) === ((_cartItem$prod = cartItem.prod) === null || _cartItem$prod === void 0 ? void 0 : _cartItem$prod._id) && asRelatedItemId === cartItem.asRelatedItemId;
};
const makeNonBoxItem = item => {
  const nonBoxItem = _objectSpread({}, item, {
    boxItemsId: null,
    quantityPerBox: null
  });
  if (item.customProductId) {
    delete nonBoxItem.customProductId;
    delete nonBoxItem.customProductCreated;
  }
  return nonBoxItem;
};
export const getCartItemsWithMergedItem = ({
  alreadyCheckedItems,
  cartItems,
  cartItem
}) => {
  const reminderItemInCheckedItems = getNonBoxReminderInCart({
    cartItems: alreadyCheckedItems,
    cartItem
  });
  if (reminderItemInCheckedItems) {
    return alreadyCheckedItems.map(item => {
      if (item._id === reminderItemInCheckedItems._id) {
        return mergeSharedItemToRemainder({
          remainderItem: reminderItemInCheckedItems,
          sharedItem: cartItem
        });
      }
      return item;
    });
  }
  const remainderItem = getNonBoxReminderInCart({
    cartItems,
    cartItem
  });
  if (!remainderItem) {
    return alreadyCheckedItems;
  }
  return [...alreadyCheckedItems, mergeSharedItemToRemainder({
    remainderItem,
    sharedItem: cartItem
  })];
};
const mergeSharedItemToRemainder = ({
  remainderItem,
  sharedItem
}) => _objectSpread({}, remainderItem, {
  customerMockups: _objectSpread({}, remainderItem.customerMockups, sharedItem.customerMockups),
  quantity: remainderItem.quantity + (sharedItem.quantity || 0),
  variants: mergeSharedItemsVariants({
    variantsAcceptor: remainderItem.variants,
    variantsDonor: sharedItem.variants
  })
});
const mergeSharedItemsVariants = ({
  variantsAcceptor,
  variantsDonor
}) => {
  return [...variantsAcceptor, ...variantsDonor].reduce((sharedVariants, swagItemVariant) => {
    const selectedVariant = sharedVariants.find(v => v.variantIndex === swagItemVariant.variantIndex);
    if (selectedVariant) {
      return getVariantsWithMergedVariantQty({
        variants: sharedVariants,
        variant: swagItemVariant
      });
    }
    return [...sharedVariants, swagItemVariant];
  }, []);
};
const getVariantsWithMergedVariantQty = ({
  variants,
  variant
}) => {
  return variants.map(swagItemVariant => {
    if (swagItemVariant.variantIndex === variant.variantIndex) {
      const {
        quantities,
        variantIndex
      } = swagItemVariant;
      return {
        variantIndex,
        quantities: quantities.map((perSizeQuantity, index) => perSizeQuantity + (variant.quantities[index] || 0))
      };
    }
    return swagItemVariant;
  });
};
const checkIsDeletedItemWasTheLastOne = ({
  cartItems,
  cartItem
}) => {
  const boxItemsId = cartItem.boxItemsId;
  if (!boxItemsId) {
    return false;
  }
  const boxItemsEntities = cartItems.filter(i => i.boxItemsId === boxItemsId && isRegularBoxItem(i) && isItemBoxWithoutSpecialTypesAndAddons(i));
  return boxItemsEntities.length === 1;
};
const getIdsWitchShouldBeDeletedToo = ({
  cartItems,
  itemsDeleteFromCartIds,
  newBoxItems
}) => {
  const shouldBeDeletedTooIds = [];
  const shouldBeMergedWithReminders = [];
  itemsDeleteFromCartIds.forEach(id => {
    const cartItem = cartItems.find(i => i._id === id);
    if (cartItem && isItemBoxWithoutSpecialTypesAndAddons(cartItem)) {
      const boxItemsId = cartItem.boxItemsId;
      const isDeletedItemWasBoxItself = isBox(cartItem.prod);
      const isDeletedItemWasTheLastOne = checkIsDeletedItemWasTheLastOne({
        cartItems: [...cartItems, ...newBoxItems],
        cartItem
      });
      if (isDeletedItemWasBoxItself) {
        cartItems.forEach(i => {
          if (i.boxItemsId === boxItemsId && i._id !== cartItem._id) {
            if (isItemBoxSpecialTypeOrAddon(i)) {
              shouldBeDeletedTooIds.push(i._id);
            } else {
              shouldBeMergedWithReminders.push(i._id);
            }
          }
        });
      } else if (isDeletedItemWasTheLastOne) {
        cartItems.forEach(i => {
          if (i.boxItemsId === boxItemsId && i._id !== cartItem._id) {
            shouldBeDeletedTooIds.push(i._id);
          }
        });
      }
    }
  });
  return {
    shouldBeDeletedTooIds,
    shouldBeMergedWithReminders
  };
};