import _flow from "lodash/flow";
import _isEmpty2 from "lodash/isEmpty";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { checkIsApparel, getItemOutOfStockMinExpiredDate } from 'swag-client-common/utils/product.utils';
import { _apparelDifferentSizeSet, _apparelIncorrectSizeQuantity, _apparelQuantityBiggerThanBoxQuantity, _boxQuantityLessThanMinQuant, _getGeneralSizeSet, _isBoxWithLEOrderMore, _isHidden, _isItemLogosEmpty, _isLETotalNotAvailable, _isNotAllowedInBox, _isOutOfStock, _isSampleProductSelectedSizeIsOutOfStock, _isSampleProductSizesIsAllOutOfStock, _isSomeItemPrintLocationRemoved, _LEMoreThanAvailableInStockSingleVariant, _LEMulticolorPerSkuError, _LEMultipleItemsSumError, _mismatchesRequiredQuantity, _moreThanAvailableInStockSingleVariant, _perBox, _quantityDivisibleByQuantityIncrement, _quantityEqualOrMoreThanMax, _quantityOfAllSharedAndRelatedItemsLessThanMinQuant, _quantityOfAllSharedAndRelatedSuperSpeedItemsLessThanMinQuant, _quantityPerBoxIsZero, _someColorsOrSizesMoreThanAvailable, _someColorsOutOfStock, _totalProductQuantityMoreThanAvailableInCart, _totalProductQuantityPerSizeMoreThanAvailableInCart, _transformToObject, getBoxVariantSku, getFirstVariantSku, getShortestSizeSet, ValidationPlaceMode } from 'swag-client-common/utils/validations/rules';
import { isOrderMore } from 'swag-common/business-logic/order/is-order-more-item.logic';
import { MAX_QUANTITY_TO_BUY } from 'swag-common/constants/product.constants';
import { BOX_MESSAGES, STANDALONE_ITEM_MESSAGES } from 'swag-common/constants/validation-messages.constants';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import { isSuperSpeedItem } from 'swag-common/utils/items/is-item-super-speed.util';
import { getItemQuantityPerBox } from 'swag-common/utils/product/product.utils';
export { ValidationPlaceMode } from 'swag-client-common/utils/validations/rules';
export const getProductKey = (id, position) => {
  return `${id}:${position}`;
};
const _validationMessagesForStandaloneItemsReducer = ({
  stockLevelsByProductIdAndVariantSku,
  ignoreThreshold,
  supplierInventoryLevels,
  items
}) => (result, item) => {
  var _stockLevelsByProduct, _item$prod, _item$prod$productSet, _item$prod$productSet2, _item$prod$productSet3;
  if (item.boxItemsId) {
    return result;
  }
  const inStock = stockLevelsByProductIdAndVariantSku === null || stockLevelsByProductIdAndVariantSku === void 0 ? void 0 : (_stockLevelsByProduct = stockLevelsByProductIdAndVariantSku[item.prod._id]) === null || _stockLevelsByProduct === void 0 ? void 0 : _stockLevelsByProduct[getFirstVariantSku(item)];
  if (ignoreThreshold) {
    item.prod.outOfStockThreshold = 0;
  }
  const errorsPerSize = _someColorsOrSizesMoreThanAvailable(item, stockLevelsByProductIdAndVariantSku, items);
  const unsatisfiedRequiredQuantities = _mismatchesRequiredQuantity(item);
  const isSuperSpeed = isSuperSpeedItem(item);
  const maxQuant = isSuperSpeed && (_item$prod = item.prod) !== null && _item$prod !== void 0 && (_item$prod$productSet = _item$prod.productSettings) !== null && _item$prod$productSet !== void 0 && (_item$prod$productSet2 = _item$prod$productSet[0]) !== null && _item$prod$productSet2 !== void 0 && (_item$prod$productSet3 = _item$prod$productSet2.superSpeed) !== null && _item$prod$productSet3 !== void 0 && _item$prod$productSet3.maxQuant ? item.prod.productSettings[0].superSpeed.maxQuant : MAX_QUANTITY_TO_BUY;
  const key = String(item._id);
  switch (true) {
    case unsatisfiedRequiredQuantities !== false:
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.REQUIRED_QUANTITY.getProductMsg(unsatisfiedRequiredQuantities),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false
        };
        break;
      }
    case _quantityEqualOrMoreThanMax(maxQuant, item, items):
      {
        result[key] = {
          message: BOX_MESSAGES.QUANTITY.getProductMaxQntyMsg(maxQuant),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isMaxQuant: true
        };
        break;
      }
    case _quantityOfAllSharedAndRelatedItemsLessThanMinQuant(item, items):
      {
        result[key] = {
          message: BOX_MESSAGES.MIN_QUANTITY.getProductMsg(item.prod.minQuant),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isMinQuant: true
        };
        break;
      }
    case _isSampleProductSizesIsAllOutOfStock(item, stockLevelsByProductIdAndVariantSku):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getProductOutOfStockMsg(),
          isOutOfStock: true,
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false
        };
        break;
      }
    case _isSampleProductSelectedSizeIsOutOfStock(item, stockLevelsByProductIdAndVariantSku):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getSelectedSizeOfSimpleProdIsOutOfStockMsg(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false
        };
        break;
      }
    case _someColorsOutOfStock(item, stockLevelsByProductIdAndVariantSku, items):
      {
        var _item$prod2, _item$prod2$limitedEd;
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getSomeSkusOutOfStockMsg(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isLE: !!((_item$prod2 = item.prod) !== null && _item$prod2 !== void 0 && (_item$prod2$limitedEd = _item$prod2.limitedEdition) !== null && _item$prod2$limitedEd !== void 0 && _item$prod2$limitedEd.isLimitedEdition)
        };
        break;
      }
    case _isHidden(item):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getProductHiddenMsg(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isOutOfStock: true
        };
        break;
      }
    case _isOutOfStock(item, stockLevelsByProductIdAndVariantSku):
      {
        const minOutOfStockExpireDate = getItemOutOfStockMinExpiredDate(supplierInventoryLevels, item);
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getProductOutOfStockMsg(minOutOfStockExpireDate || null),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isOutOfStock: true
        };
        break;
      }
    case _LEMultipleItemsSumError({
      item,
      items,
      lvls: supplierInventoryLevels
    }):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getSomeSkusOutOfStockMsg(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isLEExceed: true
        };
        break;
      }
    case _LEMoreThanAvailableInStockSingleVariant(item, items, inStock):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getProductMaxByStockLevelMsg(inStock),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isLEExceed: true
        };
        break;
      }
    case _isLETotalNotAvailable(item, stockLevelsByProductIdAndVariantSku !== null && stockLevelsByProductIdAndVariantSku !== void 0 ? stockLevelsByProductIdAndVariantSku : {}):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getLEProductNA(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isOutOfStock: false,
          isLEOOS: true
        };
        break;
      }
    case !_isEmpty2(errorsPerSize):
      {
        var _item$prod3, _item$prod3$limitedEd;
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getSomeSkusOutOfStockMsg(),
          editingQuantityErrorMessage: BOX_MESSAGES.QUANTITY.getSomeSizesAreMoreThanAvailable(),
          quantity: item.quantity,
          errorsPerSize,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isLE: !!((_item$prod3 = item.prod) !== null && _item$prod3 !== void 0 && (_item$prod3$limitedEd = _item$prod3.limitedEdition) !== null && _item$prod3$limitedEd !== void 0 && _item$prod3$limitedEd.isLimitedEdition)
        };
        break;
      }
    case _moreThanAvailableInStockSingleVariant(item, inStock, items):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getProductMaxByStockLevelMsg(inStock),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false
        };
        break;
      }
    case _quantityDivisibleByQuantityIncrement(item):
      {
        result[key] = {
          message: BOX_MESSAGES.QUANTITY_NOT_DIVISIBLE_BY_QUANTITY_INCREMENT.getProductMsg(item),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false
        };
        break;
      }
    case _LEMulticolorPerSkuError({
      item,
      lvls: supplierInventoryLevels !== null && supplierInventoryLevels !== void 0 ? supplierInventoryLevels : []
    }):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getSomeSkusOutOfStockMsg(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isLEExceed: true
        };
        break;
      }
    case _quantityOfAllSharedAndRelatedSuperSpeedItemsLessThanMinQuant(item, items):
      {
        var _item$prod$productSet4, _item$prod4, _item$prod4$productSe, _item$prod4$productSe2, _item$prod4$productSe3;
        result[key] = {
          message: BOX_MESSAGES.MIN_QUANTITY.getProductMsg((_item$prod$productSet4 = (_item$prod4 = item.prod) === null || _item$prod4 === void 0 ? void 0 : (_item$prod4$productSe = _item$prod4.productSettings) === null || _item$prod4$productSe === void 0 ? void 0 : (_item$prod4$productSe2 = _item$prod4$productSe[0]) === null || _item$prod4$productSe2 === void 0 ? void 0 : (_item$prod4$productSe3 = _item$prod4$productSe2.superSpeed) === null || _item$prod4$productSe3 === void 0 ? void 0 : _item$prod4$productSe3.minQuant) !== null && _item$prod$productSet4 !== void 0 ? _item$prod$productSet4 : 0),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isMinQuant: true
        };
        break;
      }
    case _isSomeItemPrintLocationRemoved(item):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.REQUIRED_PARAMS.getItemPrintLocationRemovedMsg(),
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isSomeItemPrintLocationRemoved: true
        };
        break;
      }
    case _isItemLogosEmpty(item):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.REQUIRED_PARAMS.getItemLogosEmptyMsg(),
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isItemLogosEmpty: true
        };
        break;
      }
    default:
      {
        break;
      }
  }
  return result;
};
function getKey({
  customKeyGenerator,
  _index,
  isIndexAdded,
  item
}) {
  if (customKeyGenerator) {
    return customKeyGenerator(item);
  }
  return isIndexAdded ? getProductKey(String(item._id), _index) : String(item._id);
}
function validateBox(boxes) {
  const result = {};
  boxes.forEach(box => {
    switch (true) {
      case _quantityEqualOrMoreThanMax(MAX_QUANTITY_TO_BUY, box):
        {
          result[box._id] = {
            message: BOX_MESSAGES.QUANTITY.getBoxMaxQntyMsg(),
            quantity: box.quantity,
            colors: {
              message: 'red',
              quantity: 'red'
            },
            isSuccessed: false,
            isMaxQuant: true,
            boxItemsId: box.boxItemsId
          };
          break;
        }
      case _boxQuantityLessThanMinQuant(box):
        {
          result[box._id] = {
            message: BOX_MESSAGES.MIN_QUANTITY.getProductMsg(box.prod.minQuant),
            quantity: box.quantity,
            colors: {
              message: 'red',
              quantity: 'red'
            },
            isSuccessed: false,
            boxItemsId: box.boxItemsId
          };
          break;
        }
      // eslint-disable-next-line no-empty
      default:
        {}
    }
  });
  return result;
}
const _validationMessagesReducer = ({
  boxes,
  isIndexAdded,
  stockLevelsByProductIdAndVariantSku,
  supplierInventoryLevels,
  ignoreThreshold = false,
  customKeyGenerator,
  items,
  mode
}) => (result, item, _index) => {
  var _stockLevelsByProduct2, _item$prod5, _item$prod5$productSe, _item$prod5$productSe2, _item$prod5$productSe3;
  const box = boxes.find(b => Boolean(item.boxItemsId) && Boolean(b.boxItemsId) && b.boxItemsId === item.boxItemsId);
  if (!box || isBox(item.prod)) {
    return result;
  }
  const {
    boxItemsId
  } = box;
  const itemsRelatedToBox = items.filter(i => !isBox(i) && i.boxItemsId === boxItemsId);
  const shortestSizeSet = getShortestSizeSet(itemsRelatedToBox) || [];
  const quantityPerBox = getItemQuantityPerBox(item, item.quantity, box);
  const variantBoxIndex = getBoxVariantSku(item) || 0;
  const inStock = stockLevelsByProductIdAndVariantSku === null || stockLevelsByProductIdAndVariantSku === void 0 ? void 0 : (_stockLevelsByProduct2 = stockLevelsByProductIdAndVariantSku[item.prod._id]) === null || _stockLevelsByProduct2 === void 0 ? void 0 : _stockLevelsByProduct2[variantBoxIndex];
  if (ignoreThreshold) {
    item.prod.outOfStockThreshold = 0;
  }
  const key = getKey({
    customKeyGenerator,
    item,
    _index,
    isIndexAdded
  });
  const errorsPerSize = _someColorsOrSizesMoreThanAvailable(item, stockLevelsByProductIdAndVariantSku || {}, items);
  const differentSizeSet = _apparelDifferentSizeSet(item, shortestSizeSet);
  const apparelIncorrectSizeQuantity = _apparelIncorrectSizeQuantity(item, itemsRelatedToBox, box);
  const isSuperSpeed = isSuperSpeedItem(item);
  const maxQuant = isSuperSpeed && (_item$prod5 = item.prod) !== null && _item$prod5 !== void 0 && (_item$prod5$productSe = _item$prod5.productSettings) !== null && _item$prod5$productSe !== void 0 && (_item$prod5$productSe2 = _item$prod5$productSe[0]) !== null && _item$prod5$productSe2 !== void 0 && (_item$prod5$productSe3 = _item$prod5$productSe2.superSpeed) !== null && _item$prod5$productSe3 !== void 0 && _item$prod5$productSe3.maxQuant ? item.prod.productSettings[0].superSpeed.maxQuant : MAX_QUANTITY_TO_BUY;
  switch (true) {
    case _isBoxWithLEOrderMore(item, mode):
      {
        result[key] = {
          message: BOX_MESSAGES.FORBIDDEN.getLEProductOrderMoreNA(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          boxItemsId
        };
        break;
      }
    case _quantityEqualOrMoreThanMax(maxQuant, item, items):
      {
        result[key] = {
          message: BOX_MESSAGES.QUANTITY.getProductMaxQntyMsg(maxQuant),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isMaxQuant: true,
          boxItemsId
        };
        break;
      }
    case _quantityPerBoxIsZero(item):
      {
        result[key] = {
          message: BOX_MESSAGES.QUANTITY.quantityPerBoxIsZero(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          boxItemsId
        };
        break;
      }
    case _LEMultipleItemsSumError({
      item,
      items,
      lvls: supplierInventoryLevels !== null && supplierInventoryLevels !== void 0 ? supplierInventoryLevels : []
    }):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getSomeSkusOutOfStockMsg(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isLEExceed: true
        };
        break;
      }
    case _LEMoreThanAvailableInStockSingleVariant(item, items, inStock):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getProductMaxByStockLevelMsg(inStock),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isLEExceed: true
        };
        break;
      }
    case _isLETotalNotAvailable(item, stockLevelsByProductIdAndVariantSku !== null && stockLevelsByProductIdAndVariantSku !== void 0 ? stockLevelsByProductIdAndVariantSku : {}):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getLEProductNA(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isOutOfStock: false,
          isLEOOS: true
        };
        break;
      }
    case _isOutOfStock(item, stockLevelsByProductIdAndVariantSku || {}):
      {
        const minOutOfStockExpireDate = getItemOutOfStockMinExpiredDate(supplierInventoryLevels || [], item);
        result[key] = {
          message: BOX_MESSAGES.QUANTITY.getBoxProductOutOfStockMsg(isOrderMore(item) || Boolean(item.isOrderMore), minOutOfStockExpireDate || null),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isOutOfStock: true,
          boxItemsId
        };
        break;
      }
    case _isHidden(item):
      {
        result[key] = {
          message: BOX_MESSAGES.QUANTITY.getBoxProductHiddenMsg(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isOutOfStock: true,
          boxItemsId
        };
        break;
      }
    case !_isEmpty2(errorsPerSize):
      {
        var _item$prod6, _item$prod6$limitedEd;
        result[key] = {
          message: BOX_MESSAGES.QUANTITY.getSomeSkusOutOfStockMsg(),
          editingQuantityErrorMessage: BOX_MESSAGES.QUANTITY.getSomeSizesAreMoreThanAvailable(),
          quantity: item.quantity,
          errorsPerSize,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          boxItemsId,
          isLE: !!((_item$prod6 = item.prod) !== null && _item$prod6 !== void 0 && (_item$prod6$limitedEd = _item$prod6.limitedEdition) !== null && _item$prod6$limitedEd !== void 0 && _item$prod6$limitedEd.isLimitedEdition)
        };
        break;
      }
    case _isNotAllowedInBox(item):
      {
        result[key] = {
          message: BOX_MESSAGES.FORBIDDEN.getProductNA(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isNotAllowedInBox: true,
          boxItemsId
        };
        break;
      }
    case _moreThanAvailableInStockSingleVariant(item, inStock, items):
      {
        result[key] = {
          message: BOX_MESSAGES.QUANTITY.getProductMaxByStockLevelMsg(inStock),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          boxItemsId
        };
        break;
      }
    case differentSizeSet.length > 0:
      {
        result[key] = {
          message: BOX_MESSAGES.APPAREL_SIZE.differentSizeSet(differentSizeSet),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          boxItemsId
        };
        break;
      }
    case _quantityOfAllSharedAndRelatedItemsLessThanMinQuant(item, items):
      {
        result[key] = {
          message: BOX_MESSAGES.MIN_QUANTITY.getProductMsg(item.prod.minQuant),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isMinQuant: true,
          boxItemsId
        };
        break;
      }
    case _quantityDivisibleByQuantityIncrement(item):
      {
        result[key] = {
          message: BOX_MESSAGES.QUANTITY_NOT_DIVISIBLE_BY_QUANTITY_INCREMENT.getProductMsg(item),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          boxItemsId
        };
        break;
      }
    case _apparelQuantityBiggerThanBoxQuantity(item, box):
      {
        result[key] = {
          message: BOX_MESSAGES.APPAREL_QUANTITY_RELATED_TO_BOX.getProductMsg(item, box),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          boxItemsId
        };
        break;
      }
    case checkIsApparel({
      designUploadMode: item.prod.designUploadMode,
      hasSize: item.prod.hasSize,
      sizeDependentPrice: item.prod.sizeDependentPrice
    }) && apparelIncorrectSizeQuantity.length > 0:
      {
        const message = apparelIncorrectSizeQuantity.join(', ');
        result[key] = {
          message: BOX_MESSAGES.DIVIDABLE_APPAREL_QUANTITY_PER_SIZE.getProductMsg(message),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          boxItemsId
        };
        break;
      }
    case _totalProductQuantityPerSizeMoreThanAvailableInCart(item):
      {
        result[key] = {
          message: BOX_MESSAGES.QUANTITY.totalProductPerSizeMoreThanAvailableInCart(item),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          boxItemsId,
          isMoreThanAvailableInCart: true
        };
        break;
      }
    case _totalProductQuantityMoreThanAvailableInCart(item):
      {
        result[key] = {
          message: BOX_MESSAGES.QUANTITY.totalProductMoreThanAvailableInCart(item),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          boxItemsId,
          isMoreThanAvailableInCart: true
        };
        break;
      }
    case _perBox(item, box) && mode === ValidationPlaceMode.orderMore:
      {
        result[key] = {
          message: BOX_MESSAGES.QUANTITY_PER_BOX.getProductMsg(item, box),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          boxItemsId
        };
        break;
      }
    case _LEMulticolorPerSkuError({
      item,
      lvls: supplierInventoryLevels !== null && supplierInventoryLevels !== void 0 ? supplierInventoryLevels : []
    }):
      {
        result[key] = {
          message: STANDALONE_ITEM_MESSAGES.QUANTITY.getSomeSkusOutOfStockMsg(),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isLEExceed: true
        };
        break;
      }
    case _quantityOfAllSharedAndRelatedSuperSpeedItemsLessThanMinQuant(item, items):
      {
        var _item$prod$productSet5, _item$prod7, _item$prod7$productSe, _item$prod7$productSe2, _item$prod7$productSe3;
        result[key] = {
          message: BOX_MESSAGES.MIN_QUANTITY.getProductMsg((_item$prod$productSet5 = (_item$prod7 = item.prod) === null || _item$prod7 === void 0 ? void 0 : (_item$prod7$productSe = _item$prod7.productSettings) === null || _item$prod7$productSe === void 0 ? void 0 : (_item$prod7$productSe2 = _item$prod7$productSe[0]) === null || _item$prod7$productSe2 === void 0 ? void 0 : (_item$prod7$productSe3 = _item$prod7$productSe2.superSpeed) === null || _item$prod7$productSe3 === void 0 ? void 0 : _item$prod7$productSe3.minQuant) !== null && _item$prod$productSet5 !== void 0 ? _item$prod$productSet5 : 0),
          quantity: item.quantity,
          colors: {
            message: 'red',
            quantity: 'red'
          },
          isSuccessed: false,
          isMinQuant: true,
          boxItemsId
        };
        break;
      }
    default:
      {
        result[key] = {
          message: BOX_MESSAGES.SUCCESS.getProductMsg(false, quantityPerBox),
          quantity: item.quantity,
          colors: {
            message: 'green',
            quantity: 'black'
          },
          isSuccessed: true,
          boxItemsId
        };
        break;
      }
  }
  return result;
};
export const getApparelSizeExtraMessage = BOX_MESSAGES.APPAREL_SIZE.getProductAdjustMsg;
export const getSizeSetsInBox = items => {
  const apparelItems = items.filter(item => checkIsApparel(item.prod));
  return _getGeneralSizeSet(apparelItems);
};
export const getBasicSizeSet = _flow([getSizeSetsInBox, _transformToObject]);
export const getValidationMessagesForStandaloneItems = ({
  items,
  stockLevelsByProductIdAndVariantSku,
  supplierInventoryLevels,
  ignoreThreshold
}) => {
  if (_isEmpty2(items)) {
    return {};
  }
  return items.reduce(_validationMessagesForStandaloneItemsReducer({
    stockLevelsByProductIdAndVariantSku,
    ignoreThreshold,
    supplierInventoryLevels,
    items
  }), {});
};
export const getValidationMessages = ({
  boxes,
  items,
  isIndexAdded,
  stockLevelsByProductIdAndVariantSku,
  supplierInventoryLevels,
  ignoreThreshold,
  customKeyGenerator,
  mode
}) => {
  if (!boxes || !boxes.length) {
    return {};
  }
  const boxItselfValidations = validateBox(boxes);
  if (_isEmpty2(items)) {
    return boxItselfValidations;
  }
  const itemsValidations = items.reduce(_validationMessagesReducer({
    boxes,
    items,
    isIndexAdded,
    stockLevelsByProductIdAndVariantSku,
    supplierInventoryLevels,
    ignoreThreshold,
    customKeyGenerator,
    mode
  }), {});
  return _objectSpread({}, boxItselfValidations, itemsValidations);
};