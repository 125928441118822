function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Price as PriceCommon } from 'swag-common/utils/price';
import { getBundleTypeBasedOnResellerSettings } from 'swag-client-common/utils/reseller/get-bundle-type-based-on-reseller-settings.util';
export class Price extends PriceCommon {
  static getPrice(params) {
    let price = 0;
    try {
      const bundleType = getBundleTypeBasedOnResellerSettings();
      price = super.getPrice(_objectSpread({}, params, {
        bundleType
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return price;
  }
  static calculateEdgePrice(params) {
    const bundleType = getBundleTypeBasedOnResellerSettings();
    const edge = super.calculateEdgePrice(_objectSpread({}, params, {
      bundleType
    }));
    return edge;
  }
  static calculateDefaultEdgePrice({
    product,
    featureFlags
  }) {
    const bundleType = getBundleTypeBasedOnResellerSettings();
    return super.calculateDefaultEdgePrice({
      product,
      featureFlags,
      bundleType
    });
  }
  static getOrderItemsBasePrices(params) {
    let items = params.items;
    try {
      const bundleType = getBundleTypeBasedOnResellerSettings();
      items = super.getOrderItemsBasePrices(_objectSpread({}, params, {
        bundleType
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return items;
  }
}