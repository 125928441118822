import { connect } from 'react-redux';
import { activeFiltersSelector } from '../../../../../../redux/product-listing/product-listing.selectors';
import { activeFiltersDispatcher } from '../../../../../../redux/product-listing/product-listing.dispatchers';
import { activeFiltersSelector as searchPageActiveFiltersSelector } from '../../../../../../redux/search-results/search-results.selectors';
import { activeFiltersDispatcher as searchPageActiveFiltersDispatcher } from '../../../../../../redux/search-results/search-results.dispatchers';
import FiltersRowPanel from './filters-row-panel';
import MobileFiltersRowPanel from './mobile-filters-row-panel';
export const ProductListingActiveFiltersRow = connect(activeFiltersSelector, activeFiltersDispatcher)(FiltersRowPanel);
export const SearchResultsActiveFiltersRow = connect(searchPageActiveFiltersSelector, searchPageActiveFiltersDispatcher)(FiltersRowPanel);
export const MobileProductListingActiveFiltersRow = connect(activeFiltersSelector, activeFiltersDispatcher)(MobileFiltersRowPanel);
export const MobileSearchProductListingActiveFiltersRow = connect(searchPageActiveFiltersSelector, searchPageActiveFiltersDispatcher)(MobileFiltersRowPanel);