function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { INVENTORY_PERMISSION_LEVELS } from '../../constants/inventory';
import { getHighestDemoInventoryRole } from '../../utils/inventory/get-heighest-demo-inventory-role.util';
export function extendDemoInventoryWithRoles(demoInventory, userInventories, userId) {
  const role = getHighestDemoInventoryRole(userInventories, userId);
  switch (role) {
    case INVENTORY_PERMISSION_LEVELS.OWNER:
      {
        return _objectSpread({}, demoInventory, {
          ownerId: userId,
          approvalSettings: []
        });
      }
    case INVENTORY_PERMISSION_LEVELS.ADMIN:
      {
        return _objectSpread({}, demoInventory, {
          adminsIds: [userId],
          approvalSettings: []
        });
      }
    case INVENTORY_PERMISSION_LEVELS.PULL:
      {
        return _objectSpread({}, demoInventory, {
          canTakeUserIds: [userId],
          approvalSettings: []
        });
      }
    case INVENTORY_PERMISSION_LEVELS.PUSH:
      {
        return _objectSpread({}, demoInventory, {
          canPutUserIds: [userId],
          approvalSettings: []
        });
      }
    case INVENTORY_PERMISSION_LEVELS.PUSH_AND_PULL:
      {
        return _objectSpread({}, demoInventory, {
          canPutUserIds: [userId],
          canTakeUserIds: [userId],
          approvalSettings: []
        });
      }
    default:
      {
        return _objectSpread({}, demoInventory, {
          approvalSettings: []
        });
      }
  }
}