function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { SUBMIT_STARTED, SUBMIT_ERROR, SUBMIT_SUCCESS, FORM_STATUS_RESET } from './custom-swag.actions';
const initialState = {
  error: undefined,
  formState: 'idle'
};
export default function customSwagReducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_STARTED:
      {
        return _objectSpread({}, state, {
          formState: 'loading'
        });
      }
    case SUBMIT_ERROR:
      {
        return _objectSpread({}, state, {
          formState: 'error'
        });
      }
    case SUBMIT_SUCCESS:
      {
        return _objectSpread({}, state, {
          formState: 'success',
          error: ''
        });
      }
    case FORM_STATUS_RESET:
      {
        return _objectSpread({}, initialState);
      }
    default:
      return state;
  }
}