import { isCancelledItem, isDeliveredToWarehouseItem, isStatedItem } from 'swag-common/utils/order';
import { DesignUploadMode } from '../../interfaces';
import * as ITEM_STATUSES from '../../constants/item-statuses.constants';
export const checkIsLastItemsShipped = (items, currentItemId) => {
  const otherItems = items.filter(item => String(item._id) !== String(currentItemId) && isStatedItem(item) && item.status !== ITEM_STATUSES.PENDING_APPROVAL);
  return otherItems.length ? otherItems.every(item => {
    var _item$status;
    return (_item$status = item.status) !== null && _item$status !== void 0 ? _item$status : 0 >= ITEM_STATUSES.SHIPPED;
  }) : true;
};
const getAllValidBoxItems = boxItemsId => item => boxItemsId === item.boxItemsId && !isCancelledItem(item);
const getValidBoxItems = (currentItemId, boxItemsId) => item => String(item._id) !== String(currentItemId) && getAllValidBoxItems(boxItemsId)(item);
export const isEveryBoxItemDeliveredToWarehouse = (items, {
  _id,
  boxItemsId
}) => {
  const filteredItems = items.filter(getValidBoxItems(_id, boxItemsId));
  if (!filteredItems.length) {
    return false;
  }
  return filteredItems.every(isDeliveredToWarehouseItem);
};
export const getItemsToReviewPantoneColors = items => {
  const itemIdsToReview = items.reduce((acc, item) => {
    var _item$prod;
    if (((_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.designUploadMode) === DesignUploadMode.logo && !item.isSample && !item.isCustomizedBlank) {
      acc.push({
        itemId: String(item._id),
        orderId: String(item.orderId)
      });
    }
    return acc;
  }, []);
  return itemIdsToReview;
};