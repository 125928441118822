import _sumBy from "lodash/sumBy";
import _get from "lodash/get";
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { isGiveawayNotecard } from 'swag-common/utils/order';
import { DesignUploadMode } from '../../interfaces';
export const isBox = item => {
  var _item$prod;
  return Boolean(item) && Boolean(item === null || item === void 0 ? void 0 : item.boxItemsId) && (item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.designUploadMode) === DesignUploadMode.box;
};
export const getFullBox = (item, items) => {
  return items.filter(i => i.boxItemsId && item.boxItemsId && item.boxItemsId === i.boxItemsId);
};
export const isItemInABox = (boxItems, item) => {
  return boxItems.length > 1 && !isBox(item);
};
export const isProductRelatedToBox = (item, items) => {
  const boxItems = getFullBox(item, items);
  return isItemInABox(boxItems, item);
};
export const checkIsItemToInventory = item => {
  return Boolean(item.isReorder && item.customProductId);
};
export const isItemNotInBox = item => {
  return !Object.prototype.hasOwnProperty.call(item, 'boxItemId') && !Object.prototype.hasOwnProperty.call(item, 'quantityPerBox');
};
export const isItemBrownBox = item => {
  return _get(item, 'prod.specialProductType') === SPECIAL_PRODUCT_TYPES.BROWN_BOX;
};
export const isItemBoxSpecialNotecard = item => {
  return _get(item, 'prod.specialProductType') === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD;
};
export const isItemBoxSpecialCrinklePaper = item => {
  return _get(item, 'prod.specialProductType') === SPECIAL_PRODUCT_TYPES.BOX_CRINKLE_PAPER;
};
export const isItemBoxSpecialTape = item => {
  return _get(item, 'prod.specialProductType') === SPECIAL_PRODUCT_TYPES.BOX_TAPE;
};
export const isItemGiveawayNotecard = item => {
  if (!item.prod) {
    return false;
  }
  return isGiveawayNotecard(item.prod);
};
export const isItemBoxAddon = item => _get(item, 'prod.specialProductType') === SPECIAL_PRODUCT_TYPES.ADD_ON_FOR_BOX;
export const isItemBoxSpecialProduct = item => {
  return isItemBoxSpecialNotecard(item) || isItemBoxSpecialCrinklePaper(item) || isItemBoxAddon(item) || isItemBoxSpecialTape(item);
};
export const isItemBoxWithoutSpecialTypes = item => {
  return !isItemBoxSpecialCrinklePaper(item) && !isItemBoxSpecialNotecard(item) && !isItemBoxSpecialTape(item) && !isItemBoxAddon(item);
};
export const isBoxSuperSpeedType = ({
  specialBoxProducts,
  type
}) => {
  var _specialBoxProducts$f;
  return Boolean((_specialBoxProducts$f = specialBoxProducts.find(prod => prod.specialProductType === type)) === null || _specialBoxProducts$f === void 0 ? void 0 : _specialBoxProducts$f.isBoxForSuperSpeed);
};
export const isItemNotBoxAndWithoutSpecialTypes = item => {
  return !isBox(item) && isItemBoxWithoutSpecialTypes(item);
};
export const isItemBoxWithoutSpecialTypesAndAddons = item => isItemBoxWithoutSpecialTypes(item) && !isItemBoxAddon(item);
export const isItemBoxSpecialTypeOrAddon = item => isItemBoxAddon(item) || isItemBoxSpecialCrinklePaper(item) || isItemBoxSpecialTape(item) || isItemBoxSpecialNotecard(item);
export const getBoxItself = (item, items) => {
  return getFullBox(item, items).find(isBox);
};
export const getBoxItems = (item, items) => {
  return getFullBox(item, items).filter(item => !isBox(item));
};
export const isForbiddenToChangeQuantityPerBox = item => {
  return isItemBoxSpecialNotecard(item) || isItemBoxSpecialCrinklePaper(item);
};
export const getTotalQuantityForSharedItems = (currentItem, items) => {
  return _sumBy(items, item => {
    if (currentItem.asRelatedItemId && item.asRelatedItemId === currentItem.asRelatedItemId && currentItem.prodId === item.prodId) {
      return String(currentItem._id) !== String(item._id) ? item.quantity : currentItem.quantity;
    }
    return 0;
  });
};
export const isItemsInTheSameBox = ({
  currentItem,
  item
}) => {
  return (currentItem === null || currentItem === void 0 ? void 0 : currentItem.boxItemsId) && currentItem.boxItemsId === item.boxItemsId;
};
export const getSharedItemsQuantityPerBox = (item, boxContent) => boxContent.reduce((acc, boxItem) => {
  if (item.asRelatedItemId && item.prodId === boxItem.prodId && item.asRelatedItemId === boxItem.asRelatedItemId) {
    return acc + (boxItem.quantityPerBox || 1);
  }
  return acc;
}, 0);
export const isItemRelatedToBoxItem = (item, allItems) => {
  if (!item.asRelatedItemId) {
    return false;
  }
  const relatedItems = allItems.filter(i => i.asRelatedItemId === item.asRelatedItemId);
  return relatedItems.some(i => !!i.boxItemsId);
};