import _isString from "lodash/isString";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { AddressManualType } from 'swag-client-common/constants';
export const getAutocompleteAddressPayload = (addresses, type) => {
  const address = addresses.find(a => a.addressType === type);
  const isAddressEnteredManually = type === AddressManualType.ADDRESS_ENTERED;
  return {
    // @todo should be fixed
    // @ts-ignore
    address,
    isAddressEnteredManually
  };
};
export const getAddressesFromStateForAutocompleteSelection = (shippingForm, address) => {
  const autocompleteAddressesForSelection = {
    enteredAddress: _objectSpread({}, shippingForm, {
      addressType: AddressManualType.ADDRESS_ENTERED
    })
  };
  if (address) {
    autocompleteAddressesForSelection.suggestedAddress = _objectSpread({}, address, {
      addressType: AddressManualType.ADDRESS_SUGGESTED
    });
  }
  return autocompleteAddressesForSelection;
};
export const checkIsShippingAddressInFormDifferentToSelectedFromAutocomplete = (shippingForm, autocompleteSelection) => {
  if (!autocompleteSelection) {
    return true;
  }
  const {
    city: formCity,
    country: formCountry,
    address: formAddress,
    state: formState,
    zip: formZip
  } = shippingForm;
  const {
    city: autocompleteCity,
    country: autocompleteCountry,
    address: autocompleteAddress,
    state: autocompleteState,
    zip: autocompleteZip
  } = autocompleteSelection;
  const isCitySame = checkIsAddressPartialValuesSame(formCity, autocompleteCity);
  // @todo should be fixed
  // @ts-ignore
  const isStateSame = checkIsAddressPartialValuesSame(autocompleteState, formState);
  const isCountrySame = checkIsAddressPartialValuesSame(autocompleteCountry, formCountry);
  const isZipSame = checkIsAddressPartialValuesSame(autocompleteZip, formZip);
  const isAddressSame = checkIsAddressPartialValuesSame(autocompleteAddress, formAddress);
  return [isCitySame, isStateSame, isCountrySame, isZipSame, isAddressSame].some(checker => !checker);
};
export const checkIsAddressPartialValuesSame = (before, after) => {
  if (!before && !after) {
    return true;
  }
  if (!_isString(before) || !_isString(after)) {
    return false;
  }
  const trimBefore = before.trim();
  const trimAfter = after.trim();
  return trimBefore === trimAfter;
};
export const checkIsAddressValidationNeeded = ({
  isAutoCompleteTurnedOff,
  isManualAddressConfirmed,
  shippingForm,
  autocompleteSelection,
  shippingAddressUntouched = false
}) => {
  const isEnteredAddressAndAutocompleteAddressDifferent = checkIsShippingAddressInFormDifferentToSelectedFromAutocomplete(shippingForm, autocompleteSelection);
  const isAutocompletedUsed = autocompleteSelection && !isEnteredAddressAndAutocompleteAddressDifferent;
  if (shippingAddressUntouched) {
    return false;
  }
  if (isAutocompletedUsed && !isEnteredAddressAndAutocompleteAddressDifferent) {
    return false;
  }
  if (!isManualAddressConfirmed && !isAutocompletedUsed && isEnteredAddressAndAutocompleteAddressDifferent) {
    return true;
  }
  if (!isManualAddressConfirmed && isAutocompletedUsed && isEnteredAddressAndAutocompleteAddressDifferent) {
    return true;
  }
  if (isManualAddressConfirmed) {
    return false;
  }
  return isAutoCompleteTurnedOff || isEnteredAddressAndAutocompleteAddressDifferent;
};