export const REFUNDED = -15;
export const CANCELLED = -10;
export const REJECTED = -5;
export const ABANDONED = -1;
export const DISPLAY_ALL = 0;
export const INITIAL = 1; // just created item
export const PENDING_APPROVAL = 2; // order approval flow -- pending
export const PO_RECEIVED = 3; // for NET && UPFR_50_NET payment flows
export const PREPAYMENT_RECEIVED = 5; // for NET && UPFR_50_NET && ACH payment flows
export const PAID = 10; // Credit Cart (CC) flow
export const COLORS_REVIEW_BY_RESELLER_REQUESTED = 11; // reseller flow
export const COLORS_REVIEW_BY_CUSTOMER_REQUESTED = 13; // common flow
export const COLORS_APPROVED_BY_CUSTOMER = 15; // common flow
export const SENT_TO_VENDOR = 20; // common flow
export const MOCKUP_APPROVAL_BY_RESELLER = 25; // reseller flow
export const MOCKUP_REVISION_BY_RESELLER = 27; // reseller flow
export const MOCKUP_APPROVAL_BY_CUSTOMER = 30; // common flow
export const MOCKUP_REVISION_BY_CUSTOMER = 40; // common flow
export const MOCKUP_APPROVED_BY_CUSTOMER = 50; // common flow
export const IN_PRODUCTION = 60; // common flow
export const CONTINUED_PRODUCTION = 65; // common flow
export const NOTICE_SENT_WAREHOUSE = 67; // common flow
export const SHIPPED = 70; // common flow
export const DELIVERED_TO_WAREHOUSE = 73; // common box flow
/**
 * Status for box items
 * Applied after all box items composed on warehouse
 * And box is ready to be in stock and distribute through inventory
 */
export const READY_FOR_DISTRIBUTION = 74;
export const SHIPPED_TO_CUSTOMER = 75; // common box flow
export const DELIVERED = 80; // common flow
export const FINAL_PAYMENT = 90; // for NET && UPFR_50_NET payment flows