function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
const pageLinks = {
  homepage: APP_ROUTES.HOME,
  faq: APP_ROUTES.FAQ,
  letsTalk: APP_ROUTES.CONTACT,
  kickstarter: APP_ROUTES.KICKSTARTER_CALCULATOR,
  customSwag: APP_ROUTES.CUSTOM_SWAG,
  ourStory: APP_ROUTES.OUR_STORY,
  primoprint: APP_ROUTES.PRIMOPRINT,
  industrious: APP_ROUTES.INDUSTRIOUS,
  americanUnderground: APP_ROUTES.AMERICANUNDERGROUND,
  wework: APP_ROUTES.WEWORK,
  spring: APP_ROUTES.SPRING,
  techstars: APP_ROUTES.TECHSTARS,
  office: APP_ROUTES.OFFICE,
  starbucks: APP_ROUTES.STARBUCKS,
  notFound: APP_ROUTES.NOT_FOUND,
  allSwag: APP_ROUTES.ALL_SWAG,
  inBox: APP_ROUTES.IN_A_BOX,
  intelligent: APP_ROUTES.INTELLIGENT,
  policy: APP_ROUTES.PRIVACY_POLICY,
  cart: APP_ROUTES.CART,
  checkout: APP_ROUTES.CHECKOUT,
  delivery: APP_ROUTES.DELIVERY,
  distribute: APP_ROUTES.DISTRIBUTE,
  awYouGuys: APP_ROUTES.REVIEWS,
  patagonia: APP_ROUTES.PATAGONIA,
  pastWork: APP_ROUTES.PAST_WORK,
  giveaway: APP_ROUTES.GIVEAWAY,
  automation: APP_ROUTES.AUTOMATION,
  zapier: APP_ROUTES.ZAPIER,
  explorer: APP_ROUTES.EXPLORER
};
export const getMetaTagsByPathname = (metaTags, pathname) => {
  switch (pathname) {
    case pageLinks.homepage:
      {
        return metaTags.homepage;
      }
    case pageLinks.allSwag:
      {
        return metaTags.allSwag;
      }
    case pageLinks.faq:
      {
        return metaTags.faq;
      }
    case pageLinks.letsTalk:
      {
        return metaTags.letsTalk;
      }
    case pageLinks.kickstarter:
      {
        return _objectSpread({}, metaTags.kickstarter, {
          robots: 'noindex,nofollow'
        });
      }
    case pageLinks.customSwag:
      {
        return metaTags.customSwag;
      }
    case pageLinks.primoprint:
      {
        return metaTags.primoprint;
      }
    case pageLinks.industrious:
      {
        return metaTags.industrious;
      }
    case pageLinks.americanUnderground:
      {
        return metaTags.americanUnderground;
      }
    case pageLinks.wework:
      {
        return metaTags.wework;
      }
    case pageLinks.spring:
      {
        return metaTags.spring;
      }
    case pageLinks.ourStory:
      {
        return metaTags.ourStory;
      }
    case pageLinks.techstars:
      {
        return metaTags.techstars;
      }
    case pageLinks.starbucks:
      {
        return metaTags.starbucks;
      }
    case pageLinks.office:
      {
        return metaTags.office;
      }
    case pageLinks.inBox:
      {
        return metaTags.inBox;
      }
    case pageLinks.intelligent:
      {
        return metaTags.intelligent;
      }
    case pageLinks.notFound:
      {
        return _objectSpread({}, metaTags.notFound, {
          type: 'notFound'
        });
      }
    case pageLinks.policy:
      {
        return metaTags.policy;
      }
    case pageLinks.cart:
      {
        return metaTags.cart;
      }
    case pageLinks.checkout:
      {
        return metaTags.checkout;
      }
    case pageLinks.delivery:
      {
        return metaTags.delivery;
      }
    case pageLinks.awYouGuys:
      {
        return metaTags.awYouGuys;
      }
    case pageLinks.distribute:
      {
        return metaTags.distribute;
      }
    case pageLinks.patagonia:
      {
        return metaTags.patagonia;
      }
    case pageLinks.pastWork:
      {
        return metaTags.pastWork;
      }
    case pageLinks.giveaway:
      {
        return metaTags.giveaway;
      }
    case pageLinks.automation:
      {
        return metaTags.automation;
      }
    case pageLinks.zapier:
      {
        return metaTags.zapier;
      }
    case pageLinks.explorer:
      {
        return metaTags.explorer;
      }
    default:
  }
};