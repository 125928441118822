import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/product';
const ProductApi = createApi({
  getPage: {
    url: `${baseUrl}/page`,
    method: 'get'
  },
  getById: {
    url: `${baseUrl}/:id`,
    method: 'get'
  },
  getByIds: {
    url: `${baseUrl}/products`,
    method: 'get'
  },
  getByNames: {
    url: `${baseUrl}/names`,
    method: 'get'
  },
  getSwagSampleBox: {
    url: `${baseUrl}/swag-sample-box`,
    method: 'get'
  },
  getSuggested: {
    url: `${baseUrl}/suggested`,
    method: 'post'
  },
  getSuggestedByCategory: {
    url: `${baseUrl}/suggested-by-category`,
    method: 'post'
  },
  search: {
    url: `${baseUrl}/search`,
    method: 'get'
  },
  filter: {
    url: `${baseUrl}/filter`,
    method: 'get'
  },
  getFonts: {
    url: `${baseUrl}/text-fonts`,
    method: 'get'
  },
  subscribeUserToOutOfStockProduct: {
    url: '/api/v1/product/subscription',
    method: 'post'
  },
  getAddonProductsForBox: {
    url: `${baseUrl}/add-on-for-box`,
    method: 'get'
  },
  submitInstantQuote: {
    url: `${baseUrl}/submit-instant-quote`,
    method: 'post'
  }
});
export default ProductApi;