import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { editQuery, clearSearchResults } from '../redux/search-products/search-products.actions';
import { changeQueryString } from '../redux/search-results/search-results.actions';
const UPDATE_LOCATION = '@@router/UPDATE_LOCATION';
const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export default function ({
  getState,
  dispatch
}) {
  return next => action => {
    switch (action.type) {
      case UPDATE_LOCATION:
      case LOCATION_CHANGE:
        {
          const {
            searchProducts,
            searchResults
          } = getState();
          if (action.payload.pathname !== APP_ROUTES.SEARCH_RESULTS) {
            if (searchResults.query) {
              dispatch(changeQueryString(''));
            }
            if (searchProducts.query) {
              dispatch(editQuery(''));
              dispatch(clearSearchResults());
            }
          }
          break;
        }
      default:
    }
    next(action);
  };
}