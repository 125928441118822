import moment from 'moment';
import isValidQuantityByIncrement from 'swag-common/utils/product/is-valid-quantity-by-increment.util';
import { MAX_QUANTITY_TO_BUY } from '../../constants/product.constants';
const CANNOT_TEXT = 'You cannot order';
function getNotificationText({
  quantityForCurrentItem,
  productIsAlwaysInStock = false,
  minQuantity = 0,
  quantIncrement = 1,
  variants = [],
  stockLevelsByVariantSku = {},
  maxQuantity = MAX_QUANTITY_TO_BUY,
  totalQuantityWithRelatedItems = null,
  isVariantOutOfStock = false,
  outOfStockExpireDate = null,
  sharedQuantityForCurrentItem = undefined,
  isShared = false,
  skipValidation = false
}) {
  if (skipValidation) {
    return '';
  }
  if (isVariantOutOfStock) {
    return `This color is not available for purchase and is currently out of stock${outOfStockExpireDate ? ` (Back in stock date is ${moment(outOfStockExpireDate).format('MMMM Do')})` : ''}. Please contact us for more information`;
  }
  const quantityToValidateForMinAndMax = sharedQuantityForCurrentItem || totalQuantityWithRelatedItems || quantityForCurrentItem;
  if (quantityToValidateForMinAndMax > maxQuantity) {
    return `${CANNOT_TEXT} more than ${maxQuantity}`;
  }
  if (quantityToValidateForMinAndMax < minQuantity) {
    return `${CANNOT_TEXT} less than ${minQuantity}`;
  }
  if (variants.length > 1 && !isShared) {
    return '';
  }
  const currentVariant = variants[0];
  const isAlwaysInStock = currentVariant ? productIsAlwaysInStock || currentVariant.isAlwaysInStockVariant : false;
  const quantityToValidateForPS = sharedQuantityForCurrentItem || quantityForCurrentItem;
  if (currentVariant && !isAlwaysInStock && currentVariant !== null && currentVariant !== void 0 && currentVariant.productSku) {
    const variantInStock = stockLevelsByVariantSku[currentVariant.productSku];
    if (variantInStock === 0 && quantityToValidateForPS !== 0) {
      return 'This color is out of stock';
    }
    if (quantityToValidateForPS > variantInStock) {
      if (isShared) {
        return `Overall q-ty is ${quantityToValidateForPS} but only ${variantInStock} are available. Please adjust quantity or try another color or another product to proceed.`;
      } else {
        return `Only ${variantInStock} available for purchase. Please adjust quantity to proceed.`;
      }
    }
  }
  if (!isValidQuantityByIncrement(sharedQuantityForCurrentItem || quantityForCurrentItem, quantIncrement)) {
    return `Has to be ordered in increments of ${quantIncrement}`;
  }
  return '';
}
export default getNotificationText;