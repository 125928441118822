function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { FETCH_PROFILE_SUCCESS } from 'swag-client-common/redux/user/user.actions';
import { GET_LEVELS_SUCCESS, GET_TRANSACTIONS_SUCCESS, GET_SCORE_SUCCESS, SET_LEVEL_PICKER, TOGGLE_DRAWER } from './gamification.actions';
import { DRAWERS } from './gamification.constants';
const initialState = {
  levels: [],
  scoreHistory: [],
  transactionsByLevels: {},
  selectedLvl: null,
  drawerOpen: {
    [DRAWERS.GET_MORE]: false,
    [DRAWERS.ALL_ACTIVITY]: false
  }
};
export default function enterpriseFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LEVELS_SUCCESS:
      return _objectSpread({}, state, {
        levels: action.payload.levels
      });
    case GET_TRANSACTIONS_SUCCESS:
      return _objectSpread({}, state, {
        transactionsByLevels: action.payload.transactionsByLevels
      });
    case SET_LEVEL_PICKER:
      return _objectSpread({}, state, {
        selectedLvl: action.val
      });
    case GET_SCORE_SUCCESS:
    case FETCH_PROFILE_SUCCESS:
      {
        const {
          gamification
        } = action.body;
        const scoreHistory = gamification.company && gamification.company.length ? gamification.company : gamification.user;
        return _objectSpread({}, state, {
          scoreHistory
        });
      }
    case TOGGLE_DRAWER:
      {
        const {
          type,
          open
        } = action.payload;
        state.drawerOpen[type] = open;
        return _objectSpread({}, state);
      }
    default:
      return state;
  }
}