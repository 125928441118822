import { createApi } from 'swag-client-common/libs/rapi';
export const CollectSizesApi = createApi({
  create: {
    url: '/api/v1/collect-sizes',
    method: 'post'
  },
  deleteSizeSubmission: {
    url: '/api/v1/collect-sizes/:id',
    method: 'delete'
  },
  getSizeSubmissionById: {
    url: '/api/v1/collect-sizes/:id',
    method: 'get'
  },
  getSizeSubmission: {
    url: '/api/v1/collect-sizes',
    method: 'get'
  },
  getSizeSubmissionLinkData: {
    url: '/api/v1/collect-sizes/by-link/:link',
    method: 'get'
  },
  submitSizeByLink: {
    url: '/api/v1/collect-sizes/submit',
    method: 'post'
  },
  renameSizeSubmissionCampaign: {
    url: '/api/v1/collect-sizes/rename',
    method: 'PATCH'
  }
});