import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { swagAdminModeLabelSelector } from 'swag-client-common/redux/user/user.selectors';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { ADMIN_MODE_EXIT_POPUP } from 'swag-client-common/constants';
import { AdminModeColoredFrame as AdminModeColoredFrameComponent } from './admin-mode-colored-frame';
const mapStateToProps = createStructuredSelector({
  labelText: swagAdminModeLabelSelector
});
const mapDispatchToProps = dispatch => ({
  onExit: () => {
    dispatch(showPopup({
      popup: ADMIN_MODE_EXIT_POPUP
    }));
  }
});
export const AdminModeColoredFrame = connect(mapStateToProps, mapDispatchToProps)(AdminModeColoredFrameComponent);