import { createSelector } from 'reselect';
const rootSelector = createSelector(state => state.shareDesign, root => root);
const emailSelector = createSelector(rootSelector, state => state.email);
const errorSelector = createSelector(rootSelector, state => state.error);
const customerMockupsSelector = createSelector(rootSelector, state => state.customerMockups);
const specsSelector = createSelector(rootSelector, state => state.specs);
const descrSelector = createSelector(rootSelector, state => state.descr);
const prodTimeSelector = createSelector(rootSelector, state => state.prodTime);
const quantitySelector = createSelector(rootSelector, state => state.quantity);
const priceSelector = createSelector(rootSelector, state => state.price);
export const itemsToShareSelector = createSelector(rootSelector, root => root.itemsToShare);
export const emailBlockSelector = createSelector(emailSelector, errorSelector, (email, error) => ({
  email,
  error
}));
export const isBoxItemSelector = createSelector(itemsToShareSelector, items => items && items[0] && items[0].boxItemsId);
export const checkboxBlockSelector = createSelector(customerMockupsSelector, specsSelector, descrSelector, prodTimeSelector, quantitySelector, priceSelector, isBoxItemSelector, (customerMockups, specs, descr, prodTime, quantity, price, isItemInBox) => ({
  customerMockups,
  specs,
  descr,
  prodTime,
  quantity,
  price,
  isItemInBox
}));