import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { isSwagAdminLoggedInAsResellerUserSelector, isAdminLoginAsUserSelector, userSelector, isCustomInkImpersonateFlowSelector, isSwagAdminModeSelector } from 'swag-client-common/redux/user/user.selectors';
import { logoutAsUser } from 'swag-client-common/redux/user/user.actions';
import { removeOriginalUserRole } from 'swag-client-common/utils/local-storage.util';
import { AdminWidget } from './admin-as-user-widget';
const shouldShowAdminWidgetSelector = createSelector(isAdminLoginAsUserSelector, isCustomInkImpersonateFlowSelector, (isAdminLoginAsUser, isCustomInkImpersonateFlow) => isAdminLoginAsUser || isCustomInkImpersonateFlow);
const mapStateToProps = createStructuredSelector({
  user: userSelector,
  isLoggedInAsResellerAdmin: isSwagAdminLoggedInAsResellerUserSelector,
  isSwagAdminMode: isSwagAdminModeSelector,
  shouldShowAdminWidget: shouldShowAdminWidgetSelector
});
const mapDispatchToProps = dispatch => ({
  logout: () => {
    removeOriginalUserRole();
    dispatch(logoutAsUser());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminWidget);