import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/recently-viewed';
const RecentlyViewedApi = createApi({
  addToRecentlyViewed: {
    url: `${baseUrl}/`,
    method: 'post'
  },
  merge: {
    url: `${baseUrl}/merge`,
    method: 'post'
  },
  getProducts: {
    url: `${baseUrl}/`,
    method: 'get'
  }
});
export default RecentlyViewedApi;