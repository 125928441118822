import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { USER_ROLES } from 'swag-common/constants/user-roles';
import CustomStoreApi from '../../api/custom-stores.api';
import { isLoggedInSelector, userRoleSelector } from '../user/user.selectors';
const PREFIX = 'CUSTOM_STORE_';
export const GET_CUSTOM_STORE_REQUEST = `${PREFIX}GET_CUSTOM_STORE_REQUEST`;
export const GET_CUSTOM_STORE_SUCCESS = `${PREFIX}GET_CUSTOM_STORE_SUCCESS`;
export const GET_CUSTOM_STORE_ERROR = `${PREFIX}GET_CUSTOM_STORE_ERROR`;
export const getCustomStore = createAsyncAction({
  fn: CustomStoreApi.getStore,
  preFn: (dispatch, getState) => {
    const state = getState();
    const isLoggedIn = isLoggedInSelector(state);
    const userRole = userRoleSelector(state);
    if (!isLoggedIn || userRole !== USER_ROLES.ENTERPRISE_ADMIN) {
      return true;
    }
    dispatch({
      type: GET_CUSTOM_STORE_REQUEST
    });
  },
  success: store => ({
    type: GET_CUSTOM_STORE_SUCCESS,
    payload: store
  }),
  error: error => ({
    type: GET_CUSTOM_STORE_ERROR,
    error
  })
});