function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import dateFormat from 'dateformat';
import { createSelector } from 'reselect';
import { Price } from 'swag-client-common/utils/price';
import { getPossibleDeliveriesList } from 'swag-common/utils/shipout/get-possible-deliveries-list';
import { featureTogglesSelector } from 'swag-client-common/redux/feature-toggles/feature-toggles.selectors';
import { Carrier, COUNTRY_CODES, DHL_ALLOWED_SHIPPING_OPTIONS } from 'swag-common/constants/shipping.constants';
import { renameDeliveryOptions } from 'swag-common/utils/rename-delivery-options';
import { isBox } from 'swag-common/utils/order/item-is-box.util';
import { cartItemsSelector, orderIdtSelector } from '../cart/cart.selectors';
const distributionFeeEstimationSelector = state => state.distributionFeeEstimation;
const distributionFeeEstimationQuantitiesSelector = createSelector(distributionFeeEstimationSelector, state => state.quantitiesInPackage);
const resultedDistributionFeeEstimationQuantitiesSelector = createSelector(cartItemsSelector, distributionFeeEstimationQuantitiesSelector, (items, quantities) => {
  const boxItems = items.filter(isBox);
  return items.reduce((acc, item) => {
    const itemId = String(item._id);
    const relatedBoxItem = boxItems.find(({
      _id,
      boxItemsId
    }) => String(_id) !== itemId && boxItemsId === item.boxItemsId);
    const quantity = quantities[itemId] || 0;
    return _objectSpread({}, acc, {
      [itemId]: relatedBoxItem ? quantities[String(relatedBoxItem._id)] * (item.quantityPerBox || 1) : quantity
    });
  }, {});
});
export const noInternationalShippingItemsSelector = createSelector(cartItemsSelector, distributionFeeEstimationQuantitiesSelector, (items, quantities) => items.reduce((acc, item) => {
  if (quantities[item._id] > 0 && item.prod.isForbiddenToShipInternationally) {
    acc.push({
      productId: item.prod._id,
      name: item.prod.name
    });
  }
  return acc;
}, []));
export const distributionFeeEstimationQuantitiesByProductSelector = createSelector(distributionFeeEstimationQuantitiesSelector, (_, props) => props.item, (productQuantities, item) => productQuantities[item._id] || 0);
export const isDistributionFeeEstimationQuantitiesValidSelector = createSelector(distributionFeeEstimationQuantitiesSelector, productQuantities => Object.values(productQuantities).some(quantity => quantity > 0));
export const distributionFeeEstimationScreenSelector = createSelector(distributionFeeEstimationSelector, state => state.screen);
export const distributionFeeEstimationAddressesSelector = createSelector(distributionFeeEstimationSelector, state => state.addresses);
export const distributionFeeEstimationZipCodeValidationErrorSelector = createSelector(distributionFeeEstimationSelector, state => state.error);
export const checkoutDistributionFeeEstimationResultsSelector = createSelector(distributionFeeEstimationSelector, state => state.checkoutResults);
export const getDistributionFeeEstimationErrorsSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, list => list.map((cost, index) => {
  if (cost.error) {
    return `Line ${index + 1}: ${cost.error}`;
  }
  return null;
}).filter(Boolean));
function getSelectedShippingToCustomerByCosts(costs) {
  return costs[0] ? costs[0].selectedShippingToCustomer : null;
}
export const selectedDomesticShippingToCustomerEstimateDistributionFeeSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, costs => {
  const usCosts = costs.filter(cost => cost.country === COUNTRY_CODES.US);
  return getSelectedShippingToCustomerByCosts(usCosts) || '';
});
export const selectedInternationalShippingToCustomerEstimateDistributionFeeSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, costs => {
  const internationalCosts = costs.filter(cost => cost.country !== COUNTRY_CODES.US);
  return getSelectedShippingToCustomerByCosts(internationalCosts) || '';
});
export const isDHLInternationalShippingOptionChosenSelector = createSelector(selectedInternationalShippingToCustomerEstimateDistributionFeeSelector, selectedMethod => selectedMethod === DHL_ALLOWED_SHIPPING_OPTIONS.PLY);
export const getDistributionFeeEstimationCostsForCSVSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, selectedInternationalShippingToCustomerEstimateDistributionFeeSelector, selectedDomesticShippingToCustomerEstimateDistributionFeeSelector, (costs, selectedIntMethod, selectedDomMethod) => costs.map(cost => {
  var _possibleDeliveriesTo, _possibleDeliveriesTo2;
  const {
    taxes,
    possibleDeliveriesToCustomer = {},
    shippingToCustomerPrice
  } = cost;
  let taxesSum = 0;
  let taxVat = 0;
  let taxDutyFees = 0;
  let taxFees = 0;
  if (taxes) {
    taxVat = taxes.vat;
    taxDutyFees = taxes.dutyFees;
    taxFees = taxes.fees;
    taxesSum = taxVat + taxDutyFees + taxFees;
  }
  const shippingPrice = cost.country === COUNTRY_CODES.US ? (_possibleDeliveriesTo = possibleDeliveriesToCustomer[selectedDomMethod]) === null || _possibleDeliveriesTo === void 0 ? void 0 : _possibleDeliveriesTo.shippingPrice : (_possibleDeliveriesTo2 = possibleDeliveriesToCustomer[selectedIntMethod]) === null || _possibleDeliveriesTo2 === void 0 ? void 0 : _possibleDeliveriesTo2.shippingPrice;
  const shippingCost = shippingPrice || shippingToCustomerPrice;
  return [cost.country, cost.state, String(cost.packageCount), Price.formatPriceCSV(shippingCost), Price.formatPriceCSV(cost.pickAndPackPrice), Price.formatPriceCSV(taxesSum), Price.formatPriceCSV(shippingCost + cost.pickAndPackPrice + taxesSum), formatDate(cost.estWarehouseDate), formatDate(cost.estInHandDate)];
}));
export const loadingEstimateDistributionFeeSelector = createSelector(distributionFeeEstimationSelector, state => state.isLoading);
export const possibleDeliveriesToCustomerEstimateDistributionFeeSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, featureTogglesSelector, (costs, featureToggles) => {
  const domesticReceivers = [];
  const internationalReceivers = [];
  costs.forEach(rec => {
    if (rec.country === COUNTRY_CODES.US) {
      domesticReceivers.push(rec);
    } else {
      internationalReceivers.push(rec);
    }
  });
  const possibleDomesticDeliveriesList = getPossibleDeliveriesList(domesticReceivers, rec => rec.possibleDeliveriesToCustomer ? Object.keys(rec.possibleDeliveriesToCustomer) : [], cur => cur.possibleDeliveriesToCustomer, cur => cur.selectedShippingToCustomer);
  const possibleInternationalDeliveriesList = getPossibleDeliveriesList(internationalReceivers, rec => rec.possibleDeliveriesToCustomer ? Object.keys(rec.possibleDeliveriesToCustomer) : [], cur => cur.possibleDeliveriesToCustomer, cur => cur.selectedShippingToCustomer);
  const possibleInternationalDeliveries = Object.values(renameDeliveryOptions(possibleInternationalDeliveriesList)).map(delivery => _objectSpread({}, delivery, {
    totalCharges: delivery.shippingPrice
  })).filter(delivery => featureToggles.dhlShippingIntegration ? true : delivery.carrier !== Carrier.DHL);
  return {
    possibleDomesticDeliveries: Object.values(renameDeliveryOptions(possibleDomesticDeliveriesList)).map(delivery => _objectSpread({}, delivery, {
      totalCharges: delivery.shippingPrice
    })),
    possibleInternationalDeliveries
  };
});
export const distributionFeeEstimateRecipientsQuantitySelector = createSelector(distributionFeeEstimationSelector, state => state.recipientsQuantity);
export const isDistributionFeeEstimateRecipientsQuantityValidSelector = createSelector(distributionFeeEstimateRecipientsQuantitySelector, quantity => quantity > 0);
export const isEnoughAddressesWereEnteredSelector = createSelector(distributionFeeEstimationAddressesSelector, distributionFeeEstimateRecipientsQuantitySelector, (addresses, quantity) => addresses.every(address => Number(address.quantity) > 0) && addresses.reduce((total, cur) => total + Number(cur.quantity), 0) === quantity);
export const distributionFeeEstimationZipErrorSelector = createSelector(distributionFeeEstimationAddressesSelector, distributionFeeEstimateRecipientsQuantitySelector, (list, recipientsQuantity) => {
  const errors = [];
  if (list.some(value => !value.quantity)) {
    errors.push('Address need to have at least one recipient');
  }
  const total = list.reduce((total, cur) => total + Number(cur.quantity), 0);
  if (list.length > 0 && total !== recipientsQuantity) {
    errors.push(`The number of recipients should be ${recipientsQuantity}`);
  }
  return errors;
});
export const addressCountEstimateDistributionFeeSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, costs => costs.length);
export const zipCodesQuantityEstimateDistributionFeeSelector = createSelector(distributionFeeEstimationAddressesSelector, addresses => addresses.reduce((acc, address) => acc + parseInt(address.quantity), 0));
export const totalShippingToCustomerPriceEstimateDistributionFeeSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, selectedInternationalShippingToCustomerEstimateDistributionFeeSelector, selectedDomesticShippingToCustomerEstimateDistributionFeeSelector, (costs, selectedIntMethod, selectedDomMethod) => {
  return costs.reduce((total, cost) => {
    var _cost$possibleDeliver, _cost$possibleDeliver2;
    const shippingPrice = cost.country === COUNTRY_CODES.US ? (_cost$possibleDeliver = cost.possibleDeliveriesToCustomer[selectedDomMethod]) === null || _cost$possibleDeliver === void 0 ? void 0 : _cost$possibleDeliver.shippingPrice : (_cost$possibleDeliver2 = cost.possibleDeliveriesToCustomer[selectedIntMethod]) === null || _cost$possibleDeliver2 === void 0 ? void 0 : _cost$possibleDeliver2.shippingPrice;
    const shippingCost = shippingPrice || cost.shippingToCustomerPrice;
    return shippingCost + total;
  }, 0);
});
export const pickAndPackPriceWarehouseEstimateDistributionFeeSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, costs => costs.reduce((total, cost) => cost.pickAndPackPrice + total, 0));
export const taxesEstimateDistributionFeeSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, costs => costs.reduce((total, cost) => ({
  vat: total.vat + cost.taxes.vat,
  dutyFees: total.dutyFees + cost.taxes.dutyFees,
  fees: total.fees + cost.taxes.fees
}), {
  vat: 0,
  dutyFees: 0,
  fees: 0
}));
export const taxesVatEstimateDistributionFeeSelector = createSelector(taxesEstimateDistributionFeeSelector, taxes => taxes.vat);
export const taxesDutyFeesEstimateDistributionFeeSelector = createSelector(taxesEstimateDistributionFeeSelector, taxes => taxes.dutyFees);
export const taxesFeesEstimateDistributionFeeSelector = createSelector(taxesEstimateDistributionFeeSelector, taxes => taxes.fees);
export const taxesSumEstimateDistributionFeeSelector = createSelector(taxesEstimateDistributionFeeSelector, taxes => taxes.vat + taxes.dutyFees + taxes.fees);
export const shouldShowOnlyTaxesLabelDistributionFeeSelector = createSelector(taxesEstimateDistributionFeeSelector, allTaxes => {
  return Boolean(allTaxes.vat && !allTaxes.dutyFees && !allTaxes.fees);
});
export const totalEstimateDistributionFeeSelector = createSelector(pickAndPackPriceWarehouseEstimateDistributionFeeSelector, totalShippingToCustomerPriceEstimateDistributionFeeSelector, taxesSumEstimateDistributionFeeSelector, (pickAndPack, shippingToCustomer, taxes) => shippingToCustomer + pickAndPack + taxes);
export const estDateToCustomerEstimateDistributionFeeSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, costs => {
  const dates = costs.reduce((acc, cost) => {
    const newAccValues = {};
    if (!acc.min || new Date(acc.min) > new Date(cost.estInHandDate.min)) {
      newAccValues.min = cost.estInHandDate.min;
    }
    if (!acc.min || new Date(acc.max) < new Date(cost.estInHandDate.max)) {
      newAccValues.max = cost.estInHandDate.max;
    }
    return _objectSpread({}, acc, newAccValues);
  }, {
    min: '',
    max: ''
  });
  return formatDate(dates);
});
export const estDateToWarehouseEstimateDistributionFeeSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, costs => formatDate(costs[0].estWarehouseDate));
export const hasInternationalOrDomesticShipmentsSelector = createSelector(checkoutDistributionFeeEstimationResultsSelector, receivers => {
  const domesticReceivers = receivers.filter(rec => rec.country === COUNTRY_CODES.US);
  const internationalReceivers = receivers.filter(rec => rec.country !== COUNTRY_CODES.US);
  const domesticDeliveryCodesByReceivers = domesticReceivers.filter(Boolean);
  const internationalDeliveryCodesByReceivers = internationalReceivers.filter(Boolean);
  const hasDomesticShipments = Boolean(domesticDeliveryCodesByReceivers.length);
  const hasInternationalShipments = Boolean(internationalDeliveryCodesByReceivers.length);
  const hasOnlyDomesticShipments = hasDomesticShipments && !hasInternationalShipments;
  const hasOnlyInternationalShipments = hasInternationalShipments && !hasDomesticShipments;
  return {
    hasOnlyInternationalShipments,
    hasOnlyDomesticShipments
  };
});
export const widgetTypeSelector = createSelector(distributionFeeEstimationSelector, widget => widget.type);
export const distributionFeeEstimateStatesListSelector = createSelector(distributionFeeEstimationSelector, widget => {
  var _widget$statesList;
  return ((_widget$statesList = widget.statesList) === null || _widget$statesList === void 0 ? void 0 : _widget$statesList.map(state => ({
    value: state,
    label: state,
    key: state
  }))) || [];
});
export const widgetResultSelector = createSelector(distributionFeeEstimationSelector, widget => widget.widgetResults);
export const isWidgetLoadingSelector = createSelector(distributionFeeEstimationSelector, widget => widget.isLoading);
export const widgetErrorSelector = createSelector(distributionFeeEstimationSelector, widget => widget.error);
export const isWidgetResultLoadedSelector = createSelector(widgetResultSelector, result => result !== null);
export const widgetShippingSelector = createSelector(widgetResultSelector, result => result ? result.shipping : 0);
export const widgetPickAndPackSelector = createSelector(widgetResultSelector, result => result ? result.pickAndPack : 0);
export const widgetTotalSelector = createSelector(widgetShippingSelector, widgetPickAndPackSelector, (shipping, pickAndPack) => shipping + pickAndPack);
export const getDistributionFeeEstimationDestinationsSelectorFactory = ({
  withShippingMethod = true,
  shippingMethodToCustomerParams,
  isInternational = false
}) => createSelector(distributionFeeEstimationAddressesSelector, resultedDistributionFeeEstimationQuantitiesSelector, hasInternationalOrDomesticShipmentsSelector, orderIdtSelector, selectedDomesticShippingToCustomerEstimateDistributionFeeSelector, selectedInternationalShippingToCustomerEstimateDistributionFeeSelector, (addressList, itemsList, {
  hasOnlyInternationalShipments,
  hasOnlyDomesticShipments
}, orderId, selectedDomesticShippingToCustomer, selectedInternationalShippingToCustomer) => {
  return addressList.map(({
    country,
    state,
    quantity
  }) => {
    let shippingMethodToCustomer;
    if (!withShippingMethod) {
      shippingMethodToCustomer = undefined;
    } else if (country === COUNTRY_CODES.US) {
      shippingMethodToCustomer = isInternational ? selectedDomesticShippingToCustomer : shippingMethodToCustomerParams;
    } else {
      shippingMethodToCustomer = isInternational ? shippingMethodToCustomerParams : selectedInternationalShippingToCustomer;
    }
    return {
      quantity: parseInt(quantity),
      state,
      itemsList,
      country,
      shippingMethodToCustomer,
      orderId
    };
  });
});
function formatDate({
  min,
  max
}) {
  const dateMin = dateFormat(min, 'mmm dS');
  const dateMax = dateFormat(max, 'mmm dS');
  if (dateMax === dateMin) {
    return dateMin;
  }
  return `${dateMin} - ${dateMax}`;
}
export const shouldShowAdditionalTaxesTooltipDistributionFeeSelector = createSelector(selectedInternationalShippingToCustomerEstimateDistributionFeeSelector, checkoutDistributionFeeEstimationResultsSelector, (shippingMethodInternational, destinations) => {
  return destinations.some(destination => {
    var _destination$possible;
    if (destination.country === COUNTRY_CODES.US) {
      return false;
    }
    return !(destination !== null && destination !== void 0 && (_destination$possible = destination.possibleDeliveriesToCustomer) !== null && _destination$possible !== void 0 && _destination$possible[shippingMethodInternational]);
  });
});