function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export const isSharedWorkerSupported = ('SharedWorker' in globalThis);
export class SharedWorkerPolyfill {
  constructor(url, opts) {
    _defineProperty(this, "ActualWorker", void 0);
    if (isSharedWorkerSupported) {
      this.ActualWorker = new SharedWorker(url, opts);
    } else {
      this.ActualWorker = new Worker(url, opts);
    }
  }
  get onmessage() {
    if (isSharedWorkerSupported) {
      var _this$ActualWorker;
      return (_this$ActualWorker = this.ActualWorker) === null || _this$ActualWorker === void 0 ? void 0 : _this$ActualWorker.port.onmessage;
    } else {
      return this.ActualWorker.onmessage;
    }
  }
  set onmessage(value) {
    if (isSharedWorkerSupported) {
      this.ActualWorker.port.onmessage = value;
    } else {
      this.ActualWorker.onmessage = value;
    }
  }
  get onmessageerror() {
    if (isSharedWorkerSupported) {
      var _this$ActualWorker2;
      return (_this$ActualWorker2 = this.ActualWorker) === null || _this$ActualWorker2 === void 0 ? void 0 : _this$ActualWorker2.port.onmessageerror;
    } else {
      return this.ActualWorker.onmessageerror;
    }
  }
  set onmessageerror(value) {
    if (isSharedWorkerSupported) {
      this.ActualWorker.port.onmessageerror = value;
    } else {
      this.ActualWorker.onmessageerror = value;
    }
  }
  start() {
    if (isSharedWorkerSupported) {
      var _this$ActualWorker3;
      return (_this$ActualWorker3 = this.ActualWorker) === null || _this$ActualWorker3 === void 0 ? void 0 : _this$ActualWorker3.port.start();
    }
  }
  postMessage(message, transfer) {
    if (isSharedWorkerSupported) {
      var _this$ActualWorker4;
      return (_this$ActualWorker4 = this.ActualWorker) === null || _this$ActualWorker4 === void 0 ? void 0 : _this$ActualWorker4.port.postMessage(message, transfer);
    } else {
      return this.ActualWorker.postMessage(message, transfer);
    }
  }
  terminate() {
    if (isSharedWorkerSupported) {
      var _this$ActualWorker5;
      return (_this$ActualWorker5 = this.ActualWorker) === null || _this$ActualWorker5 === void 0 ? void 0 : _this$ActualWorker5.port.close();
    } else {
      return this.ActualWorker.terminate();
    }
  }
  close() {
    return this.terminate();
  }
  get port() {
    return isSharedWorkerSupported ? this.ActualWorker.port : this.ActualWorker;
  }
  get onerror() {
    return this.ActualWorker.onerror;
  }
  set onerror(value) {
    this.ActualWorker.onerror = value;
  }
  addEventListener(type, listener, options) {
    if (isSharedWorkerSupported && type !== 'error') {
      var _this$ActualWorker6;
      return (_this$ActualWorker6 = this.ActualWorker) === null || _this$ActualWorker6 === void 0 ? void 0 : _this$ActualWorker6.port.addEventListener(type, listener, options);
    } else {
      return this.ActualWorker.addEventListener(type, listener, options);
    }
  }
  removeEventListener(type, listener, options) {
    if (isSharedWorkerSupported && type !== 'error') {
      var _this$ActualWorker7;
      return (_this$ActualWorker7 = this.ActualWorker) === null || _this$ActualWorker7 === void 0 ? void 0 : _this$ActualWorker7.port.removeEventListener(type, listener, options);
    } else {
      return this.ActualWorker.removeEventListener(type, listener, options);
    }
  }
  dispatchEvent(event) {
    return this.ActualWorker.dispatchEvent(event);
  }
}
export const initSharedWorkerFallback = () => {
  if (!isSharedWorkerSupported) {
    globalThis.SharedWorker = SharedWorkerPolyfill;
  }
};