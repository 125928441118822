function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ADD_FAVOURITE_PRODUCT_SUCCESS } from 'swag-client-common/redux/favourite-products/favourite-products.actions';
import { INotificationType } from 'swag-common/interfaces/snackbar-notification-type.interface';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './snackbar.actions';
const initialState = {
  notifications: []
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return _objectSpread({}, state, {
        notifications: [...state.notifications, _objectSpread({
          key: action.payload.key
        }, action.payload.notification)]
      });
    case REMOVE_NOTIFICATION:
      return _objectSpread({}, state, {
        notifications: state.notifications.filter(notification => notification.key !== action.payload.key)
      });
    case ADD_FAVOURITE_PRODUCT_SUCCESS:
      return _objectSpread({}, state, {
        notifications: [...state.notifications, {
          key: Date.now(),
          type: INotificationType.DEFAULT,
          message: 'Added to favorites!'
        }]
      });
    default:
      return state;
  }
});