function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { isBox, isItemBoxAddon, isItemBoxSpecialCrinklePaper, isItemBoxSpecialNotecard, isItemBoxSpecialTape, isItemNotBoxAndWithoutSpecialTypes } from 'swag-common/utils/order/item-is-box.util';
import { isProductSpecialCrinklePaper, isProductSpecialNotecard, isProductWithoutSpecificTypes } from 'swag-common/utils/custom-product/specific-product.util';
export function sortBoxContentInSwagItems(items) {
  return [...items.filter(isBox), ...items.filter(isItemBoxSpecialTape), ...items.filter(isItemNotBoxAndWithoutSpecialTypes), ...items.filter(isItemBoxAddon), ...items.filter(isItemBoxSpecialCrinklePaper), ...items.filter(isItemBoxSpecialNotecard)];
}
export function sortBoxContentInCustomBox(product) {
  const {
    boxContent
  } = product;
  if (!boxContent || !boxContent.length) {
    return product;
  }
  const sortedBoxContent = [...boxContent.filter(isProductWithoutSpecificTypes), ...boxContent.filter(isProductSpecialCrinklePaper), ...boxContent.filter(isProductSpecialNotecard)];
  return _objectSpread({}, product, {
    boxContent: sortedBoxContent
  });
}
export const sortIncludingCorrectBoxOrder = items => {
  return items.reduce((acc, item) => {
    if (!item) {
      return acc;
    }
    if (item.boxItemsId && !acc.find(i => i._id === item._id)) {
      const boxItems = items.filter(i => i.boxItemsId === item.boxItemsId);
      acc.push(...sortBoxContentInSwagItems(boxItems));
      return acc;
    }
    if (item.boxItemsId && acc.find(i => i._id === item._id)) {
      return acc;
    }
    acc.push(item);
    return acc;
  }, []);
};