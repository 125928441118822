const intercomDefaultPadding = 20;
export const moveUpIntercom = () => {
  if (window.intercomSettings) {
    window.intercomSettings.vertical_padding = 120;
    Intercom('update');
  }
};
export const moveUpIntercomMobile = () => {
  if (window.intercomSettings) {
    window.intercomSettings.vertical_padding = 180;
    Intercom('update');
  }
};
export const moveDownIntercom = (padding = intercomDefaultPadding) => {
  if (window.intercomSettings) {
    window.intercomSettings.vertical_padding = padding;
    Intercom('update');
  }
};
export const setIntercomHorizontalPadding = padding => {
  if (window.intercomSettings) {
    window.intercomSettings.horizontal_padding = padding;
    Intercom('update');
  }
};
export const resetIntercomHorizontalPadding = () => {
  if (window.intercomSettings) {
    window.intercomSettings.horizontal_padding = intercomDefaultPadding;
    Intercom('update');
  }
};
export const setIntercomAlign = (isLeft = false) => {
  if (window.intercomSettings) {
    window.intercomSettings.alignment = isLeft ? 'left' : 'right';
    Intercom('update');
  }
};
export const showIntercom = () => {
  Intercom('show');
};
export const updateIntercom = () => {
  if (window.intercomSettings) {
    Intercom('update', window.intercomSettings);
  }
};
export const hideIntercom = (isHidden = false) => {
  if (window.intercomSettings) {
    if (isHidden) {
      Intercom('shutdown');
    } else {
      Intercom('update', window.intercomSettings);
    }
  }
};
export const changeProtocol = url => url.replace(/^https:\/\//i, 'http://');
export const isMobile = () => window.matchMedia('only screen and (max-width: 767px)').matches;
export const isTablet = () => window.matchMedia('only screen and (max-width: 1149px)').matches;
export const isScreenWithBigResolution = () => window.matchMedia('only screen and (min-width: 1441px)').matches;