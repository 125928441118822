import { createSelector } from 'reselect';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import getCanonicalUrl from '../../utils/get-canonical-url.utils';
import { getMetaTagsByPathname } from '../../utils/get-meta-tags-by-pathname.utils';
import { metaTagsSelector } from '../pages/pages.selectors';
export const metaSelector = createSelector(metaTagsSelector, tags => {
  const data = getMetaTagsByPathname(tags, APP_ROUTES.ALL_SWAG);
  return {
    title: data === null || data === void 0 ? void 0 : data.title,
    description: data === null || data === void 0 ? void 0 : data.description,
    canonical: getCanonicalUrl(location),
    image: data === null || data === void 0 ? void 0 : data.image,
    type: 'page'
  };
});