import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import { isMockupAvailableSelector, isNotecardReviewSelector } from '../../../../../redux/mockup-manager/mockup-manages.selectors';
import { skipBeforeApprovingMockup } from '../../../../../redux/mockup-manager/mockup-manager.actions';
import { BeforeApprovingMockupComponent } from './before-approving-mockup';
const baseContent = {
  header: 'Before approving your mockup, pay close attention',
  subHeader: 'Please make sure you have reviewed and confirmed the following:',
  rules: ['The Pantone colors are correct', 'The color of the item(s) you ordered is correct', 'The artwork is the right size and in the right place', 'The decoration method is correct'],
  description: 'As soon as you approve your mockup, we will be moving your item(s) to production. That means we will no longer be able to make any changes to your order. Please take this opportunity to confirm the quantity of item(s) and the sizes (where applicable) are also correct!'
};
const notecardContent = {
  header: 'Before approving your notecard, pay close attention!',
  subHeader: 'Please make sure you have reviewed and confirmed the following:',
  rules: ['The artwork is the right size and in the right place', 'The border and font colors are correct', 'The text on the notecard is exactly as you want it to read'],
  description: 'As soon as you approve your mockup, we will be moving your notecards to production. That means we will no longer be able to make any changes.'
};
const contentSelector = createSelector(isNotecardReviewSelector, isNotecardReview => isNotecardReview ? notecardContent : baseContent);
const mapStateToProps = createStructuredSelector({
  isMockupAvailable: isMockupAvailableSelector,
  content: contentSelector
});
const mapDispatchToProps = dispatch => ({
  onSkip: () => {
    dispatch(skipBeforeApprovingMockup());
  }
});
export const BeforeApprovingMockup = connect(mapStateToProps, mapDispatchToProps)(BeforeApprovingMockupComponent);