import _uniq from "lodash/uniq";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { DEFAULT_INVENTORY_OPTION } from 'swag-common/constants/inventory';
import { isUserInventoryOwner } from 'swag-common/business-logic/inventory/is-user-inventory-owner.logic';
import { canPullOnlyFromInventory } from 'swag-common/business-logic/inventory/permissions-checker.logic';
import { canUserManagePermissions } from 'swag-common/business-logic/inventory';
import { extendDemoInventoryWithRoles } from 'swag-common/utils/inventory/extend-demo-inventory-with-roles.util';
import { userIdSelector, userWarehouseLocationSelector } from '../user/user.selectors';
export const inventorySelector = state => state.inventory;
export const cartSelector = state => state.cart;
export const cartItemsSelector = createSelector(cartSelector, cart => cart.items);
export const inventoriesSelector = createSelector(inventorySelector, inventory => inventory.list);
export const selectedCardSelector = createSelector(inventorySelector, inventory => inventory.selectedCard);
export const selectedInventoryIdSelector = createSelector((state, props = {}) => props.customValue, inventorySelector, (customInventoryId, inventory) => customInventoryId || inventory.selectedInventoryId);
export const selectedDistributeOptionSelector = createSelector(inventorySelector, inventory => inventory.selectedDistributeOption || null);
export const selectedDistributionAreaSelector = createSelector(inventorySelector, inventory => {
  return inventory.selectedDistributionArea || null;
});
export const addressSummaryInventoryNameSelector = createSelector(inventoriesSelector, selectedInventoryIdSelector, cartItemsSelector, (inventories, selectedInvId, items) => {
  const defaultInventory = 'My inventory';
  const selectedInventory = inventories.find(inv => inv._id === selectedInvId);
  const inventoriesWithProducts = Object.keys(items.filter(i => i.inventoryId).reduce((all, i) => _objectSpread({}, all, {
    [i.inventoryId]: i.inventoryId
  }), {}));
  if (inventoriesWithProducts.length) {
    const severalInventoriesNames = inventoriesWithProducts.reduce((name, id) => {
      const inventory = inventories.find(inv => inv._id === id);
      if (inventory) {
        return `${name}${name && ','} ${inventory.name}`;
      }
      return name;
    }, '');
    return severalInventoriesNames || defaultInventory;
  }
  return selectedInventory ? selectedInventory.name : defaultInventory;
});
export const deliveryMustBeDoneToInventoriesIdsSelector = createSelector(cartItemsSelector, items => {
  const inventoriesIds = [];
  const inventoriesWithProducts = Object.keys(items.filter(i => i.inventoryId).reduce((all, i) => _objectSpread({}, all, {
    [i.inventoryId]: i.inventoryId
  }), {}));
  if (inventoriesWithProducts.length) {
    inventoriesIds.push(...inventoriesWithProducts);
  }
  return _uniq(inventoriesIds);
});
export const userHasOneOwnInventorySelector = createSelector(inventoriesSelector, userIdSelector, (inventories, userId) => userId && inventories.length === 1 && inventories.every(inventory => isUserInventoryOwner(inventory, userId)));
export const hasUserOwnNonStoreInventorySelector = createSelector(inventoriesSelector, userIdSelector, (inventories, userId) => inventories.some(({
  ownerId
}) => ownerId === userId));
export const ownInventoriesSelector = createSelector(userIdSelector, inventoriesSelector, (userId, inventories) => inventories.filter(inv => inv.ownerId === userId));
export const sharedInventoriesSelector = createSelector(userIdSelector, inventoriesSelector, (userId, inventories) => inventories.filter(inv => inv.ownerId !== userId));
export const demoInventoryIdSelector = createSelector(inventorySelector, inventory => inventory.demoInventory ? inventory.demoInventory._id : null);
export const demoInventoryNotExtendedSelector = createSelector(inventorySelector, inventory => inventory.demoInventory ? inventory.demoInventory : null);
export const stateUserInventoryToShowSelector = createSelector(inventorySelector, demoInventoryIdSelector, (inventory, demoInventoryId) => inventory.lastInventoryId || demoInventoryId);
export const demoInventorySelector = createSelector(demoInventoryNotExtendedSelector, userIdSelector, inventoriesSelector, (demoInventory, userId, list) => {
  return demoInventory && userId ? extendDemoInventoryWithRoles(demoInventory, list, userId) : null;
});
export const selectedFullInventorySelector = createSelector(inventoriesSelector, selectedInventoryIdSelector, demoInventorySelector, (inventories, id, demoInventory) => {
  return inventories.find(({
    _id
  }) => _id === id) || demoInventory;
});
export const isInventoryAdminSelector = createSelector(selectedFullInventorySelector, userIdSelector, (inventory, userId) => {
  return inventory && userId && canUserManagePermissions(inventory, userId);
});
export const userLastInventoryIdSelector = createSelector(inventorySelector, userIdSelector, inventoriesSelector, ({
  lastInventoryId
}, userId, list) => {
  const listWithPutRightInventories = list.filter(i => !canPullOnlyFromInventory(i, userId));
  if (listWithPutRightInventories.length) {
    const lastInventoryObject = listWithPutRightInventories.find(i => i._id === lastInventoryId);
    return lastInventoryObject ? lastInventoryId : null;
  }
  return null;
});
export const hasOnlySharedInventoriesSelector = createSelector(ownInventoriesSelector, sharedInventoriesSelector, (ownInventories, sharedInventories) => ownInventories.length === 0 && sharedInventories.length > 0);
export const hasDistributionAreaOrWarehouseLocation = createSelector(inventorySelector, inventoriesSelector, selectedInventoryIdSelector, userWarehouseLocationSelector, (inventory, inventories, selectedId, userWarehouse) => {
  if (userWarehouse) {
    return true;
  }
  const selectedInventory = inventories.find(({
    _id
  }) => _id === selectedId);
  if (selectedInventory && !selectedInventory.distributionArea) {
    return Boolean(inventory.selectedDistributionArea) || Boolean(selectedInventory.warehouseLocation);
  }
  return Boolean(inventory.selectedDistributionArea) || null;
});
export const defaultSelectedInventoryIdSelector = createSelector(hasOnlySharedInventoriesSelector, sharedInventoriesSelector, userLastInventoryIdSelector, (hasOnlySharedInventories, sharedInventories, lastInventoryId) => {
  if (lastInventoryId) {
    return lastInventoryId;
  }
  if (hasOnlySharedInventories) {
    return sharedInventories[0]._id;
  }
  return DEFAULT_INVENTORY_OPTION.value;
});
export const inventoriesPaymentSettingsSelector = createSelector(inventorySelector, inventory => inventory.settings);
export const isInventorySelector = state => state.checkout.deliverToFulfillmentCenter;
export const hasInventoryUnderApprovalFlowSelector = createSelector(inventoriesSelector, inventories => {
  return inventories.some(inv => inv.isRoleApprovalEnabled || inv.isUserApprovalEnabled);
});