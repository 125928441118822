import dateFormat from 'dateformat';
const DATE_FORMAT = 'mm/dd/yyyy HH:MM';
const formatting = {
  month: 'short',
  day: 'numeric',
  timeZone: 'UTC'
};
const prefixFormat = {
  '1': 'st',
  '2': 'nd',
  '3': 'rd',
  '21': 'st',
  '22': 'nd',
  '23': 'rd',
  '31': 'st'
};
export const toLocalDateStingFormatter = (date, config) => date.toLocaleDateString('en-US', config);
export const prepareDateFormatISOlike = startDate => {
  const [year, month, day] = startDate.split('-');
  return new Date(Date.UTC(+year, +month - 1, +day, 0, 0, 0, 0));
};

// 2022-01-02T00:00:00.000Z => 2022-0-2;
export const convertISODateToJsDate = date => {
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
};

// 2022-01-02T00:00:00.000Z => 12345678987654321;
export const convertISOStringToTimestamp = ISOString => new Date(ISOString).getTime();
export const getTodayUTCDate = () => {
  const today = new Date();
  return new Date(Date.UTC(+today.getUTCFullYear(), +today.getUTCMonth(), +today.getUTCDate(), 0, 0, 0, 0));
};
export const toDateFormat = (date, format = DATE_FORMAT) => {
  return dateFormat(date, format);
};
const getDayPrefix = day => prefixFormat[day] || 'th';
export const dateFormatter = date => {
  const formatted = toLocalDateStingFormatter(date, formatting);
  const day = date.getUTCDate();
  const prefix = getDayPrefix(String(day));
  return `${formatted}${prefix}`;
};
export const dateFormatterWithYear = date => {
  const isCurrentYearUTC = date.getUTCFullYear() === new Date().getUTCFullYear();
  return isCurrentYearUTC ? dateFormatter(date) : `${dateFormatter(date)}, ${date.getUTCFullYear().toString().slice(2)}`;
};
export const toDateForEmailSubjectFormatter = (date = new Date()) => {
  const dateWithTZ = date.toLocaleString('en-US', {
    timeZone: 'America/New_York'
  });
  return dateFormat(dateWithTZ, 'mmm dS yyyy hh:MM TT');
};
export const toUTCDateFormatter = date => {
  const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
  return new Date(utcDate).toISOString();
};
export const toESTDateFormatter = date => new Date(new Date(date).toLocaleString('en-US', {
  timeZone: 'America/New_York'
}));
export const formatTo_MMM_Do = date => {
  const mmm_d = dateFormat(date, 'mmm:d');
  const [mmm, d] = mmm_d.split(':');
  return `${mmm} ${d}${getDayPrefix(d)}`;
};
export const formatTo_mm_dd_yyyy = date => {
  return dateFormat(date, 'mm/dd/yyyy');
};
export const convertDateToHistoryFormat = date => {
  return dateFormat(date, new Date().getFullYear() === new Date(date).getFullYear() ? 'mmm dS - h:MMtt' : 'yyyy mmm dS - h:MMtt');
};