import { instantScrollTop } from 'swag-client-common/utils/tricks';
import { requestChat } from '../../../../../redux/common/common.actions';
import { resetPageNumber } from '../../../../../redux/product-listing/product-listing.actions';
export default function (dispatch) {
  return {
    onChatClick: () => dispatch(requestChat()),
    onFullProductsLinkClick: () => {
      dispatch(resetPageNumber());
      instantScrollTop();
    }
  };
}