const DEFAULT_CATEGORY_ID = 'uncategorized';
const DEFAULT_CATEGORY_NAME = 'Uncategorized';
const DEFAULT_AWARDS_PRICE_RANGES = [{
  id: 'PRICE_UP_TO_250',
  min: 0,
  max: 249.99
}, {
  id: 'PRICE_MORE_THEN_250',
  min: 250,
  max: Infinity
}];
const FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME = 'custom-design';
const FULL_DESIGN_PRODUCT_DEFAULT_COLOR_VALUE = '#fff';
const STORAGE_WEIGHT_CATEGORIES = [{
  min: 0,
  max: 0.0625,
  pricePerProduct: 0
}, {
  min: 0.0626,
  max: Infinity,
  pricePerProduct: 1
}];
const OLD_WEIGHT_THRESHOLD = 0.1875;
const CUSTOM_STORE_ORDERS_PAGE_URL = 'https://swag.com/custom-store/orders';
const PAYMENT_STATUSES = {
  PAID: 'paid',
  PENDING_TRANSACTION: 'pending transaction',
  PENDING_APPROVAL: 'pending approval',
  REJECTED: 'rejected'
};
const SHIPMENT_TYPES = {
  ORDER: 'ORDER',
  SWAG_ITEM: 'SWAG_ITEM',
  SHIPOUT: 'SHIPOUT',
  GIVEAWAY: 'GIVEAWAY',
  PRODUCT_SOURCING_ITEM: 'PRODUCT_SOURCING_ITEM'
};
const ROOT_PRODUCT_PREFIX = 'root_product';
export { DEFAULT_CATEGORY_ID, DEFAULT_CATEGORY_NAME, DEFAULT_AWARDS_PRICE_RANGES, FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME, FULL_DESIGN_PRODUCT_DEFAULT_COLOR_VALUE, STORAGE_WEIGHT_CATEGORIES, CUSTOM_STORE_ORDERS_PAGE_URL, PAYMENT_STATUSES, SHIPMENT_TYPES, ROOT_PRODUCT_PREFIX, OLD_WEIGHT_THRESHOLD };