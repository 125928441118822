import _differenceBy from "lodash/differenceBy";
import _getFormValues from "redux-form/es/getFormValues";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ADD_ITEM_TO_CART, REMOVE_ITEM_FROM_CART_SUCCESS } from 'swag-client-common/redux/cart/cart.actions';
import { calculateOrderShipmentByItems, getTaxes } from 'swag-common/utils/order';
import { Price } from 'swag-common/utils/price';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { GOOGLE_TAG_MANAGER_EVENT, mapActionTypeToFormAndLandingName, GOOGLE_TAG_MANAGER_DEFAULT_VALUES } from '../../common/constants/google-tag-manager';
import { OPEN_CART_PAGE, OPEN_DELIVERY_PAGE } from '../redux/cart/cart.actions';
import { cartItemsSelector, absoluteDiscountValueSelector } from '../redux/cart/cart.selectors';
import { OPEN_CHECKOUT_PAGE, PAYMENT_POPUP_OPEN, PROCEED_ORDER_SUCCESS } from '../redux/checkout/checkout.actions';
import { REQUEST_SUCCESS } from '../redux/contact/contact.actions';
import { SUBMIT_SUCCESS } from '../redux/custom-swag/custom-swag.actions';
import { REQUEST_AUTOMATION_CONTACT_SUCCESS, REQUEST_ENTERPRISE_SUCCESS, REQUEST_GIVEAWAY_CONTACT_SUCCESS, REQUEST_IN_A_BOX_CONTACT_SUCCESS, REQUEST_SHOPIFY_CONTACT_SUCCESS, REQUEST_ZAPIER_CONTACT_SUCCESS } from '../redux/landings/landings.actions';
import { FIRST_LOGO_UPLOAD } from '../redux/logo/logo.actions';
import { categoriesMapSelector } from '../redux/product-listing/product-listing.selectors';
import { FETCH_PRODUCT_SUCCESS } from '../redux/product/product.actions';
import { isRebuildModeSelector } from '../redux/product/product.selectors';
import { REQUEST_DEMO_SUCCESS } from '../redux/send-email/send-email.action';
import { getItemPositionInTheCart } from '../utils/google-tag-manager/get-item-position-in-the-cart.util';
import { getItemsForGoogleTagManager, getItemsToSend, getValue } from '../utils/google-tag-manager/get-items-for-google-tag-manager.util';
import { getProductForGoogleTagManager } from '../utils/google-tag-manager/get-product-for-google-tag-manager.util';
import { handleSomeFormDates } from '../utils/google-tag-manager/handle-some-form-date';
import { SEARCH_SUCCESS_GA } from '../redux/search-products/search-products.actions';
export const googleTagManagerMiddleware = ({
  getState
}) => {
  return next => action => {
    try {
      switch (action.type) {
        case REQUEST_ENTERPRISE_SUCCESS:
        case REQUEST_IN_A_BOX_CONTACT_SUCCESS:
        case REQUEST_GIVEAWAY_CONTACT_SUCCESS:
        case REQUEST_SHOPIFY_CONTACT_SUCCESS:
        case REQUEST_AUTOMATION_CONTACT_SUCCESS:
        case REQUEST_ZAPIER_CONTACT_SUCCESS:
        case SUBMIT_SUCCESS:
        case REQUEST_DEMO_SUCCESS:
        case REQUEST_SUCCESS:
          {
            const formValues = _getFormValues(mapActionTypeToFormAndLandingName[action.type].formName)(getState());
            const updatedFormValues = handleSomeFormDates(action.type, formValues);
            dataLayer.push(_objectSpread({
              event: GOOGLE_TAG_MANAGER_EVENT.FORM_SUBMIT,
              'landing page': mapActionTypeToFormAndLandingName[action.type].landingPageName
            }, updatedFormValues));
            break;
          }
        case FETCH_PRODUCT_SUCCESS:
          {
            const isRebuild = isRebuildModeSelector(getState());
            if (isRebuild) {
              break;
            }
            const product = action.payload;
            const categoriesMap = categoriesMapSelector(getState());
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.VIEW_ITEM,
              ecommerce: {
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                value: '',
                items: [getProductForGoogleTagManager(product, Object.values(categoriesMap))]
              }
            });
            break;
          }
        case ADD_ITEM_TO_CART:
          {
            const categoriesMap = categoriesMapSelector(getState());
            const itemsInCart = cartItemsSelector(getState());
            const {
              order: {
                items: updatedItems
              }
            } = action.body;
            const newlyItems = _differenceBy(updatedItems, itemsInCart, '_id');
            const items = getItemsToSend(newlyItems, categoriesMap, GOOGLE_TAG_MANAGER_EVENT.ADD_TO_CART);
            const value = getValue(newlyItems);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.ADD_TO_CART,
              ecommerce: {
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                value,
                items
              }
            });
            break;
          }
        case OPEN_CART_PAGE:
          {
            const itemsInCart = cartItemsSelector(getState());
            const categoriesMap = categoriesMapSelector(getState());
            const value = getValue(itemsInCart);
            const items = getItemsToSend(itemsInCart, categoriesMap);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.VIEW_CART,
              ecommerce: {
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                value,
                items
              }
            });
            break;
          }
        case REMOVE_ITEM_FROM_CART_SUCCESS:
          {
            const deletedItem = action.body.deletedItem;
            const deletedItemIndex = action.body.deletedItemIndex;
            const categoriesMap = categoriesMapSelector(getState());
            const itemsToRemove = getItemsForGoogleTagManager(deletedItem, getItemPositionInTheCart(deletedItemIndex), Object.values(categoriesMap));
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.REMOVE_FROM_CART,
              ecommerce: {
                itemsToRemove
              }
            });
            break;
          }
        case OPEN_DELIVERY_PAGE:
          {
            const itemsInCart = cartItemsSelector(getState());
            const categoriesMap = categoriesMapSelector(getState());
            const absoluteDiscountValue = absoluteDiscountValueSelector(getState());
            const items = getItemsToSend(itemsInCart, categoriesMap);
            const value = getValue(itemsInCart);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.BEGIN_CHECKOUT,
              ecommerce: {
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                value,
                coupon: Price.formatNumber(absoluteDiscountValue),
                items
              }
            });
            break;
          }
        case OPEN_CHECKOUT_PAGE:
          {
            const itemsInCart = cartItemsSelector(getState());
            const categoriesMap = categoriesMapSelector(getState());
            const absoluteDiscountValue = absoluteDiscountValueSelector(getState());
            const value = getValue(itemsInCart);
            const items = getItemsToSend(itemsInCart, categoriesMap);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.ADD_SHIPPING_INFO,
              ecommerce: {
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                value,
                coupon: Price.formatNumber(absoluteDiscountValue),
                items
              }
            });
            break;
          }
        case PAYMENT_POPUP_OPEN:
          {
            const itemsInCart = cartItemsSelector(getState());
            const categoriesMap = categoriesMapSelector(getState());
            const items = getItemsToSend(itemsInCart, categoriesMap);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.CHECKOUT_PAYMENT_INFO,
              ecommerce: {
                items
              }
            });
            break;
          }
        case FIRST_LOGO_UPLOAD:
          {
            const {
              product: {
                _id,
                categoryName
              }
            } = getState().product;
            dataLayer.push({
              event: 'Upload Image',
              'Product ID': _id,
              'Product Category': categoryName
            });
            break;
          }
        case PROCEED_ORDER_SUCCESS:
          {
            const {
              order
            } = action.body;
            const categoriesMap = categoriesMapSelector(getState());
            const absoluteDiscountValue = absoluteDiscountValueSelector(getState());
            const items = getItemsToSend(order.items, categoriesMap, GOOGLE_TAG_MANAGER_EVENT.PURCHASE);
            const shipping = calculateOrderShipmentByItems(order);
            const tax = getTaxes(order);
            const value = getValue(order.items);
            dataLayer.push({
              ecommerce: null
            });
            dataLayer.push({
              event: GOOGLE_TAG_MANAGER_EVENT.PURCHASE,
              ecommerce: {
                transaction_id: order._id.toString(),
                value,
                tax: Price.formatNumber(tax),
                shipping: Price.formatNumber(shipping),
                currency: GOOGLE_TAG_MANAGER_DEFAULT_VALUES.CURRENCY,
                coupon: Price.formatNumber(absoluteDiscountValue),
                items
              }
            });
            break;
          }
        case SEARCH_SUCCESS_GA:
          {
            const {
              matchByName = [],
              matchByCategory = [],
              matchByTag = [],
              query,
              isQuickSearch
            } = action.payload;
            if (query.trim().length < 3) {
              break;
            }
            const productsList = [...matchByName, ...matchByCategory, ...matchByTag].slice(0, 6).map(({
              name,
              seoSlug
            }) => ({
              url: `${APP_ROUTES.PRODUCT}/${seoSlug}`,
              name
            }));
            const formattedQuery = query.replace(' ', '+');
            const event = isQuickSearch ? GOOGLE_TAG_MANAGER_EVENT.SEARCH_RESULTS_QUICK_SEARCH : GOOGLE_TAG_MANAGER_EVENT.SEARCH_RESULTS_SEARCH_PAGE;
            dataLayer.push({
              event,
              ecommerce: {
                query: `/search/?query=${formattedQuery}`,
                items: productsList
              }
            });
            break;
          }
        default:
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    next(action);
  };
};