function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { commonUserReducer, newUserDataReducer, initialState } from 'swag-client-common/redux/user/user.reducer';
import { LOGOUT_SUCCESS, CLIENT_LOGIN_SUCCESS } from 'swag-client-common/redux/user/user.actions';
import { HANDLE_LOGIN_FAIL } from 'swag-client-common/redux/user/user.actions';
import { SET_PASSWORD_SUCCESS } from '../set-password/set-password.actions';
import { VERIFY_SUCCESS } from '../account-verification/account-verification.actions';
export const initialStateCustomer = _objectSpread({}, initialState, {
  isLoggedOut: false
});
export default function userReducer(state = initialStateCustomer, action) {
  if ([VERIFY_SUCCESS, SET_PASSWORD_SUCCESS].includes(action.type)) {
    return newUserDataReducer(state, action);
  }
  if (action.type === CLIENT_LOGIN_SUCCESS && state.isLoggedOut === true) {
    return _objectSpread({}, state, {
      isLoggedOut: false
    });
  }
  if (action.type === LOGOUT_SUCCESS) {
    return _objectSpread({}, state, {
      isLoggedOut: true
    });
  }
  if (action.type === HANDLE_LOGIN_FAIL) {
    return _objectSpread({}, state, {
      loginFailureCode: action.payload
    });
  }
  return commonUserReducer(state, action);
}