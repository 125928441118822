import { isCancelledItem } from 'swag-common/utils/order';
import { getDataForGettingItemShippingCost, getItemShippingCost } from './order/get-item-shipping-cost.service';
const getRelatedItemsPricing = (items, {
  withShipping = false
} = {}) => {
  const {
    setupFee,
    setupFeeDeductionDiscount
  } = items[0];
  const filteredItems = items.filter(item => !isCancelledItem(item));
  let total = filteredItems.reduce((price, next) => price + next.price, 0);
  if (withShipping) {
    const totalShipping = filteredItems.reduce((shippingPrice, next) => getItemShippingCost(getDataForGettingItemShippingCost(next, filteredItems)) + shippingPrice, 0);
    total += totalShipping;
  }
  const isRelated = items.length > 1;
  let oldSetupFee = 0;
  if (setupFeeDeductionDiscount) {
    oldSetupFee = setupFeeDeductionDiscount + setupFee;
  }
  return {
    total,
    isRelated,
    setupFee,
    oldSetupFee
  };
};
export default getRelatedItemsPricing;