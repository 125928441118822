import { getProductVariantImage } from 'swag-common/utils/product/get-product-variant-image.util';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { getCustomStoreProductDefaultImage } from 'swag-common/utils/custom-store-product/get-custom-store-product-default-image.util';
export function getDefaultImageFromItem(item) {
  if (!item) {
    return '';
  }
  const {
    customerMockups,
    prod,
    variants
  } = item;
  if (!prod) {
    return '';
  }
  if (prod.specialProductType === SPECIAL_PRODUCT_TYPES.BOX_CRINKLE_PAPER || prod.specialProductType === SPECIAL_PRODUCT_TYPES.COLORED_BOX) {
    const variant = prod.variants[variants[0].variantIndex];
    return getProductVariantImage({
      product: {
        variants: [variant]
      }
    }).url;
  }
  if (!customerMockups || !Object.keys(customerMockups).length) {
    var _prod$logo;
    return (_prod$logo = prod === null || prod === void 0 ? void 0 : prod.logo) !== null && _prod$logo !== void 0 ? _prod$logo : '';
  }
  const mockups = Object.values(customerMockups);
  return getCustomStoreProductDefaultImage(mockups);
}