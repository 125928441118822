function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import ProductApi from '../../api/product.api';
import { isSearchResultsShownSelector } from './search-products.selectors';
const PREFIX = 'SEARCH_PRODUCTS_';
export const SEARCH_REQUEST = `${PREFIX}SEARCH_REQUEST`;
export const SEARCH_SUCCESS = `${PREFIX}SEARCH_SUCCESS`;
export const SEARCH_ERROR = `${PREFIX}SEARCH_ERROR`;
const LIMIT = 6;
export const SEARCH_SUCCESS_GA = `${PREFIX}SEARCH_SUCCESS_GA`;
export const searchProducts = createAsyncAction({
  preFn: (dispatch, _, data) => {
    if (data.query === '') {
      dispatch(clearSearchResults());
      return true;
    }
    dispatch({
      type: SEARCH_REQUEST,
      payload: {
        query: data.query
      }
    });
  },
  transformData: data => {
    return _objectSpread({}, data, {
      limit: LIMIT
    });
  },
  takeLatest: true,
  fn: ProductApi.search,
  success: body => ({
    type: SEARCH_SUCCESS,
    payload: body
  }),
  postSuccess(dispatch, getState, transformedData, res) {
    const isQuickSearch = isSearchResultsShownSelector(getState());
    if (isQuickSearch) {
      dispatch({
        type: SEARCH_SUCCESS_GA,
        payload: _objectSpread({}, res, {
          query: transformedData.query,
          isQuickSearch: true
        })
      });
    }
  },
  error: errorAction(SEARCH_ERROR)
});
export const CLEAR_RESULTS = `${PREFIX}CLEAR_RESULTS`;
export const clearSearchResults = () => ({
  type: CLEAR_RESULTS
});
export const GO_TO_SEARCH_RESULTS_PAGE = `${PREFIX}GO_TO_SEARCH_RESULTS_PAGE`;
export const goToSearchResultsPage = query => ({
  type: GO_TO_SEARCH_RESULTS_PAGE,
  payload: {
    query
  }
});
export const EDIT_QUERY = `${PREFIX}EDIT_QUERY`;
export const editQuery = query => ({
  type: EDIT_QUERY,
  payload: query
});
export const SET_PRODUCT_ID_TO_URL = `${PREFIX}SET_PRODUCT_ID_TO_URL`;
export const setProductIdToUrl = id => ({
  type: SET_PRODUCT_ID_TO_URL,
  payload: {
    id
  }
});
export const OPEN_PRODUCT_FROM_CATEGORY_ENABLE = `${PREFIX}OPEN_PRODUCT_FROM_CATEGORY_ENABLE`;
export const openProductFromCategoryEnable = () => ({
  type: OPEN_PRODUCT_FROM_CATEGORY_ENABLE
});
export const OPEN_PRODUCT_FROM_CATEGORY_DISABLE = `${PREFIX}OPEN_PRODUCT_FROM_CATEGORY_DISABLE`;
export const openProductFromCategoryDisable = () => ({
  type: OPEN_PRODUCT_FROM_CATEGORY_DISABLE
});
export const CHANGE_ACTIVE_STATE = `${PREFIX}CHANGE_ACTIVE_STATE`;
export const changeActiveState = isActive => ({
  type: CHANGE_ACTIVE_STATE,
  payload: isActive
});
export const CHANGE_IS_SEARCH_RESULTS_SHOWN = `${PREFIX}CHANGE_IS_SEARCH_RESULTS_SHOWN`;
export const changeIsSearchResultsShown = isSearchResultsShown => ({
  type: CHANGE_IS_SEARCH_RESULTS_SHOWN,
  payload: isSearchResultsShown
});
export const GET_SEARCH_SUGGESTIONS_SUCCESS = `${PREFIX}GET_SEARCH_SUGGESTIONS_SUCCESS`;
export const getSearchSuggestionsSuccess = payload => ({
  type: GET_SEARCH_SUGGESTIONS_SUCCESS,
  payload
});
export const GET_SEARCH_SUGGESTIONS_FAILURE = `${PREFIX}GET_SEARCH_SUGGESTIONS_FAILURE`;
export const getSearchSuggestionsFailure = () => ({
  type: GET_SEARCH_SUGGESTIONS_FAILURE
});