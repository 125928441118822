import { Price } from '../price';
import { checkIsOrderCreationMode } from '../reseller/check-is-reseller-admin-mode.util';
export const getCartItemsPrice = items => items.reduce((all, item) => all + item.price, 0);
export const orderTotalPrice = (totalBeforeDiscount, discount, swagSpaceOrderTotal, resellerAdminMode) => {
  if (checkIsOrderCreationMode(resellerAdminMode)) {
    return swagSpaceOrderTotal || 0;
  }
  if (discount > totalBeforeDiscount) {
    return 0;
  }
  return totalBeforeDiscount - discount;
};
export const getTotalBeforeDiscount = (production, shipping, taxes, ksFulfillment) => production + shipping + taxes + ksFulfillment;
export const getAbsoluteDiscount = (absoluteDiscountValue, totalBeforeDiscount, onlyDeductionsValue) => {
  const maxPossiblePromocodeDiscount = totalBeforeDiscount - onlyDeductionsValue;
  return Price.getDiscountLessThanTotal(maxPossiblePromocodeDiscount, absoluteDiscountValue);
};