import _debounce from "lodash/debounce";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { browserHistory } from 'react-router';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { isMobile, isTablet } from '../../utils/ui.utils';
import { searchProducts as searchProductsForResultsPage, getProductListingFilterData, fetchCategories, resetFilter, resetSorting, resetProducts } from '../search-results/search-results.actions';
import { blockPage } from '../common/common.actions';
import { searchProducts, clearSearchResults, editQuery, goToSearchResultsPage, changeActiveState, changeIsSearchResultsShown } from './search-products.actions';
const isSmallScreenView = isMobile() || isTablet();
export const searchProductsDispatcher = dispatch => {
  const debouncedDispatch = _debounce(dispatch, 700);
  return {
    onSearch: value => {
      dispatch(changeActiveState(isSmallScreenView || !!value.length));
      dispatch(editQuery(value));
      dispatch(changeIsSearchResultsShown(!!value.length));
      debouncedDispatch(searchProducts({
        query: value
      }));
    },
    onGoToSearchResultsPage: query => {
      dispatch(goToSearchResultsPage(query));
      if (isSmallScreenView) {
        dispatch(clearSearchResults());
      }
      dispatch(changeIsSearchResultsShown(false));
      dispatch(changeActiveState(!isSmallScreenView));
      dispatch(blockPage(false));
      const searchQuery = `?query=${encodeURIComponent(query)}`;
      if (window.location.pathname === APP_ROUTES.SEARCH_RESULTS) {
        dispatch(resetFilter());
        dispatch(searchProductsForResultsPage({
          query,
          page: 1
        }));
        dispatch(fetchCategories());
        const currentLocation = browserHistory.getCurrentLocation();
        browserHistory.replace(_objectSpread({}, currentLocation, {
          search: searchQuery,
          query: _objectSpread({}, currentLocation.query, {
            query
          })
        }));
      } else {
        dispatch(getProductListingFilterData());
        dispatch(fetchCategories());
        dispatch(resetFilter());
        dispatch(resetSorting());
        dispatch(resetProducts());
        browserHistory.push(`${APP_ROUTES.SEARCH_RESULTS}${searchQuery}`);
      }
    },
    onLinkClick: () => {
      dispatch(clearSearchResults());
      if (isSmallScreenView) {
        debouncedDispatch(searchProducts({
          query: ''
        }));
      }
    },
    onFocus: isPageBlocked => {
      dispatch(blockPage(isPageBlocked));
      dispatch(changeActiveState(true));
      dispatch(changeIsSearchResultsShown(true));
    },
    onPageScrollBlock: isPageBlocked => {
      dispatch(blockPage(isPageBlocked));
    },
    onClear: () => {
      dispatch(changeIsSearchResultsShown(false));
    },
    onDeactivate: query => {
      if (window.location.pathname !== APP_ROUTES.SEARCH_RESULTS || !query) {
        dispatch(changeActiveState(false));
      }
      if (isSmallScreenView) {
        dispatch(changeActiveState(false));
      }
      dispatch(changeIsSearchResultsShown(false));
    }
  };
};