import _isEmpty from "lodash/isEmpty";
import getBoxVariantSizeName from 'swag-common/business-logic/custom-store-product/get-box-variant-size-name.logic';
export const getInventoryProductsDetails = (products, productsMap) => {
  return products.map(product => {
    const {
      _id,
      name,
      colorName,
      colorValue,
      sizeSettings,
      sizeIndex,
      shipoutQuantity,
      parentId,
      isProductSourcing
    } = product;
    const swagProduct = product.swagProductId;
    const boxSize = getBoxVariantSizeName(product);
    const hasSize = sizeSettings && ((swagProduct === null || swagProduct === void 0 ? void 0 : swagProduct.hasSize) || isProductSourcing && !_isEmpty(sizeSettings === null || sizeSettings === void 0 ? void 0 : sizeSettings.options));
    const productSize = hasSize ? sizeSettings === null || sizeSettings === void 0 ? void 0 : sizeSettings.options[sizeIndex] : null;
    const quantity = productsMap ? productsMap[String(_id)] : shipoutQuantity;
    return {
      _id: String(_id),
      name,
      colorName,
      colorValue,
      quantity: quantity || 1,
      size: boxSize || productSize || '',
      parentId: String(parentId)
    };
  });
};
export const getInventoryGoodieProductsDetails = goodies => {
  return goodies.map(({
    productId,
    quantity
  }) => ({
    _id: String(productId === null || productId === void 0 ? void 0 : productId._id),
    name: (productId === null || productId === void 0 ? void 0 : productId.name) || '',
    colorName: '',
    colorValue: '',
    quantity: quantity || 1,
    size: '',
    parentId: null
  }));
};