function pickSkuForLineItem(product, variantsToOrder) {
  const {
    variants,
    num
  } = product;
  const variantToOrderWithSku = variants.find(({
    productSku
  }, index) => !!productSku && variantsToOrder.some(({
    variantIndex
  }) => index === variantIndex));
  const variantSku = variantToOrderWithSku && variantToOrderWithSku.productSku;
  return variantSku || num;
}
export default pickSkuForLineItem;