import _isFinite from "lodash/isFinite";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { INVENTORY_UPSELL_STATUSES } from 'swag-common/constants/order-statuses.constants';
import { Price } from 'swag-common/utils/price';
const getIsValueValidNumber = value => Boolean(value === 0 || value);
export const getInventoryUpsellPresentationData = ({
  upsellData,
  currentQuantity,
  item,
  wasQuantityForUpsellUpdated
}) => {
  var _item$prod;
  const {
    newQuantity: currentPriceBreak,
    initialPriceBreak
  } = upsellData;
  const quantityWasUpdated = currentQuantity !== item.quantity;
  const defaultStatus = item.inventoryUpsellStatus || quantityWasUpdated ? INVENTORY_UPSELL_STATUSES.INCREASE : null;
  const minQuant = (item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.minQuant) || 1;
  const isLessThanMinQuantity = (upsellData.totalQuantityPerProduct || currentQuantity) < minQuant;
  const increaseData = {
    upsellStatus: defaultStatus,
    newTargetPrice: upsellData.newItemPrice ? Price.formatPrice(upsellData.newItemPrice) : null,
    priceBreakQuantity: String(currentPriceBreak),
    newItemPrice: Price.formatPrice(upsellData.newItemPrice),
    currentItemPrice: Price.formatPrice(upsellData.currentItemPrice),
    quantityWasUpdated
  };
  if (isLessThanMinQuantity) {
    return _objectSpread({}, increaseData, {
      priceBreakQuantity: String(initialPriceBreak || 0)
    });
  }
  const appliedData = {
    newTargetPrice: null,
    priceBreakQuantity: null,
    upsellStatus: INVENTORY_UPSELL_STATUSES.APPLIED,
    newItemPrice: Price.formatPrice(upsellData.newItemPrice),
    currentItemPrice: Price.formatPrice(upsellData.currentItemPrice),
    quantityWasUpdated
  };
  const isUpsellAppliedOnServer = item.inventoryUpsellStatus === INVENTORY_UPSELL_STATUSES.APPLIED;
  if (!getIsValueValidNumber(currentPriceBreak) || !wasQuantityForUpsellUpdated && isUpsellAppliedOnServer ||
  // for displaying a box when there no price break
  !currentPriceBreak) {
    return appliedData;
  }
  const maximalPriceBreak = upsellData.priceBreaks ? Math.max(...upsellData.priceBreaks) : 0;

  // if for quantity in DB maximal price break is reached
  if (!_isFinite(initialPriceBreak) && maximalPriceBreak && currentQuantity < maximalPriceBreak) {
    return _objectSpread({}, increaseData, {
      priceBreakQuantity: String(maximalPriceBreak)
    });
  }
  return getIsValueValidNumber(initialPriceBreak) && currentPriceBreak > initialPriceBreak ? appliedData : increaseData;
};