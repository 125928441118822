import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
export default function getBoxVariantSizeName(variant) {
  const {
    boxContent
  } = variant;
  if (!boxContent) {
    return '';
  }
  const sizeData = getBoxVariantSizeIndex(variant);
  if (!sizeData) {
    return '';
  }
  const {
    sizeSettings,
    sizeIndex
  } = sizeData;
  return (sizeSettings === null || sizeSettings === void 0 ? void 0 : sizeSettings.options[sizeIndex]) || '';
}
export function getBoxVariantSizeIndex(variant) {
  if (!isBox(variant.swagProductId)) {
    const {
      sizeSettings,
      sizeIndex
    } = variant;
    return {
      sizeSettings,
      sizeIndex
    };
  }
  const {
    boxContent
  } = variant;
  if (!boxContent) {
    return null;
  }
  const firstSizedContent = boxContent.find(({
    product
  }) => {
    const {
      sizeSettings
    } = product;
    const swagProduct = product.swagProductId;
    return sizeSettings && sizeSettings.options && !swagProduct.sizeDependentPrice && swagProduct.hasSize;
  });
  if (!firstSizedContent) {
    return null;
  }
  const {
    sizeSettings,
    sizeIndex
  } = firstSizedContent.product;
  return {
    sizeSettings,
    sizeIndex
  };
}