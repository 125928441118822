import { DesignUploadMode } from 'swag-common/interfaces';
import { transformProductSettingsForFeature } from 'swag-common/utils/product-settings/transform-product-settings-for-feature';
import { Price } from './price';
export const getBiggestScreenPriceByColorAmount = (items, products, featureFlags) => {
  if (products.length === 1) {
    return 0;
  }
  const screenPrices = products.map(product => {
    var _productSettings$0$sc, _productSettings$;
    if (!product || product.designUploadMode === DesignUploadMode.box) {
      return 0;
    }
    const foundItem = items.find(item => item.prodId === String(product === null || product === void 0 ? void 0 : product._id));
    if (!foundItem) {
      throw new Error('Item product was not found');
    }
    const productSettings = transformProductSettingsForFeature(foundItem.prod.productSettings, product, featureFlags);
    const amountOfSides = Object.keys(foundItem.logos || {}).length;
    const colors = Price.getScreensAmountForImage(productSettings[0], foundItem.colors, amountOfSides);
    return colors * ((_productSettings$0$sc = productSettings === null || productSettings === void 0 ? void 0 : (_productSettings$ = productSettings[0]) === null || _productSettings$ === void 0 ? void 0 : _productSettings$.screenPrice) !== null && _productSettings$0$sc !== void 0 ? _productSettings$0$sc : 0) / amountOfSides;
  });
  return Math.max.apply(null, screenPrices);
};