import { Price } from 'swag-client-common/utils/price';
export function getUpsellBoxPrice({
  boxContentItems,
  boxItem,
  newQuantity: propsQuantity
}) {
  if (!boxItem.prod) {
    throw new Error('Item product not found');
  }
  const priceForBoxes = Price.getBoxPrice({
    orderItem: boxItem,
    product: boxItem.prod,
    isBoxItemsPriceAdded: false
  });
  const boxItemPrice = Price.getItemPriceWithoutSetUpFee({
    price: priceForBoxes,
    quantity: boxItem.quantity,
    setupFee: 0
  });
  let fullBoxNewPrice = boxItemPrice;
  let fullBoxCurrentPrice = boxItemPrice;
  boxContentItems.forEach(({
    quantityPerBox,
    upsellData: {
      currentItemPrice = 0,
      newItemPrice = 0,
      newQuantity = 0
    }
  }) => {
    const quantity = quantityPerBox || 1;
    const nextPriceBreak = newQuantity && newQuantity / quantity;
    if (nextPriceBreak && nextPriceBreak <= propsQuantity) {
      fullBoxNewPrice += newItemPrice * quantity;
    } else {
      fullBoxNewPrice += currentItemPrice * quantity;
    }
    fullBoxCurrentPrice += currentItemPrice * quantity;
  });
  return {
    newItemPrice: fullBoxNewPrice,
    currentItemPrice: fullBoxCurrentPrice
  };
}