import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { acceptInvitation } from '../../../redux/invitation/invitation.actions';
import { isErrorSelector, errorMessageSelector, isLoadingSelector, inviteNameSelector, inviteInventoryNameSelector, passwordSelector, completedPasswordRequirementsSelector, isPasswordInputFocusedSelector, isAllPasswordRequirementsCompletedSelector, isPasswordLengthMoreThanMaxAllowedCharactersSelector } from '../../../redux/invitation/invitation.selectors';
import InvitationForm from './invitation-form';
const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingSelector,
  isError: isErrorSelector,
  errorMessage: errorMessageSelector,
  name: inviteNameSelector,
  inventoryName: inviteInventoryNameSelector,
  completedPasswordRequirements: completedPasswordRequirementsSelector,
  isAllPasswordRequirementsCompleted: isAllPasswordRequirementsCompletedSelector,
  isPasswordLengthMoreThanMaxAllowedCharacters: isPasswordLengthMoreThanMaxAllowedCharactersSelector,
  isPasswordInputFocused: isPasswordInputFocusedSelector,
  password: passwordSelector
});
const mapDispatchToProps = dispatch => ({
  onSubmit: values => dispatch(acceptInvitation(values))
});
export default connect(mapStateToProps, mapDispatchToProps)(InvitationForm);