import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/reseller';
export const ResellerApi = createApi({
  submitForm: {
    method: 'post',
    url: `${baseUrl}/submit`
  },
  getResellerLandingData: {
    method: 'get',
    url: `${baseUrl}/landing-data`
  },
  exitResellerMode: {
    method: 'get',
    url: `${baseUrl}/mode/exit`
  },
  acceptContract: {
    method: 'get',
    url: `${baseUrl}/accept-contract`
  },
  saveInvoice: {
    method: 'post',
    url: `${baseUrl}/invoice/save`
  },
  getCommission: {
    method: 'get',
    url: `${baseUrl}/commission`
  },
  getContractDetails: {
    method: 'get',
    url: `${baseUrl}/contract/:token`
  },
  signContract: {
    method: 'post',
    url: `${baseUrl}/contract/sign`
  },
  getCustomHomePageData: {
    method: 'get',
    url: `${baseUrl}/home-page-data`
  }
});