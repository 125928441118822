export const getFormattedTimezone = timezone => {
  if (!timezone) {
    return '';
  }
  const timeFormat = new Date().toLocaleString('en-US', {
    timeZone: timezone,
    timeZoneName: 'short'
  });
  const GMT = timeFormat.split(' ').pop();
  const places = timezone.split('/');
  places.shift();
  const city = places.join(' ').replace('_', ' ');
  return `(${GMT}) ${city}`;
};