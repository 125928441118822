import { EST_LIMIT_HOURS } from 'swag-common/constants/date';
import { addDays, isWorkingDay, subtractDays, toESTDateFormatter } from './date-lib';
function addBusinessDays({
  date,
  daysToAdd,
  cutOffTime,
  limitHours = EST_LIMIT_HOURS
}) {
  let count = 0;
  let tmpDate = new Date(date);
  const ESTTime = toESTDateFormatter(tmpDate);
  const ESTHours = ESTTime.getHours();
  const toAdd = cutOffTime && ESTHours >= limitHours ? daysToAdd + 1 : daysToAdd;
  if (daysToAdd <= 0) {
    return date;
  }
  while (count < toAdd) {
    tmpDate = addDays(tmpDate, 1);
    if (isWorkingDay(tmpDate)) {
      count = count + 1;
    }
  }
  return tmpDate;
}
function subtractBusinessDays({
  date,
  daysToSubtract,
  cutOffTime
}) {
  let count = 0;
  let tmpDate = new Date(date);
  const ESTTime = toESTDateFormatter(tmpDate);
  const ESTHours = ESTTime.getHours();
  const toSubtract = cutOffTime && ESTHours > EST_LIMIT_HOURS ? daysToSubtract + 1 : daysToSubtract;
  if (daysToSubtract <= 0) {
    return date;
  }
  while (count < toSubtract) {
    tmpDate = subtractDays(tmpDate, 1);
    if (isWorkingDay(tmpDate)) {
      count = count + 1;
    }
  }
  return tmpDate;
}
export { addBusinessDays, subtractBusinessDays };