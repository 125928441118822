function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
export const mockupsSelector = state => state.accountMockups.mockups.map(mockup => {
  var _variants$, _productVariants$vari, _Object$keys$find, _variantImages$locati, _variantImages$locati2, _variantImages$locati3;
  const {
    item: {
      variants,
      customerMockups,
      sizeInd,
      logos,
      prod: {
        name,
        _id: productId,
        variants: productVariants,
        logo
      }
    }
  } = mockup;
  const variantIndex = (_variants$ = variants[0]) === null || _variants$ === void 0 ? void 0 : _variants$.variantIndex;
  const variantImages = (_productVariants$vari = productVariants[variantIndex]) === null || _productVariants$vari === void 0 ? void 0 : _productVariants$vari.images;
  const locationWithLogo = (_Object$keys$find = Object.keys(logos).find(location => !!logos[location].length && (variantImages === null || variantImages === void 0 ? void 0 : variantImages[location]))) !== null && _Object$keys$find !== void 0 ? _Object$keys$find : '';
  const customerMockup = {
    image: (_variantImages$locati = variantImages === null || variantImages === void 0 ? void 0 : (_variantImages$locati2 = variantImages[locationWithLogo]) === null || _variantImages$locati2 === void 0 ? void 0 : (_variantImages$locati3 = _variantImages$locati2[sizeInd]) === null || _variantImages$locati3 === void 0 ? void 0 : _variantImages$locati3.url) !== null && _variantImages$locati !== void 0 ? _variantImages$locati : logo
  };
  if (Object.keys(customerMockups).length) {
    const mockupImages = Object.values(customerMockups);
    customerMockup.image = mockupImages[0].fileName || customerMockup.image;
  }
  return _objectSpread({
    name,
    productId,
    customerMockup
  }, mockup);
});
export const selectedMockupsSelector = state => state.accountMockups.selectedMockups;
export const selectedMockupsDataSelector = state => state.accountMockups.mockups.filter(({
  _id
}) => state.accountMockups.selectedMockups.indexOf(_id) > -1).map(({
  item
}) => item);
export const accountMockupsSelector = createSelector(mockupsSelector, selectedMockupsSelector, (mockups, selectedMockups) => ({
  mockups: mockups.map(mockup => _objectSpread({}, mockup, {
    selected: selectedMockups.indexOf(mockup._id) > -1
  })),
  isShareDisabled: selectedMockups.length < 1,
  hasMockups: mockups.length > 0
}));