function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { GET_CART, REMOVE_ITEM_FROM_CART_SUCCESS, ADD_ITEM_TO_CART, GET_DRAFT_SUCCESS, REMOVE_ITEM_FROM_DRAFT_SUCCESS } from 'swag-client-common/redux/cart/cart.actions';
import { getItemsToReviewPantoneColors } from 'swag-common/utils/order/order-statuses.utils';
import { subtractDays, setStartOfDay } from 'swag-common/utils/date-lib';
import { APPROVING_SUCCESS, REQUESTING_CHANGES_SUCCESS } from '../mockup-manager/mockup-manager.actions';
import { APPROVE_COLORS_SUCCESS } from '../item-pantones/item-pantones.actions';
import { PROCEED_ORDER_SUCCESS } from '../checkout/checkout.actions';
import { STATE_IDLE, STATE_ERROR, STATE_LOADING, STATE_SUCCESS } from '../redux.constants';
import { SELECT_LVL_ALL } from '../gamification/gamification.constants';
import { UPDATE_EXPORT_DATE, RESET_APPROVE_PANTONE_COLORS_ITEM } from '../../redux/account-orders/account-orders.actions';
import { mergeOrdersWithPagination } from '../../../common/utils/merge-orders';
import { PERIODS } from './account-orders.constants';
import { mapSamplePromoItemsFromCart } from './account-orders.utils';
import { LOADING_STARTED, LOADING_SUCCESS, LOADING_ERROR, ORDERS_FILTERED, SHOWING_TRACKING_NUMBERS, RESET_ORDERS, SEARCH_CHANGE } from './account-orders.actions';
const initialState = {
  state: STATE_IDLE,
  orders: [],
  ordersToDisplay: [],
  hasNoOrders: false,
  timePeriod: PERIODS[0],
  selectedLvl: SELECT_LVL_ALL,
  showingTrackingNumbers: null,
  samplePromoItemsInCart: {},
  exportDate: {
    maxDate: new Date(),
    minDate: new Date('2015'),
    exportFrom: setStartOfDay(subtractDays(new Date(), 1)),
    exportTo: new Date()
  },
  pagination: {
    perPage: 5,
    page: 1,
    total: 0
  },
  search: '',
  itemsToReviewPantoneColors: []
};
export function accountOrdersReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_EXPORT_DATE:
      return _objectSpread({}, state, {
        exportDate: _objectSpread({}, state.exportDate, {
          [action.payload.dateType]: action.payload.dateValue
        })
      });
    case LOADING_STARTED:
      return _objectSpread({}, state, {
        state: STATE_LOADING
      });
    case LOADING_SUCCESS:
      {
        const {
          orders,
          hasNoOrders,
          timePeriod = PERIODS[0],
          pagination
        } = action;
        const {
          page,
          perPage
        } = pagination;
        const {
          pagination: {
            page: prevPage
          },
          orders: prevOrders = []
        } = state;
        const mergedOrders = mergeOrdersWithPagination({
          page,
          prevPage,
          perPage,
          orders,
          prevOrders
        });
        return _objectSpread({}, state, {
          state: STATE_SUCCESS,
          orders: mergedOrders,
          ordersToDisplay: orders,
          hasNoOrders,
          timePeriod,
          pagination
        });
      }
    case LOADING_ERROR:
      return _objectSpread({}, state, {
        state: STATE_ERROR
      });
    case ORDERS_FILTERED:
      {
        const {
          period,
          selectedLvl,
          ordersToDisplay
        } = action;
        return _objectSpread({}, state, {
          timePeriod: period,
          selectedLvl,
          ordersToDisplay
        });
      }
    case PROCEED_ORDER_SUCCESS:
      {
        const {
          order,
          isApprovalFlow
        } = action.body;
        if (isApprovalFlow || order.isSilent) {
          return _objectSpread({}, initialState);
        }
        const itemsToReviewPantoneColors = getItemsToReviewPantoneColors(order.items);
        return _objectSpread({}, initialState, {
          itemsToReviewPantoneColors
        });
      }
    case SHOWING_TRACKING_NUMBERS:
      {
        const {
          ksTrackings,
          orderNumber
        } = action;
        return _objectSpread({}, state, {
          showingTrackingNumbers: {
            ksTrackings,
            orderNumber
          }
        });
      }
    case REQUESTING_CHANGES_SUCCESS:
    case APPROVE_COLORS_SUCCESS:
    case APPROVING_SUCCESS:
      {
        const updatedOrder = action.order;
        const orders = state.orders.map(order => order._id === updatedOrder._id ? updatedOrder : order);
        const ordersToDisplay = state.ordersToDisplay.map(order => order._id === updatedOrder._id ? updatedOrder : order);
        return _objectSpread({}, state, {
          orders,
          ordersToDisplay
        });
      }
    case RESET_APPROVE_PANTONE_COLORS_ITEM:
      {
        const itemId = action.payload;
        const filteredItemsToApprove = state.itemsToReviewPantoneColors.filter(el => el.itemId !== itemId);
        return _objectSpread({}, state, {
          itemsToReviewPantoneColors: filteredItemsToApprove
        });
      }
    case GET_CART:
      {
        const {
          items = []
        } = action.body;
        const samplePromoItemsInCart = mapSamplePromoItemsFromCart(items);
        return _objectSpread({}, state, {
          samplePromoItemsInCart: _objectSpread({}, samplePromoItemsInCart, state.samplePromoItemsInCart)
        });
      }
    case GET_DRAFT_SUCCESS:
      {
        const {
          draftItems = []
        } = action.payload;
        const samplePromoItemsInCart = mapSamplePromoItemsFromCart(draftItems);
        return _objectSpread({}, state, {
          samplePromoItemsInCart: _objectSpread({}, samplePromoItemsInCart, state.samplePromoItemsInCart)
        });
      }
    case ADD_ITEM_TO_CART:
      {
        const {
          order: {
            items = []
          }
        } = action.body;
        const samplePromoItemsInCart = mapSamplePromoItemsFromCart(items);
        return _objectSpread({}, state, {
          samplePromoItemsInCart
        });
      }
    case REMOVE_ITEM_FROM_CART_SUCCESS:
      {
        const {
          itemId
        } = action.body;
        const samplePromoItemsInCart = _objectSpread({}, state.samplePromoItemsInCart);
        delete samplePromoItemsInCart[itemId];
        return _objectSpread({}, state, {
          samplePromoItemsInCart
        });
      }
    case REMOVE_ITEM_FROM_DRAFT_SUCCESS:
      {
        const {
          itemIdFromReorder
        } = action.payload;
        const samplePromoItemsInCart = _objectSpread({}, state.samplePromoItemsInCart);
        delete samplePromoItemsInCart[itemIdFromReorder];
        return _objectSpread({}, state, {
          samplePromoItemsInCart
        });
      }
    case RESET_ORDERS:
      {
        return _objectSpread({}, state, {
          orders: [],
          ordersToDisplay: [],
          pagination: initialState.pagination
        });
      }
    case SEARCH_CHANGE:
      {
        return _objectSpread({}, state, {
          search: action.payload.search
        });
      }
    default:
      return state;
  }
}