import dateFormat from 'dateformat';
import { recalculateProceededOrder } from 'swag-common/business-logic/order/recalculate-proceeded-order.logic';
import { MILLISECONDS_IN_ONE_DAY } from 'swag-common/constants/date';
import * as ITEM_STATUSES from 'swag-common/constants/item-statuses.constants';
import { PREDEFINED_TENANTS } from 'swag-common/constants/main-swag.constants';
import { checkIsResellerTenant } from 'swag-common/utils/check-is-reseller-tenant.util';
import { getAddressForInvoice } from 'swag-common/utils/invoices/get-address-for-invoice.util';
import { getOfficeAddress } from 'swag-common/utils/invoices/get-office-address.util';
import { excludeRejectedAndAbandoned, getGrandTotal, getPaymentMethodByCode, isPaymentAch, isPaymentCC, isPaymentCredits, isPaymentInvoice, isPaymentNet, isPaymentNetUpfront, isStatedItem } from 'swag-common/utils/order';
import { isDeliverToFulfillmentCenter } from 'swag-common/utils/order/is-deliver-to-fulfillment-center.util';
import * as shippingUtils from 'swag-common/utils/shipping';
import { LOGO_LINK } from 'swag-common/constants/tenant';
import { prepareItemsInfoForInvoice } from 'swag-common/services/product/prepare-items-info-for-invoice';
import { Price } from 'swag-common/utils/price';
const getDataForOrderInvoiceTemplateFunction = ({
  paymentMethod,
  order,
  tenantStoreSettings
}) => {
  var _paymentMethodObj$dut, _paymentMethodObj$dut2, _paymentMethodObj$pre, _paymentMethodObj$pre2;
  const {
    company,
    firstName,
    lastName,
    city,
    address,
    unit,
    state,
    zip,
    country,
    num,
    items: allItems,
    shipmentCustomerTotal = 0,
    tenant = PREDEFINED_TENANTS.SWAG,
    isTaxExempt = false
  } = order;
  const items = allItems.filter(isStatedItem);
  const isToFulfillmentCenter = isDeliverToFulfillmentCenter(order) || false;
  const {
    shipmentDiscount,
    setupFeeDiscount,
    discountWithoutAdditionals
  } = recalculateProceededOrder(order, items.map(item => String(item._id)));
  const replacementDiscount = Price.getReplacementDiscount(items);
  const samplePriceDeduction = Price.getSampleDeductionDiscount(items);
  const itemsPrice = Price.getTotalWithoutDiscounts(allItems.filter(isStatedItem));
  const deductions = Price.getDeductions(allItems.filter(isStatedItem));
  const isAllCancelledOrRefunded = items.every(item => item.status === ITEM_STATUSES.CANCELLED || item.status === ITEM_STATUSES.REFUNDED);
  const total = isAllCancelledOrRefunded ? 0 : itemsPrice - deductions + shipmentCustomerTotal;
  const grandTotal = isAllCancelledOrRefunded ? 0 : getGrandTotal(order);
  const stateObj = shippingUtils.getStateByStateCode(state);
  const orderDate = new Date(); // date when customer requested invoice
  const paymentMethodObj = getPaymentMethodByCode(paymentMethod);
  const itemsPrintInfo = prepareItemsInfoForInvoice(allItems.filter(excludeRejectedAndAbandoned));
  const isNet = isPaymentNet(paymentMethod);
  const isAch = isPaymentAch(paymentMethod);
  const isNetUpfront = isPaymentNetUpfront(paymentMethod);
  const isCCPaymentMethod = isPaymentCC(paymentMethod);
  const isPostal = tenant === PREDEFINED_TENANTS.POSTAL;
  const [poatalLine1, poatalLine2] = getExtraAddressLine(address);
  const discount = discountWithoutAdditionals + replacementDiscount;
  const logo = checkIsResellerTenant(tenant) ? tenantStoreSettings === null || tenantStoreSettings === void 0 ? void 0 : tenantStoreSettings.logoUrl : LOGO_LINK[tenant];
  const addressForInvoice = getAddressForInvoice(tenant, tenantStoreSettings);
  const officeAddress = getOfficeAddress(tenant, tenantStoreSettings);
  return {
    isPostal,
    isReseller: checkIsResellerTenant(tenant),
    bankInfo: tenantStoreSettings === null || tenantStoreSettings === void 0 ? void 0 : tenantStoreSettings.invoiceBankDetails,
    company,
    firstName,
    lastName,
    logo,
    address: addressForInvoice || [],
    officeAddress,
    addressExtra: isPostal ? poatalLine1 : null,
    shippingAddress: isPostal ? `${poatalLine2} ${unit}` : `${address} ${unit}`,
    shippingStateZip: `${city} ${stateObj === null || stateObj === void 0 ? void 0 : stateObj.name} ${zip}`,
    country,
    orderDate: dateFormat(orderDate, 'mmmm dS, yyyy'),
    dueDate: dateFormat(getDueDate((_paymentMethodObj$dut = paymentMethodObj === null || paymentMethodObj === void 0 ? void 0 : paymentMethodObj.dutyDaysCount) !== null && _paymentMethodObj$dut !== void 0 ? _paymentMethodObj$dut : 0), 'mmmm dS, yyyy'),
    orderNumber: num,
    paymentMethodName: paymentMethodObj.name,
    paymentDutyDaysDuration: (_paymentMethodObj$dut2 = paymentMethodObj.dutyDaysCount) !== null && _paymentMethodObj$dut2 !== void 0 ? _paymentMethodObj$dut2 : 0,
    paymentDutyPricePercentsAmount: (_paymentMethodObj$pre = paymentMethodObj.prepaymentPercentageAmount) !== null && _paymentMethodObj$pre !== void 0 ? _paymentMethodObj$pre : 0,
    paymentDutyPricePercentsAmountRemaining: 100 - ((_paymentMethodObj$pre2 = paymentMethodObj.prepaymentPercentageAmount) !== null && _paymentMethodObj$pre2 !== void 0 ? _paymentMethodObj$pre2 : 0),
    paymentDescription: paymentMethodObj.description,
    isToFulfillmentCenter,
    isTaxExempt,
    items: itemsPrintInfo,
    paymentFlags: {
      isNet,
      isNetOrAchOrNetUpfront: isNet || isAch || isNetUpfront,
      isNetUpfront,
      isAch,
      isNotCC: !isCCPaymentMethod,
      isCC: isCCPaymentMethod,
      isCredits: isPaymentCredits(paymentMethod),
      isInvoice: isPaymentInvoice(paymentMethod)
    },
    subTotal: {
      text: Price.formatPrice(total),
      num: total
    },
    discount: {
      text: Price.formatPrice(discount),
      num: discount
    },
    setupFeeDiscount: {
      text: Price.formatPrice(setupFeeDiscount),
      num: setupFeeDiscount
    },
    shipmentDiscount: {
      text: Price.formatPrice(shipmentDiscount),
      num: shipmentDiscount
    },
    samplePriceDeduction: {
      text: Price.formatPrice(samplePriceDeduction),
      num: samplePriceDeduction
    },
    shipping: {
      text: Price.formatPrice(shipmentCustomerTotal),
      num: shipmentCustomerTotal
    },
    grandTotal: {
      text: Price.formatPrice(grandTotal || 0),
      num: grandTotal || 0
    }
  };
  function getDueDate(dutyDaysAmount) {
    const dueMsAmount = dutyDaysAmount * MILLISECONDS_IN_ONE_DAY;
    return new Date(orderDate.getTime() + dueMsAmount);
  }
};
function getExtraAddressLine(adr) {
  return adr.split(',');
}
export default getDataForOrderInvoiceTemplateFunction;