export const getLeftTime = date => {
  const difference = Number(date) - Number(new Date());
  return difference > 0 ? difference : 0;
};
export const getDaysLeft = time => Math.floor(time / (1000 * 60 * 60 * 24));
export const getHoursLeft = time => getDaysLeft(time) * 24 + Math.floor(time / (1000 * 60 * 60) % 24);
export const getMinutesLeft = time => Math.floor(time / 1000 / 60 % 60);
export const getSecondsLeft = time => Math.floor(time / 1000 % 60);
export const isISOString = str => {
  const regExpTemplate = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
  if (!regExpTemplate.test(str)) {
    return false;
  }
  const date = new Date(str);
  return date.toISOString() === str;
};
export const addSeconds = (date, seconds) => {
  const result = new Date(date);
  result.setSeconds(result.getSeconds() + seconds);
  return result;
};