const weightOneOz = 0.0625;
export function getPriceInCentsForItemByWeight(prodWeight) {
  if (prodWeight <= weightOneOz * 3) {
    return 3;
  }
  if (prodWeight < 5) {
    return 14;
  }
  if (prodWeight >= 5 && prodWeight <= 10) {
    return 20;
  }
  return 25;
}