import { downloadFileInBrowser } from 'swag-client-common/utils/download-file-in-browser';
const CSV_DOWNLOAD = 'CSV_DOWNLOAD';
export const downloadCSVAction = (data, name) => ({
  type: CSV_DOWNLOAD,
  payload: {
    data,
    name
  }
});
export const csvDownloadMiddleware = () => next => action => {
  if (action.type === CSV_DOWNLOAD) {
    if (!global.document) {
      return;
    }
    const {
      data,
      name
    } = action.payload;
    const fileName = `${name}.csv`;
    const text = data.map(rows => rows.join(',')).join('\n');
    const blob = new Blob(['', text], {
      type: 'text/csv'
    });
    downloadFileInBrowser({
      blob,
      name: fileName
    });
  }
  next(action);
};