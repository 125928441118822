import { getDefaultCategory } from '../data.utils';
export const getParentCategory = (productCategories, allCategories) => {
  var _allCategories$find;
  if (!productCategories) {
    return '';
  }
  const defaultProductCategory = getDefaultCategory(productCategories || []);
  const productCategoryParentId = (_allCategories$find = allCategories.find(item => item._id === defaultProductCategory.categoryId)) === null || _allCategories$find === void 0 ? void 0 : _allCategories$find.parent_id;
  const parentProductCategory = allCategories.find(item => item._id === productCategoryParentId);
  return (parentProductCategory === null || parentProductCategory === void 0 ? void 0 : parentProductCategory.name) || '';
};