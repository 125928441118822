import { HTML_TO_IMAGE_OPTIONS } from 'swag-client-common/constants/html-to-image';
import { toPng } from 'html-to-image';
export async function generateImageFromHtml({
  mockupContainer,
  hiddenContainerId
}) {
  if (!mockupContainer) {
    return null;
  }
  const contentToClone = mockupContainer.outerHTML;
  const hiddenContainer = document.getElementById(hiddenContainerId);
  if (!hiddenContainer) {
    return null;
  }
  hiddenContainer.innerHTML = contentToClone;
  const imgUrl = await toPng(hiddenContainer, HTML_TO_IMAGE_OPTIONS);
  return imgUrl;
}