import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { CollectSizesApi } from '../../api/collect-sizes.api';
const PREFIX = 'COLLECT_SIZES_';
export const IF_USER_CREATED_BEFORE_REQUEST = `${PREFIX}IF_USER_CREATED_BEFORE_REQUEST`;
export const IF_USER_CREATED_BEFORE_SUCCESS = `${PREFIX}IF_USER_CREATED_BEFORE_SUCCESS`;
export const IF_USER_CREATED_BEFORE_FAILURE = `${PREFIX}IF_USER_CREATED_BEFORE_FAILURE`;
export const ifCollectSizesBeforeRequest = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: IF_USER_CREATED_BEFORE_REQUEST
    });
  },
  fn: CollectSizesApi.checkIfCreatedBefore,
  success: ({
    isUserCreatedCollectSizesBefore
  }) => ({
    type: IF_USER_CREATED_BEFORE_SUCCESS,
    payload: {
      isUserCreatedCollectSizesBefore
    }
  }),
  error: error => ({
    type: IF_USER_CREATED_BEFORE_FAILURE,
    payload: {
      error
    },
    error
  })
});