function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { LOCATION_CHANGE } from 'react-router-redux';
import { POPUP_SHOWN } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { ADDITIONAL_DISCOUNT_TYPES, PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
import { OrderStorageTypes } from 'swag-common/constants/main-swag.constants';
import { commonCartReducer } from 'swag-client-common/redux/cart/cart.reducer';
import { REGULAR_QUANTITY_EDITOR_POPUP, BOX_QUANTITY_EDITOR_POPUP, SIZE_EDITOR_POPUP, PRODUCTION_TIME_DRAWER, REGULAR_SIZE_POPUP, REMOVE_ITEM_FROM_CART_POPUP, CART_BOXES_SPECIAL_PRODUCTS_WARNING } from 'swag-client-common/constants';
import { MOVE_ITEM_TO_CART_FAIL, MOVE_ITEM_TO_CART_REQUEST, MOVE_ITEM_TO_DRAFT_FAIL, MOVE_ITEM_TO_DRAFT_REQUEST, PROMOCODE_APPLIED_SUCCESS, MOVE_ITEM_TO_CART_SUCCESS, MOVE_ITEM_TO_DRAFT_SUCCESS, GET_RECENTLY_VIEWED_AND_SUGGESTED_REQUEST_SUCCESS, GET_RECENTLY_VIEWED_REQUEST_STARTED, GET_RECENTLY_VIEWED_REQUEST_ERROR, CLEAR_CART } from 'swag-client-common/redux/cart/cart.actions';
import { removeIsOrderTokenShouldBeRemoved, removeOrderToken } from 'swag-client-common/utils/local-storage.util';
import { PROCEED_ORDER_FAIL, GET_RATES_SUCCESS, UPDATE_TAXES } from '../checkout/checkout.actions';
import { UPLOAD_CSV_SUCCESS, UPLOAD_CSV_FAIL, APPLY_KS_FLOW_SUCCESS } from '../kickstarter-shipping/kickstarter-shipping.actions';
import { PRELOAD_SUGGESTED_PRODUCT, GET_ADD_ON_PRODUCTS_FOR_BOX_SUCCESS } from '../product/product.actions';
import { SET_BOX_NOTECARD, SET_CRINKLE_PAPER, RESET_PICKED_SPECIAL_BOXES_PRODUCTS, CLICK_ADD_TO_CART_BUTTON, CLICK_ADD_TO_BOX_BUTTON, CLICK_BUY_PRINTED } from './cart.actions';
import { SET_UPSELL_WIDGET_STATUS, INITIALIZE_UPSELL_WIDGET_PRODUCTS_INPUT_DATA, SET_INVENTORY_UPSELL_EDIT_MODE, SET_INVENTORY_UPSELL_SELECTED_VARIANT_INDEX, SET_INVENTORY_UPSELL_VARIANT_QUANTITY, SET_INVENTORY_UPSELL_SELECTED_ITEM_ID_EDIT, SET_INVENTORY_UPSELL_BOX_APPAREL_QUANTITY, RESET_INVENTORY_UPSELL_VARIANT_QUANTITY, SET_UPSELL_SEND_TO_INVENTORY_QUANTITY, RESET_UPSELL_SEND_TO_INVENTORY_QUANTITY } from './upsell-widget.actions';
import { handleUpdateInventoryUpsellVariantQuantity, initializeUpsellInputData, resetUpdateInventoryUpsellVariantQuantity } from './cart.utils';
const {
  IS_FREE_SHIPPING,
  IS_FREE_SETUP_FEE,
  IS_TAXFREE
} = ADDITIONAL_DISCOUNT_TYPES;
const initialState = {
  paymentMethod: undefined,
  defaultShipmentCustomerTotal: undefined,
  listOfNearestVariants: undefined,
  cartLoaded: false,
  getCartIsLoading: false,
  getDraftIsLoading: false,
  items: [],
  draftItems: [],
  orderId: null,
  orderPublicId: 'unknown',
  orderToken: localStorage.getItem('orderToken'),
  draftToken: localStorage.getItem('draftToken'),
  orderVersion: null,
  draftVersion: null,
  promocode: '',
  measurement: PromocodeMeasurementEnum.PERCENTAGES,
  taxes: 0,
  isTaxExempt: false,
  promocodeDiscount: 0,
  delivery: 1,
  plan: null,
  preTotal: 0,
  total: 0,
  shipmentCustomerTotal: 0,
  ksFulfillment: null,
  ksFlow: false,
  error: null,
  suggestedProducts: [],
  selectedSuggestedProduct: null,
  itemToEdit: null,
  [IS_FREE_SHIPPING]: false,
  [IS_FREE_SETUP_FEE]: false,
  [IS_TAXFREE]: false,
  shipmentDiscount: 0,
  setupFeeDiscount: 0,
  storageType: OrderStorageTypes.CART,
  isRemoveFromBox: false,
  taxesPrice: 0,
  isLoading: false,
  checkoutButtonTouched: false,
  specialBoxProductsLoading: false,
  specialBoxProducts: [],
  pickedSpecialBoxesProducts: {},
  isAddToCartClicked: false,
  isAddToBoxClicked: false,
  isBuyPrintedClicked: false,
  cart: {
    supplierInventoryLevels: [],
    suppliers: []
  },
  draft: {
    supplierInventoryLevels: [],
    suppliers: []
  },
  upsellDismissedStates: {},
  addOnProductsForBox: [],
  recentlyViewed: [],
  isRecentlyViewedLoading: false,
  showUpsellWidget: false,
  upsellTotalQuantitiesMap: {},
  upsellInventoryQuantitiesMap: {},
  inventoryUpsellSelectedVariantsIndexesMap: {},
  inventoryUpsellEditMode: null,
  inventoryUpsellSelectedItemIdForEdit: null,
  swagSpaceTaxesPrice: 0,
  swagSpaceTotalPrice: 0,
  resellerInvoiceNum: null,
  resellersCommission: null,
  productsCommission: [],
  selectedItem: null
};
export function cartReducer(state = initialState, action) {
  switch (action.type) {
    case MOVE_ITEM_TO_CART_REQUEST:
    case MOVE_ITEM_TO_DRAFT_REQUEST:
      {
        return _objectSpread({}, state, {
          isLoading: true
        });
      }
    case MOVE_ITEM_TO_CART_FAIL:
    case MOVE_ITEM_TO_DRAFT_FAIL:
      {
        return _objectSpread({}, state, {
          cartLoaded: true,
          isLoading: false
        });
      }
    case SET_BOX_NOTECARD:
      {
        const {
          boxItemsId,
          isNotecardPicked
        } = action.payload;
        return _objectSpread({}, state, {
          pickedSpecialBoxesProducts: _objectSpread({}, state.pickedSpecialBoxesProducts, {
            [boxItemsId]: _objectSpread({}, state.pickedSpecialBoxesProducts[boxItemsId], {
              isNotecardPicked
            })
          })
        });
      }
    case SET_CRINKLE_PAPER:
      {
        const {
          boxItemsId,
          pickedVariantIndex
        } = action.payload;
        return _objectSpread({}, state, {
          pickedSpecialBoxesProducts: _objectSpread({}, state.pickedSpecialBoxesProducts, {
            [boxItemsId]: _objectSpread({}, state.pickedSpecialBoxesProducts[boxItemsId], {
              crinklePaperVariantIndex: pickedVariantIndex
            })
          })
        });
      }
    case RESET_PICKED_SPECIAL_BOXES_PRODUCTS:
      {
        return _objectSpread({}, state, {
          pickedSpecialBoxesProducts: {},
          checkoutButtonTouched: false
        });
      }
    case MOVE_ITEM_TO_CART_SUCCESS:
    case MOVE_ITEM_TO_DRAFT_SUCCESS:
      {
        var _cart$items, _draft$items, _cart$ksFulfillment, _cart$setupFeeDiscoun, _cart$shipmentCustome, _cart$shipmentDiscoun, _cart$shipmentUpsTota, _cart$total, _cart$resellersCommis, _cart$productsCommiss, _cart$items2, _draft$items2, _cart$items3;
        const {
          cart,
          draft,
          orderToken,
          draftToken
        } = action.payload;
        if (orderToken) {
          localStorage.setItem('orderToken', orderToken);
        }
        if (draftToken) {
          localStorage.setItem('draftToken', draftToken);
        }
        if (!(cart !== null && cart !== void 0 && (_cart$items = cart.items) !== null && _cart$items !== void 0 && _cart$items.length)) {
          localStorage.removeItem('orderToken');
        }
        if (!(draft !== null && draft !== void 0 && (_draft$items = draft.items) !== null && _draft$items !== void 0 && _draft$items.length)) {
          localStorage.removeItem('draftToken');
        }
        const cartData = {
          ksFulfillment: (_cart$ksFulfillment = cart === null || cart === void 0 ? void 0 : cart.ksFulfillment) !== null && _cart$ksFulfillment !== void 0 ? _cart$ksFulfillment : null,
          setupFeeDiscount: (_cart$setupFeeDiscoun = cart === null || cart === void 0 ? void 0 : cart.setupFeeDiscount) !== null && _cart$setupFeeDiscoun !== void 0 ? _cart$setupFeeDiscoun : 0,
          shipmentCustomerTotal: (_cart$shipmentCustome = cart === null || cart === void 0 ? void 0 : cart.shipmentCustomerTotal) !== null && _cart$shipmentCustome !== void 0 ? _cart$shipmentCustome : 0,
          shipmentDiscount: (_cart$shipmentDiscoun = cart === null || cart === void 0 ? void 0 : cart.shipmentDiscount) !== null && _cart$shipmentDiscoun !== void 0 ? _cart$shipmentDiscoun : 0,
          shipmentUpsTotal: (_cart$shipmentUpsTota = cart === null || cart === void 0 ? void 0 : cart.shipmentUpsTotal) !== null && _cart$shipmentUpsTota !== void 0 ? _cart$shipmentUpsTota : 0,
          total: (_cart$total = cart === null || cart === void 0 ? void 0 : cart.total) !== null && _cart$total !== void 0 ? _cart$total : 0,
          resellersCommission: (_cart$resellersCommis = cart === null || cart === void 0 ? void 0 : cart.resellersCommission) !== null && _cart$resellersCommis !== void 0 ? _cart$resellersCommis : 0,
          productsCommission: (_cart$productsCommiss = cart === null || cart === void 0 ? void 0 : cart.productsCommission) !== null && _cart$productsCommiss !== void 0 ? _cart$productsCommiss : []
        };
        return _objectSpread({}, state, cartData, {
          orderToken,
          draftToken,
          orderVersion: cart.orderVersion,
          draftVersion: draft.orderVersion,
          isLoading: false,
          cartLoaded: true,
          items: (_cart$items2 = cart === null || cart === void 0 ? void 0 : cart.items) !== null && _cart$items2 !== void 0 ? _cart$items2 : [],
          draftItems: (_draft$items2 = draft === null || draft === void 0 ? void 0 : draft.items) !== null && _draft$items2 !== void 0 ? _draft$items2 : [],
          suggestedProducts: cart !== null && cart !== void 0 && (_cart$items3 = cart.items) !== null && _cart$items3 !== void 0 && _cart$items3.length ? state.suggestedProducts : []
        });
      }
    case GET_RATES_SUCCESS:
      {
        var _action$body$reseller, _action$body$products;
        const {
          items,
          taxes,
          shipmentCustomerTotal,
          shipmentDiscount,
          setupFeeDiscount,
          paymentMethod,
          ksFulfillment,
          promocode,
          promocodeDiscount,
          measurement,
          total,
          _id,
          publicId,
          taxesPrice,
          supplierInventoryLevels,
          suppliers,
          version: orderVersion,
          swagSpaceTaxesPrice,
          swagSpaceTotalPrice
        } = action.body.order;
        return _objectSpread({}, state, {
          orderVersion,
          items,
          taxes: parseFloat(taxes),
          total: parseInt(total, 10),
          paymentMethod,
          shipmentCustomerTotal,
          setupFeeDiscount,
          defaultShipmentCustomerTotal: shipmentCustomerTotal,
          shipmentDiscount,
          ksFulfillment: parseInt(ksFulfillment, 10),
          orderId: _id,
          orderPublicId: publicId || initialState.orderPublicId,
          promocode: promocode || initialState.promocode,
          promocodeDiscount: promocodeDiscount || initialState.promocodeDiscount,
          measurement: measurement || initialState.measurement,
          taxesPrice: parseInt(taxesPrice, 10),
          resellersCommission: (_action$body$reseller = action.body.resellersCommission) !== null && _action$body$reseller !== void 0 ? _action$body$reseller : 0,
          productsCommission: (_action$body$products = action.body.productsCommission) !== null && _action$body$products !== void 0 ? _action$body$products : [],
          cart: {
            supplierInventoryLevels: supplierInventoryLevels || state.cart.supplierInventoryLevels,
            suppliers: suppliers || state.cart.suppliers
          },
          swagSpaceTaxesPrice,
          swagSpaceTotalPrice
        });
      }
    case UPDATE_TAXES:
      {
        return _objectSpread({}, state, {
          taxes: parseFloat(action.taxes)
        });
      }
    case PROMOCODE_APPLIED_SUCCESS:
      {
        const {
          plan,
          paymentMethod,
          promocode,
          promocodeDiscount,
          measurement,
          total,
          shipmentCustomerTotal,
          ksFulfillment,
          shipmentDiscount = 0,
          setupFeeDiscount = 0,
          taxesPrice,
          items,
          version: orderVersion
        } = action;
        return _objectSpread({}, state, {
          orderVersion,
          total: parseInt(total, 10),
          taxesPrice: parseInt(taxesPrice, 10),
          promocodeDiscount: parseInt(promocodeDiscount, 10),
          shipmentCustomerTotal: parseInt(shipmentCustomerTotal, 10),
          ksFulfillment: parseInt(ksFulfillment, 10),
          promocode,
          measurement,
          plan,
          paymentMethod,
          error: null,
          [IS_FREE_SHIPPING]: !!action[IS_FREE_SHIPPING],
          [IS_FREE_SETUP_FEE]: !!action[IS_FREE_SETUP_FEE],
          [IS_TAXFREE]: !!action[IS_TAXFREE],
          shipmentDiscount,
          setupFeeDiscount,
          items
        });
      }

    // TODO: Handle success upload
    case UPLOAD_CSV_SUCCESS:
      {
        return state;
      }

    // TODO: Handle fail upload
    case UPLOAD_CSV_FAIL:
      {
        return state;
      }
    case APPLY_KS_FLOW_SUCCESS:
      {
        const {
          items,
          total,
          shipmentCustomerTotal,
          ksFulfillment,
          paymentMethod
        } = action.body;
        return _objectSpread({}, state, {
          ksFlow: true,
          items,
          total: parseInt(total, 10),
          shipmentCustomerTotal: parseInt(shipmentCustomerTotal, 10),
          ksFulfillment: parseInt(ksFulfillment, 10),
          paymentMethod
        });
      }
    case GET_RECENTLY_VIEWED_AND_SUGGESTED_REQUEST_SUCCESS:
      {
        const {
          suggestedProducts,
          listOfNearestVariants,
          recentlyViewed
        } = action.payload;
        return _objectSpread({}, state, {
          suggestedProducts,
          listOfNearestVariants,
          recentlyViewed: recentlyViewed,
          isRecentlyViewedLoading: false
        });
      }
    case PRELOAD_SUGGESTED_PRODUCT:
      {
        return _objectSpread({}, state, {
          selectedSuggestedProduct: null
        });
      }
    case PROCEED_ORDER_FAIL:
      {
        const {
          status
        } = action.err;
        if (status === 422) {
          return _objectSpread({}, state, {
            promocode: '',
            promocodeDiscount: 0,
            total: state.items.reduce((all, item) => item.price + all, 0)
          });
        }
        return state;
      }
    case POPUP_SHOWN:
      {
        if (action.payload.popup === 'sampleSizePopup' || action.payload.popup === REGULAR_QUANTITY_EDITOR_POPUP || action.payload.popup === BOX_QUANTITY_EDITOR_POPUP || action.payload.popup === SIZE_EDITOR_POPUP || action.payload.popup === PRODUCTION_TIME_DRAWER || action.payload.popup === REGULAR_SIZE_POPUP || action.payload.popup === REMOVE_ITEM_FROM_CART_POPUP) {
          const {
            itemId,
            storageType,
            isRemoveFromBox
          } = action.payload.params;
          return _objectSpread({}, state, {
            itemToEdit: itemId,
            storageType,
            isRemoveFromBox
          });
        }
        if (action.payload.popup === CART_BOXES_SPECIAL_PRODUCTS_WARNING) {
          return _objectSpread({}, state, {
            checkoutButtonTouched: true
          });
        }
        return state;
      }
    case CLICK_ADD_TO_CART_BUTTON:
      return _objectSpread({}, state, {
        isAddToCartClicked: true
      });
    case CLICK_ADD_TO_BOX_BUTTON:
      return _objectSpread({}, state, {
        isAddToBoxClicked: true
      });
    case CLICK_BUY_PRINTED:
      return _objectSpread({}, state, {
        isBuyPrintedClicked: true
      });
    case LOCATION_CHANGE:
      return _objectSpread({}, state, {
        isAddToCartClicked: false,
        isAddToBoxClicked: false,
        isBuyPrintedClicked: false
      });
    case GET_ADD_ON_PRODUCTS_FOR_BOX_SUCCESS:
      {
        return _objectSpread({}, state, {
          addOnProductsForBox: action.payload
        });
      }
    case GET_RECENTLY_VIEWED_REQUEST_STARTED:
      {
        return _objectSpread({}, state, {
          isRecentlyViewedLoading: true
        });
      }
    case GET_RECENTLY_VIEWED_REQUEST_ERROR:
      {
        return _objectSpread({}, state, {
          isRecentlyViewedLoading: false
        });
      }
    case SET_UPSELL_WIDGET_STATUS:
      {
        return _objectSpread({}, state, {
          showUpsellWidget: action.payload
        });
      }
    case INITIALIZE_UPSELL_WIDGET_PRODUCTS_INPUT_DATA:
      {
        return initializeUpsellInputData(state);
      }
    case SET_INVENTORY_UPSELL_EDIT_MODE:
      return _objectSpread({}, state, {
        inventoryUpsellEditMode: action.payload
      });
    case SET_INVENTORY_UPSELL_SELECTED_ITEM_ID_EDIT:
      return _objectSpread({}, state, {
        inventoryUpsellSelectedItemIdForEdit: action.payload
      });
    case SET_INVENTORY_UPSELL_SELECTED_VARIANT_INDEX:
      return _objectSpread({}, state, {
        inventoryUpsellSelectedVariantsIndexesMap: _objectSpread({}, state.inventoryUpsellSelectedVariantsIndexesMap, {
          [action.payload.itemId]: action.payload.variantIndex
        })
      });
    case SET_INVENTORY_UPSELL_VARIANT_QUANTITY:
      {
        return handleUpdateInventoryUpsellVariantQuantity(state, action.payload);
      }
    case SET_UPSELL_SEND_TO_INVENTORY_QUANTITY:
      {
        const {
          itemId,
          variantIndex,
          sizeIndex = 0,
          quantity
        } = action.payload;
        const sizeIndexNumber = Number(sizeIndex);
        const newVariant = [...state.upsellInventoryQuantitiesMap[itemId][variantIndex]];
        newVariant[sizeIndexNumber] = quantity;
        return _objectSpread({}, state, {
          upsellInventoryQuantitiesMap: _objectSpread({}, state.upsellInventoryQuantitiesMap, {
            [itemId]: _objectSpread({}, state.upsellInventoryQuantitiesMap[itemId], {
              [variantIndex]: newVariant
            })
          })
        });
      }
    case RESET_UPSELL_SEND_TO_INVENTORY_QUANTITY:
      {
        return _objectSpread({}, state, {
          upsellInventoryQuantitiesMap: {}
        });
      }
    case RESET_INVENTORY_UPSELL_VARIANT_QUANTITY:
      {
        return resetUpdateInventoryUpsellVariantQuantity(state, action.payload);
      }
    case SET_INVENTORY_UPSELL_BOX_APPAREL_QUANTITY:
      {
        return _objectSpread({}, state, {
          upsellTotalQuantitiesMap: _objectSpread({}, state.upsellTotalQuantitiesMap, action.payload)
        });
      }
    case CLEAR_CART:
      {
        removeOrderToken();
        removeIsOrderTokenShouldBeRemoved();
        return _objectSpread({}, initialState, {
          orderToken: null
        });
      }
    default:
      {
        return commonCartReducer(state, action);
      }
  }
}