import _reset from "swag-client-common/redux-form-actions/reset";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { scrollIntoView } from 'swag-client-common/utils/tricks';
import { HUBSPOT_FIELDS } from 'swag-common/constants/hubspot';
import { ResellerApi } from 'swag-client-common/api/reseller.api';
import { ViewIdToScroll } from 'swag-common/constants/other';
import { EmailApi } from '../../api/email.api';
import { ENTERPRISE_FORM, IN_A_BOX_FORM, PATAGONIA_FORM, GIVEAWAY_FORM, AUTOMATION_FORM, ZAPIER_FORM, SHOPIFY_FORM, RESELLER_FORM } from '../redux.constants';
import { resellerFormSelector, shopifyFormSelector, zapierFormSelector } from './landings.selectors';
const PREFIX = 'LANDING_';
export const SENT_STATUS_RESET = `${PREFIX}SENT_STATUS_RESET`;
export const REQUEST_IN_A_BOX_CONTACT_REQUEST = `${PREFIX}REQUEST_IN_A_BOX_CONTACT_REQUEST`;
export const REQUEST_IN_A_BOX_CONTACT_SUCCESS = `${PREFIX}REQUEST_IN_A_BOX_CONTACT_SUCCESS`;
export const REQUEST_IN_A_BOX_CONTACT_FAILURE = `${PREFIX}REQUEST_IN_A_BOX_CONTACT_FAILURE`;
export const requestInABoxContact = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REQUEST_IN_A_BOX_CONTACT_REQUEST
    });
  },
  transformData: (data, getState) => {
    const {
      values
    } = getState().form[IN_A_BOX_FORM];
    return Object.keys(values).reduce((fullData, nextField) => {
      if (nextField === 'shipDate') {
        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        return _objectSpread({}, fullData, {
          [nextField]: values.shipDate.toLocaleDateString('en-US', options)
        });
      }
      return _objectSpread({}, fullData, {
        [nextField]: values[nextField]
      });
    }, {});
  },
  fn: EmailApi.requestInABox,
  success: () => ({
    type: REQUEST_IN_A_BOX_CONTACT_SUCCESS
  }),
  error: error => ({
    type: REQUEST_IN_A_BOX_CONTACT_FAILURE,
    error
  }),
  postSuccess: dispatch => {
    dispatch(_reset(IN_A_BOX_FORM));
    scrollIntoView(ViewIdToScroll);
    setTimeout(() => dispatch({
      type: SENT_STATUS_RESET
    }), 3000);
  }
});
export const REQUEST_ENTERPRISE_REQUEST = `${PREFIX}REQUEST_ENTERPRISE_REQUEST`;
export const REQUEST_ENTERPRISE_SUCCESS = `${PREFIX}REQUEST_ENTERPRISE_SUCCESS`;
export const REQUEST_ENTERPRISE_FAILURE = `${PREFIX}REQUEST_ENTERPRISE_FAILURE`;
export const requestEnterPriceContact = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REQUEST_ENTERPRISE_REQUEST
    });
  },
  transformData: (data, getState) => {
    const {
      values
    } = getState().form[ENTERPRISE_FORM];
    return _objectSpread({}, values, data);
  },
  fn: EmailApi.requestEnterprise,
  success: () => ({
    type: REQUEST_ENTERPRISE_SUCCESS
  }),
  error: error => ({
    type: REQUEST_ENTERPRISE_FAILURE,
    error
  }),
  postSuccess: dispatch => {
    dispatch(_reset(ENTERPRISE_FORM));
    scrollIntoView(ViewIdToScroll);
    setTimeout(() => dispatch({
      type: SENT_STATUS_RESET
    }), 3000);
  }
});
export const REQUEST_PATAGONIA_REQUEST = `${PREFIX}REQUEST_PATAGONIA_REQUEST`;
export const REQUEST_PATAGONIA_SUCCESS = `${PREFIX}REQUEST_PATAGONIA_SUCCESS`;
export const REQUEST_PATAGONIA_FAILURE = `${PREFIX}REQUEST_PATAGONIA_FAILURE`;
export const requestPatagoniaContact = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REQUEST_PATAGONIA_REQUEST
    });
  },
  transformData: (data, getState) => {
    const {
      values
    } = getState().form[PATAGONIA_FORM];
    return _objectSpread({}, values, data);
  },
  fn: EmailApi.requestPatagonia,
  success: () => ({
    type: REQUEST_PATAGONIA_SUCCESS
  }),
  error: error => ({
    type: REQUEST_PATAGONIA_FAILURE,
    error
  }),
  postSuccess: dispatch => {
    dispatch(_reset(PATAGONIA_FORM));
    scrollIntoView(ViewIdToScroll);
    setTimeout(() => dispatch({
      type: SENT_STATUS_RESET
    }), 3000);
  }
});
export const REQUEST_GIVEAWAY_CONTACT_REQUEST = `${PREFIX}REQUEST_GIVEAWAY_CONTACT_REQUEST`;
export const REQUEST_GIVEAWAY_CONTACT_SUCCESS = `${PREFIX}REQUEST_GIVEAWAY_CONTACT_SUCCESS`;
export const REQUEST_GIVEAWAY_CONTACT_FAILURE = `${PREFIX}REQUEST_GIVEAWAY_CONTACT_FAILURE`;
export const requestGiveawayContact = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REQUEST_GIVEAWAY_CONTACT_REQUEST
    });
  },
  transformData: (data, getState) => {
    const {
      values
    } = getState().form[GIVEAWAY_FORM];
    return Object.keys(values).reduce((fullData, nextField) => {
      if ([HUBSPOT_FIELDS.SHIP_DATE, HUBSPOT_FIELDS.DEVIVERED_DATE].includes(nextField)) {
        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        return _objectSpread({}, fullData, {
          [nextField]: values[nextField].toLocaleDateString('en-US', options)
        });
      }
      return _objectSpread({}, fullData, {
        [nextField]: values[nextField]
      });
    }, {});
  },
  fn: EmailApi.requestGiveaway,
  success: () => ({
    type: REQUEST_GIVEAWAY_CONTACT_SUCCESS
  }),
  error: error => ({
    type: REQUEST_GIVEAWAY_CONTACT_FAILURE,
    error
  }),
  postSuccess: dispatch => {
    dispatch(_reset(GIVEAWAY_FORM));
    scrollIntoView(ViewIdToScroll);
    setTimeout(() => dispatch({
      type: SENT_STATUS_RESET
    }), 3000);
  }
});
export const REQUEST_AUTOMATION_CONTACT_REQUEST = `${PREFIX}REQUEST_AUTOMATION_CONTACT_REQUEST`;
export const REQUEST_AUTOMATION_CONTACT_SUCCESS = `${PREFIX}REQUEST_AUTOMATION_CONTACT_SUCCESS`;
export const REQUEST_AUTOMATION_CONTACT_FAILURE = `${PREFIX}REQUEST_AUTOMATION_CONTACT_FAILURE`;
export const requestAutomationContact = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REQUEST_AUTOMATION_CONTACT_REQUEST
    });
  },
  transformData: (data, getState) => {
    const {
      values
    } = getState().form[AUTOMATION_FORM];
    return _objectSpread({}, values, data);
  },
  fn: EmailApi.requestAutomation,
  success: () => ({
    type: REQUEST_AUTOMATION_CONTACT_SUCCESS
  }),
  error: error => ({
    type: REQUEST_AUTOMATION_CONTACT_FAILURE,
    error
  }),
  postSuccess: dispatch => {
    dispatch(_reset(AUTOMATION_FORM));
    scrollIntoView(ViewIdToScroll);
    setTimeout(() => dispatch({
      type: SENT_STATUS_RESET
    }), 3000);
  }
});
export const REQUEST_ZAPIER_CONTACT_REQUEST = `${PREFIX}REQUEST_ZAPIER_CONTACT_REQUEST`;
export const REQUEST_ZAPIER_CONTACT_SUCCESS = `${PREFIX}REQUEST_ZAPIER_CONTACT_SUCCESS`;
export const REQUEST_ZAPIER_CONTACT_FAILURE = `${PREFIX}REQUEST_ZAPIER_CONTACT_FAILURE`;
export const requestZapierContact = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REQUEST_ZAPIER_CONTACT_REQUEST
    });
  },
  transformData: (data, getState) => {
    const {
      values
    } = zapierFormSelector(getState());
    return _objectSpread({}, values, data);
  },
  fn: EmailApi.requestZapier,
  success: () => ({
    type: REQUEST_ZAPIER_CONTACT_SUCCESS
  }),
  error: error => ({
    type: REQUEST_ZAPIER_CONTACT_FAILURE,
    error
  }),
  postSuccess: dispatch => {
    dispatch(_reset(ZAPIER_FORM));
    scrollIntoView(ViewIdToScroll);
    setTimeout(() => dispatch({
      type: SENT_STATUS_RESET
    }), 3000);
  }
});
export const REQUEST_SHOPIFY_CONTACT_REQUEST = `${PREFIX}REQUEST_SHOPIFY_CONTACT_REQUEST`;
export const REQUEST_SHOPIFY_CONTACT_SUCCESS = `${PREFIX}REQUEST_SHOPIFY_CONTACT_SUCCESS`;
export const REQUEST_SHOPIFY_CONTACT_FAILURE = `${PREFIX}REQUEST_SHOPIFY_CONTACT_FAILURE`;
export const requestShopifyContact = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REQUEST_SHOPIFY_CONTACT_REQUEST
    });
  },
  transformData: (data, getState) => {
    const {
      values
    } = shopifyFormSelector(getState());
    return _objectSpread({}, values, data);
  },
  fn: EmailApi.requestShopify,
  success: () => ({
    type: REQUEST_SHOPIFY_CONTACT_SUCCESS
  }),
  error: error => ({
    type: REQUEST_SHOPIFY_CONTACT_FAILURE,
    error
  }),
  postSuccess: dispatch => {
    dispatch(_reset(SHOPIFY_FORM));
    scrollIntoView(ViewIdToScroll);
    setTimeout(() => dispatch({
      type: SENT_STATUS_RESET
    }), 3000);
  }
});
export const REQUEST_RESELLER_FORM_REQUEST = `${PREFIX}REQUEST_RESELLER_FORM_REQUEST`;
export const REQUEST_RESELLER_FORM_SUCCESS = `${PREFIX}REQUEST_RESELLER_FORM_REQUEST_SUCCESS`;
export const REQUEST_RESELLER_FORM_FAILURE = `${PREFIX}REQUEST_RESELLER_FORM_REQUEST_FAILURE`;
export const requestResellerForm = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REQUEST_RESELLER_FORM_REQUEST
    });
  },
  transformData: (data, getState) => {
    const {
      values
    } = resellerFormSelector(getState());
    return _objectSpread({}, values);
  },
  fn: ResellerApi.submitForm,
  success: () => ({
    type: REQUEST_RESELLER_FORM_SUCCESS
  }),
  error: error => ({
    type: REQUEST_RESELLER_FORM_FAILURE,
    error
  }),
  postSuccess: dispatch => {
    dispatch(_reset(RESELLER_FORM));
    scrollIntoView(ViewIdToScroll);
    setTimeout(() => dispatch({
      type: SENT_STATUS_RESET
    }), 3000);
  },
  postError: dispatch => {
    setTimeout(() => dispatch({
      type: SENT_STATUS_RESET
    }), 3000);
  }
});
export const GET_RESELLER_LANDING_DATA_REQUEST = `${PREFIX}GET_RESELLER_LANDING_DATA_REQUEST`;
export const GET_RESELLER_LANDING_DATA_SUCCESS = `${PREFIX}GET_RESELLER_LANDING_DATA_SUCCESS`;
export const GET_RESELLER_LANDING_DATA_FAILURE = `${PREFIX}GET_RESELLER_LANDING_DATA_FAILURE`;
export const getResellerLandingsData = createAsyncAction({
  preFn: dispatch => void dispatch({
    type: GET_RESELLER_LANDING_DATA_REQUEST
  }),
  fn: ResellerApi.getResellerLandingData,
  success: payload => ({
    type: GET_RESELLER_LANDING_DATA_SUCCESS,
    payload
  }),
  error: error => ({
    type: GET_RESELLER_LANDING_DATA_FAILURE,
    error
  })
});