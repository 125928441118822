import { calculateUpsellQuantity } from '../calculate-upsell-quantity';
export function getNextPriceBreakForStandaloneCartItem({
  item,
  product,
  sharedItems,
  quantity,
  percentToNextPriceBreak,
  nextPriceBreak: propsNextPriceBreak,
  selectedVariants,
  shouldApplyInitialPB = false,
  featureFlags
}) {
  const {
    current: associatedProductsQuantity,
    initial: initialAssociatedProductsQuantity
  } = sharedItems.reduce((acc, relatedItem) => {
    if (String(relatedItem._id) === String(item === null || item === void 0 ? void 0 : item._id)) {
      return acc;
    }
    if (relatedItem.splitShippedType) {
      return acc;
    }
    return {
      current: acc.current + relatedItem.quantity,
      initial: acc.initial + (relatedItem.initialQuantity || relatedItem.quantity)
    };
  }, {
    initial: 0,
    current: 0
  });
  const totalQuantity = (quantity || (item === null || item === void 0 ? void 0 : item.quantity) || 0) + associatedProductsQuantity;
  const {
    nextPriceBreak,
    isWithinRange,
    priceBreaks
  } = calculateUpsellQuantity({
    item,
    product,
    quantity: totalQuantity,
    selectedVariants,
    percentToNextPriceBreak,
    featureFlags
  });
  const initialTotalQuantity = ((item === null || item === void 0 ? void 0 : item.initialQuantity) || (item === null || item === void 0 ? void 0 : item.quantity) || 0) + initialAssociatedProductsQuantity;
  const {
    nextPriceBreak: initialPriceBreak
  } = calculateUpsellQuantity({
    item,
    product,
    quantity: initialTotalQuantity,
    selectedVariants,
    percentToNextPriceBreak,
    featureFlags
  });
  const propsNextPriceBreakTotal = propsNextPriceBreak && propsNextPriceBreak + associatedProductsQuantity;
  const initialQuantity = initialPriceBreak && initialPriceBreak - initialAssociatedProductsQuantity;
  let nextPriceBreakToUse = nextPriceBreak;
  const needApplyInitialPBInsteadNextPB = shouldApplyInitialPB && initialPriceBreak && nextPriceBreak && initialPriceBreak > nextPriceBreak;
  if (propsNextPriceBreakTotal) {
    nextPriceBreakToUse = propsNextPriceBreakTotal;
  } else if (needApplyInitialPBInsteadNextPB) {
    nextPriceBreakToUse = initialPriceBreak;
  }
  return {
    nextPriceBreak: nextPriceBreakToUse,
    newQuantity: nextPriceBreakToUse && nextPriceBreakToUse - associatedProductsQuantity,
    initialQuantity,
    isWithinRange,
    priceBreaks
  };
}