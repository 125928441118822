import { connect } from 'react-redux';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { getSuperSpeedSettings } from 'swag-client-common/redux/settings/settings-other.actions';
import { SUPER_SPEED_ERR_MSG } from 'swag-common/constants/error.const';
import { FilterIds } from 'swag-common/constants/main-swag.constants';
import { PROD_TIME } from 'swag-common/constants/product.constants';
import { applyFilter, changeRadioFilter, getProducts, openFilterPopup, resetProducts, setFilterVisibility } from '../../../../../customer/redux/product-listing/product-listing.actions';
import { SuperSpeedButton as Component } from './super-speed-button';
const mapDispatchToProps = dispatch => ({
  onClick: async () => {
    var _payload$config;
    const {
      payload
    } = await dispatch(getSuperSpeedSettings());
    const isSuperSpeedEnabled = payload === null || payload === void 0 ? void 0 : (_payload$config = payload.config) === null || _payload$config === void 0 ? void 0 : _payload$config.enabled;
    if (!isSuperSpeedEnabled) {
      return dispatch(showNotification({
        text: SUPER_SPEED_ERR_MSG
      }));
    }
    dispatch(changeRadioFilter({
      filterId: FilterIds.productionTime,
      filterPropertyValue: PROD_TIME.LESS_THAN_5_DAYS
    }));
    dispatch(applyFilter());
    dispatch(resetProducts());
    dispatch(getProducts());
    dispatch(openFilterPopup());
    dispatch(setFilterVisibility(true));
  }
});
export const SuperSpeedButton = connect(null, mapDispatchToProps)(Component);