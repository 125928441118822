function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { NEW_DEPARTMENT_ID } from 'swag-client-common/utils/company-departments';
import { CREATE_COMPANY_DEPARTMENT_REQUEST, CREATE_COMPANY_DEPARTMENT_SUCCESS, CREATE_COMPANY_DEPARTMENT_ERROR, FETCH_COMPANY_DEPARTMENTS_ERROR, FETCH_COMPANY_DEPARTMENTS_REQUEST, FETCH_COMPANY_DEPARTMENTS_SUCCESS, ADD_BLANK_DEPARTMENT, DELETE_COMPANY_DEPARTMENT_SUCCESS, DELETE_COMPANY_DEPARTMENT_REQUEST, DELETE_COMPANY_DEPARTMENT_ERROR, UPDATE_COMPANY_DEPARTMENT_REQUEST, UPDATE_COMPANY_DEPARTMENT_ERROR, UPDATE_COMPANY_DEPARTMENT_SUCCESS, FETCH_COMPANY_USERS_REQUEST, FETCH_COMPANY_USERS_SUCCESS, FETCH_COMPANY_USERS_ERROR, CANCEL_CREATING_NEW_DEPARTMENT, SELECT_DEPARTMENT_TO_EDIT } from './company-departments.actions';
const initialState = {
  departments: [],
  departmentsLoading: false,
  companyUsers: [],
  companyUsersLoading: false,
  currentEditingDepartmentId: null
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY_DEPARTMENTS_SUCCESS:
      {
        return _objectSpread({}, state, {
          departmentsLoading: false,
          departments: action.payload
        });
      }
    case ADD_BLANK_DEPARTMENT:
      {
        return _objectSpread({}, state, {
          currentEditingDepartmentId: NEW_DEPARTMENT_ID,
          departments: [action.payload].concat(state.departments.filter(item => item._id !== NEW_DEPARTMENT_ID))
        });
      }
    case CREATE_COMPANY_DEPARTMENT_SUCCESS:
      {
        return _objectSpread({}, state, {
          currentEditingDepartmentId: null,
          departmentsLoading: false,
          departments: [action.payload].concat(state.departments.filter(item => item._id !== NEW_DEPARTMENT_ID))
        });
      }
    case UPDATE_COMPANY_DEPARTMENT_REQUEST:
    case DELETE_COMPANY_DEPARTMENT_REQUEST:
    case CREATE_COMPANY_DEPARTMENT_REQUEST:
    case FETCH_COMPANY_DEPARTMENTS_REQUEST:
      {
        return _objectSpread({}, state, {
          departmentsLoading: true
        });
      }
    case FETCH_COMPANY_USERS_REQUEST:
      {
        return _objectSpread({}, state, {
          companyUsersLoading: true
        });
      }
    case FETCH_COMPANY_USERS_ERROR:
      {
        return _objectSpread({}, state, {
          companyUsersLoading: false
        });
      }
    case FETCH_COMPANY_USERS_SUCCESS:
      {
        return _objectSpread({}, state, {
          companyUsersLoading: false,
          companyUsers: action.payload
        });
      }
    case UPDATE_COMPANY_DEPARTMENT_ERROR:
    case DELETE_COMPANY_DEPARTMENT_ERROR:
    case FETCH_COMPANY_DEPARTMENTS_ERROR:
    case CREATE_COMPANY_DEPARTMENT_ERROR:
      {
        return _objectSpread({}, state, {
          departmentsLoading: false
        });
      }
    case DELETE_COMPANY_DEPARTMENT_SUCCESS:
      {
        return _objectSpread({}, state, {
          departmentsLoading: false,
          departments: state.departments.filter(item => item._id !== action.payload)
        });
      }
    case CANCEL_CREATING_NEW_DEPARTMENT:
      {
        return _objectSpread({}, state, {
          currentEditingDepartmentId: null,
          departments: state.departments.filter(item => item._id !== NEW_DEPARTMENT_ID)
        });
      }
    case SELECT_DEPARTMENT_TO_EDIT:
      {
        return _objectSpread({}, state, {
          currentEditingDepartmentId: action.payload
        });
      }
    case UPDATE_COMPANY_DEPARTMENT_SUCCESS:
      {
        return _objectSpread({}, state, {
          currentEditingDepartmentId: null,
          departmentsLoading: false,
          departments: state.departments.map(item => {
            if (item._id !== action.payload._id) {
              return item;
            }
            return _objectSpread({}, item, action.payload);
          })
        });
      }
    default:
      {
        return state;
      }
  }
}