import _find from "lodash/find";
import _get from "lodash/get";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { userIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { HISTORY_TABS } from 'swag-client-common/constants';
import { canUserManagePermissions, canUserTakeFrom } from 'swag-common/business-logic/inventory';
import { canPullOnlyFromInventory, canPutOnlyFromInventory, getPermission, getUserIds, getUserPermissionLevel, isAdmin, isOwner } from 'swag-common/business-logic/inventory/permissions-checker.logic';
import { INVENTORY_HISTORY_TYPES, INVENTORY_PERMISSION_LEVELS } from 'swag-common/constants/inventory';
import { CREDIT_CARD } from 'swag-common/constants/payment-methods';
import { extendDemoInventoryWithRoles } from 'swag-common/utils/inventory/extend-demo-inventory-with-roles.util';
import { isInventoryBuyCreditsCommonPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { MAX_TO_BUY_CREDITS_AMOUNT, MAX_TO_BUY_CREDITS_AMOUNT_WITH_DECIMALS, MIN_TO_BUY_CREDITS_AMOUNT_WITH_DECIMALS, MIN_WITHDRAW_CREDITS_AMOUNT } from 'swag-common/constants/credits';
import { addDay, getFirstDayOfMonth, isAfter, isBefore, isBetween, setStartOfYear, subtractMonthsFromUTCDate, subtractYears } from 'swag-common/utils/date-lib';
import { Price } from 'swag-client-common/utils/price';
const {
  ADMIN,
  OWNER
} = INVENTORY_PERMISSION_LEVELS;
const EXCLUDED_ACTIONS = [INVENTORY_HISTORY_TYPES.USER_DEFAULT, INVENTORY_HISTORY_TYPES.CREATE_CARD, INVENTORY_HISTORY_TYPES.UPDATE_CARD, INVENTORY_HISTORY_TYPES.DELETE_CARD];
const shouldBeApprovedPermissions = [INVENTORY_PERMISSION_LEVELS.PULL, INVENTORY_PERMISSION_LEVELS.PUSH_AND_PULL];
export const inventorySelector = state => state.inventory;
export const inventoriesSelector = createSelector(inventorySelector, inventory => inventory.list);
export const isLoadedInventoryEnteredSelector = createSelector(inventorySelector, inventory => inventory.loadingPageDataNumber === 0);
export const inventoriesFromUnfreezeNotificationSelector = createSelector(inventorySelector, state => state.fromUnfreezeNotification);
export const selectedInventorySelector = createSelector(inventorySelector, inventory => inventory.selectedInventory);
export const demoInventoryNotExtendedSelector = createSelector(inventorySelector, inventory => inventory.demoInventory ? inventory.demoInventory : null);
export const demoInventorySelector = createSelector(demoInventoryNotExtendedSelector, userIdSelector, inventoriesSelector, (demoInventory, userId, list) => {
  return demoInventory ? extendDemoInventoryWithRoles(demoInventory, list, userId) : null;
});
export const demoInventoryIdSelector = createSelector(demoInventorySelector, demo => demo ? demo._id : null);
export const isSelectedDemoInventorySelector = createSelector(selectedInventorySelector, demoInventoryIdSelector, (selected, demo) => selected === demo);
export const shouldShowDemoInventorySelector = createSelector(inventoriesSelector, inventories => inventories.every(inv => inv.isEmpty));
export const isInventoriesExistsSelector = createSelector(inventoriesSelector, inventories => inventories.length > 0);
export const isSelectedInventoryLoadedSelector = createSelector(selectedInventorySelector, selectedInventory => selectedInventory !== null);
export const selectedFullInventorySelector = createSelector(inventoriesSelector, selectedInventorySelector, demoInventorySelector, (inventories, id, demoInventory) => {
  return inventories.find(({
    _id
  }) => _id === id) || demoInventory;
});
export const currentInventoryCreatedAtSelector = createSelector(selectedFullInventorySelector, inventory => inventory === null || inventory === void 0 ? void 0 : inventory.createdAt);
export const isCurrentInventoryFrozenSelector = createSelector(selectedFullInventorySelector, inventory => Boolean(inventory === null || inventory === void 0 ? void 0 : inventory.frozenAt));
export const inventoriesToShowOnSelectSelector = createSelector(inventoriesSelector, demoInventorySelector, (inventories, demo) => demo ? [...inventories, demo] : inventories);
export const isApprovalFlowEnabledSelector = createSelector(selectedFullInventorySelector, inventory => inventory && (inventory.isRoleApprovalEnabled || inventory.isUserApprovalEnabled));
export const canTakeFromInventorySelector = createSelector(selectedFullInventorySelector, userIdSelector, (inventory, userId) => !!inventory && canUserTakeFrom(inventory, userId));
export const isNotPutUserRollSelector = createSelector(selectedFullInventorySelector, userIdSelector, (inventory, userId) => {
  if (!inventory) {
    return false;
  }
  return !canPutOnlyFromInventory(inventory, userId);
});
export const canDistributeFromInventorySelector = createSelector(selectedFullInventorySelector, userIdSelector, (inventory, userId) => {
  if (!inventory) {
    return false;
  }
  return canPullOnlyFromInventory(inventory, userId);
});
export const isAdminOrOwnerUserHasSelector = createSelector(selectedFullInventorySelector, userIdSelector, (inventory, userId) => {
  if (!inventory) {
    return false;
  }
  const {
    adminsIds,
    ownerId
  } = inventory;
  return isAdmin(adminsIds, userId) || isOwner(ownerId, userId);
});
export const isOrderMoreAvailableSelector = createSelector(canDistributeFromInventorySelector, isDistributeRole => !isDistributeRole);
export const isOrderMoreShownSelector = createSelector((state, props) => props.giveaway, isOrderMoreAvailableSelector, (giveaway, isAvailable) => !!giveaway.isNeedStock && isAvailable);
export const isPayFeesShownSelector = createSelector((state, props) => props.giveaway, isNotPutUserRollSelector, (giveaway, isNotPutUserRoll) => (giveaway.currentFees > 0 || giveaway.linksWithZeroAmount > 0) && isNotPutUserRoll);
export const isInventoryAdminSelector = createSelector(selectedFullInventorySelector, userIdSelector, (inventory, userId) => {
  if (!inventory) {
    return false;
  }
  return canUserManagePermissions(inventory, userId);
});
export const allowedInventoriesWithoutCurrentSelector = createSelector(inventoriesSelector, selectedInventorySelector, userIdSelector, (inventories, id, userId) => inventories.filter(inventory => inventory._id !== id && canUserTakeFrom(inventory, userId) && !canPullOnlyFromInventory(inventory, userId)));
const getFirstInventoriesFromList = inventories => inventories.length > 0 ? inventories[0]._id : null;
export const firstInventoryIdSelector = createSelector(allowedInventoriesWithoutCurrentSelector, getFirstInventoriesFromList);
export const firstInventoryIdSelectorNotIn = inId => createSelector(createSelector(inventoriesSelector, demoInventorySelector, (inventories, demo) => [...inventories.filter(({
  _id
}) => _id.toString() !== inId), demo]), getFirstInventoriesFromList);
export const currentInventoryNameSelector = createSelector(selectedFullInventorySelector, inventory => inventory && inventory.name || '');
export const currentInventoryTabNameSelector = createSelector(currentInventoryNameSelector, inventoryName => inventoryName && `${inventoryName} - Swag distribution`);
export const inventoryModeSelector = createSelector(inventorySelector, inventory => inventory.mode);
export const inventoryUsersSelector = createSelector(inventorySelector, inventory => inventory.users);
export const inventoryPendingUsersSelector = createSelector(inventorySelector, inventory => {
  var _inventory$users;
  return ((_inventory$users = inventory.users) === null || _inventory$users === void 0 ? void 0 : _inventory$users.pendingUsers) || [];
});
export const inventoryGroupPermissionLevelSelector = createSelector(inventorySelector, inventory => inventory.permissions.groupPermissionLevel);
export const inventoryNewUsersSelector = createSelector(inventorySelector, inventory => inventory.permissions.newUsers);
export const inventoryUpdatedPermissionsSelector = createSelector(inventorySelector, inventory => inventory.permissions.updatedPermissions);
export const inventoryUsersPermissionsSelector = createSelector(inventoryUsersSelector, users => {
  if (!users) {
    return [];
  }
  const {
    ownerId,
    canPutUserIds,
    canTakeUserIds,
    adminsIds,
    pendingUsers = []
  } = users;
  if (canPutUserIds && canTakeUserIds) {
    const pushOrPull = [...canPutUserIds, ...canTakeUserIds].reduce((acc, user) => acc.find(({
      _id
    }) => _id === user._id) ? acc : [...acc, user], []);
    return [_objectSpread({}, ownerId, {
      permission: OWNER
    }), ...adminsIds.map(user => _objectSpread({}, user, {
      permission: ADMIN
    })), ...pushOrPull.map(user => _objectSpread({}, user, {
      permission: getPermission(user._id, getUserIds(canTakeUserIds), getUserIds(canPutUserIds))
    })), ...pendingUsers.map(user => _objectSpread({}, user, {
      firstName: '',
      lastName: ''
    }))].sort((a, b) => new Date(a.createdAt || 0).getTime() - new Date(b.createdAt || 0).getTime());
  }
  return [];
});
export const inventoryCurrentUsersInfoSelector = createSelector(inventoryUsersPermissionsSelector, inventoryUpdatedPermissionsSelector, (users, permissions) => users.map(({
  _id,
  email,
  firstName,
  lastName,
  permission,
  status
}) => ({
  _id,
  name: `${firstName} ${lastName}`.trim() || email,
  email,
  permission: permissions[_id] || permission,
  status
})));
export const ownInventoriesSelector = createSelector(userIdSelector, inventoriesSelector, (userId, inventories) => {
  const ownInventories = inventories.filter(inv => inv.ownerId === userId);
  return ownInventories;
});
export const demoInventoriesSelector = createSelector(demoInventorySelector, shouldShowDemoInventorySelector, (demo, shouldShowDemo) => shouldShowDemo && demo ? [demo] : []);
export const sharedInventoriesSelector = createSelector(userIdSelector, inventoriesSelector, (userId, inventories) => inventories.filter(inv => inv.ownerId !== userId));
export const ownInventoriesWithoutCurrentSelector = createSelector(userIdSelector, allowedInventoriesWithoutCurrentSelector, (userId, inventories) => inventories.filter(inv => inv.ownerId === userId));
export const sharedInventoriesWithoutCurrentSelector = createSelector(userIdSelector, allowedInventoriesWithoutCurrentSelector, (userId, inventories) => inventories.filter(inv => {
  return inv.ownerId !== userId && !canPullOnlyFromInventory(inv, userId);
}));
export const inventoryPaymentMethodSelector = createSelector(selectedFullInventorySelector, inventory => inventory ? inventory.paymentMethod : CREDIT_CARD);
export const paymentMethodSelector = createSelector(inventorySelector, inventory => inventory.paymentMethod);
const exportDateSelector = state => state.inventory.exportDate;
export const exportBarDataSelector = createSelector(exportDateSelector, exportDate => _objectSpread({}, exportDate, {
  exportToPlusDay: addDay(new Date())
}));
export const pendingActivities = createSelector(inventorySelector, inventory => inventory.pendingActivities);
export const isLoadedPendingActivitiesByInventoryIdSelector = inventoryId => createSelector(pendingActivities, pendingActivitiesObject => !!pendingActivitiesObject[inventoryId]);
export const isLoadedPendingActivitiesSelectedInventorySelector = createSelector(selectedInventorySelector, pendingActivities, (inventoryId, pendingActivitiesObject) => inventoryId && !!pendingActivitiesObject[inventoryId]);
export const pendingActivitiesInSelectedInventory = createSelector(pendingActivities, selectedInventorySelector, (pendingActivitiesObject, inventoryId) => inventoryId ? pendingActivitiesObject[inventoryId] : {});
export const hasPendingActivitiesInSelectedInventory = createSelector(pendingActivitiesInSelectedInventory, pendingActivities => {
  return !!pendingActivities && (!!pendingActivities.customWarehouseInvoicesCount || !!pendingActivities.shipouts.length || !!pendingActivities.transferProducts.length || !!pendingActivities.ongoingGiveaways.length);
});
export const userLastInventoryIdSelector = createSelector(inventorySelector, demoInventoryIdSelector, (inventory, demoInventoryId) => inventory.lastInventoryId || demoInventoryId);
export const userInventoryToShowSelector = createSelector(inventorySelector, demoInventoryIdSelector, (inventory, demoInventoryId) => inventory.lastInventoryId || demoInventoryId);
export const inventoryNameByInventoryIdSelector = inventoryId => createSelector(inventoriesSelector, inventories => _get(_find(inventories, {
  _id: inventoryId
}), 'name', ''));
export const isSoleOwnerInventorySelector = createSelector(selectedFullInventorySelector, inventory => {
  if (inventory) {
    return inventory.adminsIds.filter(id => id !== inventory.ownerId).length === 0 && inventory.canPutUserIds.filter(id => id !== inventory.ownerId).length === 0 && inventory.canTakeUserIds.filter(id => id !== inventory.ownerId).length === 0;
  }
  return false;
});
const historyRootSelector = createSelector(inventorySelector, inventory => inventory.history);
const historySelector = createSelector(historyRootSelector, history => history.list);
export const historyActiveTabSelector = createSelector(historyRootSelector, history => history.activeTab);
export const isInventoryHistoryLoaded = createSelector(historyRootSelector, history => history.isLoaded);
export const hasMoreHistoryItemsSelector = createSelector(historyRootSelector, history => history.hasMore);
export const thisMonthHistorySelector = createSelector(userIdSelector, historyActiveTabSelector, historySelector, (userId, activeTab, history) => {
  const startOfThisMonth = getFirstDayOfMonth(new Date());
  let filter;
  if (activeTab === HISTORY_TABS.JUST_YOU) {
    filter = item => {
      var _item$authorId;
      return isAfter(new Date(item.eventTime), startOfThisMonth) && ((_item$authorId = item.authorId) === null || _item$authorId === void 0 ? void 0 : _item$authorId._id.toString()) === userId.toString();
    };
  } else {
    filter = item => isAfter(new Date(item.eventTime), startOfThisMonth) && !EXCLUDED_ACTIONS.includes(item.type);
  }
  return history.filter(filter);
});
export const lastMonthHistorySelector = createSelector(userIdSelector, historyActiveTabSelector, historySelector, (userId, activeTab, history) => {
  const startOfThisMonth = getFirstDayOfMonth(new Date());
  const startOfLastMonth = getFirstDayOfMonth(subtractMonthsFromUTCDate({
    sourceDate: new Date(),
    numberOfMonths: 1
  }));
  let filter;
  if (activeTab === HISTORY_TABS.JUST_YOU) {
    filter = item => {
      var _item$authorId2;
      return isBetween(new Date(item.eventTime), {
        from: startOfLastMonth,
        to: startOfThisMonth
      }) && ((_item$authorId2 = item.authorId) === null || _item$authorId2 === void 0 ? void 0 : _item$authorId2._id.toString()) === userId.toString();
    };
  } else {
    filter = item => isBetween(new Date(item.eventTime), {
      from: startOfLastMonth,
      to: startOfThisMonth
    }) && !EXCLUDED_ACTIONS.includes(item.type);
  }
  return history.filter(filter);
});
export const thisYearHistorySelector = createSelector(userIdSelector, historyActiveTabSelector, historySelector, (userId, activeTab, history) => {
  const startOfThisYear = setStartOfYear(new Date());
  const startOfLastMonth = getFirstDayOfMonth(subtractMonthsFromUTCDate({
    sourceDate: new Date(),
    numberOfMonths: 1
  }));
  let filter;
  if (activeTab === HISTORY_TABS.JUST_YOU) {
    filter = item => {
      var _item$authorId3;
      return isBetween(new Date(item.eventTime), {
        from: startOfThisYear,
        to: startOfLastMonth
      }) && ((_item$authorId3 = item.authorId) === null || _item$authorId3 === void 0 ? void 0 : _item$authorId3._id.toString()) === userId.toString();
    };
  } else {
    filter = item => isBetween(new Date(item.eventTime), {
      from: startOfThisYear,
      to: startOfLastMonth
    }) && !EXCLUDED_ACTIONS.includes(item.type);
  }
  return history.filter(filter);
});
export const lastYearHistorySelector = createSelector(userIdSelector, historyActiveTabSelector, historySelector, (userId, activeTab, history) => {
  const startOfLastYear = setStartOfYear(subtractYears(new Date(), 1));
  const startOfThisYear = setStartOfYear(new Date());
  let filter;
  if (activeTab === HISTORY_TABS.JUST_YOU) {
    filter = item => {
      var _item$authorId4;
      return isBetween(new Date(item.eventTime), {
        from: startOfLastYear,
        to: startOfThisYear
      }) && ((_item$authorId4 = item.authorId) === null || _item$authorId4 === void 0 ? void 0 : _item$authorId4._id.toString()) === userId.toString();
    };
  } else {
    filter = item => isBetween(new Date(item.eventTime), {
      from: startOfLastYear,
      to: startOfThisYear
    }) && !EXCLUDED_ACTIONS.includes(item.type);
  }
  return history.filter(filter);
});
export const longAgoHistorySelector = createSelector(userIdSelector, historyActiveTabSelector, historySelector, (userId, activeTab, history) => {
  const startOfLastYear = setStartOfYear(subtractYears(new Date(), 1));
  let filter;
  if (activeTab === HISTORY_TABS.JUST_YOU) {
    filter = item => {
      var _item$authorId5;
      return isBefore(new Date(item.eventTime), startOfLastYear) && ((_item$authorId5 = item.authorId) === null || _item$authorId5 === void 0 ? void 0 : _item$authorId5._id.toString()) === userId.toString();
    };
  } else {
    filter = item => isBefore(new Date(item.eventTime), startOfLastYear) && !EXCLUDED_ACTIONS.includes(item.type);
  }
  return history.filter(filter);
});
export const isHistoryEmptySelector = state => {
  return state.inventory.history.list.length === 0;
};
export const inventoryAdminSelector = createSelector(inventoryUsersSelector, users => users ? [users.ownerId, ...users.adminsIds] : []);
export const inventoryActivitiesSearchSelector = createSelector(inventorySelector, inventory => inventory.activitiesSearch);
export const inventoryActivitiesSearchQuerySelector = createSelector(inventoryActivitiesSearchSelector, search => search.query);
export const inventoryActivitiesSearchResultsSelector = createSelector(inventoryActivitiesSearchSelector, search => search.results);
export const inventoryActivitiesSearchResultsCountSelector = createSelector(inventoryActivitiesSearchResultsSelector, results => results ? results.length : 0);
export const inventoryActivitiesSearchResultIndexSelector = createSelector(inventoryActivitiesSearchSelector, search => search.activeResultIndex);
export const inventoryActivitiesSearchIsLoadedSelector = createSelector(inventoryActivitiesSearchSelector, search => search.isLoaded);
export const inventoryActivitiesSearchShouldShowAdditionalText = createSelector(inventoryActivitiesSearchQuerySelector, inventoryActivitiesSearchIsLoadedSelector, (query, isLoaded) => isLoaded && query && query.length > 0);
export const inventoryActivitiesSearchActiveResult = createSelector(inventoryActivitiesSearchResultsSelector, inventoryActivitiesSearchResultIndexSelector, (results, i) => results ? results[i] : null);
export const inventoryActivitiesSearchActiveResultReceiversIds = createSelector(inventoryActivitiesSearchResultsSelector, inventoryActivitiesSearchActiveResult, (results, activeResult) => activeResult ? results.map(res => res.receiverId).filter(Boolean) : []);
export const inventoryCreditsHasFulfilledBuyTransactionsSelector = createSelector(inventorySelector, inventory => inventory.inventoryCreditsHasFulfilledBuyTransactions);
export const inventoryCreditsRootSelector = createSelector(inventorySelector, inventory => inventory.credits);
export const inventoryCreditsCurrentValueSelector = createSelector(inventoryCreditsRootSelector, credits => (credits === null || credits === void 0 ? void 0 : credits.available) || 0);
export const inventoryCreditsPendingValueSelector = createSelector(inventoryCreditsRootSelector, credits => (credits === null || credits === void 0 ? void 0 : credits.pending) || 0);
export const inventoryCreditsHasSomeCreditsSelector = createSelector(inventoryCreditsCurrentValueSelector, inventoryCreditsPendingValueSelector, (current, pending) => current > 0 || pending > 0);
export const pendingCreditsAmountSelector = createSelector(inventoryCreditsPendingValueSelector, pendingValue => Price.formatNumber(pendingValue));
export const currentCreditsAmountSelector = createSelector(inventoryCreditsCurrentValueSelector, currentValue => Price.formatNumber(currentValue));
export const isInventoryCreditsCurrentValueExistsSelector = createSelector(inventoryCreditsCurrentValueSelector, credits => credits > 0);
export const isInventoryCreditsPendingValueExistsSelector = createSelector(inventoryCreditsPendingValueSelector, pending => pending > 0);
export const isCreditsExistsSelector = createSelector(isInventoryCreditsCurrentValueExistsSelector, isInventoryCreditsPendingValueExistsSelector, (isCreditsExists, isPendingExists) => isCreditsExists || isPendingExists);
export const isInventoryCreditsAvailableSelector = createSelector(inventoryCreditsCurrentValueSelector, credits => credits > 0);
export const isInventoryPayByCreditsAvailableSelector = createSelector(isInventoryCreditsAvailableSelector, isInventoryBuyCreditsCommonPopupShownSelector, inventoryCreditsHasFulfilledBuyTransactionsSelector, (isCreditsAvailable, isInventoryBuyCreditsCommonPopupShown, inventoryCreditsHasFulfilledBuyTransactions) => {
  if (!inventoryCreditsHasFulfilledBuyTransactions) {
    return false;
  }
  return isCreditsAvailable && !isInventoryBuyCreditsCommonPopupShown;
});
export const canTransferCreditsSelector = createSelector(allowedInventoriesWithoutCurrentSelector, inventories => Boolean(inventories.length));
export const isCreditsMoreToBuyFlowSelector = createSelector(inventorySelector, inventory => inventory.isCreditsMoreToBuyFlow);
export const creditsToBuySelector = createSelector(inventorySelector, inventory => inventory.creditsToBuy);
export const creditsMoreToBuySelector = createSelector(inventorySelector, inventory => inventory.creditsMoreToBuy);
export const creditsPriceAmountToBuySelector = createSelector(creditsToBuySelector, creditsToBuy => Price.addDecimalToNumber(creditsToBuy));
export const creditsToWithdrawSelector = createSelector(inventoryCreditsRootSelector, inventorySelector, ({
  available
}, {
  creditsToWithdraw
}) => {
  const availableInDollars = Math.floor(available / 100);
  if (creditsToWithdraw === 0) {
    return availableInDollars >= 1000 ? 1000 : availableInDollars;
  }
  if (creditsToWithdraw === null) {
    return 0;
  }
  return Math.floor(creditsToWithdraw);
});
export const withdrawErrorSelector = createSelector(inventoryCreditsCurrentValueSelector, creditsToWithdrawSelector, inventoryCreditsPendingValueSelector, (creditsAvailable, creditsToWithdraw, creditsPending) => {
  const toWithdraw = Price.addDecimalToNumber(creditsToWithdraw);
  switch (true) {
    case toWithdraw > MAX_TO_BUY_CREDITS_AMOUNT_WITH_DECIMALS:
      {
        return `Sorry, quantity should not be more than ${MAX_TO_BUY_CREDITS_AMOUNT}`;
      }
    case toWithdraw < MIN_TO_BUY_CREDITS_AMOUNT_WITH_DECIMALS:
      {
        return `Sorry, quantity should not be less than ${MIN_WITHDRAW_CREDITS_AMOUNT}`;
      }
    case toWithdraw > creditsAvailable && creditsPending > 0:
      {
        return 'You are only able to withdraw the available credits that you have. Since you have not paid for your pending credits, there is nothing to withdraw.';
      }
    case toWithdraw > creditsAvailable:
      {
        return 'You are only able to withdraw the available credits that you have.';
      }
    default:
      {
        return null;
      }
  }
});
export const purchaseFlowSelector = createSelector(inventorySelector, inventory => inventory.purchaseFlow);
export const forceScreenToShowSelector = createSelector(inventorySelector, inventory => inventory.forceScreenToShow);
const inventoryApprovalSettingsSelector = createSelector(selectedFullInventorySelector, inventory => {
  return inventory === null || inventory === void 0 ? void 0 : inventory.approvalSettings;
});
const inventoryHasApprovalSettingsSelector = createSelector(inventoryApprovalSettingsSelector, approvalSettings => {
  if (!approvalSettings) {
    return false;
  }
  if (approvalSettings.length > 0) {
    return true;
  }
  return false;
});
export const userRoleBasedAmountApprovalSelector = createSelector(inventoryHasApprovalSettingsSelector, inventoryApprovalSettingsSelector, isApprovalFlowEnabledSelector, selectedFullInventorySelector, userIdSelector, (hasApprovalSettings, approvalSettings, isApprovalEnabled, inventory, userId) => {
  if (!isApprovalEnabled) {
    return {
      isApprovalFlow: false,
      amount: 0
    };
  }
  if (!(inventory !== null && inventory !== void 0 && inventory.isRoleApprovalEnabled)) {
    return {
      isApprovalFlow: false,
      amount: 0
    };
  }
  if (!inventory) {
    return {
      isApprovalFlow: false,
      amount: 0
    };
  }
  if (!hasApprovalSettings) {
    return {
      isApprovalFlow: false,
      amount: 0
    };
  }
  const enabledRoleBasedApprovalSettings = approvalSettings.filter(({
    role,
    isEnabled
  }) => role !== null && isEnabled === true);
  const userPermission = getUserPermissionLevel(inventory, userId);
  const approvalSetting = enabledRoleBasedApprovalSettings.find(({
    role
  }) => role === userPermission);
  if (approvalSetting && shouldBeApprovedPermissions.includes(userPermission)) {
    return {
      isApprovalFlow: true,
      amount: approvalSetting.amount
    };
  }
  return {
    isApprovalFlow: false,
    amount: 0
  };
});
export const userBasedAmountApprovalSelector = createSelector(inventoryHasApprovalSettingsSelector, inventoryApprovalSettingsSelector, userIdSelector, selectedFullInventorySelector, (hasApprovalSettings, inventoryApprovalSettings, userIdSelector, inventory) => {
  if (!hasApprovalSettings) {
    return {
      isApprovalFlow: false,
      amount: 0
    };
  }
  if (!(inventory !== null && inventory !== void 0 && inventory.isUserApprovalEnabled)) {
    return {
      isApprovalFlow: false,
      amount: 0
    };
  }
  const userBasedApprovalSettings = inventoryApprovalSettings.find(({
    userId,
    isEnabled
  }) => userId === userIdSelector && isEnabled === true);
  if (!userBasedApprovalSettings) {
    return {
      isApprovalFlow: false,
      amount: 0
    };
  }
  return {
    isApprovalFlow: true,
    amount: userBasedApprovalSettings.amount
  };
});