const paymentFormFieldToLabelMap = {
  firstName: 'First name',
  lastName: 'Last name',
  city: 'City',
  country: 'Country',
  street: 'Street',
  zipcode: 'Zip/Postal Code',
  holder: 'Holder name',
  number: 'Card number',
  cvc: 'CVV code',
  expDate: 'Expiration date'
};
export const buildError = (errors, fields) => {
  const fieldsErrors = Object.keys(errors);
  const {
    length
  } = fieldsErrors;
  return fieldsErrors.reduce((notification, key, index) => {
    if (!fields[key]) {
      return notification;
    }
    const {
      visited,
      touched
    } = fields[key];
    if (visited && touched) {
      const fieldError = errors[key] ? errors[key] : '';
      if (!fieldError) {
        return notification;
      }
      const separator = length > 1 && length - 1 === index ? ' and' : ',';
      return `${notification ? '' : 'Invalid '}${notification}${notification ? separator : ''} ${paymentFormFieldToLabelMap[key]}`;
    }
    return notification;
  }, '');
};