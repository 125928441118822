import { createSelector } from 'reselect';
import { cartItemsSelector, cartOrderIdSelector, isTaxExemptSelector } from 'swag-client-common/redux/cart/cart.selectors';
import { selectedDistributeOptionSelector, selectedDistributionAreaSelector } from '../inventory/inventory.selectors';
import { formatCartToInvoiceLineItems, formatInvoiceSavePayload } from './reseller-invoice.utils';
const resellerInvoiceRootSelector = state => state.resellerInvoice;
export const invoiceDiscountDataSelector = createSelector(resellerInvoiceRootSelector, state => state.discountData);
export const paymentTermsSelector = createSelector(resellerInvoiceRootSelector, state => state.paymentTerms);
export const invoiceLineItemsSelector = createSelector(resellerInvoiceRootSelector, state => state.lineItems);
export const resellerCommissionSelector = createSelector(resellerInvoiceRootSelector, state => state.resellerCommission);
export const resellerTierCommissionSelector = createSelector(resellerInvoiceRootSelector, state => state.resellerTierCommission);
export const resellerBonusCommissionSelector = createSelector(resellerInvoiceRootSelector, state => state.resellerBonusCommission);
export const resellerBonusCommissionThresholdSelector = createSelector(resellerInvoiceRootSelector, state => state.resellerBonusCommissionThreshold);
export const invoiceLineItemsInitialSelector = createSelector(resellerTierCommissionSelector, cartItemsSelector, resellerBonusCommissionSelector, resellerBonusCommissionThresholdSelector, (resellerTierCommission, cartItems, resellerBonusCommission, resellerBonusCommissionThreshold) => {
  return formatCartToInvoiceLineItems({
    resellerTierCommission,
    cartItems,
    resellerBonusCommission,
    resellerBonusCommissionThreshold
  });
});
export const invoiceLineItemsErrorsSelector = createSelector(resellerInvoiceRootSelector, state => state.errors);
export const invoiceSavePayloadSelector = createSelector(cartOrderIdSelector, invoiceLineItemsSelector, invoiceDiscountDataSelector, paymentTermsSelector, formatInvoiceSavePayload);
export const isLoadingSelector = createSelector(resellerInvoiceRootSelector, state => state.loading);
export const isCreateInvoiceDisabledSelector = createSelector(isLoadingSelector, invoiceLineItemsErrorsSelector, (isLoading, errors) => {
  return isLoading || !!Object.keys(errors).length;
});
export const isInventoryContractSignedSelector = createSelector(resellerInvoiceRootSelector, ({
  isInventoryContractSigned
}) => isInventoryContractSigned);
export const isInventoryContractSubmitBtnDisabledSelector = createSelector(selectedDistributeOptionSelector, selectedDistributionAreaSelector, (distributeOption, distributionArea) => !distributeOption || !distributionArea);
export const invoiceLineItemsIsEverySampleItemSelector = createSelector(invoiceLineItemsSelector, lineItems => {
  return lineItems.every(({
    isSample
  }) => isSample);
});
export const invoiceLineItemsHasSampleItemSelector = createSelector(invoiceLineItemsSelector, lineItems => {
  return lineItems.some(({
    isSample
  }) => isSample);
});
export const bonusCommissionMessageSelector = createSelector(invoiceLineItemsSelector, resellerBonusCommissionSelector, resellerBonusCommissionThresholdSelector, invoiceLineItemsIsEverySampleItemSelector, (lineItems, bonusCommission, bonusCommissionThreshold, isEverySampleItem) => {
  const orderTotal = lineItems.reduce((acc, el) => acc + parseFloat(el.userItemPrice) * el.quantity * 100, 0);
  if (!bonusCommission || orderTotal > bonusCommissionThreshold || isEverySampleItem) {
    return '';
  }
  return `Congrats! You have a bonus commission on this order. When you ultimately place your order on our site you will make an additional ${bonusCommission}% commission.`;
});
export const isTaxInputDisabledSelector = createSelector(invoiceDiscountDataSelector, isTaxExemptSelector, (invoiceDiscountData, isTaxExempt) => {
  return invoiceDiscountData.freeTax || isTaxExempt;
});