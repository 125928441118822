function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { browserHistory } from 'react-router';
import { CHANGE_COLOR_LOGO } from '../../../customer/redux/logo-editing/logo-editing.actions';
import { CHAT_REQUIRED } from '../../../customer/redux/common/common.actions';
export function intercomMiddleware() {
  return next => action => {
    switch (action.type) {
      case CHANGE_COLOR_LOGO:
        {
          // @ts-ignore
          browserHistory.replace(_objectSpread({}, window.location, {
            search: '?logo-uploaded=true'
          }));
          Intercom('update', {});
          break;
        }
      case CHAT_REQUIRED:
        {
          Intercom('show');
          break;
        }
      default:
    }
    next(action);
  };
}