import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { HEADER_CATEGORY, HEADER_PRODUCT_LISTING, HEADER_SEARCH_RESULTS } from 'swag-client-common/utils/constants';
import { pathSelector } from '../../redux/common/common.selectors';
import SuspenseFallback from './suspense-fallback';
const SHOW_VIOLET_HEADER_ROUTES = ['', 'collections', 'kickstarter', 'techstars-products', 'spring-products', 'search-results', 'inventory', 'giveaway'];
const headerIsVioletSelector = createSelector(pathSelector, path => SHOW_VIOLET_HEADER_ROUTES.includes(path));
const otherHeaderPropsSelector = createSelector(pathSelector, path => {
  const isBrowseProductPage = path.includes('collections');
  if (isBrowseProductPage) {
    return {
      header: HEADER_PRODUCT_LISTING,
      headerIsSticky: true,
      showCategories: true
    };
  }
  const isSearchProductPage = path.includes('search-results');
  if (isSearchProductPage) {
    return {
      header: HEADER_SEARCH_RESULTS,
      headerIsSticky: true,
      showCategories: true
    };
  }
  return {
    header: HEADER_CATEGORY
  };
});
const mapStateToProps = createStructuredSelector({
  headerIsViolet: headerIsVioletSelector,
  otherHeaderProps: otherHeaderPropsSelector
});
export default connect(mapStateToProps)(SuspenseFallback);