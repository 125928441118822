import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { loadingSelector } from '../../../redux/pending-orders/pending-orders.selectors';
import { confirmAction } from '../../../redux/pending-orders/pending-orders.actions';
import PendingOrderConfirmActionPopup from './pending-order-confirm-action-popup';
const mapStateToProps = createStructuredSelector({
  loading: loadingSelector
});
const mapDispatchToProps = dispatch => ({
  onActionConfirm: ({
    id,
    action
  }) => {
    dispatch(confirmAction({
      id,
      action
    }));
  },
  onCancel: () => {
    dispatch(hidePopup());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PendingOrderConfirmActionPopup);