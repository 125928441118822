export default function isProductOutOfStock(variant) {
  const {
    quantity,
    inProductionQuantity,
    lastOrderQuantity
  } = variant;
  if (lastOrderQuantity === 0) {
    return false;
  }

  // @todo. auto generated. fix this error
  // @ts-ignore
  return quantity + inProductionQuantity === 0;
}