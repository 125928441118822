import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { v4 as uuidv4 } from 'uuid';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { checkIsApparel, getTotalCount } from 'swag-client-common/utils/product.utils';
import { isItemBrownBox } from 'swag-common/utils/order/item-is-box.util';
import { DesignUploadMode } from 'swag-common/interfaces';
import { subtractQuantities } from 'swag-client-common/utils/order-item.utils';
import { makeFakeAddonItemForCart } from './box-builder.utils';
import { getCartItemWithEditedQuantities } from './box-builder.selectors';
export const saveBoxToCart = ({
  cartItems,
  productCardItems,
  addonCardItems,
  addonList,
  currentBoxesQuantity,
  boxItem,
  editedQuantityInCart,
  boxTapeItem
}) => {
  const boxItemsId = uuidv4();
  const newBoxItems = [];
  const newCartItems = cartItems.reduce((acc, cartItem) => {
    const cartItemWithEditedQuantity = getCartItemWithEditedQuantities(cartItem, cartItems, editedQuantityInCart);
    if (isNotSelectedForBox(productCardItems, cartItemWithEditedQuantity, currentBoxesQuantity)) {
      //if cartItem is not selected for box
      return [...acc, cartItemWithEditedQuantity];
    } else {
      const {
        splittedNonBoxQuantity = 0,
        splittedNonBoxVariants = [],
        splittedBoxVariants = []
      } = createSplittedItems({
        cartItem: cartItemWithEditedQuantity,
        productCardItems,
        currentBoxesQuantity
      });
      if (!isBoxExist(newBoxItems, boxItemsId)) {
        const box = createBox({
          boxItem,
          boxItemsId,
          quantity: currentBoxesQuantity
        });
        newBoxItems.push(box);
        if (isItemBrownBox(box) && boxTapeItem) {
          const tape = createTape({
            boxItemsId,
            tape: boxTapeItem,
            quantity: currentBoxesQuantity
          });
          newBoxItems.push(tape);
        }
      }
      if (splittedBoxVariants.length) {
        newBoxItems.push(...getNewSplittedItemsForBoxByVariant({
          splittedBoxVariants,
          cartItem: cartItemWithEditedQuantity,
          currentBoxesQuantity,
          boxItemsId
        }));
      }
      if (splittedNonBoxQuantity) {
        acc.push(getNewSplittedItemForNonBox({
          cartItem: cartItemWithEditedQuantity,
          splittedNonBoxVariants,
          splittedNonBoxQuantity
        }));
      }
      return acc;
    }
  }, []);
  if (addonCardItems !== null && addonCardItems !== void 0 && addonCardItems.length && addonList !== null && addonList !== void 0 && addonList.length) {
    newBoxItems.push(...addonCardItems.reduce((acc, addonCardItem) => {
      const prod = addonList.find(addon => addon._id === addonCardItem.itemId);
      const addon = makeFakeAddonItemForCart({
        prod,
        info: addonCardItem,
        currentBoxesQuantity
      });
      return addonCardItem.isSelected && currentBoxesQuantity * addonCardItem.quantityPerBox > 0 ? [...acc, _objectSpread({}, addon, {
        boxItemsId
      })] : acc;
    }, []));
  }
  return sortByBoxItems([...newCartItems, ...newBoxItems], boxItemsId);
};

//helpers---------------------------------------

export const getNewSplittedItemForNonBox = ({
  cartItem,
  splittedNonBoxVariants,
  splittedNonBoxQuantity
}) => {
  const customerMockups = getCustomerMockupsByVariantIndex({
    customerMockups: cartItem.customerMockups || {},
    variants: splittedNonBoxVariants
  });
  return _objectSpread({}, cartItem, {
    customerMockups,
    quantityPerBox: null,
    boxItemsId: null,
    quantity: splittedNonBoxQuantity,
    variants: splittedNonBoxVariants
  });
};
export const getNewSplittedItemsForBoxByVariant = ({
  splittedBoxVariants,
  cartItem,
  currentBoxesQuantity,
  boxItemsId,
  isEditMode = false
}) => {
  return splittedBoxVariants.map(({
    variant
  }) => {
    const itemToUse = isEditMode ? cartItem : _omit(cartItem, '_id', 'inventoryId');
    const totalQuantities = getTotalCount(variant.quantities);
    const quantityPerBox = getQtyPerBox(totalQuantities, currentBoxesQuantity);
    const customerMockups = getCustomerMockupsByVariantIndex({
      customerMockups: cartItem.customerMockups || {},
      variants: [variant]
    });
    return _objectSpread({}, itemToUse, {
      quantityPerBox,
      customerMockups,
      boxItemsId,
      quantity: getTotalCount(variant.quantities),
      variants: [variant]
    });
  });
};
function getBoxItemVariantQuantity({
  quantitiesItem,
  prod,
  sumSizesSelection,
  sizeInd
}) {
  if (!prod) {
    return quantitiesItem;
  }
  const {
    size,
    sizeDependentPrice
  } = prod;
  if (checkIsApparel(prod)) {
    return quantitiesItem;
  }
  if (sizeDependentPrice) {
    var _size$options;
    const arr = size !== null && size !== void 0 && (_size$options = size.options) !== null && _size$options !== void 0 && _size$options.length ? new Array(size.options.length) : [];
    arr.fill(0);
    arr[sizeInd] = sumSizesSelection;
    return arr;
  }
  return [sumSizesSelection];
}
export const createSplittedItems = ({
  cartItem,
  productCardItems,
  currentBoxesQuantity
}) => {
  let splittedNonBoxQuantity = 0;
  const splittedNonBoxVariants = [];
  const splittedBoxVariants = [];
  cartItem.variants.forEach(cartItemVariant => {
    const selectedItemVariantsList = productCardItems.filter(productCardItem => productCardItem.itemId === cartItem._id);
    const sumSizesCartVariant = getTotalCount(cartItemVariant.quantities);

    //entry - variant it mean box entry (participant)
    const entry = selectedItemVariantsList.find(entryItem => entryItem.variant.variantIndex === cartItemVariant.variantIndex && entryItem.isSelected);
    if (entry) {
      const sumSizesSelection = checkIsApparel(cartItem.prod) ? getTotalCount(entry.quantitiesItem) : currentBoxesQuantity * entry.quantityPerBox;
      if (sumSizesSelection === sumSizesCartVariant) {
        //if all variant quantity was detected as whole moved to box
        // (disappear in cart and variant appear as new in box)
        splittedBoxVariants.push({
          variant: cartItemVariant
        });
      } else if (cartItem.prod && entry.quantitiesItem) {
        var _entry$variant;
        //variant splitting between cart and box
        const variantIndex = (entry === null || entry === void 0 ? void 0 : (_entry$variant = entry.variant) === null || _entry$variant === void 0 ? void 0 : _entry$variant.variantIndex) || 0;
        const boxVariantQuantity = getBoxItemVariantQuantity({
          sumSizesSelection,
          prod: cartItem.prod,
          sizeInd: cartItem.sizeInd,
          quantitiesItem: entry.quantitiesItem || []
        });
        splittedBoxVariants.push({
          variant: {
            variantIndex,
            quantities: boxVariantQuantity
          }
        });
        splittedNonBoxQuantity = splittedNonBoxQuantity + (sumSizesCartVariant - sumSizesSelection);
        splittedNonBoxVariants.push({
          variantIndex,
          quantities: subtractQuantities({
            decreasingQuantity: cartItemVariant.quantities || [],
            subtractionQuantity: boxVariantQuantity
          }, false)
        });
      }
    } else {
      //it's item Variant was detected as non-selected for box
      splittedNonBoxQuantity = splittedNonBoxQuantity + sumSizesCartVariant;
      splittedNonBoxVariants.push(cartItemVariant);
    }
  });
  return {
    splittedNonBoxQuantity,
    splittedNonBoxVariants,
    splittedBoxVariants
  };
};
const isBoxExist = (currentCartItems, boxItemsId) => {
  return currentCartItems.find(currentCartItem => {
    if (!currentCartItem) {
      return false;
    }
    const {
      prod,
      boxItemsId: currentBoxItemsId
    } = currentCartItem;
    if (!(prod !== null && prod !== void 0 && prod.specialProductType)) {
      return false;
    }
    const isTheSameBox = currentBoxItemsId === boxItemsId;
    if (!isTheSameBox) {
      return false;
    }
    const isBoxWithSpecialType = [SPECIAL_PRODUCT_TYPES.COLORED_BOX, SPECIAL_PRODUCT_TYPES.CUSTOM_BOX, SPECIAL_PRODUCT_TYPES.FULLY_CUSTOM_BOX, SPECIAL_PRODUCT_TYPES.BROWN_BOX].includes(prod.specialProductType);
    return isBoxWithSpecialType && isTheSameBox;
  });
};
const createBox = ({
  boxItem,
  boxItemsId,
  quantity
}) => {
  var _boxItem$variants$;
  return _objectSpread({}, boxItem, {
    boxItemsId,
    quantity,
    blendedQuantity: null,
    variants: [{
      quantities: [quantity],
      variantIndex: (boxItem === null || boxItem === void 0 ? void 0 : (_boxItem$variants$ = boxItem.variants[0]) === null || _boxItem$variants$ === void 0 ? void 0 : _boxItem$variants$.variantIndex) || 0
    }]
  });
};
const createTape = ({
  tape,
  boxItemsId,
  quantity
}) => {
  var _tape$variants$;
  return _objectSpread({}, tape, {
    boxItemsId,
    quantity,
    blendedQuantity: null,
    variants: [{
      quantities: [quantity],
      variantIndex: (tape === null || tape === void 0 ? void 0 : (_tape$variants$ = tape.variants[0]) === null || _tape$variants$ === void 0 ? void 0 : _tape$variants$.variantIndex) || 0
    }]
  });
};
const isNotSelectedForBox = (productCardItems, cartItem, currentBoxesQuantity) => currentBoxesQuantity <= 0 || cartItem.boxItemsId || !productCardItems.find(productCardItem => productCardItem.itemId === cartItem._id && productCardItem.isSelected && getTotalCount(productCardItem.quantitiesItem) > 0);
const sortByBoxItems = (newCartItems, boxItemsId) => {
  const boxItems = [];
  const nonBoxItems = newCartItems.reduce((acc, item) => {
    if (item.boxItemsId && item.boxItemsId === boxItemsId) {
      const currentBoxItems = newCartItems.filter(newCartItem => newCartItem.boxItemsId === item.boxItemsId);
      const boxItemSelection = boxItems.find(boxItem => boxItem.boxItemsId === item.boxItemsId);
      if (!boxItemSelection) {
        boxItems.push(...currentBoxItems);
      }
      return acc;
    }
    return [...acc, item];
  }, []);
  return [...nonBoxItems, ...boxItems];
};
const getQtyPerBox = (qty, currentBoxesQuantity) => {
  return Math.floor(qty / currentBoxesQuantity) || 1;
};
const getCustomerMockupsByVariantIndex = ({
  customerMockups,
  variants
}) => {
  const newCustomerMockups = {};
  if (customerMockups && !_isEmpty(customerMockups)) {
    for (const key in customerMockups) {
      const {
        variantIndex: mockupIndex,
        location
      } = customerMockups[key];
      const variant = variants.find(v => v.variantIndex === mockupIndex);
      if (variant || location === DesignUploadMode.fullDesign) {
        newCustomerMockups[key] = customerMockups[key];
      }
    }
  }
  return newCustomerMockups;
};