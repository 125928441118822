import { DEBOSSED_EMBELISHMENT_METHOD_POPUP } from '../../../common/constants';
import { showPopup } from '../../../common/redux/popup-manager/popup-manager.actions';
export const showDebossedPopupAction = productId => showPopup({
  popup: DEBOSSED_EMBELISHMENT_METHOD_POPUP,
  productId
});
export const showDebossedPopupOnItemEditAction = (itemId, isItemEdit) => {
  return showPopup({
    popup: DEBOSSED_EMBELISHMENT_METHOD_POPUP,
    itemId,
    isItemEdit
  });
};