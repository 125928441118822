import { predefinedNotifications } from './notification.constants';
const PREFIX = 'NOTIFICATION_';
let hideTimeoutId;
export const NOTIFICATION_HIDDEN = `${PREFIX}NOTIFICATION_HIDDEN`;
export const hideNotification = () => {
  clearTimeout(hideTimeoutId);
  return {
    type: NOTIFICATION_HIDDEN
  };
};
export const NOTIFICATION_SHOWN = `${PREFIX}NOTIFICATION_SHOWN`;
export const showNotification = ({
  text,
  hideIn = 3000,
  dataAttr = null
}) => dispatch => {
  clearTimeout(hideTimeoutId);
  // @ts-ignore
  if (hideIn !== false) {
    hideTimeoutId = setTimeout(() => {
      dispatch({
        type: NOTIFICATION_HIDDEN
      });
    }, hideIn);
  }
  dispatch({
    type: NOTIFICATION_SHOWN,
    text,
    dataAttr
  });
};
export const showPredefinedNotification = ({
  notificationName = undefined,
  hideIn = undefined
} = {}) => dispatch => {
  if (notificationName && predefinedNotifications[notificationName]) {
    const text = predefinedNotifications[notificationName];
    dispatch(showNotification({
      text,
      hideIn
    }));
  }
};