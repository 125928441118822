function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { browserHistory } from 'react-router';
import { UNAVAILABLE_SIZES_POPUP } from 'swag-client-common/constants';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { CollectSizesUrl } from 'swag-common/utils/urls/collect-sizes.url';
import { CollectSizesApi } from '../../api/collect-sizes.api';
import { productSelector, resetEditedItemSelector, sizeSelector } from '../product/product.selectors';
import { campaignIdSelector, getCurrentPageSizeSubmissionSelector, getPerPageSizeSubmissionSelector, campaignLinkSelector } from './collect-sizes.selectors';
const PREFIX = 'COLLECT_SIZES_';
export const RESET_POPUP_WIZARD = `${PREFIX}RESET_POPUP_WIZARD`;
export const resetPopupWizard = () => ({
  type: RESET_POPUP_WIZARD
});
export const MOVE_TO_POPUP_NEXT_STEP = `${PREFIX}MOVE_TO_POPUP_NEXT_STEP`;
export const moveToPopupNextStep = () => ({
  type: MOVE_TO_POPUP_NEXT_STEP
});
export const SET_SUCCESSFULLY_SUBMIT_SIZE = `${PREFIX}SET_SUCCESSFULLY_SUBMIT_SIZE`;
export const setSuccessfullySubmitSize = value => ({
  type: SET_SUCCESSFULLY_SUBMIT_SIZE,
  payload: value
});
export const CREATE_REQUEST = `${PREFIX}CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX}CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX}CREATE_FAILURE`;
export const createCollectSizesRequest = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: CREATE_REQUEST
    });
  },
  transformData: (name, getState) => {
    const state = getState();
    const item = _objectSpread({}, resetEditedItemSelector(state), {
      textImages: {}
    });
    if (item.embellishmentMethod) {
      item.embellishmentMethod = item.embellishmentMethod.name;
    }
    return {
      name,
      item
    };
  },
  fn: CollectSizesApi.create,
  success: ({
    link
  }) => ({
    type: CREATE_SUCCESS,
    payload: {
      link
    }
  }),
  postSuccess: dispatch => {
    dispatch(moveToPopupNextStep());
  },
  error: error => ({
    type: CREATE_FAILURE,
    payload: {
      error
    },
    error
  })
});
export const GET_SIZE_SUBMISSION_BY_ID_REQUEST = `${PREFIX}GET_SIZE_SUBMISSION_BY_ID_REQUEST`;
export const GET_SIZE_SUBMISSION_BY_ID_SUCCESS = `${PREFIX}GET_SIZE_SUBMISSION_BY_ID_SUCCESS`;
export const GET_SIZE_SUBMISSION_BY_ID_ERROR = `${PREFIX}GET_SIZE_SUBMISSION_BY_ID_ERROR`;
export const APPLY_SIZE_SUBMISSION_MOCKUP = `${PREFIX}APPLY_SIZE_SUBMISSION_MOCKUP`;
export const getSizeSubmissionById = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_SIZE_SUBMISSION_BY_ID_REQUEST
    });
  },
  fn: CollectSizesApi.getSizeSubmissionById,
  success: () => ({
    type: GET_SIZE_SUBMISSION_BY_ID_SUCCESS
  }),
  error: error => ({
    type: GET_SIZE_SUBMISSION_BY_ID_ERROR,
    payload: {
      error
    },
    error
  }),
  postSuccess: (dispatch, getState, __, {
    campaign: {
      submissions,
      sizeOptions,
      mockup,
      mockup: {
        prod: oldProduct,
        variants
      }
    }
  }) => {
    var _size$options;
    const state = getState();
    const product = productSelector(state);
    const size = sizeSelector(state);
    const selectedSizes = sizeOptions.reduce((acc, sizeOption, index) => {
      const quantities = submissions.filter(({
        sizeIndex
      }) => Number(sizeIndex) === index).length;
      if (quantities > 0) {
        acc[sizeOption] = quantities;
      }
      return acc;
    }, {});
    const unavailableSizes = {};
    const productSizeOptions = (_size$options = size === null || size === void 0 ? void 0 : size.options) !== null && _size$options !== void 0 ? _size$options : [];
    const selectedVariantQuantities = [];
    Object.keys(selectedSizes).forEach(size => {
      const sizeIndex = productSizeOptions.findIndex(option => option === size);
      const isAvailableSize = sizeIndex !== -1;
      if (isAvailableSize) {
        selectedVariantQuantities[sizeIndex] = selectedSizes[size];
      } else {
        unavailableSizes[size] = selectedSizes[size];
      }
    });
    productSizeOptions.forEach((_, index) => {
      if (!selectedVariantQuantities[index]) {
        selectedVariantQuantities[index] = 0;
      }
    });
    const oldVariantsIndexes = variants.map(({
      variantIndex
    }) => variantIndex);
    const productSkus = oldProduct.variants.reduce((acc, variant, index) => {
      if (oldVariantsIndexes.includes(index)) {
        return [...acc, variant.productSku];
      }
      return acc;
    }, []);
    const variantsIndexes = product.variants.reduce((acc, variant, index) => {
      const isAvailableVariant = productSkus.includes(variant.productSku) && !variant.isHidden;
      if (isAvailableVariant) {
        return [...acc, index];
      }
      return acc;
    }, []);
    const selectedVariants = variantsIndexes.length ? variantsIndexes : [product.variants.findIndex(variant => !variant.isHidden)];
    const variantIndex = selectedVariants[0];
    const quantities = selectedVariants.reduce((acc, variantIdx) => _objectSpread({}, acc, {
      [variantIdx]: variantIdx === variantIndex ? selectedVariantQuantities : new Array(selectedVariantQuantities.length).fill(0)
    }), {});
    if (Object.keys(unavailableSizes).length) {
      dispatch(showPopup({
        popup: UNAVAILABLE_SIZES_POPUP,
        unavailableSizes
      }));
    }
    dispatch({
      type: APPLY_SIZE_SUBMISSION_MOCKUP,
      payload: {
        item: _objectSpread({}, mockup, {
          quantities,
          selectedVariants,
          variantIndex
        })
      }
    });
  }
});
export const DELETE_SIZE_SUBMISSION_REQUEST = `${PREFIX}DELETE_SIZE_SUBMISSION_REQUEST`;
export const DELETE_SIZE_SUBMISSION_SUCCESS = `${PREFIX}DELETE_SIZE_SUBMISSION_SUCCESS`;
export const DELETE_SIZE_SUBMISSION_ERROR = `${PREFIX}DELETE_SIZE_SUBMISSION_ERROR`;
export const onDeleteBtnClickAction = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: DELETE_SIZE_SUBMISSION_REQUEST
    });
  },
  fn: CollectSizesApi.deleteSizeSubmission,
  success: (_, {
    id
  }) => ({
    type: DELETE_SIZE_SUBMISSION_SUCCESS,
    payload: id
  }),
  error: error => ({
    type: DELETE_SIZE_SUBMISSION_ERROR,
    payload: {
      error
    },
    error
  }),
  postError: dispatch => {
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
    dispatch(hidePopup());
  },
  postSuccess: dispatch => {
    dispatch(hidePopup());
  }
});
export const GET_SIZE_SUBMISSION_CARDS_REQUEST = `${PREFIX}GET_SIZE_SUBMISSION_CARDS_REQUEST`;
export const GET_SIZE_SUBMISSION_CARDS_SUCCESS = `${PREFIX}GET_SIZE_SUBMISSION_CARDS_SUCCESS`;
export const GET_SIZE_SUBMISSION_CARDS_ERROR = `${PREFIX}GET_SIZE_SUBMISSION_CARDS_ERROR`;
export const getSizeSubmissionCardsAction = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_SIZE_SUBMISSION_CARDS_REQUEST
    });
  },
  transformData: (_, getState) => {
    const state = getState();
    const currentPage = getCurrentPageSizeSubmissionSelector(state);
    const perPage = getPerPageSizeSubmissionSelector(state);
    return {
      page: currentPage + 1,
      perPage
    };
  },
  fn: CollectSizesApi.getSizeSubmission,
  success: () => () => {
    /*not empty*/
  },
  error: error => {
    return {
      type: GET_SIZE_SUBMISSION_CARDS_ERROR,
      payload: {
        error
      },
      error
    };
  },
  postSuccess: (dispatch, _, __, payload) => {
    setTimeout(() => {
      dispatch({
        type: GET_SIZE_SUBMISSION_CARDS_SUCCESS,
        payload
      });
    }, 500);
  }
});
export const GET_SIZE_SUBMISSION_LINK_DATA_REQUEST = `${PREFIX}GET_SIZE_SUBMISSION_LINK_DATA_REQUEST`;
export const GET_SIZE_SUBMISSION_LINK_DATA_SUCCESS = `${PREFIX}GET_SIZE_SUBMISSION_LINK_DATA_SUCCESS`;
export const GET_SIZE_SUBMISSION_LINK_DATA_ERROR = `${PREFIX}GET_SIZE_SUBMISSION_LINK_DATA_ERROR`;
export const getCollectSizesLinkDataAction = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_SIZE_SUBMISSION_LINK_DATA_REQUEST
    });
  },
  fn: CollectSizesApi.getSizeSubmissionLinkData,
  success: ({
    campaign
  }) => ({
    type: GET_SIZE_SUBMISSION_LINK_DATA_SUCCESS,
    payload: {
      campaign
    }
  }),
  error: error => ({
    type: GET_SIZE_SUBMISSION_LINK_DATA_ERROR,
    payload: {
      error
    },
    error
  }),
  postError: () => {
    browserHistory.replace(APP_ROUTES.NOT_FOUND);
  }
});
export const SUBMIT_SIZE_REQUEST = `${PREFIX}SUBMIT_SIZE_REQUEST`;
export const SUBMIT_SIZE_SUCCESS = `${PREFIX}SUBMIT_SIZE_SUCCESS`;
export const SUBMIT_SIZE_ERROR = `${PREFIX}SUBMIT_SIZE_ERROR`;
export const submitSizeAction = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: SUBMIT_SIZE_REQUEST
    });
  },
  transformData: ({
    email,
    sizeIndex
  }, getState) => {
    const state = getState();
    return {
      _id: campaignIdSelector(state),
      email,
      sizeIndex
    };
  },
  fn: CollectSizesApi.submitSizeByLink,
  success: ({
    campaign
  }) => ({
    type: SUBMIT_SIZE_SUCCESS,
    payload: {
      campaign
    }
  }),
  error: error => ({
    type: SUBMIT_SIZE_ERROR,
    payload: {
      error
    },
    error
  }),
  postSuccess: (dispatch, getState) => {
    const link = campaignLinkSelector(getState());
    dispatch(setSuccessfullySubmitSize(true));
    browserHistory.push(CollectSizesUrl.getCollectSizesSubmitSuccess({
      link
    }));
  }
});
export const CLEAR_SUBMIT_SIZE_EMAIL_ERROR = `${PREFIX}CLEAR_SUBMIT_SIZE_EMAIL_ERROR`;
export const clearSubmitSizeEmailErrorAction = () => ({
  type: CLEAR_SUBMIT_SIZE_EMAIL_ERROR
});
export const ERASE_SIZE_SUBMISSION_CARDS = `${PREFIX}ERASE_SIZE_SUBMISSION_CARDS`;
export const eraseSizeSubmissionCardsAction = () => ({
  type: ERASE_SIZE_SUBMISSION_CARDS
});
export const RENAME_SIZE_SUBMISSION_REQUEST = `${PREFIX}RENAME_SIZE_SUBMISSION_REQUEST`;
export const RENAME_SIZE_SUBMISSION_SUCCESS = `${PREFIX}RENAME_SIZE_SUBMISSION_SUCCESS`;
export const RENAME_SIZE_SUBMISSION_ERROR = `${PREFIX}DELETE_SIZE_SUBMISSION_ERROR`;
export const onRenameBtnClickAction = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: RENAME_SIZE_SUBMISSION_REQUEST
    });
  },
  fn: CollectSizesApi.renameSizeSubmissionCampaign,
  success: ({
    campaign
  }) => ({
    type: RENAME_SIZE_SUBMISSION_SUCCESS,
    payload: campaign
  }),
  error: error => ({
    type: RENAME_SIZE_SUBMISSION_ERROR,
    payload: {
      error
    },
    error
  }),
  postError: dispatch => {
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
    dispatch(hidePopup());
  },
  postSuccess: dispatch => {
    dispatch(hidePopup());
  }
});