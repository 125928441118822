import { SWAG_SERVER_DEVELOPMENT, SWAG_SERVER_PROD, SWAG_SERVER_STAGING, SWAG_SERVER_SANDBOX, SWAG_SERVER_TEST, SWAG_PROD, SWAG_STAGING, SWAG_DEVELOPMENT, SWAG_TEST } from '../constants';

/**
 * Will not be fully accessible on front side in case of having
 * process.env.AWS_REGION
 */
export const SWAG_AWS_REGION = typeof process !== 'undefined' ? process.env.AWS_REGION || 'us-east-2' : 'us-east-2';
function getS3BucketByEnvironment(environment) {
  switch (environment) {
    case SWAG_SERVER_PROD:
      return SWAG_PROD;
    case SWAG_SERVER_STAGING:
    case SWAG_SERVER_SANDBOX:
      return SWAG_STAGING;
    case SWAG_SERVER_TEST:
      return SWAG_TEST;
    case SWAG_SERVER_DEVELOPMENT:
      return SWAG_DEVELOPMENT;
    default:
      return SWAG_DEVELOPMENT;
  }
}
export const SWAG_AWS_S3BUCKET = getS3BucketByEnvironment(process.env.SWAG_SERVER);