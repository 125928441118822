import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { getRelatedItemsBasedOnProvidedId } from 'swag-common/business-logic';
export function isRelatedItem(item, items, isStrict) {
  const {
    asRelatedItemId
  } = item;
  if (!asRelatedItemId) {
    return false;
  }
  const countToCompare = isStrict ? 0 : 1;
  const relatedItems = getRelatedItemsBasedOnProvidedId(item, items, isStrict);
  return relatedItems.length > countToCompare;
}
export function isSharedOrRelatedItem(item, items) {
  return isSharedItem(item, items) || isRelatedItem(item, items);
}