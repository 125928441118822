function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { spreadQuantities } from '../../utils/product/product.utils';
export function getBlankSample({
  prodId,
  orderItem,
  variantIndex,
  sizeDependent,
  sizeIndex,
  sizeLoaded,
  size,
  hasSize,
  price,
  prodTime
}, isPrinted) {
  if (!sizeLoaded) {
    return {};
  }
  let quantities;
  if (hasSize) {
    quantities = sizeDependent || Number.isInteger(sizeIndex) ? spreadQuantities({
      quantity: 1,
      itemsQuantityToSpread: size.options.length,
      allQuantityTo: sizeIndex,
      sizeOptions: size.options
    }) : spreadQuantities({
      quantity: 1,
      itemsQuantityToSpread: size.options.length,
      sizeOptions: size.options
    });
  } else {
    quantities = [1];
  }
  const item = _objectSpread({}, orderItem, {
    prodId,
    price,
    prodTime,
    quantity: 1,
    setupFeeDeductionDiscount: 0,
    variantInd: variantIndex,
    variants: [{
      variantIndex,
      quantities
    }]
  });
  if (!isPrinted) {
    delete item.logos;
  }
  delete item.asRelatedItemId;
  delete item.blendedPrice;
  delete item.blendedQuantity;
  return item;
}