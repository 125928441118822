function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { PRODUCT_ADJUSTMENT_MAX_COMMENT_LENGTH, PRODUCT_ADJUSTMENT_MIN_COMMENT_LENGTH } from 'swag-common/constants/inventory-products.constants';
export const isValidForm = errors => !errors.find(arg => arg !== '');
export const validate = (input, validator) => {
  const {
    required,
    regexp,
    regexpErr,
    min,
    max
  } = validator;
  switch (true) {
    case required && input === '':
      {
        return 'Required field';
      }

    // @todo. auto generated. fix this error
    // @ts-ignore
    case input.length < min:
      {
        return `Min ${min} characters`;
      }

    // @todo. auto generated. fix this error
    // @ts-ignore
    case input.length > max:
      {
        return `Max ${max} characters`;
      }
    case regexp && !regexp.test(input):
      {
        return regexpErr;
      }
    default:
      return '';
  }
};
export const checkIsValidComment = comment => {
  return comment.trim().length >= PRODUCT_ADJUSTMENT_MIN_COMMENT_LENGTH && comment.trim().length <= PRODUCT_ADJUSTMENT_MAX_COMMENT_LENGTH;
};
export const mapInputEventToHandler = (handler, transformer) => {
  return e => {
    const value = e.target.value;
    const transformedValue = transformer ? transformer(value) : value;
    handler(transformedValue);
  };
};
export const combineValidators = validators => _extends({}, ...validators);
export const alphanumValidator = {
  regexp: /^[a-zA-z0-9\-+_()!@#$%^&*'":;“”=?\\| ]+$/,
  regexpErr: "Acceptable characters: a-z, A-Z, 0-9, space, '-', '+', '_', '('," + " ')', '!', '@', '#', '$', '%', '^', '&', '*', ':', ';', '“', '”', '?', '|'"
};
export const requiredValidator = {
  required: true
};
export const regularLengthValidator = {
  min: 1,
  max: 100
};
export const intValidator = {
  regexp: /^\d+$/,
  regexpErr: 'Integer numbers only'
};
export const contentValidator = {
  required: true,
  regexp: /^.+$/m,
  regexpErr: 'Acceptable characters: any',
  min: 1,
  max: 2000
};
export const hintValidator = {
  required: false,
  regexp: /^.*$/,
  regexpErr: 'Acceptable characters: any',
  min: 0,
  max: 15
};
export const descriptionValidator = {
  required: true,
  regexp: /^.+$/,
  regexpErr: 'Acceptable characters: any',
  min: 1,
  max: 800
};
export const integerValidator = {
  required: true,
  regexp: /^[0-9]*$/,
  regexpErr: 'Not valid integer',
  min: 0,
  max: 999999
};
export const priceIntValidator = {
  required: true,
  regexp: /^[0-9]*$/,
  regexpErr: 'Not valid integer',
  min: 1,
  max: 6
};
export const priceFractValidator = {
  required: true,
  regexp: /^[0-9]*$/,
  regexpErr: 'Not valid integer',
  min: 1,
  max: 2
};
export const emailValidator = {
  required: true,
  regexp: /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i,
  regexpErr: 'Invalid e-mail',
  min: 1,
  max: 100
};
export const phoneValidator = {
  required: true,
  regexp: /^[()\-+ 0-9]+$/,
  regexpErr: "Acceptable characters: 0-9, '(', ')', '-', '+', space",
  min: 6,
  max: 20
};
export const addressValidator = {
  required: true,
  regexp: /^.+$/,
  regexpErr: 'Acceptable characters: any',
  min: 1,
  max: 200
};
export const timeValidator = {
  required: true,
  regexp: /^.+$/,
  regexpErr: 'Acceptable characters: any',
  min: 1,
  max: 50
};
export const loginValidator = {
  required: true,
  regexp: /^.+$/,
  regexpErr: 'Invalid login',
  min: 1,
  max: 50
};
export const pwdValidator = {
  required: true,
  regexp: /^.+$/,
  regexpErr: 'Invalid password',
  min: 1,
  max: 30
};