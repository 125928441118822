import { createSelector } from 'reselect';
import { ILoqateFindResultType } from 'swag-common/interfaces/address';
export const isAddressEnteredManuallySelectorFactory = rootSelector => {
  return createSelector(rootSelector, store => store.isAddressEnteredManually);
};
export const autocompleteSelectedOptionSelectorFactory = rootSelector => {
  return createSelector(rootSelector, store => store.selectedOption);
};
export const isAddressLoadingSelectorFactory = rootSelector => createSelector(rootSelector, store => store.isLoading);
export const autocompleteListSelectorFactory = rootSelector => {
  return createSelector(rootSelector, store => {
    if (store.autocompleteList.length > 0) {
      return [...store.autocompleteList, {
        id: 'manual',
        highlight: '',
        description: '',
        text: 'Enter address manually',
        type: ILoqateFindResultType.ManualEnter
      }];
    }
    return [];
  });
};
export function createAutocompleteSelectors(rootSelector) {
  return {
    isAddressEnteredManuallySelector: isAddressEnteredManuallySelectorFactory(rootSelector),
    autocompleteListSelector: autocompleteListSelectorFactory(rootSelector),
    isAddressLoadingSelector: isAddressLoadingSelectorFactory(rootSelector),
    selectedOptionSelector: autocompleteSelectedOptionSelectorFactory(rootSelector)
  };
}