function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import getUniqueSizeSet from './get-unique-size-set.logic';
export default function getBoxSizeSet(box) {
  var _box$variants$, _box$variants$$boxCon;
  if (!box) {
    return {};
  }
  const boxProducts = (_box$variants$ = box.variants[0]) === null || _box$variants$ === void 0 ? void 0 : (_box$variants$$boxCon = _box$variants$.boxContent) === null || _box$variants$$boxCon === void 0 ? void 0 : _box$variants$$boxCon.map(({
    product: {
      swagProductId,
      sizeSettings
    }
  }) => _objectSpread({}, swagProductId, {
    sizeId: sizeSettings && sizeSettings._id,
    productSize: sizeSettings
  }));
  return getUniqueSizeSet(boxProducts || []);
}
export function getBoxSizeVariantSet(variant) {
  var _variant$boxContent;
  const boxProducts = (_variant$boxContent = variant.boxContent) === null || _variant$boxContent === void 0 ? void 0 : _variant$boxContent.map(({
    product: {
      swagProductId,
      sizeSettings
    }
  }) => _objectSpread({}, swagProductId, {
    sizeId: sizeSettings && sizeSettings._id,
    productSize: sizeSettings
  }));
  return getUniqueSizeSet(boxProducts || []);
}