function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { BOX_SINGLE_LOGO } from 'swag-client-common/constants';
import { getAndRemoveItemFromLS } from 'swag-client-common/utils/local-storage.util';
import { viewTypeSelector } from '../product/product.selectors';
import { toggleUploadingOverlay } from '../logo-editing/logo-editing.actions';
import { CHEST_POSITIONS, ALIGN_SIDES } from '../product/product.constants';
import { layersByLocationSelector, selectedLogoSelector, currentViewLogosList, logosSelector } from './logo.selectors';
const PREFIX = 'LOGO_';
export const SET_ALL_LOGOS_AND_LAYERS = `${PREFIX}SET_ALL_LOGOS_AND_LAYERS`;
export const applyLogosAndLayers = () => {
  const {
    logos,
    layers,
    texts
  } = getAndRemoveItemFromLS('savedEditorState');
  return {
    type: SET_ALL_LOGOS_AND_LAYERS,
    payload: {
      logos,
      layers,
      texts
    }
  };
};
export const setLogos = ({
  logos,
  layers
}) => ({
  type: SET_ALL_LOGOS_AND_LAYERS,
  payload: {
    logos,
    layers,
    texts: {}
  }
});
export const REMOVE = `${PREFIX}REMOVE`;
export const removeLogo = id => ({
  type: REMOVE,
  payload: {
    id
  }
});
export const ADD_LOGO = `${PREFIX}ADD_LOGO`;
export const addLogo = details => ({
  type: ADD_LOGO,
  payload: details
});
export const CHANGE_LOGO_DIMENSIONS = `${PREFIX}CHANGE_LOGO_DIMENSIONS`;
export const changeLogoDimensions = ({
  rect,
  mediaId,
  type,
  isLogoDimensionsManuallyModified
}) => ({
  type: CHANGE_LOGO_DIMENSIONS,
  payload: {
    rect,
    mediaId,
    type,
    isLogoDimensionsManuallyModified
  }
});
export const SELECT_CURRENT = `${PREFIX}SELECT_CURRENT`;
export const selectLogo = logoId => ({
  type: SELECT_CURRENT,
  payload: {
    logoId
  }
});
export const UPDATE_LAYERS = `${PREFIX}UPDATE_LAYERS`;
export const bringForward = id => (dispatch, getState) => {
  const state = getState();
  const layers = layersByLocationSelector(state);
  const view = viewTypeSelector(state);
  let replacedLogo;
  if (layers[layers.length - 1] === id) {
    return;
  }
  const updatedLayers = layers.map((mediaId, index) => {
    if (mediaId === id) {
      replacedLogo = layers[index + 1];
      return layers[index + 1];
    }
    if (mediaId === replacedLogo) {
      return id;
    }
    return mediaId;
  });
  dispatch({
    type: UPDATE_LAYERS,
    payload: {
      layers: updatedLayers,
      view
    }
  });
};
export const sendBackward = id => (dispatch, getState) => {
  const state = getState();
  const layers = layersByLocationSelector(state);
  const view = viewTypeSelector(state);
  let replacedLogo;
  if (layers[0] === id) {
    return;
  }
  const updatedLayers = layers.map((mediaId, index) => {
    if (id === layers[index + 1]) {
      replacedLogo = mediaId;
      return id;
    }
    if (mediaId === id) {
      return replacedLogo;
    }
    return mediaId;
  });
  dispatch({
    type: UPDATE_LAYERS,
    payload: {
      layers: updatedLayers,
      view
    }
  });
};
export const setInitialLogoRect = (logoParams, imprintAreaParams, logoId) => (dispatch, getState) => {
  const {
    originalLogoWidth,
    originalLogoHeight
  } = logoParams;
  const state = getState();
  const {
    logo: {
      logos
    }
  } = state;
  const currentRect = logos[logoId].rect;
  let logoWidth, logoHeight;
  if (currentRect) {
    return;
  }
  const imprintAreaAspectRatio = imprintAreaParams.width / imprintAreaParams.height;
  const logoAspectRatio = originalLogoWidth / originalLogoHeight;
  if (logoAspectRatio > imprintAreaAspectRatio) {
    logoWidth = 100;
    logoHeight = logoWidth * (imprintAreaAspectRatio / logoAspectRatio);
  } else {
    logoHeight = 100;
    logoWidth = logoHeight / (imprintAreaAspectRatio / logoAspectRatio);
  }
  const logoTop = (100 - logoHeight) / 2;
  const logLeft = (100 - logoWidth) / 2;
  dispatch(changeLogoDimensions({
    rect: {
      top: logoTop,
      left: logLeft,
      width: logoWidth,
      height: logoHeight,
      angle: 0
    },
    mediaId: logoId,
    type: 'logos',
    isLogoDimensionsManuallyModified: false
  }));
  dispatch(toggleUploadingOverlay(false));
};
export const FIRST_LOGO_UPLOAD = `${PREFIX}FIRST_LOGO_UPLOAD`;
export const UPDATE_LOGO_DETAILS = `${PREFIX}UPDATE_LOGO_DETAILS`;
export const updateLogoDetails = logoDetails => (dispatch, getState) => {
  const state = getState();
  const {
    logo: {
      logoNeverUploaded
    }
  } = state;
  const logosList = currentViewLogosList(state);
  const additionalLogos = {};
  const logoAlreadyUploaded = logosList.some(({
    mediaId
  }) => mediaId === logoDetails.id);
  const payload = _objectSpread({}, logoDetails, {
    additionalLogos
  });
  if (logoAlreadyUploaded) {
    dispatch({
      type: UPDATE_LOGO_DETAILS,
      payload: payload
    });
  } else {
    dispatch(addLogo(payload));
  }
  if (logoNeverUploaded) {
    dispatch({
      type: FIRST_LOGO_UPLOAD
    });
  }
};
export const adjustLogoToImprintArea = (newImprintArea, oldImprintArea) => (dispatch, getState) => {
  const state = getState();
  const logos = logosSelector(state);
  const currentView = viewTypeSelector(state);
  const currentViewLogos = Object.keys(logos).reduce((acc, id) => {
    if (logos[id].location === currentView) {
      // @todo. auto generated. fix this error
      // @ts-ignore
      acc.push(logos[id]);
    }
    return acc;
  }, []);
  if (!Object.keys(oldImprintArea).length) {
    return;
  }
  const imprintAreaWidthDiff = oldImprintArea.width / newImprintArea.width;
  const imprintAreaHeightDiff = oldImprintArea.height / newImprintArea.height;
  currentViewLogos.forEach(({
    rect,
    id
  }) => {
    if (!rect) {
      return;
    }
    // @todo. auto generated. fix this error
    // @ts-ignore
    const newLogoRect = _objectSpread({}, rect);

    // @todo. auto generated. fix this error
    // @ts-ignore
    newLogoRect.height = rect.height * imprintAreaHeightDiff;
    // @todo. auto generated. fix this error
    // @ts-ignore
    newLogoRect.width = rect.width * imprintAreaWidthDiff;
    dispatch(changeLogoDimensions({
      rect: newLogoRect,
      mediaId: id,
      type: 'logos',
      isLogoDimensionsManuallyModified: false
    }));
  });
};
export const centerLogo = () => (dispatch, getState) => {
  const state = getState();
  const {
    rect,
    rect: {
      width
    },
    id,
    type = 'logos'
  } = selectedLogoSelector(state);
  const newLeft = (100 - width) / 2;
  dispatch(changeLogoDimensions({
    rect: _objectSpread({}, rect, {
      left: newLeft
    }),
    mediaId: id,
    type,
    isLogoDimensionsManuallyModified: false
  }));
};
export const alignLogoToChest = side => (dispatch, getState) => {
  if (!ALIGN_SIDES[side]) {
    return;
  }
  const currentLogo = selectedLogoSelector(getState());
  const {
    rect: {
      height: originalHeight,
      width: originalWidth
    },
    id,
    type = 'logos'
  } = currentLogo;
  const alignedLogoRect = CHEST_POSITIONS[side];
  const newHeight = originalHeight / originalWidth * alignedLogoRect.width;
  const rect = _objectSpread({}, alignedLogoRect, {
    height: newHeight
  });
  dispatch(changeLogoDimensions({
    rect,
    mediaId: id,
    type,
    isLogoDimensionsManuallyModified: true
  }));
};
export const CHANGE_TEXT_FIELD = `${PREFIX}CHANGE_TEXT_VALUE`;
export const changeTextField = ({
  value,
  key
}) => ({
  type: CHANGE_TEXT_FIELD,
  payload: {
    value,
    key
  }
});
export const ADD_TEXT = `${PREFIX}ADD_TEXT`;
export const addText = () => ({
  type: ADD_TEXT
});
export const ADD_NEW_PANTONE = `${PREFIX}ADD_NEW_PANTONE`;
export const addNewColor = color => ({
  type: ADD_NEW_PANTONE,
  payload: {
    color
  }
});
export const APPLY_CHANGES_TO_ALL = `${PREFIX}APPLY_CHANGES_TO_ALL`;
export const applyChangesToAllLogos = (logoDetails, isOriginalChanged = false) => (dispatch, getState) => {
  const {
    logo
  } = getState();
  const {
    colorsToPrint,
    convertTo,
    detectedColors,
    isConfirmedByCustomer,
    previewsConvertedToOneColor,
    selectedColors
  } = logoDetails;
  const logos = Object.values(logo.logos).filter(item => item.location.includes(BOX_SINGLE_LOGO));
  dispatch({
    type: APPLY_CHANGES_TO_ALL,
    payload: {
      logos: logos.reduce((all, item) => {
        if (item.location === BOX_SINGLE_LOGO && !isOriginalChanged) {
          return _objectSpread({}, all, {
            [item.id]: item
          });
        }
        const updatedItem = _objectSpread({}, item, {
          colors: _objectSpread({}, item.colors, {
            isConfirmedByCustomer,
            convertTo,
            detectedColors,
            colorsToPrint,
            selectedColors,
            colorsNumber: colorsToPrint.length
          })
        });
        if (previewsConvertedToOneColor) {
          updatedItem.previewsConvertedToOneColor = previewsConvertedToOneColor;
        }
        return _objectSpread({}, all, {
          [updatedItem.id]: updatedItem
        });
      }, {}),
      isColorsModified: !isOriginalChanged
    }
  });
};