import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isAdminModeExitPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { adminModeSelector } from 'swag-client-common/redux/user/user.selectors';
import { exitAdminMode } from 'swag-client-common/redux/cart/cart.actions';
import { AdminModeExitPopup as AdminModeExitPopupComponent } from './admin-mode-exit-popup';
const mapStateToProps = createStructuredSelector({
  open: isAdminModeExitPopupShownSelector,
  adminMode: adminModeSelector
});
const mapDispatchToProps = dispatch => ({
  onCancel: () => {
    dispatch(hidePopup());
  },
  onConfirm: () => exitAdminMode()
});
export const AdminModeExitPopup = connect(mapStateToProps, mapDispatchToProps)(AdminModeExitPopupComponent);