function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { addQuantities } from 'swag-client-common/utils/order-item.utils';
import { UPLOAD_CUSTOM_BOX_LOGO_LOADING, UPLOAD_CUSTOM_BOX_LOGO_FAIL, UPLOAD_CUSTOM_BOX_LOGO_SUCCESS, UPLOAD_BROWN_BOX_LOGO_LOADING, UPLOAD_BROWN_BOX_LOGO_FAIL, UPLOAD_BROWN_BOX_LOGO_SUCCESS } from 'swag-client-common/redux/box-builder/box-builder.actions';
import { BOX_INFO_STEP_COMPLETED, RESET_BOX_BUILDER_STATE, SAVE_BOX_NAME, SAVE_BOX_TYPE, SELECT_BACK_BUTTON, SAVE_COLORED_BOX_INFO, SAVE_CUSTOM_BOX_INFO, SAVE_BROWN_BOX_INFO, SAVE_FULLY_CUSTOM_BOX_INFO, CHANGE_CURRENT_BOXES_QUANTITY, CHANGE_PRODUCT_CARD_SELECTIONS, SAVE_SELECTED_CART_ITEMS_TO_BOX, CHANGE_ADDON_CARD_SELECTIONS, OPEN_EDIT_QUANTITY_IN_CART, CLOSE_EDIT_QUANTITY_IN_CART, EDIT_CURRENT_QUANTITY_IN_CART, SAVE_NEW_QUANTITY_IN_CART_FOR_ITEM_PRODUCT_CARD, SET_BOX_NAME_EDIT_ID, SET_BOX_BUILDER_EDIT_MODE_BOX_ITEMS_ID, SET_BOX_TYPE_EDIT_ID, CHANGE_PRODUCT_CARD_QUANTITY_PER_BOX, CHANGE_PRODUCT_CARD_QUANTITIES, CHANGE_ADDON_CARD_QUANTITY_PER_BOX, TYPE_BOX_NAME, CHOOSE_BOX_TYPE, SELECT_COLORED_BOX_INFO, TYPE_FULLY_CUSTOM_BOX_INFO, SET_CUSTOM_BOX_COLOR } from './box-builder.actions';
import { BOX_TYPE_TO_STEP, initialState, STEPS } from './box-builder.constants';
export function getNextQuantityPerBox({
  prefillQuantityPerBox,
  isSelected,
  prevValue
}) {
  if (!isSelected) {
    return 0;
  }
  if (prefillQuantityPerBox) {
    return 1;
  }
  return prevValue;
}
export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_BOX_NAME:
      return _objectSpread({}, state, {
        boxName: action.payload,
        selectedBackButtonOnStep: null,
        selectedForwardButtonOnStep: STEPS.BOX_NAMING
      });
    case TYPE_BOX_NAME:
      return _objectSpread({}, state, {
        boxName: action.payload
      });
    case CHOOSE_BOX_TYPE:
      return _objectSpread({}, state, {
        selectedBoxType: action.payload,
        selectedBackButtonOnStep: BOX_TYPE_TO_STEP[action.payload]
      });
    case SELECT_COLORED_BOX_INFO:
      return _objectSpread({}, state, {
        coloredBoxInfo: {
          color: action.payload
        }
      });
    case TYPE_FULLY_CUSTOM_BOX_INFO:
      return _objectSpread({}, state, {
        fullyCustomBoxInfo: {
          note: action.payload
        }
      });
    case SET_CUSTOM_BOX_COLOR:
      {
        return _objectSpread({}, state, {
          // @todo. auto generated. fix this error
          // @ts-ignore
          customBoxInfo: _objectSpread({}, state.customBoxInfo, {
            color: action.payload
          })
        });
      }
    case BOX_INFO_STEP_COMPLETED:
      return _objectSpread({}, state, {
        boxInfoStepCompleted: action.payload,
        selectedBackButtonOnStep: null,
        selectedForwardButtonOnStep: STEPS.BOX_INTRODUCTION_INFO
      });
    case SAVE_BOX_TYPE:
      {
        const nextBoxType = action.payload;
        let nextColoredBoxInfo = state.coloredBoxInfo;
        let nextCustomBoxInfo = state.customBoxInfo;
        let nextFullyCustomBoxInfo = state.fullyCustomBoxInfo;
        let nextBrownBoxInfo = state.brownBoxInfo;
        switch (nextBoxType) {
          case SPECIAL_PRODUCT_TYPES.COLORED_BOX:
            {
              nextCustomBoxInfo = null;
              nextFullyCustomBoxInfo = null;
              nextBrownBoxInfo = null;
              break;
            }
          case SPECIAL_PRODUCT_TYPES.CUSTOM_BOX:
            {
              nextColoredBoxInfo = null;
              nextFullyCustomBoxInfo = null;
              nextBrownBoxInfo = null;
              break;
            }
          case SPECIAL_PRODUCT_TYPES.FULLY_CUSTOM_BOX:
            {
              nextColoredBoxInfo = null;
              nextCustomBoxInfo = null;
              nextBrownBoxInfo = null;
              break;
            }
          case SPECIAL_PRODUCT_TYPES.BROWN_BOX:
            {
              nextColoredBoxInfo = null;
              nextCustomBoxInfo = null;
              nextFullyCustomBoxInfo = null;
              break;
            }
          default:
            {
              break;
            }
        }
        return _objectSpread({}, state, {
          selectedBoxType: nextBoxType,
          selectedBackButtonOnStep: null,
          selectedForwardButtonOnStep: STEPS.BOX_TYPE_SELECTION,
          coloredBoxInfo: nextColoredBoxInfo,
          customBoxInfo: nextCustomBoxInfo,
          fullyCustomBoxInfo: nextFullyCustomBoxInfo,
          brownBoxInfo: nextBrownBoxInfo
        });
      }
    case SAVE_SELECTED_CART_ITEMS_TO_BOX:
      {
        return _objectSpread({}, state, {
          selectedBackButtonOnStep: null,
          selectedForwardButtonOnStep: STEPS.SELECT_PRODUCTS_FOR_BOX
        });
      }
    case SAVE_FULLY_CUSTOM_BOX_INFO:
      return _objectSpread({}, state, {
        fullyCustomBoxInfo: {
          note: action.payload
        },
        selectedBackButtonOnStep: null,
        selectedForwardButtonOnStep: STEPS.FULLY_CUSTOM_BOX_SELECTION
      });
    case SAVE_COLORED_BOX_INFO:
      return _objectSpread({}, state, {
        coloredBoxInfo: {
          color: action.payload
        },
        selectedBackButtonOnStep: null,
        selectedForwardButtonOnStep: STEPS.COLORED_BOX_SELECTION
      });
    case SAVE_CUSTOM_BOX_INFO:
      {
        const {
          color
        } = action.payload;
        return _objectSpread({}, state, {
          // @todo. auto generated. fix this error
          // @ts-ignore
          customBoxInfo: _objectSpread({}, state.customBoxInfo, {
            color
          }),
          selectedBackButtonOnStep: null,
          selectedForwardButtonOnStep: STEPS.CUSTOM_BOX_SELECTION
        });
      }
    case SAVE_BROWN_BOX_INFO:
      {
        var _state$brownBoxInfo;
        return _objectSpread({}, state, {
          brownBoxInfo: _objectSpread({}, state.brownBoxInfo, {
            tapeLogo: _objectSpread({}, (_state$brownBoxInfo = state.brownBoxInfo) === null || _state$brownBoxInfo === void 0 ? void 0 : _state$brownBoxInfo.tapeLogo)
          }),
          selectedBackButtonOnStep: null,
          selectedForwardButtonOnStep: STEPS.BROWN_BOX_SELECTION
        });
      }
    case SELECT_BACK_BUTTON:
      return _objectSpread({}, state, {
        selectedBackButtonOnStep: action.payload
      });
    case CHANGE_CURRENT_BOXES_QUANTITY:
      return _objectSpread({}, state, {
        currentBoxesQuantity: action.payload
      });
    case OPEN_EDIT_QUANTITY_IN_CART:
      {
        const {
          itemId,
          variantIndex
        } = action.payload;
        return _objectSpread({}, state, {
          editQuantityInCartItemData: {
            itemId,
            variantIndex
          }
        });
      }
    case EDIT_CURRENT_QUANTITY_IN_CART:
      return _objectSpread({}, state, {
        editingQuantityInCart: action.payload
      });
    case SAVE_NEW_QUANTITY_IN_CART_FOR_ITEM_PRODUCT_CARD:
      {
        var _state$editedQuantity;
        const {
          offsetQuantity
        } = action.payload;
        // @todo. auto generated. fix this error
        // @ts-ignore
        const {
          itemId,
          variantIndex
        } = state.editQuantityInCartItemData;
        if (!state.editingQuantityInCart) {
          return _objectSpread({}, state, {
            editingQuantityInCart: null,
            editQuantityInCartItemData: null
          });
        }
        return _objectSpread({}, state, {
          editingQuantityInCart: null,
          editQuantityInCartItemData: null,
          editedQuantityInCartData: _objectSpread({}, state.editedQuantityInCartData, {
            [itemId]: _objectSpread({}, ((_state$editedQuantity = state.editedQuantityInCartData) === null || _state$editedQuantity === void 0 ? void 0 : _state$editedQuantity[itemId]) || {}, {
              [variantIndex]: addQuantities({
                firstQuantities: state.editingQuantityInCart,
                secondsQuantities: offsetQuantity
              })
            })
          })
        });
      }
    case CLOSE_EDIT_QUANTITY_IN_CART:
      return _objectSpread({}, state, {
        editQuantityInCartItemData: null,
        editingQuantityInCart: null
      });
    case CHANGE_PRODUCT_CARD_SELECTIONS:
      {
        var _state$productCardSel, _state$productCardSel2, _state$productCardSel3, _state$productCardSel4, _state$productCardSel5, _state$productCardSel6, _state$productCardSel7, _state$productCardSel8, _state$productCardSel9, _state$productCardSel10, _state$productCardSel11;
        const {
          isSelected,
          itemId,
          variantIndex,
          prefillQuantityPerBox
        } = action.payload;
        const nextQuantityPerBox = getNextQuantityPerBox({
          prevValue: (_state$productCardSel = state.productCardSelections) === null || _state$productCardSel === void 0 ? void 0 : (_state$productCardSel2 = _state$productCardSel[itemId]) === null || _state$productCardSel2 === void 0 ? void 0 : (_state$productCardSel3 = _state$productCardSel2[variantIndex]) === null || _state$productCardSel3 === void 0 ? void 0 : _state$productCardSel3.quantityPerBox,
          prefillQuantityPerBox,
          isSelected
        });
        return _objectSpread({}, state, {
          productCardSelections: _objectSpread({}, state.productCardSelections, {
            [itemId]: _objectSpread({}, (_state$productCardSel4 = (_state$productCardSel5 = state.productCardSelections) === null || _state$productCardSel5 === void 0 ? void 0 : _state$productCardSel5[itemId]) !== null && _state$productCardSel4 !== void 0 ? _state$productCardSel4 : {}, {
              [variantIndex]: {
                quantities: (_state$productCardSel6 = state.productCardSelections) === null || _state$productCardSel6 === void 0 ? void 0 : (_state$productCardSel7 = _state$productCardSel6[itemId]) === null || _state$productCardSel7 === void 0 ? void 0 : (_state$productCardSel8 = _state$productCardSel7[variantIndex]) === null || _state$productCardSel8 === void 0 ? void 0 : _state$productCardSel8.quantities,
                quantityPerBox: nextQuantityPerBox,
                isSelected: isSelected !== null && isSelected !== void 0 ? isSelected : (_state$productCardSel9 = state.productCardSelections) === null || _state$productCardSel9 === void 0 ? void 0 : (_state$productCardSel10 = _state$productCardSel9[itemId]) === null || _state$productCardSel10 === void 0 ? void 0 : (_state$productCardSel11 = _state$productCardSel10[variantIndex]) === null || _state$productCardSel11 === void 0 ? void 0 : _state$productCardSel11.isSelected
              }
            })
          })
        });
      }
    case CHANGE_PRODUCT_CARD_QUANTITY_PER_BOX:
      {
        var _state$productCardSel12, _state$productCardSel13, _state$productCardSel14, _state$productCardSel15, _state$productCardSel16, _state$productCardSel17, _state$productCardSel18, _state$productCardSel19, _state$productCardSel20, _state$productCardSel21, _state$productCardSel22;
        const {
          quantityPerBox,
          itemId,
          variantIndex
        } = action.payload;
        return _objectSpread({}, state, {
          productCardSelections: _objectSpread({}, state.productCardSelections, {
            [itemId]: _objectSpread({}, (_state$productCardSel12 = (_state$productCardSel13 = state.productCardSelections) === null || _state$productCardSel13 === void 0 ? void 0 : _state$productCardSel13[itemId]) !== null && _state$productCardSel12 !== void 0 ? _state$productCardSel12 : {}, {
              [variantIndex]: {
                quantities: (_state$productCardSel14 = state.productCardSelections) === null || _state$productCardSel14 === void 0 ? void 0 : (_state$productCardSel15 = _state$productCardSel14[itemId]) === null || _state$productCardSel15 === void 0 ? void 0 : (_state$productCardSel16 = _state$productCardSel15[variantIndex]) === null || _state$productCardSel16 === void 0 ? void 0 : _state$productCardSel16.quantities,
                isSelected: (_state$productCardSel17 = state.productCardSelections) === null || _state$productCardSel17 === void 0 ? void 0 : (_state$productCardSel18 = _state$productCardSel17[itemId]) === null || _state$productCardSel18 === void 0 ? void 0 : (_state$productCardSel19 = _state$productCardSel18[variantIndex]) === null || _state$productCardSel19 === void 0 ? void 0 : _state$productCardSel19.isSelected,
                quantityPerBox: quantityPerBox !== null && quantityPerBox !== void 0 ? quantityPerBox : (_state$productCardSel20 = state.productCardSelections) === null || _state$productCardSel20 === void 0 ? void 0 : (_state$productCardSel21 = _state$productCardSel20[itemId]) === null || _state$productCardSel21 === void 0 ? void 0 : (_state$productCardSel22 = _state$productCardSel21[variantIndex]) === null || _state$productCardSel22 === void 0 ? void 0 : _state$productCardSel22.quantityPerBox
              }
            })
          })
        });
      }
    case CHANGE_PRODUCT_CARD_QUANTITIES:
      {
        var _state$productCardSel23, _state$productCardSel24, _state$productCardSel25, _state$productCardSel26, _state$productCardSel27, _state$productCardSel28, _state$productCardSel29, _state$productCardSel30, _state$productCardSel31, _state$productCardSel32, _state$productCardSel33;
        const {
          quantities,
          itemId,
          variantIndex
        } = action.payload;
        return _objectSpread({}, state, {
          productCardSelections: _objectSpread({}, state.productCardSelections, {
            [itemId]: _objectSpread({}, (_state$productCardSel23 = (_state$productCardSel24 = state.productCardSelections) === null || _state$productCardSel24 === void 0 ? void 0 : _state$productCardSel24[itemId]) !== null && _state$productCardSel23 !== void 0 ? _state$productCardSel23 : {}, {
              [variantIndex]: {
                quantityPerBox: (_state$productCardSel25 = state.productCardSelections) === null || _state$productCardSel25 === void 0 ? void 0 : (_state$productCardSel26 = _state$productCardSel25[itemId]) === null || _state$productCardSel26 === void 0 ? void 0 : (_state$productCardSel27 = _state$productCardSel26[variantIndex]) === null || _state$productCardSel27 === void 0 ? void 0 : _state$productCardSel27.quantityPerBox,
                isSelected: (_state$productCardSel28 = state.productCardSelections) === null || _state$productCardSel28 === void 0 ? void 0 : (_state$productCardSel29 = _state$productCardSel28[itemId]) === null || _state$productCardSel29 === void 0 ? void 0 : (_state$productCardSel30 = _state$productCardSel29[variantIndex]) === null || _state$productCardSel30 === void 0 ? void 0 : _state$productCardSel30.isSelected,
                quantities: quantities !== null && quantities !== void 0 ? quantities : (_state$productCardSel31 = state.productCardSelections) === null || _state$productCardSel31 === void 0 ? void 0 : (_state$productCardSel32 = _state$productCardSel31[itemId]) === null || _state$productCardSel32 === void 0 ? void 0 : (_state$productCardSel33 = _state$productCardSel32[variantIndex]) === null || _state$productCardSel33 === void 0 ? void 0 : _state$productCardSel33.quantities
              }
            })
          })
        });
      }
    case CHANGE_ADDON_CARD_SELECTIONS:
      {
        var _state$addonCardSelec, _state$addonCardSelec2, _state$addonCardSelec3, _state$addonCardSelec4, _state$addonCardSelec5, _state$addonCardSelec6;
        const {
          isSelected,
          productId,
          prefillQuantityPerBox
        } = action.payload;
        const nextQuantityPerBox = getNextQuantityPerBox({
          prevValue: (_state$addonCardSelec = state.addonCardSelections) === null || _state$addonCardSelec === void 0 ? void 0 : (_state$addonCardSelec2 = _state$addonCardSelec[productId]) === null || _state$addonCardSelec2 === void 0 ? void 0 : _state$addonCardSelec2.quantityPerBox,
          prefillQuantityPerBox,
          isSelected
        });
        return _objectSpread({}, state, {
          addonCardSelections: _objectSpread({}, state.addonCardSelections, {
            [productId]: _objectSpread({}, (_state$addonCardSelec3 = (_state$addonCardSelec4 = state.addonCardSelections) === null || _state$addonCardSelec4 === void 0 ? void 0 : _state$addonCardSelec4[productId]) !== null && _state$addonCardSelec3 !== void 0 ? _state$addonCardSelec3 : {}, {
              isSelected: isSelected !== null && isSelected !== void 0 ? isSelected : (_state$addonCardSelec5 = state.addonCardSelections) === null || _state$addonCardSelec5 === void 0 ? void 0 : (_state$addonCardSelec6 = _state$addonCardSelec5[productId]) === null || _state$addonCardSelec6 === void 0 ? void 0 : _state$addonCardSelec6.isSelected,
              quantityPerBox: nextQuantityPerBox
            })
          })
        });
      }
    case CHANGE_ADDON_CARD_QUANTITY_PER_BOX:
      {
        var _state$addonCardSelec7, _state$addonCardSelec8;
        const {
          productId,
          quantityPerBox
        } = action.payload;
        return _objectSpread({}, state, {
          addonCardSelections: _objectSpread({}, state.addonCardSelections, {
            [productId]: {
              isSelected: (_state$addonCardSelec7 = state.addonCardSelections) === null || _state$addonCardSelec7 === void 0 ? void 0 : (_state$addonCardSelec8 = _state$addonCardSelec7[productId]) === null || _state$addonCardSelec8 === void 0 ? void 0 : _state$addonCardSelec8.isSelected,
              quantityPerBox
            }
          })
        });
      }
    case UPLOAD_CUSTOM_BOX_LOGO_LOADING:
      {
        return _objectSpread({}, state, {
          customBoxLogoLoading: true
        });
      }
    case UPLOAD_CUSTOM_BOX_LOGO_FAIL:
      {
        return _objectSpread({}, state, {
          customBoxLogoLoading: false
        });
      }
    case UPLOAD_CUSTOM_BOX_LOGO_SUCCESS:
      {
        return _objectSpread({}, state, {
          // @todo. auto generated. fix this error
          // @ts-ignore
          customBoxInfo: _objectSpread({}, state.customBoxInfo, {
            logo: action.body
          }),
          customBoxLogoLoading: false
        });
      }
    case SET_BOX_NAME_EDIT_ID:
      {
        return _objectSpread({}, state, {
          editingBoxNameBoxId: action.payload
        });
      }
    case SET_BOX_TYPE_EDIT_ID:
      {
        return _objectSpread({}, state, {
          editingBoxTypeBoxId: action.payload
        });
      }
    case SET_BOX_BUILDER_EDIT_MODE_BOX_ITEMS_ID:
      {
        return _objectSpread({}, state, {
          // @todo. auto generated. fix this error
          // @ts-ignore
          currentBoxesQuantity: null,
          editBoxBuilderItemsId: action.payload
        });
      }
    case UPLOAD_BROWN_BOX_LOGO_LOADING:
      {
        return _objectSpread({}, state, {
          brownBoxLogoLoading: true
        });
      }
    case UPLOAD_BROWN_BOX_LOGO_FAIL:
      {
        return _objectSpread({}, state, {
          brownBoxLogoLoading: false
        });
      }
    case UPLOAD_BROWN_BOX_LOGO_SUCCESS:
      {
        return _objectSpread({}, state, {
          brownBoxInfo: _objectSpread({}, state.customBoxInfo, {
            tapeLogo: action.body
          }),
          brownBoxLogoLoading: false
        });
      }
    case RESET_BOX_BUILDER_STATE:
      {
        return initialState;
      }
    default:
      return state;
  }
}