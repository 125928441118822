import { PAYMENT_STATES } from 'swag-client-common/utils/constants';
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
const initialDelivery = {
  fname: '',
  lname: '',
  company: '',
  email: '',
  phoneNumber: '',
  fnameShipping: '',
  lnameShipping: '',
  address: '',
  unit: '',
  city: '',
  country: COUNTRY_CODES.US,
  state: 'AL',
  zip: '',
  deliveryNote: '',
  isDeliveryPrefilled: false,
  shippingNameUntouched: true,
  shippingAddressUntouched: true,
  gettingRates: false,
  snackbarOpened: false,
  err: '',
  msg: ''
};
export const initialState = {
  loading: false,
  dialogIsOpen: undefined,
  emailVerified: undefined,
  passwordNotSet: undefined,
  delivery: initialDelivery,
  deliveryOptionWasChosen: false,
  deliverToFulfillmentCenter: false,
  inventoryAccepted: false,
  acceptContract: false,
  isCreateMyInventorySelected: false,
  credits: {
    available: 0,
    pending: 0,
    moreToBuy: 0,
    isBuyMoreFlow: false
  },
  inventoryCreditsHasFulfilledBuyTransactions: false,
  // ati - Add To Inventory Widget
  atiWidgetIsOpen: 0,
  atiWidgetIsTouched: false,
  rates: [],
  addressConfirmed: false,
  paymentState: PAYMENT_STATES.IDLE,
  previousPaymentState: PAYMENT_STATES.IDLE,
  err: null,
  userEmail: null,
  paymentPopupOpen: false,
  orderForCustomStore: null,
  isInventoryApprovalFlow: false,
  neededTokens: 1,
  autocompleteList: [],
  isAddressEnteredManually: false,
  isCreditsMoreToBuyFlow: false,
  isTOSAccepted: false,
  replacementOrder: {
    searchValue: '#',
    selectedItemId: null,
    replacementPriceRange: null,
    replacementPriceRangeChanged: false,
    comments: '',
    isLoading: false,
    isSilent: false,
    items: {
      ids: [],
      data: {}
    }
  },
  showReviewColorsStage: false,
  publicId: '',
  resellersCommission: null,
  productsCommission: []
};