function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { browserHistory } from 'react-router';
import { STATE_IDLE, STATE_LOADING, STATE_ERROR } from '../redux.constants';
import { SEARCH_REQUEST, SEARCH_SUCCESS, CLEAR_RESULTS, EDIT_QUERY, OPEN_PRODUCT_FROM_CATEGORY_ENABLE, OPEN_PRODUCT_FROM_CATEGORY_DISABLE, GO_TO_SEARCH_RESULTS_PAGE, CHANGE_ACTIVE_STATE, CHANGE_IS_SEARCH_RESULTS_SHOWN, SEARCH_ERROR, GET_SEARCH_SUGGESTIONS_SUCCESS } from './search-products.actions';
const currentLocation = browserHistory.getCurrentLocation();
const {
  query = ''
} = currentLocation.query;
const initialState = {
  isActive: false,
  isSearchResultsShown: true,
  loadingState: STATE_IDLE,
  isOpeningProductFromCategory: false,
  products: {
    matchByName: [],
    matchByCategory: [],
    matchByTag: []
  },
  total: null,
  query: query,
  didSearch: false,
  currentCategoryName: '',
  searchSuggestions: []
};
export default function searchProductsReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_REQUEST:
      {
        return _objectSpread({}, state, {
          didSearch: true,
          loadingState: STATE_LOADING
        });
      }
    case SEARCH_SUCCESS:
      {
        const {
          matchByName = [],
          matchByCategory = [],
          matchByTag = [],
          total
        } = action.payload;
        return _objectSpread({}, state, {
          loadingState: STATE_IDLE,
          products: {
            matchByName,
            matchByCategory,
            matchByTag
          },
          total
        });
      }
    case SEARCH_ERROR:
      {
        return _objectSpread({}, state, {
          products: {
            matchByName: [],
            matchByCategory: [],
            matchByTag: []
          },
          loadingState: STATE_ERROR
        });
      }
    case CLEAR_RESULTS:
      {
        return _objectSpread({}, initialState, {
          isActive: state.isActive,
          query: state.query,
          searchSuggestions: state.searchSuggestions
        });
      }
    case GO_TO_SEARCH_RESULTS_PAGE:
      {
        return _objectSpread({}, initialState, {
          products: state.products,
          query: state.query,
          searchSuggestions: state.searchSuggestions
        });
      }
    case EDIT_QUERY:
      {
        const query = action.payload;
        return _objectSpread({}, state, {
          query
        });
      }
    case OPEN_PRODUCT_FROM_CATEGORY_ENABLE:
      {
        return _objectSpread({}, state, {
          isOpeningProductFromCategory: true
        });
      }
    case CHANGE_ACTIVE_STATE:
      {
        const isActive = action.payload;
        return _objectSpread({}, state, {
          isActive
        });
      }
    case CHANGE_IS_SEARCH_RESULTS_SHOWN:
      {
        const isSearchResultsShown = action.payload;
        return _objectSpread({}, state, {
          isSearchResultsShown
        });
      }
    case OPEN_PRODUCT_FROM_CATEGORY_DISABLE:
      {
        return _objectSpread({}, state, {
          isOpeningProductFromCategory: false
        });
      }
    case GET_SEARCH_SUGGESTIONS_SUCCESS:
      {
        const {
          searchSuggestions
        } = action.payload;
        return _objectSpread({}, state, {
          searchSuggestions
        });
      }
    default:
      return state;
  }
}