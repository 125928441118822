import { createSelector } from 'reselect';
const cartRootSelector = state => state.cart;
const cartItemsSelector = createSelector(cartRootSelector, cart => cart.items);
const makeItemsFlat = items => items.reduce((acc, item) => {
  const flatItems = item.products ? [item, ...item.products] : item;
  return acc.concat(flatItems);
}, []);
export const flatCartItemsSelector = createSelector(cartItemsSelector, makeItemsFlat);
export const isAddToCartClickedSelector = createSelector(cartRootSelector, cart => cart.isAddToCartClicked);
export const isAddToBoxClickedSelector = createSelector(cartRootSelector, cart => cart.isAddToBoxClicked);
export const isBuyPrintedClickedSelector = createSelector(cartRootSelector, cart => cart.isBuyPrintedClicked);