import { createApi } from 'swag-client-common/libs/rapi';
const prefix = '/api/v1';
export const CompanyArtFolderApi = createApi({
  getList: {
    url: `${prefix}/company-art-folder`,
    method: 'get'
  },
  deleteArtFolderLogo: {
    url: `${prefix}/company-art-folder/:id`,
    method: 'delete'
  },
  saveArtFolderLogos: {
    url: `${prefix}/company-art-folder`,
    method: 'post'
  }
});