import { getIsReviewMockupsByCustomerAllowedFromOrder, getIsReviewPantonesByCustomerAllowedFromOrder } from 'swag-common/utils/order/specific-reviews-permissions.util';
export const itemsToReviewPantoneColorsSelector = state => state.accountOrders.itemsToReviewPantoneColors;
export const isReviewPantonesByCustomerAllowedSelector = (state, {
  orderId
}) => {
  const order = state.accountOrders.orders.find(({
    _id
  }) => _id === orderId);
  return getIsReviewPantonesByCustomerAllowedFromOrder(order);
};
export const isReviewMockupsByCustomerAllowedSelector = (state, {
  orderId
}) => {
  const order = state.accountOrders.orders.find(({
    _id
  }) => _id === orderId);
  return getIsReviewMockupsByCustomerAllowedFromOrder(order);
};