import _uniqBy from "lodash/uniqBy";
import { createSelector } from 'reselect';
import { DEFAULT_PANTONE_COLORS, MEDIA_TYPES } from 'swag-client-common/utils/constants';
import { DesignUploadMode } from 'swag-common/interfaces';
import { BOX_SINGLE_LOGO } from 'swag-client-common/constants';
export const selectedLogoIdSelector = state => state.logo.selectedLogo;
export const logosSelector = state => state.logo.logos;
export const isLogoDimensionsManuallyModifiedSelector = state => state.logo.isLogoDimensionsManuallyModified;
export const layersSelector = state => state.logo.layers;
export const layersByLocationSelector = state => state.logo.layers[state.product.view];
export const textsSelector = state => state.logo.texts;
export const fontsSelector = state => state.logo.fonts;
export const exactPantonesSelector = state => state.logo.exactPantones;
export const isAddTextModeSelector = state => state.product.isAddTextMode;
const isBoxProductSelector = state => state.product.product && state.product.product.designUploadMode === DesignUploadMode.box;
export const selectedLogoSelector = createSelector(selectedLogoIdSelector, logosSelector, layersByLocationSelector, isBoxProductSelector, textsSelector, (selectedId, logos, currentLocationLogos, isBox, texts) => {
  if (!isBox && currentLocationLogos && currentLocationLogos.length === 1) {
    return logos[currentLocationLogos[0]] || texts[currentLocationLogos[0]];
  }
  return logos[selectedId] || texts[selectedId];
});
export const currentViewLogosList = state => {
  const {
    logo: {
      layers,
      texts
    },
    product: {
      view
    }
  } = state;
  return layers[view] && layers[view].map(mediaId => {
    if (texts[mediaId]) {
      return {
        type: MEDIA_TYPES.TEXTS,
        mediaId
      };
    }
    return {
      type: MEDIA_TYPES.LOGOS,
      mediaId
    };
  }) || [];
};
export const currentLogosBasedOnView = (state, props) => {
  const {
    logo: {
      layers,
      texts
    }
  } = state;
  const {
    view
  } = props;
  return layers[view] && layers[view].map(mediaId => {
    if (texts[mediaId]) {
      return {
        type: MEDIA_TYPES.TEXTS,
        mediaId
      };
    }
    return {
      type: MEDIA_TYPES.LOGOS,
      mediaId
    };
  }) || [];
};
export const singleViewLogosSelector = (state, props) => {
  const {
    logos,
    layers,
    texts
  } = state.logo;
  const {
    view
  } = props;
  let viewLayer = layers[view] || [];
  if (view === BOX_SINGLE_LOGO) {
    const location = Object.keys(layers).find(key => key.includes(BOX_SINGLE_LOGO));

    // @todo. auto generated. fix this error
    // @ts-ignore
    viewLayer = layers[location] || [];
  }
  return viewLayer.map(mediaId => !texts[mediaId] && logos[mediaId]).filter(mediaId => mediaId);
};
export const singleLogoSelector = (state, props) => {
  if (state.logo.logos[props.mediaId]) {
    return state.logo.logos[props.mediaId];
  }
  return state.logo.texts[props.mediaId];
};
export const isLogoUploadedSelector = (state, props) => {
  const {
    logo: {
      layers
    }
  } = state;
  const logos = Object.values(state.logo.logos);
  let view = props.view;
  if (view.includes(BOX_SINGLE_LOGO)) {
    view = Object.keys(layers).find(key => key.includes(BOX_SINGLE_LOGO));
  }
  return !!logos.length && !!logos.filter(({
    location
  }) => location === view).length;
};
export const isAnyLogoUploadedSelector = createSelector(logosSelector, logos => !!Object.keys(logos).length);
export const pantoneSelector = createSelector(singleViewLogosSelector, exactPantonesSelector, (logos, exactPantones) => {
  const pantones = logos && logos.reduce((allColors, logo) => [...allColors, ...logo.colors.detectedColors], DEFAULT_PANTONE_COLORS) || DEFAULT_PANTONE_COLORS;
  return _uniqBy([...pantones, ...exactPantones], 'pms');
});
export const addTextDialogSelector = createSelector(textsSelector, fontsSelector, selectedLogoIdSelector, pantoneSelector, isAddTextModeSelector, (texts, fonts, selectedMedia, pantones, isAddTextMode) => {
  const isAddMoreShown = !!Object.keys(texts).length;
  return {
    fonts,
    isAddMoreShown,
    text: texts[selectedMedia] || {},
    selectedMedia,
    pantones,
    isAddTextMode
  };
});