import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/custom-store-api/v1/product';
const StoreApi = createApi({
  addProduct: {
    url: baseUrl,
    method: 'post'
  },
  addBoxProduct: {
    url: `${baseUrl}/box`,
    method: 'post'
  }
});
export default StoreApi;