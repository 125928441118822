import _getFormMeta from "redux-form/es/getFormMeta";
import _formValueSelector from "redux-form/es/formValueSelector";
import { createSelector } from 'reselect';
import { getCompletedPasswordRequirements } from 'swag-client-common/utils/get-password-requirements.utils';
import { MAX_PASSWORD_LENGTH, PASSWORD_REQUIREMENTS } from 'swag-common/constants/validations';
import { ReduxFormNames } from 'swag-client-common/constants';
const userSelector = state => state.user;
const isErrorSelector = createSelector(userSelector, user => !!user.err);
const errorMessageSelector = createSelector(userSelector, user => user.err);
const isSuccessSelector = createSelector(userSelector, user => user.isSuccess);
const isLoadingSelector = createSelector(userSelector, user => user.isLoading);
const isPasswordNotSetSelector = createSelector(userSelector, user => user.isPasswordNotSet);
const changePasswordSelector = state => state.profilePasswordForm;
const isChangePasswordLoadingSelector = createSelector(changePasswordSelector, profilePassword => profilePassword.isLoading);
const isChangePasswordErrorSelector = createSelector(changePasswordSelector, profilePassword => !!profilePassword.err);
const isChangePasswordSuccessSelector = createSelector(changePasswordSelector, profilePassword => profilePassword.isSuccess);
const errorMessageChangePasswordSelector = createSelector(changePasswordSelector, profilePassword => profilePassword.err);
const changeFormPasswordSelector = state => {
  return _formValueSelector(ReduxFormNames.ChangePasswordForm)(state, 'newPassword') || '';
};
const changeFormCompletedPasswordRequirementsSelector = createSelector(changeFormPasswordSelector, password => {
  return getCompletedPasswordRequirements(password);
});
const isChangeFormPasswordLengthMoreThanMaxAllowedCharactersSelector = createSelector(changeFormPasswordSelector, password => {
  return password.length > MAX_PASSWORD_LENGTH;
});
const isChangeFormAllPasswordRequirementsCompletedSelector = createSelector(isChangeFormPasswordLengthMoreThanMaxAllowedCharactersSelector, changeFormCompletedPasswordRequirementsSelector, (isPasswordLengthMoreThanMaxAllowedCharacters, completedPasswordRequirements) => {
  return !isPasswordLengthMoreThanMaxAllowedCharacters && completedPasswordRequirements.length === PASSWORD_REQUIREMENTS.length;
});
const isChangeFormPasswordInputFocusedSelector = state => {
  var _formMeta$newPassword;
  const formMeta = _getFormMeta(ReduxFormNames.ChangePasswordForm)(state);
  return !!(formMeta !== null && formMeta !== void 0 && (_formMeta$newPassword = formMeta.newPassword) !== null && _formMeta$newPassword !== void 0 && _formMeta$newPassword.active);
};
const setPasswordSelector = state => state.setPassword;
const isSetPasswordLoadingSelector = createSelector(setPasswordSelector, setPassword => setPassword.isLoading);
const isSetPasswordErrorSelector = createSelector(setPasswordSelector, setPassword => !!setPassword.err);
const isSetPasswordSuccessSelector = createSelector(setPasswordSelector, setPassword => setPassword.isSuccess);
const errorMessageSetPasswordSelector = createSelector(setPasswordSelector, setPassword => setPassword.err);
const setFormPasswordSelector = state => {
  return _formValueSelector(ReduxFormNames.SetPasswordByLoggedInForm)(state, 'password') || '';
};
const setFormCompletedPasswordRequirementsSelector = createSelector(setFormPasswordSelector, password => {
  return getCompletedPasswordRequirements(password);
});
const isSetFormPasswordLengthMoreThanMaxAllowedCharactersSelector = createSelector(setFormPasswordSelector, password => {
  return password.length > MAX_PASSWORD_LENGTH;
});
const isSetFormAllPasswordRequirementsCompletedSelector = createSelector(isSetFormPasswordLengthMoreThanMaxAllowedCharactersSelector, setFormCompletedPasswordRequirementsSelector, (isPasswordLengthMoreThanMaxAllowedCharacters, completedPasswordRequirements) => {
  return !isPasswordLengthMoreThanMaxAllowedCharacters && completedPasswordRequirements.length === PASSWORD_REQUIREMENTS.length;
});
const isSetFormPasswordInputFocusedSelector = state => {
  var _formMeta$password;
  const formMeta = _getFormMeta(ReduxFormNames.SetPasswordByLoggedInForm)(state);
  return !!(formMeta !== null && formMeta !== void 0 && (_formMeta$password = formMeta.password) !== null && _formMeta$password !== void 0 && _formMeta$password.active);
};
export { isErrorSelector, errorMessageSelector, isSuccessSelector, isLoadingSelector, isPasswordNotSetSelector, changeFormCompletedPasswordRequirementsSelector, isChangeFormAllPasswordRequirementsCompletedSelector, isChangeFormPasswordLengthMoreThanMaxAllowedCharactersSelector, isChangeFormPasswordInputFocusedSelector, isChangePasswordLoadingSelector, isChangePasswordErrorSelector, isChangePasswordSuccessSelector, errorMessageChangePasswordSelector, setFormCompletedPasswordRequirementsSelector, isSetFormAllPasswordRequirementsCompletedSelector, isSetFormPasswordLengthMoreThanMaxAllowedCharactersSelector, isSetFormPasswordInputFocusedSelector, isSetPasswordLoadingSelector, isSetPasswordErrorSelector, isSetPasswordSuccessSelector, errorMessageSetPasswordSelector };