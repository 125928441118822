import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/page-management';
export const PageApi = createApi({
  getFaqPages: {
    url: `${baseUrl}/faq`,
    method: 'get'
  },
  update: {
    url: `${baseUrl}/faq/update`,
    method: 'post'
  },
  saveImages: {
    url: `${baseUrl}/past-works/save-images`,
    method: 'put'
  },
  removeQuestion: {
    url: `${baseUrl}/faq/remove-question`,
    method: 'put'
  },
  getBrowseProductsPage: {
    url: `${baseUrl}/browse-products`,
    method: 'get'
  },
  updatePromoText: {
    url: `${baseUrl}/browse-products/promo-text`,
    method: 'put'
  },
  updateCategoryFAQ: {
    url: `${baseUrl}/browse-products/category-faq`,
    method: 'put'
  },
  getPromoTexts: {
    url: `${baseUrl}/browse-products/promo-text`,
    method: 'get'
  },
  getPastWorksPage: {
    url: `${baseUrl}/past-works`,
    method: 'get'
  }
});