import { hasOwnProperties } from 'swag-common/utils/has-own-properties';
import { getItemMockupsBasedOnProductImage } from 'swag-common/business-logic/items/get-item-mockups-based-on-product-image.logic';
import { getItemMockupsBasedOnSelectedVariants } from 'swag-common/business-logic/items/get-item-mockups-based-on-selected-variants.logic';
const productImageSelector = state => {
  const {
    item
  } = state.showMockups;
  const images = getItemMockupsBasedOnProductImage(item);
  return [images];
};
const mockupsSelector = state => {
  const {
    item
  } = state.showMockups;
  const mockupsMap = getItemMockupsBasedOnSelectedVariants(item);
  const mockups = Object.values(mockupsMap);
  return mockups;
};
export const showMockupsSelector = state => {
  const {
    item: {
      customerMockups
    }
  } = state.showMockups;
  const mockups = hasOwnProperties(customerMockups) ? mockupsSelector(state) : productImageSelector(state);
  return {
    mockups
  };
};