import { LOW_ON_STOCK_LEVEL } from '../../constants/inventory';
export default function isProductLowOnStock(variant) {
  const {
    quantity,
    lastOrderQuantity = 0,
    inProductionQuantity,
    settings
  } = variant;
  if (lastOrderQuantity === 0) {
    return false;
  }
  const inStockSnapshot = settings && settings.inStockSnapshot || 0;
  const lowOnStockLevel = (lastOrderQuantity + inStockSnapshot) * LOW_ON_STOCK_LEVEL;

  // @todo. auto generated. fix this error
  // @ts-ignore
  return quantity + inProductionQuantity <= lowOnStockLevel;
}