export const predefinedNotifications = {
  signup: 'Your account has been created. Please check your inbox and verify your email address',
  login: 'You have successfully logged in',
  emailVerified: 'Your email has been confirmed.',
  DEPARTMENT_EDITED: 'Company department successfully edited',
  DEPARTMENT_DELETED: 'Company department successfully deleted',
  DEPARTMENT_CREATED: 'Company department successfully created',
  PENDING_ORDER_APPROVED: 'Pending order successfully approved',
  PENDING_ORDER_REJECTED: 'Pending order successfully rejected',
  signupAndVerify: 'You have successfully signed up, please check your inbox to verify your email'
};
export const NOTIFICATIONS = {
  DEPARTMENT_EDITED: 'DEPARTMENT_EDITED',
  DEPARTMENT_DELETED: 'DEPARTMENT_DELETED',
  DEPARTMENT_CREATED: 'DEPARTMENT_CREATED',
  PENDING_ORDER_APPROVED: 'PENDING_ORDER_APPROVED',
  PENDING_ORDER_REJECTED: 'PENDING_ORDER_REJECTED'
};
export const PUBLIC_API_NOTIFICATIONS = {
  CREDIT_CARD_SAVED: 'Card has been successfully saved.',
  CREDIT_CARD_DELETED: 'Card has been successfully deleted.'
};