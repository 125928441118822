const PREFIX = 'COMMON_';
export const SHOW_LOADER = `${PREFIX}SHOW_LOADER`;
export const showLoader = () => ({
  type: SHOW_LOADER
});
export const HIDE_LOADER = `${PREFIX}HIDE_LOADER`;
export const hideLoader = () => ({
  type: HIDE_LOADER
});
export const ENABLE_ROUTE_CONFIRMATION = `${PREFIX}ENABLE_ROUTE_CONFIRMATION`;
export const enableRouteConfirmation = func => ({
  type: ENABLE_ROUTE_CONFIRMATION,
  payload: func
});
export const DISABLE_ROUTE_CONFIRMATION = `${PREFIX}DISABLE_ROUTE_CONFIRMATION`;
export const disableRouteConfirmation = () => ({
  type: DISABLE_ROUTE_CONFIRMATION
});