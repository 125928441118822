import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { isRelatedItem } from 'swag-common/utils/items/is-item-shared-or-related';
export const getRealSetupFee = data => {
  const {
    quantity,
    blendedQuantity
  } = data.item;
  let setupFee;
  if ('setupFee' in data) {
    setupFee = data.setupFee;
  } else if ('setupFee' in data.item) {
    setupFee = data.item.setupFee;
  } else {
    throw Error("Wrong 'setupFee' typing for 'getRealSetupFee' util");
  }
  let isShared;
  if ('isShared' in data) {
    isShared = data.isShared;
  } else if ('items' in data) {
    isShared = isSharedItem(data.item, data.items);
  } else {
    throw Error("Wrong 'isShared' typing for 'getRealSetupFee' util");
  }
  let isRelated;
  if ('isRelated' in data) {
    isRelated = data.isRelated;
  } else if ('items' in data) {
    isRelated = isRelatedItem(data.item, data.items, true);
  } else {
    throw Error("Wrong 'isRelated' typing for 'getRealSetupFee' util");
  }
  const setupFeeCoefficient = blendedQuantity ? quantity / blendedQuantity : 1;
  return isShared || isRelated ? setupFee * setupFeeCoefficient : setupFee;
};