import { createApi } from 'swag-client-common/libs/rapi';
const CompanyDepartmentApi = createApi({
  getCompanyDepartments: {
    url: '/api/v1/company-departments',
    method: 'get'
  },
  createCompanyDepartment: {
    url: '/api/v1/company-departments',
    method: 'post'
  },
  updateCompanyDepartment: {
    url: '/api/v1/company-departments/:id',
    method: 'put'
  },
  removeCompanyDepartment: {
    url: '/api/v1/company-departments/:id',
    method: 'delete'
  }
}, {
  replaceUnnecessaryFields: true
});
export default CompanyDepartmentApi;