function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { STATE_IDLE, STATE_LOADING, STATE_ERROR, STATE_SUCCESS } from '../redux.constants';
import { SET_MOCKUP_MANAGER_DATA, EDIT_MOCKUP_CHANGE_REQUEST, REQUESTING_CHANGES_MOCKUP_STARTED, REQUESTING_CHANGES_ERROR, REQUESTING_CHANGES_SUCCESS, APPROVING_MOCKUP_STARTED, APPROVING_ERROR, APPROVING_SUCCESS, RESET_STATE, USER_SKIPPED_BEFORE_APPROVING_MOCKUP, REQUEST_CHANGES, SET_IS_LOGO_UPLOAD_COMPLETED } from './mockup-manager.actions';
const initialState = {
  mockup: null,
  orderId: null,
  itemId: null,
  state: STATE_IDLE,
  requestChangesState: STATE_IDLE,
  isUserReadBeforeApproving: false,
  isRequestingChanges: false,
  isNotecardReview: false,
  isLogoUploadCompleted: false,
  isDisabled: false
};
export default function mockupManagerReducer(state = initialState, action) {
  switch (action.type) {
    case USER_SKIPPED_BEFORE_APPROVING_MOCKUP:
      {
        return _objectSpread({}, state, {
          isUserReadBeforeApproving: true
        });
      }
    case REQUEST_CHANGES:
      {
        return _objectSpread({}, state, {
          isRequestingChanges: action.payload
        });
      }
    case SET_MOCKUP_MANAGER_DATA:
      {
        const {
          mockup,
          itemId,
          orderId,
          isNotecardReview
        } = action;
        return _objectSpread({}, state, {
          mockup,
          itemId,
          orderId,
          isNotecardReview
        });
      }
    case EDIT_MOCKUP_CHANGE_REQUEST:
      {
        const {
          value
        } = action;
        const mockup = _objectSpread({}, state.mockup);
        mockup.changeRequest = value;
        return _objectSpread({}, state, {
          mockup
        });
      }
    case REQUESTING_CHANGES_MOCKUP_STARTED:
      {
        return _objectSpread({}, state, {
          requestChangesState: STATE_LOADING
        });
      }
    case REQUESTING_CHANGES_ERROR:
      {
        return _objectSpread({}, state, {
          requestChangesState: STATE_ERROR
        });
      }
    case REQUESTING_CHANGES_SUCCESS:
      {
        return _objectSpread({}, state, {
          requestChangesState: STATE_SUCCESS
        });
      }
    case APPROVING_MOCKUP_STARTED:
      {
        return _objectSpread({}, state, {
          state: STATE_LOADING
        });
      }
    case APPROVING_ERROR:
      {
        return _objectSpread({}, state, {
          state: STATE_ERROR
        });
      }
    case APPROVING_SUCCESS:
      {
        return _objectSpread({}, state, {
          state: STATE_SUCCESS
        });
      }
    case SET_IS_LOGO_UPLOAD_COMPLETED:
      {
        return _objectSpread({}, state, {
          isLogoUploadCompleted: action.payload.isLogoUploadCompleted
        });
      }
    case RESET_STATE:
      {
        return _objectSpread({}, initialState);
      }
    default:
      return state;
  }
}