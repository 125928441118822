import _uniqBy from "lodash/uniqBy";
import _intersectionBy from "lodash/intersectionBy";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { sortCards } from 'swag-client-common/utils/stripe-cards.utils';
import { isItemBlankSampleOrPrintedSample } from 'swag-common/utils/items/is-item-sample.service';
import { isItemSampleBox } from 'swag-common/utils/order/is-item-sample-box.util';
import { isForbiddenForInventoryItemExists } from 'swag-common/utils/items/is-forbidden-for-inventory.util';
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
export { isDeliverToFulfillmentCenter, isToFulfillmentCenterAddressValid } from './checkout.utils-other';
export const getRequiredFields = delivery => {
  const values = [delivery.fname, delivery.lname, delivery.email, delivery.fnameShipping, delivery.lnameShipping, delivery.address, delivery.country, delivery.city, delivery.zip];
  if (delivery.country !== COUNTRY_CODES.US) {
    values.push(delivery.phoneNumber);
  }
  return values;
};
export const getDeliveryFormFields = () => ['fname', 'lname', 'phoneNumber', 'company', 'email', 'fnameShipping', 'lnameShipping', 'address', 'unit', 'city', 'state', 'country', 'zip', 'companyDepartmentId'];
export const isSample = item => {
  return isItemBlankSampleOrPrintedSample(item) || isItemSampleBox(item);
};
export const isDeliveryToInventoryPossible = (items, deliverToFulfillmentCenter) => {
  const isSampleInCartExist = items.some(isSample);
  const isForbiddenForInventoryExist = isForbiddenForInventoryItemExists(items);
  const isAnyItemGoToWarehouse = Boolean(items.filter(i => i.inventoryId).length);
  const deliverToWarehouse = isAnyItemGoToWarehouse || deliverToFulfillmentCenter;
  return deliverToWarehouse && !isSampleInCartExist && !isForbiddenForInventoryExist;
};
export const formatCard = card => {
  if (!card) {
    return {};
  }
  return {
    holder: card.name,
    number: card.last4,
    brand: card.brand,
    userDefault: card.userDefault,
    inventoryDefault: card.inventoryDefault,
    _id: card._id
  };
};
export const getCardsByInventories = settings => {
  if (!settings.length) {
    return [];
  }
  const cards = settings.map(setting => {
    if (!setting) {
      return [];
    }
    const {
      cards: settingsCards,
      defaultInventoryCard,
      defaultCard
    } = setting;
    return settingsCards.map(card => _objectSpread({}, card, {
      userDefault: card._id === defaultCard,
      inventoryDefault: card._id === defaultInventoryCard
    })).filter(c => !c.inventoryDefault).sort(sortCards);
  });
  const commonCards = cards.reduce((all, next) => _intersectionBy(all, next, 'fingerprint'), cards[0]);

  // check is payment will be done for one inventory
  // if so - find and add inventory default card
  // case when inventory default card has same card number as user default
  const isOneInventory = settings.length === 1;
  const inventoryDefaultCard = isOneInventory && settings[0] ? settings[0].cards.find(card => card._id === settings[0].defaultInventoryCard) : null;
  if (inventoryDefaultCard) {
    commonCards.unshift(inventoryDefaultCard);
  }
  return _uniqBy(commonCards, '_id');
};
export const getApprovalDepartmentName = (companyDepartments, companyDepartmentId) => {
  const companyDepartment = companyDepartmentId ? companyDepartments.find(department => companyDepartmentId === department._id) : null;
  return companyDepartment ? companyDepartment.name : '';
};