export const STATE_LOADING = 'loading';
export const STATE_IDLE = 'idle';
export const STATE_ERROR = 'error';
export const STATE_SUCCESS = 'success';
export const IN_A_BOX_FORM = 'getStartedInABoxForm';
export const ENTERPRISE_FORM = 'getStartedEnterpriseForm';
export const PATAGONIA_FORM = 'getStartedPatagoniaForm';
export const COMPANY_DEPARTMENT_FORM = 'companyDepartmentForm';
export const GIVEAWAY_FORM = 'giveawayForm';
export const AUTOMATION_FORM = 'automationForm';
export const ZAPIER_FORM = 'zapierForm';
export const SHOPIFY_FORM = 'shopifyForm';
export const RESELLER_FORM = 'resellerForm';
export const PAYMENT_DETAILS_FORM = 'paymentDetailsBillingAddress';
export const REQUEST_DEMO_FORM = 'requestDemoForm';
export const CUSTOM_SWAG_FORM = 'customSwagForm';
export const CONTACT_FORM = 'contactForm';