import getBoxVariantSizeName from '../custom-store-product/get-box-variant-size-name.logic';
import isBox from '../../utils/custom-store-product/product-is-box.util';
export const getShipoutProductsDetails = products => {
  return Array.from(products.reduce((acc, variant) => acc.set(`${String(variant.parentId)}${variant.colorName}`, (acc.get(`${String(variant.parentId)}${variant.colorName}`) || []).concat(variant)), new Map()).entries()).map(([key, variantsByColor]) => {
    const shipoutQuantity = variantsByColor.reduce((acc, variant) => acc + variant.shipoutQuantity, 0);
    if (isBox(variantsByColor[0].swagProductId) && variantsByColor.length > 0) {
      const sizeCounts = variantsByColor.map(variant => {
        const {
          shipoutQuantity
        } = variant;
        const sizeVariantName = getBoxVariantSizeName(variant);
        return sizeVariantName ? {
          quantity: shipoutQuantity,
          sizeName: sizeVariantName
        } : null;
      }).filter(Boolean);
      return {
        key,
        variants: variantsByColor,
        shipoutQuantity,
        sizeCounts,
        parentId: variantsByColor[0].parentId
      };
    }
    const sizeCounts = variantsByColor.filter(variant => variant.sizeSettings).map(({
      sizeIndex,
      sizeSettings,
      shipoutQuantity
    }) => {
      const sizeVariantName = sizeSettings.options[sizeIndex];
      return sizeVariantName ? {
        quantity: shipoutQuantity,
        sizeName: sizeVariantName
      } : null;
    }).filter(Boolean);
    return {
      key,
      variants: variantsByColor,
      shipoutQuantity,
      sizeCounts,
      parentId: variantsByColor[0].parentId
    };
  });
};