import _flatMap from "lodash/flatMap";
import { defaultProdTime, getBoxProductionTimeList, getBundleProductionTimeList } from '../get-box-production-time-list.util';
import { isBox } from '../order/item-is-box.util';
export const getProductionTimeListForItem = (item, isCart, isCheckout, draftItems, items) => {
  var _item$prod$productSet, _item$prod, _item$prod$productSet2, _item$prod$productSet3;
  if (isBox(item)) {
    const correctItems = isCart || isCheckout ? items : draftItems;
    return getBoxProductionTimeList(item, correctItems);
  }
  if (item.bundleCustomProductId) {
    return getBundleProductionTimeList(item, items);
  }
  return (_item$prod$productSet = item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : (_item$prod$productSet2 = _item$prod.productSettings) === null || _item$prod$productSet2 === void 0 ? void 0 : (_item$prod$productSet3 = _item$prod$productSet2[0]) === null || _item$prod$productSet3 === void 0 ? void 0 : _item$prod$productSet3.productionTimeList) !== null && _item$prod$productSet !== void 0 ? _item$prod$productSet : [defaultProdTime];
};
export const getPossibleProductionListForItem = (item, isCart, isCheckout, draftItems, items) => {
  var _item$allProductSetti;
  if (isBox(item)) {
    const correctItems = isCart || isCheckout ? items : draftItems;
    return getBoxProductionTimeList(item, correctItems);
  }
  return _flatMap(item === null || item === void 0 ? void 0 : (_item$allProductSetti = item.allProductSettings) === null || _item$allProductSetti === void 0 ? void 0 : _item$allProductSetti.filter(settings => !!settings.embellishmentMethodId).map(({
    productionTimeList
  }) => productionTimeList)) || [];
};