function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import getBoxVariantSizeName from 'swag-common/business-logic/custom-store-product/get-box-variant-size-name.logic';
import { InReservePopupTypes } from 'swag-common/constants/product.constants';
import getEmptyQuantities from './get-empty-quantities.util';
export function mapSizesToOneVariantColor(variants, sizeSettings, isBox, type) {
  return variants.reduce((uniqueVariantsByColor, variant) => {
    var _colors$indexOfExiste;
    const {
      colorName,
      colorValue,
      sizeIndex,
      inProductionQuantity,
      inReserveDetails
    } = variant;
    const colors = [...uniqueVariantsByColor];
    const indexOfExistedItemInList = colors.findIndex(variant => variant.colorName === colorName);
    const quantityPerSize = getQuantityByType({
      type,
      variant
    });
    const variantSizeIndex = isBox && sizeSettings ? sizeSettings.options.indexOf(getBoxVariantSizeName(variant)) : sizeIndex;
    if (indexOfExistedItemInList === -1) {
      return [...colors, {
        colorName,
        colorValue,
        quantity: quantityPerSize,
        inProductionQuantity,
        quantities: {
          [variantSizeIndex]: quantityPerSize || 0
        },
        inReserveDetailsByColor: inReserveDetails && inReserveDetails.length ? inReserveDetails : []
      }];
    }
    if (!colors[indexOfExistedItemInList].inReserveDetailsByColor) {
      colors[indexOfExistedItemInList].inReserveDetailsByColor = [];
    }
    colors[indexOfExistedItemInList].quantity += quantityPerSize;
    colors[indexOfExistedItemInList] = _objectSpread({}, colors[indexOfExistedItemInList], {
      quantities: _objectSpread({}, getEmptyQuantities(sizeSettings).reduce((all, next, position) => _objectSpread({}, all, {
        [String(position)]: next
      }), {}), colors[indexOfExistedItemInList].quantities, {
        [variantSizeIndex]: quantityPerSize || 0
      }),
      inReserveDetailsByColor: inReserveDetails && inReserveDetails.length ? [...(colors[indexOfExistedItemInList].inReserveDetailsByColor || []), ...inReserveDetails] : colors[indexOfExistedItemInList].inReserveDetailsByColor
    });
    (_colors$indexOfExiste = colors[indexOfExistedItemInList].inReserveDetailsByColor) === null || _colors$indexOfExiste === void 0 ? void 0 : _colors$indexOfExiste.sort((a, b) => new Date(a.reservedAt).getTime() - new Date(b.reservedAt).getTime());
    return colors;
  }, []);
}
const getQuantityByType = ({
  type = '',
  variant
}) => {
  const {
    quantity = 0,
    inProductionQuantity = 0,
    inReserveProductionQuantity = 0,
    inReserveStockQuantity = 0
  } = variant;
  switch (type) {
    case InReservePopupTypes.IN_STOCK_AVAILABLE:
      {
        return quantity - inReserveStockQuantity;
      }
    case InReservePopupTypes.IN_STOCK:
      {
        return inReserveStockQuantity;
      }
    case InReservePopupTypes.IN_PRODUCTION:
      {
        return inReserveProductionQuantity;
      }
    case InReservePopupTypes.IN_PRODUCTION_AVAILABLE:
      {
        return inProductionQuantity - inReserveProductionQuantity;
      }
    default:
      {
        return quantity;
      }
  }
};