function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import getBoxVariantSizeIndex from 'swag-common/business-logic/custom-store-product/get-box-variant-size-index.logic';
import isBox from '../../utils/custom-store-product/product-is-box.util';
export default function mapRelationalBoxData(product) {
  if (!isBox(product.productData.swagProductId)) {
    return product;
  }
  const {
    productData
  } = product;
  return _objectSpread({}, product, {
    productData: _objectSpread({}, productData, {
      boxContent: productData.boxContent.map(({
        quantity,
        product: productId
      }) => {
        const product = productData.boxContentProducts.find(({
          _id
        }) => _id.toString() === productId);
        return {
          quantity,
          product: _objectSpread({}, product, {
            sizeSettings: productData.boxContentProductsSizes.find(({
              _id
            }) => _id === product.sizeSettings),
            swagProductId: productData.boxContentSwagProducts.find(({
              _id
            }) => _id === product.swagProductId)
          })
        };
      })
    }),
    variants: product.variants.map(variant => {
      return _objectSpread({}, variant, {
        productData: _objectSpread({}, variant.productData, {
          sizeIndex: getBoxVariantSizeIndex(variant.productData.boxContentProducts),
          boxContent: variant.productData.boxContent.map(({
            quantity,
            product: productId
          }) => {
            const product = variant.productData.boxContentProducts.find(({
              _id
            }) => _id.toString() === productId);
            return {
              quantity,
              product: _objectSpread({}, product, {
                sizeSettings: variant.productData.boxContentProductsSizes.find(({
                  _id
                }) => _id === product.sizeSettings),
                swagProductId: variant.productData.boxContentSwagProducts.find(({
                  _id
                }) => _id === product.swagProductId)
              })
            };
          })
        })
      });
    })
  });
}