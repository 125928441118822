function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector, createStructuredSelector } from 'reselect';
import { getTotalInStock, getQuantityBySupplierInventoryLevels, getTotalStockLvls, isSuperSpeedProductionOption } from 'swag-common/utils/product/product.utils';
import { isBox } from 'swag-common/utils/order/item-is-box.util';
import { Price } from 'swag-client-common/utils/price';
import { MAX_QUANTITY_TO_BUY, FRONT, BRANDED, EMBELLISHMENT_METHODS } from 'swag-common/constants/product.constants';
import { getProductVariantImage } from 'swag-common/utils/product/get-product-variant-image.util';
import { BundleType, OrderStorageTypes } from 'swag-common/constants/main-swag.constants';
import getNotificationText from 'swag-common/utils/product/get-notification-text-for-product-quantity.util';
import { getBundleTypeBasedOnResellerSettings } from 'swag-client-common/utils/reseller/get-bundle-type-based-on-reseller-settings.util';
import { calculateSetupFeeValue, getBlankSample } from 'swag-common/business-logic';
import pickSkuForLineItem from 'swag-common/business-logic/order/pick-sku-for-line-item.logic';
import { getVariantWithNearestColor } from 'swag-common/utils/product/get-nearest-color-variant.util';
import { PRODUCT_BUILDER_MODES } from 'swag-client-common/utils/constants';
import { DesignUploadMode } from 'swag-common/interfaces';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { BoxEntities } from 'swag-common/constants/validation-messages.constants';
import prepareItemToShare from 'swag-common/utils/order/prepare-item-to-share.util';
import { mapSwagItemToCustomProductParams } from 'swag-common/utils/custom-store-product/map-swag-item-to-custom-product-params.util';
import { isProductionTimeForProductPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { UPLOAD_NAMES } from 'swag-client-common/constants';
import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { getLeftTime } from 'swag-common/utils/date-lib';
import { isProductPreorderAllowed } from 'swag-common/business-logic/custom-store-product/product-props-calculation.logic';
import { getVisibleProductVariants, getIsAlwaysInStock, getInStockVariants, getVariantInStockLevelNumber, getTotalCount, checkIsApparel, getSupplierStockProduct, getDifferenceBetweenOrderedQuantitiesAndStock, getProductOutOfStockMinExpiredDate, getProductInStockLevelsByProductIdAndVariantSku } from 'swag-client-common/utils/product.utils';
import { getProductInStockLevelsByVariantSku, getProductOutOfStockThreshold } from 'swag-common/utils/product/product.utils';
import { checkIsSharedItemExistsOnlyInBox } from 'swag-client-common/redux/cart/cart.utils';
import { isHighPrivilegesRole } from 'swag-common/utils/users/is-high-privileges-role.util';
import { checkIsCommonValuesForSharedItemsHaveChanged } from 'swag-client-common/utils/order-item.utils';
import { getRealSetupFee } from 'swag-common/utils/items/get-real-setup-fee';
import { getLogosUniqueColorsCount } from 'swag-common/utils/items/super-speed-color-limit.util';
import { getRelatedItems } from 'swag-common/business-logic/order/group-items-as-related-and-default.logic';
import { isPreBuiltLimitedCartOpenedByCustomerSelector } from 'swag-client-common/redux/cart/cart.selectors';
import { isAdminLoggedInModeSelector, isResellerInOrderCreationModeSelector } from 'swag-client-common/redux/user/user.selectors';
import { featureTogglesSelector } from 'swag-client-common/redux/feature-toggles/feature-toggles.selectors';
import { getItemProdByItem, getItemProd } from 'swag-common/utils/items/get-item-prod';
import { getSelectedProductSetting } from 'swag-common/utils/items/get-item-selected-product-settings.util';
import { isPreorderAllowedSelector } from '../custom-store/custom-store.selector';
import { isCustomStoreAdminSelector, userRoleSelector } from '../user/user.selectors';
import { debossedPopupRootSelector } from '../debossed-popup/debossed-popup.selectors';
import { isAnyLogoUploadedSelector, logosSelector, layersSelector, textsSelector, isLogoDimensionsManuallyModifiedSelector } from '../logo/logo.selectors';
import { selectedSplitColorsTooltip, unselectedSplitColorsTooltip } from '../related-product-builder-popup/product.constants';
import { getUpsellWidgetData } from '../../utils/get-upsell-widget-data.util';
import { getSetupFeeLabel } from '../cart/cart.utils';
import { isSuperSpeedActiveSelector, isAvailableSuperSpeedSelector } from '../../../common/redux/settings/settings.selectors';
import { presentUpsellWidgetForCartItem } from '../../utils/get-upsell-widget-data/present-upsell-widget-for-cart-item.util';
import { flatCartItemsSelector, isAddToBoxClickedSelector, isAddToCartClickedSelector, isBuyPrintedClickedSelector } from '../cart/cart.selectors.no-circular';
import { getPercentageDifference, getValidRelatedProducts, getBiggestScreenPrice, formatRelatedProductForQuantitiesBlock, getSharedItemsTotalQuantities, getSuperSpeedProductionTimeWithIndex, getSuperSpeedEmbellishmentMethodWithIndex, getProductionTimeIndex, getDefaultProductionTimeIdx, mapImagesForSpec, getShortestProductionTimeFromList, isStandardProductionTime } from './product.utils';
import { getItemPriceFromProduct } from './product-other.utils';
const DEFAULT_MINIMUM_COLORS_NUMBER = 1;
const BLANK_EMBELLISHMENT = 'blank';
const defaultLogos = {
  front: [{
    colors: {
      colorsToPrint: ['White'],
      colorsNumber: DEFAULT_MINIMUM_COLORS_NUMBER
    }
  }],
  back: [{
    colors: {
      colorsToPrint: [],
      colorsNumber: 0
    }
  }],
  left: [{
    colors: {
      colorsToPrint: [],
      colorsNumber: 0
    }
  }],
  right: [{
    colors: {
      colorsToPrint: [],
      colorsNumber: 0
    }
  }]
};
export const itemsFromSourceOrderSelector = state => {
  const {
    cart,
    product
  } = state;
  if (product.storageType === OrderStorageTypes.DRAFT) {
    return cart.draftItems;
  }
  return cart.items;
};
const productRootSelector = state => state.product;
export const productSelector = createSelector(productRootSelector, state => state && state.product);
export const isQuantityManuallyChangedSelector = createSelector(productRootSelector, state => state && state.isQuantityManuallyChanged);
export const isLoadingSelector = createSelector(productRootSelector, state => state && state.isLoading);
const productVariantsSelector = createSelector(productSelector, state => state && state.variants);
export const productLoadedSelector = createSelector(productRootSelector, state => state && state.productLoaded);
export const quantitiesSelector = createSelector(productRootSelector, state => state && state.quantities);
export const sizeLoadedSelector = createSelector(productRootSelector, state => state && state.sizeLoaded);
export const fontsLoadedSelector = state => !!state.logo.fonts.length;
export const productIdSelector = createSelector(productSelector, state => state && state._id);
export const productViewSelector = createSelector(productRootSelector, state => state && state.view);
export const serviceCodeSelector = createSelector(productRootSelector, state => state && state.serviceCode);
export const variantIndexSelector = createSelector(productRootSelector, state => state && state.variantIndex);
export const boxItemsIdSelector = createSelector(productRootSelector, state => state && state.boxItemsId);
export const sizeSubmissionIdSelector = createSelector(productRootSelector, state => state && state.sizeSubmissionId);
export const isFullDesignProductSelector = createSelector(productSelector, product => isProductFullDesign(product));
export const hasDonateWidgetSelector = createSelector(productSelector, product => product && product.hasDonateWidget);
export const isBoxProductSelector = state => Boolean(state.product.product) && state.product.product.designUploadMode === DesignUploadMode.box;
export const imagesWithoutImprintAreaSelector = createSelector(productSelector, state => state && state.imagesWithoutImprintArea);
export const sizeIndexSelector = createSelector(productRootSelector, product => product.sizeIndex || 0);
export const methodIndexSelector = createSelector(productRootSelector, state => state && state.methodIndex);
export const openedToolbarItemSelector = createSelector(productRootSelector, state => state && state.openedToolbarItem);
export const embellishmentMethodsSelector = createSelector(productSelector, state => state && (state.embellishmentMethods || []));
export const viewTypeSelector = createSelector(productRootSelector, state => state && state.view);
export const designNotesSelector = createSelector(productRootSelector, state => state && state.designNotes);
export const maxPrintColorsSelector = createSelector(productSelector, state => state && state.maxPrintColors);
export const printingProcessDescriptionSelector = createSelector(productSelector, state => state && state.printingProcessDescription);
export const discountSelector = createSelector(productRootSelector, state => state && state.discount);
export const productSettingsSelector = createSelector(productSelector, product => product.productSettings || []);
export const productSettingsFilteredSelector = createSelector(productSettingsSelector, isAvailableSuperSpeedSelector, (productSettings, isAvailableSuperSpeed) => {
  return productSettings.reduce((acc, setting) => {
    const filteredProdTime = !isAvailableSuperSpeed ? setting.productionTimeList.filter(s => !isSuperSpeedProductionOption(s)) : setting.productionTimeList;
    if (!filteredProdTime.length || !setting.embellishmentMethodId) {
      return acc;
    }
    return [...acc, _objectSpread({}, setting, {
      productionTimeList: filteredProdTime
    })];
  }, []);
});
export const settingsByEmbellishmentMethodsSelector = createSelector(productSettingsSelector, settings => {
  return settings.reduce((result, setting) => {
    result[setting.embellishmentMethodId || BLANK_EMBELLISHMENT] = setting;
    return result;
  }, {});
});
const nonBlankSettingsByEmbellishmentMethodsSelector = createSelector(productSettingsSelector, settings => {
  return settings.reduce((result, setting) => {
    if (setting.embellishmentMethodId) {
      result[setting.embellishmentMethodId] = setting;
    }
    return result;
  }, {});
});
export const currentEmbellishmentMethodSelector = createSelector(embellishmentMethodsSelector, methodIndexSelector, (embellishmentMethods, methodIndex) => {
  return embellishmentMethods[methodIndex] || null;
});
export const isCustomizedBlankSelector = createSelector(productRootSelector, state => state.isCustomizedBlank);
export const currentProductSettingsSelector = createSelector(settingsByEmbellishmentMethodsSelector, currentEmbellishmentMethodSelector, isCustomizedBlankSelector, (settings, currentMethod, isCustomizedBlank) => {
  const methodId = isCustomizedBlank ? BLANK_EMBELLISHMENT : (currentMethod === null || currentMethod === void 0 ? void 0 : currentMethod._id) || '';
  return settings[methodId] || null;
});
export const firstEmbellishmentMethodSettingsSelector = createSelector(settingsByEmbellishmentMethodsSelector, embellishmentMethodsSelector, (settings, embellishmentMethods) => {
  var _embellishmentMethods;
  const methodId = ((_embellishmentMethods = embellishmentMethods[0]) === null || _embellishmentMethods === void 0 ? void 0 : _embellishmentMethods._id) || '';
  return settings[methodId] || null;
});
export const currentProductionTimeListSelector = createSelector(currentProductSettingsSelector, firstEmbellishmentMethodSettingsSelector, (currentSettings, firstEmbellishmentMethodSettings) => (currentSettings === null || currentSettings === void 0 ? void 0 : currentSettings.productionTimeList) || (firstEmbellishmentMethodSettings === null || firstEmbellishmentMethodSettings === void 0 ? void 0 : firstEmbellishmentMethodSettings.productionTimeList) || []);
export const currentSuperSpeedSettingsSelector = createSelector(currentProductSettingsSelector, productSettingsFilteredSelector, currentSettings => (currentSettings === null || currentSettings === void 0 ? void 0 : currentSettings.superSpeed) || null);
export const superSpeedProductMaxPrintColorsSelector = createSelector(currentSuperSpeedSettingsSelector, superSpeed => {
  var _superSpeed$maxPrintC;
  return (_superSpeed$maxPrintC = superSpeed === null || superSpeed === void 0 ? void 0 : superSpeed.maxPrintColors) !== null && _superSpeed$maxPrintC !== void 0 ? _superSpeed$maxPrintC : 0;
});
export const actualMaxPrintColorsSelector = createSelector(maxPrintColorsSelector, superSpeedProductMaxPrintColorsSelector, isSuperSpeedActiveSelector, (mainProductMaxPrintColors, ssMaxPrintColors, isSuperSpeedActive) => {
  if (isSuperSpeedActive) {
    return ssMaxPrintColors;
  }
  return mainProductMaxPrintColors;
});
const superSpeedEmbellishmentMethodSelector = createSelector(productSettingsFilteredSelector, settings => {
  const superSpeedSetting = settings.find(setting => !!setting.superSpeed && setting.productionTimeList.some(isSuperSpeedProductionOption));
  return (superSpeedSetting === null || superSpeedSetting === void 0 ? void 0 : superSpeedSetting.embellishmentMethod) || null;
});
export const getSuperSpeedEmbellishmentMethodIdxSelector = createSelector(embellishmentMethodsSelector, superSpeedEmbellishmentMethodSelector, (embellishmentMethods, method) => getSuperSpeedEmbellishmentMethodWithIndex(embellishmentMethods, method === null || method === void 0 ? void 0 : method._id).index);
export const superSpeedProductionTimeIdxSelector = createSelector(settingsByEmbellishmentMethodsSelector, superSpeedEmbellishmentMethodSelector, (settings, method) => {
  var _settings$methodId;
  const methodId = (method === null || method === void 0 ? void 0 : method._id) || '';
  const productionTimeList = ((_settings$methodId = settings[methodId]) === null || _settings$methodId === void 0 ? void 0 : _settings$methodId.productionTimeList) || [];
  return getSuperSpeedProductionTimeWithIndex(productionTimeList).index;
});
export const selectedProductionTimeSelector = createSelector(productRootSelector, currentProductionTimeListSelector, (state, productionTimeList) => {
  var _state$selectedProduc;
  const selectedProductionTime = (_state$selectedProduc = state === null || state === void 0 ? void 0 : state.selectedProductionTime) !== null && _state$selectedProduc !== void 0 ? _state$selectedProduc : 0;
  return getProductionTimeIndex(productionTimeList, selectedProductionTime);
});
export const upsellDismissedStatesSelector = createSelector(productRootSelector, state => state && state.upsellDismissedStates);
export function getProductVariantsToChoose(product) {
  var _product$limitedEditi, _product$limitedEditi2;
  const outOfStockThreshold = getProductOutOfStockThreshold(product);
  const variants = getVisibleProductVariants(product);
  const isAlwaysInStock = getIsAlwaysInStock(product);
  const productInStockLevelsByVariantSku = getProductInStockLevelsByVariantSku(product);
  const totalInStock = getTotalInStock(productInStockLevelsByVariantSku);
  const variantIndexesBySku = getVariantIndexes(product.variants);
  const isLE = product === null || product === void 0 ? void 0 : (_product$limitedEditi = product.limitedEdition) === null || _product$limitedEditi === void 0 ? void 0 : _product$limitedEditi.isLimitedEdition;
  const isInfinity = product === null || product === void 0 ? void 0 : (_product$limitedEditi2 = product.limitedEdition) === null || _product$limitedEditi2 === void 0 ? void 0 : _product$limitedEditi2.isInfinity;
  const minQuant = product === null || product === void 0 ? void 0 : product.minQuant;
  const indexedVariants = variants.map(v => _objectSpread({}, v, {
    index: variantIndexesBySku[v.productSku]
  }));
  if (!outOfStockThreshold && outOfStockThreshold !== 0) {
    return indexedVariants;
  }
  if (isLE && isInfinity) {
    return indexedVariants;
  }
  if (isLE && !isInfinity && totalInStock < minQuant) {
    return [];
  }
  const inStockVariants = getInStockVariants({
    variants,
    isAlwaysInStock,
    productInStockLevelsByVariantSku,
    outOfStockThreshold,
    isLE
  });
  return inStockVariants.map(variant => _objectSpread({}, variant, {
    index: variantIndexesBySku[variant.productSku]
  }));
}
export const itemIdSelector = createSelector(productRootSelector, ({
  reorderId,
  itemId
}) => {
  return {
    reorderId,
    itemId
  };
});
export const editingItemSelector = createSelector(itemIdSelector, itemsFromSourceOrderSelector, ({
  itemId
}, items) => {
  const item = items.find(i => i._id === itemId);
  if (!item) {
    return null;
  }
  const isShared = Boolean(item.asRelatedItemId && item.boxItemsId);
  return _objectSpread({}, item, {
    isShared
  });
});
export const sizeSelector = createSelector(productRootSelector, state => state && state.size);
export const getProductVariantsToChooseSelector = (product, size, editedQuantities, item) => {
  const isShared = (item === null || item === void 0 ? void 0 : item.isShared) || false;
  const productVariants = getProductVariantsToChoose(product);
  if (isShared && productVariants.length > 0) {
    const quantities = Object.values(editedQuantities)[0];
    const stockLevelsByVariantSku = getProductInStockLevelsByVariantSku(product);
    return productVariants.reduce((all, variant) => {
      const variantStock = getSupplierStockProduct({
        size,
        product,
        quantities,
        stockLevelsByVariantSku,
        variant
      });
      const difference = getDifferenceBetweenOrderedQuantitiesAndStock({
        variantStock,
        quantities,
        size
      });
      if (difference.length) {
        return all;
      }
      return [...all, variant];
    }, []);
  }
  return productVariants;
};
export const productVariantsToChooseSelector = createSelector(productSelector, sizeSelector, quantitiesSelector, editingItemSelector, getProductVariantsToChooseSelector);
export function isProductOutOfStock(productVariantsToChoose, isFullDesign, product) {
  var _product$limitedEditi3, _product$limitedEditi4, _product$limitedEditi5;
  if ((_product$limitedEditi3 = product.limitedEdition) !== null && _product$limitedEditi3 !== void 0 && _product$limitedEditi3.isLimitedEdition && (_product$limitedEditi4 = product.limitedEdition) !== null && _product$limitedEditi4 !== void 0 && _product$limitedEditi4.expirationDate && getLeftTime(new Date((_product$limitedEditi5 = product.limitedEdition) === null || _product$limitedEditi5 === void 0 ? void 0 : _product$limitedEditi5.expirationDate)) <= 0) {
    return true;
  }
  const supplierInventoryLevels = product.supplierInventoryLevels || [];
  if (isFullDesign) {
    var _product$limitedEditi6, _supplierInventoryLev;
    if ((_product$limitedEditi6 = product.limitedEdition) !== null && _product$limitedEditi6 !== void 0 && _product$limitedEditi6.isLimitedEdition) {
      var _product$limitedEditi7;
      return (_product$limitedEditi7 = product.limitedEdition) !== null && _product$limitedEditi7 !== void 0 && _product$limitedEditi7.isInfinity ? false : getTotalStockLvls(product.supplierInventoryLevels) < product.minQuant;
    }
    const supplierInventoryLevel = supplierInventoryLevels.find(item => item.variantSku === product.num);
    return getVariantInStockLevelNumber((_supplierInventoryLev = supplierInventoryLevel === null || supplierInventoryLevel === void 0 ? void 0 : supplierInventoryLevel.inStock) !== null && _supplierInventoryLev !== void 0 ? _supplierInventoryLev : null) === 0;
  }
  return !productVariantsToChoose.length && !isFullDesign;
}
export const isOutOfStockSelector = createSelector(productVariantsToChooseSelector, isFullDesignProductSelector, productSelector, isProductOutOfStock);
export const outOfStockDateSelector = createSelector(isOutOfStockSelector, productSelector, (isOutOfStock, product) => {
  var _product$supplierInve;
  if (!isOutOfStock) {
    return null;
  }
  const supplierInventoryLevels = (_product$supplierInve = product === null || product === void 0 ? void 0 : product.supplierInventoryLevels) !== null && _product$supplierInve !== void 0 ? _product$supplierInve : [];
  return getProductOutOfStockMinExpiredDate(supplierInventoryLevels);
});
export const variantSelector = createSelector(productVariantsSelector, productLoadedSelector, variantIndexSelector, (variants, isProductLoaded, variantIndex) => {
  if (!isProductLoaded) {
    return null;
  }
  return variants[variantIndex];
});
export const variantColorSelector = createSelector(variantSelector, isFullDesignProductSelector, (variant, isFullDesignTypeProduct) => {
  if (isFullDesignTypeProduct) {
    return '#fff';
  }
  return variant.color;
});
export const isOpenRelatedProductsPopup = createSelector(productRootSelector, state => state && state.isOpenRelatedProductsPopup);
export const popupModeSelector = state => state.relatedProduct.popupMode;
export const showEmbellishmentMethodSectionSelector = createSelector(productSelector, isCustomizedBlankSelector, (product, isCustomizedBlank) => !isCustomizedBlank && product.embellishmentMethods && product.embellishmentMethods.length > 0);
export const selectedEmbellishmentMethodSelector = createSelector(currentEmbellishmentMethodSelector, showEmbellishmentMethodSectionSelector, (currentEmbellishmentMethod, useEmbellishmentMethod) => {
  if (useEmbellishmentMethod) {
    return currentEmbellishmentMethod;
  }
  return null;
});
const isEmbellishmentMethodSelectedSelector = createSelector(methodIndexSelector, index => index > -1);
const shortestDefaultProductionTimeSelector = createSelector(nonBlankSettingsByEmbellishmentMethodsSelector, productionTimeLists => {
  const defaultProductionTimes = Object.values(productionTimeLists).map(({
    productionTimeList
  }) => {
    const index = getDefaultProductionTimeIdx(productionTimeList);
    return productionTimeList[index];
  }).filter(Boolean);
  const standardProductionTimes = defaultProductionTimes.filter(isStandardProductionTime);
  const productionTimesToUse = standardProductionTimes.length ? standardProductionTimes : defaultProductionTimes;
  return getShortestProductionTimeFromList(productionTimesToUse);
});
export const currentProductionTimeSelector = createSelector(currentProductionTimeListSelector, selectedProductionTimeSelector, isEmbellishmentMethodSelectedSelector, (productionTimeList, productionTimeIndex, isEmbellishmentMethodSelected) => {
  return isEmbellishmentMethodSelected ? productionTimeList[productionTimeIndex] : undefined;
});
const chosenProductionTimeToDisplaySelector = createSelector(currentProductionTimeListSelector, isEmbellishmentMethodSelectedSelector, selectedProductionTimeSelector, shortestDefaultProductionTimeSelector, (productionTimeList, isEmbellishmentMethodSelected, productionTimeIndex, shortestProductionTime) => {
  if (!isEmbellishmentMethodSelected) {
    return shortestProductionTime;
  }
  return productionTimeList[productionTimeIndex] || productionTimeList[0];
});
export const isSuperSpeedCurrentProductionTimeSelector = createSelector(currentProductionTimeSelector, isSuperSpeedProductionOption);
export const minimumQuantitySelector = createSelector(productSelector, currentProductionTimeSelector, currentSuperSpeedSettingsSelector, (product, chosenProductionTime, superSpeedSettings) => {
  return isSuperSpeedProductionOption(chosenProductionTime) && superSpeedSettings ? superSpeedSettings.minQuant : product === null || product === void 0 ? void 0 : product.minQuant;
});
export const maximumQuantitySelector = createSelector(productSelector, currentProductionTimeSelector, currentSuperSpeedSettingsSelector, (product, chosenProductionTime, superSpeedSettings) => {
  return isSuperSpeedProductionOption(chosenProductionTime) && superSpeedSettings ? superSpeedSettings.maxQuant : MAX_QUANTITY_TO_BUY;
});
export const quantityIncrementSelector = createSelector(productSelector, state => state && state.quantIncrement);
export const swagSampleBoxSelector = createSelector(productRootSelector, state => state && state.swagSampleBox);
export const itemIdOnlySelector = createSelector(productRootSelector, state => (state === null || state === void 0 ? void 0 : state.itemId) || null);
export const orderModeSelector = createSelector(productRootSelector, state => state && state.mode);
export const selectedVariantsSelector = state => state.product.selectedVariants.sort((previousVariantIndex, variantIndex) => previousVariantIndex > variantIndex ? 1 : -1);
export const splitOrderModeSelector = createSelector(productRootSelector, state => state && state.splitOrderMode);
export const variantDependentPriceSelector = createSelector(productSelector, state => state && state.variantDependentPrice);
export const sizeDependentImagesSelector = createSelector(productSelector, state => state && state.sizeDependentImages);
export const showMoreImageModeSelector = createSelector(productRootSelector, state => state && state.showMoreImageMode);
export const blankSamplePriceSelector = createSelector(productSelector, isBoxProductSelector, (product, isBoxTypeProduct) => {
  if (isBoxTypeProduct) {
    if (isBoxTypeProduct) {
      return null;
    }
  }
  return product.samplePrice;
});
export const printedSamplePriceSelector = createSelector(productSelector, isBoxProductSelector, (product, isBoxTypeProduct) => {
  if (isBoxTypeProduct) {
    return null;
  }
  return product.printedSamplePrice;
});
export const additionalPrintLocationsSelector = state => state.product.product.additionalPrintLocations ? state.product.product.additionalPrintLocations.map(location => location) : [];
export const fullScreenModeSelector = createSelector(productRootSelector, state => state && state.fullScreenMode);
export const showColorSectionSelector = createSelector(productVariantsToChooseSelector, variants => variants.length > 0);
export const isProductPrintingModeSelector = createSelector(isCustomizedBlankSelector, isCustomizedBlank => !isCustomizedBlank);
export const isPrintedSampleAvailableSelector = createSelector(isProductPrintingModeSelector, printedSamplePriceSelector, (isProductPrintingMode, printedSamplePrice) => isProductPrintingMode && !!printedSamplePrice);
export const isBlankSampleAvailableSelector = createSelector(blankSamplePriceSelector, blankSamplePrice => !!blankSamplePrice);
export const hasRelatedProductsSelector = createSelector(productSelector, product => Boolean(product.associatedProducts && product.associatedProducts.length));
export const relatedProductsSelector = createSelector(productRootSelector, state => state ? state.associatedProducts : []);
export const asRelatedItemIdSelector = createSelector(productRootSelector, state => state && state.asRelatedItemId);
export const rootSelectedAssociatedProductsSelector = createSelector(productRootSelector, state => state ? state.selectedAssociatedProducts : []);
export const selectedAssociatedProductsSelector = createSelector(rootSelectedAssociatedProductsSelector, itemsFromSourceOrderSelector, (selectedAssociatedProducts, sourceOrderItems) => {
  return selectedAssociatedProducts.map(associatedProduct => {
    const {
        product,
        variantIndex
      } = associatedProduct,
      rest = _objectWithoutProperties(associatedProduct, ["product", "variantIndex"]);
    const variant = product.variants.find(({
      index
    }, key) => (index || key) === variantIndex);
    const imageSrc = getProductVariantImage({
      product: {
        variants: [variant]
      }
    }).url;
    const isSharedExistsInBoxOnly = checkIsSharedItemExistsOnlyInBox(null, associatedProduct, sourceOrderItems);
    return _objectSpread({}, rest, {
      product,
      variantIndex,
      currentVariantImageUrl: imageSrc,
      isShared: isSharedExistsInBoxOnly
    });
  });
});
export const selectedAssociatedProductsQuantitiesSelector = createSelector(selectedAssociatedProductsSelector, selectedAssociatedProducts => {
  return selectedAssociatedProducts.reduce((quantity, {
    selectedVariants,
    quantities,
    isShared
  }) => {
    const itemQuantity = selectedVariants.reduce((total, variantIndex) => {
      if (!quantities[variantIndex] || isShared) {
        return total;
      }
      const variantTotal = quantities[variantIndex].reduce((variantQuantity, q) => q + variantQuantity, 0);
      return total + variantTotal;
    }, 0);
    return quantity + itemQuantity;
  }, 0);
});
export const hasSelectedAssociatedProductsSelector = createSelector(selectedAssociatedProductsSelector, selectedProducts => selectedProducts.length > 0);
export const isThereNotSelectedRelatedProductSelector = state => state.product.product.associatedProducts.filter(id => !state.product.selectedAssociatedProducts.some(selectedP => selectedP.product._id === id)).length !== 0;
export const sharedItemsSelector = createSelector(itemsFromSourceOrderSelector, itemIdOnlySelector, productSelector, asRelatedItemIdSelector, (itemsFromSourceOrder, itemId, prod, asRelatedItemId) => {
  return itemsFromSourceOrder.filter(i => isSharedItem({
    _id: itemId,
    asRelatedItemId,
    prodId: prod._id
  }, [i]));
});
export const associatedProductsForQuantityBlockSelector = createSelector(selectedAssociatedProductsSelector, flatCartItemsSelector, (selectedAssociatedProducts, items) => {
  return selectedAssociatedProducts.map(item => {
    const sharedItemsRelatedToItem = items.filter(i => {
      const isShared = isSharedItem(i, [{
        _id: item.itemId,
        asRelatedItemId: item.asRelatedItemId,
        prodId: item.product._id
      }]);
      return isShared && item._id !== i._id;
    });
    const cartItem = items.find(cartItem => cartItem._id === item._id);
    const withoutSharedItems = sharedItemsRelatedToItem.length === 0 && item.boxItemsId && item.asRelatedItemId;
    const sharedItems = withoutSharedItems ? [cartItem] : sharedItemsRelatedToItem;
    return formatRelatedProductForQuantitiesBlock(item, items, sharedItems, withoutSharedItems);
  });
});
export const designAttachmentsSelector = createSelector(productRootSelector, state => state && state.attachments);
export const adminAttachmentsSelector = createSelector(productRootSelector, state => state && state.adminAttachments);
export const fontsSelector = state => state.logo.fonts;
export const productCategoriesIdSelector = createSelector(productSelector, state => state ? state.categories : []);
export const categoryNameSelector = createSelector(productSelector, state => state && state.categoryName);
export const sampleDeductionDiscountSelector = createSelector(productRootSelector, state => state && state.sampleDeductionDiscount);
export const readyForPromoSelector = createSelector(productRootSelector, state => state && state.readyForPromo);
export const isReorderSelector = createSelector(productRootSelector, state => state && state.isReorder);
export const setupFeeDeductionSelector = createSelector(productRootSelector, state => state && state.setupFeeDeductionDiscount);
export const isSamplePromoSelector = createSelector(productRootSelector, product => product && product.readyForPromo && product.sampleDeductionDiscount);
export const shouldDisplayUploadLogosSelector = createSelector(isSamplePromoSelector, isCustomizedBlankSelector, (isSamplePromo, isCustomizedBlank) => !isSamplePromo && !isCustomizedBlank);
export const shouldDisplayDesignNotesSelector = createSelector(productSelector, isCustomizedBlankSelector, (product, isCustomizedBlank) => product && product.locationPrintTitle && !isCustomizedBlank);
export const productOutOfStockThresholdSelector = createSelector(productSelector, product => getProductOutOfStockThreshold(product));
export const disableToolsButtonSelector = createSelector(isSamplePromoSelector, isPromo => isPromo);
export const productNameSelector = createSelector(productSelector, state => state && state.name);
export const modifiedProductNameSelector = createSelector(productRootSelector, state => state && state.modifiedProductName);
export const productSeoSlugSelector = createSelector(productSelector, state => state && state.seoSlug);
export const addEdgePriceToRelatedProduct = product => {
  const edgePrices = Price.calculateDefaultEdgePrice({
    product
  });
  return _objectSpread({}, product, edgePrices);
};
export const addEdgePriceToProduct = product => {
  const bundleType = getBundleTypeBasedOnResellerSettings();
  if (bundleType === BundleType.DYNAMIC) {
    return _objectSpread({}, product, {
      minPriceToUse: product.minPriceDynamic,
      maxPriceToUse: product.maxPriceDynamic
    });
  }
  return _objectSpread({}, product, {
    minPriceToUse: product.minPrice,
    maxPriceToUse: product.maxPrice
  });
};
export const suggestedProductsSelector = state => state.product.suggestedProducts.map(product => addEdgePriceToProduct(_objectSpread({}, product, {
  productSettings: []
})));
export const listOfNearestVariantsSelector = state => state.product.listOfNearestVariants;
const getMockedVariants = product => [{
  images: {
    front: [{
      rectangle: null,
      url: product.logo
    }]
  }
}];
export const suggestedProductsForDifferentDesignMode = createSelector(isFullDesignProductSelector, isBoxProductSelector, suggestedProductsSelector, (isFullDesignProduct, isBoxProduct, suggestedProducts) => {
  if (isFullDesignProduct || isBoxProduct) {
    return suggestedProducts.map(product => _objectSpread({}, product, {
      logos: {
        front: [{}]
      },
      variants: getMockedVariants(product)
    }));
  }
  return suggestedProducts.map(product => {
    if (product.variants.length) {
      return _objectSpread({}, product, {
        logos: {
          front: [{}]
        }
      });
    }
    return _objectSpread({}, product, {
      logos: {
        front: [{}]
      },
      variants: getMockedVariants(product)
    });
  });
});
export const variantsListForSuggestedProducts = createSelector(isFullDesignProductSelector, isBoxProductSelector, listOfNearestVariantsSelector, suggestedProductsSelector, (isFullDesignProduct, isBoxProduct, variantList, suggestedProducts) => {
  if (isFullDesignProduct || isBoxProduct) {
    return suggestedProducts.reduce((all, nextProduct) => _objectSpread({}, all, {
      [nextProduct._id]: 0
    }), {});
  }
  return variantList;
});
export const quantitySelector = createSelector(selectedVariantsSelector, quantitiesSelector, (variants, quantities) => variants.reduce((allVariantsQuantity, variantIndex) => {
  if (!quantities[variantIndex]) {
    return allVariantsQuantity;
  }
  const singleVariantQuantity = quantities[variantIndex].reduce((variantQuantity, q) => q + variantQuantity, 0);
  return allVariantsQuantity + singleVariantQuantity;
}, 0));
export const sharedProductQuantitiesWithoutRebuildItemSelector = createSelector(asRelatedItemIdSelector, editingItemSelector, itemsFromSourceOrderSelector, selectedAssociatedProductsSelector, (asRelatedItemId, item, items, relatedItems) => {
  if (!item) {
    return 0;
  }
  const sharedAndRelatedItems = items.filter(i => !i.boxItemsId && i.asRelatedItemId === asRelatedItemId && item._id !== i._id && !i.splitShippedType);
  if (!sharedAndRelatedItems.length) {
    return 0;
  }
  const relatedItemsIds = relatedItems.map(i => i.itemId).filter(Boolean);
  return sharedAndRelatedItems.reduce((total, i) => {
    if (!relatedItemsIds.includes(i._id) && !relatedItemsIds.includes(item._id)) {
      return total + i.quantity;
    }
    return total;
  }, 0);
});
export const quantityPerBoxSelector = createSelector(editingItemSelector, item => item ? item.quantityPerBox : 0);
export const isRebuildModeSelector = createSelector(orderModeSelector, mode => mode === PRODUCT_BUILDER_MODES.REBUILD_MODE);
export const quantityWithRelatedProductsSelector = createSelector(quantitySelector, selectedAssociatedProductsQuantitiesSelector, sharedProductQuantitiesWithoutRebuildItemSelector, (mainItemQuantities, selectedAssociatedProductsQuantities, sharedProductQuantities) => {
  return mainItemQuantities + selectedAssociatedProductsQuantities + sharedProductQuantities;
});
export const overallSharedProductQuantitiesWithoutRebuildItemSelector = createSelector(asRelatedItemIdSelector, editingItemSelector, itemsFromSourceOrderSelector, selectedAssociatedProductsQuantitiesSelector, (asRelatedItemId, item, items, selectedAssociatedProductsQuantities) => {
  if (!item) {
    return selectedAssociatedProductsQuantities || 0;
  }
  const sharedAndRelatedItems = items.filter(i => i.asRelatedItemId === asRelatedItemId && item._id !== i._id);
  if (!sharedAndRelatedItems.length) {
    return selectedAssociatedProductsQuantities || 0;
  }
  return sharedAndRelatedItems.reduce((total, item) => total + item.quantity, 0);
});
export const totalQuantityForRebuildItemAndOverallSharedProductQuantitiesSelector = createSelector(quantitySelector, overallSharedProductQuantitiesWithoutRebuildItemSelector, (mainItemQuantities, sharedProductsQuantities) => {
  return mainItemQuantities + sharedProductsQuantities;
});
export const singleProductTotalQuantityAcrossAllItemsSelector = createSelector(quantitySelector, editingItemSelector, itemsFromSourceOrderSelector, (quantity, item, sourceItems) => {
  if (!item) {
    return quantity;
  }
  const quantityAcrossItems = sourceItems.reduce((all, i) => {
    if (isSharedItem(i, [item])) {
      return i.quantity + all;
    }
    return all;
  }, 0);
  return quantityAcrossItems + quantity;
});
export const minimumQuantityErrorSelector = createSelector(minimumQuantitySelector, totalQuantityForRebuildItemAndOverallSharedProductQuantitiesSelector, (minQuant, totalQuantity) => minQuant > totalQuantity);
export const quantityIncrementErrorSelector = createSelector(quantityIncrementSelector, quantityWithRelatedProductsSelector, quantitiesSelector, (quantIncrement, totalQuantity, quantities) => Boolean(totalQuantity % quantIncrement !== 0 || Object.values(quantities).find(quantitiesPerVariant => (quantitiesPerVariant || []).find(q => q % quantIncrement !== 0))));
export const minimumQuantityPerProductErrorSelector = createSelector(quantitySelector, mainItemQuantities => mainItemQuantities < 1);
export const minimumQuantityPerRelatedProductErrorSelector = createSelector(selectedAssociatedProductsQuantitiesSelector, selectedAssociatedProductsSelector, (selectedAssociatedProductsQuantities, numberOfProducts) => selectedAssociatedProductsQuantities < numberOfProducts.filter(p => !p.isShared).length);
export const maximumQuantityErrorSelector = createSelector(quantityWithRelatedProductsSelector, maximumQuantitySelector, (totalQuantity, maxQuantity) => totalQuantity > maxQuantity);
export const sizeDependentPriceSelector = createSelector(productSelector, product => product.sizeDependentPrice);
export const hasSizeSelector = createSelector(productSelector, product => product.hasSize);
export const productIsAlwaysInStockSelector = createSelector(productSelector, product => product.isAlwaysInStock);
export const selectedVariantsDataSelector = createSelector(productLoadedSelector, selectedVariantsSelector, productVariantsSelector, quantitiesSelector, (productLoaded, selectedVariants, productVariants, quantities) => {
  if (!productLoaded || !productVariants.length) {
    return [];
  }
  return selectedVariants.map(variantIndex => {
    const productVariant = productVariants[variantIndex];
    const variantQuantities = quantities[variantIndex] || [0];
    return {
      variantIndex,
      color: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.color) || '',
      name: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.name) || '',
      quantities: variantQuantities,
      productSku: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.productSku) || '',
      isAlwaysInStockVariant: !!(productVariant !== null && productVariant !== void 0 && productVariant.isAlwaysInStockVariant),
      sizeSkus: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.sizeSkus) || {}
    };
  });
});
export const productSkuSelector = createSelector(productLoadedSelector, variantIndexSelector, productSelector, (isLoaded, variantIndex, product) => isLoaded ? pickSkuForLineItem(product, [{
  variantIndex
}]) : null);
const orderItemVariantsSelector = createSelector(productLoadedSelector, selectedVariantsSelector, quantitiesSelector, (productLoaded, selectedVariants, quantities) => {
  if (!productLoaded) {
    return [];
  }
  return selectedVariants.map(variantIndex => {
    const variantQuantities = quantities[variantIndex];
    return {
      variantIndex,
      quantities: variantQuantities
    };
  });
});
export const logosByLocationSelector = createSelector(layersSelector, logosSelector, variantSelector, (layers, logos, variant) => Object.keys(layers).reduce((resultingLogos, location) => {
  var _imgLocation$;
  const imgLocation = variant === null || variant === void 0 ? void 0 : variant.images[location];
  const name = imgLocation && ((_imgLocation$ = imgLocation[0]) === null || _imgLocation$ === void 0 ? void 0 : _imgLocation$.id) || location;
  const logosByLocation = layers[location].reduce((acc, id) => {
    if (logos[id]) {
      acc.push(_objectSpread({}, logos[id], {
        location: name
      }));
    }
    return acc;
  }, []);
  return _objectSpread({}, resultingLogos, {
    [location]: logosByLocation
  });
}, {}));
export const sharedRelatedOrderItemsSelector = createSelector(productRootSelector, logosByLocationSelector, editingItemSelector, itemsFromSourceOrderSelector, selectedAssociatedProductsSelector, totalQuantityForRebuildItemAndOverallSharedProductQuantitiesSelector, featureTogglesSelector, (newItem, newLogos, sourceItem, sourceOrderItems, relatedItems, totalQuantityForSharedItems, featureFlags) => {
  if (!sourceItem) {
    return [];
  }
  const relatedItemsIds = relatedItems.map(i => i._id || i.itemId).filter(Boolean);
  const sharedRelatedOrderItems = sourceOrderItems.filter(i => i.asRelatedItemId === sourceItem.asRelatedItemId && i._id !== sourceItem._id && !relatedItemsIds.includes(i._id)).map(i => {
    const updateItem = _objectSpread({}, i, {
      logos: newLogos,
      sizeInd: newItem.sizeIndex,
      prodTime: newItem.selectedProductionTime,
      blendedQuantity: totalQuantityForSharedItems
    });
    const price = getItemPriceFromProduct({
      orderItem: updateItem,
      product: updateItem.prod,
      blendedQuantity: updateItem.blendedQuantity,
      totalQuantityForSharedItems,
      discardMultiplier: true,
      featureFlags
    });
    updateItem.price = price;
    updateItem.blendedPrice = price;
    return updateItem;
  });
  return sharedRelatedOrderItems;
});
export const productWithSelectedSettingsSelector = createSelector(productSelector, selectedEmbellishmentMethodSelector, featureTogglesSelector, (product, selectedEmbellishment, featureFlags) => getItemProd(product, featureFlags, selectedEmbellishment === null || selectedEmbellishment === void 0 ? void 0 : selectedEmbellishment._id));
export const allSelectedProductSettingsSelector = createSelector(productSelector, selectedAssociatedProductsSelector, sharedRelatedOrderItemsSelector, selectedEmbellishmentMethodSelector, featureTogglesSelector, (product, related, shared, selectedEmbellishment, featureFlags) => {
  return [product, ...related.map(r => r.product), ...shared.map(r => r.prod)].map(product => getSelectedProductSetting(product, featureFlags, selectedEmbellishment === null || selectedEmbellishment === void 0 ? void 0 : selectedEmbellishment._id));
});
export const biggestScreenPriceSelector = createSelector(logosByLocationSelector, textsSelector, allSelectedProductSettingsSelector, getBiggestScreenPrice);
const getColorsAmount = logos => Object.keys(logos).reduce((colorsSum, location) => {
  const number = logos[location].reduce((colorsSumPerLocation, nextLogo) => nextLogo.colors.colorsNumber + colorsSumPerLocation, 0);
  return number + colorsSum;
}, 0);
const getRebuildItemData = (items, itemId) => {
  const itemToRebuild = items.find(item => item._id === itemId);
  if (!itemToRebuild) {
    return {};
  }
  const {
    totalCharges,
    isNyTaxFree,
    deliveryTime,
    vendor,
    inHandDate,
    status,
    splitShippedType
  } = itemToRebuild;
  return {
    totalCharges,
    isNyTaxFree,
    deliveryTime,
    vendor,
    inHandDate,
    status,
    splitShippedType
  };
};
const colorsForOrderItemSelector = createSelector(isFullDesignProductSelector, isAnyLogoUploadedSelector, logosByLocationSelector, (isFullDesignProduct, isDesignUploaded, logos) => {
  if (isFullDesignProduct) {
    return isDesignUploaded ? DEFAULT_MINIMUM_COLORS_NUMBER : 0;
  }
  return getColorsAmount(logos);
});
const uniqueColorsForOrderItemSelector = createSelector(isFullDesignProductSelector, isAnyLogoUploadedSelector, logosByLocationSelector, (isFullDesignProduct, isDesignUploaded, logos) => {
  if (isFullDesignProduct) {
    return isDesignUploaded ? DEFAULT_MINIMUM_COLORS_NUMBER : 0;
  }
  return getLogosUniqueColorsCount(logos);
});
export const superSpeedColorLimitationErrorSelector = createSelector(isSuperSpeedActiveSelector, superSpeedProductMaxPrintColorsSelector, uniqueColorsForOrderItemSelector, (isSuperSpeedActive, superSpeedProductMaxPrintColors, uniqueColorsForOrderItem) => {
  // 0 maxPrintColors means infinity max print colors
  if (isSuperSpeedActive && superSpeedProductMaxPrintColors === 0) {
    return false;
  }
  return isSuperSpeedActive && superSpeedProductMaxPrintColors < uniqueColorsForOrderItem;
});
const initialCreationDateSelector = createSelector(productRootSelector, state => state.initialCreationDate);
const sendosoSKUsSelector = createSelector(productRootSelector, state => state.sendosoSKUs);
const reorderItemChainIdSelector = createSelector(productRootSelector, state => state.reorderItemChainId);
export const relatedOrderItemsSelector = createSelector([selectedAssociatedProductsSelector, logosSelector, isAnyLogoUploadedSelector, textsSelector, designNotesSelector, discountSelector, orderModeSelector, serviceCodeSelector, designAttachmentsSelector, logosByLocationSelector, totalQuantityForRebuildItemAndOverallSharedProductQuantitiesSelector, biggestScreenPriceSelector, setupFeeDeductionSelector, adminAttachmentsSelector, singleProductTotalQuantityAcrossAllItemsSelector, initialCreationDateSelector, productSelector, currentEmbellishmentMethodSelector, featureTogglesSelector], (selectedAssociatedProducts, logos, isDesignUploaded, texts, designNotes, discount, mode, serviceCode, attachments, itemLogos, blendedQuantity, screenPrice, setupFeeDeductionDiscount, adminAttachments, singleProductTotalQuantityAcrossItems, initialCreationDate, mainItemProduct, embellishmentMethod, featureFlags) => {
  const colorsAmount = Object.keys(logos).reduce((colorsSum, logoId) => logos[logoId].colors.colorsNumber + colorsSum, 0);
  const relatedOrderItems = [];
  const commonOrderItemFields = {
    designNotes: designNotes || '',
    attachments,
    adminAttachments,
    discount,
    texts,
    blendedQuantity,
    setupFeeDeductionDiscount,
    initialCreationDate
  };
  selectedAssociatedProducts.forEach(product => {
    var _product$product$prod;
    const selectedEmbellishmentMethodExists = product.product.productSettings.find(setting => setting.embellishmentMethodId === (embellishmentMethod === null || embellishmentMethod === void 0 ? void 0 : embellishmentMethod._id));
    const embellishmentMethodToUse = selectedEmbellishmentMethodExists ? embellishmentMethod === null || embellishmentMethod === void 0 ? void 0 : embellishmentMethod._id : (_product$product$prod = product.product.productSettings.find(setting => setting.embellishmentMethodId)) === null || _product$product$prod === void 0 ? void 0 : _product$product$prod.embellishmentMethodId;
    const orderItem = {
      asRelatedItemId: product.asRelatedItemId,
      relatedMode: product.relatedMode,
      prodId: product.product._id,
      variants: product.selectedVariants.map(variantIndex => {
        const variantQuantities = product.quantities[variantIndex];
        return {
          variantIndex,
          quantities: variantQuantities
        };
      }),
      sizeInd: product.sizeIndex,
      quantity: Object.keys(product.quantities).reduce((q, index) => q + product.quantities[index].reduce((qu, i) => qu + i, 0), 0),
      itemSku: pickSkuForLineItem(product.product, [{
        variantIndex: product.variantIndex
      }]),
      embellishmentMethods: product.product.embellishmentMethods,
      boxItemsId: product.boxItemsId,
      prodTime: product.prodTime,
      colors: colorsAmount,
      prod: getItemProd(product.product, featureFlags, embellishmentMethodToUse)
    };
    if (product.product.variantDependentPrice) {
      orderItem.variantInd = product.selectedVariants[0];
    }
    if (isProductFullDesign(product.product)) {
      // always count design as 1 color print, unless there is no design uploaded
      orderItem.colors = isDesignUploaded ? DEFAULT_MINIMUM_COLORS_NUMBER : 0;
    }
    if (mode === PRODUCT_BUILDER_MODES.REBUILD_MODE) {
      const {
        _id,
        totalCharges,
        isNyTaxFree,
        deliveryTime,
        vendor,
        inHandDate,
        status
      } = product;
      _extends(orderItem, {
        _id,
        serviceCode,
        totalCharges,
        isNyTaxFree,
        deliveryTime,
        vendor,
        inHandDate,
        status
      });
    }
    const preparedLogos = Object.keys(itemLogos).reduce((result, logoKey) => {
      var _product$product$vari, _product$product$vari2, _mainItemProduct$vari, _mainItemProduct$vari2;
      const associatedProductImages = (_product$product$vari = (_product$product$vari2 = product.product.variants[0]) === null || _product$product$vari2 === void 0 ? void 0 : _product$product$vari2.images) !== null && _product$product$vari !== void 0 ? _product$product$vari : {};
      const mainProductImages = (_mainItemProduct$vari = (_mainItemProduct$vari2 = mainItemProduct.variants[0]) === null || _mainItemProduct$vari2 === void 0 ? void 0 : _mainItemProduct$vari2.images) !== null && _mainItemProduct$vari !== void 0 ? _mainItemProduct$vari : {};
      const associatedProductImagesKeys = Object.keys(associatedProductImages);
      if (associatedProductImagesKeys.length > 0 && !associatedProductImagesKeys.includes(logoKey)) {
        const mainLogoLocation = mainProductImages[logoKey][0].name;
        let associatedLogoLocation = mainLogoLocation;
        if (!associatedProductImages[associatedLogoLocation]) {
          associatedLogoLocation = associatedProductImagesKeys.find(key => associatedProductImages[key][0].name === mainLogoLocation);
        }
        if (associatedLogoLocation) {
          result[associatedLogoLocation] = [_objectSpread({}, itemLogos[logoKey][0], {
            location: associatedLogoLocation
          })];
        }
      } else {
        result[logoKey] = itemLogos[logoKey];
      }
      return result;
    }, {});
    const updatedOrderItem = _objectSpread({}, commonOrderItemFields, orderItem, {
      logos: preparedLogos
    });
    updatedOrderItem.price = getItemPriceFromProduct({
      orderItem: updatedOrderItem,
      product: product.product,
      discardMultiplier: true,
      featureFlags
    });
    updatedOrderItem.blendedPrice = getItemPriceFromProduct({
      orderItem: updatedOrderItem,
      product: product.product,
      blendedQuantity: blendedQuantity,
      screenPrice: screenPrice,
      totalQuantityForSharedItems: singleProductTotalQuantityAcrossItems,
      discardMultiplier: true,
      featureFlags
    });
    relatedOrderItems.push(updatedOrderItem);
  });
  return relatedOrderItems;
});
export const orderProductTypeItemSelector = createSelector([productWithSelectedSettingsSelector, productLoadedSelector, productIdSelector, orderItemVariantsSelector, productSkuSelector, sizeIndexSelector, quantitySelector, designNotesSelector, discountSelector, selectedProductionTimeSelector, itemIdSelector, serviceCodeSelector, itemsFromSourceOrderSelector, variantDependentPriceSelector, selectedEmbellishmentMethodSelector, designAttachmentsSelector, textsSelector, sampleDeductionDiscountSelector, readyForPromoSelector, logosByLocationSelector, totalQuantityForRebuildItemAndOverallSharedProductQuantitiesSelector, asRelatedItemIdSelector, biggestScreenPriceSelector, isRebuildModeSelector, isReorderSelector, setupFeeDeductionSelector, adminAttachmentsSelector, boxItemsIdSelector, colorsForOrderItemSelector, isCustomizedBlankSelector, upsellDismissedStatesSelector, quantityPerBoxSelector, editingItemSelector, singleProductTotalQuantityAcrossAllItemsSelector, initialCreationDateSelector, sendosoSKUsSelector, reorderItemChainIdSelector, featureTogglesSelector], (productWithSetting, isProductLoaded, prodId, selectedVariants, itemSku, sizeInd, quantity, designNotes, discount, selectedProductionTime, reorderInfo, serviceCode, cartItems, variantDependentPrice, embellishmentMethod, attachments, texts, sampleDeductionDiscount, readyForPromo, logos, blendedQuantity, asRelatedItemId, screenPrice, isRebuildMode, isReorder, setupFeeDeductionDiscount, adminAttachments, boxItemsId, colorsForOrderItem, isCustomizedBlank, upsellDismissedStates, quantityPerBox, editingItem, singleProductTotalQuantityAcrossItems, initialCreationDate, sendosoSKUs, reorderItemChainId, featureFlags) => {
  if (!isProductLoaded) {
    return {};
  }
  const orderItem = {
    texts,
    logos,
    colors: colorsForOrderItem,
    prodId,
    itemSku,
    sizeInd,
    quantity,
    isReorder,
    discount,
    attachments,
    blendedQuantity,
    asRelatedItemId,
    adminAttachments,
    setupFeeDeductionDiscount,
    boxItemsId,
    isMainItem: true,
    variants: selectedVariants.filter(variant => getTotalCount(variant.quantities) > 0),
    designNotes: designNotes || '',
    prodTime: selectedProductionTime,
    isCustomizedBlank,
    upsellDismissedStates,
    initialCreationDate,
    sendosoSKUs,
    prod: productWithSetting
  };
  if (embellishmentMethod) {
    orderItem.embellishmentMethod = embellishmentMethod;
  }
  if (variantDependentPrice) {
    orderItem.variantInd = selectedVariants[0] && selectedVariants[0].variantIndex;
  }
  if (isReorder) {
    const {
      reorderId,
      itemId
    } = reorderInfo;
    orderItem.itemId = itemId;
    orderItem.reorderId = reorderId;
    orderItem.reorderItemChainId = reorderItemChainId;
  } else {
    orderItem.itemId = null;
    orderItem.reorderId = null;
    orderItem.reorderItemChainId = null;
  }
  if (editingItem !== null && editingItem !== void 0 && editingItem.customProductId) {
    orderItem.customProductId = editingItem.customProductId;
  }
  if (editingItem !== null && editingItem !== void 0 && editingItem.originalInventoryId) {
    orderItem.originalInventoryId = editingItem.originalInventoryId;
  }
  if (Price.checkIfItemPriceCalculationPossible(orderItem)) {
    const calculatedPrice = getItemPriceFromProduct({
      orderItem,
      product: productWithSetting,
      blendedQuantity,
      screenPrice,
      totalQuantityForSharedItems: singleProductTotalQuantityAcrossItems,
      discardMultiplier: true,
      featureFlags
    });
    orderItem.price = orderItem.blendedPrice = calculatedPrice;
  }
  if (isRebuildMode) {
    const {
      itemId
    } = reorderInfo;
    const rebuildItemData = getRebuildItemData(cartItems, itemId);

    // tslint:disable-next-line: prefer-object-spread
    _extends(orderItem, _objectSpread({}, rebuildItemData, {
      serviceCode,
      _id: itemId
    }));
  }
  if (readyForPromo && sampleDeductionDiscount) {
    orderItem.sampleDeductionDiscount = sampleDeductionDiscount;
    orderItem.readyForPromo = true;
  }
  if (Number.isFinite(quantityPerBox) && quantityPerBox !== 0) {
    orderItem.quantityPerBox = quantityPerBox;
  }
  return orderItem;
});
export const setupFeeByColorsSelector = createSelector(productWithSelectedSettingsSelector, logosByLocationSelector, textsSelector, selectedProductionTimeSelector, featureTogglesSelector, (product, logos, texts, productionTime, featureToggles) => {
  if (!logos || !product) {
    return 0;
  }
  return calculateSetupFeeValue({
    product,
    logos,
    texts,
    isSample: false,
    prodTime: productionTime,
    featureFlags: featureToggles
  });
});
export const setupFeeForRelatedProductsSelector = createSelector(relatedOrderItemsSelector, relatedProductsSelector, featureTogglesSelector, (relatedItems, products, featureToggles) => {
  if (relatedItems.length === 0 || products.length === 0) {
    return 0;
  }
  const setupFees = relatedItems.map(item => {
    const product = products.find(p => p._id === item.prodId);
    return calculateSetupFeeValue({
      product,
      logos: item.logos,
      texts: item.texts,
      featureFlags: featureToggles
    });
  });
  return Math.max.apply(null, setupFees);
});
export const orderItemSelector = createSelector(orderProductTypeItemSelector, productTypeItem => productTypeItem);
export const setupFeeSelector = createSelector(setupFeeByColorsSelector, setupFeeForRelatedProductsSelector, setupFeeDeductionSelector, (setupFeeForRegularItem, setupFeeForRelatedItem, setupFeeDeductionDiscount) => {
  const setupFee = Math.max.apply(null, [setupFeeForRegularItem, setupFeeForRelatedItem]);
  const calculatedSetupFee = setupFee - setupFeeDeductionDiscount;
  return calculatedSetupFee > 0 ? calculatedSetupFee : 0;
});
export const realSetupFeeSelector = createSelector(setupFeeSelector, editingItemSelector, itemsFromSourceOrderSelector, orderItemSelector, relatedOrderItemsSelector, (setupFee, editItem, sourceItems, item, relatedItems) => {
  return editItem ? getRealSetupFee({
    setupFee,
    item: editItem,
    items: sourceItems
  }) : getRealSetupFee({
    setupFee,
    item,
    items: [item, ...relatedItems]
  });
});
export const embellishmentErrorSelector = createSelector(showEmbellishmentMethodSectionSelector, embellishmentMethodsSelector, selectedEmbellishmentMethodSelector, (isEmbellishmentMethodRequired, embellishmentMethods, selectedEmbellishmentMethod) => {
  return isEmbellishmentMethodRequired && (!selectedEmbellishmentMethod || !embellishmentMethods.includes(selectedEmbellishmentMethod));
});
export const isLogoUploadedAndPriceSetSelector = createSelector(orderItemSelector, orderItem => isFinite(orderItem.price));
const getSetupFeeForPriceBreaks = ({
  screenPrice = 0,
  addOneColor = false
} = {}) => addOneColor ? screenPrice * 2 : screenPrice;
export const getPricesWithQuantity = createSelector(productWithSelectedSettingsSelector, orderItemSelector, featureTogglesSelector, biggestScreenPriceSelector, (product, orderItem, featureFlags, screenPrice) => {
  var _orderItem$prod;
  const currentProductSettings = (orderItem === null || orderItem === void 0 ? void 0 : (_orderItem$prod = orderItem.prod) === null || _orderItem$prod === void 0 ? void 0 : _orderItem$prod.productSettings[0]) || product.productSettings[0];
  const printRunQuantities = (currentProductSettings === null || currentProductSettings === void 0 ? void 0 : currentProductSettings.printRunQuantities) || [];
  const {
    prodTime,
    discount,
    colors,
    logos,
    sizeInd,
    variantInd,
    defaultPrintingMethod
  } = orderItem;
  return printRunQuantities.map(quantity => {
    const hasColor = orderItem.colorsNumber;
    const item = {
      quantity,
      discount,
      prodTime,
      variantInd,
      logos: hasColor && logos || defaultLogos,
      colors: colors || DEFAULT_MINIMUM_COLORS_NUMBER,
      sizeInd,
      defaultPrintingMethod,
      prod: orderItem.prod
    };
    const price = getItemPriceFromProduct({
      orderItem: item,
      product,
      discardMultiplier: true,
      featureFlags
    });
    const setupFee = getSetupFeeForPriceBreaks(currentProductSettings);
    const cost = Price.getItemPriceWithoutSetUpFee({
      price,
      quantity,
      setupFee
    });
    return {
      quantity,
      cost
    };
  });
});
const calculatePriceBreaks = prices => {
  var _prices$;
  const maxPrice = (_prices$ = prices[0]) === null || _prices$ === void 0 ? void 0 : _prices$.cost;
  return prices.map(item => {
    const saving = getPercentageDifference(maxPrice, item.cost);
    return _objectSpread({}, item, {
      saving
    });
  });
};
export const priceBreaksSelector = createSelector(getPricesWithQuantity, selectedEmbellishmentMethodSelector, productSettingsFilteredSelector, (prices, embellishmentMethod, settings) => {
  const hasMoreThanOneItem = prices.length > 1;
  const someSettingHavePriceBreaks = settings.some(s => s.printRunQuantities.length > 1);
  const showPriceBreaksForEmbellishment = embellishmentMethod && hasMoreThanOneItem;
  return {
    prices: prices !== null && prices !== void 0 && prices.length ? calculatePriceBreaks(prices) : [],
    showPriceBreaks: someSettingHavePriceBreaks,
    showPriceBreaksForEmbellishment: showPriceBreaksForEmbellishment
  };
});
export const resetEditedItemSelector = createSelector(orderItemSelector, productSelector, featureTogglesSelector, (item, product, featureFlags) => {
  const resetedItem = prepareItemToShare(item);
  resetedItem.prod = getItemProdByItem(product, item, featureFlags);
  return resetedItem;
});
export const sampleItemSelector = (isPrinted = false) => createSelector(productSelector, currentProductionTimeListSelector, resetEditedItemSelector, variantIndexSelector, sizeDependentPriceSelector, sizeIndexSelector, sizeLoadedSelector, sizeSelector, hasSizeSelector, ({
  _id,
  samplePrice,
  printedSamplePrice,
  initialCreationDate
}, productionTimeList, orderItem, variantIndex, sizeDependent, sizeIndex, sizeLoaded, size, hasSize) => {
  const data = {
    orderItem,
    variantIndex,
    sizeDependent,
    sizeIndex,
    sizeLoaded,
    size,
    initialCreationDate,
    hasSize,
    prodId: _id,
    price: isPrinted ? printedSamplePrice : samplePrice,
    prodTime: getDefaultProductionTimeIdx(productionTimeList)
  };
  return getBlankSample(data, isPrinted);
});
const sampleCreator = (isPrinted = false) => createSelector(sampleItemSelector(isPrinted), sample => {
  return [sample];
});
export const swagBoxSampleItemSelector = createSelector(swagSampleBoxSelector, productSelector, logosSelector, layersSelector, ({
  _id,
  samplePrice,
  name
}) => {
  const orderItem = {
    prodId: _id,
    itemSku: name,
    quantity: 1,
    discount: false,
    isReorder: false,
    variantInd: 0,
    logos: {},
    texts: {},
    sizeInd: 0,
    isMainItem: true,
    prodTime: 0,
    colors: 1,
    blendedQuantity: null
  };
  return [getBlankSample({
    orderItem,
    variantIndex: 0,
    sizeDependent: false,
    hasSize: false,
    prodId: _id,
    price: samplePrice,
    sizeLoaded: true
  })];
});
export const printedSampleItemSelector = state => sampleCreator(true)(state);
export const blankSampleItemSelector = state => sampleCreator(false)(state);
export const showPriceBreaksSelector = createSelector(priceBreaksSelector, ({
  showPriceBreaks
}) => showPriceBreaks);
export const specsSelector = state => {
  const {
    product: {
      product: {
        specs,
        imagesForSpec,
        name
      }
    }
  } = state;
  const images = mapImagesForSpec(imagesForSpec, name);
  return {
    specs,
    images
  };
};
export const teeImagePopupSelector = state => {
  const {
    product: {
      product: {
        imagesForSpec,
        name
      }
    }
  } = state;
  return {
    images: mapImagesForSpec(imagesForSpec, name)
  };
};
export const isToolsEnabledSelector = state => {
  const {
    product: {
      view
    },
    logo: {
      layers,
      logos,
      texts
    }
  } = state;
  const isFullDesignProduct = isFullDesignProductSelector(state);
  const isBoxProduct = isBoxProductSelector(state);
  if (isFullDesignProduct || isBoxProduct) {
    return false;
  }
  if (!layers[view] || !layers[view].length) {
    return false;
  }
  const notOldFormatOfColor = !layers[view].some(id => !texts[id] && !!logos[id].oldFormat);
  const logoWasUploaded = !!layers[view].length;
  return logoWasUploaded && notOldFormatOfColor && !isFullDesignProduct;
};
export const isProductImageHoveredSelector = state => state.product.isProductImageHovered;
export const isToolbarForceHiddenSelector = state => state.product.isToolbarForceHidden;
export const isToolsPanelShownSelector = createSelector(isProductImageHoveredSelector, isAnyLogoUploadedSelector, isToolbarForceHiddenSelector, (isProductImageHovered, isAnyLogoUploaded, isToolbarForceHidden) => {
  if (isToolbarForceHidden) {
    return false;
  }
  return isProductImageHovered || isAnyLogoUploaded;
});
export const brandedLogoSelector = createSelector(productSelector, state => state === null || state === void 0 ? void 0 : state.logoBranded);
export const brandedImageSelector = createSelector(brandedLogoSelector, brandedLogo => {
  return Boolean(brandedLogo) && {
    id: BRANDED,
    url: brandedLogo,
    isActive: false,
    isImageWithImprintArea: false
  };
});
export const currentLogoSelector = state => {
  const {
    logo: {
      logos,
      selectedLogo
    }
  } = state;
  if (selectedLogo) {
    return _objectSpread({}, logos[selectedLogo], {
      logoId: selectedLogo
    });
  }
};

// get images from all selected variants.
export const selectedVariantsImagesSelector = createSelector(brandedImageSelector, sizeIndexSelector, viewTypeSelector, variantIndexSelector, productVariantsSelector, productLoadedSelector, sizeDependentImagesSelector, imagesWithoutImprintAreaSelector, isFullDesignProductSelector, isBoxProductSelector, (brandedImage, sizeIndex, view, variantIndex, variants, productLoaded, sizeDependentImages, imagesWithoutImprintArea, isFullDesignProduct, isBoxProduct) => {
  if (!productLoaded) {
    return [];
  }
  if (isFullDesignProduct || isBoxProduct) {
    const imagesArr = imagesWithoutImprintArea.map(images => _objectSpread({}, images, {
      variantIndex
    }));
    if (brandedImage) {
      imagesArr.push(brandedImage);
    }
    return imagesArr;
  } // return imagesWithoutImprintArea if there are no variants.

  const imageSizeIndex = sizeDependentImages ? sizeIndex : 0;
  const {
    images
  } = mergeImages(variants[variantIndex], imagesWithoutImprintArea, view, imageSizeIndex, variantIndex, brandedImage);
  return images.sort((a, b) => {
    if (a.isImageWithImprintArea === b.isImageWithImprintArea) {
      return 0;
    }
    return a.isImageWithImprintArea ? -1 : 1;
  });
});
export const activeProductImageSelector = createSelector(selectedVariantsImagesSelector, viewTypeSelector, variantIndexSelector, (currentImages, view, variantIndex) => {
  const activeProductImage = currentImages.find(image => {
    const isSameView = view === image.id;
    const isBrandedLogo = image.id === BRANDED;
    const isSameVariant = variantIndex === image.variantIndex;
    return isSameView && (isBrandedLogo || isSameVariant);
  });
  if (!activeProductImage) {
    return currentImages[0];
  }
  return activeProductImage;
});
export const hiddenActiveProductImage = (state, props) => {
  const {
    variants
  } = state.product.product;
  const {
    view
  } = props;
  const variant = variants.find(variant => !variant.isHidden && variant.images[view]);
  if (!variant) {
    return null;
  }
  const {
    images
  } = variant;
  return _objectSpread({}, images[view][0], {
    isImageWithImprintArea: true
  });
};
export const neighboringPreviewsSelector = createSelector(selectedVariantsImagesSelector, activeProductImageSelector, (allImages, currentImage) => {
  const currentIndex = allImages.findIndex(image => image.id === currentImage.id);
  const isLast = currentIndex === allImages.length - 1;
  const isFirst = currentIndex === 0;
  const next = isLast ? null : allImages[currentIndex + 1].id;
  const previous = isFirst ? null : allImages[currentIndex - 1].id;
  return {
    previous,
    next
  };
});
export const logoSizeSelector = createSelector(activeProductImageSelector, currentLogoSelector, (activeImage, currentLogo) => {
  if (!activeImage || !activeImage.isImageWithImprintArea || !activeImage.size) {
    return null;
  }
  const {
    size: {
      widthInch,
      heightInch
    }
  } = activeImage;
  if (!currentLogo) {
    return null;
  }
  const {
    rect: logoRect
  } = currentLogo;
  if (heightInch && widthInch && logoRect) {
    return {
      width: widthInch * logoRect.width / 100,
      height: heightInch * logoRect.height / 100
    };
  }
  return null;
});
export const hasPreviewsSelector = createSelector(selectedVariantsImagesSelector, selectedVariantsImages => selectedVariantsImages.length > 1);
export const productViewsSelector = createSelector(variantSelector, additionalPrintLocationsSelector, isFullDesignProductSelector, isBoxProductSelector, (variant, additionalPrintLocations, isFullDesignProduct, isBoxProduct) => {
  if (isFullDesignProduct || isBoxProduct) {
    return additionalPrintLocations.map(({
      id
    }) => id);
  }
  return variant && Object.keys(variant.images).filter(imageLocation => variant.images[imageLocation] && variant.images[imageLocation].length && variant.images[imageLocation][0].url);
});
const relatedProductsWithCorrectVariantsSelector = createSelector(relatedProductsSelector, products => products.map(product => _objectSpread({}, product, {
  variants: getProductVariantsToChoose(product)
})));
export const validRelatedProductsSelector = createSelector(productViewsSelector, relatedProductsWithCorrectVariantsSelector, (productViews, relatedProducts) => {
  return getValidRelatedProducts(productViews, relatedProducts);
});
export const hasValidRelatedProductsSelector = createSelector(validRelatedProductsSelector, userRoleSelector, (validRelatedProducts, userRole) => validRelatedProducts.filter(p => !(!isHighPrivilegesRole(userRole) && p.hidden)).length > 0);
export const relatedProductsPopupSelector = createSelector(validRelatedProductsSelector, selectedAssociatedProductsSelector, variantIndexSelector, productVariantsToChooseSelector, productViewSelector, userRoleSelector, (relatedProducts, selectedAssociatedProducts, variantIndex, variants, view, userRole) => {
  const variant = variants.find((item, index) => (item.index || index) === variantIndex);
  const color = variant && variant.color;
  if (!color) {
    return [];
  }
  return relatedProducts.filter(p => {
    if (!isHighPrivilegesRole(userRole) && p.hidden) {
      return false;
    }
    return !selectedAssociatedProducts.some(selectedP => selectedP.product._id === p._id) && p.variants.length > 0;
  }).map(product => {
    const relatedProductVariant = getVariantWithNearestColor(getProductVariantsToChoose(product), color);
    const fallback = product.variants[0];
    const source = relatedProductVariant || fallback;
    const imagesBySide = source.images[view] || source.images[FRONT];
    return addEdgePriceToRelatedProduct(_objectSpread({}, product, {
      logoThumbnail: imagesBySide && imagesBySide[0]
    }));
  });
});
export const isEditingBoxItemSelector = createSelector(isRebuildModeSelector, itemsFromSourceOrderSelector, boxItemsIdSelector, (isRebuildMode, cartItems, boxItemsId) => {
  const boxItem = cartItems.find(item => item.boxItemsId === boxItemsId);
  return isRebuildMode && boxItem && isBox(boxItem);
});
export const isEditingSharedProductInBoxSelector = createSelector(editingItemSelector, itemsFromSourceOrderSelector, (item, items) => {
  if (!item) {
    return false;
  }
  const {
    asRelatedItemId,
    boxItemsId,
    _id
  } = item;
  const sharedItems = items.filter(i => i.asRelatedItemId === asRelatedItemId && i._id !== _id);
  return boxItemsId && asRelatedItemId && sharedItems.length > 0;
});
export const isEditingStandaloneProductSelector = createSelector(editingItemSelector, itemsFromSourceOrderSelector, (item, items) => {
  if (!item) {
    return false;
  }
  return isSharedItem(item, items);
});
export const isLimitedEditionSelector = createSelector(productSelector, product => {
  var _product$limitedEditi8;
  return !!(product !== null && product !== void 0 && (_product$limitedEditi8 = product.limitedEdition) !== null && _product$limitedEditi8 !== void 0 && _product$limitedEditi8.isLimitedEdition);
});
export const shouldDisplayRelatedProductsBlockSelector = createSelector(hasValidRelatedProductsSelector, isAnyLogoUploadedSelector, isEditingBoxItemSelector, isEditingSharedProductInBoxSelector, isLimitedEditionSelector, isPreBuiltLimitedCartOpenedByCustomerSelector, isResellerInOrderCreationModeSelector, isEmbellishmentMethodSelectedSelector, featureTogglesSelector, (hasValidRelatedProducts, isAnyLogoUploaded, isEditingBoxItem, isSharedProductInBox, isLimitedEdition, isPreBuiltLimitedCart, isResellerInOrderCreationMode, isEmbellishmentMethodSelected, featureFlags) => hasValidRelatedProducts && isAnyLogoUploaded && !isEditingBoxItem && !isSharedProductInBox && !isLimitedEdition && !isPreBuiltLimitedCart && !isResellerInOrderCreationMode && (isEmbellishmentMethodSelected || !featureFlags.dynamicPricingOptionsForDecorationMethods));
export const shouldDisplayEmbellishmentErrorTooltipSelector = createSelector(embellishmentErrorSelector, isAddToCartClickedSelector, isAddToBoxClickedSelector, isBuyPrintedClickedSelector, (embellishmentError, isAddToCartClicked, isAddToBoxClicked, isBuyPrintedClicked) => embellishmentError && (isAddToCartClicked || isAddToBoxClicked || isBuyPrintedClicked));
export const shouldDisplayQuantityBlockSelector = createSelector(boxItemsIdSelector, boxItemsId => !boxItemsId);
export const isLEProductHasInfinityQuantity = createSelector(productSelector, ({
  limitedEdition
}) => limitedEdition === null || limitedEdition === void 0 ? void 0 : limitedEdition.isInfinity);
export const isEmbellishmentMethodOnlyOneAndDebossedSelector = createSelector(embellishmentMethodsSelector, embellishmentMethods => {
  var _embellishmentMethods2;
  return !!((embellishmentMethods === null || embellishmentMethods === void 0 ? void 0 : embellishmentMethods.length) === 1 && ((_embellishmentMethods2 = embellishmentMethods[0]) === null || _embellishmentMethods2 === void 0 ? void 0 : _embellishmentMethods2.name) === EMBELLISHMENT_METHODS.DEBOSSED);
});
export const hasDebossedPopupBeenShownForCurrentProductSelector = createSelector(debossedPopupRootSelector, productIdSelector, ({
  productId,
  hasDebossedPopupBeenShown
}, currentProductId) => productId === currentProductId && hasDebossedPopupBeenShown);
export const shouldShowDebossedPopupInCaseEmbellishmentMethodIsOnlyDebossedSelector = createSelector(hasDebossedPopupBeenShownForCurrentProductSelector, isEmbellishmentMethodOnlyOneAndDebossedSelector, (hasDebossedPopupBeenShownForCurrentProduct, isEmbellishmentMethodOnlyOneAndDebossed) => {
  return !hasDebossedPopupBeenShownForCurrentProduct && isEmbellishmentMethodOnlyOneAndDebossed;
});
export const realRelatedItemsSelector = createSelector(orderItemSelector, relatedOrderItemsSelector, itemsFromSourceOrderSelector, (item, relatedOrderItems, sourceItems) => {
  const realRelatedItems = getRelatedItems({
    item,
    items: sourceItems.filter(i => i._id !== item._id)
  });
  const realRelatedItemsIds = realRelatedItems.map(i => String(i._id));
  relatedOrderItems.forEach(relatedItem => {
    const relatedItemIndex = realRelatedItemsIds.indexOf(String(relatedItem._id));
    if (relatedItem._id && relatedItemIndex !== -1) {
      realRelatedItems[relatedItemIndex] = relatedItem;
    } else {
      realRelatedItems.push(relatedItem);
    }
  });
  return realRelatedItems;
});
export const totalOfAssociatedPriceSelector = createSelector(orderItemSelector, realRelatedItemsSelector, sharedItemsSelector, (item, realRelatedItems, sharedItems) => {
  if (!item || !realRelatedItems.length || sharedItems.length) {
    return null;
  }
  return Price.getTotalForRelatedProducts([item, ...realRelatedItems]);
});
const propsSelector = (state, props) => props;
export const singleViewDataSelector = createSelector(additionalPrintLocationsSelector, isFullDesignProductSelector, propsSelector, variantSelector, (additionalPrintLocations, isFullDesignProduct, {
  view
}, variant) => {
  if (isFullDesignProduct) {
    const printLocation = additionalPrintLocations.find(({
      id
    }) => id === view);
    if (printLocation) {
      return printLocation;
    }
  }
  let name = UPLOAD_NAMES[view];
  if (!name && view.includes(':')) {
    name = view.split(':')[0];
  } else {
    var _variant$images$view$;
    name = (variant === null || variant === void 0 ? void 0 : (_variant$images$view$ = variant.images[view][0]) === null || _variant$images$view$ === void 0 ? void 0 : _variant$images$view$.name) || view;
  }
  return {
    id: view,
    name
  };
});
export const shouldShowMaxPrintColorsMessageSelector = createSelector(actualMaxPrintColorsSelector, isFullDesignProductSelector, (maxPrintColors, isFullDesignProduct) => !!maxPrintColors && !isFullDesignProduct);
export const designUploadSelector = createSelector(actualMaxPrintColorsSelector, printingProcessDescriptionSelector, productViewsSelector, shouldShowMaxPrintColorsMessageSelector, (maxPrintColors, printingProcessDescription, productViews, shouldShowMaxPrintColorsMessage) => ({
  maxPrintColors,
  printingProcessDescription,
  productViews,
  shouldShowMaxPrintColorsMessage
}));
export const edgePriceSelector = createSelector(variantIndexSelector, productSelector, selectedProductionTimeSelector, sizeIndexSelector, currentProductSettingsSelector, featureTogglesSelector, (variantInd, product, prodTime, sizeInd, currentProductSettings, featureFlags) => {
  const productSettings = currentProductSettings ? [currentProductSettings] : product.productSettings;
  const {
    minPrice,
    maxPrice
  } = Price.calculateEdgePrice({
    product: _objectSpread({}, product, {
      productSettings
    }),
    orderItem: {
      sizeInd,
      prodTime,
      variantInd
    },
    featureFlags
  });
  return {
    minPrice,
    maxPrice
  };
});
export const priceSelector = createSelector(orderItemSelector, setupFeeDeductionSelector, sampleDeductionDiscountSelector, ({
  price
}, setupFeeDeductionDiscount = 0, sampleDeductionDiscount = 0) => {
  const result = price - setupFeeDeductionDiscount - sampleDeductionDiscount;
  return result >= 0 ? result : 0;
});
export const mixedItemsSelector = createSelector(orderItemSelector, relatedOrderItemsSelector, sharedRelatedOrderItemsSelector, (regularOrderItem, relatedOrderItems, sharedRelatedItems) => {
  if (!regularOrderItem) {
    return [];
  }
  const mixedItems = [regularOrderItem, ...relatedOrderItems, ...sharedRelatedItems];
  const {
    prodId,
    asRelatedItemId,
    boxItemsId
  } = regularOrderItem;
  const itemWithSameProduct = [...relatedOrderItems, ...sharedRelatedItems].filter(i => i.prodId === prodId);
  if (!itemWithSameProduct.length && asRelatedItemId && !boxItemsId) {
    return mixedItems.filter(i => !i.boxItemsId);
  }
  if (!itemWithSameProduct.length && asRelatedItemId && boxItemsId) {
    return mixedItems.filter(i => i.boxItemsId);
  }
  return mixedItems;
});
export const totalPriceSelector = createSelector(orderItemSelector, item => {
  var _item$price;
  const {
    setupFeeDeductionDiscount,
    blendedQuantity,
    quantity
  } = item !== null && item !== void 0 ? item : {};
  let price = (_item$price = item === null || item === void 0 ? void 0 : item.price) !== null && _item$price !== void 0 ? _item$price : 0;
  if (price) {
    const deductionAmount = Price.getItemPriceDeductionAmountFromSetupFeeDeduction({
      setupFeeDeductionDiscount,
      blendedQuantity,
      quantity
    });
    price -= deductionAmount;
  }
  return price;
});
const pageBlockSelector = state => state.common.isPageBlocked;
export const footerButtonLabelSelector = createSelector(isRebuildModeSelector, isEdit => isEdit ? 'Save Changes' : 'Add To Cart');
const quantityPerSizeEnabledSelector = createSelector(hasSizeSelector, sizeDependentPriceSelector, (hasSize, sizeDependent) => hasSize && !sizeDependent);
const labelForMultipleQuantityBlockSelector = createSelector(isBoxProductSelector, quantityWithRelatedProductsSelector, (isBox, quantity) => {
  switch (true) {
    case isBox && quantity > 1:
      return BoxEntities.BOX_MANY;
    case isBox:
      return BoxEntities.BOX_ONE;
    default:
      return 'printed';
  }
});
const sizeOptionsSelector = createSelector(productSelector, product => {
  var _product$productSize;
  return (_product$productSize = product.productSize) === null || _product$productSize === void 0 ? void 0 : _product$productSize.options;
});
export const productInStockLevelsByVariantSkuSelector = createSelector(productSelector, relatedProductsSelector, (product, fetchedAssociatedProducts) => {
  var _product$supplierInve2;
  const supplierInventoryLevels = (_product$supplierInve2 = product === null || product === void 0 ? void 0 : product.supplierInventoryLevels) !== null && _product$supplierInve2 !== void 0 ? _product$supplierInve2 : [];
  fetchedAssociatedProducts.forEach(product => {
    supplierInventoryLevels.push(...(product.supplierInventoryLevels || []));
  });
  return getProductInStockLevelsByVariantSku({
    supplierInventoryLevels
  });
});
export const productQuantityBySupplierInventoryLevelsSelector = createSelector(productSelector, ({
  variants,
  supplierInventoryLevels
}) => {
  return getQuantityBySupplierInventoryLevels(variants, supplierInventoryLevels);
});
export const selectedVariantsInStockLevelsSelector = createSelector(productVariantsSelector, selectedVariantsSelector, productInStockLevelsByVariantSkuSelector, sizeOptionsSelector, productIsAlwaysInStockSelector, (variants, selectedVariants, stockLevelsByVariantSku, sizeSizeOptions, productIsAlwaysInStock) => {
  if (!sizeSizeOptions) {
    return [];
  }
  return selectedVariants.map(i => {
    const variant = variants[i];
    if (!variant) {
      return;
    }
    const {
      sizeSkus,
      isAlwaysInStockVariant
    } = variant;
    if (!sizeSkus || isAlwaysInStockVariant || productIsAlwaysInStock) {
      return;
    }
    return sizeSizeOptions.map(size => {
      const sku = sizeSkus[size];
      return stockLevelsByVariantSku[sku];
    });
  });
});
export const associatedProductsSelectedVariantsInStockLevelsSelector = createSelector(associatedProductsForQuantityBlockSelector, productInStockLevelsByVariantSkuSelector, (associatedProductsData, stockLevelsByVariantSku) => {
  return associatedProductsData.map(item => {
    return item.variants.map(variant => {
      const {
        sizeSkus,
        isAlwaysInStockVariant
      } = variant;
      if (!sizeSkus || isAlwaysInStockVariant || item.product.isAlwaysInStock) {
        return;
      }
      return item.product.productSize.options.map(size => {
        const sku = sizeSkus[size];
        return stockLevelsByVariantSku[sku];
      });
    });
  });
});
export const infoForSharedItemsSelector = createSelector(flatCartItemsSelector, selectedVariantsDataSelector, productSelector, sharedItemsSelector, editingItemSelector, getSharedItemsTotalQuantities);
export const sharedQuantitiesLackBasedOnSupplierStockSelector = createSelector(productSelector, sharedItemsSelector, sizeSelector, quantitiesSelector, itemIdSelector, asRelatedItemIdSelector, editingItemSelector, itemsFromSourceOrderSelector, selectedAssociatedProductsSelector, (product, sharedItems, size, editedQuantities, {
  itemId
}, asRelatedItemId, item, items, selectedAssociatedProducts) => {
  const stockLevelsByVariantSku = getProductInStockLevelsByVariantSku(product);
  const selectedItemEditedQuantities = Object.keys(editedQuantities).reduce((all, next) => [...all, {
    variantIndex: Number(next),
    quantities: editedQuantities[next]
  }], []);
  if (!sharedItems.length) {
    return {};
  }
  const sharedAndRelatedItems = items.filter(i => i.boxItemsId === (item === null || item === void 0 ? void 0 : item.boxItemsId) && itemId !== i._id);
  const uniqMapItems = [...sharedItems, ...sharedAndRelatedItems].reduce((all, i) => {
    const selectedAssociatedProduct = selectedAssociatedProducts.find(p => p._id === i._id);
    const itemToUse = selectedAssociatedProduct || i;
    if (selectedAssociatedProduct) {
      itemToUse.variants = i.variants.map(v => {
        const {
          quantities
        } = selectedAssociatedProduct;
        const quantityForCurrentIndex = quantities[v.variantIndex];
        if (quantityForCurrentIndex) {
          return _objectSpread({}, v, {
            quantities: quantityForCurrentIndex
          });
        }
        return v;
      });
    }
    return _objectSpread({}, all, {
      [i._id]: itemToUse
    });
  }, {});
  const itemsToProcess = Object.values(uniqMapItems);
  const itemPayload = itemId ? {
    _id: itemId,
    variants: selectedItemEditedQuantities,
    prod: product
  } : null;
  if (itemPayload) {
    itemsToProcess.unshift(itemPayload);
  }
  return itemsToProcess.reduce((all, {
    variants,
    _id,
    prod
  }) => {
    const itemVariantsMap = {};
    const variantsToProcess = itemId === _id ? selectedItemEditedQuantities : variants;
    const {
      variants: productVariants,
      name
    } = prod;
    variantsToProcess.forEach(itemVariant => {
      const {
        quantities,
        variantIndex
      } = itemVariant;
      const variantKey = `${prod._id}:${variantIndex}`;
      const productVariant = productVariants[variantIndex];
      const variantStock = getSupplierStockProduct({
        size,
        product: prod,
        quantities,
        stockLevelsByVariantSku,
        variant: productVariant
      });
      const updatedQuantities = all[variantKey] ? all[variantKey].quantities.reduce((all, quantity, index) => {
        all[index] = quantities[index] + quantity;
        return all;
      }, []) : quantities;
      itemVariantsMap[variantKey] = {
        productId: prod._id,
        name: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.name) || name,
        variant: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.color) || name,
        quantities: updatedQuantities,
        difference: getDifferenceBetweenOrderedQuantitiesAndStock({
          variantStock,
          quantities: updatedQuantities,
          size
        })
      };
    });
    return _objectSpread({}, all, itemVariantsMap);
  }, {});
});
const sharedQuantitiesLackBasedOnSupplierStockWarningSelector = createSelector(sharedQuantitiesLackBasedOnSupplierStockSelector, sharedQuantitiesLack => {
  if (!sharedQuantitiesLack) {
    return null;
  }
  const lackQuantities = Object.values(sharedQuantitiesLack);
  if (!lackQuantities.length) {
    return null;
  }
  const quantities = lackQuantities.reduce((all, variant) => {
    if (!(variant !== null && variant !== void 0 && variant.difference.length)) {
      return all;
    }
    const difference = variant.difference.filter(Boolean).join();
    return _objectSpread({}, all, {
      [`${variant.productId}:${variant.name}`]: `Overall quantity of ${variant.name} product in your cart is ${difference} available. Please lower your quantities to be able to proceed.`
    });
  }, {});
  return Object.keys(quantities).length ? quantities : null;
});
const designUploadModeSelector = createSelector(productSelector, product => product.designUploadMode);
export const isApparelSelector = createSelector(designUploadModeSelector, hasSizeSelector, sizeDependentPriceSelector, (designUploadMode, hasSize, sizeDependentPrice) => {
  return checkIsApparel({
    designUploadMode,
    hasSize,
    sizeDependentPrice
  });
});
export const multipleQuantityBlockSelector = createSelector([priceBreaksSelector, productNameSelector, selectedVariantsDataSelector, minimumQuantitySelector, maximumQuantitySelector, sizeSelector, sizeIndexSelector, sizeLoadedSelector, quantityWithRelatedProductsSelector, quantityPerSizeEnabledSelector, minimumQuantityErrorSelector, hasSelectedAssociatedProductsSelector, associatedProductsForQuantityBlockSelector, labelForMultipleQuantityBlockSelector, quantityIncrementSelector, productInStockLevelsByVariantSkuSelector, productIsAlwaysInStockSelector, productOutOfStockThresholdSelector, infoForSharedItemsSelector, totalQuantityForRebuildItemAndOverallSharedProductQuantitiesSelector, isEditingStandaloneProductSelector, sharedQuantitiesLackBasedOnSupplierStockWarningSelector, editingItemSelector, productIdSelector, isApparelSelector, sizeOptionsSelector, splitOrderModeSelector, isPreBuiltLimitedCartOpenedByCustomerSelector, selectedEmbellishmentMethodSelector, featureTogglesSelector], ({
  showPriceBreaks,
  showPriceBreaksForEmbellishment
}, productName, variants, minQuant, maxQuant, size, sizeIndex, sizeLoaded, totalQuantity, quantityPerSizeEnabled, minimumQuantityError, hasSelectedAssociatedProducts, associatedProducts, label, quantIncrement, stockLevelsByVariantSku, productIsAlwaysInStock, productOutOfStockThreshold, sharedItemInfo, quantityWithSharedItems, isEditingStandaloneProduct, sharedQuantitiesLack, editingItem, productId, isApparel, sizeOptions, splitOrderMode, isPreBuiltLimitedCart, selectedEmbellishmentMethod, featureToggles) => {
  if (!sizeLoaded) {
    return {
      variants: []
    };
  }
  const errorMessage = getNotificationText({
    variants,
    quantIncrement,
    productIsAlwaysInStock,
    stockLevelsByVariantSku,
    minQuantity: minQuant,
    maxQuantity: maxQuant,
    isShared: isEditingStandaloneProduct,
    quantityForCurrentItem: quantityWithSharedItems
  });
  const isDynamicPricesAvailable = featureToggles.dynamicPricingOptionsForDecorationMethods;
  return {
    size,
    label,
    variants,
    productId,
    minQuant,
    sizeIndex,
    productName,
    errorMessage,
    totalQuantity,
    sharedItemInfo,
    quantIncrement,
    showPriceBreaks,
    disablePriceBreaks: isDynamicPricesAvailable ? !selectedEmbellishmentMethod || !showPriceBreaksForEmbellishment : false,
    disablePriceBreaksMessage: !selectedEmbellishmentMethod ? 'Please select decoration method to be able to view price breaks.' : 'This decoration method does not have price breaks',
    associatedProducts,
    minimumQuantityError,
    quantityPerSizeEnabled,
    productIsAlwaysInStock,
    stockLevelsByVariantSku,
    hasSelectedAssociatedProducts,
    sharedQuantitiesLack,
    splitOrderMode,
    // @ SWAG-1662 temp remove notifications for boxes
    boxNotifications: [],
    outOfStockThreshold: productOutOfStockThreshold,
    isSharedItemEditing: (editingItem === null || editingItem === void 0 ? void 0 : editingItem.isShared) || false,
    skipStockLevelByVariantValidation: Boolean(sharedQuantitiesLack),
    isCollectSizesAvailable: isApparel && sizeOptions && sizeOptions.length > 1 && !isPreBuiltLimitedCart
  };
});
export const sharedQuantitiesLackErrorSelector = createSelector(multipleQuantityBlockSelector, sharedQuantitiesLackBasedOnSupplierStockWarningSelector, ({
  errorMessage
}, sharedQuantitiesLack) => {
  return sharedQuantitiesLack ? errorMessage : null;
});
const quantityMoreThanSupplierHasErrorSelector = createSelector(hasSizeSelector, selectedVariantsDataSelector, productInStockLevelsByVariantSkuSelector, productIsAlwaysInStockSelector, sizeOptionsSelector, associatedProductsForQuantityBlockSelector, sharedQuantitiesLackErrorSelector, (hasSize, variants, stockLevelsByVariantSku, productIsAlwaysInStock, sizeSizeOptions, associatedProductsForQuantityBlock, sharedQuantitiesLackError) => {
  if (sharedQuantitiesLackError) {
    return null;
  }
  const isMainProductValid = variants.every(variant => {
    if (hasSize) {
      return sizeSizeOptions.every((sizeLabel, index) => {
        var _variant$quantities, _variant$sizeSkus;
        return !getNotificationText({
          stockLevelsByVariantSku,
          productIsAlwaysInStock: productIsAlwaysInStock,
          quantityForCurrentItem: (_variant$quantities = variant.quantities) === null || _variant$quantities === void 0 ? void 0 : _variant$quantities[index],
          variants: [_objectSpread({}, variant, {
            productSku: (_variant$sizeSkus = variant.sizeSkus) === null || _variant$sizeSkus === void 0 ? void 0 : _variant$sizeSkus[sizeLabel]
          })]
        });
      });
    } else {
      return !getNotificationText({
        quantityForCurrentItem: getTotalCount(variant.quantities),
        variants: [variant],
        stockLevelsByVariantSku,
        productIsAlwaysInStock
      });
    }
  });
  const isAllAssociatedProductsValid = associatedProductsForQuantityBlock.every(item => {
    return item.variants.every(variant => {
      if (item.product.hasSize) {
        return item.product.productSize.options.every((sizeLabel, index) => {
          var _variant$sizeSkus2;
          return !getNotificationText({
            stockLevelsByVariantSku,
            productIsAlwaysInStock: item.product.isAlwaysInStock,
            quantityForCurrentItem: variant.quantities[index],
            variants: [_objectSpread({}, variant, {
              productSku: (_variant$sizeSkus2 = variant.sizeSkus) === null || _variant$sizeSkus2 === void 0 ? void 0 : _variant$sizeSkus2[sizeLabel]
            })]
          });
        });
      } else {
        return !getNotificationText({
          quantityForCurrentItem: getTotalCount(variant.quantities),
          variants: [variant],
          stockLevelsByVariantSku,
          productIsAlwaysInStock: item.product.isAlwaysInStock
        });
      }
    });
  });
  return isMainProductValid && isAllAssociatedProductsValid ? false : "The quantity of products you've selected is higher than available for purchase. Please adjust the quantity to proceed.";
});
const isValidProductToSaveSelector = createSelector(maximumQuantityErrorSelector, minimumQuantityErrorSelector, minimumQuantityPerProductErrorSelector, minimumQuantityPerRelatedProductErrorSelector, isLogoUploadedAndPriceSetSelector, embellishmentErrorSelector, quantityIncrementErrorSelector, quantityMoreThanSupplierHasErrorSelector, (maximumQuantityError, minimumQuantityError, minimumQuantityPerProductError, minimumQuantityPerRelatedError, isLogoUploadedAndPriceSet, embellishmentError, quantityIncrementError, quantityMoreThanSupplierHasError) => isLogoUploadedAndPriceSet && !maximumQuantityError && !minimumQuantityError && !minimumQuantityPerProductError && !minimumQuantityPerRelatedError && !embellishmentError && !quantityIncrementError && !quantityMoreThanSupplierHasError);
export const isCommonValuesForSharedItemsWereEditedSelector = createSelector(isValidProductToSaveSelector, isRebuildModeSelector, sharedItemsSelector, variantDependentPriceSelector, variantIndexSelector, selectedEmbellishmentMethodSelector, isCustomizedBlankSelector, sizeDependentPriceSelector, sizeIndexSelector, selectedProductionTimeSelector, logosByLocationSelector, isLogoDimensionsManuallyModifiedSelector, (isValidProductToSave, isEdit, sharedItems, variantDependentPrice, variantIndex, selectedEmbellishmentMethod, isCustomizedBlank, sizeDependentPrice, sizeIndex, selectedProductionTime, logos, isLogoDimensionsModified) => {
  if (!isValidProductToSave || !isEdit || !sharedItems.length) {
    return false;
  }
  const anySharedItem = sharedItems[0];
  return checkIsCommonValuesForSharedItemsHaveChanged({
    embellishmentMethod: selectedEmbellishmentMethod === null || selectedEmbellishmentMethod === void 0 ? void 0 : selectedEmbellishmentMethod.name,
    isCustomizedBlank,
    prodTime: selectedProductionTime,
    variantInd: variantIndex,
    sizeInd: sizeIndex,
    logos,
    prod: {
      variantDependentPrice,
      sizeDependentPrice
    }
  }, anySharedItem, isLogoDimensionsModified);
});
const anyWarningOrConfirmationNeededSelector = createSelector(isValidProductToSaveSelector, isCommonValuesForSharedItemsWereEditedSelector, (isValidProductToSave, isCommonValuesForSharedItemsWereEdited) => {
  return !isValidProductToSave || isCommonValuesForSharedItemsWereEdited;
});
const addToCartEnabledSelector = createSelector(anyWarningOrConfirmationNeededSelector, anyWarningOrConfirmationNeeded => !anyWarningOrConfirmationNeeded);
function getVariantIndexes(variants) {
  return variants.reduce((acc, item, index) => {
    acc[item.productSku] = index;
    return acc;
  }, {});
}
export const splitColorsTooltipSelector = createSelector(splitOrderModeSelector, isSplitOrderModeOn => {
  return isSplitOrderModeOn ? selectedSplitColorsTooltip : unselectedSplitColorsTooltip;
});
export const chooseColorVariantsSelector = createSelector(productVariantsToChooseSelector, selectedVariantsSelector, (productVariants, selectedVariants) => {
  return productVariants.map(_ref => {
    let {
        index
      } = _ref,
      rest = _objectWithoutProperties(_ref, ["index"]);
    return _objectSpread({}, rest, {
      index,
      isActive: selectedVariants.includes(index)
    });
  });
});
export const isSplitOrderModeAvailableSelector = createSelector(variantDependentPriceSelector, isEditingBoxItemSelector, chooseColorVariantsSelector, isEditingSharedProductInBoxSelector, (variantDependentPrice, isEditingBoxItem, variants, isSharedProductInBox) => {
  return !isEditingBoxItem && !variantDependentPrice && !isSharedProductInBox && variants.length > 1;
});
export const chooseColorSelector = createSelector(chooseColorVariantsSelector, splitOrderModeSelector, splitColorsTooltipSelector, isSplitOrderModeAvailableSelector, selectedVariantsSelector, isLimitedEditionSelector, (variants, isSplitOrderModeOn, splitColorsTooltip, isSplitOrderModeAvailable, selectedVariants, isLimitedEdition) => ({
  colors: variants,
  selectedVariants,
  isSplitOrderModeOn,
  splitColorsTooltip,
  isSplitOrderModeAvailable,
  isLimitedEdition
}));

// merge images with imprint area with images without imprint area
export const mergeImages = (variant, imagesWithoutImprintArea, view, sizeIndex, variantIndex, brandedImage) => {
  const images = variant && variant.images || [];
  const imagesWithoutImprintAreaType = imagesWithoutImprintArea.filter(image => image).map(image => _objectSpread({}, image, {
    isImageWithImprintArea: false,
    variantIndex
  }));
  const imagesWithImprintAreaType = Object.keys(images).map(viewName => {
    if (!images[viewName]) {
      return null;
    }
    return _objectSpread({}, images[viewName][sizeIndex], {
      isImageWithImprintArea: true,
      variantIndex,
      id: viewName
    });
  });
  const allImages = [...imagesWithImprintAreaType, ...imagesWithoutImprintAreaType].filter(image => image !== null && image.url !== '');
  if (brandedImage) {
    allImages.push(brandedImage);
  }
  return {
    images: allImages
  };
};
export const designNotesEditorSelector = createSelector(designNotesSelector, designAttachmentsSelector, isLoadingSelector, isLimitedEditionSelector, (notes, attachments, isDisabled, isLimitedEdition) => ({
  notes,
  attachments,
  isDisabled,
  isLimitedEdition
}));
export const uploadWarningPopupContentSelector = () => 'Please upload your logo in order to proceed';
export const priceForEachLabelSelector = createSelector(isBoxProductSelector, isBox => isBox ? 'Each kit:' : 'Item price:');
export const isPriceShownSelector = createSelector(logosSelector, isEmbellishmentMethodSelectedSelector, featureTogglesSelector, (logos, isEmbellishmentMethodSelected, featureFlags) => !!Object.keys(logos).length && (isEmbellishmentMethodSelected || !featureFlags.dynamicPricingOptionsForDecorationMethods));
export const priceWithoutSetupFeeSelector = createSelector(quantitySelector, totalPriceSelector, realSetupFeeSelector, (quantity, totalPriceForItems, realSetupFee) => {
  const screenPrice = realSetupFee / quantity;
  return Price.getPricePerItem({
    quantity,
    screenPrice,
    totalPriceForItems
  });
});
export const pricingFormationDetailsSelector = createSelector(orderItemSelector, realRelatedItemsSelector, productSelector, selectedAssociatedProductsSelector, setupFeeSelector, isLimitedEditionSelector, featureTogglesSelector, (mainOrderItem, relatedOrderItems, mainProduct, selectedAssociatedProducts, setupFee, isLimitedEdition, featureFlags) => {
  const allSelectedProducts = [mainProduct, ...selectedAssociatedProducts.map(p => p.product)];
  const orderItems = [mainOrderItem, ...relatedOrderItems].map(item => {
    const product = allSelectedProducts.find(p => p._id === item.prodId) || item.prod;
    const regularPrice = Price.checkIfItemPriceCalculationPossible(item) ? getItemPriceFromProduct({
      orderItem: item,
      product,
      discardMultiplier: true,
      featureFlags
    }) : 0;
    return _objectSpread({}, item, {
      logo: product.logo,
      title: product.name,
      price: regularPrice,
      blendedPrice: item.blendedPrice || item.price,
      setupFee: item.quantity * setupFee / item.blendedQuantity
    });
  });
  const totalPrices = orderItems.reduce((acc, item) => {
    acc.separatedTotalOrderPrice += item.price;
    acc.blendedTotalOrderPrice += item.blendedPrice;
    return acc;
  }, {
    separatedTotalOrderPrice: 0,
    blendedTotalOrderPrice: 0
  });
  const {
    separatedTotalOrderPrice,
    blendedTotalOrderPrice
  } = totalPrices;
  return {
    orderItems,
    blendedTotalOrderPrice,
    saving: separatedTotalOrderPrice - blendedTotalOrderPrice,
    sharedSetupFee: setupFee,
    isLimitedEdition
  };
});
export const pricesAndQuantitySelector = createSelector(edgePriceSelector, quantityWithRelatedProductsSelector, totalPriceSelector, minimumQuantityErrorSelector, priceForEachLabelSelector, priceWithoutSetupFeeSelector, setupFeeSelector, setupFeeDeductionSelector, totalOfAssociatedPriceSelector, pricingFormationDetailsSelector, (edgePrices, quantity, price, minQuantityError, priceForEachLabel, priceWithoutSetupFee, setupFee, setupFeeDeductionDiscount, totalOfAssociatedPrice, pricingFormationDetails) => ({
  edgePrices,
  quantity,
  price,
  minQuantityError,
  priceForEachLabel,
  priceWithoutSetupFee,
  setupFee,
  setupFeeDeductionDiscount,
  totalOfAssociatedPrice,
  totalSaveForAssociated: pricingFormationDetails.saving
}));
export const priceBlockSelector = createSelector(pricesAndQuantitySelector, hasSelectedAssociatedProductsSelector, isFullDesignProductSelector, isPriceShownSelector, isCustomizedBlankSelector, isEditingStandaloneProductSelector, editingItemSelector, isLimitedEditionSelector, ({
  edgePrices,
  quantity,
  price,
  minQuantityError,
  priceForEachLabel,
  priceWithoutSetupFee,
  setupFee,
  setupFeeDeductionDiscount,
  totalOfAssociatedPrice,
  totalSaveForAssociated
}, hasSelectedAssociatedProducts, fullDesignMode, isPriceShown, isCustomizedBlank, isEditingStandaloneProduct, editingItem, isLimitedEdition) => {
  const isSharedInBoxItem = Boolean(editingItem === null || editingItem === void 0 ? void 0 : editingItem.isShared);
  const isStandaloneItem = isEditingStandaloneProduct && !(editingItem !== null && editingItem !== void 0 && editingItem.boxItemsId);
  return {
    quantity,
    setupFee,
    edgePrices,
    minQuantityError,
    isStandaloneItem,
    priceForEachLabel,
    isSharedInBoxItem,
    priceWithoutSetupFee,
    hasSelectedAssociatedProducts,
    totalOfAssociatedPrice,
    isSetupFeeShown: !fullDesignMode && !isCustomizedBlank,
    price: isPriceShown || isCustomizedBlank ? price : undefined,
    setupFeeLabel: getSetupFeeLabel(isStandaloneItem || isSharedInBoxItem || hasSelectedAssociatedProducts),
    oldSetupFee: setupFeeDeductionDiscount && setupFee + setupFeeDeductionDiscount || 0,
    isLimitedEdition,
    totalSaveForAssociated
  };
});
export const hasOneEmbellishmentMethodSelector = createSelector(embellishmentMethodsSelector, methods => {
  return (methods === null || methods === void 0 ? void 0 : methods.length) === 1;
});
const productionTimeSettingsForPopupSelector = createSelector(productSettingsFilteredSelector, embellishmentMethodsSelector, (settings, embellishmentMethodsList) => {
  return settings.map(_ref2 => {
    let {
        productionTimeList,
        embellishmentMethodId
      } = _ref2,
      rest = _objectWithoutProperties(_ref2, ["productionTimeList", "embellishmentMethodId"]);
    const selectedEmbellishmentMethod = embellishmentMethodsList.find(embellishment => embellishment._id === embellishmentMethodId);
    return _objectSpread({}, rest, {
      embellishmentMethodId,
      embellishmentMethodName: (selectedEmbellishmentMethod === null || selectedEmbellishmentMethod === void 0 ? void 0 : selectedEmbellishmentMethod.name) || '',
      embellishmentMethodTooltip: (selectedEmbellishmentMethod === null || selectedEmbellishmentMethod === void 0 ? void 0 : selectedEmbellishmentMethod.tooltip) || '',
      productionTimeList
    });
  });
});
export const productionTimePopupSelector = createStructuredSelector({
  productId: productIdSelector,
  prodTime: selectedProductionTimeSelector,
  isBox: isBoxProductSelector,
  productSettings: productionTimeSettingsForPopupSelector,
  open: isProductionTimeForProductPopupShownSelector,
  isLimitedEdition: isLimitedEditionSelector,
  uniqueColorsForOrderItem: () => 0,
  embellishmentMethods: embellishmentMethodsSelector,
  selectedEmbellishmentMethod: selectedEmbellishmentMethodSelector
});
const isGreyProductionTimeSelector = createSelector(isCustomizedBlankSelector, isCustomizedBlank => isCustomizedBlank);
const isSingleProductionTimeAvailableAcrossAllMethodsSelector = createSelector(productSettingsFilteredSelector, productSettings => {
  return productSettings.every(settings => settings.productionTimeList.length === 1);
});
const isSingleProductionTimeAvailableSelector = createSelector(productSettingsFilteredSelector, settings => settings.length === 1 && settings[0].productionTimeList.length === 1);
const isProductionTimeClickableSelector = createSelector(isBoxProductSelector, isCustomizedBlankSelector, isEditingSharedProductInBoxSelector, isSingleProductionTimeAvailableSelector, (isBox, isCustomizedBlank, isEditingSharedProduct, isSingleProductionTimeAvailable) => !isBox && !isCustomizedBlank && !isEditingSharedProduct && !isSingleProductionTimeAvailable);
const isProductionTimeDisabledSelector = createSelector(hasSelectedAssociatedProductsSelector, isEditingSharedProductInBoxSelector, isSingleProductionTimeAvailableSelector, (withRelatedItems, isEditingSharedProduct, isSingleProductionTimeAvailable) => withRelatedItems || isEditingSharedProduct || isSingleProductionTimeAvailable);
export const productionTimeOptionsSelector = createStructuredSelector({
  productionTime: chosenProductionTimeToDisplaySelector,
  shouldDisabled: isProductionTimeDisabledSelector,
  isProductionTimeClickable: isProductionTimeClickableSelector,
  isHidden: isEditingBoxItemSelector,
  isGreyProductionTime: isGreyProductionTimeSelector,
  shouldShowInfoTooltip: isSingleProductionTimeAvailableAcrossAllMethodsSelector,
  isEmbellishmentMethodSelected: isEmbellishmentMethodSelectedSelector
});
export const isCreateModeSelector = state => {
  const mode = orderModeSelector(state);
  return mode === PRODUCT_BUILDER_MODES.CREATE_MODE;
};
export const isAddTextShownSelector = state => state.product.isAddTextMode;
export const customStoreProductSelector = createSelector(orderItemSelector, productSelector, sizeSelector, (orderItem, product, size) => mapSwagItemToCustomProductParams(orderItem, _objectSpread({}, product, {
  productSize: size
})));
export const isAddStoreShownSelector = createSelector(isCustomStoreAdminSelector, isPreorderAllowedSelector, hasSizeSelector, sizeDependentPriceSelector, isBoxProductSelector, (isCustomStoreAdmin, isPreorderAllowed, hasSize, isSizeDependentPrice, isBoxProduct) => !isBoxProduct && isCustomStoreAdmin && isProductPreorderAllowed(isPreorderAllowed, hasSize, isSizeDependentPrice, false));
export const isAdditionalLogosAllowedSelector = createSelector(isFullDesignProductSelector, isBoxProductSelector, (isFullDesignProduct, isBoxProduct) => !(isFullDesignProduct || isBoxProduct));
export const isLogoTypeProductSelector = createSelector(productSelector, ({
  designUploadMode
}) => designUploadMode === DesignUploadMode.logo);
export const isToolsPanelButtonsShownSelector = createSelector(productSelector, ({
  designUploadMode
}) => designUploadMode !== DesignUploadMode.box);
export const forbiddenForBoxSelector = createSelector(productSelector, state => state && state.forbiddenForBox);
export const isForbiddenToShipInternationallySelector = createSelector(productSelector, state => state && state.isForbiddenToShipInternationally);
export const productInformationSelector = createSelector([productSelector, printedSamplePriceSelector, isSamplePromoSelector, productNameSelector, modifiedProductNameSelector, productIdSelector, isBoxProductSelector, isEditingBoxItemSelector, isPrintedSampleAvailableSelector, isBlankSampleAvailableSelector, isOutOfStockSelector, isLimitedEditionSelector, isPreBuiltLimitedCartOpenedByCustomerSelector, isResellerInOrderCreationModeSelector], ({
  descr,
  specs,
  imagesForSpec
}, printedSamplePrice, isSamplePromo, name, modifiedName, productId, isBoxProduct, isEditingBoxItem, isPrintedSampleAvailable, isBlankSampleAvailable, isOutOfStock, isLimitedEdition, isPreBuiltLimitedCart, isResellerInOrderCreationMode) => {
  return {
    descr,
    productId,
    isSamplePromo,
    isBoxProduct,
    hasInstantQuote: !isBoxProduct,
    shouldShowSampleButton: (isPrintedSampleAvailable || isBlankSampleAvailable) && !isEditingBoxItem && !isOutOfStock && !isResellerInOrderCreationMode,
    hasPrintedSample: !!printedSamplePrice,
    name: name.trim().replace(/ {2,}/g, ' ').split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' '),
    modifiedName,
    isLimitedEdition,
    isPreBuiltLimitedCart,
    shouldShowSpecs: Boolean(specs) || Boolean(imagesForSpec === null || imagesForSpec === void 0 ? void 0 : imagesForSpec.length)
  };
});
export const embellishmentMethodMouseOnSelector = createSelector(productRootSelector, product => product && product.embellishmentMethodMouseOn);
export const isForbiddenForInventorySelector = createSelector(productSelector, product => product.forbiddenForInventory);
export const upsellWidgetDataSelector = createSelector([setupFeeSelector, isPriceShownSelector, isCustomizedBlankSelector, productWithSelectedSettingsSelector, quantitySelector, selectedAssociatedProductsQuantitiesSelector, biggestScreenPriceSelector, mixedItemsSelector, selectedVariantsDataSelector, upsellDismissedStatesSelector, isRebuildModeSelector, itemIdSelector, isQuantityManuallyChangedSelector, hasSelectedAssociatedProductsSelector, featureTogglesSelector], (setupFee, isPriceShown, isCustomizedBlank, product, quantity, associatedProductsQuantity, screenPrice, items, selectedVariants, upsellDismissedStates, isRebuildMode, {
  itemId
}, isQuantityManuallyChanged, hasSelectedAssociatedProducts, featureFlags) => {
  const isPriceRange = !isPriceShown && !isCustomizedBlank;
  let isBoxItem = false;
  const realUpsellDismissedStates = upsellDismissedStates;
  const item = items.find(item => item._id === itemId);
  if (isRebuildMode) {
    isBoxItem = item ? Boolean(item.boxItemsId) : false;
  }
  const supplierInventoryLevels = getProductInStockLevelsByProductIdAndVariantSku({
    supplierInventoryLevels: product.supplierInventoryLevels
  });
  const upsellData = getUpsellWidgetData({
    item,
    isBoxItem,
    product: _objectSpread({}, product, {
      supplierInventoryLevels: supplierInventoryLevels[product._id || '']
    }),
    quantity,
    associatedProductsQuantity,
    items,
    screenPrice,
    setupFee,
    selectedVariants,
    isUpsellDismissed: realUpsellDismissedStates && realUpsellDismissedStates.productBuilder,
    hideWidget: isPriceRange || !isQuantityManuallyChanged,
    isWithRelatedProducts: hasSelectedAssociatedProducts,
    isSplitShipped: Boolean(item === null || item === void 0 ? void 0 : item.splitShippedType),
    featureFlags
  });
  return presentUpsellWidgetForCartItem(upsellData);
});
export const expirationDateForLimitedEditionSelector = createSelector(productSelector, product => {
  var _product$limitedEditi9;
  return new Date((_product$limitedEditi9 = product.limitedEdition) === null || _product$limitedEditi9 === void 0 ? void 0 : _product$limitedEditi9.expirationDate);
});
export const warningDialogSelector = createSelector([blankSamplePriceSelector, minimumQuantitySelector, maximumQuantitySelector, quantityIncrementSelector, minimumQuantityErrorSelector, maximumQuantityErrorSelector, minimumQuantityPerProductErrorSelector, minimumQuantityPerRelatedProductErrorSelector, uploadWarningPopupContentSelector, embellishmentErrorSelector, isLogoUploadedAndPriceSetSelector, quantityIncrementErrorSelector, quantityMoreThanSupplierHasErrorSelector, superSpeedColorLimitationErrorSelector, superSpeedProductMaxPrintColorsSelector], (blankSamplePrice, minQuant, maxQuant, quantIncrement, minQuantityError, maxQuantityError, minQuantityPerProductError, minQuantityPerRelatedProductError, uploadWarningPopupContent, embellishmentError, isLogoUploadedAndPriceSet, quantityIncrementError, quantityMoreThanSupplierHasError, superSpeedColorLimitationError, superSpeedColorLimit) => ({
  minQuantityPerRelatedProductError,
  minQuantityPerProductError,
  blankSamplePrice,
  minQuantityError,
  maxQuantityError,
  minQuant,
  maxQuant,
  quantIncrement,
  uploadWarningPopupContent,
  embellishmentError,
  isLogoUploadedAndPriceSet,
  quantityIncrementError,
  quantityMoreThanSupplierHasError,
  superSpeedColorLimitationError,
  superSpeedColorLimit
}));
export const footerSelector = createStructuredSelector({
  edgePrices: edgePriceSelector,
  quantity: quantitySelector,
  selectedProductionTime: selectedProductionTimeSelector,
  price: priceSelector,
  addToCartEnabled: addToCartEnabledSelector,
  minQuantityError: minimumQuantityErrorSelector,
  buttonLabel: footerButtonLabelSelector,
  isPageBlocked: pageBlockSelector,
  isCustomizedBlank: isCustomizedBlankSelector,
  isOutOfStock: isOutOfStockSelector,
  isLimitedEdition: isLimitedEditionSelector,
  expirationDateForLimitedEdition: expirationDateForLimitedEditionSelector,
  isAdminLoggedInMode: isAdminLoggedInModeSelector
});
export const outOfStockSizesForSelectedVariantsSelector = createSelector(selectedVariantsDataSelector, isApparelSelector, sizeLoadedSelector, sizeSelector, productInStockLevelsByVariantSkuSelector, productSelector, productIsAlwaysInStockSelector, (selectedVariants, isApparel, sizeLoaded, size, productInStockLevelsByVariantSku, product, productIsAlwaysInStock) => {
  if (!isApparel || !sizeLoaded) {
    return [];
  }
  const threshold = getProductOutOfStockThreshold(product);
  return selectedVariants.map(variant => size.options.map(sizeName => {
    var _variant$sizeSkus$siz, _variant$sizeSkus3;
    if (variant.isAlwaysInStockVariant || productIsAlwaysInStock || threshold === null) {
      return false;
    }
    return getVariantInStockLevelNumber(productInStockLevelsByVariantSku[(_variant$sizeSkus$siz = variant === null || variant === void 0 ? void 0 : (_variant$sizeSkus3 = variant.sizeSkus) === null || _variant$sizeSkus3 === void 0 ? void 0 : _variant$sizeSkus3[sizeName]) !== null && _variant$sizeSkus$siz !== void 0 ? _variant$sizeSkus$siz : 0]) <= threshold;
  }));
});
export const outOfStockSizesForSampleSelector = createSelector(outOfStockSizesForSelectedVariantsSelector, outOfStockSizesForSelectedVariants => outOfStockSizesForSelectedVariants[0]);
export const isInStockLessThanMinQtySelector = createSelector(isLEProductHasInfinityQuantity, productQuantityBySupplierInventoryLevelsSelector, minimumQuantitySelector, (isInfinity, inStock, minQuant) => !isInfinity && inStock < minQuant);
export const isLETimeExpiredSelector = createSelector(productRootSelector, state => state.isLETimeExpired);
export const hideLEInStockSelector = createSelector(isLEProductHasInfinityQuantity, isInStockLessThanMinQtySelector, isLETimeExpiredSelector, (isInfinity, isOutOfStock, isLETimeExpired) => isInfinity || isOutOfStock || isLETimeExpired);