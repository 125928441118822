export const MOCKUP_GIVEAWAY_NOTECARD_HIDDEN_CONTAINER_ID = 'mockup-giveaway-notecard-hidden-container';
export const MOCKUP_HIDDEN_CONTAINER_STYLES = {
  position: 'absolute',
  left: '-5000px',
  width: '720px',
  height: '480px'
};
export const HTML_TO_IMAGE_OPTIONS = {
  quality: 1,
  pixelRatio: 3,
  cacheBust: true,
  style: {
    position: 'static',
    width: '720px',
    height: '480px'
  }
};
export const MOCKUP_BOX_NOTECARD_HIDDEN_CONTAINER_ID = 'mockup-box-notecard-hidden-container';