import _reset from "swag-client-common/redux-form-actions/reset";
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { EmailApi } from '../../api/email.api';
const PREFIX = 'CUSTOM_SWAG_';
export const SUBMIT_STARTED = `${PREFIX}SUBMIT_STARTED`;
export const SUBMIT_SUCCESS = `${PREFIX}SUBMIT_SUCCESS`;
export const SUBMIT_ERROR = `${PREFIX}SUBMIT_ERROR`;
export const FORM_STATUS_RESET = `${PREFIX}FORM_STATUS_RESET`;
export const submitForm = createAsyncAction({
  fn: EmailApi.customSwag,
  preFn: dispatch => {
    dispatch({
      type: SUBMIT_STARTED
    });
  },
  success: () => ({
    type: SUBMIT_SUCCESS
  }),
  error: error => ({
    type: SUBMIT_ERROR,
    error
  }),
  postSuccess: dispatch => {
    setTimeout(() => {
      dispatch(_reset('customSwag'));
      dispatch({
        type: FORM_STATUS_RESET
      });
    }, 3000);
  }
});