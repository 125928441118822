function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export const NOTECARD_EDITOR_FONTS = {
  HELVETICA: 'Helvetica',
  ARIAL: 'Arial',
  GEORGIA: 'Georgia',
  IMPACT: 'Impact',
  TAHOMA: 'Tahoma',
  TIMES_NEW_ROMAN: 'Times New Roman',
  VERDANA: 'Verdana'
};
export const NOTECARD_EDITOR_TEXT_FORMAT_NAMES = {
  BOLD: 'BOLD',
  ITALIC: 'ITALIC',
  UNDERLINE: 'UNDERLINE'
};
export const NOTECARD_TEXT_ALIGNMENT_OPTIONS = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
};
export const NOTECARD_EDITOR_STYLES_MAP = _objectSpread({
  FONT_FAMILY_PREFIX: 'fontfamily-',
  FONT_SIZE_PREFIX: 'fontsize-',
  COLOR_RGB_PREFIX: 'color-rgb'
}, NOTECARD_EDITOR_TEXT_FORMAT_NAMES);
export const NOTECARD_EDITOR_SELECT_TYPES = {
  FONT: 'font',
  TEXT_PROPERTY: 'textProperty',
  FONT_SIZE: 'fontSize',
  TEXT_ALIGN: 'textAlign',
  COLOR: 'color'
};
export const MAX_FONT_SIZE_PT = 95;
const createFontSizeStyleMap = maxValue => {
  const styleMap = {};
  for (let counter = 0; counter <= maxValue; counter++) {
    styleMap[`fontsize-${counter}pt`] = {
      fontSize: counter ? `${counter}pt` : counter
    };
  }
  return styleMap;
};
export const CUSTOM_FONT_SIZE_STYLE_MAP = createFontSizeStyleMap(MAX_FONT_SIZE_PT);
export const editorDefaultContentString = `Hi,
I hope you are well! We wanted to give you something to say thank you for being a great customer. Please take our products as a gift of our appreciation.
Best,
Jeremy`;