export function subtractUpsellQuantities(obj1, obj2) {
  if (!Object.keys(obj2).length) {
    return obj1;
  }
  const result = {};
  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key) && Object.prototype.hasOwnProperty.call(obj2, key)) {
      const arr1 = obj1[key];
      const arr2 = obj2[key];
      const diffArr = arr1.map((val, index) => val - arr2[index]);
      result[key] = diffArr;
    }
  }
  return result;
}