function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { containsDestinationConflictItems } from 'swag-client-common/utils/cart.util';
import { CART_LINK_PREFIX } from 'swag-common/constants/main-swag.constants';
import { getCartItemsCount } from 'swag-common/utils/get-cart-items-count.util';
import { PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
import { PreBuiltCartType } from 'swag-common/constants/pre-built-cart.constants';
import { isSuperSpeedItem } from 'swag-common/utils/items/is-item-super-speed.util';
import { getBoxProductDataForBoxBuilderPopup } from 'swag-client-common/utils/product.utils';
import { BOX_TYPE_FOR_SELECTION, SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { isAvailableSuperSpeedSelector } from '../settings/settings.selectors';
const rootSelector = state => state.cart;
export const cartItemsSelector = createSelector(rootSelector, cart => cart.items);
const cartBadgeIsLoadingStateSelector = createSelector(rootSelector, cart => cart.cartBadgeIsLoading);
const isLoadingSelector = createSelector(rootSelector, cart => cart.isLoading);
const deletingItemIdSelector = createSelector(rootSelector, cart => cart.deletingItemId);
export const orderTokenSelector = createSelector(rootSelector, cart => cart.orderToken);
export const orderVersionSelector = createSelector(rootSelector, cart => cart.orderVersion);
export const draftVersionSelector = createSelector(rootSelector, cart => cart.draftVersion);
export const draftTokenSelector = createSelector(rootSelector, cart => cart.draftToken);
export const cartItemsCountSelector = createSelector(cartItemsSelector, getCartItemsCount);
const cartDeletingItemSelector = createSelector(cartItemsSelector, deletingItemIdSelector, (items, deletingItemId) => {
  return items.find(item => item._id === deletingItemId);
});
export const isReducingDeletingItemSelector = createSelector(cartItemsSelector, cartDeletingItemSelector, (items, deletingItem) => {
  if (!deletingItem || !deletingItem.boxItemsId) {
    return true;
  }
  return items.filter(item => item.boxItemsId === deletingItem.boxItemsId).length < 3;
});
export const cartBadgeIsLoadingSelector = createSelector(isReducingDeletingItemSelector, cartBadgeIsLoadingStateSelector, isLoadingSelector, (isReducingDeletingItem, cartBadgeIsLoading, isLoading) => (cartBadgeIsLoading || isLoading) && isReducingDeletingItem);
export const containsDestinationConflictItemsSelector = createSelector(cartItemsSelector, containsDestinationConflictItems);
export const preBuiltCartLinkSelector = createSelector(rootSelector, cart => {
  const origin = window.location.origin;
  return `${origin}${CART_LINK_PREFIX}${cart.preBuiltCartShortId}${APP_ROUTES.CART}`;
});
export const preBuiltCartTypeSelector = createSelector(rootSelector, cart => {
  return cart === null || cart === void 0 ? void 0 : cart.preBuiltCartType;
});
export const isPreBuiltCartSelector = createSelector(preBuiltCartTypeSelector, Boolean);
export const isPreBuiltLimitedCartSelector = createSelector(preBuiltCartTypeSelector, type => type === PreBuiltCartType.PreBuiltLimited);
export const isPreBuiltLimitedCartOpenedByCustomerSelector = createSelector(isPreBuiltLimitedCartSelector, isPreBuiltLimitedCart => {
  var _window, _window$location;
  const CART_LINK_PREFIX = '/cart-link/';
  const isOpenedByCustomer = (_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.pathname.includes(CART_LINK_PREFIX);
  return isPreBuiltLimitedCart && isOpenedByCustomer;
});
export const cartOrderIdSelector = createSelector(rootSelector, cart => {
  return cart._id;
});
export const cartOrderDiscountsSelector = createSelector(rootSelector, cart => {
  const {
    measurement,
    IS_FREE_SETUP_FEE,
    IS_FREE_SHIPPING,
    IS_TAXFREE,
    promocodeDiscount
  } = cart;
  const discountValueAmount = measurement === PromocodeMeasurementEnum.ABSOLUTE ? promocodeDiscount / 100 : promocodeDiscount;
  return {
    discountType: measurement,
    discountValue: discountValueAmount,
    freeTax: IS_TAXFREE,
    freeSetupFee: IS_FREE_SETUP_FEE,
    freeShipping: IS_FREE_SHIPPING
  };
});
export const cartShipmentTotalSelector = createSelector(rootSelector, cart => {
  return cart.shipmentCustomerTotal;
});
export const cartTotalSelector = createSelector(rootSelector, cart => {
  return (cart === null || cart === void 0 ? void 0 : cart.total) || 0;
});
export const shouldShowSuperSpeedBoxLabelSelector = createSelector(isAvailableSuperSpeedSelector, cartItemsSelector, (isAvailableSuperSpeed, items) => {
  return isAvailableSuperSpeed && (items === null || items === void 0 ? void 0 : items.some(item => isSuperSpeedItem(item)));
});
export const specialBoxProductsSelector = createSelector(rootSelector, state => state && state.specialBoxProducts);
export const boxTypesSelector = createSelector(specialBoxProductsSelector, specialProducts => {
  return BOX_TYPE_FOR_SELECTION.map(type => {
    const product = specialProducts.find(product => product.specialProductType === type);
    if (!product) {
      return null;
    }
    return getBoxProductDataForBoxBuilderPopup(product);
  }).filter(item => !!item);
});
export const boxTypesWithFormattedPricesSelector = createSelector(boxTypesSelector, boxTypes => boxTypes.map(box => _objectSpread({}, box, {
  pricePerBox: box.pricePerBox / 100,
  assemblyFee: box.assemblyFee / 100
})));
export const tapeDataSelector = createSelector(specialBoxProductsSelector, specialProducts => {
  const product = specialProducts.find(product => product.specialProductType === SPECIAL_PRODUCT_TYPES.BOX_TAPE);
  return getBoxProductDataForBoxBuilderPopup(product);
});
export const isTaxExemptSelector = createSelector(rootSelector, cart => {
  return Boolean(cart === null || cart === void 0 ? void 0 : cart.isTaxExempt);
});