export const LANDING_TYPES = {
  INABOX: 'INABOX',
  ENTERPRISE: 'ENTERPRISE',
  INTELLIGENT: 'INTELLIGENT',
  INVENTORY: 'INVENTORY',
  PATAGONIA: 'PATAGONIA',
  AW_YOU_GUYS: 'AW_YOU_GUYS',
  OUR_STORY: 'OUR_STORY',
  GIVEAWAY: 'GIVEAWAY',
  AUTOMATION: 'AUTOMATION',
  SHOPIFY: 'SHOPIFY',
  OUR_INTEGRATIONS: 'OUR_INTEGRATIONS',
  TERMS: 'TERMS',
  TOS: 'TOS',
  COMMITMENT_TO_ACCESSIBILITY: 'COMMITMENT_TO_ACCESSIBILITY',
  FAQ: 'FAQ'
};
export const OTHER_HUBSPOT_FORMS = {
  REQUEST_DEMO: 'REQUEST_DEMO'
};
export const THEMES = {
  [LANDING_TYPES.INABOX]: 'inabox',
  [LANDING_TYPES.ENTERPRISE]: 'enterprise',
  [LANDING_TYPES.INVENTORY]: 'inventory',
  [LANDING_TYPES.PATAGONIA]: 'patagonia',
  [LANDING_TYPES.OUR_STORY]: 'our-story',
  [LANDING_TYPES.GIVEAWAY]: 'giveaway',
  [LANDING_TYPES.AUTOMATION]: 'automation',
  [LANDING_TYPES.SHOPIFY]: 'shopify',
  [LANDING_TYPES.OUR_INTEGRATIONS]: 'our-integrations',
  [LANDING_TYPES.TERMS]: 'terms',
  [LANDING_TYPES.TOS]: 'tos',
  [LANDING_TYPES.COMMITMENT_TO_ACCESSIBILITY]: 'commitment-to-accessibility',
  [LANDING_TYPES.FAQ]: 'faq'
};
export const SCROLL_BANNER_ELEMENTS = {
  [LANDING_TYPES.INABOX]: 'inabox-explore',
  [LANDING_TYPES.ENTERPRISE]: 'enterprise-explore',
  [LANDING_TYPES.INVENTORY]: 'inventory-explore',
  [LANDING_TYPES.PATAGONIA]: 'patagonia-form',
  [LANDING_TYPES.GIVEAWAY]: 'giveaway-feature'
};
export const SCROLL_FORM_ELEMENTS = {
  [LANDING_TYPES.INABOX]: 'inabox-form',
  [LANDING_TYPES.ENTERPRISE]: 'enterprise-form',
  [LANDING_TYPES.INVENTORY]: 'inventory-form',
  [LANDING_TYPES.AUTOMATION]: 'automation-form',
  [LANDING_TYPES.GIVEAWAY]: 'giveaway-form',
  [LANDING_TYPES.SHOPIFY]: 'shopify-form'
};
export const MAP_REQUEST_TO_SUBJECT_THEME = {
  [THEMES.ENTERPRISE]: 'Swag shop',
  [THEMES.INVENTORY]: 'Inventory',
  [THEMES.PATAGONIA]: 'Patagonia'
};