import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { handleMockupManagerClose } from '../../../redux/mockup-manager/mockup-manager.actions';
import { currentStepSelector } from '../../../redux/mockup-manager/mockup-manages.selectors';
import { MockupManagerPopup as MockupManagerPopupComponent } from './mockup-manager-popup';
const mapStateToProps = createStructuredSelector({
  currentStep: currentStepSelector
});
const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(handleMockupManagerClose());
  }
});
export const MockupManagerPopup = connect(mapStateToProps, mapDispatchToProps)(MockupManagerPopupComponent);