import { getFirstDayOfMonth, getLastMomentOfMonth, isSameMonth, setStartOfDay } from '.';
export const getDateRange = anyDayOfMonth => {
  const today = new Date();
  if (isSameMonth(today, anyDayOfMonth)) {
    return {
      from: getFirstDayOfMonth(anyDayOfMonth),
      to: today
    };
  }
  return {
    from: getFirstDayOfMonth(anyDayOfMonth),
    to: getLastMomentOfMonth(anyDayOfMonth)
  };
};
export const isBefore = (first, second, unit) => {
  if (unit === 'day') {
    return setStartOfDay(new Date(first)).valueOf() < setStartOfDay(new Date(second)).valueOf();
  }
  return new Date(first).valueOf() < new Date(second).valueOf();
};
export const isAfter = (first, second, unit) => {
  if (unit === 'day') {
    return setStartOfDay(new Date(first)).valueOf() > setStartOfDay(new Date(second)).valueOf();
  }
  return new Date(first).valueOf() > new Date(second).valueOf();
};
export const isBetween = (date, range) => date >= range.from && date <= range.to;
export const isSameOrBefore = (first, second, unit) => {
  if (unit === 'day') {
    return setStartOfDay(new Date(first)).valueOf() <= setStartOfDay(new Date(second)).valueOf();
  }
  return new Date(first).valueOf() <= new Date(second).valueOf();
};
export const isSameOfAfter = (first, second, unit) => {
  if (unit === 'day') {
    return setStartOfDay(new Date(first)).valueOf() >= setStartOfDay(new Date(second)).valueOf();
  }
  return new Date(first).valueOf() >= new Date(second).valueOf();
};
export const isSame = (first, second, unit) => {
  if (unit === 'day') {
    return setStartOfDay(new Date(first)).valueOf() === setStartOfDay(new Date(second)).valueOf();
  }
  return new Date(first).valueOf() === new Date(second).valueOf();
};