import { checkIsResellerTenant } from 'swag-common/utils/check-is-reseller-tenant.util';
import { ADDRESS_FOR_INVOICE } from 'swag-common/constants/tenant.constants';
export const getAddressForInvoice = (tenant, tenantStoreSettings) => {
  if (checkIsResellerTenant(tenant) && tenantStoreSettings !== null && tenantStoreSettings !== void 0 && tenantStoreSettings.address) {
    const {
      companyName,
      address,
      companyPhone,
      companyShowPhone
    } = tenantStoreSettings;
    const companyPhoneNumber = companyShowPhone && companyPhone ? companyPhone : '';
    return [companyName || '', address === null || address === void 0 ? void 0 : address.billingAddress, `${address === null || address === void 0 ? void 0 : address.billingCity}, ${address === null || address === void 0 ? void 0 : address.billingState} ${address === null || address === void 0 ? void 0 : address.billingZip}`, address === null || address === void 0 ? void 0 : address.billingCountry, companyPhoneNumber];
  }
  return ADDRESS_FOR_INVOICE[tenant] || null;
};