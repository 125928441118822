function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { PAYMENT_STATES } from 'swag-client-common/utils/constants';
import { CHECK_USER_EXISTS_STARTED } from 'swag-client-common/redux/user/user.actions';
import { DeliveryKeys } from 'swag-client-common/constants/delivery';
import { CLIENT_LOGIN_SUCCESS, LOGOUT_SUCCESS } from 'swag-client-common/redux/user/user.actions';
import { POPUP_SHOWN } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { GET_CART, ADD_ITEM_TO_CART, EDIT_ITEM_IN_CART_SUCCESS, REMOVE_ITEM_FROM_CART_SUCCESS, REPLACE_ITEM_SUCCESS, CLEAR_CART, MOVE_ITEM_TO_DRAFT_SUCCESS, MOVE_ITEM_TO_CART_SUCCESS } from 'swag-client-common/redux/cart/cart.actions';
import { CartErrorCodes, FORBIDDEN_ERROR_CODE } from 'swag-common/constants/error-codes';
import { AddressManualType, INVENTORY_BUY_CREDITS_POPUP } from 'swag-client-common/constants';
import { isOrderSplitShipped } from 'swag-common/utils/order/is-order-split-shipped';
import { STRIPE_FORM_SUBMIT_STARTED, STRIPE_FORM_SUBMIT_ERROR } from '../stripe-form/stripe-form.actions';
import { BUY_CREDITS_FAILURE, BUY_CREDITS_REQUEST, BUY_CREDITS_SUCCESS } from '../inventory/inventory.actions';
import { EDIT_CHECKOUT_FIELD, SHIPPING_NAME_TOUCHED, PROCEED_ORDER_SUCCESS, PROCEED_ORDER_FAIL, START_CHECKOUT_LOADING, GET_RATES_SUCCESS, GET_RATES_REQUEST, GET_RATES_FAIL, CLOSE_DELIVERY_SNACKBAR, PAYMENT_POPUP_OPEN, GATHER_CARD_INFO, GATHER_ADDRESS_INFO, TOGGLE_FULFILLMENT_CENTER_OPTION, PREFILL_DELIVERY_CONTACT, PREFILL_SHIPPING, RESET_POPUP_STATE, PROCEED_DUTY_ORDER_FAIL, ACCEPT_CONTRACT_OPTION, ACCEPT_INVENTORY_OPTION, TOGGLE_CREATE_MY_INVENTORY_SELECTED, TOGGLE_ADD_TO_INVENTORY_WIDGET_OPEN, TOGGLE_ADD_TO_INVENTORY_WIDGET_TOUCHED, SET_PAYMENT_STATE, PAYMENT_POPUP_CLOSE, SET_SELECTED_ADDRESS_SUCCESS, CONFIRM_ADDRESS, SHIPPING_ADDRESS_TOUCHED, START_PROCEED_LOADING, RESET_DELIVERY_OPTION, FETCH_CHECKOUT_CREDITS_SUCCESS, SET_CREDITS_MORE_TO_BUY, SET_BUY_MORE_FLOW, CHANGE_BUY_MORE_CREDITS, TOGGLE_TOS_CHECKBOX, CHANGE_REPLACEMENT_ORDER_SEARCH_VALUE, REPLACEMENT_ORDER_SEARCH_SUCCESS, REPLACEMENT_ORDER_SEARCH_REQUEST, REPLACEMENT_ORDER_SEARCH_ERROR, SELECT_ORIGINAL_ITEM_FOR_REPLACE, COMMENTS_CHANGE_TEXT, REPLACEMENT_PERCENT_CHANGE, ERASE_REPLACEMENT_ORDER_STATE, REPLACEMENT_IS_SILENT_CHANGE } from './checkout.actions';
import { isDeliveryToInventoryPossible } from './checkout.utils';
import { initialState } from './initial-state-params';
const DELIVERY_KEYS = [DeliveryKeys.FirstName, DeliveryKeys.LastName, DeliveryKeys.FirstNameShipping, DeliveryKeys.LastNameShipping, DeliveryKeys.Company, DeliveryKeys.Email, DeliveryKeys.PhoneNumber, DeliveryKeys.Address, DeliveryKeys.Country, DeliveryKeys.City, DeliveryKeys.Unit, DeliveryKeys.State, DeliveryKeys.Zip, DeliveryKeys.DeliveryNote, DeliveryKeys.IsDeliveryPrefilled];
export function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_CHECKOUT_FIELD:
      {
        switch (action.field) {
          case DeliveryKeys.FormFirstName:
          case DeliveryKeys.FormLastName:
          case DeliveryKeys.PhoneNumber:
          case DeliveryKeys.Company:
          case DeliveryKeys.Email:
          case DeliveryKeys.FirstNameShipping:
          case DeliveryKeys.LastNameShipping:
          case DeliveryKeys.Address:
          case DeliveryKeys.Unit:
          case DeliveryKeys.City:
          case DeliveryKeys.State:
          case DeliveryKeys.Country:
          case DeliveryKeys.Zip:
          case DeliveryKeys.CompanyDepartmentId:
          case DeliveryKeys.DeliveryNote:
            {
              const {
                field,
                value,
                additionalData
              } = action;
              const {
                delivery
              } = state;
              const nextStateDelivery = _objectSpread({}, delivery, additionalData, {
                [field]: value
              });
              const autocompleteList = field === DeliveryKeys.Address && !value ? [] : state.autocompleteList;
              if ([DeliveryKeys.Zip, DeliveryKeys.Country, DeliveryKeys.State].includes(field)) {
                nextStateDelivery.err = null;
              }
              if (field === DeliveryKeys.FormFirstName || field === DeliveryKeys.FirstNameShipping) {
                nextStateDelivery[DeliveryKeys.FormFirstName] = value;
                nextStateDelivery[DeliveryKeys.FirstNameShipping] = value;
              }
              if (field === DeliveryKeys.FormLastName || field === DeliveryKeys.LastNameShipping) {
                nextStateDelivery[DeliveryKeys.FormLastName] = value;
                nextStateDelivery[DeliveryKeys.LastNameShipping] = value;
              }
              return _objectSpread({}, state, {
                delivery: nextStateDelivery,
                addressConfirmed: false,
                autocompleteList,
                isAddressEnteredManually: false
              });
            }
          default:
            {
              return state;
            }
        }
      }
    case PREFILL_DELIVERY_CONTACT:
      {
        return _objectSpread({}, state, {
          delivery: _objectSpread({}, state.delivery, action.delivery, {
            isDeliveryPrefilled: true
          })
        });
      }
    case FETCH_CHECKOUT_CREDITS_SUCCESS:
      {
        const {
          available,
          pending,
          inventoryCreditsHasFulfilledBuyTransactions
        } = action.payload;
        return _objectSpread({}, state, {
          credits: _objectSpread({}, state.credits, {
            available: available || 0,
            pending: pending || 0
          }),
          inventoryCreditsHasFulfilledBuyTransactions: inventoryCreditsHasFulfilledBuyTransactions
        });
      }
    case SET_CREDITS_MORE_TO_BUY:
      {
        return _objectSpread({}, state, {
          credits: _objectSpread({}, state.credits, {
            moreToBuy: action.payload
          })
        });
      }
    case SET_BUY_MORE_FLOW:
      {
        return _objectSpread({}, state, {
          credits: _objectSpread({}, state.credits, {
            isBuyMoreFlow: action.payload
          })
        });
      }
    case PREFILL_SHIPPING:
      {
        return _objectSpread({}, state, {
          delivery: _objectSpread({}, state.delivery, action.payload)
        });
      }
    case TOGGLE_FULFILLMENT_CENTER_OPTION:
      {
        return _objectSpread({}, state, {
          deliverToFulfillmentCenter: action.payload,
          deliveryOptionWasChosen: true
        });
      }
    case RESET_DELIVERY_OPTION:
      {
        return _objectSpread({}, state, {
          deliveryOptionWasChosen: false
        });
      }
    case ACCEPT_CONTRACT_OPTION:
      {
        const inventoryAccepted = !action.payload ? false : state.inventoryAccepted;
        return _objectSpread({}, state, {
          acceptContract: action.payload,
          inventoryAccepted
        });
      }
    case ACCEPT_INVENTORY_OPTION:
      {
        return _objectSpread({}, state, {
          inventoryAccepted: action.payload
        });
      }
    case TOGGLE_CREATE_MY_INVENTORY_SELECTED:
      {
        return _objectSpread({}, state, {
          isCreateMyInventorySelected: action.payload
        });
      }
    case TOGGLE_ADD_TO_INVENTORY_WIDGET_OPEN:
      {
        return _objectSpread({}, state, {
          atiWidgetIsOpen: action.payload
        });
      }
    case TOGGLE_ADD_TO_INVENTORY_WIDGET_TOUCHED:
      {
        return _objectSpread({}, state, {
          atiWidgetIsTouched: action.payload
        });
      }
    case CHECK_USER_EXISTS_STARTED:
      {
        return _objectSpread({}, state, {
          acceptContract: false
        });
      }
    case SHIPPING_NAME_TOUCHED:
      {
        return _objectSpread({}, state, {
          delivery: _objectSpread({}, state.delivery, {
            shippingNameUntouched: false
          })
        });
      }
    case PROCEED_ORDER_SUCCESS:
      {
        const {
          user: {
            email,
            emailVerified,
            passwordNotSet
          },
          isApprovalFlow,
          order: {
            publicId
          }
        } = action.body;
        return _objectSpread({}, state, {
          userEmail: email,
          loading: false,
          dialogIsOpen: true,
          paymentState: PAYMENT_STATES.SUCCESS,
          err: null,
          emailVerified,
          passwordNotSet,
          acceptContract: false,
          isInventoryApprovalFlow: isApprovalFlow,
          publicId
        });
      }
    case PROCEED_ORDER_FAIL:
    case PROCEED_DUTY_ORDER_FAIL:
      {
        var _action$err, _action$err2;
        if (((_action$err = action.err) === null || _action$err === void 0 ? void 0 : _action$err.code) === CartErrorCodes.INVALID_ORDER_VERSION || ((_action$err2 = action.err) === null || _action$err2 === void 0 ? void 0 : _action$err2.status) === FORBIDDEN_ERROR_CODE) {
          return _objectSpread({}, state, {
            paymentPopupOpen: false,
            dialogIsOpen: false,
            loading: false,
            err: null
          });
        }
        return _objectSpread({}, state, {
          paymentState: PAYMENT_STATES.ERROR,
          dialogIsOpen: true,
          loading: false,
          err: action.err
        });
      }
    case GET_RATES_SUCCESS:
      {
        var _action$body$order$re, _action$body$order$pr;
        return _objectSpread({}, state, {
          addressConfirmed: true,
          orderForCustomStore: action.body.order.orderForCustomStore,
          isInventoryApprovalFlow: action.body.isApprovalFlow,
          neededTokens: action.body.neededTokens,
          paymentPopupOpen: false,
          resellersCommission: (_action$body$order$re = action.body.order.resellersCommission) !== null && _action$body$order$re !== void 0 ? _action$body$order$re : 0,
          productsCommission: (_action$body$order$pr = action.body.order.productsCommission) !== null && _action$body$order$pr !== void 0 ? _action$body$order$pr : [],
          delivery: _objectSpread({}, state.delivery, {
            zip: action.body.order.zip,
            gettingRates: false,
            err: '',
            msg: '',
            snackbarOpened: false,
            shippingAddressUntouched: true
          })
        });
      }
    case GET_RATES_REQUEST:
      {
        return _objectSpread({}, state, {
          delivery: _objectSpread({}, state.delivery, {
            gettingRates: true
          })
        });
      }
    case GET_RATES_FAIL:
      {
        return _objectSpread({}, state, {
          addressConfirmed: false,
          delivery: _objectSpread({}, state.delivery, {
            gettingRates: false,
            snackbarOpened: true,
            err: action.err,
            msg: action.err.message
          })
        });
      }
    case START_CHECKOUT_LOADING:
      {
        return _objectSpread({}, state, {
          paymentState: PAYMENT_STATES.LOADING,
          paymentPopupOpen: true
        });
      }
    case START_PROCEED_LOADING:
      {
        return _objectSpread({}, state, {
          loading: true
        });
      }
    case CLOSE_DELIVERY_SNACKBAR:
      {
        return _objectSpread({}, state, {
          delivery: _objectSpread({}, state.delivery, {
            err: '',
            msg: '',
            snackbarOpened: false
          })
        });
      }
    case ADD_ITEM_TO_CART:
    case EDIT_ITEM_IN_CART_SUCCESS:
    case REPLACE_ITEM_SUCCESS:
    case REMOVE_ITEM_FROM_CART_SUCCESS:
      {
        var _action$body$reseller, _action$body$products;
        return _objectSpread({}, state, {
          addressConfirmed: false,
          resellersCommission: (_action$body$reseller = action.body.resellersCommission) !== null && _action$body$reseller !== void 0 ? _action$body$reseller : 0,
          productsCommission: (_action$body$products = action.body.productsCommission) !== null && _action$body$products !== void 0 ? _action$body$products : []
        });
      }
    case GET_CART:
      {
        var _action$body$reseller2, _action$body$products2;
        const cart = action.body;
        const {
          items = []
        } = cart;
        const {
          delivery,
          deliverToFulfillmentCenter: prevDeliverToFulfillmentCenter,
          atiWidgetIsTouched
        } = state;
        const deliveryFromCart = !cart.deliverToFulfillmentCenter ? DELIVERY_KEYS.reduce((all, key) => {
          if (cart[key] && cart[key] !== '') {
            all[key] = cart[key];
          }
          return all;
        }, {}) : {};
        const isDeliveryToWarehousePossible = isDeliveryToInventoryPossible(items, prevDeliverToFulfillmentCenter);
        const isSplitShipped = isOrderSplitShipped({
          items
        });
        const deliveryOptionsSettings = {
          deliverToFulfillmentCenter: isDeliveryToWarehousePossible
        };
        if (isSplitShipped) {
          deliveryOptionsSettings.deliverToFulfillmentCenter = false;
          deliveryOptionsSettings.deliveryOptionWasChosen = true;
        }
        const shouldAtiWidgetBeOpened = isDeliveryToWarehousePossible ? 1 : 0;
        const newAtiWidgetIsOpen = isSplitShipped ? 0 : shouldAtiWidgetBeOpened;
        return _objectSpread({}, state, {
          delivery: _objectSpread({}, delivery, deliveryFromCart)
        }, deliveryOptionsSettings, {
          atiWidgetIsOpen: newAtiWidgetIsOpen,
          atiWidgetIsTouched: isDeliveryToWarehousePossible ? true : atiWidgetIsTouched,
          resellersCommission: (_action$body$reseller2 = action.body.resellersCommission) !== null && _action$body$reseller2 !== void 0 ? _action$body$reseller2 : 0,
          productsCommission: (_action$body$products2 = action.body.productsCommission) !== null && _action$body$products2 !== void 0 ? _action$body$products2 : []
        });
      }
    case MOVE_ITEM_TO_DRAFT_SUCCESS:
    case MOVE_ITEM_TO_CART_SUCCESS:
      {
        var _cart$items;
        const {
          cart
        } = action.payload;
        const {
          deliverToFulfillmentCenter: prevDeliverToFulfillmentCenter,
          atiWidgetIsOpen,
          atiWidgetIsTouched
        } = state;
        const items = (_cart$items = cart === null || cart === void 0 ? void 0 : cart.items) !== null && _cart$items !== void 0 ? _cart$items : [];
        const isDeliveryToWarehousePossible = isDeliveryToInventoryPossible(items, prevDeliverToFulfillmentCenter);
        const isSplitShipped = isOrderSplitShipped({
          items
        });
        const deliverToFulfillmentCenter = isSplitShipped ? false : isDeliveryToWarehousePossible;
        const shouldAtiWidgetBeOpened = isDeliveryToWarehousePossible ? 1 : atiWidgetIsOpen;
        const newAtiWidgetIsOpen = isSplitShipped ? 0 : shouldAtiWidgetBeOpened;
        return _objectSpread({}, state, {
          deliverToFulfillmentCenter,
          atiWidgetIsOpen: newAtiWidgetIsOpen,
          atiWidgetIsTouched: isDeliveryToWarehousePossible ? true : atiWidgetIsTouched
        });
      }
    case STRIPE_FORM_SUBMIT_STARTED:
      {
        return _objectSpread({}, state, {
          paymentState: PAYMENT_STATES.LOADING
        });
      }
    case STRIPE_FORM_SUBMIT_ERROR:
      {
        return _objectSpread({}, state, {
          paymentState: PAYMENT_STATES.GATHERING_CARD_INFO
        });
      }
    case PAYMENT_POPUP_OPEN:
      {
        return _objectSpread({}, state, {
          paymentPopupOpen: action.isOpen,
          paymentState: action.paymentState,
          isTOSAccepted: false
        });
      }
    case PAYMENT_POPUP_CLOSE:
      {
        return _objectSpread({}, state, {
          paymentPopupOpen: false,
          err: null,
          paymentState: PAYMENT_STATES.IDLE
        });
      }
    case GATHER_CARD_INFO:
      {
        return _objectSpread({}, state, {
          paymentState: PAYMENT_STATES.GATHERING_CARD_INFO
        });
      }
    case GATHER_ADDRESS_INFO:
      {
        return _objectSpread({}, state, {
          paymentState: PAYMENT_STATES.GATHERING_ADDRESS_INFO
        });
      }
    case CLIENT_LOGIN_SUCCESS:
      {
        const {
          profile: {
            phoneNumber,
            firstName,
            lastName,
            company,
            email
          }
        } = action.body;
        return _objectSpread({}, state, {
          delivery: _objectSpread({}, state.delivery, {
            fname: firstName,
            lname: lastName,
            phoneNumber,
            company,
            email
          })
        });
      }
    case LOGOUT_SUCCESS:
      {
        return _objectSpread({}, state, {
          delivery: _objectSpread({}, state.delivery, {
            isDeliveryPrefilled: false
          })
        });
      }
    case RESET_POPUP_STATE:
      {
        return _objectSpread({}, state, {
          err: null,
          paymentState: PAYMENT_STATES.IDLE
        });
      }
    case CLEAR_CART:
      {
        return _objectSpread({}, initialState, {
          deliverToFulfillmentCenter: false
        });
      }
    case SET_PAYMENT_STATE:
      {
        return _objectSpread({}, state, {
          paymentState: action.payload.paymentState,
          previousPaymentState: state.paymentState
        });
      }
    case SET_SELECTED_ADDRESS_SUCCESS:
      {
        const data = action.payload.address || {};
        return _objectSpread({}, state, {
          delivery: _objectSpread({}, state.delivery, data)
        });
      }
    case CONFIRM_ADDRESS:
      {
        const type = action.payload;
        const error = state.delivery.err;
        const address = type === AddressManualType.ADDRESS_ENTERED ? {} : error.address;
        return _objectSpread({}, state, {
          isAddressEnteredManually: true,
          delivery: _objectSpread({}, state.delivery, address, {
            err: null
          })
        });
      }
    case SHIPPING_ADDRESS_TOUCHED:
      {
        return _objectSpread({}, state, {
          delivery: _objectSpread({}, state.delivery, {
            shippingAddressUntouched: false
          })
        });
      }
    case CHANGE_BUY_MORE_CREDITS:
      {
        const {
          moreToBuy
        } = action.payload;
        return _objectSpread({}, state, {
          credits: _objectSpread({}, state.credits, {
            moreToBuy
          })
        });
      }
    case POPUP_SHOWN:
      {
        const {
          popup,
          params
        } = action.payload;
        if (popup !== INVENTORY_BUY_CREDITS_POPUP) {
          return _objectSpread({}, state, {
            isTOSAccepted: false
          });
        }
        return _objectSpread({}, state, {
          credits: _objectSpread({}, state.credits, {
            moreToBuy: Math.ceil(params.moreToPay / 100)
          })
        });
      }
    case BUY_CREDITS_SUCCESS:
      {
        const {
          available,
          pending
        } = action.payload;
        return _objectSpread({}, state, {
          loading: false,
          credits: _objectSpread({}, state.credits, {
            available,
            pending
          })
        });
      }
    case BUY_CREDITS_FAILURE:
      {
        return _objectSpread({}, state, {
          loading: false
        });
      }
    case BUY_CREDITS_REQUEST:
      {
        return _objectSpread({}, state, {
          loading: true
        });
      }
    case TOGGLE_TOS_CHECKBOX:
      {
        return _objectSpread({}, state, {
          isTOSAccepted: action.payload
        });
      }
    case CHANGE_REPLACEMENT_ORDER_SEARCH_VALUE:
      {
        const value = action.payload.replace(/#/g, '');
        const isEmptyValue = value === '';
        return _objectSpread({}, state, {
          replacementOrder: _objectSpread({}, state.replacementOrder, {
            searchValue: `#${value.toUpperCase()}`,
            selectedItemId: null,
            items: isEmptyValue ? initialState.replacementOrder.items : state.replacementOrder.items
          })
        });
      }
    case REPLACEMENT_ORDER_SEARCH_REQUEST:
      {
        return _objectSpread({}, state, {
          replacementOrder: _objectSpread({}, state.replacementOrder, {
            isLoading: true
          })
        });
      }
    case REPLACEMENT_ORDER_SEARCH_ERROR:
      {
        return _objectSpread({}, state, {
          replacementOrder: _objectSpread({}, state.replacementOrder, {
            isLoading: initialState.replacementOrder.isLoading,
            selectedItemId: initialState.replacementOrder.selectedItemId,
            items: initialState.replacementOrder.items
          })
        });
      }
    case REPLACEMENT_ORDER_SEARCH_SUCCESS:
      {
        const {
          newIds,
          newData
        } = action.payload.list.reduce((acc, item) => {
          acc.newIds.push(item._id);
          acc.newData[item._id] = item;
          return acc;
        }, {
          newIds: [],
          newData: {}
        });
        return _objectSpread({}, state, {
          replacementOrder: _objectSpread({}, state.replacementOrder, {
            isLoading: false,
            items: {
              ids: newIds,
              data: newData
            }
          })
        });
      }
    case SELECT_ORIGINAL_ITEM_FOR_REPLACE:
      {
        const id = action.payload;
        const itemData = state.replacementOrder.items.data[id];
        return _objectSpread({}, state, {
          replacementOrder: _objectSpread({}, state.replacementOrder, {
            isLoading: false,
            searchValue: `#${itemData.num}-${itemData.letterIndicator}`.toUpperCase(),
            selectedItemId: id,
            items: {
              ids: [id],
              data: {
                [id]: itemData
              }
            }
          })
        });
      }
    case COMMENTS_CHANGE_TEXT:
      {
        return _objectSpread({}, state, {
          replacementOrder: _objectSpread({}, state.replacementOrder, {
            comments: action.payload
          })
        });
      }
    case REPLACEMENT_PERCENT_CHANGE:
      {
        return _objectSpread({}, state, {
          replacementOrder: _objectSpread({}, state.replacementOrder, {
            replacementPriceRange: action.payload,
            replacementPriceRangeChanged: true
          })
        });
      }
    case REPLACEMENT_IS_SILENT_CHANGE:
      {
        return _objectSpread({}, state, {
          replacementOrder: _objectSpread({}, state.replacementOrder, {
            isSilent: action.payload
          })
        });
      }
    case ERASE_REPLACEMENT_ORDER_STATE:
      {
        return _objectSpread({}, state, {
          replacementOrder: initialState.replacementOrder
        });
      }
    default:
      {
        return state;
      }
  }
}