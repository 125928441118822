function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { SPECIAL_ROUTES } from './app-special-routes';
export const APP_ROUTES = _objectSpread({}, SPECIAL_ROUTES, {
  HOME: '/',
  IN_A_BOX: '/in-a-box',
  DISTRIBUTE: '/distribute',
  GIVEAWAY: '/gifting',
  AUTOMATION: '/automation',
  ZAPIER: '/zapier',
  SHOPIFY: '/companystores',
  BLOG: '/blog/',
  CUSTOM_SWAG: '/custom-swag',
  REVIEWS: '/reviews',
  PAST_WORK: '/past-work',
  CONTACT: '/contact',
  OUR_STORY: '/our-story',
  FAQ: '/faq',
  MY_COMPANY_DEPARTMENTS: '/my-company/departments',
  MY_COMPANY_PENDING_ORDERS: '/my-company/pending-orders',
  SEARCH_RESULTS: '/search-results',
  LOGIN: '/login',
  ACCOUNT_SAVED_DESIGNS: '/account/saved-designs',
  FAVORITE_PRODUCTS: '/favorite-products',
  MY_COMPANY: '/my-company',
  KICKSTARTER: '/kickstarter',
  WEWORK: '/wework',
  PATAGONIA: '/patagonia',
  SET_PASSWORD: '/set-password',
  VERIFY_EMAIL: '/verify-email',
  FORGOT_PASSWORD: '/forgot-password',
  INVITATION: '/invitation',
  ACCOUNT: '/account',
  ACCOUNT_PUBLIC_API: '/account/public-api',
  ACCOUNT_API_SETTINGS: '/account/public-api/settings',
  ACCOUNT_API_PAYMENT: '/account/public-api/payment',
  NOT_FOUND: '/not-found',
  STARBUCKS: '/starbucks',
  OFFICE: '/office',
  TECHSTARS: '/techstars',
  SPRING: '/spring',
  AMERICANUNDERGROUND: '/americanunderground',
  INDUSTRIOUS: '/industrious',
  PRIMOPRINT: '/primoprint',
  TECHSTARS_PRODUCTS: '/techstars-products',
  SPRING_PRODUCTS: '/spring-products',
  INTELLIGENT: '/intelligent',
  KICKSTARTER_CALCULATOR: '/kickstarter-calculator',
  SUPERVISOR_DASHBOARD_ORDER_APPROVAL: '/',
  SUPERVISOR_DASHBOARD_ALL_ORDERS: '/',
  SUPERVISOR_DASHBOARD_PAYMENT_SETTINGS: '/',
  API_AUTH_REDIRECT: '/',
  DEVELOPMENT_PORTAL: '/public-api-documentation/',
  GOODIE_GIVEAWAY_CATALOG: '/gift-catalog',
  INVENTORY: '/inventory',
  EXPLORER: '/explorer',
  WHAT_WE_OFFER: '/what-we-offer',
  COMMITMENT_TO_ACCESSIBILITY: '/commitment-to-accessibility'
});