import { handleLoginFail } from 'swag-client-common/redux/user/user.actions';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
export const getGamification = () => undefined;
export const handleLoginFailed = (store, dispatch) => (nextState, replace, next) => {
  const {
    code
  } = nextState.params;
  dispatch(handleLoginFail(code));
  replace(APP_ROUTES.ALL_SWAG);
  next();
};
export function fetchInitialStatesOther() {
  return null;
}
export const getCheckoutTenantData = () => undefined;