import { Price } from '../price';
import { isBox } from './item-is-box.util';
export const productionCostCalculate = item => {
  const {
    price,
    setupFeeDeductionDiscount,
    blendedQuantity,
    quantity
  } = item;
  if (isBox(item)) {
    return price;
  }
  let itemPrice = price;
  if (setupFeeDeductionDiscount) {
    const deductionAmount = Price.getItemPriceDeductionAmountFromSetupFeeDeduction({
      setupFeeDeductionDiscount,
      blendedQuantity,
      quantity
    });
    itemPrice -= deductionAmount;
  }
  return itemPrice;
};