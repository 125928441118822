import visaImage from 'assets/svg/visa.svg';
import mastercardImage from 'assets/svg/mastercard.svg';
import amexImage from 'assets/svg/amex.svg';
import discoverImage from 'assets/svg/discover.svg';
export function stripeCardsDefaultText(userDefault, inventoryDefault) {
  switch (true) {
    case userDefault && inventoryDefault:
      return 'Inventory & my default';
    case userDefault:
      return 'My default';
    case inventoryDefault:
      return 'Inventory default';
    default:
      return '';
  }
}
const supportedBrands = {
  Visa: visaImage,
  MasterCard: mastercardImage,
  'American Express': amexImage,
  Discover: discoverImage
};
export function getStripeCardIcon(brand) {
  return supportedBrands[brand];
}
export function sortCards(a, b) {
  if (a.userDefault && b.inventoryDefault) {
    return -1;
  }
  if (a.userDefault && !b.inventoryDefault) {
    return -1;
  }
  if (a.inventoryDefault && !b.userDefault) {
    return -1;
  }
  return 1;
}