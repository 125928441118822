import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { FeatureTogglesApi } from 'swag-client-common/api/feature-toggles.api';
const PREFIX = 'FEATURE_TOGGLES';
export const GET_FEATURE_TOGGLES_REQUEST = `${PREFIX}_REQUEST`;
export const GET_FEATURE_TOGGLES_SUCCESS = `${PREFIX}_SUCCESS`;
export const GET_FEATURE_TOGGLES_FAIL = `${PREFIX}_FAIL`;
export const getFeatureTogglesRequest = () => ({
  type: GET_FEATURE_TOGGLES_REQUEST
});
export const getFeatureTogglesSuccess = payload => ({
  type: GET_FEATURE_TOGGLES_SUCCESS,
  payload
});
export const fetchFeatureToggles = createAsyncAction({
  preFn: dispatch => {
    dispatch(getFeatureTogglesRequest());
  },
  fn: FeatureTogglesApi.getFeatureToggles,
  success: payload => getFeatureTogglesSuccess(payload),
  error: errorAction(GET_FEATURE_TOGGLES_FAIL)
});