import { PROCEED_ORDER_SUCCESS } from '../redux/checkout/checkout.actions';
import { orderTotalPriceSelector, taxesPriceSelector } from '../redux/cart/cart.selectors';
const middleware = ({
  getState
}) => next => action => {
  next(action);
  if (window.rrSpace) {
    const state = getState();
    const total = orderTotalPriceSelector(state);
    const taxes = taxesPriceSelector(state);
    if (action.type === PROCEED_ORDER_SUCCESS) {
      window.rrSpace.executeEvent('conversion', {
        parameters: {
          firstname: state.user.firstName,
          lastname: state.user.lastName,
          email: state.user.email,
          amount: `${Math.round((total - taxes) / 100)}`,
          conversionstatus: 'Approved'
        }
      });
    }
  }
};
export default middleware;