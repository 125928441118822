import _reduxForm from "redux-form/es/reduxForm";
import _reset from "swag-client-common/redux-form-actions/reset";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { isRequestDemoPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { REQUEST_DEMO_FORM } from '../../../redux/redux.constants';
import { requestDemo } from '../../../redux/send-email/send-email.action';
import RequestDemoPopup from './request-demo-popup';
const mapStateToProps = createStructuredSelector({
  open: isRequestDemoPopupShownSelector
});
const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(hidePopup());
    dispatch(_reset(REQUEST_DEMO_FORM));
  },
  onSubmitRequest: () => dispatch(requestDemo())
});
const ReduxForm = _reduxForm({
  form: REQUEST_DEMO_FORM
})(RequestDemoPopup);
export default connect(mapStateToProps, mapDispatchToProps)(ReduxForm);