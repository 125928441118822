function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { getDistributionFeeData } from '../../utils/get-distribution-fee-data';
import { cartItemsForEstimateDistributionFeeSelector } from '../cart/cart.selectors';
import { EstimateDistributionFeeScreen } from '../../constants/estimate-distribution-fee-screen.enum';
import InventoryApi from '../../api/inventory.api';
import { downloadCSVAction } from '../../middleware/csv-download.middleware';
import { emailSelector } from '../checkout/checkout.selectors';
import { getDistributionFeeEstimationDestinationsSelectorFactory, getDistributionFeeEstimationCostsForCSVSelector, widgetTypeSelector, selectedInternationalShippingToCustomerEstimateDistributionFeeSelector, selectedDomesticShippingToCustomerEstimateDistributionFeeSelector, shouldShowOnlyTaxesLabelDistributionFeeSelector } from './distribution-fee-estimation.selector';
const PREFIX = 'DISTRIBUTION_FEE_ESTIMATION_';
export const INIT_CALCULATION_RESULTS = `${PREFIX}INIT_CALCULATION_RESULTS`;
export const initResults = () => ({
  type: INIT_CALCULATION_RESULTS
});
export const INIT_DISTRIBUTION_FEE = `${PREFIX}INIT`;
export const initDistributionFeePopupAction = () => (dispatch, getState) => {
  const items = cartItemsForEstimateDistributionFeeSelector(getState());
  dispatch({
    type: INIT_DISTRIBUTION_FEE,
    payload: {
      productIds: items.map(item => Array.isArray(item) ? item.map(i => i._id) : item._id).flat()
    }
  });
};
export const RESET_DISTRIBUTION_FEE = `${PREFIX}RESET`;
export const resetDistributionFeePopupAction = () => ({
  type: RESET_DISTRIBUTION_FEE
});
export const SET_DISTRIBUTION_FEE_PRODUCT_QUANTITY = `${PREFIX}SET_PRODUCT_QUANTITY`;
export const setDistributionFeeProductQuantityAction = (productId, quantity) => ({
  type: SET_DISTRIBUTION_FEE_PRODUCT_QUANTITY,
  payload: {
    productId,
    quantity
  }
});
export const INCREMENT_DISTRIBUTION_FEE_PRODUCT_QUANTITY = `${PREFIX}INCREMENT_PRODUCT_QUANTITY`;
export const incrementDistributionFeeProductQuantityAction = productId => ({
  type: INCREMENT_DISTRIBUTION_FEE_PRODUCT_QUANTITY,
  payload: {
    productId
  }
});
export const DECREMENT_DISTRIBUTION_FEE_PRODUCT_QUANTITY = `${PREFIX}DECREMENT_PRODUCT_QUANTITY`;
export const decrementDistributionFeeProductQuantityAction = productId => ({
  type: DECREMENT_DISTRIBUTION_FEE_PRODUCT_QUANTITY,
  payload: {
    productId
  }
});
export const SET_DISTRIBUTION_FEE_SCREEN = `${PREFIX}SET_SCREEN`;
export const setDistributionFeeScreen = screen => ({
  type: SET_DISTRIBUTION_FEE_SCREEN,
  payload: {
    screen
  }
});
export const ADD_DISTRIBUTION_FEE_ADDRESS = `${PREFIX}ADD_ADDRESS`;
export const addDistributionFeeEstimationAddress = params => ({
  type: ADD_DISTRIBUTION_FEE_ADDRESS,
  payload: params
});
export const SET_DISTRIBUTION_FEE_COUNTRY_AND_ZIP_QUANTITY = `${PREFIX}SET_COUNTRY_AND_ZIP_QUANTITY`;
export const setDistributionFeeEstimationCountryAndZipQuantityAction = (index, quantity) => ({
  type: SET_DISTRIBUTION_FEE_COUNTRY_AND_ZIP_QUANTITY,
  payload: {
    index,
    quantity
  }
});
export const DELETE_DISTRIBUTION_FEE_COUNTRY_AND_ZIP = `${PREFIX}DELETE_COUNTRY_AND_ZIP`;
export const deleteDistributionFeeEstimationCountryAndZipAction = index => ({
  type: DELETE_DISTRIBUTION_FEE_COUNTRY_AND_ZIP,
  payload: {
    index
  }
});
export const SET_ZIP_CODE_VALIDATION_ERROR = `${PREFIX}SET_ZIP_CODE_VALIDATION_ERROR`;
export const setValidationError = err => ({
  type: SET_ZIP_CODE_VALIDATION_ERROR,
  payload: err
});
export const CALCULATE_DISTRIBUTION_FEES_COSTS_REQUEST = `${PREFIX}CALCULATE_DISTRIBUTION_FEES_COSTS_REQUEST`;
export const CALCULATE_DISTRIBUTION_FEES_COSTS_SUCCESS = `${PREFIX}CALCULATE_DISTRIBUTION_FEES_COSTS_SUCCESS`;
export const CALCULATE_DISTRIBUTION_FEES_COSTS_FAILURE = `${PREFIX}CALCULATE_DISTRIBUTION_FEES_COSTS_FAILURE`;
export const calculateDistributionFeesAction = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: CALCULATE_DISTRIBUTION_FEES_COSTS_REQUEST
    });
  },
  transformData: (params, getState) => {
    const state = getState();
    const destinations = getDistributionFeeEstimationDestinationsSelectorFactory({
      withShippingMethod: false
    })(state);
    const deliveryEmail = emailSelector(state);
    return {
      destinations,
      deliveryEmail
    };
  },
  fn: InventoryApi.getDistributionEstimate,
  success: costs => ({
    type: CALCULATE_DISTRIBUTION_FEES_COSTS_SUCCESS,
    payload: {
      costs
    }
  }),
  postSuccess: (dispatch, getState, transformedData, costs) => {
    if (costs.every(cost => !cost.error)) {
      dispatch(setDistributionFeeScreen(EstimateDistributionFeeScreen.zipCodeResults));
    }
  },
  error: error => ({
    type: CALCULATE_DISTRIBUTION_FEES_COSTS_FAILURE,
    error
  }),
  postError: (dispatch, _, err) => {
    dispatch(showNotification({
      text: err.message
    }));
  }
});
export const SELECT_SHIPPING_METHOD_REQUEST = `${PREFIX}SELECT_SHIPPING_METHOD_REQUEST`;
export const SELECT_SHIPPING_METHOD_SUCCESS = `${PREFIX}SELECT_SHIPPING_METHOD_SUCCESS`;
export const SELECT_SHIPPING_METHOD_FAILURE = `${PREFIX}SELECT_SHIPPING_METHOD_FAILURE`;
export const setShippingMethodDistributionFeesAction = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: SELECT_SHIPPING_METHOD_REQUEST
    });
  },
  transformData: (params, getState) => {
    const state = getState();
    const destinations = getDistributionFeeEstimationDestinationsSelectorFactory({
      withShippingMethod: true,
      shippingMethodToCustomerParams: params.shippingMethodToCustomer,
      isInternational: params.isInternational
    })(state);
    const deliveryEmail = emailSelector(state);
    return {
      destinations,
      deliveryEmail
    };
  },
  fn: InventoryApi.getDistributionEstimate,
  success: costs => ({
    type: SELECT_SHIPPING_METHOD_SUCCESS,
    payload: {
      costs
    }
  }),
  error: error => ({
    type: SELECT_SHIPPING_METHOD_FAILURE,
    error
  }),
  postError: (dispatch, _, error) => {
    dispatch(showNotification({
      text: error.message
    }));
  }
});
export const changeShippingMethod = params => (dispatch, getState) => {
  const {
    shippingMethodToCustomer
  } = params;
  const state = getState();
  const previousIntShippingMethod = selectedInternationalShippingToCustomerEstimateDistributionFeeSelector(state);
  const previousDomShippingMethod = selectedDomesticShippingToCustomerEstimateDistributionFeeSelector(state);
  if (shippingMethodToCustomer !== previousIntShippingMethod || shippingMethodToCustomer !== previousDomShippingMethod) {
    dispatch(setShippingMethodDistributionFeesAction(params));
  }
};
export const getCalculateDistributionFeesCSVAction = () => (dispatch, getState) => {
  const state = getState();
  const costs = getDistributionFeeEstimationCostsForCSVSelector(state);
  const shouldShowOnlyTaxesLabel = shouldShowOnlyTaxesLabelDistributionFeeSelector(state);
  const dutiesAndTaxesLabel = shouldShowOnlyTaxesLabel ? 'Taxes' : 'Duties and Taxes';
  const header = ['Country', 'State', 'Number of shipments', 'Shipping cost', 'Pick and package cost', dutiesAndTaxesLabel, 'Total distribution cost', 'Est. date to fulfillment center', 'Est. date to recipients'];
  const distributionFeeDataToShow = getDistributionFeeData(header, costs);
  const {
    headerToShow,
    costsToShow
  } = distributionFeeDataToShow;
  dispatch(downloadCSVAction([headerToShow, ...costsToShow], 'Calculated_Fees'));
};
export const SET_DISTRIBUTION_FEE_ESTIMATE_RECIPIENTS_QUANTITY = `${PREFIX}SET_DISTRIBUTION_FEE_ESTIMATE_RECIPIENTS_QUANTITY`;
export const setDistributionFeeEstimateRecipientsQuantity = value => ({
  type: SET_DISTRIBUTION_FEE_ESTIMATE_RECIPIENTS_QUANTITY,
  payload: value
});
export const SET_DISTRIBUTION_FEE_ESTIMATE_STATES_FOR_COUNTRY = `${PREFIX}SET_DISTRIBUTION_FEE_ESTIMATE_STATES_FOR_COUNTRY`;
export const getStatesForCountry = createAsyncLoadedAction({
  transformData: country => {
    return {
      country
    };
  },
  fn: InventoryApi.getStatesForDistributionWidget,
  success: result => ({
    type: SET_DISTRIBUTION_FEE_ESTIMATE_STATES_FOR_COUNTRY,
    payload: result
  })
});
export const WIDGET_ON_LANDING_SET_STATE = `${PREFIX}WIDGET_ON_LANDING_SET_STATE`;
export const setState = value => ({
  type: WIDGET_ON_LANDING_SET_STATE,
  payload: value
});
export const WIDGET_ON_LANDING_SET_ESTIMATES = `${PREFIX}WIDGET_ON_LANDING_SET_ESTIMATES`;
export const WIDGET_ON_LANDING_SET_ERROR = `${PREFIX}WIDGET_ON_LANDING_SET_ERROR`;
export const setEstimates = createAsyncLoadedAction({
  transformData: (data, getState) => {
    const _state = getState();
    const type = widgetTypeSelector(_state);
    return _objectSpread({}, data, {
      quantity: 1,
      type
    });
  },
  fn: InventoryApi.getDistributionByCountryAndState,
  success: result => ({
    type: WIDGET_ON_LANDING_SET_ESTIMATES,
    payload: result
  }),
  error: error => ({
    type: WIDGET_ON_LANDING_SET_ERROR,
    payload: error.message,
    error
  }),
  postError: (dispatch, _, err) => {
    dispatch(showNotification({
      text: err.message
    }));
  }
});
export const WIDGET_ON_LANDING_SET_PRODUCT_TYPE = `${PREFIX}WIDGET_ON_LANDING_SET_PRODUCT_TYPE`;
export const setProductType = value => ({
  type: WIDGET_ON_LANDING_SET_PRODUCT_TYPE,
  payload: value
});
export const WIDGET_ON_LANDING_SET_QUANTITY = `${PREFIX}WIDGET_ON_LANDING_SET_QUANTITY`;
export const setQuantity = value => ({
  type: WIDGET_ON_LANDING_SET_QUANTITY,
  payload: value
});