import { loadScript } from '../../utils/load-script.utils';
const PREFIX = 'TENANT_STORE_';
export const LOAD_ADDITIONAL_SCRIPTS_SUCCESS = `${PREFIX}LOAD_ADDITIONAL_SCRIPTS_SUCCESS`;
export const LOAD_ADDITIONAL_SCRIPTS_FAILED = `${PREFIX}LOAD_ADDITIONAL_SCRIPTS_FAILED`;
export const loadAdditionalScriptsAction = () => async dispatch => {
  if (window.swag.storeSettings) {
    try {
      await Promise.allSettled(window.swag.storeSettings.additionalScripts.map(script => loadScript({
        src: script.path,
        isAsync: false,
        type: script.scriptType,
        position: script.position
      })));
      dispatch({
        type: LOAD_ADDITIONAL_SCRIPTS_SUCCESS
      });
    } catch (error) {
      dispatch({
        type: LOAD_ADDITIONAL_SCRIPTS_FAILED
      });
    }
  }
};