export const PRINTING_METHODS = {
  SCREENPRINTING: 'screenprinting',
  DTG: 'dtg'
};
export const ALIGN_SIDES = {
  left: 'left',
  right: 'right'
};
export const CHEST_POSITIONS = {
  [ALIGN_SIDES.left]: {
    top: 5,
    left: 45,
    width: 50
  },
  [ALIGN_SIDES.right]: {
    top: 5,
    left: 5,
    width: 50
  }
};