import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/user';
const ApiSettingsApi = createApi({
  getApiCredentials: {
    url: `${baseUrl}/api-credentials`,
    method: 'get'
  },
  resetApiCredentials: {
    url: `${baseUrl}/api-credentials/reset`,
    method: 'get'
  },
  createRedirectUrl: {
    url: `${baseUrl}/api-credentials/redirect-urls`,
    method: 'post'
  },
  deleteRedirectUrl: {
    url: `${baseUrl}/api-credentials/redirect-urls/:redirectUrlId`,
    method: 'delete'
  },
  createCreditCard: {
    url: `${baseUrl}/add-api-payment`,
    method: 'post'
  },
  deleteCreditCard: {
    url: `${baseUrl}/remove-api-payment`,
    method: 'delete'
  }
});
export { ApiSettingsApi };