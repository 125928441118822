function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { validate, requiredValidator } from 'swag-client-common/utils/validators';
import { deliverToFulfillmentCenterSelector } from '../checkout/checkout.selectors';
const PREFIX = 'BILLING_ADDRESS_';
export const VALIDATED = `${PREFIX}VALIDATED`;
const validateBillingAddress = () => (dispatch, getState) => {
  const {
    billingAddress: {
      address,
      mandatoryKeys
    }
  } = getState();
  const errors = mandatoryKeys.reduce((err, key) => {
    const error = validate(address[key], requiredValidator);
    return error ? _objectSpread({}, err, {
      [key]: error
    }) : err;
  }, {});
  dispatch({
    type: VALIDATED,
    errors
  });
};
export const EDIT_FIELD = `${PREFIX}EDIT_FIELD`;
export const editField = (field, value) => dispatch => {
  dispatch({
    type: EDIT_FIELD,
    field,
    value
  });
  dispatch(validateBillingAddress());
};
export const SAME_TO_SHIPPING = `${PREFIX}SAME_TO_SHIPPING`;
const setSameAsShipping = () => (dispatch, getState) => {
  const {
    checkout: {
      delivery
    }
    //billingAddress: { address },
  } = getState();
  const copiedAddress = {
    firstName: delivery.fnameShipping,
    lastName: delivery.lnameShipping,
    street: delivery.address,
    unit: delivery.unit,
    city: delivery.city,
    state: delivery.state,
    country: delivery.country,
    zipcode: delivery.zip
  };
  dispatch({
    type: SAME_TO_SHIPPING,
    address: copiedAddress
  });
};
export const SAME_AS_SHIPPING_WAS_SET = `${PREFIX}SAME_AS_SHIPPING_WAS_SET`;
export const setSameAsShippingInitial = () => (dispatch, getState) => {
  const {
    billingAddress: {
      sameAsShippingWasSet
    }
  } = getState();
  const deliverToFulfillmentCenter = deliverToFulfillmentCenterSelector(getState());
  if (sameAsShippingWasSet || deliverToFulfillmentCenter) {
    return;
  }
  dispatch(setSameAsShipping());
  dispatch({
    type: SAME_AS_SHIPPING_WAS_SET
  });
};
export const RESET = `${PREFIX}RESET`;
export const toggleSameAsShipping = ({
  isSame
}) => (dispatch /* , getState */) => {
  if (isSame) {
    dispatch(setSameAsShipping());
  } else {
    dispatch({
      type: RESET
    });
  }
};