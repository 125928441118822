function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { POPUP_SHOWN } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { NON_VECTOR_IMAGE_UPLOAD_POPUP } from 'swag-client-common/constants';
import { FETCH_PRODUCT_SUCCESS } from '../product/product.actions';
import { UPDATE_LOGO_DETAILS } from '../logo/logo.actions';
import { STATE_LOADING, STATE_IDLE, STATE_SUCCESS, STATE_ERROR } from '../redux.constants';
import { PRINTING_METHODS } from '../product/product.constants';
import { LOGO_UPLOAD_STARTED, DESIGN_UPLOAD_STARTED, LOGO_UPLOAD_STATUS_CHANGE, LOGO_UPLOAD, COLORS_DETECTED, FIRST_COLORS_SELECTION, SET_DATA_TO_PROCESS, CHANGE_COLOR_LOGO_SUCCESS, CHANGE_COLOR_LOGO_FAILURE, CHANGE_COLOR_LOGO, REMOVE_BACKGROUND, REMOVE_BACKGROUND_REVERT, REVERT_TO_ORIGINAL_PREVIEW, CHANGE_PRINTING_METHOD, REMOVE_COLOR, ADD_COLOR, SHOW_NOTIFICATION, HIDE_NOTIFICATION, LOGO_UPLOAD_OVERLAY } from './logo-editing.actions';
const initialState = {
  isSuccess: undefined,
  id: undefined,
  location: '',
  showOverlay: false,
  etag: null,
  preview: null,
  original: null,
  originalPreview: null,
  originalFileName: null,
  previewWOBackground: null,
  isBackgroundRemoved: false,
  previewsConvertedToOneColor: {},
  state: STATE_IDLE,
  selectedColors: [],
  detectedColors: [],
  convertTo: null,
  printingMethod: PRINTING_METHODS.SCREENPRINTING,
  neverSelectedColors: true,
  error: null,
  logoHandlingProgress: 0,
  logoHandlingStatus: '',
  productData: {
    hasDtgOption: false,
    minimumColorsForDtg: null,
    maxColorsToPrint: 0
  },
  isNotificationShown: false,
  hasBackground: undefined,
  // @todo. auto generated. fix this error
  // @ts-ignore
  logoToUploadView: null,
  // @todo. auto generated. fix this error
  // @ts-ignore
  logoToUploadFile: null
};
export default function logoEditingReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_SUCCESS:
      {
        const {
          hasDtgOption,
          minimumColorsForDtg,
          maxPrintColors
        } = action.payload;
        return _objectSpread({}, state, {
          productData: {
            hasDtgOption,
            minimumColorsForDtg,
            maxPrintColors
          }
        });
      }
    case LOGO_UPLOAD_STATUS_CHANGE:
      {
        const {
          progress,
          status,
          isSuccess
        } = action.payload;
        return _objectSpread({}, state, {
          logoHandlingProgress: progress,
          logoHandlingStatus: status,
          isSuccess: !!isSuccess
        });
      }
    case LOGO_UPLOAD:
      {
        const {
          original,
          preview,
          previewWOBackground,
          customerLogoOriginalName,
          id,
          location,
          etag
        } = action.payload;
        return _objectSpread({}, state, {
          original,
          originalPreview: preview,
          preview,
          id,
          location,
          previewWOBackground,
          originalFileName: customerLogoOriginalName,
          previewsConvertedToOneColor: {},
          etag
        });
      }
    case COLORS_DETECTED:
      {
        const {
          detectedColors,
          hasBackground,
          isBackgroundRemoved,
          selectedColors
        } = action;
        const {
          originalPreview,
          previewWOBackground
        } = state;
        return _objectSpread({}, state, {
          detectedColors,
          selectedColors,
          hasBackground,
          isBackgroundRemoved,
          preview: isBackgroundRemoved ? previewWOBackground : originalPreview,
          convertTo: null
        });
      }
    case CHANGE_PRINTING_METHOD:
      {
        const printingMethod = action.payload;
        return _objectSpread({}, state, {
          printingMethod
        });
      }
    case SET_DATA_TO_PROCESS:
      {
        const _action$payload = action.payload,
          {
            colors: {
              selectedColors,
              detectedColors,
              convertTo,
              isBackgroundRemoved,
              hasBackground
            },
            originalPreview,
            preview,
            previewWOBackground,
            previewsConvertedToOneColor = {},
            printingMethod
          } = _action$payload,
          rest = _objectWithoutProperties(_action$payload, ["colors", "originalPreview", "preview", "previewWOBackground", "previewsConvertedToOneColor", "printingMethod"]);
        return _objectSpread({}, state, rest, {
          selectedColors,
          detectedColors,
          originalPreview,
          convertTo,
          isBackgroundRemoved,
          hasBackground,
          preview,
          previewWOBackground,
          previewsConvertedToOneColor,
          printingMethod
        });
      }
    case CHANGE_COLOR_LOGO:
      return _objectSpread({}, state, {
        state: STATE_LOADING
      });
    case CHANGE_COLOR_LOGO_SUCCESS:
      {
        const {
          convertTo,
          preview
        } = action.payload;
        return _objectSpread({}, state, {
          convertTo,
          preview,
          previewsConvertedToOneColor: _objectSpread({}, state.previewsConvertedToOneColor, {
            [convertTo.pms]: preview
          }),
          state: STATE_SUCCESS
        });
      }
    case CHANGE_COLOR_LOGO_FAILURE:
      return _objectSpread({}, state, {
        error: action.err,
        state: STATE_ERROR
      });
    case REMOVE_BACKGROUND:
      {
        return _objectSpread({}, state, {
          preview: state.previewWOBackground,
          isBackgroundRemoved: true
        });
      }
    case REMOVE_BACKGROUND_REVERT:
      {
        return _objectSpread({}, state, {
          preview: state.originalPreview,
          isBackgroundRemoved: false
        });
      }
    case REVERT_TO_ORIGINAL_PREVIEW:
      {
        return _objectSpread({}, state, {
          preview: state.hasBackground ? state.previewWOBackground : state.originalPreview,
          isBackgroundRemoved: state.hasBackground,
          convertTo: null,
          state: STATE_IDLE
        });
      }
    case UPDATE_LOGO_DETAILS:
      {
        return _objectSpread({}, initialState, {
          productData: state.productData
        });
      }
    case FIRST_COLORS_SELECTION:
      {
        return _objectSpread({}, state, {
          neverSelectedColors: false
        });
      }
    case REMOVE_COLOR:
      {
        const {
          pms
        } = action.payload;
        const selectedColors = state.selectedColors.filter(color => color.pms !== pms);
        return _objectSpread({}, state, {
          selectedColors
        });
      }
    case ADD_COLOR:
      {
        const {
          color
        } = action.payload;
        const selectedColors = [color, ...state.selectedColors];
        return _objectSpread({}, state, {
          selectedColors
        });
      }
    case SHOW_NOTIFICATION:
      {
        return _objectSpread({}, state, {
          isNotificationShown: true
        });
      }
    case HIDE_NOTIFICATION:
      {
        return _objectSpread({}, state, {
          isNotificationShown: false
        });
      }
    case LOGO_UPLOAD_STARTED:
    case DESIGN_UPLOAD_STARTED:
      {
        const {
          location
        } = action.payload;
        return _objectSpread({}, state, {
          location
        });
      }
    case LOGO_UPLOAD_OVERLAY:
      {
        const {
          show
        } = action.payload;
        return _objectSpread({}, state, {
          showOverlay: show
        });
      }
    case POPUP_SHOWN:
      {
        const {
          popup
        } = action.payload;
        if (popup === NON_VECTOR_IMAGE_UPLOAD_POPUP) {
          const {
            file,
            view
          } = action.payload.params;
          return _objectSpread({}, state, {
            logoToUploadFile: file,
            logoToUploadView: view
          });
        }
        return state;
      }
    default:
      return state;
  }
}