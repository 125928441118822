import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { isPreBuiltLimitedCartOpenedByCustomerSelector } from 'swag-client-common/redux/cart/cart.selectors';
import { requestDemoHubspotCalendarLinkSelector } from 'swag-client-common/redux/settings/settings.selectors';
import { offerOptionsSelector } from './common-props';
import { OffersDropdownComponent } from './offers-dropdown';
const showDropDownSelector = createSelector(isPreBuiltLimitedCartOpenedByCustomerSelector, isCustomerOpenPreBuiltLimitedCart => {
  return !isCustomerOpenPreBuiltLimitedCart && window.swag.storeSettings.isWhatWeOfferActive;
});
const mapStateToProps = createStructuredSelector({
  offerOptions: offerOptionsSelector,
  isShown: showDropDownSelector,
  hubspotCalendarLink: requestDemoHubspotCalendarLinkSelector
});
export const OffersDropdown = connect(mapStateToProps)(OffersDropdownComponent);