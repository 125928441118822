function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import moment from 'moment';
import { mergeOrdersWithPagination } from '../../../common/utils/merge-orders';
import { PERIODS } from '../account-orders/account-orders.constants';
import { SELECT_LVL_ALL } from '../gamification/gamification.constants';
import { FETCH_USERS_ORDERS_SUCCESS, FETCH_USERS_ORDERS_ERROR, ORDERS_FILTERED, COMPANY_ORDER_SEARCH, FETCH_USERS_ORDERS_REQUEST, UPDATE_EXPORT_DATE, RESET_ORDERS, SEARCH_CHANGE } from './company-orders.actions';
const initialState = {
  orders: [],
  ordersToDisplay: [],
  timePeriod: PERIODS[0],
  selectedLvl: SELECT_LVL_ALL,
  searchTerm: '',
  err: null,
  loading: false,
  hasNoOrders: false,
  exportDate: {
    maxDate: moment().toDate(),
    minDate: new Date('2015'),
    exportFrom: moment().subtract(1, 'days').toDate(),
    exportTo: moment().toDate()
  },
  pagination: {
    perPage: 5,
    page: 1,
    total: 0
  },
  search: ''
};
export default function companyOrdersReducer(state = initialState, action) {
  const {
    type,
    payload
  } = action;
  switch (type) {
    case UPDATE_EXPORT_DATE:
      return _objectSpread({}, state, {
        exportDate: _objectSpread({}, state.exportDate, {
          [action.payload.dateType]: action.payload.dateValue
        })
      });
    case FETCH_USERS_ORDERS_REQUEST:
      {
        return _objectSpread({}, state, {
          loading: true
        });
      }
    case FETCH_USERS_ORDERS_SUCCESS:
      {
        const {
          orders,
          timePeriod,
          hasNoOrders,
          pagination
        } = payload;
        const {
          pagination: {
            page: prevPage
          },
          orders: prevOrders
        } = state;
        const {
          page,
          perPage
        } = pagination;
        const mergedOrders = mergeOrdersWithPagination({
          page,
          prevPage,
          perPage,
          orders,
          prevOrders
        });
        return _objectSpread({}, state, {
          orders: mergedOrders,
          timePeriod,
          ordersToDisplay: orders,
          hasNoOrders,
          pagination,
          loading: false
        });
      }
    case FETCH_USERS_ORDERS_ERROR:
      {
        return _objectSpread({}, state, {
          err: action.payload,
          loading: false
        });
      }
    case ORDERS_FILTERED:
      {
        const {
          timePeriod,
          selectedLvl,
          ordersToDisplay
        } = action.payload;
        return _objectSpread({}, state, {
          timePeriod,
          selectedLvl,
          ordersToDisplay,
          loading: false
        });
      }
    case COMPANY_ORDER_SEARCH:
      {
        const {
          ordersToDisplay,
          searchTerm
        } = action.payload;
        return _objectSpread({}, state, {
          ordersToDisplay,
          searchTerm
        });
      }
    case RESET_ORDERS:
      {
        return _objectSpread({}, state, {
          orders: [],
          ordersToDisplay: [],
          pagination: initialState.pagination
        });
      }
    case SEARCH_CHANGE:
      {
        return _objectSpread({}, state, {
          search: action.payload.search
        });
      }
    default:
      return state;
  }
}