function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { connect } from 'react-redux';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { signout as signoutAction } from 'swag-client-common/redux/user/user.actions';
import { LOGIN_POPUP, SIGNUP_POPUP } from 'swag-client-common/constants';
import { mobMenuState } from 'swag-client-common/utils/constants';
import { ALL_SWAG_CATEGORY } from 'swag-client-common/constants';
import { SwagAccount as SwagAccountComponent } from 'swag-client-common/components/ecommerce-header/components/swag-account';
import { inventoriesCountSelector, isAdminModeSelector, userHasInventoriesSelector } from 'swag-client-common/redux/user/user.selectors';
import { cartItemsCountSelector, cartBadgeIsLoadingSelector } from 'swag-client-common/redux/cart/cart.selectors';
import { isFilterOpenedSelector, selectedCategoryIdsSelector } from '../../../../../../redux/product-listing/product-listing.selectors';
import { isFiltersPopupShownSelector } from '../../../../../../redux/search-results/search-results.selectors';
import { stateUserInventoryToShowSelector } from '../../../../../../redux/inventory/inventory.selectors';
import { showInventoryLinkSelector } from '../../../../../../redux/inventory/inventory-other.selectors';
import { isShownMobMenuSelector, mobMenuStateSelector } from '../../../../../../redux/pages/pages.selectors';
import { changeIsShownMobMenuState } from '../../../../../../redux/pages/pages.actions';
import { hideIntercom } from '../../../../../../../customer/utils/ui.utils';
export const categoriesMenuSelector = state => {
  const {
    categories,
    sorting,
    categoriesMap,
    currentCategoryName,
    appliedFilter,
    isVisible
  } = state.productListing;
  const currentCategory = categoriesMap[currentCategoryName] || {};
  const parentCategory = currentCategory && currentCategory.parent_id && categories.find(c => c._id === currentCategory.parent_id) || null;
  const currentMainCategory = parentCategory || currentCategory;
  const isBrowseProductPage = window.location.pathname.includes('collections');
  const visibleCategories = categories.filter(category => category.isVisible);
  if (!isBrowseProductPage) {
    return {
      categories: visibleCategories,
      currentMainCategory: {}
    };
  }
  const isActiveFilterBlockShown = Object.keys(appliedFilter).length && isVisible && !(Object.keys(appliedFilter).length === 0 && appliedFilter.categoryId && currentMainCategory.slug !== ALL_SWAG_CATEGORY);
  return {
    currentCategory,
    currentSubcategoryId: currentCategory.parent_id ? currentCategory._id : null,
    subcategories: currentMainCategory.subcategories && currentMainCategory.subcategories.length > 0 ? currentMainCategory.subcategories : null,
    isActiveFilterBlockShown,
    isFilterOpened: isFilterOpenedSelector(state) || isFiltersPopupShownSelector(state),
    sorting,
    categoriesMap,
    currentMainCategory,
    categories: visibleCategories,
    isFilterSectionShown: isBrowseProductPage,
    productCount: state.productListing.products.length
  };
};
const mapStateToProps = state => _objectSpread({
  accountVerification: state.accountVerification,
  userRole: state.user.role,
  isApiAccessEnabled: state.user.publicApiSettings.isApiAccessEnabled,
  userCompanyDepartments: state.user.companyDepartments,
  userIsHeadOfCompanyDepartment: state.user.isHeadOfCompanyDepartment,
  orderApprovalFlow: state.user.orderApprovalFlow,
  isLoggedIn: state.user.isLoggedIn,
  itemsInCart: cartItemsCountSelector(state),
  isLoading: cartBadgeIsLoadingSelector(state),
  name: state.user.firstName,
  showText: false,
  userHasInventory: userHasInventoriesSelector(state),
  inventoryId: stateUserInventoryToShowSelector(state),
  showInventoryLink: showInventoryLinkSelector(state),
  inventoriesCount: inventoriesCountSelector(state),
  open: isShownMobMenuSelector(state),
  state: mobMenuStateSelector(state),
  selectedCategoryIds: selectedCategoryIdsSelector(state),
  shouldHideUserLinks: isAdminModeSelector(state)
}, categoriesMenuSelector(state));
const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(signoutAction()),
  onLogin: () => dispatch(showPopup({
    popup: LOGIN_POPUP
  })),
  onSignup: () => dispatch(showPopup({
    popup: SIGNUP_POPUP
  })),
  changeIsShownMobMenuState: (isShown, state = mobMenuState.MAIN) => {
    hideIntercom(isShown);
    dispatch(changeIsShownMobMenuState(isShown, state));
  }
});
export const SwagAccount = connect(mapStateToProps, mapDispatchToProps)(SwagAccountComponent);