import { SUPER_SPEED_PRODUCTION_OPTION } from 'swag-common/constants/product.constants';
export const isSuperSpeedItem = item => {
  var _item$prod, _item$prod$productSet, _item$prod$productSet2, _item$prod2, _item$prod2$productSe, _item$prod2$productSe2, _item$prod2$productSe3;
  if (!(item !== null && item !== void 0 && (_item$prod = item.prod) !== null && _item$prod !== void 0 && (_item$prod$productSet = _item$prod.productSettings) !== null && _item$prod$productSet !== void 0 && (_item$prod$productSet2 = _item$prod$productSet[0]) !== null && _item$prod$productSet2 !== void 0 && _item$prod$productSet2.productionTimeList) || !item.prodTime && item.prodTime !== 0) {
    return false;
  }
  return (item === null || item === void 0 ? void 0 : (_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : (_item$prod2$productSe = _item$prod2.productSettings) === null || _item$prod2$productSe === void 0 ? void 0 : (_item$prod2$productSe2 = _item$prod2$productSe[0]) === null || _item$prod2$productSe2 === void 0 ? void 0 : (_item$prod2$productSe3 = _item$prod2$productSe2.productionTimeList[item.prodTime]) === null || _item$prod2$productSe3 === void 0 ? void 0 : _item$prod2$productSe3.name) === SUPER_SPEED_PRODUCTION_OPTION;
};
export const isProceededItemSuperSpeed = item => {
  return !!(item !== null && item !== void 0 && item.isSuperSpeed);
};